import {getUniqueDataNodeByType} from			"../scripts/dataManager";
import {getTranslation} from							"../scripts/localization/localizationManager";
import {interfaceTypeEnum, interfaceOperatingModeEnum} from	"../scripts/constantsAndEnumerations";
import Joi from "joi-browser";	// TODO replace by below
// import Joi from "joi";


/** Central collection of all all validation schemata
 *
 * UTILIZING:
 *		joi-browser validation library, details under https://github.com/jeffbski/joi-browser, https://github.com/hapijs/joi
 *		schema documentation: https://github.com/hapijs/joi/blob/v14.3.0/API.md
 *		pattern evaluation: https://scotch.io/tutorials/node-api-schema-validation-with-joi#toc-advanced-schemas
 *		helper to create regular expressions: https://regex101.com/
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *		Stanislav Biryukov
 */


export const schemaNoValidation = {										// typically used for descriptions etc.
	value: Joi.string().allow(""),
};

export const schemaGenericText = {										// typically used for descriptions etc.
	value: Joi.string().empty("").label("tmp"),
};

export const schemaGenericName = {										// typically used for dataNode names
	value: Joi.string().trim().required().error((errors) => {
		return {message: errors[0].context.label + " " + getTranslation("validation.msg-name")};
	}).label("tmp"),
};

export const schemaPositiveFloat = {									// TODO
	value: Joi.number().positive().required().error((errors) => {
		return {message: errors[0].context.label + " " + getTranslation("validation.msg-positiveFloat")};
	}).label("tmp"),
};

export const schemaPositiveInteger = {									// TODO
	value: Joi.number().integer().positive().required().error((errors) => {
		return {message: errors[0].context.label + " " + getTranslation("validation.msg-positiveInteger")};
	}).label("tmp"),
};

// TODO function with min-max parameter when the cable min-max lengths will be present
export const schemaCableMinMaxIntegerOLD = {									// TODO
	value: Joi.number().integer().min(100).max(100000).required().error((errors) => {
		return {message: errors[0].context.value ? errors[0].context.label + (errors[0].context.limit > errors[0].context.value ? " \u2265 " : " \u2264 ") + errors[0].context.limit : errors[0].context.label + " " + getTranslation("validation.msg-positiveInteger")};
	}).label("tmp"),
};


export const schemaCableMinMaxInteger = (_min, _max) => {		// positive integer between min/max and divisible by 10 (last arg forces input of cm's)
	return {
		value: Joi.number().integer().min(_min).max(_max).multiple(10).required().error((errors) => {
			return {message: errors[0].context.value ? errors[0].context.label + (errors[0].context.limit > errors[0].context.value ? " \u2265 " : " \u2264 ") + errors[0].context.limit : errors[0].context.label + " " + getTranslation("validation.msg-positiveInteger")};
		}).label("tmp"),
	}
};

export const schemaFilename = {										// TODO
	value: Joi.string().regex(/^[\u00C0-\u017Fa-zA-Z0-9 _\-.#]+$/).max(30).trim().required().error((errors) => {
		return {message: errors[0].context.label + " " + getTranslation("validation.msg-file")};
	}).label("tmp"),	// only allow alphanumerics (inlcuding umlauts) and "_-#."
};

export const schemaCheckbox = {										// TODO
	value: Joi.boolean().label("tmp"),
};

export const schemaDropdown = {										// TODO
	value: Joi.string().required().error(() => {
		return {message: getTranslation("modalDialog.dialogAggregate.msg-selectDropdownElement")};
	}).label("tmp"),
};

/* export const schemaDropdownAggr = {										// TODO
	value: Joi.string().required().invalid(0).error(() => {
		return {message: getTranslation("mDlg-msg-selectDropdownElement")};
	}).label("tmp"),
}; */

/** Returns a custom referenceDesignator validation schema
 * @param  {String} _dataNodeType to validate
 * @returns {joi-schema} custom schema
 */
export function schemaReferenceDesignator(_dataNodeType) {
	const tmpTemplate = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getTemplate();
	let tmpRegexPattern = null;
	let tmpExample = null;

	switch (_dataNodeType) {
		case "InfrastructureNode":
			tmpRegexPattern = tmpTemplate.regex.start + tmpTemplate.regex.function + tmpTemplate.regex.place + tmpTemplate.regex.end;
			tmpExample = tmpTemplate.functionExample;
			break;
		case "AssemblyNode":
			tmpRegexPattern = tmpTemplate.regex.start + tmpTemplate.regex.function + tmpTemplate.regex.place + tmpTemplate.regex.end;
			tmpExample = tmpTemplate.functionExample;
			break;
		case "UnitNode":
			tmpRegexPattern = tmpTemplate.regex.start + tmpTemplate.regex.function + tmpTemplate.regex.place + tmpTemplate.regex.device + tmpTemplate.regex.end;
			tmpExample = tmpTemplate.unitExample;
			break;
		case "DeviceNode":
			tmpRegexPattern = tmpTemplate.regex.start + tmpTemplate.regex.function + tmpTemplate.regex.place + tmpTemplate.regex.device + tmpTemplate.regex.end;
			tmpExample = tmpTemplate.deviceExample;
			break;
		default:
			break;
	}

	return {
		value: Joi.string().regex(new RegExp(tmpRegexPattern)).required().error(() => {
			return {message: getTranslation("validation.error-referenceDesignator-pattern-mismatch") + `: "${tmpExample}"`};
		}).label("tmp"),
	};
}

export const schemaArrayUniqueness = {
	value: Joi.array().unique((a, b) => a.getReferenceDesignatorString === b.getReferenceDesignatorString).label("tmp"),
};

/** Returns a custom checkbox safeOffVoltage validation schema
 * @param  {object} _interface where safeOffVoltage changed
 * @returns {joi-schema} custom schema
 */
export function schemaSafeOffVoltage(_interface) {
	let schema = null;
	switch (_interface.operatingMode.type) {
		case interfaceOperatingModeEnum.CONTINUOUS.type:
			if ((_interface.type.type == interfaceTypeEnum.ENERGY_AC.type || _interface.type.type == interfaceTypeEnum.ENERGY_DC.type) && _interface.isSafe) schema = Joi.boolean().invalid(true);
			else schema = Joi.boolean();
			break;
		default:
			schema = Joi.boolean();
			break;
	}
	return {
		value: schema.error(() => {
			return {message: getTranslation("modalDialog.dialogAggregate.msg-safeOffNotPossible")};
		}).label("tmp"),
	};
}
