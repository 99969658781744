import * as React from "react";
import {Typography} from "@mui/material";
import "./gitInfo.css";

type GitInfoProps = {
	gitInfo: GitInfo;
	gitClass: string;
	style: React.CSSProperties;
};
//! this type must be defined in applicationManager.ts, move later
type GitInfo = {
	branchType: "HOTFIX" | "BUGFIX" | "TESTING" | "FEATURE" | "PRODUCTION" | "DEVELOPMENT" | "OTHER";
	currentTag: null | string;
	commitHash: string;
	commitDate: string;
	branchName: string;
};

/**
 *
 * jsx for git info
 * @returns {React.ReactElement} git info
 */
export default function GitInfo(props: GitInfoProps): React.ReactElement {
	const gitString: string = createInfoString(props.gitInfo);

	/**
	 * Constructs a string to display as git info (top right display corner)
	 * @param {object} _gitInfo to construct string from
	 * @returns {string} containing formatted git info (depending on the branch type)
	 */
	function createInfoString(_gitInfo: GitInfo): string {
		switch (_gitInfo.branchType) {
			case "PRODUCTION":
				if (_gitInfo.currentTag != null) {
					return `Version ${_gitInfo.currentTag}`;
				} else {
					return "";
				}
			case "TESTING":
				return `Testing | ${_gitInfo.commitHash} | ${_gitInfo.commitDate}`;
			default:
				return `${_gitInfo.branchName} | ${_gitInfo.commitHash} | ${_gitInfo.commitDate}`;
		}
	}

	return (
		<Typography noWrap className={props.gitClass} sx={props.style}>
			{gitString}
		</Typography>
	);
}
