import {NodeGraphicsElement} from "../../graphics"; // convert to webpack import
import tmpLimboNodeIcon from "../../../images/outlinerIcons/limboNode.icon.svg";

const tmpLimboNodeImage = null; // convert to webpack import
const tmpLimboNodeSymbol = null;

export const limboNodeTemplate = {
	databaseId: -666,
	UUID: "lmbo",
	type: "LimboNode",
	graphics: {
		image: new NodeGraphicsElement(tmpLimboNodeImage, null, null),
		symbol: new NodeGraphicsElement(tmpLimboNodeSymbol, null, null),
		icon: new NodeGraphicsElement(tmpLimboNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "LimboNodeToolTip",
	},

	i18nKey: null,
	description: null,
	ports: [],
	decentralControl: null,
};
