import "./dragListFiller.css";


/**
 * Class of li-element used as filler between content-carrying li-elements.
 * Used in conjunction with DragListControl.
 * @class DragListFiller
 */
export class DragListFiller {
	/**
	 * Creates an instance of DragListFiller.
	 * @returns {HTMLElement} empty li-element with initial height 0.
	 */
	constructor() {
		return this.__createListItem();
	}

	/**
	 * Creates empty li-element with initial height 0.
	 * @returns {HTMLElement} empty li-element with class and id.
	 */
	__createListItem() {
		const tmpListItemFiller = document.createElement("li");
		tmpListItemFiller.classList.add("dragListFiller");
		tmpListItemFiller.id = "dragListFiller";

		return tmpListItemFiller;
	}
}
