import {NodeGraphicsElement} from "../../graphics";

import tmpAssemblyNodeImage from "../../../images/canvasImages/assemblyNode.image.svg";
import tmpAssemblyNodeSymbol from "../../../images/canvasImages/assemblyNode.symbol.svg"; //! I don't see any need for a different symbol atm
import tmpAssemblyNodeIcon from "../../../images/outlinerIcons/assemblyNode.icon.svg";

export const assemblyNodeTemplate = {
	databaseId: -400,
	type: "AssemblyNode",
	graphics: {
		image: new NodeGraphicsElement(tmpAssemblyNodeImage, 410 * 0.3, 237 * 0.3),
		symbol: new NodeGraphicsElement(tmpAssemblyNodeImage, 410 * 0.3, 237 * 0.3), // used as symbol
		// symbol: new NodeGraphicsElement(tmpAssemblyNodeSymbol, null, null),		//! I don't see any need for a different symbol atm
		icon: new NodeGraphicsElement(tmpAssemblyNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "AssemblyNodeToolTip",
	},

	i18nKey: "dataManager.defaultname-assemblyNode",
	description: "",
	ports: [],
	referenceDesignator: {},
	decentralControl: false,
};
