import {genericRequestLegacy1, requestEnum} from "./communication/communicationManagerLegacy";
import {PortSide} from "./ports/utils";

/* Central structure for server communication from/to the modal dialog
 *
 * Utilizing:

 * Author Stanislav Biryukov
 */

/**
 * Creates connector list for consumer/aggregate port: Request
 * @param {Port} _port - port of aggregate's connector
 * @param {boolean} _includeRibbon - wether ribbon connector is possible, true for aggregate and cabinet
 * @returns {object} objConnectorOptions - connector options
 */
export function makeConnectorArrayByPort(_port, _includeRibbon) {
	let objConnectorOptions = [];
	const parameterIn = {side: _port.side, includeRibbon: _includeRibbon}; // , shield: _port.isShielded
	const interfaces = [];
	for (const tmpInterface of _port.interfaces) {
		interfaces.push({
			id: tmpInterface.type.value,
			interfaceTyp: tmpInterface.type.group,
			currentRated: parseFloat(tmpInterface.current),
			voltage: parseInt(tmpInterface.voltage),
			mode: tmpInterface.operatingMode.number,
			name: tmpInterface.name,
			safeFlg: tmpInterface.isSafe,
			motorFlg: tmpInterface.isMotor,
			protectionFlg: tmpInterface.isProtected,
			softFlg: tmpInterface.isSoftStarted,
			monitorFlg: tmpInterface.isMonitored,
			address: tmpInterface.address,
		});
	}
	parameterIn.interfaceList = interfaces;
	genericRequestLegacy1(requestEnum.CONNECTORS, {parameterIn: JSON.stringify(parameterIn)}, processConsumerConnectors); // @SBI use a wrapper please (like requestTranslationFile)

	/**
	 * processes consumer connectors from ajax
	 * @param  {object} _consumerConnectors - connector list, ajax reply
	 */
	function processConsumerConnectors(_consumerConnectors) {
		if (_consumerConnectors.consumerConnectorList && typeof _consumerConnectors.consumerConnectorList.length !== "undefined") {
			objConnectorOptions = _consumerConnectors.consumerConnectorList; // assign result from ajax, must be checked
		} else MESSENGER.post2statusbar("ERROR", "modalDialog.dialogAggregate.msg-errorConnector");
	}
	return objConnectorOptions;
}
