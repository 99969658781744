//TODO css is still a mess


import type {EventManager} from					"../../eventManager";
import {LanguageSwitcher} from					"./LanguageSwitcher";
import {Messenger} from									"./Messenger";
import																	"./statusbar.css";


/**
 * Class representing a Statusbar.
 */
export class Statusbar {
	private static instance: Statusbar;	// singleton helper
	private globalEventManager: EventManager;
	private id = "statusbar";
	private HTML: HTMLDivElement;
	public messenger: Messenger;
	public languageSwitcher: LanguageSwitcher;

	/**
	 * Creates an instance of Statusbar.
	 * @param {HTMLDivElement} _parentContainer DOM element to nest into
	 * @param {EventManager} _globalEventManager to hook into
	 */
	constructor(_parentContainer: HTMLDivElement, _globalEventManager: EventManager) {
		// Prevent multiple instances
		if (Statusbar.instance) throw new Error("Singleton classes can't be instantiated more than once.");
		Statusbar.instance = this;

		this.globalEventManager = _globalEventManager;
		this.HTML = this.createDOM(_parentContainer);
		this.messenger = new Messenger(this.HTML, this.globalEventManager);
		this.languageSwitcher = new LanguageSwitcher(this.HTML, this.globalEventManager);

		Object.seal(this);
	}

	/**
	 * Creates the DOM components of Statusbar.
	 * @param {HTMLDivElement} _parentContainer DOM element to nest into
	 * @returns {HTMLDivElement} statusbar DOM element
	 */
	private createDOM(_parentContainer: HTMLDivElement): HTMLDivElement {
		const tmpElement = document.createElement("div");
		tmpElement.id = this.id;
		tmpElement.classList.add("noselect");
		_parentContainer.appendChild(tmpElement);

		return tmpElement;
	}
}

