import {Application} from "../constantsAndEnumerations2";
import {BaseConnection} from "./BaseConnection";
import type {HydraulicConnectionData, HydraulicConnectionSaveData} from "./hydraulicConnection.schema";

/** Class describing a hydraulic connection between two Nodes. */
export class HydraulicConnection extends BaseConnection {
	/**
	 * Creates a new instance of HydraulicConnection
	 * @param {HydraulicConnectionData} _connectionData  dataset describing a hydraulic connection
	 */
	constructor(_connectionData: HydraulicConnectionData) {
		throw new Error(`Hydraulic Connection is not yet implemented :(`);
	}

	/**
	 * Returns the variable characteristics of this hydraulic connection.
	 * @returns {HydraulicConnectionSaveData} object containing all info
	 */
	public save(): HydraulicConnectionSaveData {
		return {
			...super.save(),
			application: Application.HYDRAULIC, //REFACTOR this should be handled by BaseConnection
			ports: this.ports.map((_hydraulicPort) => {
				const tmpPortSave = _hydraulicPort.save();
				_hydraulicPort.interfaces = []; // don't save interfaces on connections and their ports
				return tmpPortSave;
			}),
		};
	}
}
