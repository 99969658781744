/* cSpell:disable */

import {BACKEND, GLOBALEVENTMANAGER, MESSENGER} from "./applicationManager";
import {layOutGroupNode} from "./canvasLayout";
import {eCreateCanvas, eTrashCanvas, eUnTrashCanvas, eDeleteCanvas, eUpdateCanvasName, eSetActiveCanvas} from "./canvasManager";
import {requestAutoConfig} from "./communication/communicationManagerLegacy";
import {ElectricConnectionCompatibilityChecker} from "./compatibilityChecks/ElectricConnectionCompatibilityChecker.ts";
import {FindAllCompatibleElectricConnections} from "./compatibilityChecks/FindAllCompatibleElectricConnections.ts";
import {PortCompatibilityChecker} from "./compatibilityChecks/PortCompatibilityChecker.ts";
import {connectionFactory} from "./connections/connectionFactory.ts";
import {createGenericElectricConnectionSourceData} from "./connections/createGenericConnection.ts";
import {genericElectricConnectionTemplate} from "./connections/genericElectricConnectionTemplate";
import {parseRESTPortData} from "./dataConverter";
import {ProjectNode, DeviceNode, UnitNode, InfrastructureNode, TrashNode, LimboNode, AssemblyNode} from "./dataNode";
import {dataNodeFactoryFRH} from "./dataNodes/dataNodeFactory.ts";
import {assemblyNodeTemplate} from "./dataNodes/templates/assemblyNodeTemplate";
import {infrastructureNodeTemplate} from "./dataNodes/templates/infrastructureNodeTemplate";
import {limboNodeTemplate} from "./dataNodes/templates/limboNodeTemplate";
import {projectNodeTemplate} from "./dataNodes/templates/projectNodeTemplate";
import {trashNodeTemplate} from "./dataNodes/templates/trashNodeTemplate";
import {unitNodeTemplate} from "./dataNodes/templates/unitNodeTemplate";
import {searchArrayForElementByKeyValuePair} from "./helper";
import {
	eCreateJspNode,
	eDeleteJspObject,
	eRelocateJspNode,
	eRenameJspNode,
	eRepositionJspNode,
	eTechnicalParameterUpdate,
	eUpdateJspNodeTooltip,
	eUpdateJspNodeValidationIndicator,
	eSwitchNodePaletteMode,
	eZoomCanvasToFit,
} from "./jsPlumb/jspManager";
import {getTranslation} from "./localization/localizationManager";
import {eUpdateTreeNodeName, eCreateTreeNode, eRelocateTreeNode, eDeleteTreeNode, eUpdateTreeNodeTooltip, eSetActiveTreeNode} from "./GUI/outliner/outliner";

import cloneDeep from "lodash.clonedeep";
import {portFactory} from "./ports/portFactory";

/* ######### Quick and dirty hack to satisfy eslint-jsdoc/no-undefined-types (TS - I wish you were here!) ######### */

//! Don't rely on the type definitions made here!!!

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {string} UUID
 * @typedef {object} DataNode
 * @typedef {object} Connection
 * @typedef {object} Port
 */
/* eslint-enable jsdoc/require-property */

/* ################################################################################################################ */

// Central, top most data parent; for details see setDataRoot
export const dataRoot = {
	debugMode: null,
	nodeList: [], // stores all nodes separately for quick access and convenience.
	nodeCounter: {}, // helper object to calculated reference Designators.
	activeNode: null, // stores the momentarily active dataNode (clicked in outliner or set programmatically).
	activeGroupNode: null, // stores the momentarily active 1st or 2nd level groupNode (only project-, infrastructure-, trash- or assemblyNodes).
	sourceData: [], // stores all device & cable details retrieved from server.
	interfaceSourceData: [], // stores all interface details retrieved from server.
	portSourceData: [], // stores all port details retrieved from server.
};

/**
 * Standard initialization routine phase 1 (just setting event handlers)
 * @param {boolean} [_debugMode=false] turn chatty log on/off
 */
export function initializeDataManager(_debugMode = false) {
	dataRoot.debugMode = _debugMode;
	if (dataRoot.debugMode) {
		window.dataRoot = dataRoot;
		window.getDataNodeByUUID = getDataNodeByUUID;
		window.getPortByUUID = getPortByUUID;
		window.getConnectionByUUID = getConnectionByUUID;
		window.getUniqueDataNodeByType = getUniqueDataNodeByType;
		window.getDeviceSourceDataByDatabaseId = getDeviceSourceDataByDatabaseId;
		window.getConnectionSourceDataByDatabaseId = getConnectionSourceDataByDatabaseId;
		window.getPortSourceDataByDatabaseId = getPortSourceDataByDatabaseId;
		// eslint-disable-next-line no-console
		console.debug("%cDebug Mode: true", "color: green");
	}

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeCreated", eCreateTreeNode);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeCreated", eCreateCanvas);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeCreated", eCreateJspNode);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRelocated", eRelocateTreeNode);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRelocated", eRelocateJspNode);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToTrash", eHandleTrash); // Trashing a DataNode is NOT a deletion but a relocation event!
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToTrash", eTrashCanvas);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRemovedFromTrash", eUnTrashCanvas);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", eDeleteTreeNode);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", eDeleteJspObject);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", eDeleteCanvas);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", eHandleLimbo);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRenamed", eUpdateTreeNodeName);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRenamed", eUpdateCanvasName);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRenamed", eRenameJspNode);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRepositioned", eRepositionJspNode);

	// GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeDescriptionChanged", console.debug("eDTM_DataNodeDescriptionChanged"));
	// GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeDescriptionChanged", console.debug("eDTM_DataNodeDescriptionChanged"));
	GLOBALEVENTMANAGER.addHandler("eDTM_TechnicalParameterChanged", eTechnicalParameterUpdate);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeTooltipChanged", eUpdateJspNodeTooltip);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeTooltipChanged", eUpdateTreeNodeTooltip);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeValidationChanged", eUpdateJspNodeValidationIndicator);

	GLOBALEVENTMANAGER.addHandler("eDTM_ActivateDataNodeChanged", eSetActiveTreeNode);
	GLOBALEVENTMANAGER.addHandler("eDTM_ActivateGroupNodeChanged", eSetActiveCanvas);
	GLOBALEVENTMANAGER.addHandler("eDTM_ActivateGroupNodeChanged", eToggleAccordionItemsActiveMode);
	GLOBALEVENTMANAGER.addHandler("eDTM_ActivateGroupNodeChanged", eSwitchNodePaletteMode);

	GLOBALEVENTMANAGER.addHandler("eGUI_NewAssemblySpecial", newAssemblySpecialHandler);
	GLOBALEVENTMANAGER.addHandler("eDTM_CanvasAssembled", eZoomCanvasToFit);

	GLOBALEVENTMANAGER.addHandler("eJSP_CreateDataNode", eNewDataNode);
	GLOBALEVENTMANAGER.addHandler("eJSP_CreatePort", eAddPort);
	GLOBALEVENTMANAGER.addHandler("eJSP_CreateConnection", eCreateConnection);
	GLOBALEVENTMANAGER.addHandler("eJSP_RemoveConnection", eRemoveConnection);
	GLOBALEVENTMANAGER.addHandler("eCM_RemoveConnection", eRemoveConnection);

	addSourceDataToDataRoot(genericElectricConnectionTemplate);
	addSourceDataToDataRoot(projectNodeTemplate);
	addSourceDataToDataRoot(infrastructureNodeTemplate);
	addSourceDataToDataRoot(trashNodeTemplate);
	addSourceDataToDataRoot(limboNodeTemplate);
	addSourceDataToDataRoot(assemblyNodeTemplate);
	addSourceDataToDataRoot(unitNodeTemplate);
}

/** Standard initialization routine phase 2 (defining project base structure) */
export function finalizeDataManager() {
	dataRoot.project = new ProjectNode(getTranslation(projectNodeTemplate.i18nKey)); // mandatory Node
	dataRoot.infrastructure = new InfrastructureNode(); // mandatory Node
	getUniqueDataNodeByType("ProjectNode").addChild(dataRoot.infrastructure);
	// dataRoot.project.children.push(dataRoot.infrastructure);
	dataRoot.trash = new TrashNode(); // mandatory Node
	dataRoot.limbo = new LimboNode(); // mandatory Node
	getUniqueDataNodeByType("ProjectNode").createChild(new AssemblyNode(getTranslation("dataManager.defaultname-assemblyNode"), createUUID()));
	getUniqueDataNodeByType("ProjectNode").setActive(); // set focus initially to projectNode
}

/* ======================================== EVENTHANDLING ======================================== */

/**
 * Wrapper for renameEvents raised from contextMenu
 * @param {DataNode} _dataNode to rename
 * @param {string} _newName of dataNode
 */
export function eRenameDataNode(_dataNode, _newName) {
	_dataNode.setName(_newName);
}

/**
 * Wrapper for relocateEvents raised from Outliner
 * @param {DataNode} _dataNode to move
 * @param {DataNode} _targetParentNode to move to
 */
export function eRelocateDataNode(_dataNode, _targetParentNode) {
	_dataNode.relocate(_targetParentNode);
}

/**
 * Wrapper for deleteEvents raised from Outliner (ContextMenu)
 * @param {DataNode} _dataNode to delete
 * @param {boolean} _deletePermanently wether to delete dataNode (=true) or move it to trash (=false)
 */
export function eDeleteDataNode(_dataNode, _deletePermanently) {
	_dataNode.delete(_deletePermanently); // _deletePermanently
}

/**
 * Wrapper for clearEvents raised from Outliner (ContextMenu)
 * @param {DataNode} _dataNode to clear
 */
export function eClearDataNode(_dataNode) {
	const deletePermanently = false; // TODO add a way to dynamically decide on permanent deletion (some kind of dialog is needed)
	_dataNode.clear(deletePermanently);
}

/** Wrapper for createAssemblyNodeEvents raised from Outliner (ContextMenu) */
export function eNewAssemblyNode() {
	getUniqueDataNodeByType("ProjectNode").createChild(new AssemblyNode(getTranslation("dataManager.defaultname-assemblyNode"), createUUID()));
}

/** Wrapper for createAssemblyNodeEvents raised from sidePanelWidget atop outliner */
export function newAssemblySpecialHandler() {
	const tmpNode = new AssemblyNode(getTranslation("dataManager.defaultname-assemblyNode"), createUUID());
	getUniqueDataNodeByType("ProjectNode").createChild(tmpNode);
	tmpNode.setActive();
	GLOBALEVENTMANAGER.dispatch("eDTM_EditTreeNode", tmpNode.UUID);
}

/**
 * Wrapper for createUnitNodeEvents raised from Outliner (ContextMenu)
 * @param {DataNode} _parentDataNode to add new UnitNode to
 */
function eNewUnitNode(_parentDataNode) {
	getDataNodeByUUID(_parentDataNode.UUID).createChild(new UnitNode(getTranslation("dataManager.defaultname-unitNode"), createUUID()));
}

/**
 * Wrapper for eJSP_CreateDeviceNode raised from jsPlumb
 * @param {string} _parentDataNodeId to add new dataNode to
 * @param {DataNode} _UUID of the node to create
 * @param {string} _baseData this nodes real world data
 */
export function eNewDataNode(_parentDataNodeId, _UUID, _baseData) {
	if (_baseData.type === "deviceNode") {
		getDataNodeByUUID(_parentDataNodeId).createChild(new DeviceNode(_baseData.name, _UUID, _baseData));
	} else {
		getDataNodeByUUID(_parentDataNodeId).createChild(new UnitNode(_baseData.name, _UUID, _baseData));
	}
}

/**
 * Wrapper for eOL_Click & eCM_Click event raised from Outliner/canvasManager
 * @param {string} _UUID of the dataNode that is represented by the treeNode that got clicked
 */
export function eUpdateActiveNode(_UUID) {
	getDataNodeByUUID(_UUID).setActive();
}

/**
 * Wrapper for createPort events raised from outside.
 * atm only used for port creation on unitNode drop
 * @param {UUID} _UUID of dataNode to add port to.
 * @param {Port} _port to add to datanode
 * @listens eJSP_CreatePort
 */
function eAddPort(_UUID, _port) {
	const dataNode = getDataNodeByUUID(_UUID);
	dataNode.addPort(_port);
}

/**
 * Wrapper for createConnection events raised from outside.
 * @param {UUID} _groupNodeUUID of DataGroupNode to add a connection to
 * @param {UUID} _sourceDevicePortUUID of sourceDevice to connect.
 * @param {UUID} _targetDevicePortUUID of targetDevice to connect.
 * @listens eJSP_CreateConnection
 */
function eCreateConnection(_groupNodeUUID, _sourceDevicePortUUID, _targetDevicePortUUID) {
	const groupNode = getDataNodeByUUID(_groupNodeUUID);
	const sourceDevicePort = getPortByUUID(_sourceDevicePortUUID);
	const targetDevicePort = getPortByUUID(_targetDevicePortUUID);

	// check port compatibility
	const portIsCompatible = new PortCompatibilityChecker({silent: false}).autoCheck(sourceDevicePort, targetDevicePort);
	if (!portIsCompatible) return;

	// find compatible connections
	const sourceConnections = dataRoot.sourceData.filter((sourceElement) => sourceElement.group === "cables");
	const compatibleConnections = new FindAllCompatibleElectricConnections(
		sourceDevicePort,
		targetDevicePort,
		sourceConnections,
		new ElectricConnectionCompatibilityChecker(),
	).result;

	// check if a compatibleConnection was found, if not try to create a genericConnection
	const connectionReference = compatibleConnections
		? compatibleConnections[0].databaseId
		: createGenericElectricConnectionSourceData(sourceConnections, sourceDevicePort, targetDevicePort, {debugMode: true});

	if (!connectionReference) {
		// abort if no compatibleConnection was found and creating a genericConnection failed
		MESSENGER.post2statusbar("ERROR", "autoConfig.noCableFound", {
			targetPort: targetDevicePort,
			targetDevice: targetDevicePort.parent,
			sourcePort: sourceDevicePort,
			sourceDevice: sourceDevicePort.parent,
		});
		return;
	}

	// remove existing connections on sourceDevicePort/targetDevicePort
	if (sourceDevicePort.isConnectedTo) groupNode.removeConnection(sourceDevicePort.isConnectedTo.parent);
	if (targetDevicePort.isConnectedTo) groupNode.removeConnection(targetDevicePort.isConnectedTo.parent);

	const connection = connectionFactory(connectionReference);
	groupNode.addConnection(connection, sourceDevicePort, targetDevicePort);
}

/**
 * Wrapper for removeConnection events raised from outside.
 * @param {UUID} _connectionUUID of connection to remove
 * @listens eJSP_RemoveConnection
 * @listens eCM_RemoveConnection
 */
export function eRemoveConnection(_connectionUUID) {
	const connection = getConnectionByUUID(_connectionUUID);
	if (!connection) return; // prevent event chaining

	const groupNode = connection.parent;
	groupNode.removeConnection(connection);
}

/**
 * Wrapper for eJSP_PathEditStarted event raised from jsPlumbManager
 * @param {string} _uuid of the edge, which path must be changed
 */
export function ePathEditStarted(_uuid) {
	const tmpConnection = searchArrayForElementByKeyValuePair(dataRoot.activeNode.connections, "UUID", _uuid);
	tmpConnection.editStarted = true;
}

/**
 * Wrapper for eJSP_PathEditStopped event raised from jsPlumbManager
 * @param {string} _uuid of the edge, which path is changed
 * @param {object} _geometry of the edge
 * @param {boolean} _edited property of edge
 */
export function ePathEditStopped(_uuid, _geometry, _edited) {
	const tmpConnection = searchArrayForElementByKeyValuePair(dataRoot.activeNode.connections, "UUID", _uuid);
	if (tmpConnection) {
		tmpConnection.geometry = _geometry;
		tmpConnection.editStarted = false;
		tmpConnection.edited = _edited;
	}
}

/**
 * Wrapper for eJSP_NodeRepositioned event raised from jsPlumbManager
 * @param {string} _UUID of dataNode that got moved
 * @param {Array} _position new position of dataNode (x, y)
 */
export function eRepositionDataNode(_UUID, _position) {
	getDataNodeByUUID(_UUID).setPosition({x: _position.x, y: _position.y});
}

/**
 * Wrapper for eDTM_DataNodeSendToTrash event raised from DataManager
 * @param {DataNode} _dataNode that got trashed
 */
function eHandleTrash(_dataNode) {
	MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-trashed", {dataNode: _dataNode, trashNode: getUniqueDataNodeByType("TrashNode")});
}

/**
 * Wrapper for eDTM_DataNodeSendToLimbo event raised from DataManager
 * @param {DataNode} _dataNode that got limboed
 */
function eHandleLimbo(_dataNode) {
	MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-removed", {dataNode: _dataNode});
}

/**
 * Wrapper for eJSP_SurfaceLayoutChanged event raised from jspManager
 * @param {string} _UUID of dataNode that needs to be updated
 * @param {object} _layoutData (pan x/y, zoom)
 */
export function eUpdateGroupNodeLayout(_UUID, _layoutData) {
	getDataNodeByUUID(_UUID).graphics.layout = _layoutData;
}

/**
 * Wrapper for eDTM_ActivateGroupNodeChanged event raised from dataManager; checks activeGroupNode and raises an event to set all collapsibleAccordionItems active mode accordingly
 * @param {DataNode} _activeGroupNode new active GroupNode
 */
function eToggleAccordionItemsActiveMode(_activeGroupNode) {
	if (_activeGroupNode.nodeType === "AssemblyNode" || _activeGroupNode.nodeType === "InfrastructureNode") {
		GLOBALEVENTMANAGER.dispatch("eDTM_ToggleAccordionCollapsibleItemsActiveMode", true);
	} else {
		GLOBALEVENTMANAGER.dispatch("eDTM_ToggleAccordionCollapsibleItemsActiveMode", false);
	}
}

/* ============================= LOAD / SAVE / IMPORT / EXPORT ============================= */

/** Standard reset routine (setting Manager back to initial values etc..) */
function resetProject() {
	getUniqueDataNodeByType("ProjectNode").setActive();
	getUniqueDataNodeByType("ProjectNode").clear(true);
	getUniqueDataNodeByType("InfrastructureNode").clear(true);
	getUniqueDataNodeByType("TrashNode").clear();
	MESSENGER.clear();
}

/**
 * Sets the project back to it's defaults
 * @param {string} _projectName (OPTIONAL) name for new project
 */
export function newProject(_projectName = getTranslation(projectNodeTemplate.i18nKey)) {
	resetProject();
	getDataNodeByUUID("proj").setName(_projectName);
	getUniqueDataNodeByType("ProjectNode").createChild(new AssemblyNode(getTranslation("dataManager.defaultname-assemblyNode"), createUUID()));
	MESSENGER.post2statusbar("NORMAL", "dataManager.newProject", {projectNode: getUniqueDataNodeByType("ProjectNode")});
}

/**
 * Saves the project under the provided name.
 * @param {number} _userId of the user, whose project is saved
 * @param {number|null} _projectId of project to save, null in case it's a project that's not already saved
 * @param {string} _projectName to save project under
 */
export async function saveProject(_userId, _projectId, _projectName) {
	await exportData(_userId, _projectId, _projectName, getUniqueDataNodeByType("ProjectNode").UUID);
	MESSENGER.post2statusbar("WARNING", "modalDialog.projects.saved-finish", {projectName: `${_projectName}`});
}

/**
 * Loads a project with the provided name from the user with the given id.
 * @param {number} _userId id of the user, whose project should be loaded
 * @param {number} _projectId received from user
 */
export async function loadProject(_userId, _projectId) {
	resetProject();
	const tmpProjectData = await BACKEND.loadProject(_userId, _projectId);
	MESSENGER.post2statusbar("WARNING", "modalDialog.projects.loading-start", {projectName: `${tmpProjectData.name}`});
	importDataFRH(JSON.parse(tmpProjectData.data));
	MESSENGER.post2statusbar("WARNING", "modalDialog.projects.loading-finish", {projectName: `${tmpProjectData.name}`});
}

/**
 * Persists the data Structure (or parts of it) to an object in preparation for a server request.
 * @param {number} _userId id of the user, whose data is exported
 * @param {number|null} _projectId id of project to save, null in case it's a project that's not already saved
 * @param {string} _projectName name to save project
 * @param {string} _dataNodeUUID top most dataNode to save
 */
async function exportData(_userId, _projectId, _projectName, _dataNodeUUID) {
	const tmpSaveData = getDataNodeByUUID(_dataNodeUUID).save();
	await BACKEND.saveProject(_userId, _projectId, _projectName, tmpSaveData);
}

/**
 * Imports a (partial) dataStructure into the project.
 * @param {object} _importData a dataNode structure representing a project or parts of it
 */
function importData(_importData) {
	if (_importData.nodeType === "ProjectNode") {
		const projectInfrastructure = _importData.children.filter((child) => child.UUID === "infr")[0];
		_importData.children.push(_importData.children.splice(_importData.children.indexOf(projectInfrastructure), 1)[0]); // move the infrastructure to the end
		getUniqueDataNodeByType("ProjectNode").update(_importData);
	} else if (_importData.nodeType === "InfrastructureNode") {
		getUniqueDataNodeByType("InfrastructureNode").update(_importData);
	} else {
		getDataNodeByUUID(_importData.parentUUID).createChild(dataNodeFactory(_importData));
	}

	if (_importData.children != null) {
		_importData.children.forEach((child) => {
			importData(child);
		});
	}

	if (_importData.connections.length > 0) {
		_importData.connections.forEach((connection) => {
			const tmpDataNode = getDataNodeByUUID(_importData.UUID);
			const tmpSourcePort = getPortByUUID(connection.sourcePortId);
			const tmpTargetPort = getPortByUUID(connection.targetPortId);

			//! replace by something like:
			//! const connection = connectionFactory(connectionSaveData);
			//! groupNode.addConnection(connection, sourceDevicePort, targetDevicePort);

			tmpDataNode.createConnection(tmpSourcePort, tmpTargetPort, connection.type, connection.UUID, connection.databaseId, connection.geometry, connection.edited);
		});
	}
	GLOBALEVENTMANAGER.dispatch("eDTM_CanvasAssembled", _importData.UUID);
}

/**
 * Imports a (partial) dataStructure into the project.
 * @param {object} _importData a dataNode structure representing a project or parts of it
 */
function importDataFRH(_importData) {
	const projectNode = getUniqueDataNodeByType("ProjectNode");
	projectNode.update(_importData);

	_importData.children.forEach((_groupNodeData) => {
		if (_groupNodeData.type === "AssemblyNode") {
			const groupNode = dataNodeFactoryFRH(_groupNodeData);
			projectNode.createChild(groupNode);

			GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", groupNode.UUID, true);
			_groupNodeData.children.forEach((_dataNodeData) => {
				const dataNode = dataNodeFactoryFRH(_dataNodeData);
				groupNode.createChild(dataNode);
			});
			GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", groupNode.UUID, false);
			restoreConnectionsFromSaveData(_groupNodeData, groupNode);
		}
	});

	const infrastructureNodeData = _importData.children.find((_child) => _child.UUID === "infr");
	const infrastructureNode = getUniqueDataNodeByType("InfrastructureNode");
	infrastructureNode.update(infrastructureNodeData);

	GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", infrastructureNodeData.UUID, true);
	infrastructureNodeData.children.forEach((_dataNodeData) => {
		const dataNode = dataNodeFactoryFRH(_dataNodeData);
		infrastructureNode.createChild(dataNode);
	});
	GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", infrastructureNodeData.UUID, false);
	restoreConnectionsFromSaveData(infrastructureNodeData, infrastructureNode);

	projectNode.children.forEach((_dataGroupNode) => {
		GLOBALEVENTMANAGER.dispatch("eDTM_CanvasAssembled", _dataGroupNode.UUID);
	});

	/**
	 * restoring connections by import
	 * @param {object} _groupNodeData nodeData from dataNode to restore
	 * @param {AssemblyNode|InfrastructureNode} _groupNode dataNode to restore
	 */
	function restoreConnectionsFromSaveData(_groupNodeData, _groupNode) {
		_groupNodeData.connections.forEach((_connectionSaveData) => {
			const sourceDevicePort = getPortByUUID(_connectionSaveData.sourceDevicePortUUID);
			const targetDevicePort = getPortByUUID(_connectionSaveData.targetDevicePortUUID);
			if (_connectionSaveData.databaseId === 0) {
				const sourceConnections = dataRoot.sourceData.filter((_sourceElement) => _sourceElement.group === "cables");
				const genericConnectionSourceData = createGenericElectricConnectionSourceData(sourceConnections, sourceDevicePort, targetDevicePort);
				if (!genericConnectionSourceData) throw new Error("Could not create generic connection.");
				_connectionSaveData = {...genericConnectionSourceData, ..._connectionSaveData};
			}
			const connection = connectionFactory(_connectionSaveData);
			_groupNode.addConnection(connection, sourceDevicePort, targetDevicePort);
		});
	}
}

/**
 * Creates a dataNode instance
 * @param {string} _tmpNodeData dataNode to restore
 * @returns {DataNode} instance of _type
 */
function dataNodeFactory(_tmpNodeData) {
	let TmpNodeClass = {};
	switch (_tmpNodeData.nodeType) {
		case "ProjectNode":
			// since ProjectNodes never get completely deleted (just updated) nothing is happening here
			return;
		case "InfrastructureNode":
			// since InfrastructureNodes never get completely deleted (just updated) nothing is happening here
			return;
		case "AssemblyNode":
			TmpNodeClass = AssemblyNode;
			break;
		case "UnitNode":
			TmpNodeClass = UnitNode;
			break;
		case "DeviceNode":
			TmpNodeClass = DeviceNode;
			break;
		default:
			throw new Error(`nodeType "${_tmpNodeData.nodeType}" unknown!`);
	}

	if (!_tmpNodeData.UUID) return new TmpNodeClass(_tmpNodeData.name, createUUID(), _tmpNodeData);
	else return new TmpNodeClass(_tmpNodeData.name, _tmpNodeData.UUID, _tmpNodeData);
}

/* ======================================== HELPERS ======================================== */

/**
 * Checks if a dataNode exists
 * @param {DataNode} _dataNode fully qualified path of dataNode to check, may be substituted by getDataNodeByUUID(_dataNode.UUID)
 * @throws exception providing additional information on error
 */
function checkDataNodeExistence(_dataNode) {
	if (!searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _dataNode.UUID)) {
		throw new Error("DataNode does not exist.");
	}
}

/**
 * Returns a dataNode with matching UUID.
 * @param {string} _UUID identifier of dataNode to find.
 * @returns {DataNode} dataNode with matching UUID.
 * @throws  exception providing additional information on error.
 */
export function getDataNodeByUUID(_UUID) {
	if (!searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _UUID)) {
		throw new Error(`DataNode with UUID "${_UUID}" does not exist!`);
	} else {
		return searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _UUID);
	}
}

/**
 *	Retrieves a port from local ports[] by its unique ID.
 * @param {string} _uniquePortId of Port to get.
 * @returns {Port|false} with matching Id, false if no Id is matching.
 */
export function getPortByUUID(_uniquePortId) {
	let res = false;
	for (let i = 0; i < dataRoot.nodeList.length; i++) {
		res = searchArrayForElementByKeyValuePair(dataRoot.nodeList[i].ports, "UUID", _uniquePortId);
		if (res) {
			break;
		}
	}
	return res;
}

/**
 * Returns a connection with matching UUID
 * @param {UUID} _UUID identifier of cable to find
 * @returns {Connection} connection with matching UUID or false
 */
export function getConnectionByUUID(_UUID) {
	let result;
	const dataGroupNodes = dataRoot.nodeList.filter((dataNode) => dataNode.getType() === "AssemblyNode" || dataNode.getType() === "InfrastructureNode");

	for (const dataGroupNode of dataGroupNodes) {
		result = dataGroupNode.connections.find((connection) => connection.UUID === _UUID);
		if (result) break;
	}

	return result || false;
}

/**
 * Returns a unique DataNode (ProjectNode, InfrastructureNode, TrashNode)
 * @param {string} _type of DataNode to find
 * @returns {DataNode} dataNode of matching type
 * @throws  exception providing additional information on error
 */
export function getUniqueDataNodeByType(_type) {
	let tmpNode;

	switch (_type) {
		case "ProjectNode":
			tmpNode = searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "ProjectNode");
			break;
		case "InfrastructureNode":
			tmpNode = searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "InfrastructureNode");
			break;
		case "TrashNode":
			tmpNode = searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "TrashNode");
			break;
		case "LimboNode":
			tmpNode = searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "LimboNode");
			break;
		default:
			throw new Error(_type + " is not a unique DataNodeType.");
	}

	return tmpNode;
}

/**
 * Creates a unique 4-letter-ID (out of 358.800 permutations)
 * @returns {string} UUID
 */
export function createUUID() {
	let text;
	let unique;
	const possible = "abcdefghijklmnopqrstuvwxyz";

	do {
		text = "";
		unique = true;
		for (let i = 0; i < 4; i++) {
			// create random 3 letter string
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", text)) {
			unique = false;
		}
	} while (!unique);

	return text;
}

/**
 * Adds a dataNode entry to dataStructure.nodeList
 * @param {DataNode} _dataNode to add to the list
 * @throws exception providing additional information on error
 */
export function addDataNodeToNodeList(_dataNode) {
	if (!searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _dataNode.UUID)) {
		dataRoot.nodeList.push(_dataNode);
	} else {
		throw new Error('DataNode with ID "' + _dataNode.UUID + '" already exists in NodeList.');
	}
}

/**
 * Removes a dataNode entry from dataStructure.nodeList
 * @param {DataNode} _dataNode entry to remove
 * @throws exception providing additional information on error
 */
export function removeDataNodeFromNodeList(_dataNode) {
	checkDataNodeExistence(_dataNode);

	dataRoot.nodeList.splice(dataRoot.nodeList.indexOf(searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _dataNode.UUID)), 1);
}

/**
 * Pushes raw sourceData from ServerRequest to dataRoot
 * @param {object} _sourceData to store in dataRoot
 */
export function addSourceDataToDataRoot(_sourceData) {
	dataRoot.sourceData.push(_sourceData);
}

/**
 * Fetches raw deviceData from dataRoot.sourceData
 * @param {number} _databaseId representing a sourceDevice
 * @returns {sourceDevice} with matching _databaseId
 */
export function getDeviceSourceDataByDatabaseId(_databaseId) {
	//REFACTOR consolidate with getConnectionSourceDataByDatabaseId (see below) to getSourceDataByDatabaseId(sourceGroup, id)
	let result = false;
	dataRoot.sourceData.forEach((e) => {
		if (e.databaseId === _databaseId) result = e;
	});
	return cloneDeep(result);
}

/**
 * Fetches raw cableData from dataRoot.sourceData
 * @param {number} _databaseId representing a sourceCable
 * @returns {object} with matching _databaseId
 */
export function getConnectionSourceDataByDatabaseId(_databaseId) {
	//REFACTOR consolidate with getDeviceSourceDataByDatabaseId (see above) to getSourceDataByDatabaseId(sourceGroup, id)
	const cableSourceData = dataRoot.sourceData.find((sourceData) => sourceData.group === "cables" && sourceData.databaseId === _databaseId);

	const result = cableSourceData ? cloneDeep(cableSourceData) : false;
	return result;
}

/**
 * Fetches raw portData from dataRoot.portSourceData
 * @param {number} _databaseId representing a sourcePort
 * @returns {sourcePort} with matching _databaseId
 */
export function getPortSourceDataByDatabaseId(_databaseId) {
	const portSourceData = dataRoot.portSourceData.find((port) => port.databaseId === _databaseId);

	const result = portSourceData ? cloneDeep(portSourceData) : false;
	return result;
}

/**
 * Fetches raw interfaceData from dataRoot.interfaceSourceData
 * @param {number} _databaseId representing a sourceInterface
 * @returns {sourceInterface} with matching _databaseId
 */
export function getInterfaceSourceDataByDatabaseId(_databaseId) {
	const interfaceSourceData = dataRoot.interfaceSourceData.find((_interface) => _interface.databaseId === _databaseId);

	const result = interfaceSourceData ? cloneDeep(interfaceSourceData) : false;
	return result;
}

/**
 * Processes autoConfig response.
 * @param {object} _autoConfigResponse shape is documented in the API, but not final.
 */
export function processAutoConfigFRH(_autoConfigResponse) {
	GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", _autoConfigResponse.groupNodeUUID, true);

	const statusMessages = [];
	const autoConfigIndexToUUID = new Map();
	const tmpGroupNode = getDataNodeByUUID(_autoConfigResponse.groupNodeUUID);

	_autoConfigResponse.devices.forEach((device) => {
		if (device.existsOnClient) {
			autoConfigIndexToUUID.set(device.autoConfigIndex, device.uuid);
			return;
		}

		let tmpSourceData = __convertSourceDataNodeType(getDeviceSourceDataByDatabaseId(device.databaseId));
		tmpSourceData = __addPortsToSourceData(device.ports, tmpSourceData); // only relevant for cabinets

		const tmpDataNode = dataNodeFactory(tmpSourceData);
		tmpGroupNode.createChild(tmpDataNode);

		autoConfigIndexToUUID.set(device.autoConfigIndex, tmpDataNode.UUID);
	});

	GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", _autoConfigResponse.groupNodeUUID, false);

	_autoConfigResponse.connections.forEach((connectionData) => {
		const tmpTargetDevice = getDataNodeByUUID(autoConfigIndexToUUID.get(connectionData.targetPort.parentDeviceAutoConfigIndex));
		const tmpTargetPort = tmpTargetDevice.getPortByDbNumber(connectionData.targetPort.portIndexOnItsDevice);

		if (connectionData.status === "NO_SOURCE_FOUND") {
			statusMessages.push({logLevel: "ERROR", translationKey: "autoConfig.noSourceFound", variables: {targetPort: tmpTargetPort, targetDevice: tmpTargetDevice}});
			return;
		}
		const tmpSourceDevice = getDataNodeByUUID(autoConfigIndexToUUID.get(connectionData.sourcePort.parentDeviceAutoConfigIndex));
		const tmpSourcePort = tmpSourceDevice.getPortByDbNumber(connectionData.sourcePort.portIndexOnItsDevice);
		let connectionReference = connectionData.connection.databaseId;

		if (connectionReference === 0) {
			const sourceConnections = dataRoot.sourceData.filter((sourceElement) => sourceElement.group === "cables");
			connectionReference = createGenericElectricConnectionSourceData(sourceConnections, tmpSourcePort, tmpTargetPort);
			if (!connectionReference) {
				statusMessages.push({
					logLevel: "ERROR",
					translationKey: "autoConfig.noCableFound",
					variables: {targetPort: tmpTargetPort, targetDevice: tmpTargetDevice, sourcePort: tmpSourcePort, sourceDevice: tmpSourceDevice},
				});
				return;
			}
		}

		const tmpConnection = connectionFactory(connectionReference);
		tmpGroupNode.addConnection(tmpConnection, tmpSourcePort, tmpTargetPort);
	});

	layOutGroupNode(tmpGroupNode);
	GLOBALEVENTMANAGER.dispatch("eDTM_CanvasAssembled", tmpGroupNode.UUID);

	statusMessages.push({logLevel: "WARNING", translationKey: "autoConfig.completed"});
	statusMessages.forEach((message) => MESSENGER.post2statusbar(message.logLevel, message.translationKey, message.variables));

	/**
	 * Adds portData to sourceData (atm only relevant for cabinets).
	 * @param {Array} _ports portData from autoConfig response, empty for all devices apart from cabinet.
	 * @param {object} _sourceData sourceData of device.
	 * @returns {object} sourceData with filled ports array.
	 */
	function __addPortsToSourceData(_ports, _sourceData) {
		_ports.forEach((portData) => {
			// This line throws away server current calculation, because otherwise client calculation doubles the current values
			portData.interfaceList.forEach((_interface) => (_interface.current = 0));
			const tmpPortData = parseRESTPortData(portData); //? Why do we need extra parameters
			_sourceData.ports.push(tmpPortData);
		});
		return _sourceData;
	}

	/**
	 * Corrects a spelling error in sourceData.type. //TODO correct sourceData.
	 * @param {object} _sourceData sourceData entry.
	 * @returns {object} sourceData entry with corrected type.
	 */
	function __convertSourceDataNodeType(_sourceData) {
		if (_sourceData.type === "deviceNode") {
			_sourceData.nodeType = "DeviceNode";
		} else {
			_sourceData.nodeType = "UnitNode";
		}
		return _sourceData;
	}
}

/* ======================================== calculate currents ======================================== */
// TODO the calculation is not correct yet!!!
//! Don't rely on info from jsp, use the UUID and retrieve the data from dataNode/dataManager
/**
 * calculates currents and write them into port data
 * @param {object} _params from edgeAdded/edgeRemoved surface widget function
 * @param {boolean} _added - true if the edge added, false if removed
 */
export function recalculateCurrents(_params, _added) {
	// determine source/target side (jsp doesn't care for source/target anymore; we check this with the help of dataManager)
	const jspPort1 = _params.source;
	const jspPort2 = _params.target;
	const tmpPort1 = getPortByUUID(jspPort1.data.UUID);
	const tmpPort2 = getPortByUUID(jspPort2.data.UUID);

	const jspSourcePort = tmpPort1.side === "SOURCE" ? jspPort1 : jspPort2;
	const jspTargetPort = tmpPort2.side === "TARGET" ? jspPort2 : jspPort1;

	const sourcePort = getPortByUUID(jspSourcePort.data.UUID);
	const targetPort = getPortByUUID(jspTargetPort.data.UUID);

	const sourceInterfaces = sourcePort.interfaces;
	const targetInterfaces = targetPort.interfaces;

	const sourceArray = [];
	if (sourceInterfaces && targetInterfaces) {
		/* trouble with deleted assembly port */
		for (let i = 0; i < targetInterfaces.length; i++) {
			for (let j = 0; j < sourceInterfaces.length; j++) {
				if (sourceInterfaces[j].databaseId === targetInterfaces[i].databaseId) {
					if (_added) {
						// transmit the consumption currents to the source port
						sourceInterfaces[j].current += targetInterfaces[i].current;
					} else {
						sourceInterfaces[j].current -= targetInterfaces[i].current;
					}
					let sourceNodeHasConnectedTargetInterface = true;
					let sourceJSPNode = jspSourcePort.getNode();
					const sourceDataNode = getDataNodeByUUID(sourcePort.parentUUID);
					// if (sourceArray.includes(sourceJSPNode.data.UUID)) break;
					// else sourceArray.push(sourceJSPNode.data.UUID);
					if (!sourceArray.includes(sourceJSPNode.data.UUID)) sourceArray.push(sourceJSPNode.data.UUID);
					while (sourceNodeHasConnectedTargetInterface) {
						//  && counter < 10transmit the consumption current further to the source device target etc.
						const sourceJSPNodePorts = sourceJSPNode.getPorts();
						let myEdge;
						for (let k = 0; k < sourceJSPNodePorts.length; k++) {
							// iterate (target, not connected) ports for interface
							if (sourceJSPNodePorts[k].data.side === "TARGET") {
								// target side, check interfaces
								const nextTargetInterfaces = getPortByUUID(sourceJSPNodePorts[k].data.UUID).interfaces;
								for (let l = 0; l < nextTargetInterfaces.length; l++) {
									// iterate interfaces
									if (nextTargetInterfaces[l].databaseId === targetInterfaces[i].databaseId) {
										// interface found (simplificated)
										// TODO consider more the same interfaces on one device
										// TODO use interceptions for measure/control/24V // save interface index?
										if (_added) {
											// transmit the consumption currents to the source
											nextTargetInterfaces[l].current += targetInterfaces[i].current;
										} else {
											nextTargetInterfaces[l].current -= targetInterfaces[i].current;
										} // TODO use property Port.isConnected instead of getEdges.length, now is always false
										if (sourceDataNode.parentEventManager)
											sourceDataNode.parentEventManager.dispatch(
												"eDTM_InterfaceParameterChanged",
												sourceJSPNodePorts[k].data.UUID,
												nextTargetInterfaces[l].UUID,
												"current",
												nextTargetInterfaces[l].current,
											);
										if (sourceJSPNodePorts[k].getEdges().length === 0) {
											// port disconnected, end of the search for this interface
											sourceNodeHasConnectedTargetInterface = false;
											break;
										} else {
											// get the edge
											myEdge = sourceJSPNodePorts[k].getEdges()[0];
										}
									}
								}
							}
							if (!sourceNodeHasConnectedTargetInterface) break;
						}
						if (sourceNodeHasConnectedTargetInterface && myEdge) {
							// define new source & target ports/interfaces (through the edge)
							sourceJSPNode = myEdge.source.getNode();
							if (sourceArray.includes(sourceJSPNode.data.UUID)) break;
							else {
								sourceArray.push(sourceJSPNode.data.UUID);
								const nextSourceInterfaces = getPortByUUID(myEdge.source.data.UUID).isConnectedTo.interfaces;
								for (let m = 0; m < nextSourceInterfaces.length; m++) {
									if (nextSourceInterfaces[m].databaseId === targetInterfaces[i].databaseId) {
										// interface found (simplificated)
										if (_added) {
											// transmit the consumption currents to the source
											nextSourceInterfaces[m].current += targetInterfaces[i].current;
										} else {
											nextSourceInterfaces[m].current -= targetInterfaces[i].current;
										}
									}
								}
							}
						} else {
							// end of the cycle
							sourceNodeHasConnectedTargetInterface = false;
						}
					}
				}
			}
		}
	}
}

/**
 * Prepare autoConfig request data.
 * @param {object} _autoConfigParameters holds configuration parameters.
 * @param {DataNode} _autoConfigParameters.groupNode that should get configured.AssemblyNode.
 * @param {number} _autoConfigParameters.configLevel value 1 for primary control level, value 2 for control and distribution level.
 * @param {Array<string>} _autoConfigParameters.fieldBusPreferences ordered array with field bus types.
 * @param {Array<string>} _autoConfigParameters.systemBusPreferences ordered array with system bus types.
 * @param {boolean|null} _autoConfigParameters.createCabinet indicates whether a user likes to have a cabinet or not, null for primary control level.
 * @param {boolean|null} _autoConfigParameters.configureRibbonAdapter indicates whether a user likes to have ribbon adapters or not, null for distribution level.
 */
export function eAutoConfig(_autoConfigParameters) {
	MESSENGER.post2statusbar("WARNING", "autoConfig.started");

	const tmpGroupNode = _autoConfigParameters.groupNode;
	delete _autoConfigParameters.groupNode;
	tmpGroupNode.removeConfiguration();
	const tmpAutoConfigData = tmpGroupNode.getAutoConfigData();

	const startConfData = {...tmpAutoConfigData, ..._autoConfigParameters};

	startConfData.deviceList.forEach((_device) => {
		if (_device.type.isConsumer && _device.type.isMotor)
			_device.decentralControl = _device.decentralControl === null ? _autoConfigParameters.preferDecentralMotors : _device.decentralControl;

		if (_device.type.isConsumer && !_device.type.isMotor)
			_device.decentralControl = _device.decentralControl === null ? _autoConfigParameters.preferDecentralConsumers : _device.decentralControl;
	});

	// cleanup helpers
	delete startConfData.type;
	delete startConfData.preferDecentralMotors;
	delete startConfData.preferDecentralConsumers;

	const tmpConfigData = JSON.stringify(startConfData, null, "\t");

	requestAutoConfig(tmpConfigData);
}
