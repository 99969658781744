//TODO post2errorbar does nothing atm (remove the warning on the function once this is fixed)
//TODO how to restrict logLevels for errorbar to ERROR & WARNING only? (remove the warning on the function once this is fixed)

//BUG post2window will fail, even for simple strings, when i18next is not initialized; depends on loading order in applicationManager (remove the warning on the function once this is fixed)
//BUG type defs are not even remotely correct...


import {getTranslation} from						"./localization/localizationManager";
import type {Messenger} from						"./GUI/statusbar/Messenger";


//? ################################ Dear future me. Please forgive me. I can't even express how sorry I am. ################################

/** temporary helper for type definition, not sure yet how to do it correctly */
enum LogLevel {
	NORMAL,
	WARNING,
	ERROR,
}

/** temporary helper for type definition, not sure yet how to do it correctly */
export type LogLevels = keyof typeof LogLevel;

/**
 * Temporary helper for type definition, not sure yet how to do it correctly.
 * Type definition for i18next translationKeys.
 * Could be useful to sometimes differentiate translationKeys from strings.
 * @see https://www.i18next.com/translation-function/essentials
 */
export type TranslationKey = string;


/**
 * Temporary helper for type definition, not sure yet how to do it correctly.
 * Interface for i18next interpolation parameters.
 * @see https://www.i18next.com/translation-function/interpolation
 */
export interface TranslationVariables {			// https://stackoverflow.com/a/36467564/1054981
	// no clue how to define a variable number of properties
	interpolation?: {escapeValue: boolean};
}

//? ##########################################################################################################################################


/**
 * Class wrapping functions for posting messages to different parts of the application.
 */
export class MessageService {
	private static instance: MessageService;	// singleton helper
	private readonly statusbarMessenger: Messenger;

	/**
	 * Creates a new instance of MessageService.
	 * @param {Messenger} _statusbarMessenger to send statusbar messages to
	 */
	constructor(_statusbarMessenger: Messenger) {
		// Prevent multiple instances
		if (MessageService.instance) throw new Error("Singleton classes can't be instantiated more than once.");
		MessageService.instance = this;

		this.statusbarMessenger = _statusbarMessenger;

		Object.seal(this);
	}

	/**
	 * Clears all permanent MessageService components (e.g. statusbarMessenger).
	 * Useful when creating a new project.
	 */
	public clear(): void {
		this.statusbarMessenger.clear();
	}

	/**
	 * Posts a (localized) message to statusbar.messenger.
	 * @param {messagePriorityEnum} _level  of message
	 * @param {string|TranslationKey} _message to post
	 * @param {TranslationVariables} [_messageVariables] optional for i18next interpolation
	 */
	public post2statusbar(_level: LogLevels, _message: string|TranslationKey, _messageVariables?: TranslationVariables): void {
		this.statusbarMessenger.display(_level, _message, _messageVariables);
	}

	/**
	 * Posts a (localized) message to errorbar.
	 * @warning Since errorbar is used to display errors|warnings only, don't post logLevel.NORMAL!					//TODO find a way to restrict the interface
	 * @todo Does nothing atm.
	 * @param {LogLevel.WARNING|LogLevel.ERROR} _level  of message
	 * @param {string|TranslationKey} _message to post
	 * @param {TranslationVariables} _messageVariables optional for i18next interpolation
	 */
	public post2errorbar(_level: LogLevel.WARNING | LogLevel.ERROR, _message: TranslationKey, _messageVariables?: TranslationVariables): void {
		// eslint-disable-next-line no-console
		console.error("NOT YET IMPLEMENTED: ", _level, _message, _messageVariables);
	}

	/**
	 * Posts a (localized) message to window.alert().
	 * @warning Use sparingly, only when absolutely needed!
	 * @warning Will fail if i18next is not initialized!																										//TODO find a solution (make localizationManager a singleton like User.js)
	 * @param {string|TranslationKey} _message to post
	 * @param {TranslationVariables} [_messageVariables] optional for i18next interpolation
	 */
	public post2window(_message: string|TranslationKey, _messageVariables?: TranslationVariables): void {
		window.alert(getTranslation(_message, _messageVariables));
	}
}
