import {languageEnum} from										"../constantsAndEnumerations";
import numeral from "numeral";
import "numeral/locales";


/** Wrapper around numeral.js
 * @export
 * @class NumberFormatter
 */
export class NumberFormatter {
	/** Creates an instance of NumberFormatter.
	 * @memberof NumberFormatter
	 */
	constructor() {
		// Prevent multiple instances
		if (NumberFormatter.__instance) throw new Error("Singleton classes can't be instantiated more than once.");
		NumberFormatter.__instance = this;
		this.__formatStrings = {
			floatNumber: "0.[00]",
			materialNumberLength: "00000",
		};

		this.__overwriteNumeralFunction();
		numeral.locale(this.__getBrowserLocale());
	}

	/** Returns the browser locale or the fallback locale
	 * @memberof NumberFormatter
	 * @returns {string} browser or fallback locale
	 */
	__getBrowserLocale() {
		const tmpLocale = navigator.language.toLowerCase();
		const tmpLocaleShort = tmpLocale.split(/-|_/)[0];

		if (this.locales[tmpLocale]) return tmpLocale;
		if (this.locales[tmpLocaleShort]) return tmpLocaleShort;
		return languageEnum.en.type;
	}

	/** Overwrites some numeral functionality that is causing trouble
	 * @memberof NumberFormatter
	 */
	__overwriteNumeralFunction() {
		numeral._.stringToNumber = function(string) {
			const locale = numeral.locales[numeral.options.currentLocale];
			const stringOriginal = string;
			const abbreviations = {
				thousand: 3,
				million: 6,
				billion: 9,
				trillion: 12,
			};
			let abbreviation;
			let value;
			let i;
			let regexp;

			if (numeral.options.zeroFormat && string === numeral.options.zeroFormat) {
				value = 0;
			} else if (numeral.options.nullFormat && string === numeral.options.nullFormat || !string.replace(/[^0-9]+/g, "").length) {
				value = null;
			} else {
				value = 1;
				// string = string.split(locale.delimiters.thousands).join(""); // SBI: remove thousand delimeters
				if (locale.delimiters.decimal !== ".") {
					// string = string.replace(/\./g, '').replace(locale.delimiters.decimal, '.');
					string = string.replace(/\./g, "a").replace(locale.delimiters.decimal, "."); // SBI: 'a' instead of '' to make it unvalid
					// string = string.replace(locale.delimiters.decimal, '.');    // SBI: alternative, if we let also "." as decimal delimeter
				}

				/* for (abbreviation in abbreviations) {	// SBI commented to escape eslint error since we do not use abbreviations
				regexp = new RegExp("[^a-zA-Z]" + locale.abbreviations[abbreviation] + "(?:\\)|(\\" + locale.currency.symbol + ")?(?:\\))?)?$");

				if (stringOriginal.match(regexp)) {
					value *= Math.pow(10, abbreviations[abbreviation]);
					break;
				}
			} */

				// check for negative number
				value *= (string.split("-").length + Math.min(string.split("(").length - 1, string.split(")").length - 1)) % 2 ? 1 : -1;
				// remove non numbers
				// string = string.replace(/[^0-9\.]+/g, '');   // SBI: we do not need it

				value *= Number(string);
			}

			return value;
		};
	}

	/** sets current locale
	 * @memberof NumberFormatter
	 * @param {string} _locale to set
	 */
	set locale(_locale) {
		numeral.locale(_locale);
	}

	/** gets current locale
	 * @memberof NumberFormatter
	 * @returns {string} locale
	 */
	get locale() {
		return numeral.locale();
	}

	/** gets all numeral locales
	 * @memberof NumberFormatter
	 * @returns {string} locale
	 */
	get locales() {
		return numeral.locales;
	};

	/** converts number to string using the float format
	 * @param {number} _number to convert
	 * @returns {string} converted number
	 * @memberof NumberFormatter
	 */
	number2string(_number) {
		return numeral(_number).format(this.__formatStrings.floatNumber);
	};

	/** converts cable length as a number to the length as a part of mat. number
	 * @param {number} _length to convert
	 * @returns {string} converted length
	 * @memberof NumberFormatter
	 */
	number2matNrLength(_length) {
		return numeral(_length/10).format(this.__formatStrings.materialNumberLength);
	};

	/** converts a number typed by user to the real number
	 * @param {string} _number as a string from dialog
	 * @returns {number} number
	 * @memberof NumberFormatter
	 */
	string2number(_number) {
		return numeral(_number).value();
	};

	/** gets decimal delimiter for cable wires as 4x2,5+2x1,5 //REFACTOR cable wires later
	 * @returns {string} decimal delimiter
	 * @memberof NumberFormatter
	 */
	getDecimalDelimiter() {
		return numeral.locales[numeral.locale()].delimiters.decimal;
	};
}
