import React, {Suspense} from     "react";
import CssBaseline from           "@mui/material/CssBaseline";
import {ThemeProvider} from       "@mui/material/styles";
import MenuBar from                "../menubar/menuBar";
import {theme} from                "./theme";

/**
 * React main widget
 * @returns {React.ReactElement} menu bar
 */
function App(): React.ReactElement {
	return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense>
        <MenuBar />
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
