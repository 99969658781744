import {GLOBALEVENTMANAGER} from								"./applicationManager";
import {AccordionCollapsible} from							"./collapsible";
import {checkExistenceNew} from									"./helper";
import {checkUniqueness} from										"./helper";
import {getDomElementById} from									"./helper";
import {bindDomElementToTranslateEvent} from		"./localization/localizationManager";

/** Container for collapsible elements
 * Vanilla-JS implementation as a replacement for jquery-accordion
 */
export class AccordionNew {
	/** Standard constructor
	 * @param  {String} _parentId of DOM element to nest into (usually sidePanelLeft)
	 * @param  {String} _id of this accordion
	 * @param  {i18nKey} _captionKey of this accordion
	 */
	constructor(_parentId, _id, _captionKey) {
		this.parentId = checkExistenceNew(_parentId);
		this.id = checkUniqueness(_id);
		this.captionKey = _captionKey;
		this.collapsibles = [];
		this.domContainer = {};
		this.contentContainer = {};
		this.createDomStructure();
	}

	/** Creates the necessary dom element for this accordion */
	createDomStructure() {
		this.domContainer = document.createElement("div");
		this.domContainer.id = this.id;
		this.domContainer.classList.add("accordionNew");
		getDomElementById(this.parentId).appendChild(this.domContainer);

		const headingContainer = document.createElement("div");
		headingContainer.classList.add("accordionNew-heading", "noselect");
		bindDomElementToTranslateEvent(headingContainer, "textContent", this.captionKey);
		bindDomElementToTranslateEvent(headingContainer, "title", this.captionKey);
		this.domContainer.appendChild(headingContainer);

		this.contentContainer = document.createElement("div");
		this.contentContainer.classList.add("accordionNew-content");
		this.domContainer.appendChild(this.contentContainer);
	}

	/** Adds a collapsible to this accordion
	 * @param  {AccordionCollapsible} _collapsible to add
	 */
	addElement(_collapsible) {
		this.collapsibles.push(_collapsible);
		GLOBALEVENTMANAGER.addHandler(`collapsibleExpanded_${_collapsible.domContainer.id}`, (_collapsible) => this.collapseAllButActive(_collapsible));
		this.contentContainer.appendChild(_collapsible.domContainer);
	}

	/** Handles collapsibleExpanded event
	 * Collapses all other children of this accordion except the collapsible that was expanded last
	 * @param  {AccordionCollapsible} _collapsible that was expanded (provided as argument of event)
	 */
	collapseAllButActive(_collapsible) {
		// fetch the collapsibles that don't have id=_collapsible.id (we could additionally check for expanded=true to narrow the result but that would bind collapsible to close to accordion -> the performance impact an closing all elements is negligible atm)
		const tmpCollapsibles = this.collapsibles.filter(function(element) {return element.id !== _collapsible.id;});
		tmpCollapsibles.forEach((element) => {
			element.setExpanded(false);
		});
	}

	/** Returns a collapsible of this accordion
	 * @param  {String} _id of collapsible to find
	 * @returns {AccordionCollapsible|false} with matching id or false if none was found
	 */
	getElementById(_id) {
		let result = false;
		this.collapsibles.forEach((element) => {
			if (element.id === _id) result = element;
		});
		return result;
	}
}
