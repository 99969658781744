import {DragListFiller} from "./dragListFiller";
import "./dragListControl.css";


/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} ModalDialog
 */
/* eslint-enable jsdoc/require-property */

/**
 * Represents an unsorted list of draggable listItems.
 * Used in conjunction with ListItemBase.
 * @class DragListControl
 */
export class DragListControl {
	/**
	 * Creates an instance of DragListControl.
	 * @param {string} _ulId id to be assigned to the DragListControl.
	 * @param {HTMLElement} _parentContainer container to embed DragListControl into.
	 * @param {Function} _callback function
	 * @param {Array} [_dragItemList = []] listItems to be inserted on DragListControl creation.
	 */
	constructor(_ulId, _parentContainer, _callback, _dragItemList = []) {
		this.__id = _ulId;
		this.__callback = _callback;
		this.__validated = true;
		this.__focusable = false;
		this.__event = `${this.__id}_ValueChanged`;
		this.__parentDialog = null;
		this.__dragItem = null;
		this.__unsortedList = this.__createDefaultDomStructure(_parentContainer);
		this.__filler = new DragListFiller();

		this.__addEventListenersFiller(this.__filler);
		_dragItemList.forEach((_listItem) => {
			this.addItem(_listItem);
		});
	}

	/**
	 * Returns callback function.
	 * @returns {Function} callback
	 */
	get callBack() {
		return this.__callback;
	}

	/**
	 * Returns all DragItems payloads.
	 * @returns {Array} all payloads
	 */
	get value() {
		const value = [];
		[...this.__unsortedList.children].forEach((listItem) => {
			if (!listItem.querySelector("[data-payload]")) return;
			const payload = listItem.querySelector("[data-payload]").dataset.payload;
			value.push(payload);
		});
		return value;
	}

	/**
	 * Getter for this.__id.
	 * @readonly
	 * @returns {string} id.
	 */
	get id() {
		return this.__id;
	}

	/**
	 * Getter for this.__validated.
	 * @readonly
	 * @returns {boolean} validation status.
	 */
	get validated() {
		return this.__validated;
	}

	/**
	 * Getter for this.__focusable
	 * @readonly
	 * @returns {boolean} states, whether control is focusable or not.
	 */
	get focusable() {
		return this.__focusable;
	}

	/**
	 * Getter for this.__parentDialog.
	 * @returns {ModalDialog} parentDialog.
	 */
	get parentDialog() {
		return this.__parentDialog;
	}

	/**
	 * Setter for this.__parentDialog.
	 * @param {ModalDialog} _parentDialog Dialog this element lies in.
	 */
	set parentDialog(_parentDialog) {
		this.__parentDialog = _parentDialog;
	}

	/**
	 * Returns class name.
	 * @returns {string} Name of the class.
	 */
	getType() {
		return "DragListControl";
	}

	/**
	 * Returns the name of the event this elements uses to submit data.
	 * @returns {string} EventSignature.
	 */
	getEvent() {
		return this.__event;
	}

	/**
	 * empty filler.
	 */
	setEventHandler() {

	}

	/**
	 * empty filler
	 */
	focus() {
	}

	/** run this elements callback function */
	executeCallBack() {
		if (this.callBack == null) return;
		this.callBack(this.value);
	}

	/**
	 * Creates an ul-element.
	 * @param {HTMLElement} _container parent-element to nest into
	 * @returns {HTMLElement} ul-element
	 */
	__createDefaultDomStructure(_container) {
		const tmpDiv = document.createElement("div");
		tmpDiv.classList.add("container");
		tmpDiv.setAttribute("id", `${this.__id}-container`);
		tmpDiv.innerHTML = `<ul class="dragListControl" id="${this.__id}"></ul>`;

		document.getElementById(_container).appendChild(tmpDiv);
		return tmpDiv.querySelector(`#${this.__id}`);
	}

	/**
	 * Adds a ListItem to the DragListControl.
	 * @param {HTMLElement} _listItem ListItem to add
	 */
	addItem(_listItem) {
		const newPayload = _listItem.querySelector("[data-payload]").dataset.payload;
		if (this.__unsortedList.querySelector(`[data-payload= ${newPayload}] `)) return;

		this.__addEventListeners(_listItem);
		this.__unsortedList.appendChild(_listItem);
	}

	/**
	 * Adds EventListeners to parts of given listItem.
	 * @param {HTMLElement} _listItem listItem to add EventListeners to.
	 */
	__addEventListeners(_listItem) {
		this.__addEventListenersListItem(_listItem);
		this.__addEventListenersGhostDivTop(_listItem.querySelector("#js-ghostDivTop"));
		this.__addEventListenersGhostDivBottom(_listItem.querySelector("#js-ghostDivBottom"));
	}

	/**
	 * Adds EventListeners to a new ListItem.
	 * @param {HTMLElement} _listItem listItem that was added.
	 */
	__addEventListenersListItem(_listItem) {
		_listItem.addEventListener("dragstart",	(e) => this.__setDragCursor(e));
		_listItem.addEventListener("dragstart",	(e) => this.__dragStartHandler(e.currentTarget));
		_listItem.addEventListener("dragend",		(e) => this.__dragEndHandler(e.currentTarget));
	}

	/**
	 * Adds EventListeners to the ghost div.
	 * @param {HTMLElement} _div transparent div on the top half of the listItem.
	 */
	__addEventListenersGhostDivTop(_div) {
		_div.addEventListener("dragenter",	(e) => this.__dragEnterHandlerGhostDivTop(e.currentTarget));
	}

	/**
	 * Adds EventListeners to the ghost div.
	 * @param {HTMLElement} _div transparent div on the bottom half of the listItem.
	 */
	__addEventListenersGhostDivBottom(_div) {
		_div.addEventListener("dragenter",	(e) => this.__dragEnterHandlerGhostDivBottom(e.currentTarget));
	}

	/**
	 * Adds EventListeners to given ListItem.
	 * @param {HTMLElement} _listItem Filler to add EventListeners to.
	 */
	__addEventListenersFiller(_listItem) {
		_listItem.addEventListener("drop",			() => this.__dropHandler());
		_listItem.addEventListener("dragover",	(e) => this.__dragOverHandler(e));													// needed to allow dropping onto objects, see https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
	}

	/**
	 * Sets the drag cursor to 'move', overwriting the default 'copy' cursor.
	 * @param {Event} _event dom dragstart event
	 * @listens document#dragstart
	 */
	__setDragCursor(_event) {
		_event.dataTransfer.effectAllowed = "move";
	}

	/**
	 * Callback for dragstart-Event.
	 * @param {HTMLElement} _dragItem ListItem that gets dragged.
	 * @listens document#dragstart
	 */
	__dragStartHandler(_dragItem) {
		this.__dragItem = _dragItem;
		this.__dragItem.querySelector(".dragListItem").classList.add("selected");

		this.__dragItem.querySelector("#js-ghostDivBottom").setAttribute("data-blocked", "true");
		this.__dragItem.querySelector("#js-ghostDivTop").setAttribute("data-blocked", "true");

		this.__dragItem.nextSibling?.querySelector("#js-ghostDivTop").setAttribute("data-blocked", "true");
		this.__dragItem.previousSibling?.querySelector("#js-ghostDivBottom").setAttribute("data-blocked", "true");
	}

	/**
	 * Callback for dragend-Event.
	 * @listens document#dragend
	 */
	__dragEndHandler() {
		this.__dragItem.querySelector(".dragListItem").classList.remove("selected");
		this.__dragItem = null;
		this.__unsortedList.querySelectorAll("[data-blocked]").forEach((overlayDiv) => overlayDiv.setAttribute("data-blocked", "false"));
		if (this.__unsortedList.contains(this.__filler)) this.__unsortedList.removeChild(this.__filler);
	}

	/**
	 * Callback for dragenter-Event on ghostDivTop.
	 * @param {HTMLElement} _dropItem ghostDivTop.
	 * @listens document#dragenter
	 */
	__dragEnterHandlerGhostDivTop(_dropItem) {
		if (!this.__dragItem) return;
		if (_dropItem.dataset.blocked === "true") return;
		this.__unsortedList.insertBefore(this.__filler, _dropItem.parentNode);
	}

	/**
	 * Callback for dragenter-Event on ghostDivBottom.
	 * @param {HTMLElement} _dropItem ghostDivBottom.
	 * @listens document#dragenter
	 */
	__dragEnterHandlerGhostDivBottom(_dropItem) {
		if (!this.__dragItem) return;
		if (_dropItem.dataset.blocked === "true") return;
		this.__unsortedList.insertBefore(this.__filler, _dropItem.parentNode.nextSibling);
	}

	/**
	 * Callback for dragover-Event.
	 * @param {Event} _event dom dragover event
	 * @listens document#dragover
	 */
	__dragOverHandler(_event) {
		if (this.__dragItem) _event.preventDefault();
	}

	/**
	 * Callback for drop-Event.
	 * @listens document#drop
	 */
	__dropHandler() {
		this.__unsortedList.insertBefore(this.__dragItem, this.__filler);
	}
}
