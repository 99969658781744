import {initializeOutliner} from												"./GUI/outliner/outliner";
import {AccordionNew} from															"./accordionNew";
import {GLOBALEVENTMANAGER} from												"./applicationManager";
// import {applicationRoot} from														"./applicationManager";
import {USER} from																			"./applicationManager";
import {AccordionCollapsible} from											"./collapsible";
import {CollapsibleAccordionItem} from									"./collapsibleItem";
import {CollapsibleLayerControlItemCheckbox} from				"./collapsibleItem";
import {CollapsibleLayerControlItemSlider} from					"./collapsibleItem";
import {accordionLayoutEnum} from												"./constantsAndEnumerations";
import {drawModeEnum} from															"./constantsAndEnumerations";
import {languageEnum} from															"./constantsAndEnumerations";
import {dataRoot} from																	"./dataManager";
import {cId2jqSel} from																	"./helper";
import {LayerController} from														"./layerController";
import {bindDomElementToTranslateEvent} from						"./localization/localizationManager";
import {getTranslation} from														"./localization/localizationManager";
import {SplashScreen} from															"./splashscreen";
import {createLanguageSwitcher} from										"./statusBar";
import {addElementToLanguageSwitcher} from							"./statusBar";
import {updateLanguageSwitcherIcon} from								"./statusBar";

import chevronLeft from																	"../images/sidePanelWidgetIcons/chevronLeft.svg";
import chevronRight from																"../images/sidePanelWidgetIcons/chevronRight.svg";
import toggleRefDesNameIcon from												"../images/sidePanelWidgetIcons/toggleRefDesName.svg";
import toggleCollapseIcon from													"../images/sidePanelWidgetIcons/toggleCollapse.svg";
import newAssemblyIcon from															"../images/sidePanelWidgetIcons/newAssembly.svg";

import $ from "jquery";
const jQuery = $;


export const guiRoot = {
	activeAccordionLayout: null,
};

export let layerControllerNew;
export let splashscreen;

// WEBPACK: OBSOLETE DUE TO ES6 IMPORTS
export const defaultIconPath = {
	statusBar: "client/images/statusbarIcons/",
	outliner: "client/images/outlinerIcons/",
	sidePanelWidgets: "client/images/sidePanelWidgetIcons/",
};


/** standard initialization routine (adding buttons, setting event handlers) */
export function initializeGuiManager(_user) {
	createStatusBar();
	createSidePanelWidgets();
	createAccordionsNew();
	initializeOutliner();
	createLayerController();
	setColorScheme(_user.colorScheme);
	// initializePanelButtons();	// temporary disabled until working flawlessly
}

/**
 * Sets the users preferred color scheme on startup.
 * @param {colorSchemeEnum.type} _colorScheme to set
 */
function setColorScheme(_colorScheme) {
	document.documentElement.className = _colorScheme.type.toLowerCase();
}

/** Standard initialization routine (mainly setting event handlers etc..) */
function createStatusBar() {
	createLanguageSwitcher("statusPanel", "languageSwitcher");

	Object.values(languageEnum).forEach((language) => {
		addElementToLanguageSwitcher(language);
	});

	GLOBALEVENTMANAGER.addHandler("eTM_Translate", updateLanguageSwitcherIcon);
}


/** Creates Accordions and collapsibles for each device group / subgroup */
function createAccordionsNew() {
	const tmpGroups = [...new Set(dataRoot.sourceData.map((element) => element.group))];																		// gather all unique groups of dataRoot.sourceData
	tmpGroups.splice(tmpGroups.indexOf("cables"), 1);																																				// ! remove cable group to prevent automatic creation of cable accordion

	tmpGroups.forEach((group) => {
		const tmpAccordion = new AccordionNew("sidePanelLeft", `accordion-${group}`, `accordionManager.defaultHeading-${group}`);						// create accordion for each group

		const tmpGroupElements = dataRoot.sourceData.filter((element) => element.group === group && element.isArchived !== true);			// filter out all archived elements

		const tmpSubGroups = [...new Set(tmpGroupElements.map((x) => x.subGroup))];																						// gather all unique subGroups of this groups elements

		tmpSubGroups.forEach((subGroup) => {
			const tmpCollapsible = new AccordionCollapsible(`${group}_${subGroup}`, subGroup, USER.accordionLayout);						// create collapsible for each subgroup

			const tmpSubGroupElements = tmpGroupElements.filter((element) => {																									// gather all elements of this subGroup
				return element.subGroup === subGroup;
			});

			tmpSubGroupElements.forEach((element) => {																																					// add collapsibleAccordionItems for each element of this subGroup to collapsible
				tmpCollapsible.addItem(new CollapsibleAccordionItem(element));
			});

			tmpAccordion.addElement(tmpCollapsible);																																						// add collapsible to accordion
		});
	});
}

/**
 * @export
 * @param {Boolean} _mode return value of calling menuSwitch
 */
export function toggleDrawModeHandler(_mode) {
	const tmpMode = (_mode) ? drawModeEnum.SYMBOL : drawModeEnum.IMAGE;		// convert boolean return value from menuSwitch to drawModeEnum
	GLOBALEVENTMANAGER.dispatch("eGUI_ToggleDrawMode", tmpMode);
}

/**
 * Updates the toggleDrawModeButton when drawMode has been changed in user settings dialog
 * @param {drawModeEnum} _mode to set button to1
 */
export function updateToggleDrawModeButton(_mode) {
	GLOBALEVENTMANAGER.dispatch("eGUI_ToggleDrawMode", _mode);
}


/**
  Central handler for changing the active accordion layout
 */
export function setAccordionLayout() {
	document.getElementById("sidePanelLayoutButton").src = USER.accordionLayout.widgetIcon;		// update widget icon
	GLOBALEVENTMANAGER.dispatch("eGUI_ToggleCollapsibleLayout", USER.accordionLayout);
}


/** creates two widgets on the top of the left and right panels */
function createSidePanelWidgets() {
	$("#sidePanelRight").mouseenter((e) => {
		GLOBALEVENTMANAGER.dispatch("eGUI_MouseEnterPanel", e.target, "right", $("#sidePanelRight").find("#sidePanelRightButton"), $("#sidePanelRight"));
	});

	$("#sidePanelRight").mouseleave((e) => {
		GLOBALEVENTMANAGER.dispatch("eGUI_MouseLeavePanel", e.target, "right", $("#sidePanelRight").find("#sidePanelRightButton"), $("#sidePanelRight"));
	});

	$("#sidePanelLeft").mouseenter((e) => {
		GLOBALEVENTMANAGER.dispatch("eGUI_MouseEnterPanel", e.target, "left", $("#sidePanelLeft").find("#sidePanelLeftButton"), $("#sidePanelLeft"));
	});

	$("#sidePanelLeft").mouseleave((e) => {
		GLOBALEVENTMANAGER.dispatch("eGUI_MouseLeavePanel", e.target, "left", $("#sidePanelLeft").find("#sidePanelLeftButton"), $("#sidePanelLeft"));
	});


	$("<div>", {
		id: "sidePanelWidgetLeft",
		class: "sidePanel-widget noselect",
	}).appendTo(cId2jqSel("sidePanelLeft"));

	$("<img>", {																						// toggleFoldPanel
		id: "sidePanelLeftButton",
		class: "sidePanel-widget-image opened",
		title: "translation pending",
		src: chevronLeft,
		click: function() {
			GLOBALEVENTMANAGER.dispatch("eGUI_ClickSidePanelButton", "left", $(this), $(this).parent().parent());
		},
	}).appendTo(cId2jqSel("sidePanelWidgetLeft"));
	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {													// hook title attribute to eTM_Translate event
		cId2jqSel("sidePanelLeftButton").attr("title", getTranslation("sidePanels.togglePanel-tt"));				// update title on event
	});

	const tmpToggleAccordionLayout = document.createElement("IMG");
	tmpToggleAccordionLayout.id = "sidePanelLayoutButton";
	tmpToggleAccordionLayout.classList.add("sidePanel-widget-image");
	tmpToggleAccordionLayout.src = USER.accordionLayout.widgetIcon;
	bindDomElementToTranslateEvent(tmpToggleAccordionLayout, "title", "sidePanels.toggleLayout-tt");
	tmpToggleAccordionLayout.addEventListener("click", () => {
		if (USER.accordionLayout == accordionLayoutEnum.LIST) {
			USER.accordionLayout = accordionLayoutEnum.TILE;
		} else {
			USER.accordionLayout = accordionLayoutEnum.LIST;
		}
		tmpToggleAccordionLayout.src = USER.accordionLayout.widgetIcon;
		GLOBALEVENTMANAGER.dispatch("eGUI_ToggleCollapsibleLayout", USER.accordionLayout);
	});
	document.getElementById("sidePanelWidgetLeft").appendChild(tmpToggleAccordionLayout);


	const tmpSearch = document.createElement("input");																		// search field
	tmpSearch.id = "sidePanelSearchField";
	tmpSearch.type = "search";
	tmpSearch.classList.add("sidePanel-widget-search");
	tmpSearch.spellcheck = false;
	tmpSearch.autocomplete = "off";
	bindDomElementToTranslateEvent(tmpSearch, "placeholder", "sidePanels.searchPlaceHolder");
	bindDomElementToTranslateEvent(tmpSearch, "title", "sidePanels.search-tt");
	tmpSearch.addEventListener("input", () => {
		let query = [];
		if (tmpSearch.value.length > 2) {																									// only fill query if three or more characters are entered (prevents to many results in double character queries - like "ab" or "11")
			query = tmpSearch.value.trim().split(/\s+/);																		// split tmpSearch.value at whitespace and push the parts in query
		}
		GLOBALEVENTMANAGER.dispatch("eGUI_SearchForAccordionItem", query);
	});
	document.getElementById("sidePanelWidgetLeft").appendChild(tmpSearch);


	$("<div>", {																						// creates a div for the accordion shell inside the parent container
		id: "sidePanelWidgetRight",
		class: "sidePanel-widget noselect",
	}).appendTo(cId2jqSel("sidePanelRight"));

	$("<img>", {																						// toggleFoldPanel
		id: "sidePanelRightButton",
		class: "sidePanel-widget-image opened",
		title: "translation pending",
		src: chevronRight,
		click: function() {
			GLOBALEVENTMANAGER.dispatch("eGUI_ClickSidePanelButton", "right", $(this), $(this).parent().parent());
		},
	}).appendTo(cId2jqSel("sidePanelWidgetRight"));
	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {													// hook title attribute to eTM_Translate event
		cId2jqSel("sidePanelRightButton").attr("title", getTranslation("sidePanels.togglePanel-tt"));				// update title on event
	});

	$("<img>", {																						// toggleReferenceDesignatorName
		id: "sidePanelLabelToggle",
		class: "sidePanel-widget-image",
		src: toggleRefDesNameIcon,
		title: "translation pending",
		click: () => GLOBALEVENTMANAGER.dispatch("eGUI_ToggleOutlinerLabels"),
	}).appendTo(cId2jqSel("sidePanelWidgetRight"));
	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {													// hook title attribute to eTM_Translate event
		cId2jqSel("sidePanelLabelToggle").attr("title", getTranslation("sidePanels.toggleLabels-tt"));			// update title on event
	});

	$("<img>", {																						// toggleCollapseButton
		id: "sidePanelCollapseToggle",
		class: "sidePanel-widget-image",
		src: toggleCollapseIcon,
		title: "translation pending",
		click: () => GLOBALEVENTMANAGER.dispatch("eGUI_ToggleCollapseOutliner"),
	}).appendTo(cId2jqSel("sidePanelWidgetRight"));
	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {													// hook title attribute to eTM_Translate event
		cId2jqSel("sidePanelCollapseToggle").attr("title", getTranslation("sidePanels.toggleCollapse-tt"));		// update title on event
	});

	$("<img>", {																						// newAssemblyButton
		id: "sidePanelNewAssembly",
		class: "sidePanel-widget-image",
		src: newAssemblyIcon,
		title: "translation pending",
		click: () => GLOBALEVENTMANAGER.dispatch("eGUI_NewAssemblySpecial"),
	}).appendTo(cId2jqSel("sidePanelWidgetRight"));
	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {													// hook title attribute to eTM_Translate event
		cId2jqSel("sidePanelNewAssembly").attr("title", getTranslation("sidePanels.newAssembly-tt"));		// update title on event
	});
}


/**
 *
 *
 */
function createLayerController() {
	layerControllerNew = new LayerController("sidePanelRight");
	layerControllerNew.addElement(new CollapsibleLayerControlItemCheckbox("checkbox_signatureName", "layerController.name", [".signature-name-element"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemCheckbox("checkbox_signatureReferenceDesignator", "layerController.reference-designator", [".signature-reference-designator-element"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemSlider("slider_cables24V", "layerController.24VCable", [".electric-edge-energy-dc"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemSlider("slider_cables400V", "layerController.400VCable", [".electric-edge-energy-ac"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemSlider("slider_cablesData", "layerController.dataCable", [".electric-edge-data-asi", ".electric-edge-data-iolink"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemSlider("slider_cables24V400V", "layerController.400V24VCable", [".electric-edge-energy-servo"]));
	layerControllerNew.addElement(new CollapsibleLayerControlItemSlider("slider_signal", "layerController.signalCable", [".electric-edge-measure-digital"]));
}

/**
 * Creates a new splashScreen instance
 * @param {boolean} [_forceSplash=false] optional parameter to force splashScreen despite of user settings [defaults to false]
 */
export function createSplashScreen(_forceSplash = false) {
	splashscreen = new SplashScreen(_forceSplash);
}
