import {applicationRoot}							from "./applicationManager";
import {GLOBALEVENTMANAGER}						from "./applicationManager";
import {USER}													from "./applicationManager";
import {requestEnum}									from "./communication/communicationManagerLegacy";
import {getUniqueDataNodeByType}			from "./dataManager";
import {getDataNodeByUUID}						from "./dataManager";
import {getConnectionByUUID}					from "./dataManager";
import {compareObjectProperty}				from "./helper";
import {unixTime2localTime}						from "./helper";
import {getTranslation}								from "./localization/localizationManager";

import saveAs from "file-saver/dist/FileSaver.min";
import $ from "jquery";
import isObject from "lodash.isobject";
import transform from "lodash.transform";
import XLSX from "xlsx/dist/xlsx.full.min";
import xmlConverter from "xml-js";
// WEBPACK local import jQuery


const jQuery = $;

/**
 * Handling partlist display and export functionality
 *
 * UTILIZING:
 *		FileSaver.js to provide a client-side file download, details under https://github.com/eligrey/FileSaver.js
 *		xml-js to provide xml export, details under https://www.npmjs.com/package/xml-js
 *		SheetJs js-xlsx to provide a xls/xlsx/csv export, details under https://www.npmjs.com/package/xlsx
 *		lodash for replacing keys (translation) in partsLists, details under https://lodash.com/
 *
 * TODO:
 *		Remove the need for sending form posts to shop, replace by ajax calls that enable us to get a response from the shop
 *		How to safely identify the tab of the shopping car for different languages?
 *			(When sending a post to the shop, a new tab is opening if none was existing before, otherwise the already opened tab is used.
 *			Right now this tab is identified by its caption. That caption though most likely changes for different languages...)
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/* Enumeration of different file types available for download from client (atm only for partslists)
 *		List of MIME types https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 */
export const fileTypeEnum = {
	CSV:	{extension: "csv",	caption: "modalDialog.partsList.format-CSV",		encoder: (tmp) => encodeCSV(tmp),		mimeType: "text/csv"},
	JSON:	{extension: "json",	caption: "modalDialog.partsList.format-JSON",	encoder: (tmp) => encodeJSON(tmp),	mimeType: "application/json"},
	// XLSX:	{extension: "xlsx",	caption: "Excel", encoder: (tmp) => encodeXLSX(tmp),	mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
	XLSX:	{extension: "xlsx",	caption: "modalDialog.partsList.format-Excel",	encoder: (tmp) => encodeXLSX(tmp),	mimeType: "application/octet-stream"},
	XML:	{extension: "xml",	caption: "modalDialog.partsList.format-XML",		encoder: (tmp) => encodeXML(tmp),		mimeType: "application/xml"},
};

// Central, top most partlist parent
const partListRoot = {
	project: null,
	infrastructure: null,
	trash: null,
	limbo: null,
};


/** Standard initialization routine */
export function initializePartListManager() {
	partListRoot.root = new RootPart();
	window.partListRoot = partListRoot;

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeCreated", eAddPart);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRelocated", eRelocatePart);
	GLOBALEVENTMANAGER.addHandler("eDTM_CreateConnection", eCreateCablePart);
	GLOBALEVENTMANAGER.addHandler("eDTM_RemoveConnection", eRemoveCablePart);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", eRemovePart);
}


/**
 * Wrapper for eDTM_DataNodeCreated event; Creates a new part in partListRoot (most times under partListRoot.root since nearly all dataNodes get created under root and then moved to their parent)
 * @param  {DataNode} _dataNode that got created
 */
export function eAddPart(_dataNode) {
	switch (_dataNode.nodeType) {
		case "ProjectNode":
			partListRoot.project = new ProjectPart(_dataNode);
			return;
		case "InfrastructureNode":
			partListRoot.infrastructure = new InfrastructurePart(_dataNode);
			return;
		case "TrashNode":
			partListRoot.trash = new TrashPart(_dataNode);
			return;
		case "LimboNode":
			partListRoot.limbo = new LimboPart(_dataNode);
			return;
		case "AssemblyNode":
			partListRoot.root.addChild(new AssemblyPart(_dataNode));
			break;
		case "UnitNode":
			partListRoot.root.addChild(new DevicePart(_dataNode));
			break;
		case "DeviceNode":
			partListRoot.root.addChild(new DevicePart(_dataNode));
			break;
		default:
			throw new Error(`Type "${_dataNode.nodeType}" not recognized`);
	}
}

/**
 * Wrapper for eDTM_DataNodeSendToLimbo event; Removes a part from partListRoot
 * @param  {DataNode} _dataNode that got removed
 */
export function eRemovePart(_dataNode) {
	getPartByUUID(getPartByUUID(_dataNode.UUID).parentUUID).removeChild(getPartByUUID(_dataNode.UUID));
}

/**
 * Wrapper for  eDTM_DataNodeRelocated event; Moves a part within the partListRoot tree
 * @param  {string} _targetParentPartUUID new groupPart to move to
 * @param  {string} _partUUID	part to move to parent with _targetParentPartUUID
 */
export function eRelocatePart(_targetParentPartUUID, _partUUID) {
	const tmpPart = getPartByUUID(_partUUID);
	const tmpSourceParent = getPartByUUID(tmpPart.parentUUID);
	const tmpTargetParent = getPartByUUID(_targetParentPartUUID);
	tmpPart.parentUUID = _targetParentPartUUID;
	tmpSourceParent.removeChild(tmpPart);
	tmpTargetParent.addChild(tmpPart);
}


/**
 * Wrapper for eDTM_CreateConnection event; Adds a cable to partListRoot
 * @param  {Cable} _connection to add
 * @listens eDTM_CreateConnection
 */
export function eCreateCablePart(_connection) {
	getPartByUUID(_connection.parent.UUID).addCable(new CablePart(_connection));
}

/**
 * Wrapper for eDTM_RemoveConnection event; Removes a cable from partListRoot
 * @param  {Cable} _connection to remove
 */
export function eRemoveCablePart(_connection) {
	const tmpCable = getCablePartByUUID(_connection.UUID);
	if (tmpCable) getPartByUUID(tmpCable.parentUUID).removeCable(tmpCable);				// ToDo eRemoveCablePart gets called several time due to bad event chaining... this should be refactored when working on port/connections
}


/** Base class a partList object */
class BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _dataNode that got created
	 */
	constructor(_dataNode) {
		this.UUID = _dataNode.UUID;
		this.parentUUID = _dataNode.parentUUID;
		this.children = [];
		this.cables = [];
	}

	/**
	 * Adds a childPart to this part
	 * @param  {Part} _child to add
	 */
	addChild(_child) {
		this.children.push(_child);
	}

	/**
	 * Removes a childPart from this part
	 * @param  {Part} _child to remove
	 */
	removeChild(_child) {
		this.children.splice(this.children.indexOf(_child), 1);
	}

	/**
	 * Adds a cable to this part
	 * @param  {Part} _cable to add
	 */
	addCable(_cable) {
		this.cables.push(_cable);
	}

	/**
	 * Removes cable childPart from this part
	 * @param  {Part} _cable to remove
	 */
	removeCable(_cable) {
		this.cables.splice(this.cables.indexOf(_cable), 1);
	}
}


/** Root specific implementation of BasePart class */
class RootPart {
	/** Standard constructor */
	constructor() {
		this.UUID = "root";
		this.children = [];
	}

	/**
	 * Adds a childPart to part root
	 * @param  {Part} _child to add
	 */
	addChild(_child) {
		this.children.push(_child);
	}

	/**
	 * Removes a childPart from part root
	 * @param  {Part} _child to remove
	 */
	removeChild(_child) {
		this.children.splice(this.children.indexOf(_child), 1);
	}
}


/** ProjectPart specific implementation of BasePart class */
class ProjectPart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _projectNode that got created
	 */
	constructor(_projectNode) {
		super(_projectNode);
	}

	/** Overwrite the baseclasses method*/
	addCable() {
		// ProjectParts don't have cables
	}

	/** Overwrite the baseclasses method*/
	removeCable() {
		// ProjectParts don't have cables
	}

	/**
	 * Returns this projects base information
	 * @returns {object} containing all relevant project info
	 */
	async getData() {
		const tmpProject = getUniqueDataNodeByType("ProjectNode");
		const tmpDates = await tmpProject.getDates();
		const tmpAssemblyList = [];

		this.children.forEach((tmpAssembly) => {
			tmpAssemblyList.push(tmpAssembly.getData());
		});

		sortAndUpdatePositions(tmpAssemblyList);

		return {
			project: tmpProject.getName(),
			description: (tmpProject.getDescription() !== "" && tmpProject.getDescription() !== null ? tmpProject.getDescription() : "-"),
			creator: USER.fullName,
			creationDate: (tmpDates.creationDate !== null ? unixTime2localTime(tmpDates.creationDate) : getTranslation("partListManager.unsavedProject")),
			modificationDate: (tmpDates.modificationDate !== null ? unixTime2localTime(tmpDates.modificationDate) : getTranslation("partListManager.unsavedProject")),
			assemblies: tmpAssemblyList,
			infrastructure: getPartByUUID(getUniqueDataNodeByType("InfrastructureNode").UUID).getData(),
		};
	}
}


/** InfrastructurePart specific implementation of BasePart class */
class InfrastructurePart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {InfrastructureNode} _infrastructureNode that got created
	 */
	constructor(_infrastructureNode) {
		super(_infrastructureNode);
	}

	/**
	 * Returns this assemblies base information
	 * @returns {object} containing all relevant assembly info
	 */
	getData() {
		const tmpInfrastructure = getDataNodeByUUID(this.UUID);
		const tmpConsumerList = [];
		const tmpFunctionList = [];
		this.children.forEach((tmpChild) => {
			if (getDataNodeByUUID(tmpChild.UUID).group === "consumers") tmpConsumerList.push(tmpChild.getData());
			if (getDataNodeByUUID(tmpChild.UUID).group === "functions") tmpFunctionList.push(tmpChild.getData());
		});

		sortAndUpdatePositions(tmpConsumerList);
		sortAndUpdatePositions(tmpFunctionList);

		const tmpCableList = [];
		this.cables.forEach((tmpCable) => {
			tmpCableList.push(tmpCable.getData());
		});

		sortAndUpdatePositions(tmpCableList);

		return {
			name: tmpInfrastructure.getName(),
			description: (tmpInfrastructure.getDescription() !== "" && tmpInfrastructure.getDescription() !== null ? tmpInfrastructure.getDescription() : "-"),
			referenceDesignator: tmpInfrastructure.referenceDesignator.getReferenceDesignator().string(),
			cables: tmpCableList,
			functions: tmpFunctionList,
			consumers: tmpConsumerList,
		};
	}
}

/** TrashPart specific implementation of BasePart class */
class TrashPart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _trashNode that got created
	 */
	constructor(_trashNode) {
		super(_trashNode);
	}

	/** Overwrite the baseclasses method*/
	addCable() {
		// TrashParts don't have cables
	}

	/** Overwrite the baseclasses method*/
	removeCable() {
		// TrashParts don't have cables
	}
}


/** LimboPart specific implementation of BasePart class */
class LimboPart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _limboNode that got created
	 */
	constructor(_limboNode) {
		super(_limboNode);
	}

	/** Overwrite the baseclasses method*/
	removeChild() {
		// nothing escapes from limbo
	}

	/** Overwrite the baseclasses method*/
	addCable() {
		// LimboParts don't have cables
	}

	/** Overwrite the baseclasses method*/
	removeCable() {
		// LimboParts don't have cables
	}
}


/** AssemblyPart specific implementation of BasePart class */
class AssemblyPart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _assemblyNode that got created
	 */
	constructor(_assemblyNode) {
		super(_assemblyNode);
	}

	/**
	 * Returns this assemblies base information
	 * @returns {object} containing all relevant assembly info
	 */
	getData() {
		const tmpAssembly = getDataNodeByUUID(this.UUID);
		const tmpConsumerList = [];
		const tmpFunctionList = [];
		this.children.forEach((tmpChild) => {
			if (getDataNodeByUUID(tmpChild.UUID).group === "consumers") tmpConsumerList.push(tmpChild.getData());
			if (getDataNodeByUUID(tmpChild.UUID).group === "functions") tmpFunctionList.push(tmpChild.getData());
		});

		sortAndUpdatePositions(tmpConsumerList);
		sortAndUpdatePositions(tmpFunctionList);

		const tmpCableList = [];
		this.cables.forEach((tmpCable) => {
			tmpCableList.push(tmpCable.getData());
		});

		sortAndUpdatePositions(tmpCableList);

		return {
			name: tmpAssembly.getName(),
			description: (tmpAssembly.getDescription() !== "" && tmpAssembly.getDescription() !== null ? tmpAssembly.getDescription() : "-"),
			referenceDesignator: tmpAssembly.referenceDesignator.getReferenceDesignator().string(),
			cables: tmpCableList,
			functions: tmpFunctionList,
			consumers: tmpConsumerList,
		};
	}
}

/** DevicePart specific implementation of BasePart class */
class DevicePart extends BasePart {
	/**
	 * Standard constructor
	 * @param  {DataNode} _deviceNode that got created
	 */
	constructor(_deviceNode) {
		super(_deviceNode);
		this.children = null;		// devices don't have children
	}

	/** Overwrite the baseclasses method*/
	addChild() {
		// DeviceParts don't have children
	}

	/** Overwrite the baseclasses method*/
	removeChild() {
		// DeviceParts don't have children
	}

	/** Overwrite the baseclasses method*/
	addCable() {
		// DeviceParts don't have cables
	}

	/** Overwrite the baseclasses method*/
	removeCable() {
		// DeviceParts don't have cables
	}

	/**
	 * Returns this deviceNodes/unitNodes base information
	 * @returns {object} containing all relevant node info
	 */
	getData() {
		const tmpDevice = getDataNodeByUUID(this.UUID);

		// common data all devices share, regardless of type (consumers, functions)
		const resultData = {
			name: tmpDevice.getName(),
			description: (tmpDevice.getDescription() !== "" && tmpDevice.getDescription() !== null ? tmpDevice.getDescription() : "-"),
			referenceDesignator: tmpDevice.referenceDesignator.getReferenceDesignator().string(),
			manufacturer: tmpDevice.manufacturer,
			materialNumber: "-",
			position: null,
		};

		// consumers and functions provide different data - therefore we have to differ what we return for each type
		if (getDataNodeByUUID(this.UUID).group === "consumers") {
			resultData.ports = [];					// hier name des ports und die interfaces mit Spannung strom und sage eigenschaft
			tmpDevice.ports.forEach((tmpPort) => {
				resultData.ports.push(parsePort(tmpPort));
			});
		} else if (getDataNodeByUUID(this.UUID).group === "functions") {
			resultData.materialNumber = tmpDevice.materialNumber;
		}

		return resultData;

		/**
		 * Local helper to extract port/interface data
		 * @param  {Port} _port to parse
		 * @returns {object} containing port/interface data
		 */
		function parsePort(_port) {
			const tmpPort = {
				type: _port.family,
				interfaces: [],
			};

			_port.interfaces.forEach((tmpInterface) => {
				tmpPort.interfaces.push({
					type: tmpInterface.name,
					voltage: tmpInterface.voltage,
					current: tmpInterface.current,
					safe: getTranslation(tmpInterface.isSafe.toString()),
				});
			});

			return tmpPort;
		}
	}
}


/** CablePart specific implementation of BasePart class */
class CablePart {
	/**
	 * Standard constructor
	 * @param  {Cable} _connection that got created
	 */
	constructor(_connection) {
		this.UUID = _connection.UUID;
		this.parentUUID = _connection.parent.UUID;
		this.children = null;
		this.cables = null;
	}

	/** Overwrite the baseclasses method*/
	addChild() {
		// CableParts don't have children
	}

	/** Overwrite the baseclasses method*/
	removeChild() {
		// CableParts don't have children
	}

	/** Overwrite the baseclasses method*/
	addCable() {
		// CableParts don't have cables
	}

	/** Overwrite the baseclasses method*/
	removeCable() {
		// CableParts don't have cables
	}

	/**
	 * Returns this cables base information
	 * @returns {object} containing all relevant cable info
	 */
	getData() {
		const tmpCable = getConnectionByUUID(this.UUID);
		return {
			name: tmpCable.name,									// ToDo connections right now don't differ between name and description
			manufacturer: tmpCable.manufacturer,
			materialNumber: tmpCable.materialNumber,
			// description: tmpCable.description,				// ToDo connections right now don't differ between name and description
			referenceDesignator: "-",
		};
	}
}


// ############################################################## EXPORT ##############################################################

/** Exports partList elements to shop */
export async function export2Shop() {
	const tmpProjectData = await partListRoot.project.getData();
	const tmpArticlesList = [];

	// get functions and cables from all assemblies
	tmpProjectData.assemblies.forEach((tmpAssembly) => {
		tmpAssembly.functions.forEach((tmpFunction) => {
			tmpArticlesList.push({materialNumber: tmpFunction.materialNumber, quantity: 1});
		});

		tmpAssembly.cables.forEach((tmpCable) => {
			if (tmpCable.materialNumber) {
				tmpArticlesList.push({materialNumber: tmpCable.materialNumber.replace("xxxxx", "00000"), quantity: 1});							// HACK replaces xxxxx with 00000 (the shop doesn't have *-xxxxx material numbers)
			} else {
				console.log("A cable without material number is excluded from the shopping cart!");
			}
		});
	});

	// get functions and cables from infrastructure
	tmpProjectData.infrastructure.functions.forEach((tmpFunction) => {
		tmpArticlesList.push({materialNumber: tmpFunction.materialNumber, quantity: 1});
	});

	tmpProjectData.infrastructure.cables.forEach((tmpCable) => {
		if (tmpCable.materialNumber) {
			tmpArticlesList.push({materialNumber: tmpCable.materialNumber.replace("xxxxx", "00000"), quantity: 1});								// HACK replaces xxxxx with 00000 (the shop doesn't have *-xxxxx material numbers)
		} else {
			console.log("A cable without material number is excluded from the shopping cart!");
		}
	});

	tmpArticlesList.sort(compareObjectProperty("materialNumber"));

	// prepare a pseudo form to send post to shop with (atm shop only accepts form posts)
	const tmpForm = document.createElement("form");
	tmpForm.method = requestEnum.ADD2SHOPPINGCART.method;
	tmpForm.target = "Warenkorb|LQ Shop DE";																																								// TODO find a way to safely identify this tab for different languages
	tmpForm.action = `${applicationRoot.shopURL}/CustomArticles/add`;

	tmpArticlesList.forEach((tmpArticle) => {
		const tmpArticleNumber = document.createElement("input");
		tmpArticleNumber.type = "hidden";
		tmpArticleNumber.name = `configurator__add[${tmpArticlesList.indexOf(tmpArticle)}][ordernumber]`;
		tmpArticleNumber.value = tmpArticle.materialNumber;

		const tmpArticleQuantity = document.createElement("input");
		tmpArticleQuantity.type = "hidden";
		tmpArticleQuantity.name = `configurator__add[${tmpArticlesList.indexOf(tmpArticle)}][quantity]`;
		tmpArticleQuantity.value = tmpArticle.quantity;

		tmpForm.appendChild(tmpArticleNumber);
		tmpForm.appendChild(tmpArticleQuantity);
	});

	document.body.appendChild(tmpForm);
	tmpForm.submit();
	document.body.removeChild(tmpForm);
}


/**
 * Exports a partslist as a file download
 * @param  {string} _filename to save partslist under
 * @param  {fileTypeEnum} _fileType to save partslist as
 */
export async function exportPartsListFile(_filename, _fileType) {
	// gather partList data
	const tmpProjectData = await partListRoot.project.getData();

	// translate partList
	const tmpTranslatedProjectData = translatePartsList(tmpProjectData);

	// encode partslist raw data to chosen filetype
	const tmpConvertedProjectData = _fileType.encoder(tmpTranslatedProjectData);

	// compose filename
	const tmpFilename = `${_filename}.${_fileType.extension}`;

	// construct file blob
	const tmpBlob = new Blob([tmpConvertedProjectData], {filetype: `${_fileType.mimeType};charset=utf-8`});

	// initiate download
	saveAs(tmpBlob, tmpFilename);
}

/**
 * Converts a raw (js Object) partslist or elements of it to JSON
 * @param  {object} _partsList to convert
 * @returns {object} consisting of JSON convert data and type file specifics
 */
const encodeJSON = (_partsList) => JSON.stringify(_partsList, null, 2);

/**
 * Converts a raw (js Object) partslist or elements of it to CSV
 * @param  {object} _partsList to convert
 * @returns {object} consisting of CSV convert data and type file specifics
 */
function encodeCSV(_partsList) {
	const tmpWorkSheet = XLSX.utils.aoa_to_sheet(createExcelSheet(_partsList));
	const tmpCSV = XLSX.utils.sheet_to_csv(tmpWorkSheet, {FS: ";"});		// ; is used as a separator (normally comma is used, but german formatted numbers have a comma as well)
	const output = tmpCSV.replace(/=/g, " =");													// prepend a whiteSpace to all equal signs (forces excel to interprete the cell as text and not a formula; the usual ' is not working as expected)
	return output;
}

/**
 * Converts a raw (js Object) partslist or elements of it to XML
 * @param  {object} _partsList to convert
 * @returns {object} consisting of XML convert data and type file specifics
 */
function encodeXML(_partsList) {
	const tmpPartsList = {};
	tmpPartsList[getTranslation("partListManager.partslist")] = _partsList;			// xml files need a single root node, here partslist (or it's translation is used)!

	return xmlConverter.js2xml(tmpPartsList, {compact: true, ignoreComment: true, spaces: 2});
}

/**
 * Converts a raw (js Object) partslist or elements of it to XLS (see https://docs.sheetjs.com/#sheetjs-js-xlsx for docs)
 * @param  {object} _partsList to convert
 * @returns {object} consisting of XML convert data and type file specifics
 */
function encodeXLSX(_partsList) {
	// .metaData[translateKey("project")]
	// .metaData[translateKey("creator")]
	// .metaData[translateKey("date")] =


	// create workBook
	const tmpWorkBook = XLSX.utils.book_new();

	// setup workBook details
	tmpWorkBook.props = {																																								// file properties shown in explorer/properties/details - they don't show though :(
		// Title: `${getTranslation("partListManager.partslist")}: ${tmpPartsList.metaData[translateKey("project")]}`,
		// Subject: `${getTranslation("partListManager.partslist")}: ${tmpPartsList.metaData[translateKey("project")]}`,
		// Author: tmpPartsList.metaData[translateKey("creator")],
		// CreatedDate: tmpPartsList.metaData[translateKey("date")],
	};

	// ! BUG: excel limitation to 31 characters as a sheet name (i18n key of projectName, 25 chars)
	// const tmpSheetName = `${getTranslation("partListManager.partslist")} ${_partsList[getTranslation("partListManager.project")]}`;
	const tmpSheetName = "checkSourceCode-pLM-965";

	tmpWorkBook.SheetNames.push(tmpSheetName);

	const tmpWorkSheet = XLSX.utils.aoa_to_sheet(createExcelSheet(_partsList));

	// add worksheet to workbook
	// XLSX.utils.book_append_sheet(tmpWorkBook, tmpWorkSheet, `${getTranslation("partListManager.partslist")}: ${tmpPartsList.metaData[translateKey("project")]}`);
	tmpWorkBook.Sheets[tmpSheetName] = tmpWorkSheet;


	const output = XLSX.write(tmpWorkBook, {bookType: "xlsx", type: "binary"});

	/**
	 * Converts excel output data to base64
	 * @param  {object} s excel raw data to convert
	 * @returns {object} converted excel data
	 */
	function correctFormat(s) {
		const buffer = new ArrayBuffer(s.length);
		const view = new Uint8Array(buffer);
		for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
		return buffer;
	}

	return correctFormat(output);
}


// ############################################################## HELEPRS ##############################################################

/**
 *	Searches partListRoot for a part by its UUID
 * @param  {string} _UUID of part to find
 * @returns {BasePart} with matching _UUID or false if no part was found
 */
function getPartByUUID(_UUID) {
	let result = false;
	Object.values(partListRoot).forEach((tmpGroupPart) => {
		if (tmpGroupPart.UUID === _UUID) result = tmpGroupPart;
		if (!result) checkChildren(tmpGroupPart);
	});
	return result;

	/**
	 * Local subroutine checking the children of a part for matching UUID
	 * @param  {Part} _parent to check for children with matchning _UUID
	 */
	function checkChildren(_parent) {
		if (_parent.children === null) return;
		Object.values(_parent.children).forEach((tmpChild) => {
			if (tmpChild.UUID === _UUID) result = tmpChild;
			if (!result) checkChildren(tmpChild);
		});
	}
}


/**
 *	Searches partListRoot for a cable by its UUID
 * @param  {string} _UUID of cable to find
 * @returns {CablePart} with matching _UUID or false if no cable was found
 */
function getCablePartByUUID(_UUID) {
	let result = false;
	// check infrastructure
	partListRoot.infrastructure.cables.forEach((tmpCable) => {
		if (tmpCable.UUID === _UUID) result = tmpCable;
	});

	if (!result) {
		// check all assemblies
		Object.values(partListRoot.project.children).forEach((tmpAssembly) => {
			tmpAssembly.cables.forEach((tmpCable) => {
				if (tmpCable.UUID === _UUID) result = tmpCable;
			});
		});
	}

	return result;
}


/**
 * Sorts an array by provided property and updates position numbers in relation to (new) sorting order
 * @param  {Array} _array to sort
 * @param  {string} [_sortingProperty="referenceDesignator"] to sort by
 * @param  {boolean} [_ascending=true] direction to sort for
 */
function sortAndUpdatePositions(_array, _sortingProperty = "referenceDesignator", _ascending = true) {
	_array.sort(compareObjectProperty(_sortingProperty, _ascending));
	for (let i = 0; i < _array.length; i++) {
		_array[i].position = i + 1;
	}
}

/**
 * Creates an excel worksheet
 * @param  {object} _partsList to data to process
 * @returns {object} excel worksheet
 */
function createExcelSheet(_partsList) {
	const defaultRow = ["", "", "", "", "", "", "", "", "", "", "", ""];
	const tmpWorkSheetData = [];

	pushProjectData();
	pushEmptyRow();
	pushAssembliesData();
	pushInfrastructureData();

	/** Appends an empty row  to excel worksheet */
	function pushEmptyRow() {
		tmpWorkSheetData.push(defaultRow);
	}

	/** Appends project data to excel worksheet */
	function pushProjectData() {
		const column = 0;

		// Project name
		const tmpProjectRow1 = [...defaultRow];
		tmpProjectRow1[column] = getTranslation("partListManager.project");
		tmpProjectRow1[column + 1] = _partsList[getTranslation("partListManager.project")];
		tmpWorkSheetData.push(tmpProjectRow1);

		// Project description
		const tmpProjectRow2 = [...defaultRow];
		tmpProjectRow2[column] = getTranslation("partListManager.description");
		tmpProjectRow2[column + 1] = _partsList[getTranslation("partListManager.description")];
		tmpWorkSheetData.push(tmpProjectRow2);

		// creator
		const tmpProjectRow3 = [...defaultRow];
		tmpProjectRow3[column] = getTranslation("partListManager.creator");
		tmpProjectRow3[column + 1] = _partsList[getTranslation("partListManager.creator")];
		tmpWorkSheetData.push(tmpProjectRow3);

		// creationDate
		const tmpProjectRow4 = [...defaultRow];
		tmpProjectRow4[column] = getTranslation("partListManager.creationDate");
		tmpProjectRow4[column + 1] = _partsList[getTranslation("partListManager.creationDate")];
		tmpWorkSheetData.push(tmpProjectRow4);

		// modificationDate
		const tmpProjectRow5 = [...defaultRow];
		tmpProjectRow5[column] = getTranslation("partListManager.modificationDate");
		tmpProjectRow5[column + 1] = _partsList[getTranslation("partListManager.modificationDate")];
		tmpWorkSheetData.push(tmpProjectRow5);
	}

	/** Appends assembly data to excel worksheet */
	function pushAssembliesData() {
		const column = 1;
		_partsList[getTranslation("partListManager.assemblies")].forEach((tmpAssembly) => {
			// Assembly name
			const tmpAssemblyRow1 = [...defaultRow];
			tmpAssemblyRow1[column] = getTranslation("partListManager.assembly");
			tmpAssemblyRow1[column + 1] = tmpAssembly[getTranslation("partListManager.name")];
			tmpWorkSheetData.push(tmpAssemblyRow1);

			// Assembly description
			const tmpAssemblyRow2 = [...defaultRow];
			tmpAssemblyRow2[column] = getTranslation("partListManager.description");
			tmpAssemblyRow2[column + 1] = tmpAssembly[getTranslation("partListManager.description")];
			tmpWorkSheetData.push(tmpAssemblyRow2);

			// Assembly reference Designator
			const tmpAssemblyRow3 = [...defaultRow];
			tmpAssemblyRow3[column] = getTranslation("partListManager.referenceDesignator");
			tmpAssemblyRow3[column + 1] = tmpAssembly[getTranslation("partListManager.referenceDesignator")];
			tmpWorkSheetData.push(tmpAssemblyRow3);

			// Assembly consumers if existing
			if (tmpAssembly[getTranslation("partListManager.consumers")].length > 0 ) {
				pushEmptyRow();
				// consumer topic
				const tmpAssemblyRow4 = [...defaultRow];
				tmpAssemblyRow4[column] = getTranslation("partListManager.consumers");
				tmpWorkSheetData.push(tmpAssemblyRow4);

				// set customer property headings
				const tmpConsumerHeadings = [...defaultRow];
				tmpConsumerHeadings[column] = getTranslation("partListManager.position");
				tmpConsumerHeadings[column + 1] = getTranslation("partListManager.name");
				tmpConsumerHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
				tmpConsumerHeadings[column + 3] = getTranslation("partListManager.manufacturer");
				tmpConsumerHeadings[column + 4] = getTranslation("partListManager.materialNumber");
				tmpConsumerHeadings[column + 5] = getTranslation("partListManager.ports");
				tmpConsumerHeadings[column + 6] = getTranslation("partListManager.interfaces");
				tmpConsumerHeadings[column + 7] = `${getTranslation("partListManager.voltage")} [V]`;
				tmpConsumerHeadings[column + 8] = `${getTranslation("partListManager.current")} [A]`;
				tmpConsumerHeadings[column + 9] = getTranslation("partListManager.safe");
				tmpWorkSheetData.push(tmpConsumerHeadings);

				tmpAssembly[getTranslation("partListManager.consumers")].forEach((tmpConsumer) => {
					// calculate number of rows neccessary for this tmpConsumer
					let totalRows = 0;
					tmpConsumer[getTranslation("partListManager.ports")].forEach((tmpPort) => {
						totalRows += tmpPort[getTranslation("partListManager.interfaces")].length;
					});

					// create customers
					pushConsumersData(tmpConsumer, totalRows, column);
				});
			}

			// Assembly functions if existing
			if (tmpAssembly[getTranslation("partListManager.functions")].length > 0 ) {
				pushEmptyRow();
				// function topic
				const tmpAssemblyRow5 = [...defaultRow];
				tmpAssemblyRow5[column] = getTranslation("partListManager.functions");
				tmpWorkSheetData.push(tmpAssemblyRow5);

				// set customer property headings
				const tmpFunctionHeadings = [...defaultRow];
				tmpFunctionHeadings[column] = getTranslation("partListManager.position");
				tmpFunctionHeadings[column + 1] = getTranslation("partListManager.name");
				tmpFunctionHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
				tmpFunctionHeadings[column + 3] = getTranslation("partListManager.manufacturer");
				tmpFunctionHeadings[column + 4] = getTranslation("partListManager.materialNumber");
				tmpWorkSheetData.push(tmpFunctionHeadings);

				tmpAssembly[getTranslation("partListManager.functions")].forEach((tmpFunction) => {
					pushFunctionsData(tmpFunction, column);
				});
			}

			// Assembly cables if existing
			if (tmpAssembly[getTranslation("partListManager.cables")].length > 0 ) {
				pushEmptyRow();
				// cable topic
				const tmpAssemblyRow6 = [...defaultRow];
				tmpAssemblyRow6[column] = getTranslation("partListManager.cables");
				tmpWorkSheetData.push(tmpAssemblyRow6);

				// set customer property headings
				const tmpCablesHeadings = [...defaultRow];
				tmpCablesHeadings[column] = getTranslation("partListManager.position");
				tmpCablesHeadings[column + 1] = getTranslation("partListManager.name");
				tmpCablesHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
				tmpCablesHeadings[column + 3] = getTranslation("partListManager.manufacturer");
				tmpCablesHeadings[column + 4] = getTranslation("partListManager.materialNumber");
				tmpWorkSheetData.push(tmpCablesHeadings);

				tmpAssembly[getTranslation("partListManager.cables")].forEach((tmpCable) => {
					pushCablesData(tmpCable, column);
				});
			}

			pushEmptyRow();
			pushEmptyRow();
		});
	}

	/** Appends infrastructure data to excel worksheet */
	function pushInfrastructureData() {
		const tmpInfrastructure = _partsList[getTranslation("partListManager.infrastructure")];
		const column = 1;

		// Infrastructure name
		const tmpInfrastructureRow1 = [...defaultRow];
		tmpInfrastructureRow1[column] = getTranslation("partListManager.infrastructure");
		tmpInfrastructureRow1[column + 1] = tmpInfrastructure[getTranslation("partListManager.name")];
		tmpWorkSheetData.push(tmpInfrastructureRow1);

		// Infrastructure description
		const tmpInfrastructureRow2 = [...defaultRow];
		tmpInfrastructureRow2[column] = getTranslation("partListManager.description");
		tmpInfrastructureRow2[column + 1] = tmpInfrastructure[getTranslation("partListManager.description")];
		tmpWorkSheetData.push(tmpInfrastructureRow2);

		// Infrastructure reference Designator
		const tmpInfrastructureRow3 = [...defaultRow];
		tmpInfrastructureRow3[column] = getTranslation("partListManager.referenceDesignator");
		tmpInfrastructureRow3[column + 1] = tmpInfrastructure[getTranslation("partListManager.referenceDesignator")];
		tmpWorkSheetData.push(tmpInfrastructureRow3);

		// Infrastructure consumers if existing
		if (tmpInfrastructure[getTranslation("partListManager.consumers")].length > 0 ) {
			pushEmptyRow();
			// consumer topic
			const tmpInfrastructureRow4 = [...defaultRow];
			tmpInfrastructureRow4[column] = getTranslation("partListManager.consumers");
			tmpWorkSheetData.push(tmpInfrastructureRow4);

			// set customer property headings
			const tmpConsumerHeadings = [...defaultRow];
			tmpConsumerHeadings[column] = getTranslation("partListManager.position");
			tmpConsumerHeadings[column + 1] = getTranslation("partListManager.name");
			tmpConsumerHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
			tmpConsumerHeadings[column + 3] = getTranslation("partListManager.manufacturer");
			tmpConsumerHeadings[column + 4] = getTranslation("partListManager.materialNumber");
			tmpConsumerHeadings[column + 5] = getTranslation("partListManager.ports");
			tmpConsumerHeadings[column + 6] = getTranslation("partListManager.interfaces");
			tmpConsumerHeadings[column + 7] = `${getTranslation("partListManager.voltage")} [V]`;
			tmpConsumerHeadings[column + 8] = `${getTranslation("partListManager.current")} [A]`;
			tmpConsumerHeadings[column + 9] = getTranslation("partListManager.safe");
			tmpWorkSheetData.push(tmpConsumerHeadings);

			tmpInfrastructure[getTranslation("partListManager.consumers")].forEach((tmpConsumer) => {
				// calculate number of rows neccessary for this tmpConsumer
				let totalRows = 0;
				tmpConsumer[getTranslation("partListManager.ports")].forEach((tmpPort) => {
					totalRows += tmpPort[getTranslation("partListManager.interfaces")].length;
				});

				// create customers
				pushConsumersData(tmpConsumer, totalRows, column);
			});
		}

		// Infrastructure functions if existing
		if (tmpInfrastructure[getTranslation("partListManager.functions")].length > 0 ) {
			pushEmptyRow();
			// function topic
			const tmpInfrastructureRow5 = [...defaultRow];
			tmpInfrastructureRow5[column] = getTranslation("partListManager.functions");
			tmpWorkSheetData.push(tmpInfrastructureRow5);

			// set customer property headings
			const tmpFunctionHeadings = [...defaultRow];
			tmpFunctionHeadings[column] = getTranslation("partListManager.position");
			tmpFunctionHeadings[column + 1] = getTranslation("partListManager.name");
			tmpFunctionHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
			tmpFunctionHeadings[column + 3] = getTranslation("partListManager.manufacturer");
			tmpFunctionHeadings[column + 4] = getTranslation("partListManager.materialNumber");
			tmpWorkSheetData.push(tmpFunctionHeadings);

			tmpInfrastructure[getTranslation("partListManager.functions")].forEach((tmpFunction) => {
				pushFunctionsData(tmpFunction, column);
			});
		}

		// Infrastructure cables if existing
		if (tmpInfrastructure[getTranslation("partListManager.cables")].length > 0 ) {
			pushEmptyRow();
			// cable topic
			const tmpInfrastructureRow6 = [...defaultRow];
			tmpInfrastructureRow6[column] = getTranslation("partListManager.cables");
			tmpWorkSheetData.push(tmpInfrastructureRow6);

			// set customer property headings
			const tmpCablesHeadings = [...defaultRow];
			tmpCablesHeadings[column] = getTranslation("partListManager.position");
			tmpCablesHeadings[column + 1] = getTranslation("partListManager.name");
			tmpCablesHeadings[column + 2] = getTranslation("partListManager.referenceDesignator");
			tmpCablesHeadings[column + 3] = getTranslation("partListManager.manufacturer");
			tmpCablesHeadings[column + 4] = getTranslation("partListManager.materialNumber");
			tmpWorkSheetData.push(tmpCablesHeadings);

			tmpInfrastructure[getTranslation("partListManager.cables")].forEach((tmpCable) => {
				pushCablesData(tmpCable, column);
			});
		}
	}

	/**
	 * Appends customer data to an assembly or infrastructure part of an excel worksheet
	 * @param  {object} _tmpConsumer to process
	 * @param  {number} _totalRows helper to determine number of rows necessary to show all interfaces of all ports
	 * @param  {number} _column helper to determine correct horizontal position of data
	 */
	function pushConsumersData(_tmpConsumer, _totalRows, _column) {
		// create temporary array with enough rows for all interfaces of this consumer
		const tmpConsumerArray = [];
		for (let i = 0; i < _totalRows; i++) {
			tmpConsumerArray.push([...defaultRow]);
		}

		// write base properties (without port- and interface data) to first row
		tmpConsumerArray[0][_column] = _tmpConsumer[getTranslation("partListManager.position")];
		tmpConsumerArray[0][_column + 1] = _tmpConsumer[getTranslation("partListManager.name")];
		tmpConsumerArray[0][_column + 2] = _tmpConsumer[getTranslation("partListManager.referenceDesignator")];
		tmpConsumerArray[0][_column + 3] = _tmpConsumer[getTranslation("partListManager.manufacturer")];
		tmpConsumerArray[0][_column + 4] = _tmpConsumer[getTranslation("partListManager.materialNumber")];
		tmpConsumerArray[0][_column + 5] = "port";
		tmpConsumerArray[0][_column + 6] = "interface";
		tmpConsumerArray[0][_column + 7] = "voltage";
		tmpConsumerArray[0][_column + 8] = "current";
		tmpConsumerArray[0][_column + 9] = "save";

		let tmpInterfaceRow = 0;
		_tmpConsumer[getTranslation("partListManager.ports")].forEach((tmpPort) => {
			tmpConsumerArray[tmpInterfaceRow][_column + 5] = tmpPort[getTranslation("partListManager.type")];
			tmpPort[getTranslation("partListManager.interfaces")].forEach((tmpInterface) => {
				tmpConsumerArray[tmpInterfaceRow][_column + 6] = tmpInterface[getTranslation("partListManager.type")];
				tmpConsumerArray[tmpInterfaceRow][_column + 7] = tmpInterface[getTranslation("partListManager.voltage")];
				tmpConsumerArray[tmpInterfaceRow][_column + 8] = tmpInterface[getTranslation("partListManager.current")];
				tmpConsumerArray[tmpInterfaceRow][_column + 9] = tmpInterface[getTranslation("partListManager.safe")];
				tmpInterfaceRow += 1;
			});
		});

		tmpConsumerArray.forEach((tmpRow) => {
			tmpWorkSheetData.push(tmpRow);
		});
	}

	/**
	 * Appends function data to an assembly or infrastructure part of an excel worksheet
	 * @param  {object} _tmpFunction to process
	 * @param  {number} _column helper to determine correct horizontal position of data
	 */
	function pushFunctionsData(_tmpFunction, _column) {
		const tmpFunctionRow = [...defaultRow];
		tmpFunctionRow[_column] = _tmpFunction[getTranslation("partListManager.position")];
		tmpFunctionRow[_column + 1] = _tmpFunction[getTranslation("partListManager.name")];
		tmpFunctionRow[_column + 2] = _tmpFunction[getTranslation("partListManager.referenceDesignator")];
		tmpFunctionRow[_column + 3] = _tmpFunction[getTranslation("partListManager.manufacturer")];
		tmpFunctionRow[_column + 4] = _tmpFunction[getTranslation("partListManager.materialNumber")];

		tmpWorkSheetData.push(tmpFunctionRow);
	}

	/**
	 * Appends cable data to an assembly or infrastructure part of an excel worksheet
	 * @param  {object} _tmpCable to process
	 * @param  {number} _column helper to determine correct horizontal position of data
	 */
	function pushCablesData(_tmpCable, _column) {
		const tmpCableRow = [...defaultRow];
		tmpCableRow[_column] = _tmpCable[getTranslation("partListManager.position")];
		tmpCableRow[_column + 1] = _tmpCable[getTranslation("partListManager.name")];
		tmpCableRow[_column + 2] = _tmpCable[getTranslation("partListManager.referenceDesignator")];
		tmpCableRow[_column + 3] = _tmpCable[getTranslation("partListManager.manufacturer")];
		tmpCableRow[_column + 4] = _tmpCable[getTranslation("partListManager.materialNumber")];

		tmpWorkSheetData.push(tmpCableRow);
	}

	return tmpWorkSheetData;
}


// #####################################

/**
 * @param  {object} _partsList to translate
 * @returns {object} translated partsList
 * taken from https://stackoverflow.com/a/39126851
 */
function translatePartsList(_partsList) {
	const keysMap = {
		partsList: getTranslation("partListManager.partslist"),
		name: getTranslation("partListManager.name"),
		project: getTranslation("partListManager.project"),
		description: getTranslation("partListManager.description"),
		creator: getTranslation("partListManager.creator"),
		creationDate: getTranslation("partListManager.creationDate"),
		modificationDate: getTranslation("partListManager.modificationDate"),
		assemblies: getTranslation("partListManager.assemblies"),
		infrastructure: getTranslation("partListManager.infrastructure"),
		referenceDesignator: getTranslation("partListManager.referenceDesignator"),
		cables: getTranslation("partListManager.cables"),
		functions: getTranslation("partListManager.functions"),
		consumers: getTranslation("partListManager.consumers"),
		manufacturer: getTranslation("partListManager.manufacturer"),
		materialNumber: getTranslation("partListManager.materialNumber"),
		position: getTranslation("partListManager.position"),
		ports: getTranslation("partListManager.ports"),
		interfaces: getTranslation("partListManager.interfaces"),
		type: getTranslation("partListManager.type"),
		voltage: getTranslation("partListManager.voltage"),
		current: getTranslation("partListManager.current"),
		safe: getTranslation("partListManager.safe"),
	};

	return transform(_partsList, (result, value, key) => {				// transform to a new object
		const currentKey = keysMap[key] || key; 													// if the key is in keysMap use the replacement, if not use the original key
		result[currentKey] = isObject(value) ? translatePartsList(value, keysMap) : value; // if the key is an object run it through the inner function - replaceKeys
	});
}


// ############################################################## TESTS FOR SENDING POST TO SHOP VIA AJAX ##############################################################

/**
 * Test to send a post to the shop via ajax
 * @param {Array} _articlesList to push to shopping cart
 */
function postAjaxTest(_articlesList) {
	console.log("xxx", _articlesList);
	const tmpForm = document.createElement("form");
	tmpForm.method = requestEnum.ADD2SHOPPINGCART.method;
	tmpForm.target = "Warenkorb|LQ Shop DE";
	tmpForm.action = requestEnum.ADD2SHOPPINGCART.url;


	_articlesList.forEach((tmpArticle) => {
		const tmpArticleNumber = document.createElement("input");
		tmpArticleNumber.type = "hidden";
		tmpArticleNumber.name = `configurator__add[${_articlesList.indexOf(tmpArticle)}][ordernumber]`;
		tmpArticleNumber.value = tmpArticle.materialNumber;

		const tmpArticleQuantity = document.createElement("input");
		tmpArticleQuantity.type = "hidden";
		tmpArticleQuantity.name = `configurator__add[${_articlesList.indexOf(tmpArticle)}][quantity]`;
		tmpArticleQuantity.value = tmpArticle.quantity;

		tmpForm.appendChild(tmpArticleNumber);
		tmpForm.appendChild(tmpArticleQuantity);
	});

	document.body.appendChild(tmpForm);
	const testData = $("form").serialize();
	$.post("https://shop.lq-test-systemplattform.com/CustomArticles/add", testData);
	tmpForm.submit();
	document.body.removeChild(tmpForm);
}
