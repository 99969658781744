/* eslint-disable require-jsdoc */


import type {ConnectionAutoCheckConditions}			from "./CheckerAndFilterForCableDialogSBI";
import type {CheckerAndFilterForCableDialog}		from "./CheckerAndFilterForCableDialogSBI";
import type {ElectricConnectionSourceData}	from "../connections/electricConnection.schema";

//! In the end, all compatible cables must be sorted by some algorithm

/**
 * Argument for FilterCompatibleConnections.
 * @property {boolean} debugMode - turn chatty log on/off
 */
export interface FilterCompatibleConnectionsConditions {
	debugMode: boolean;
}

export class FilterCompatibleConnections {
	private readonly sourceConnections: Array<ElectricConnectionSourceData>;
	private readonly compatibleConnections: Array<ElectricConnectionSourceData>;
	public readonly conditions: FilterCompatibleConnectionsConditions = {
		debugMode: false,
	};

	constructor(_sourceConnections: Array<ElectricConnectionSourceData>, _connectionCompatibilityChecker: CheckerAndFilterForCableDialog, _options?: Partial<FilterCompatibleConnectionsConditions>) {
		this.conditions = _connectionCompatibilityChecker.conditions; // {...this.conditions, ..._options};

		this.sourceConnections = _sourceConnections;

		this.compatibleConnections = this.filterConnections(_connectionCompatibilityChecker);

		if (this.conditions.debugMode) this.logResult(_connectionCompatibilityChecker.conditions);

		Object.seal(this);
	}

	private filterConnections(_connectionCompatibilityChecker: CheckerAndFilterForCableDialog): Array<ElectricConnectionSourceData> {
		return this.sourceConnections ? this.sourceConnections.filter((connection) => _connectionCompatibilityChecker.autoFilter(connection)) : [];
	}

	private logResult(_connectionAutoCheckConditions: ConnectionAutoCheckConditions): void {
		/* eslint-disable no-console */
		console.group(`FilterCompatibleConnections`);
		console.log("Conditions");
		console.log("  forceShielded           ", _connectionAutoCheckConditions.forceShielded);
		console.log("  forceDynamicApplication ", _connectionAutoCheckConditions.forceDynamicApplication);
		console.log("  forceSourcePortLayout   ", _connectionAutoCheckConditions.forceSourcePortLayout);
		console.log("  forceTargetPortLayout   ", _connectionAutoCheckConditions.forceTargetPortLayout);

		console.log("");

		console.group(`CompatibleConnections [${this.compatibleConnections.length}]`);
		if (this.compatibleConnections.length === 0 ) console.error("No compatible Connections found");
		this.compatibleConnections.forEach((connection) => {
			console.group(`${connection.name} [${connection.databaseId}]`);
			console.log("shielded           ", connection.isShielded);
			console.log("dynamicApplication ", connection.dynamicApplication);
			console.log("leads              ", connection.leads);

			console.group(`Port0: ${connection.ports[0].family} | ${connection.ports[0].gender}`);
			console.log("shielded ", connection.ports[0].isShielded);
			console.log("layout   ", connection.ports[0].layout);
			console.log("leads    ", connection.ports[0].leads);
			console.groupEnd();

			console.group(`Port1: ${connection.ports[1].family} | ${connection.ports[1].gender}`);
			console.log("shielded ", connection.ports[1].isShielded);
			console.log("layout   ", connection.ports[1].layout);
			console.log("leads    ", connection.ports[1].leads);
			console.groupEnd();
			console.log("");

			console.groupEnd();
		});
		console.groupEnd();

		console.groupEnd();
		/* eslint-enable no-console */
	}

	/**
	 * Returns the result of all checks.
	 * @returns {Array<ElectricConnectionSourceData>|false} All connections within sourceData that are compatible to provided ports or false.
	 */
	get result(): Array<ElectricConnectionSourceData>|false {
		return (this.compatibleConnections.length > 0) ? this.compatibleConnections : false;
	}
}
