//JIRA [LQSK-1405] Statusbar LanguageSwitcher implementieren


/* eslint-disable require-jsdoc */
import flag_DE from								"@images/statusbarIcons/flag_DE.svg";			//! don't import the files here, hand them over when adding a language
import flag_ZH from								"@images/statusbarIcons/flag_ZH.svg";
import flag_EN from								"@images/statusbarIcons/flag_EN.svg";
import type {EventManager} from		"../../eventManager";
import														"./languageSwitcher.css";


export class LanguageSwitcher {
	private static instance: LanguageSwitcher;
	private globalEventManager: EventManager;
	private id = "languageSwitcher";
	private HTML: HTMLUListElement

	constructor(_parentContainer: HTMLDivElement, _globalEventManager: EventManager) {
		// Prevent multiple instances
		if (LanguageSwitcher.instance) throw new Error("Singleton classes can't be instantiated more than once.");
		LanguageSwitcher.instance = this;

		this.globalEventManager = _globalEventManager;
		this.HTML = this.createDOM(_parentContainer);

		//! adding a language should be triggered by localizationManager

		Object.seal(this);
	}

	private createDOM(_parentContainer: HTMLElement): HTMLUListElement {
		const tmpUnorderedList = document.createElement("ul");
		tmpUnorderedList.id = this.id;
		tmpUnorderedList.classList.add("languageSwitcher-unOrderedList", "noselect");
		_parentContainer.appendChild(tmpUnorderedList);

		tmpUnorderedList.addEventListener("click",	() => this.HTML.classList.toggle("extended"));

		return tmpUnorderedList;
	}

	public addLanguage(_language) {
		const tmpLI = document.createElement("li");
		tmpLI.classList.add();

		tmpLI.innerHTML = `<img class="languageSwitcher-image" data-payload=${"_payload.id"} src= ${_language.flag}>`;

		this.HTML.appendChild(tmpLI);
	}
}
