import {GLOBALEVENTMANAGER} from "./applicationManager";
import {drawModeEnum} from "./constantsAndEnumerations";

let activeDrawMode = drawModeEnum.SYMBOL; // initialized to something; applicationManager is overwriting this via eGUI_ToggleDrawMode event later during startup

/**
 * Hooks the graphics factories to eGUI_ToggleDrawMode event
 */
export function initializeGraphics() {
	GLOBALEVENTMANAGER.addHandler("eGUI_ToggleDrawMode", (e) => {
		activeDrawMode = e;
	});
}

// TODO: I'm not sure if graphics should hold the tooltip (If you decide to remove it, don't forget the baseData templates!)

/**
 * Class representing a dataNodes graphics object
 * It's recommended that you use the provided nodeGraphicsFactory to instantiate new NodeGraphics objects.
 * @class NodeGraphics
 */
export class NodeGraphics {
	/**
	 * Creates an instance of NodeGraphics
	 * @param {object} _image component
	 * @param {object} _symbol component
	 * @param {object} _icon component
	 * @param {drawModeEnum} _initialDrawMode to set
	 * @param {object} _position to set, format: {x, y}
	 * @param {object} _layout to display, format: {pan: {x, y}, zoom}
	 * @param {string} _tooltip to display
	 */
	constructor(_image, _symbol, _icon, _initialDrawMode, _position, _layout, _tooltip) {
		this.__image = new NodeGraphicsElement(_image.file, _image.width, _image.height);
		this.__symbol = new NodeGraphicsElement(_symbol.file, _symbol.width, _symbol.height);
		this.__icon = new NodeGraphicsElement(_icon.file, _icon.width, _icon.height);

		this.__position = _position;

		this.__drawMode = _initialDrawMode;
		this.__tooltip = _tooltip;

		this.__layout = _layout;

		GLOBALEVENTMANAGER.addHandler("eGUI_ToggleDrawMode", (_drawMode) => {
			this.__drawModeChangeHandler(_drawMode);
		});
	}

	/**
	 * Returns symbol/image component of this NodeGraphics object depending on set drawMode
	 */
	get activeGraphics() {
		return this[this.__drawMode.type.toLowerCase()];
	}

	/**
	 * Returns the momentarily active drawMode of this NodeGraphics object
	 */
	get drawMode() {
		return this.__drawMode;
	}

	/**
	 * Returns the symbol component of this NodeGraphics object
	 */
	get symbol() {
		return this.__symbol;
	}

	/**
	 * Returns the image component of this NodeGraphics object
	 */
	get image() {
		return this.__image;
	}

	/**
	 * Returns the icon component of this NodeGraphics object
	 */
	get icon() {
		return this.__icon;
	}

	/**
	 * Sets the tooltip of this NodeGraphics object
	 * @param {string} _newTooltip to set
	 */
	set tooltip(_newTooltip) {
		if (this.tooltip === _newTooltip) return;
		this.__tooltip = _newTooltip;
	}

	/**
	 * Returns the tooltip of this NodeGraphics object
	 */
	get tooltip() {
		return this.__tooltip;
	}

	/**
	 * Sets the position of this NodeGraphics object
	 * @param {object} _newPosition to set
	 */
	set position(_newPosition) {
		if (this.position === _newPosition) return;
		this.__position = _newPosition;
	}

	/**
	 * Returns the position of this NodeGraphics object
	 */
	get position() {
		return this.__position;
	}

	/**
	 * Returns pan/zoom of this NodeGraphics object (only relevant for nodes representing a canvas, like assemblies and infrastructure)
	 */
	get layout() {
		return this.__layout;
	}

	/**
	 * Sets the layout of this NodeGraphics object
	 * @param {object} _newLayout to set, format: {pan: {x, y}, zoom}
	 */
	set layout(_newLayout) {
		if (this.layout === _newLayout) return;
		this.__layout = _newLayout;
	}

	/**
	 * Handles drawModeChangedEvent
	 * @param {drawModeEnum} _drawMode to set
	 */
	__drawModeChangeHandler(_drawMode) {
		this.__drawMode = _drawMode;
	}

	/**
	 * Returns the variable characteristics of NodeGraphics.
	 * @returns {object} position
	 */
	save() {
		return {
			position: {...this.position},
			// maybe more to add later
		};
	}
}

/**
 * Class representing a NodeGraphicsElement (image, symbol, icon)
 * @class NodeGraphicsElement
 */
export class NodeGraphicsElement {
	/**
	 *Creates an instance of GraphicsElement.
	 * @param {string} _elementFile file
	 * @param {number} _elementWidth width
	 * @param {number} _elementHeight height
	 */
	constructor(_elementFile, _elementWidth, _elementHeight) {
		this.__file = _elementFile;
		this.__width = _elementWidth;
		this.__height = _elementHeight;
	}

	/** */
	get file() {
		return this.__file;
	}

	/** */
	get width() {
		return this.__width;
	}

	/** */
	get height() {
		return this.__height;
	}
}

/**
 * Class representing a ports graphics object
 * @class PortGraphics
 */
export class PortGraphics {
	/**
	 * Creates an instance of PortGraphics.
	 * It's recommended that you use the provided portGraphicsFactory to instantiate new PortGraphics objects.
	 * @param {object} _image component
	 * @param {object} _symbol component
	 * @param {drawModeEnum} _initialDrawMode to set
	 * @param {string} _tooltip to display
	 */
	constructor(_image, _symbol, _initialDrawMode, _tooltip) {
		this.__image = new PortGraphicsElement(_image.orientation, _image.position);
		this.__symbol = new PortGraphicsElement(_symbol.orientation, _symbol.position);

		this.__drawMode = _initialDrawMode;
		this.__tooltip = _tooltip;

		GLOBALEVENTMANAGER.addHandler("eGUI_ToggleDrawMode", (_drawMode) => {
			this.__drawModeChangeHandler(_drawMode);
		});
	}

	/**
	 * Returns symbol/image component of this PortGraphics object depending on set drawMode
	 */
	get activeGraphics() {
		return this[this.__drawMode.type.toLowerCase()];
	}

	/**
	 * Returns the momentarily active drawMode of this PortGraphics object
	 */
	get drawMode() {
		return this.__drawMode;
	}

	/**
	 * Returns the symbol component of this PortGraphics object
	 */
	get symbol() {
		return this.__symbol;
	}

	/**
	 * Returns the image component of this PortGraphics object
	 */
	get image() {
		return this.__image;
	}

	/**
	 * Handles drawModeChangedEvent
	 * @param {drawModeEnum} _drawMode to set
	 */
	__drawModeChangeHandler(_drawMode) {
		this.__drawMode = _drawMode;
	}

	/**
	 * Returns the variable characteristics of an PortGraphics.
	 * @returns {object} orientation and position
	 */
	save() {
		return {
			image: this.image.save(),
			symbol: this.symbol.save(),
		};
	}
}

/**
 * Class representing a portGraphicsElement (images, symbol)
 * @class PortGraphicsElement
 */
export class PortGraphicsElement {
	/**
	 * Creates an instance of PortGraphicsElement.
	 * @param {portOrientationEnum} _orientation of this port
	 * @param {object} _position of this port in relative coordinates to the parent image dimensions
	 */
	constructor(_orientation, _position) {
		this.__orientation = _orientation;
		this.__position = _position;
	}

	/**
	 * Sets this portsGraphicsElements position
	 * @param {object} _newPosition of this port in relative coordinates to the parent image dimensions
	 */
	set position(_newPosition) {
		if (this.position === _newPosition) return;
		this.__position = _newPosition;
	}

	/**
	 * Returns this portsGraphicsElements position
	 */
	get position() {
		return this.__position;
	}

	/**
	 * Sets this portsGraphicsElements orientation
	 * @param {portOrientationEnum} _newOrientation to set
	 */
	set orientation(_newOrientation) {
		if (this.orientation === _newOrientation) return;
		this.__orientation = _newOrientation;
	}

	/**
	 * Returns this portsGraphicsElements orientation
	 */
	get orientation() {
		return this.__orientation;
	}

	/**
	 * Returns the variable characteristics of an PortGraphicsElement.
	 * @returns {object} orientation and position
	 */
	save() {
		return {
			orientation: this.orientation,
			position: {...this.position},
		};
	}
}

/**
 * Creates a NodeGraphics object from sourceData input
 * @param {object} _graphicsSourceData to convert to graphics
 * @returns {NodeGraphics} NodeGraphics
 */
export const nodeGraphicsFactory = (_graphicsSourceData) =>
	new NodeGraphics(
		_graphicsSourceData.image,
		_graphicsSourceData.symbol,
		_graphicsSourceData.icon,
		activeDrawMode,
		_graphicsSourceData.position,
		_graphicsSourceData.layout,
		_graphicsSourceData.tooltip,
	);

/**
 * Creates a PortGraphics object from sourceData input
 * @param {object} _graphicsSourceData to convert to graphics
 * @returns {PortGraphics} PortGraphics
 */
export const portGraphicsFactory = (_graphicsSourceData) =>
	new PortGraphics(_graphicsSourceData.image, _graphicsSourceData.symbol, activeDrawMode, _graphicsSourceData.tooltip);
