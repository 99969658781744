import {schemaSafeOffVoltage, schemaPositiveFloat, schemaCheckbox, schemaGenericName, schemaFilename, schemaNoValidation} from "../resources/validationSchemata";
import {createAccordionShell, addAccordionPanelToShell, finalizeAccordion} from "./accordion";
import {BACKEND, GLOBALEVENTMANAGER, MESSENGER, USER, pdfController} from "./applicationManager";
import {makeConnectorArrayByPort} from "./communicationDialog";
import {interfaceOperatingModeEnum, interfaceTypeEnum, technicalUnitEnum, deviceRoleEnum} from "./constantsAndEnumerations";
import {
	CustomHeadLineControl,
	CustomDataNodeHeader,
	CustomTextControl,
	CustomTextAreaControl,
	CustomControlSpacer,
	CustomDropdownControl,
	CustomInfoControl,
	CustomLabelControl,
	customControlWarningModeEnum,
	CustomCheckboxControl,
} from "./customControl";
import {CustomTableControl, CustomReferenceDesignatorControl} from "./customControlSpecial";
import {parseRESTPortData} from "./dataConverter";
import {getDataNodeByUUID, getUniqueDataNodeByType, newProject, loadProject, saveProject} from "./dataManager";
import {projectNodeTemplate} from "./dataNodes/templates/projectNodeTemplate";
import {unitPropertyDialog} from "./dialogUnit";
import {cablePropertyDialog} from "./dialogCable";
import {unixTime2localTime, searchArrayForElementByKeyValuePair, searchProjectIdInProjectList, stringCompare2dArray} from "./helper";
import {interfaceFactory} from "./interfaces/interfaceFactory";
import {getJspNodeByUUID} from "./jsPlumb/jspManager";
import {getTranslation, formatter} from "./localization/localizationManager";
import {ModalDialog, CancelButton, ConfirmButton, ModalDialogWide} from "./modalDialog";
import {exportPartsListFile, fileTypeEnum} from "./partListManager";
import {Port} from "./ports/port";
import {portFactory} from "./ports/portFactory";

import cloneDeep from "lodash.clonedeep";

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} DataNode
 * @typedef {string} i18nKey
 * @typedef {object} Port
 * @typedef {object} PdfPage
 * @typedef {string} UUID
 */
/* eslint-enable jsdoc/require-property */

/* Enumeration of different device groups */
const deviceGroupEnum = {
	CONSUMERS: {type: "CONSUMERS", caption: "modalDialog.consumerDevices"},
	FUNCTIONS: {type: "FUNCTIONS", caption: "modalDialog.functionDevices"},
	CABLES: {type: "CABLES", caption: "modalDialog.cableDevices"},
};

/** Standard initialization routine (mainly setting event handlers). */
export function initializeDialogManager() {
	GLOBALEVENTMANAGER.addHandler("OpenDeviceDialog", eDialogRouter);
}

/**
 * Determines which dataNode dialog to open depending on type of _dataNode argument.
 * @param  {DataNode} _node to base selection on
 */
function eDialogRouter(_node) {
	// switch (_dataNode.nodeType) {
	switch (_node.getType()) {
		case "InfrastructureNode":
			infrastructurePropertyDialog();
			break;
		case "ProjectNode":
			projectPropertyDialog();
			break;
		case "AssemblyNode":
			assemblyPropertyDialog(_node.UUID);
			break;
		case "UnitNode":
			unitPropertyDialog(_node.UUID);
			break;
		case "DeviceNode":
			if (_node.group == "consumers") {
				if (_node.ports[0].interfaces[0].isMotor) {
					consumerMotorPropertyDialog(_node.UUID);
				} else {
					consumerGenericDevicePropertyDialog(_node.UUID);
				}
			} else if (_node.group == "functions") {
				productDevicePropertyDialog(_node.UUID);
			}
			break;
		case "Connection":
			cablePropertyDialog(_node.UUID);
			break;
		default:
			break;
	}
}

/**
 * Handles deactivation of projectLoad/-Save/-Manage dialogs for non-registered users
 * @param  {ModalDialog} _modal	dialog to extend
 * @param  {i18nKey} _deactivationMessage to display for non-registered users
 * @returns {boolean} registration status
 */
export function handleUnregisteredUser(_modal, _deactivationMessage) {
	if (!USER.isRegistered) {
		_modal.addControl(new CustomControlSpacer(_modal.contentArea));
		_modal.addControl(new CustomInfoControl("deactivationWarning", _modal.contentArea, _deactivationMessage, customControlWarningModeEnum.WARNING));
		_modal.addControl(new CustomControlSpacer(_modal.contentArea));
		_modal.forceInvalidity();
	}
	return USER.isRegistered;
}

/**
 * Tries to read a list of existing projects for project load/-save/-manage dialogs
 * @param  {ModalDialog} _modal	dialog to extend
 * @returns {boolean|object} false or existing projectList
 */
async function buildProjectList(_modal) {
	// get list of saved projects from server
	const tmpProjectList = await BACKEND.getProjectsInfo(USER.id);
	const tmpTableRows = [];
	tmpProjectList.forEach((tmpProject) => {
		tmpTableRows.push([tmpProject.name, unixTime2localTime(tmpProject.created), unixTime2localTime(tmpProject.modified)]);
	});
	if (tmpTableRows.length == 0) {
		_modal.addControl(new CustomControlSpacer(_modal.contentArea));
		_modal.addControl(new CustomInfoControl("warningNoProjects", _modal.contentArea, "modalDialog.projects.notFound-Warning", customControlWarningModeEnum.WARNING));
		_modal.addControl(new CustomControlSpacer(_modal.contentArea));
		_modal.forceInvalidity();
		return false;
	} else {
		tmpTableRows.unshift([getTranslation("modalDialog.projects.name"), getTranslation("modalDialog.projects.created"), getTranslation("modalDialog.projects.modified")]); // prepend a heading to tmpProjectList
		tmpTableRows.unshift(["string", "date", "date"]); // prepend a type to tmpProjectList

		_modal.addControl(new CustomHeadLineControl("existingProjects", _modal.contentArea, "modalDialog.existingProjects"));
		_modal.addControl(new CustomTableControl("projectList", _modal.contentArea, tmpTableRows));
		_modal.addControl(new CustomControlSpacer(_modal.contentArea));
		return tmpProjectList;
	}
}

/** Opens a new-project dialog */
export function newProjectDialog() {
	const modal = new ModalDialog("modalDialog.heading-ProjectNew");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok", okButtonCallBack));

	// Controls
	modal.addControl(new CustomTextControl("projectNewName", modal.contentArea, "modalDialog.projects.name", technicalUnitEnum.EMPTY, schemaFilename));
	modal.getControlById("projectNewName").setValue(getTranslation(projectNodeTemplate.i18nKey));

	modal.addControl(new CustomInfoControl("warningProjectNew", modal.contentArea, "modalDialog.projects.new-Warning", customControlWarningModeEnum.WARNING));

	// Callbacks

	/** Callback for ok button */
	function okButtonCallBack() {
		newProject(modal.getControlById("projectNewName").getValue());
	}

	/** Callback for cancel button */
	function cancelButtonCallBack() {
		// can be used to call additional functions on cancel dialog
	}
}

/** Opens a load-project dialog */
export async function loadProjectDialog() {
	let projectList;

	const modal = new ModalDialog("modalDialog.heading-ProjectLoad");

	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.load", loadButtonCallBack));

	if (handleUnregisteredUser(modal, "modalDialog.projects.load-Deactivated-Warning")) {
		// check if active user is registered
		projectList = await buildProjectList(modal); // try to build list of existing projects
		if (projectList) {
			modal.addControl(new CustomLabelControl("projectName", modal.contentArea, "modalDialog.projects.name", technicalUnitEnum.EMPTY, schemaFilename));
			modal.addControl(new CustomInfoControl("warningProjectLoad", modal.contentArea, "modalDialog.projects.load-Warning", customControlWarningModeEnum.WARNING));

			// Data binding
			modal.setControlBinding("projectList", "projectName");
		}
	}

	// Callbacks
	/**
	 * Callback for load button, triggers loading of chosen project
	 */
	async function loadButtonCallBack() {
		const tmpProjectId = searchProjectIdInProjectList(projectList, modal.getControlById("projectName").getValue());
		await loadProject(USER.id, tmpProjectId);
	}
}

/** Opens a save-project dialog */
export async function saveProjectDialog() {
	let projectList;
	const modal = new ModalDialog("modalDialog.heading-ProjectSave");

	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.save", saveButtonCallBack));

	// check if active user is registered
	if (handleUnregisteredUser(modal, "modalDialog.projects.save-Deactivated-Warning")) {
		// check if active user is registered
		projectList = await buildProjectList(modal); // try to build list of existing projects

		modal.addControl(new CustomTextControl("projectName", modal.contentArea, "modalDialog.projects.name", technicalUnitEnum.EMPTY, schemaFilename));

		if (projectList) {
			modal.addControl(
				new CustomInfoControl("warningProjectOverwrite", modal.contentArea, "modalDialog.projects.overwrite-Warning", customControlWarningModeEnum.WARNING),
			);

			modal.setControlBinding("projectList", "projectName");

			modal.setLocalDataBinding(modal.getControlById("projectName").getEvent(), () => {
				if (searchArrayForElementByKeyValuePair(projectList, "0", modal.getControlById("projectName").getValue())) {
					modal.getControlById("warningProjectOverwrite").toggleVisibility(true);
				} else {
					modal.getControlById("warningProjectOverwrite").toggleVisibility(false);
				}
			});
		}

		// Default values
		modal.getControlById("projectName").setValue(getUniqueDataNodeByType("ProjectNode").getName()); // set a default value for projectName
	}

	// Callbacks
	/**
	 * Callback for save button, triggers saving of opened project
	 */
	async function saveButtonCallBack() {
		getUniqueDataNodeByType("ProjectNode").setName(modal.getControlById("projectName").getValue());
		const tmpProjectName = modal.getControlById("projectName").getValue();
		const tmpProjectId = searchProjectIdInProjectList(projectList, tmpProjectName);
		await saveProject(USER.id, tmpProjectId, tmpProjectName);
	}
}

/** Opens a dialog to manage projects (atm only to delete projects)*/
export async function deleteProjectsDialog() {
	let projectList;
	const modal = new ModalDialog("modalDialog.heading-ProjectDeletion");

	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.delete", deleteButtonCallBack));

	const tmpProjectsDeletionList = [];

	if (handleUnregisteredUser(modal, "modalDialog.projects.manage-Deactivated-Warning")) {
		// check if active user is registered
		projectList = await buildProjectList(modal); // try to build list of existing projects

		if (projectList) {
			modal.addControl(new CustomInfoControl("warningProjectDelete", modal.contentArea, "modalDialog.projects.delete-Warning", customControlWarningModeEnum.WARNING));

			// Data binding
			modal.setLocalDataBinding(modal.getControlById("projectList").event, toggleProjectDeletion); // hook projectList click to toggleProjectDeletion callback
		}
	}

	// Helpers
	/**
	 * Adds/removes a project from tmpProjectsDeletionList
	 * @param  {string} _projectName to toggle for deletion
	 */
	function toggleProjectDeletion(_projectName) {
		if (!tmpProjectsDeletionList.includes(_projectName)) {
			// project not yet marked for deletion, add _projectName to tmpProjectsDeletionList
			tmpProjectsDeletionList.push(_projectName);
		} else {
			// project already marked for deletion, remove _projectName from tmpProjectsDeletionList
			tmpProjectsDeletionList.splice(tmpProjectsDeletionList.indexOf(_projectName), 1);
		}

		// Find dataRow with _projectName and toggle css class "cControl-table-data-row-delete"
		const tmpDataRow = document.getElementById(`projectList_data-row_${_projectName}`);
		modal.getControlById("projectList").toggleDataRowMarking(tmpDataRow, "cControl-table-data-row-delete");
	}

	// Callbacks
	/** Remove all projects marked for deletion (= stored in tmpProjectsDeletionList) by server request */
	async function deleteButtonCallBack() {
		const promises = [];
		tmpProjectsDeletionList.forEach((tmpProjectName) => {
			const tmpProjectId = searchProjectIdInProjectList(projectList, tmpProjectName);
			promises.push(BACKEND.deleteProject(USER.id, tmpProjectId));
		});
		await Promise.all(promises);
	}
}

/** Opens a infrastructure property dialog */
function infrastructurePropertyDialog() {
	const tmpDataNode = cloneDeep(getUniqueDataNodeByType("InfrastructureNode"));
	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));
	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));
	modal.addControl(
		new CustomTextControl("infrastructureName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, infrastructureNameCallBack),
	);
	modal.getControlById("infrastructureName").setValue(tmpDataNode.getName());
	modal.getControlById("infrastructureName").disable(true);

	modal.addControl(new CustomTextAreaControl("infrastructureDescription", modal.contentArea, "modalDialog.description", infrastructureDescriptionCallBack));
	modal.getControlById("infrastructureDescription").setValue(tmpDataNode.getDescription());

	modal.addControl(
		new CustomReferenceDesignatorControl("referenceDesignatorEdit", modal.contentArea, "modalDialog.reference-designator", tmpDataNode, referenceDesignatorEditCallBack),
	);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	// DataBinding
	modal.setControlBinding("infrastructureName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("infrastructureDescription", "propertyHeader", "setDescriptionEventHandler");

	// Callbacks
	/**
	 * Wrapper for projectName Control callback
	 * @param  {string} _name to set
	 */
	function infrastructureNameCallBack(_name) {
		getUniqueDataNodeByType("InfrastructureNode").setName(_name);
	}

	/**
	 * Wrapper for projectDescription Control callback
	 * @param  {string} _description to set
	 */
	function infrastructureDescriptionCallBack(_description) {
		getUniqueDataNodeByType("InfrastructureNode").setDescription(_description);
	}
	/**
	 * Wrapper for referenceDesignatorEdit Control callback
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		getUniqueDataNodeByType("InfrastructureNode").referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}
}

/** Opens a project property dialog */
function projectPropertyDialog() {
	const tmpDataNode = cloneDeep(getUniqueDataNodeByType("ProjectNode"));
	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));

	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));

	modal.addControl(new CustomTextControl("projectName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, projectNameCallBack));
	modal.getControlById("projectName").setValue(tmpDataNode.getName());
	//* automatic select of name value
	modal.getControlById("projectName").select();

	modal.addControl(new CustomTextAreaControl("projectDescription", modal.contentArea, "modalDialog.description", projectDescriptionCallBack));
	modal.getControlById("projectDescription").setValue(tmpDataNode.getDescription());

	// DataBinding
	modal.setControlBinding("projectName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("projectDescription", "propertyHeader", "setDescriptionEventHandler");

	// Callbacks
	/**
	 * Wrapper for projectName Control callback
	 * @param  {string} _name to set
	 */
	function projectNameCallBack(_name) {
		getUniqueDataNodeByType("ProjectNode").setName(_name);
	}

	/**
	 * Wrapper for projectDescription Control callback
	 * @param  {string} _description to set
	 */
	function projectDescriptionCallBack(_description) {
		getUniqueDataNodeByType("ProjectNode").setDescription(_description);
	}
}

/**
 * Opens an assembly property dialog
 * @param  {string} _UUID of assemblyNode that is presented by this dialog
 */
function assemblyPropertyDialog(_UUID) {
	const tmpDataNode = cloneDeep(getDataNodeByUUID(_UUID));
	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));

	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));
	modal.addControl(new CustomTextControl("assemblyName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, assemblyNameCallBack));
	modal.getControlById("assemblyName").setValue(tmpDataNode.getName());
	//* automatic select of name value
	modal.getControlById("assemblyName").select();

	modal.addControl(new CustomTextAreaControl("assemblyDescription", modal.contentArea, "modalDialog.description", assemblyDescriptionCallBack));
	modal.getControlById("assemblyDescription").setValue(tmpDataNode.getDescription());

	modal.addControl(
		new CustomReferenceDesignatorControl("referenceDesignatorEdit", modal.contentArea, "modalDialog.reference-designator", tmpDataNode, referenceDesignatorEditCallBack),
	);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	modal.addControl(
		new CustomCheckboxControl(
			"decentralControl",
			modal.contentArea,
			"modalDialog.chckbx-decentralControl",
			technicalUnitEnum.EMPTY,
			schemaCheckbox,
			decentralControlCallBack,
		),
	);
	modal.getControlById("decentralControl").setValue(tmpDataNode.decentralControl);

	// DataBinding
	modal.setControlBinding("assemblyName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("assemblyDescription", "propertyHeader", "setDescriptionEventHandler");

	// Callbacks
	/**
	 * Wrapper for assemblyName Control callback
	 * @param  {string} _name to set
	 */
	function assemblyNameCallBack(_name) {
		getDataNodeByUUID(_UUID).setName(_name);
	}

	/**
	 * Wrapper for assemblyDescription Control callback
	 * @param  {string} _description to set
	 */
	function assemblyDescriptionCallBack(_description) {
		getDataNodeByUUID(_UUID).setDescription(_description);
	}

	/**
	 * Wrapper for referenceDesignatorEdit Control callback
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		getDataNodeByUUID(_UUID).referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}

	/**
	 *
	 * @param _decentralControl
	 */
	function decentralControlCallBack(_decentralControl) {
		getDataNodeByUUID(_UUID).decentralControl = _decentralControl;
	}
}

/**
 * Opens a consumer device property dialog.
 * @param  {UUID} _UUID of dataNode that is presented by this dialog.
 */
function consumerGenericDevicePropertyDialog(_UUID) {
	const dataNode = getDataNodeByUUID(_UUID);
	const tmpDataNode = cloneDeep(dataNode);
	const tmpInterface = tmpDataNode.ports[0].interfaces[0];

	const decentralConsumerOptions = [
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-default"), value: "null"},
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-decentral"), value: true},
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-cabinet"), value: false},
	];

	const operatingModeList = [];
	for (const mode in interfaceOperatingModeEnum) {
		if (interfaceOperatingModeEnum.hasOwnProperty(mode) && interfaceOperatingModeEnum[mode].nonmotor) {
			operatingModeList.push({caption: interfaceOperatingModeEnum[mode].caption, value: interfaceOperatingModeEnum[mode].type});
		}
	}
	// TODO use property Port.isConnected instead
	const isDeviceConnected = getJspNodeByUUID(_UUID).getAllEdges().length !== 0;

	let availablePortsForDropdown = [];
	let availablePorts = [];
	const connectorList = makeConnectorArrayByPort(tmpDataNode.ports[0], false);
	connectorList.forEach((port) => {
		availablePortsForDropdown.push({caption: port.desc, value: port.id});
		availablePorts.push({...port});
	});

	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));

	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));

	modal.addControl(new CustomTextControl("deviceName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, deviceNameCallBack));
	modal.getControlById("deviceName").setValue(tmpDataNode.getName());
	//* automatic select of name value
	modal.getControlById("deviceName").select();

	modal.addControl(new CustomTextAreaControl("deviceDescription", modal.contentArea, "modalDialog.description", deviceDescriptionCallBack));
	modal.getControlById("deviceDescription").setValue(tmpDataNode.getDescription());

	modal.addControl(
		new CustomReferenceDesignatorControl("referenceDesignatorEdit", modal.contentArea, "modalDialog.reference-designator", tmpDataNode, referenceDesignatorEditCallBack),
	);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	modal.addControl(
		new CustomDropdownControl(
			"decentralControl",
			modal.contentArea,
			"modalDialog.dropdown-decentralControlOptions",
			technicalUnitEnum.EMPTY,
			decentralConsumerOptions,
			decentralControlCallBack,
		),
	);
	modal.getControlById("decentralControl").setValue(tmpDataNode.decentralControl === null ? "null" : tmpDataNode.decentralControl);

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(new CustomHeadLineControl("specificProperties", modal.contentArea, "modalDialog.specificProperties"));

	if (isDeviceConnected) {
		modal.addControl(
			new CustomInfoControl("changeConnector", modal.contentArea, "modalDialog.dialogAggregate.info-changeConnector", customControlWarningModeEnum.WARNING),
		);
	}

	modal.addControl(
		new CustomTextControl("deviceVoltage", modal.contentArea, "modalDialog.param-voltage", technicalUnitEnum.VOLTAGE, schemaPositiveFloat, deviceVoltageCallBack),
	);
	modal.getControlById("deviceVoltage").setValue(formatter.number2string(tmpInterface.voltage));

	modal.addControl(
		new CustomTextControl("deviceCurrent", modal.contentArea, "modalDialog.param-current", technicalUnitEnum.CURRENT, schemaPositiveFloat, deviceCurrentCallBack),
	);
	modal.getControlById("deviceCurrent").setValue(formatter.number2string(tmpInterface.current));

	if (
		tmpDataNode.ports[0].interfaces[0].type.type == interfaceTypeEnum.ENERGY_AC.type ||
		tmpDataNode.ports[0].interfaces[0].type.type == interfaceTypeEnum.ENERGY_DC.type
	) {
		modal.addControl(
			new CustomDropdownControl(
				"deviceOperatingMode",
				modal.contentArea,
				"modalDialog.param-operatingMode",
				technicalUnitEnum.EMPTY,
				operatingModeList,
				deviceOperatingModeCallBack,
			),
		);
		modal.getControlById("deviceOperatingMode").setValue(tmpInterface.operatingMode.type);
		modal.setLocalDataBinding(modal.getControlById("deviceOperatingMode").getEvent(), operatingModeChanged);
	} else tmpDataNode.ports[0].interfaces[0].operatingMode = interfaceOperatingModeEnum.ON_OFF; // hack against server base data error
	modal.addControl(
		new CustomCheckboxControl(
			"deviceSafeVoltageOff",
			modal.contentArea,
			"modalDialog.param-safeVoltageOff",
			technicalUnitEnum.EMPTY,
			schemaCheckbox,
			deviceSafeVoltageOffCallBack,
		),
	);
	modal.getControlById("deviceSafeVoltageOff").setValue(tmpInterface.isSafe);

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(
		new CustomDropdownControl("devicePort", modal.contentArea, "modalDialog.param-port", technicalUnitEnum.EMPTY, availablePortsForDropdown, devicePortCallBack),
	);
	modal.getControlById("devicePort").setValue(tmpDataNode.ports[0].databaseId);

	// DataBinding
	modal.setControlBinding("deviceName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("deviceDescription", "propertyHeader", "setDescriptionEventHandler");

	// Events
	modal.setLocalDataBinding(modal.getControlById("deviceCurrent").getEvent(), currentChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceVoltage").getEvent(), voltageChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceSafeVoltageOff").getEvent(), safeVoltageOffChanged);
	modal.setLocalDataBinding(modal.getControlById("devicePort").getEvent(), portChanged);

	// Callbacks
	/**
	 * Wrapper for deviceName Control callback.
	 * @param  {string} _name to set
	 */
	function deviceNameCallBack(_name) {
		dataNode.setName(_name);
	}

	/**
	 * Wrapper for deviceDescription Control callback.
	 * @param  {string} _description to set
	 */
	function deviceDescriptionCallBack(_description) {
		dataNode.setDescription(_description);
	}

	/**
	 * Wrapper for referenceDesignatorEdit Control callback.
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		dataNode.referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}

	/**
	 * Wrapper for decentralOptions callback.
	 * @param {string} _decentralControl to set //! string bc dropdown doesn't pass null|boolean (becomes string of value). please repair in react
	 * @throws {Error} if decentralControl is not valid
	 */
	function decentralControlCallBack(_decentralControl) {
		let result;
		switch (_decentralControl) {
			case "null":
				result = null;
				break;
			case "true":
				result = true;
				break;
			case "false":
				result = false;
				break;
			default:
				throw new Error("_decentralControl is not valid");
		}

		dataNode.decentralControl = result;
	}

	/**
	 * Wrapper for deviceVoltage Control callback.
	 * @param  {number} _voltage to set
	 */
	function deviceVoltageCallBack(_voltage) {
		dataNode.ports[0].interfaces[0].voltage = _voltage;
		dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[0].UUID, dataNode.ports[0].interfaces[0].UUID, "voltage", _voltage);
		if (dataNode.sameSecondPort) {
			// for device with 2 identical ports
			dataNode.ports[1].interfaces[0].voltage = _voltage;
			dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[1].UUID, dataNode.ports[1].interfaces[0].UUID, "voltage", _voltage);
		}
	}

	/**
	 * Wrapper for deviceCurrent Control callback.
	 * @param  {number} _current to set
	 */
	function deviceCurrentCallBack(_current) {
		dataNode.ports[0].interfaces[0].current = _current;
		dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[0].UUID, dataNode.ports[0].interfaces[0].UUID, "current", _current);
		if (dataNode.sameSecondPort) {
			// for device with 2 identical ports
			dataNode.ports[1].interfaces[0].current = _current;
			dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[1].UUID, dataNode.ports[1].interfaces[0].UUID, "current", _current);
		}
	}

	/**
	 * Wrapper for deviceOperatingMode Control callback.
	 * @param  {object} _operatingMode to set
	 */
	function deviceOperatingModeCallBack(_operatingMode) {
		dataNode.ports[0].interfaces[0].operatingMode = interfaceOperatingModeEnum[_operatingMode];
		if (dataNode.sameSecondPort) {
			// for device with 2 identical ports
			dataNode.ports[1].interfaces[0].operatingMode = interfaceOperatingModeEnum[_operatingMode]; // there is no event eDTM_InterfaceParameterChanged for this parameter yet
		}
	}

	/**
	 * Wrapper for deviceSafeVoltageOff Control callback.
	 * @param  {boolean} _safeVoltageOff to set
	 */
	function deviceSafeVoltageOffCallBack(_safeVoltageOff) {
		dataNode.ports[0].interfaces[0].isSafe = _safeVoltageOff;
		if (dataNode.sameSecondPort) {
			// for device with 2 identical ports
			dataNode.ports[1].interfaces[0].isSafe = _safeVoltageOff; // there is no event eDTM_InterfaceParameterChanged for this parameter yet
		}
	}

	/**
	 * Wrapper for devicePort Control callback.
	 * @param  {number} _connectorId to set
	 */
	function devicePortCallBack(_connectorId) {
		const portToReplace = dataNode.ports[0];

		// TODO later on, this early return has to compare 2 ports on their equality, this step is only valuable if we rely on a suitable and somehow final port model
		if (getDataNodeByUUID(tmpDataNode.UUID).ports[0].databaseId === parseInt(_connectorId)) return;

		const tmpPortData = availablePorts.find((port) => port.id === parseInt(_connectorId));
		tmpPortData.picture = {
			xpos: portToReplace.graphics.image.position.x,
			ypos: portToReplace.graphics.image.position.y,
			direction: portToReplace.graphics.image.orientation,
		};
		tmpPortData.symbol = {
			xpos: portToReplace.graphics.symbol.position.x,
			ypos: portToReplace.graphics.symbol.position.y,
			direction: portToReplace.graphics.symbol.orientation,
		};
		tmpPortData.port = portToReplace.dbNumber;

		const portData = parseRESTPortData(tmpPortData, tmpDataNode.group, tmpDataNode.dataBaseId);

		dataNode.removePort(portToReplace);
		const oldPortSideTranslationKey = `ports.${portToReplace.side.toLowerCase()}`;
		MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portRemoved", {port: portToReplace, portSide: oldPortSideTranslationKey, dataNode});

		const tmpNewPort = portFactory(portData);
		dataNode.addPort(tmpNewPort);
		const newPortSideTranslationKey = `ports.${tmpNewPort.side.toLowerCase()}`;
		MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portAdded", {port: tmpNewPort, portSide: newPortSideTranslationKey, dataNode});

		dataNode.ports.sort((a, b) => a.dbNumber - b.dbNumber);

		if (tmpDataNode.sameSecondPort) {
			// for device with 2 identical ports
			const secondPortToReplace = dataNode.ports[1];
			const tmpPortData = availablePorts.find((port) => port.id === parseInt(_connectorId));

			tmpPortData.picture = {
				xpos: secondPortToReplace.graphics.image.position.x,
				ypos: secondPortToReplace.graphics.image.position.y,
				direction: secondPortToReplace.graphics.image.orientation,
			};
			tmpPortData.symbol = {
				xpos: secondPortToReplace.graphics.symbol.position.x,
				ypos: secondPortToReplace.graphics.symbol.position.y,
				direction: secondPortToReplace.graphics.symbol.orientation,
			};
			tmpPortData.port = secondPortToReplace.dbNumber;

			const portData = parseRESTPortData(tmpPortData, tmpDataNode.group, tmpDataNode.dataBaseId);

			dataNode.removePort(secondPortToReplace);
			const oldPortSideTranslationKey = `ports.${secondPortToReplace.side.toLowerCase()}`;
			MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portRemoved", {port: secondPortToReplace, portSide: oldPortSideTranslationKey, dataNode});

			const tmpNewPort = portFactory(portData);
			dataNode.addPort(tmpNewPort);
			const newPortSideTranslationKey = `ports.${tmpNewPort.side.toLowerCase()}`;
			MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portAdded", {port: tmpNewPort, portSide: newPortSideTranslationKey, dataNode});

			dataNode.ports.sort((a, b) => a.dbNumber - b.dbNumber);
		}
	}

	/** Help function for making new port list */
	function changePortList() {
		makeAvailablePortList();
		modal.getControlById("devicePort").update(availablePortsForDropdown);
		modal.getControlById("devicePort").setValue(tmpDataNode.ports[0].databaseId);
	}

	/** Help function for making new port list */
	function makeAvailablePortList() {
		availablePortsForDropdown = [];
		availablePorts = [];
		const connectorList = makeConnectorArrayByPort(tmpDataNode.ports[0], false);
		connectorList.forEach((port) => {
			availablePortsForDropdown.push({caption: port.desc, value: port.id});
			availablePorts.push({...port});
		});
	}

	/**
	 * Callback on current change.
	 * @param  {string} _value - given nominal current if validated, null if not.
	 */
	function currentChanged(_value) {
		if (_value !== null) {
			tmpInterface.current = modal.getControlById("deviceCurrent").getValue();
			changePortList();
		}
	}

	/**
	 * Callback on voltage change.
	 * @param  {string} _value - given nominal voltage if validated, null if not.
	 */
	function voltageChanged(_value) {
		if (_value !== null) {
			tmpInterface.voltage = modal.getControlById("deviceVoltage").getValue();
			changePortList();
		}
	}

	/**
	 * Callback on safeVoltageOff change.
	 * @param  {boolean} _value - new safeVoltageOff checkbox value.
	 */
	function safeVoltageOffChanged(_value) {
		tmpInterface.isSafe = _value;
		modal.getControlById("deviceSafeVoltageOff").schema = schemaSafeOffVoltage(tmpInterface);
		modal.getControlById("deviceSafeVoltageOff").hardValidate(tmpInterface.isSafe);
		changePortList();
	}

	/**
	 * Callback on operating mode change.
	 * @param  {string} _value - given operating mode.
	 */
	function operatingModeChanged(_value) {
		tmpInterface.operatingMode = interfaceOperatingModeEnum[_value];
		changePortList();
		modal.getControlById("deviceSafeVoltageOff").schema = schemaSafeOffVoltage(tmpInterface);
		modal.getControlById("deviceSafeVoltageOff").hardValidate(tmpInterface.isSafe);
	}

	/**
	 * Callback on port change.
	 * @param  {string} _value - given port.
	 */
	function portChanged(_value) {
		// tmpDataNode.ports[0].databaseId = _value;			//! did nothing, removing solves the port change problem (xtec15 -> open)
	}
}

/**
 * Opens a consumer device property dialog.
 * @param  {string} _UUID of dataNode that is presented by this dialog.
 */
function consumerMotorPropertyDialog(_UUID) {
	const dataNode = getDataNodeByUUID(_UUID);
	const tmpDataNode = cloneDeep(dataNode);
	const tmpInterface = tmpDataNode.ports[0].interfaces[0];

	const decentralMotorOptions = [
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-default"), value: "null"},
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-decentral"), value: true},
		{caption: getTranslation("modalDialog.dropdown-decentralControlOptions-cabinet"), value: false},
	];

	const operatingModeList = [];
	for (const mode in interfaceOperatingModeEnum) {
		if (interfaceOperatingModeEnum.hasOwnProperty(mode)) {
			operatingModeList.push({caption: interfaceOperatingModeEnum[mode].caption, value: interfaceOperatingModeEnum[mode].type});
		}
	}
	// TODO use property Port.isConnected instead
	const isDeviceConnected = getJspNodeByUUID(_UUID).getAllEdges().length !== 0;

	// let availablePortsList = [];
	let availablePortsForDropdown = [];
	let availablePorts = [];
	makeAvailablePortList();
	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal, okButtonCallBack));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok", okButtonCallBack));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));

	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));

	modal.addControl(new CustomTextControl("deviceName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, deviceNameCallBack));
	modal.getControlById("deviceName").setValue(tmpDataNode.getName());
	//* automatic select of name value when dialog opens
	modal.getControlById("deviceName").select();

	modal.addControl(new CustomTextAreaControl("deviceDescription", modal.contentArea, "modalDialog.description", deviceDescriptionCallBack));
	modal.getControlById("deviceDescription").setValue(tmpDataNode.getDescription());

	modal.addControl(
		new CustomReferenceDesignatorControl("referenceDesignatorEdit", modal.contentArea, "modalDialog.reference-designator", tmpDataNode, referenceDesignatorEditCallBack),
	);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	modal.addControl(
		new CustomDropdownControl(
			"decentralControl",
			modal.contentArea,
			"modalDialog.dropdown-decentralControlOptions",
			technicalUnitEnum.EMPTY,
			decentralMotorOptions,
			decentralControlCallBack,
		),
	);
	modal.getControlById("decentralControl").setValue(tmpDataNode.decentralControl === null ? "null" : tmpDataNode.decentralControl);

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(new CustomHeadLineControl("specificProperties", modal.contentArea, "modalDialog.specificProperties"));

	if (isDeviceConnected) {
		modal.addControl(
			new CustomInfoControl("changeConnector", modal.contentArea, "modalDialog.dialogAggregate.info-changeConnector", customControlWarningModeEnum.WARNING),
		);
	}

	modal.addControl(
		new CustomTextControl("deviceVoltage", modal.contentArea, "modalDialog.param-voltage", technicalUnitEnum.VOLTAGE, schemaPositiveFloat, deviceVoltageCallBack),
	);
	modal.getControlById("deviceVoltage").setValue(formatter.number2string(tmpInterface.voltage));

	modal.addControl(
		new CustomTextControl("deviceCurrent", modal.contentArea, "modalDialog.param-current", technicalUnitEnum.CURRENT, schemaPositiveFloat, deviceCurrentCallBack),
	);
	modal.getControlById("deviceCurrent").setValue(formatter.number2string(tmpInterface.current));

	modal.addControl(
		new CustomDropdownControl(
			"deviceOperatingMode",
			modal.contentArea,
			"modalDialog.param-operatingMode",
			technicalUnitEnum.EMPTY,
			operatingModeList,
			deviceOperatingModeCallBack,
		),
	);
	modal.getControlById("deviceOperatingMode").setValue(tmpInterface.operatingMode.type);

	modal.addControl(
		new CustomCheckboxControl("deviceSoftStarted", modal.contentArea, "modalDialog.param-softStart", technicalUnitEnum.EMPTY, schemaCheckbox, deviceSoftStartedCallBack),
	);
	modal.getControlById("deviceSoftStarted").setValue(tmpInterface.isSoftStarted);

	modal.addControl(
		new CustomCheckboxControl(
			"deviceCurrentMonitored",
			modal.contentArea,
			"modalDialog.param-currentMonitored",
			technicalUnitEnum.EMPTY,
			schemaCheckbox,
			deviceCurrentMonitoredCallBack,
		),
	);
	modal.getControlById("deviceCurrentMonitored").setValue(tmpInterface.isMonitored);

	modal.addControl(
		new CustomCheckboxControl(
			"deviceWindingProtection",
			modal.contentArea,
			"modalDialog.param-windingProtection",
			technicalUnitEnum.EMPTY,
			schemaCheckbox,
			isProtectedCallback,
		),
	);
	modal.getControlById("deviceWindingProtection").setValue(tmpInterface.isProtected);

	modal.addControl(
		new CustomCheckboxControl(
			"deviceSafeVoltageOff",
			modal.contentArea,
			"modalDialog.param-safeVoltageOff",
			technicalUnitEnum.EMPTY,
			schemaSafeOffVoltage(tmpInterface),
			deviceSafeVoltageOffCallBack,
		),
	);
	modal.getControlById("deviceSafeVoltageOff").setValue(tmpInterface.isSafe);

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(
		new CustomDropdownControl("devicePort", modal.contentArea, "modalDialog.param-port", technicalUnitEnum.EMPTY, availablePortsForDropdown, devicePortCallBack),
	);
	modal.getControlById("devicePort").setValue(tmpDataNode.ports[0].databaseId);

	// DataBinding
	modal.setControlBinding("deviceName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("deviceDescription", "propertyHeader", "setDescriptionEventHandler");

	// add Events
	modal.setLocalDataBinding(modal.getControlById("deviceCurrent").getEvent(), currentChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceVoltage").getEvent(), voltageChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceSoftStarted").getEvent(), isSoftStartedChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceCurrentMonitored").getEvent(), isMonitoredChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceWindingProtection").getEvent(), isProtectedChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceOperatingMode").getEvent(), operatingModeChanged);
	modal.setLocalDataBinding(modal.getControlById("deviceSafeVoltageOff").getEvent(), safeVoltageOffChanged);
	modal.setLocalDataBinding(modal.getControlById("devicePort").getEvent(), portChanged);

	// Callbacks
	/**
	 * Wrapper for deviceName Control callback.
	 * @param  {string} _name to set
	 */
	function deviceNameCallBack(_name) {
		dataNode.setName(_name);
	}

	/**
	 * Wrapper for deviceDescription Control callback.
	 * @param  {string} _description to set
	 */
	function deviceDescriptionCallBack(_description) {
		dataNode.setDescription(_description);
	}

	/**
	 * Wrapper for referenceDesignatorEdit Control callback.
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		dataNode.referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}

	/**
	 * Wrapper for decentralOptions callback.
	 * @param {string} _decentralControl to set //! string bc dropdown doesn't pass null|boolean (becomes string of value). please repair in react
	 * @throws {Error} if decentralControl is not valid
	 */
	function decentralControlCallBack(_decentralControl) {
		let result;
		switch (_decentralControl) {
			case "null":
				result = null;
				break;
			case "true":
				result = true;
				break;
			case "false":
				result = false;
				break;
			default:
				throw new Error("_decentralControl is not valid");
		}

		dataNode.decentralControl = result;
	}

	/**
	 * Wrapper for deviceVoltage Control callback.
	 * @param  {number} _voltage to set
	 */
	function deviceVoltageCallBack(_voltage) {
		dataNode.ports[0].interfaces[0].voltage = _voltage;
		// dataNode.ports[0].interfaces[0].setParameter({voltage: _voltage});		// Deactivated for now, until technical Parameters are defined
		dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[0].UUID, dataNode.ports[0].interfaces[0].UUID, "voltage", _voltage);
	}

	/**
	 * Wrapper for deviceCurrent Control callback.
	 * @param  {number} _current to set
	 */
	function deviceCurrentCallBack(_current) {
		dataNode.ports[0].interfaces[0].current = _current;
		// dataNode.ports[0].interfaces[0].setParameter({current: _current});		// Deactivated for now, until technical Parameters are defined
		dataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", dataNode.ports[0].UUID, dataNode.ports[0].interfaces[0].UUID, "current", _current);
	}

	/**
	 * Wrapper for deviceOperatingMode Control callback.
	 * @param  {object} _operatingMode to set
	 */
	function deviceOperatingModeCallBack(_operatingMode) {
		dataNode.ports[0].interfaces[0].operatingMode = interfaceOperatingModeEnum[_operatingMode];
		if (dataNode.ports[0].interfaces[0].operatingMode == interfaceOperatingModeEnum.SPEED_REGULATED) {
			dataNode.ports[0].isShielded = true;
		} else dataNode.ports[0].isShielded = false;
	}

	/**
	 * Wrapper for deviceSoftStarted Control callback.
	 * @param  {boolean} _softStarted true if the motor must be soft started.
	 */
	function deviceSoftStartedCallBack(_softStarted) {
		dataNode.ports[0].interfaces[0].isSoftStarted = _softStarted;
	}

	/**
	 * Wrapper for deviceCurrentMonitored Control callback.
	 * @param  {boolean} _currentMonitored - true if the motor control device must support current monitoring.
	 */
	function deviceCurrentMonitoredCallBack(_currentMonitored) {
		dataNode.ports[0].interfaces[0].isMonitored = _currentMonitored;
	}

	/**
	 * Wrapper for deviceSafeVoltageOff Control callback.
	 * @param  {boolean} _safeVoltageOff to set
	 */
	function deviceSafeVoltageOffCallBack(_safeVoltageOff) {
		dataNode.ports[0].interfaces[0].isSafe = _safeVoltageOff;
	}

	/**
	 * Wrapper for windingProtection Control callback..
	 * @param  {boolean} _isProtected is motor protected?
	 */
	function isProtectedCallback(_isProtected) {
		const port = dataNode.ports[0];
		if (_isProtected && port.interfaces.length === 1) {
			port.addInterface(interfaceFactory(50));
			port.interfaces[1].description = "winding protection interface";
			port.interfaces[0].isProtected = true;
		}
		if (!_isProtected && port.interfaces.length === 2) {
			port.removeInterface(port.interfaces[1]);
			port.interfaces[0].isProtected = false;
		}
	}

	/**
	 * Wrapper for devicePort Control callback.
	 * @param  {Port} _connectorId to set
	 */
	function devicePortCallBack(_connectorId) {
		const dataNode = getDataNodeByUUID(_UUID);
		const portToReplace = dataNode.ports[0];

		// TODO later on this early return has to compare 2 ports on their equality, this step is only valuable if we rely on a suitable and somehow final port model
		if (getDataNodeByUUID(tmpDataNode.UUID).ports[0].databaseId === parseInt(_connectorId)) return;

		const tmpPortData = availablePorts.find((port) => port.id === parseInt(_connectorId));
		tmpPortData.picture = {
			xpos: portToReplace.graphics.image.position.x,
			ypos: portToReplace.graphics.image.position.y,
			direction: portToReplace.graphics.image.orientation,
		};
		tmpPortData.symbol = {
			xpos: portToReplace.graphics.symbol.position.x,
			ypos: portToReplace.graphics.symbol.position.y,
			direction: portToReplace.graphics.symbol.orientation,
		};
		tmpPortData.port = portToReplace.dbNumber;

		const portData = parseRESTPortData(tmpPortData, tmpDataNode.group, tmpDataNode.dataBaseId);

		dataNode.removePort(portToReplace);
		const oldPortSideTranslationKey = `ports.${portToReplace.side.toLowerCase()}`;
		MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portRemoved", {port: portToReplace, portSide: oldPortSideTranslationKey, dataNode});

		const tmpNewPort = portFactory(portData);
		dataNode.addPort(tmpNewPort);
		const newPortSideTranslationKey = `ports.${tmpNewPort.side.toLowerCase()}`;
		MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portAdded", {port: tmpNewPort, portSide: newPortSideTranslationKey, dataNode});

		dataNode.parentEventManager.dispatch("eDTM_PortParameterChanged", portToReplace.UUID, "type", portToReplace.type);
	}

	/** Help function for changing port list */
	function changePortList() {
		makeAvailablePortList();
		modal.getControlById("devicePort").update(availablePortsForDropdown);
		modal.getControlById("devicePort").setValue(tmpDataNode.ports[0].databaseId);
	}

	/** Help function for making new port list */
	function makeAvailablePortList() {
		availablePortsForDropdown = [];
		availablePorts = [];
		const connectorList = makeConnectorArrayByPort(tmpDataNode.ports[0], false);
		connectorList.forEach((port) => {
			availablePortsForDropdown.push({caption: port.desc, value: port.id});
			availablePorts.push({...port});
		});
	}

	/** Callback for ok button */
	function okButtonCallBack() {}

	/**
	 * Callback on current change.
	 * @param  {string} _value - given nominal current if validated, null if not.
	 */
	function currentChanged(_value) {
		if (_value !== null) {
			tmpInterface.current = modal.getControlById("deviceCurrent").getValue();
			changePortList();
		}
	}

	/**
	 * Callback on voltage change.
	 * @param  {string} _value - given nominal voltage if validated, null if not.
	 */
	function voltageChanged(_value) {
		if (_value !== null) {
			tmpInterface.voltage = modal.getControlById("deviceVoltage").getValue();
			changePortList();
		}
	}

	/**
	 * Callback on softStart change.
	 * @param  {boolean} _value is motor soft started?
	 */
	function isSoftStartedChanged(_value) {
		tmpInterface.isSoftStarted = _value;
		changePortList();
	}

	/**
	 * Callback on isProtected change (windingProtection).
	 * @param  {boolean} _isProtected is motor protected?
	 */
	function isProtectedChanged(_isProtected) {
		const port = tmpDataNode.ports[0];
		if (_isProtected && port.interfaces.length === 1) {
			port.addInterface(interfaceFactory(50));
			port.interfaces[1].description = "winding protection interface";
			port.interfaces[0].isProtected = true;
		}
		if (!_isProtected && port.interfaces.length === 2) {
			port.removeInterface(port.interfaces[1]);
			port.interfaces[0].isProtected = false;
		}
		changePortList();
	}

	/**
	 * Callback on isMonitored change.
	 * @param  {boolean} _value is motor monitored?
	 */
	function isMonitoredChanged(_value) {
		tmpInterface.isMonitored = _value;
		changePortList();
	}

	/**
	 * Event callback safeVoltageOff change.
	 * @param  {boolean} _value - new safeVoltageOff checkbox value.
	 */
	function safeVoltageOffChanged(_value) {
		tmpInterface.isSafe = _value;
		modal.getControlById("deviceSafeVoltageOff").schema = schemaSafeOffVoltage(tmpInterface);
		modal.getControlById("deviceSafeVoltageOff").hardValidate(tmpInterface.isSafe);
		changePortList();
	}

	/**
	 * Event callback operating mode change.
	 * @param  {string} _value - given operating mode.
	 */
	function operatingModeChanged(_value) {
		tmpInterface.operatingMode = interfaceOperatingModeEnum[_value];
		changePortList();
		modal.getControlById("deviceSafeVoltageOff").schema = schemaSafeOffVoltage(tmpInterface);
		modal.getControlById("deviceSafeVoltageOff").hardValidate(tmpInterface.isSafe);
	}

	/**
	 * Callback on port change.
	 * @param  {string} _value - given port.
	 */
	function portChanged(_value) {
		// tmpDataNode.ports[0].databaseId = _value;			//! did nothing, removing solves the port change problem (xtec15 -> open)
	}
}

/**
 * Opens a product device property dialog
 * @param  {string} _UUID of dataNode that is presented by this dialog
 */
function productDevicePropertyDialog(_UUID) {
	const tmpDataNode = cloneDeep(getDataNodeByUUID(_UUID));

	const modal = new ModalDialog("modalDialog.heading-Properties");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));

	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));
	modal.addControl(new CustomTextControl("deviceName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, deviceNameCallBack));
	modal.getControlById("deviceName").setValue(tmpDataNode.getName());
	//* automatic select of name value
	modal.getControlById("deviceName").select();

	modal.addControl(new CustomTextAreaControl("deviceDescription", modal.contentArea, "modalDialog.description", deviceDescriptionCallBack));
	modal.getControlById("deviceDescription").setValue(tmpDataNode.getDescription());

	modal.addControl(
		new CustomReferenceDesignatorControl("referenceDesignatorEdit", modal.contentArea, "modalDialog.reference-designator", tmpDataNode, referenceDesignatorEditCallBack),
	);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	// DataBinding
	modal.setControlBinding("deviceName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("deviceDescription", "propertyHeader", "setDescriptionEventHandler");

	// Callbacks
	/**
	 * Wrapper for deviceName Control callback
	 * @param  {string} _name to set
	 */
	function deviceNameCallBack(_name) {
		getDataNodeByUUID(_UUID).setName(_name);
	}

	/**
	 * Wrapper for deviceDescription Control callback
	 * @param  {string} _description to set
	 */
	function deviceDescriptionCallBack(_description) {
		getDataNodeByUUID(_UUID).setDescription(_description);
	}

	/**
	 * Wrapper for referenceDesignatorEdit Control callback
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		getDataNodeByUUID(_UUID).referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}
}

/** Opens a dialog showing all parts of the project */
export function partsListDialog() {
	const modal = new ModalDialogWide("modalDialog.heading-partslist");

	const consumersSourceList = dataRoot.activeGroupNode.children.filter((child) => child.group === "consumers");

	const consumersResultList = [];

	consumersSourceList.forEach((tmpConsumer) => {
		let tmpPortNames = "";
		let tmpInterfaceVoltages = "";
		let tmpInterfaceCurrents = "";
		let tmpInterfaceTypes = "";
		let tmpInterfaceSafes = "";
		tmpConsumer.ports.forEach((tmpPort) => {
			tmpPortNames += tmpPort.name;
			tmpPortNames += " / ";

			tmpPort.interfaces.forEach((tmpInterface) => {
				tmpInterfaceVoltages += tmpInterface.voltage;
				tmpInterfaceVoltages += " / ";
				tmpInterfaceCurrents += tmpInterface.current;
				tmpInterfaceCurrents += " / ";
				tmpInterfaceTypes += getTranslation(tmpInterface.type.caption);
				tmpInterfaceTypes += " / ";
				tmpInterfaceSafes += tmpInterface.isSafe;
				tmpInterfaceSafes += " / ";
			});
		});

		tmpPortNames = tmpPortNames.slice(0, tmpPortNames.length - 3);
		tmpInterfaceVoltages = tmpInterfaceVoltages.slice(0, tmpInterfaceVoltages.length - 3);
		tmpInterfaceCurrents = tmpInterfaceCurrents.slice(0, tmpInterfaceCurrents.length - 3);
		tmpInterfaceTypes = tmpInterfaceTypes.slice(0, tmpInterfaceTypes.length - 3);
		tmpInterfaceSafes = tmpInterfaceSafes.slice(0, tmpInterfaceSafes.length - 3);

		consumersResultList.push([
			tmpConsumer.getName(),
			tmpConsumer.manufacturer,
			tmpPortNames,
			tmpInterfaceVoltages,
			tmpInterfaceCurrents,
			tmpInterfaceTypes,
			tmpInterfaceSafes,
			tmpConsumer.referenceDesignator.getReferenceDesignator().string(),
		]);
	});

	consumersResultList.sort(stringCompare2dArray(7, true)); // sort for referenceDesignator

	consumersResultList.forEach((tmpConsumer) => {
		// prepend position numbers
		tmpConsumer.unshift(consumersResultList.indexOf(tmpConsumer) + 1);
	});

	// prepend headlines and column data types
	consumersResultList.unshift([
		getTranslation("modalDialog.position"),
		getTranslation("modalDialog.designation"),
		getTranslation("modalDialog.manufacturer"),
		getTranslation("modalDialog.connector"),
		getTranslation("modalDialog.voltage"),
		getTranslation("modalDialog.nominal-current"),
		getTranslation("modalDialog.interface"),
		getTranslation("modalDialog.safe"),
		getTranslation("modalDialog.reference-designator"),
	]);
	consumersResultList.unshift(["number", "string", "string", "string", "number", "number", "string", "string", "string"]);

	const functionsSourceList = dataRoot.activeGroupNode.children.filter((child) => child.group === "functions");

	const functionsResultList = [];

	functionsSourceList.forEach((tmpFunction) => {
		functionsResultList.push([
			tmpFunction.getName(),
			tmpFunction.manufacturer,
			tmpFunction.materialNumber,
			tmpFunction.referenceDesignator.getReferenceDesignator().string(),
		]);
	});

	functionsResultList.sort(stringCompare2dArray(3, true)); // sort for referenceDesignator

	functionsResultList.forEach((tmpFunction) => {
		// prepend position numbers
		tmpFunction.unshift(functionsResultList.indexOf(tmpFunction) + 1);
	});

	// prepend headlines and column data types
	functionsResultList.unshift([
		getTranslation("modalDialog.position"),
		getTranslation("modalDialog.designation"),
		getTranslation("modalDialog.manufacturer"),
		getTranslation("modalDialog.material-number"),
		getTranslation("modalDialog.reference-designator"),
	]);
	functionsResultList.unshift(["number", "string", "string", "string", "string"]);

	const cablesSourceList = dataRoot.activeGroupNode.connections;
	const cablesResultList = [];

	cablesSourceList.forEach((tmpCable) => {
		cablesResultList.push([
			tmpCable.name,
			tmpCable.manufacturer,
			tmpCable.materialNumber,
			// tmpCable.referenceDesignator.getReferenceDesignator().string(),		//! referenceDesignator is broken for cables
			"ToDo", // TODO add referenceDesignator
		]);
	});

	cablesResultList.sort(stringCompare2dArray(2, true)); // sort for referenceDesignator

	cablesResultList.forEach((tmpCable) => {
		// prepend position numbers
		tmpCable.unshift(cablesResultList.indexOf(tmpCable) + 1);
	});

	// prepend headlines and column data types
	cablesResultList.unshift([
		getTranslation("modalDialog.position"),
		getTranslation("modalDialog.designation"),
		getTranslation("modalDialog.manufacturer"),
		getTranslation("modalDialog.material-number"),
		getTranslation("modalDialog.reference-designator"),
	]);
	cablesResultList.unshift(["number", "string", "string", "string", "string"]);

	// Buttons
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomHeadLineControl("consumerListHeading", modal.contentArea, deviceGroupEnum.CONSUMERS.caption));
	modal.addControl(new CustomTableControl("consumerList", modal.contentArea, consumersResultList));
	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(new CustomHeadLineControl("functionListHeading", modal.contentArea, deviceGroupEnum.FUNCTIONS.caption));
	modal.addControl(new CustomTableControl("functionList", modal.contentArea, functionsResultList));
	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(new CustomHeadLineControl("cableListHeading", modal.contentArea, deviceGroupEnum.CABLES.caption));
	modal.addControl(new CustomTableControl("cableList", modal.contentArea, cablesResultList));
}

/**
 * Creates Dialog for saving partslist
 */
export function savePartslistDialog() {
	const modal = new ModalDialog("modalDialog.partsList.heading-ExportPartsList");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.save", exportButtonCallBack));

	// check if active user is registered
	if (handleUnregisteredUser(modal, "modalDialog.partsList.deactivated-Warning")) {
		// check if active user is registered
		// prepare list of available export formats
		const formatList = [];
		Object.values(fileTypeEnum).forEach((tmpValue) => {
			formatList.push({caption: tmpValue.caption, value: tmpValue.extension});
		});

		// Controls
		modal.addControl(new CustomTextControl("exportName", modal.contentArea, "modalDialog.partsList.filename", technicalUnitEnum.EMPTY, schemaFilename));
		modal.addControl(new CustomDropdownControl("exportFormat", modal.contentArea, "modalDialog.partsList.format", technicalUnitEnum.EMPTY, formatList));
		modal.addControl(new CustomControlSpacer(modal.contentArea));

		// Default values
		modal
			.getControlById("exportName")
			.setValue(`${getUniqueDataNodeByType("ProjectNode").getName()}-${getTranslation("modalDialog.heading-partslist").replace(/\s+/g, "")}`); // set a default value for partslist file
		modal.getControlById("exportFormat").setValue(fileTypeEnum.XLSX.extension);
	}

	// Callbacks
	/**
	 * Callback for export button, triggers partslist export
	 */
	function exportButtonCallBack() {
		const tmpFileName = modal.getControlById("exportName").getValue();
		const tmpFileType = fileTypeEnum[modal.getControlById("exportFormat").getValue().toUpperCase()];
		exportPartsListFile(tmpFileName, tmpFileType);
	}
}

/** Opens a new pdfExport dialog */
export function pdfExportDialog() {
	const tmpTitleBlockContent = {
		department: null,
		departmentLead: null,
		approvedBy: null,
		keyWords: null,
		company: null,
		function: null,
		status: null,
		projectTitle: null,
		drawingId: null,
		language: null,
		page: null,
		creator1: null,
		version1: null,
		date1: null,
		change1: null,
		creator2: null,
		version2: null,
		date2: null,
		change2: null,
		creator3: null,
		version3: null,
		date3: null,
		change3: null,
		creator4: null,
		version4: null,
		date4: null,
		change4: null,
	};

	const modal = new ModalDialog("modalDialog.pdfExporter.heading");

	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok", okButtonCallBack));

	addTitleBlockForm(modal);
	addAccordionAndDocumentHistoryControls(modal);
	addCheckboxesForPagesToPrint(modal);
	// addDrawModeDropdown(modal);

	/**
	 * adds all controls to fill the titleBlock
	 * @param {*} _modal dialog parent
	 */
	function addTitleBlockForm(_modal) {
		const statusList = [];
		statusList.push({caption: "modalDialog.pdfExporter.inDevelopment", value: "modalDialog.pdfExporter.inDevelopment"}); // 1
		statusList.push({caption: "modalDialog.pdfExporter.locked", value: "modalDialog.pdfExporter.locked"}); // 0
		statusList.push({caption: "modalDialog.pdfExporter.inChange", value: "modalDialog.pdfExporter.inChange"}); // 2
		statusList.push({caption: "modalDialog.pdfExporter.prototypeRelease", value: "modalDialog.pdfExporter.prototypeRelease"}); // 7
		statusList.push({caption: "modalDialog.pdfExporter.released", value: "modalDialog.pdfExporter.released"}); // 9

		const documentTypeList = [];
		documentTypeList.push({caption: "modalDialog.pdfExporter.installationPlan", value: "modalDialog.pdfExporter.installationPlan"});

		_modal.addControl(new CustomHeadLineControl("commonProperties", _modal.contentArea, "modalDialog.pdfExporter.titleBlockProperties"));

		_modal.addControl(
			new CustomTextControl("department", _modal.contentArea, "modalDialog.pdfExporter.department", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("department", _modal.getControlById("department").value);
			}),
		);
		_modal.getControlById("department").setValue("");

		_modal.addControl(
			new CustomTextControl("departmentLead", _modal.contentArea, "modalDialog.pdfExporter.departmentLead", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("departmentLead", _modal.getControlById("departmentLead").value);
			}),
		);
		_modal.getControlById("departmentLead").setValue("");

		_modal.addControl(
			new CustomTextControl("approvedBy", _modal.contentArea, "modalDialog.pdfExporter.approvedBy", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("approvedBy", _modal.getControlById("approvedBy").value);
			}),
		);
		_modal.getControlById("approvedBy").setValue("");

		_modal.addControl(
			new CustomTextControl("keyWords", _modal.contentArea, "modalDialog.pdfExporter.keyWords", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("keyWords", _modal.getControlById("keyWords").value);
			}),
		); // make info provider for comma separated list //! we need a schema for comma separated lists of strings
		_modal.getControlById("keyWords").setValue("");

		_modal.addControl(
			new CustomTextControl("company", _modal.contentArea, "modalDialog.pdfExporter.company", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("company", _modal.getControlById("company").value);
			}),
		);
		_modal.getControlById("company").setValue(""); // collect USER.company information

		_modal.addControl(
			new CustomDropdownControl("function", _modal.contentArea, "modalDialog.pdfExporter.function", technicalUnitEnum.EMPTY, documentTypeList, () => {
				dataRetrieverCallBack("function", getTranslation(_modal.getControlById("function").value));
			}),
		);

		_modal.addControl(
			new CustomDropdownControl("status", _modal.contentArea, "modalDialog.pdfExporter.status", technicalUnitEnum.EMPTY, statusList, () => {
				dataRetrieverCallBack("status", getTranslation(_modal.getControlById("status").value));
			}),
		);

		_modal.addControl(
			new CustomTextControl("projectTitle", _modal.contentArea, "modalDialog.pdfExporter.projectTitle", technicalUnitEnum.EMPTY, schemaGenericName, () => {
				dataRetrieverCallBack("projectTitle", _modal.getControlById("projectTitle").value);
			}),
		);
		_modal.getControlById("projectTitle").setValue(getUniqueDataNodeByType("ProjectNode").getName());

		_modal.addControl(
			new CustomTextControl("drawingId", _modal.contentArea, "modalDialog.pdfExporter.drawingId", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("drawingId", _modal.getControlById("drawingId").value);
			}),
		);
		_modal.getControlById("drawingId").setValue("");

		_modal.addControl(
			new CustomTextControl("language", _modal.contentArea, "modalDialog.pdfExporter.language", technicalUnitEnum.EMPTY, schemaGenericName, () => {
				dataRetrieverCallBack("language", _modal.getControlById("language").value);
			}),
		);
		_modal.getControlById("language").setValue(USER.language.caption);
	}

	/**
	 * adds accordion with 4x4 controls for document history
	 * @param {*} _modal dialog parent
	 */
	function addAccordionAndDocumentHistoryControls(_modal) {
		createAccordionShell(_modal.contentArea, "historyShell", "modalDialog.pdfExporter.history");

		addAccordionPanelToShell("historyShell", "historyPanel", "tooltip");

		_modal.addControl(new CustomHeadLineControl("documentHistoryEntry1", "historyPanel", "modalDialog.pdfExporter.historyEntry1"));

		_modal.addControl(
			new CustomTextControl("creator1", "historyPanel", "modalDialog.pdfExporter.creatorName", technicalUnitEnum.EMPTY, schemaGenericName, () => {
				dataRetrieverCallBack("creator1", _modal.getControlById("creator1").value);
			}),
		);
		_modal.getControlById("creator1").setValue(USER.fullName); // fill automatically and make const

		_modal.addControl(
			new CustomTextControl("version1", "historyPanel", "modalDialog.pdfExporter.version", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("version1", _modal.getControlById("version1").value);
			}),
		);
		_modal.getControlById("version1").setValue("");

		_modal.addControl(
			new CustomTextControl("date1", "historyPanel", "modalDialog.pdfExporter.date", technicalUnitEnum.EMPTY, schemaGenericName, () => {
				dataRetrieverCallBack("date1", _modal.getControlById("date1").value);
			}),
		);
		_modal.getControlById("date1").setValue(unixTime2localTime(Math.floor(Date.now() / 1000))); //! very ugly; We convert javascript date.now (which is utc) to unix timestamp. I guess that problem will solve itself when we switch to i18next/numeral for getting/setting dates

		_modal.addControl(
			new CustomTextControl("change1", "historyPanel", "modalDialog.pdfExporter.change", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("change1", _modal.getControlById("change1").value);
			}),
		);
		_modal.getControlById("change1").setValue("");

		_modal.addControl(new CustomHeadLineControl("documentHistoryEntry2", "historyPanel", "modalDialog.pdfExporter.historyEntry2")); //? translation

		_modal.addControl(
			new CustomTextControl("creator2", "historyPanel", "modalDialog.pdfExporter.creatorName", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("creator2", _modal.getControlById("creator2").value);
			}),
		);
		_modal.getControlById("creator2").setValue("");

		_modal.addControl(
			new CustomTextControl("version2", "historyPanel", "modalDialog.pdfExporter.version", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("version2", _modal.getControlById("version2").value);
			}),
		);
		_modal.getControlById("version2").setValue("");

		_modal.addControl(
			new CustomTextControl("date2", "historyPanel", "modalDialog.pdfExporter.date", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("date2", _modal.getControlById("date2").value);
			}),
		);
		_modal.getControlById("date2").setValue("");

		_modal.addControl(
			new CustomTextControl("change2", "historyPanel", "modalDialog.pdfExporter.change", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("change2", _modal.getControlById("change2").value);
			}),
		);
		_modal.getControlById("change2").setValue("");

		_modal.addControl(new CustomHeadLineControl("documentHistoryEntry3", "historyPanel", "modalDialog.pdfExporter.historyEntry3")); //? translation

		_modal.addControl(
			new CustomTextControl("creator3", "historyPanel", "modalDialog.pdfExporter.creatorName", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("creator3", _modal.getControlById("creator3").value);
			}),
		);
		_modal.getControlById("creator3").setValue("");

		_modal.addControl(
			new CustomTextControl("version3", "historyPanel", "modalDialog.pdfExporter.version", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("version3", _modal.getControlById("version3").value);
			}),
		);
		_modal.getControlById("version3").setValue("");

		_modal.addControl(
			new CustomTextControl("date3", "historyPanel", "modalDialog.pdfExporter.date", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("date3", _modal.getControlById("date3").value);
			}),
		);
		_modal.getControlById("date3").setValue("");

		_modal.addControl(
			new CustomTextControl("change3", "historyPanel", "modalDialog.pdfExporter.change", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("change3", _modal.getControlById("change3").value);
			}),
		);
		_modal.getControlById("change3").setValue("");

		_modal.addControl(new CustomHeadLineControl("documentHistoryEntry4", "historyPanel", "modalDialog.pdfExporter.historyEntry4")); //? translation

		_modal.addControl(
			new CustomTextControl("creator4", "historyPanel", "modalDialog.pdfExporter.creatorName", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("creator4", _modal.getControlById("creator4").value);
			}),
		);
		_modal.getControlById("creator4").setValue("");

		_modal.addControl(
			new CustomTextControl("version4", "historyPanel", "modalDialog.pdfExporter.version", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("version4", _modal.getControlById("version4").value);
			}),
		);
		_modal.getControlById("version4").setValue("");

		_modal.addControl(
			new CustomTextControl("date4", "historyPanel", "modalDialog.pdfExporter.date", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("date4", _modal.getControlById("date4").value);
			}),
		);
		_modal.getControlById("date4").setValue("");

		_modal.addControl(
			new CustomTextControl("change4", "historyPanel", "modalDialog.pdfExporter.change", technicalUnitEnum.EMPTY, schemaNoValidation, () => {
				dataRetrieverCallBack("change4", _modal.getControlById("change4").value);
			}),
		);
		_modal.getControlById("change4").setValue("");

		finalizeAccordion("historyShell");
	}

	/**
	 * adds a heading and a checkbox for every page to print to the dialog
	 * @param {*} _modal dialog parent
	 */
	function addCheckboxesForPagesToPrint(_modal) {
		const printablePages = pdfController.printablePages;

		_modal.addControl(new CustomHeadLineControl("pdfPages", _modal.contentArea, "modalDialog.pdfExporter.pagesToExport"));

		printablePages.forEach((pdfPage) => {
			_modal.addControl(
				new CustomCheckboxControl(`pdfPageCheckbox_${pdfPage.UUID}`, _modal.contentArea, pdfPage.name, technicalUnitEnum.EMPTY, schemaCheckbox, () => {
					checkboxCallBack(_modal.getControlById(`pdfPageCheckbox_${pdfPage.UUID}`).value, pdfPage);
				}),
			);
			_modal.getControlById(`pdfPageCheckbox_${pdfPage.UUID}`).setValue(true);
		});
	}

	/**
	 * callback for getting the userInput and its context from CustomTextControl
	 * @param {*} _controlId	of CustomTextControl
	 * @param {*} _value of CustomTextControl
	 */
	function dataRetrieverCallBack(_controlId, _value) {
		tmpTitleBlockContent[_controlId] = _value;
	}

	// TODO: move that to okButtonCallback?
	const pagesToPrint = [];
	/**
	 * callback for getting pages to print
	 * @param {boolean} _value checkbox true/false
	 * @param {PdfPage} _pdfPage pageToPrint
	 */
	function checkboxCallBack(_value, _pdfPage) {
		if (_value) pagesToPrint.push(_pdfPage);
	}

	/** callback okButton when okay is pressed, call pdfController to draw */
	function okButtonCallBack() {
		// pdfController mit this.pdfPreviewContainer als div

		// drawMode = this.modal.getControl.value...
		// pagesToPrint = this.modal.getControl.value...
		pdfController.exportPdf(pagesToPrint, USER.drawMode.type.toLowerCase(), tmpTitleBlockContent);
	}

	/** callback cancelButton when cancel is pressed, do nothing */
	function cancelButtonCallBack() {
		// can be used to call additional functions on cancel dialog
	}
}
