/* eslint-disable require-jsdoc */
/* eslint-disable jsdoc/require-jsdoc */

//TODO replace by commented code once different ports have been implemented

import {Application} from "../constantsAndEnumerations2";
import {createUUID, getInterfaceSourceDataByDatabaseId, getPortSourceDataByDatabaseId} from "../dataManager";

import {Port} from "./port";
// import {ElectricPort}													from "./ElectricPort";
// import {HydraulicPort}												from "./HydraulicPort";
// import {PneumaticPort}												from "./PneumaticPort";

import type {BasePortData, BasePortSaveData, BasePortSourceData} from "./basePort.schema";

import type {ElectricPortData} from "./electricPort.schema";
import type {HydraulicPortData} from "./hydraulicPort.schema";
import type {PneumaticPortData} from "./pneumaticPort.schema";

export function portFactory(portDatabaseId: number): Port; // export function portFactory(portDatabaseId: number): ElectricPort|HydraulicPort|PneumaticPort;
export function portFactory(portSaveData: BasePortSaveData): Port; // export function portFactory(portSaveData: BasePortSaveData): ElectricPort|HydraulicPort|PneumaticPort;
export function portFactory(portSourceData: BasePortSourceData): Port; // export function portFactory(portSourceData: BasePortSourceData): ElectricPort|HydraulicPort|PneumaticPort;
export function portFactory(portReference: number | BasePortSaveData | BasePortSourceData): Port {
	// export function portFactory(portReference: number|BasePortSaveData|BasePortSourceData): ElectricPort|HydraulicPort|PneumaticPort {
	let portData: BasePortData;

	if (typeof portReference === "number") {
		portData = {
			...(getPortSourceDataByDatabaseId(portReference) as BasePortSourceData), //TODO casting to BasePortSourceData can be omitted once getPortSourceDataByDatabaseId has a correct return type
			UUID: createUUID(),
		};
		// } else if (!("UUID" in portReference)) {
		//! ATTENTION: When cloning ports/interfaces UUIDs get set to null, you need to catch that here
	} else if (!("UUID" in portReference)) {
		// } else if (!("UUID" in portReference) || portReference.UUID === null) {	// useful when cloning ports/interfaces set UUIDs to null
		portData = {
			...(portReference as BasePortSourceData),
			UUID: createUUID(),
		};
	} else {
		// portSaveData
		portData = {
			...(getPortSourceDataByDatabaseId(portReference.databaseId) as BasePortSourceData), //TODO casting to BasePortSourceData can be omitted once getPortSourceDataByDatabaseId has a correct return type
			...(portReference as BasePortSaveData),
		};
	}

	if (portData.application === Application.ELECTRIC) portData = magicalUniCornRainbowIoLinkHack(portData as ElectricPortData); // @IOLINKHACK

	switch (Application[portData.application]) {
		case Application.ELECTRIC:
			return new Port(portData as ElectricPortData); // return new ElectricPort(portData as ElectricPortData);
		case Application.HYDRAULIC:
			return new Port(portData as HydraulicPortData); // return new HydraulicPort(portData as HydraulicPortData);
		case Application.PNEUMATIC:
			return new Port(portData as PneumaticPortData); // return new PneumaticPort(portData as PneumaticPortData);
		default:
			throw new Error(`PortFactory: Unknown application "${portData.application}".`);
	}
}

/**
 * This amusing piece of sh!t sneakily adds additional leads to IO-Link ports that have multiple interfaces.
 * Composition-String isn't changed, so at least tooltips appear correct.
 * @warning This is a temporary hack! As soon as I have an idea for a sensible implementation, this gets trashed (So it will probably stay here till the end of time...)
 * PS: I'm sorry!
 * @param {ElectricPortData} electricPortData to seductively fondle into accepting multiple interfaces
 * @returns {ElectricPortData} a very very sad port with enough leads to accommodate all interfaces
 */
function magicalUniCornRainbowIoLinkHack(electricPortData: ElectricPortData): ElectricPortData {
	// @IOLINKHACK
	const iolink = electricPortData.interfaces.some((_interface) => _interface.databaseId === 41); // "DATA_IOLINK"
	const measureOrDC = electricPortData.interfaces.some((_interface) => _interface.databaseId === 50 || _interface.databaseId === 20); // "MEASURE_DIGITAL" || "ENERGY_DC"

	// find any combination of IO-Link+measureDigital or IO-Link+EnergyDC
	if (!iolink || !measureOrDC) return electricPortData; // These Are Not the Droids You Are Looking For...

	// calculate number of leads needed for all interfaces present
	const neededLeads = electricPortData.interfaces.reduce((sum, _interface) => sum + getInterfaceSourceDataByDatabaseId(_interface.databaseId).requiredLeads, 0);

	const newLeads = [];

	for (let i = 0; i < neededLeads; i++) {
		const newLead = {...electricPortData.leads[0]};
		newLead.id = i + 1;
		newLeads.push(newLead);
	}

	electricPortData.leads = newLeads;

	return electricPortData;
}
