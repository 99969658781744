import {GLOBALEVENTMANAGER} from "./applicationManager";
import {technicalUnitEnum} from "./constantsAndEnumerations";
// import {CustomLabelControlHeader} from		"./customControlSpecial";
import {checkExistenceNew} from "./helper";
import {cId2jqSel} from "./helper";
import {getTranslation, formatter} from "./localization/localizationManager";
import {schemaGenericName, schemaGenericText, schemaDropdown} from "../resources/validationSchemata";
import cloneDeep from "lodash.clonedeep";

// WEBPACK local import jQuery
import $ from "jquery";
// Webpack imports (work even if shown not used)
import scrollbar from "jquery.scrollbar";
import Joi from "joi-browser"; // TODO replace by below
// import Joi from "joi";
import cableIcon from "../images/cable.svg";
const jQuery = $;

/**
 * Central structure for the creation and manipulation of customControl objects
 *
 * UTILIZING:
 *		lodash for deep cloning arguments passed to the constructor and debouncing function calls, details under https://lodash.com/
 *		jQuery Scrollbar as a replacement for the standard scrollbar, details under https://gromo.github.io/jquery.scrollbar/
 *
 * INFO:
 *		Summary of common html5 input elements: https://www.w3schools.com/html/html_form_input_types.asp
 *
 * TODO:
 *		BUG, CustomTableControl reacts on window resize event even when its dialog is not active ???
 *		remove jQuery
 *		make input a property
 *
 *		atm we are not able to get numbers from DropdownControl.getValue, because the Joi validator(static in DropdownControl) casts the value to string
 *		-> define a variable validationScheme(int|string) to DropdownControl or get rid of validation in DropdownControl completely, could be unnecessary
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/* Enumeration of validation modes */
export const customControlValidationIndicatorEnum = {
	CORRECT: {class: "cControl-ValidationIndicator-correct"},
	WARNING: {class: "cControl-ValidationIndicator-warning"},
	ERROR: {class: "cControl-ValidationIndicator-error"},
	LOADING: {class: "cControl-ValidationIndicator-loading"},
};

/* Enumeration of customControl text color modes */
export const customControlWarningModeEnum = {
	NORMAL: {class: "cControl-Text-normal"},
	WARNING: {class: "cControl-Text-warning"},
};

/**
 * Defining the customControl base template
 * @class CustomBaseControl
 */
export class CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Event} _event this element uses to indicate a data change
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _event, _callBack) {
		this.id = _id;
		this.containerId = checkExistenceNew(_containerId);
		this.label = _label;
		this.unit = this.formatUnit(_unit);
		this.schema = cloneDeep(_schema); // making a deep copy of _schema, since complex object properties are passed byRef to the constructor ...
		this.updateSchemaLabel(this.schema); // set provided schema to correct label
		this.event = `${this.id}_${_event}`;
		this.callBack = _callBack;
		this.value = null;
		this.validated = this.setInitialValidation(this.schema);
		this.createDefaultDomStructure(); // create needed DOM elements
		this.focusable = false;
		this.parentDialog = null;
		this.isLoading = false;
	}

	/**
	 * Returns the type/name of this class
	 * @throws  {Error} indicating that an abstract method was called
	 */
	getType() {
		throw new Error("This is an abstract base class method!");
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			// control container
			id: `${this.id}_cContainer`,
			class: "cControl-Container noselect",
		}).appendTo(cId2jqSel(this.containerId));

		$("<span>", {
			// control label
			id: `${this.id}_cLabel`,
			text: getTranslation(this.label),
			class: "cControl-Label",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		$("<span>", {
			// control unit
			id: `${this.id}_cUnit`,
			html: this.unit,
			class: "cControl-Unit",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		$("<image>", {
			// control validationIndicator
			id: `${this.id}_cValidationIndicator`,
			class: "cControl-ValidationIndicator-Base",
			title: "",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		$("<image>", {
			// control infoIndicator
			id: `${this.id}_cInfoIndicator`,
			class: "cControl-InfoIndicator turnInvisible",
			title: "",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));
	}

	/**
	 * Handling any change of user input; bound to oninput event of the local <input> element
	 * @param  {object} _value to check
	 */
	handleInput(_value) {
		this.hardValidate(_value).then(() => {
			if (this.validated) {
				this.value = _value;
			} else {
				this.value = null;
			}
			GLOBALEVENTMANAGER.dispatch(this.event, this.value);
		});
	}

	/**
	 * Validates user input against provided joi-schema
	 * @param  {object} _value to validate
	 * @returns {Promise} indicating function has finished
	 */
	async hardValidate(_value) {
		return new Promise((resolve, reject) => {
			if (this.isLoading) {
				// don't validate while loading (validation will be called by toggleLoadingIndicator when loading is turned off)
				this.validated = false;
			} else {
				let result = null;
				let tmpErrorMessage = "";
				let tmpValidationStatus = null;

				result = Joi.validate({value: _value}, this.schema);
				if (result.error == null) {
					this.validated = true;
					tmpValidationStatus = customControlValidationIndicatorEnum.CORRECT; // original version
				} else {
					this.validated = false;
					tmpErrorMessage = result.error.details[0].message;
					tmpValidationStatus = customControlValidationIndicatorEnum.ERROR;
				}

				this.toggleValidationIndicator(tmpValidationStatus, tmpErrorMessage);
			}

			GLOBALEVENTMANAGER.dispatch(`${this.id}_ValidityChanged`, this.validated);
			resolve();
		});
	}

	/**
	 * Shows info icon with message as tooltip.
	 * @param {string} _message to show
	 */
	showInfo(_message) {
		document.getElementById(`${this.id}_cInfoIndicator`).title = _message;
		this.toggleInfoIndicator(true);
	}

	/**
	 * Hides info icon.
	 */
	hideInfo() {
		document.getElementById(`${this.id}_cInfoIndicator`).title = "";
		this.toggleInfoIndicator(false);
	}

	/**
	 * Switches info indicator between true/false.
	 * @param {boolean} _show new status
	 */
	toggleInfoIndicator(_show) {
		// set new class
		if (_show) {
			document.getElementById(`${this.id}_cInfoIndicator`).classList.remove("turnInvisible");
		} else {
			document.getElementById(`${this.id}_cInfoIndicator`).classList.add("turnInvisible");
		}
	}

	/**
	 * Switches between validation true/false, setting appropriate parameters for all parts of the customControl
	 * @param  {customControlValidationIndicatorEnum} _mode to set
	 * @param  {string} _errorMessage optional parameter, defaults to "" for non-error cases
	 */
	toggleValidationIndicator(_mode, _errorMessage = "") {
		// update tooltip of validation indicator
		cId2jqSel(`${this.id}_cValidationIndicator`)[0].title = _errorMessage;

		// remove all validationIndicatorClasses
		Object.keys(customControlValidationIndicatorEnum).forEach((e) => {
			cId2jqSel(`${this.id}_cValidationIndicator`).removeClass(customControlValidationIndicatorEnum[e].class);
		});

		// set new validationClasses
		cId2jqSel(`${this.id}_cValidationIndicator`).addClass(_mode.class);
	}

	/**
	 * Toggles this elements loading indicator
	 * @param  {boolean} _bool mode to set (true=on/false=off)
	 */
	toggleLoadingIndicator(_bool) {
		this.isLoading = _bool;
		this.disable(_bool); // (un)lock this control

		// remove all validationIndicatorClasses
		Object.keys(customControlValidationIndicatorEnum).forEach((e) => {
			cId2jqSel(`${this.id}_cValidationIndicator`).removeClass(customControlValidationIndicatorEnum[e].class);
		});

		if (_bool) {
			// set loading class
			cId2jqSel(`${this.id}_cValidationIndicator`).addClass(customControlValidationIndicatorEnum.LOADING.class);
			cId2jqSel(`${this.id}_cValidationIndicator`)[0].title = getTranslation("validation.statusIndicator-loading-tt");
		}

		this.handleInput(this.getValue()); // update validation (turn invalid for isLoading=true, revalidate for isLoading=false)
	}

	/**
	 * Helper function to update the schema label flag, ensuring correctly addressed error messages
	 * @param  {joi-schema} _schema provided via constructor
	 */
	updateSchemaLabel(_schema) {
		_schema.value._flags.label = getTranslation(this.label);
	}

	/**
	 * Returns the name of the event this elements uses to submit data
	 * @returns {string} name of the event
	 */
	getEvent() {
		return this.event;
	}

	/**
	 * Returns the value of this CustomControl
	 * @returns {object} value
	 */
	getValue() {
		if (this.schema == null) return this.value; // don't cast values without a validation scheme (probably not necessary...)

		switch (Joi.describe(this.schema).children.value.type) {
			case "string":
				return String(this.value);
			case "number":
				if (Joi.describe(this.schema).children.value.rules.find((rule) => rule.name == "integer")) {
					// check if number is float or integer
					return parseInt(this.value);
				} else {
					return parseFloat(this.value);
				}
			case "boolean":
				return Boolean(this.value);
			default:
				throw new Error(`CustomControl "${this.id}" no appropriate converter found for given value "${this.value}"`);
		}
	}

	/**
	 * Format the unit string depending on the supplied enum value
	 * @param  {technicalUnitEnum} _unit to format
	 * @returns {string} correctly formatted unit string
	 */
	formatUnit(_unit) {
		if (_unit == technicalUnitEnum.EMPTY) {
			// suppress units for technicalUnitEnum.EMPTY
			return "";
		} else {
			return `[ ${_unit.unit} ]`;
		}
	}

	/**
	 * Sets the initial validation status depending on the provided schema
	 * @param  {joi-schema} _schema that is provided
	 * @returns {boolean} validated
	 */
	setInitialValidation(_schema) {
		return false; // all controls with a schema get created with a negative validation status
	}

	/**
	 * Sets the value of this customControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		console.debug("CustomBaseControl.setValue");
	}

	/** run this elements callback function */
	executeCallBack() {
		if (this.callBack == null) return; // abort if no callBack was provided
		this.callBack(this.getValue());
	}

	/**
	 * Hides this control
	 */
	hide() {
		document.getElementById(`${this.id}_cContainer`).classList.add("turnInvisible");
	}

	/**
	 * Shows this control
	 */
	show() {
		document.getElementById(`${this.id}_cContainer`).classList.remove("turnInvisible");
	}

	/**
	 * Selects content of CustomControl
	 * @memberof CustomBaseControl
	 */
	select() {}

	/**
	 * Shift focus to CustomControl
	 *
	 *
	 * @memberof CustomBaseControl
	 */
	focus() {
		if (this.focusable) document.getElementById(`${this.id}_cInput`).focus();
	}
}

/** Defining the text variant of the customControl base template */
export class CustomTextControl extends CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, "ValueChanged", _callBack);
		this.extendDefaultDomStructure(); // create needed DOM elements
		this.focusable = true;
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomTextControl";
	}

	/** Adds text element to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<input>", {
			id: `${this.id}_cInput`,
			type: "text",
			spellcheck: "false",
			class: "cControl-Input cControl-Input-Textbox",
			disabled: false,
			value: null, // TODO set to a variables value if databound or keep null otherwise
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => {
			this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value);
		}); // registerInputEventHandler (benefit of doing it separately: suppression of the initial oninput-event when opening the dialog...)
	}

	/**
	 * Handling any change of user input; bound to oninput event of the local <input> element
	 * @param  {object} _value to check
	 */
	handleInput(_value) {
		if (this.schema.value.schemaType == "number") {
			let negative = false;
			if (_value.substring(0, 1) == "-") negative = true;
			_value = formatter.string2number(_value);
			if (negative) _value = ~_value + 1;
		}
		this.hardValidate(_value).then(() => {
			if (this.validated) {
				this.value = _value;
			} else {
				this.value = null;
			}
			GLOBALEVENTMANAGER.dispatch(this.event, this.value);
		});
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cInput`)[0].value = _value;
			this.handleInput(_value);
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/**
	 * Sets the value of this customControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).value = _value; // programmatically setting a value doesn't trigger oninput event...
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}

	/**
	 * Toggles this elements disabled status
	 * @param  {boolean} _bool to set (true = disable)
	 */
	disable(_bool) {
		if (_bool) {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = true;
		} else {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = false;
		}
	}

	/**
	 * Selects the Textbox content
	 * @memberof CustomTextControl
	 */
	select() {
		const tmpInput = document.getElementById(`${this.id}_cInput`);
		tmpInput.focus();
		tmpInput.select();
	}
}

/** Defining the multi line text variant of the customControl base template */
export class CustomTextAreaControl extends CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _callBack = null) {
		super(_id, _containerId, _label, technicalUnitEnum.EMPTY, schemaGenericText, "ValueChanged", _callBack);
		this.extendDefaultDomStructure(); // create needed DOM elements
		this.focusable = true;
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomTextAreaControl";
	}

	/** Adds text element to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<textarea>", {
			id: `${this.id}_cInput`,
			type: "text",
			spellcheck: "false",
			class: "cControl-Input cControl-Input-TextArea", // customScrollbarContainer
			value: null, // TODO set to a variables value if databound or keep null otherwise
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		// $(".customScrollbarContainer").scrollbar();	// TODO is causing layout trouble... :(

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => {
			this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value);
		}); // registerInputEventHandler (benefit of doing it separately: suppression of the initial oninput-event when opening the dialog...)
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cInput`)[0].value = _value;
			this.handleInput(_value);
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/**
	 * Sets the value of this customControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).value = _value; // programmatically setting a value doesn't trigger oninput event...
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}

	/**
	 * Toggles this elements disabled status
	 * @param  {boolean} _bool to set (true = disable)
	 */
	disable(_bool) {
		if (_bool) {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = true;
		} else {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = false;
		}
	}
}

/** Defining the dropdown variant of the customControl base template */
export class CustomDropdownControl extends CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _entries, _callBack = null) {
		super(_id, _containerId, _label, _unit, schemaDropdown, "ValueChanged", _callBack);
		this.entries = _entries;
		this.extendDefaultDomStructure(); // create needed DOM elements
		this.focusable = true;
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomDropdownControl";
	}

	/** Adds dropdown element to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<select>", {
			id: `${this.id}_cInput`,
			class: "cControl-Input cControl-Input-Dropdown",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		this.update(this.entries);

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => {
			this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value);
		}); // registerInputEventHandler

		this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value); // write active dropdown value on initialization
	}

	/**
	 * Write the provided _entries into the DOM element
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 */
	update(_entries) {
		this.entries = _entries;
		cId2jqSel(`${this.id}_cInput`).empty();
		this.entries.forEach((entry) => {
			const disabledOption = !!(entry.hasOwnProperty("disabled") && entry.disabled);
			$("<option>", {
				text: getTranslation(entry.caption),
				value: entry.value,
				disabled: disabledOption,
			}).appendTo(cId2jqSel(`${this.id}_cInput`));
		});
	}

	/**
	 * Sets the value of this customControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).value = _value; // programmatically setting a value doesn't trigger oninput event...
		// $("#"+this.id+"_cInput option").removeAttr('selected').filter('[value='+_value+']').attr('selected', true);
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}

	/**
	 * Toggles this elements disabled status
	 * @param  {boolean} _bool to set (true = disable)
	 */
	disable(_bool) {
		if (_bool) {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = true;
		} else {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = false;
		}
	}
}

/** Defining the dropdown variant of the customControl base template */
export class CustomDropdownControlWIthEmptyOption extends CustomDropdownControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _entries, _callBack = null) {
		super(_id, _containerId, _label, _unit, _entries, _callBack);
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomDropdownControlWIthEmptyOption";
	}

	/**
	 * Write the provided _entries into the DOM element
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 */
	update(_entries) {
		this.entries = _entries;
		cId2jqSel(`${this.id}_cInput`).empty();
		const mySelect = document.getElementById(`${this.id}_cInput`);
		mySelect.options[0] = new Option(getTranslation("modalDialog.cableDialog.empty-option"), null);
		this.entries.forEach((entry) => {
			const disabledOption = !!(entry.hasOwnProperty("disabled") && entry.disabled);
			$("<option>", {
				text: getTranslation(entry.caption),
				value: entry.value,
				disabled: disabledOption,
			}).appendTo(cId2jqSel(`${this.id}_cInput`));
		});
	}
}

/** Defining the checkbox variant of the customControl base template */
export class CustomCheckboxControl extends CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, "input", _callBack); // schemaCheckbox
		this.extendDefaultDomStructure(); // create needed DOM elements
		this.focusable = true;
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomCheckboxControl";
	}

	/** Adds text element to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<input>", {
			id: `${this.id}_cInput`,
			type: "checkbox",
			class: "cControl-Input cControl-Input-Checkbox",
			disabled: false,
			checked: null, // TODO set to a variables value if databound or keep null otherwise
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => {
			this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].checked);
		}); // registerInputEventHandler (benefit of doing it separately: suppression of the initial oninput-event when opening the dialog...)
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cInput`)[0].checked = _value;
			this.handleInput(_value);
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/**
	 * Sets the value of this customControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).checked = _value; // programmatically setting a value doesn't trigger oninput event...
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}

	/**
	 * Toggles this elements disabled status
	 * @param  {boolean} _bool to set (true = disable)
	 */
	disable(_bool) {
		if (_bool) {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = true;
		} else {
			cId2jqSel(`${this.id}_cInput`)[0].disabled = false;
		}
	}
}

/** Defining the label variant of the customControl base template */
export class CustomLabelControl extends CustomBaseControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, "ValueChanged", _callBack);
		this.extendDefaultDomStructure(); // create needed DOM elements
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomLabelControl";
	}

	/** Adds label element to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<span>", {
			// control label
			id: `${this.id}_cInput`,
			text: null,
			class: "cControl-Input cControl-Input-Label noselect",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => {
			this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].innerHTML);
		}); // registerInputEventHandler (benefit of doing it separately: suppression of the initial oninput-event when opening the dialog...)
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cInput`)[0].innerHTML = _value;
			this.handleInput(_value);
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/**
	 * Returns the value of this customTextControl
	 * @returns {object} value
	 */
	getValue() {
		return this.value;
	}

	/**
	 * Sets the value of this customLabelControl and calls the handleInput routine
	 * @param  {object} _value to set
	 */
	setValue(_value) {
		this.handleInput((cId2jqSel(`${this.id}_cInput`)[0].innerHTML = _value));
	}
}

/** Creates a label displaying text */
export class CustomInfoControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of the created element
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _message text to display
	 * @param  {customControlWarningModeEnum} _warningLevel color of message
	 */
	constructor(_id, _containerId, _message, _warningLevel) {
		this.validated = true; // Info labels can't be validated
		this.id = _id;
		this.event = `${this.id}_ValueChanged`;
		this.message = _message;
		this.containerId = checkExistenceNew(_containerId);
		this.warningLevel = _warningLevel;
		this.focusable = false;
		this.createDefaultDomStructure();
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomInfoControl";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			id: this.id,
			class: "cControl-Container cControl-InfoLabel",
		}).appendTo(cId2jqSel(this.containerId));

		$("<span>", {
			id: `${this.id}_cLabel`,
			text: getTranslation(this.message),
			class: `cControl-InfoLabelSpan noselect ${this.warningLevel.class}`,
		}).appendTo(cId2jqSel(this.id));
	}

	/**
	 * Sets the value of this CustomInfoControl
	 * @param  {string} _value to set
	 */
	setValue(_value) {
		cId2jqSel(`${this.id}_cLabel`)[0].innerHTML = _value;
	}

	/**
	 * Returns the name of the event this elements uses to submit data
	 * @returns {string} name of the event
	 */
	getEvent() {
		return this.event;
	}

	/**
	 * Switches this element (respectively it's text) between visible/invisible
	 * @param  {boolean} _mode to set (true: visible / false: invisible)
	 */
	toggleVisibility(_mode) {
		if (_mode) {
			cId2jqSel(`${this.id}`).removeClass("turnInvisible"); // @CL, I deleted _cLabel, because we do not need any placeholder
		} else {
			cId2jqSel(`${this.id}`).addClass("turnInvisible");
		}
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cLabel`)[0].innerHTML = _value;
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/** run this elements callback function */
	executeCallBack() {
		// I'm just here to avoid handling different customControl types when running all customControl callbacks from the parent dialog
	}

	/**
	 * Hides this control
	 */
	hide() {
		document.getElementById(`${this.id}_cContainer`).classList.add("turnInvisible");
	}

	/**
	 * Shows this control
	 */
	show() {
		document.getElementById(`${this.id}_cContainer`).classList.remove("turnInvisible");
	}

	/**
	 * Sets focus to this control
	 * TODO: does not inherit from baseClass atm
	 * @override
	 * @memberof CustomDataNodeHeader
	 */
	focus() {}
}

/** Creates a vertical space of standard customControl height */
export class CustomControlSpacer {
	/**
	 * Standard constructor
	 * @param  {string} _containerId to nest into
	 */
	constructor(_containerId) {
		this.id = "spacer";
		this.validated = true; // spacers can't be validated
		this.containerId = checkExistenceNew(_containerId);
		this.focusable = false;
		this.createDefaultDomStructure();
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomControlSpacer";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			class: "cControl-Container cControl-Spacer",
		}).appendTo(cId2jqSel(this.containerId));
	}

	/** run this elements callback function */
	executeCallBack() {
		// Spacers don't have a callback; I'm just here to avoid handling different customControl types when running all customControl callbacks from the parent dialog
	}

	/** do not set event handler for spacer*/
	setEventHandler() {}

	/**
	 * Sets focus to this control
	 * TODO: does not inherit from baseClass atm
	 * @override
	 * @memberof CustomDataNodeHeader
	 */
	focus() {}
}

/** Creates a horizontal line of standard customControl width */
class CustomControlDivider {
	/**
	 * Standard constructor
	 * @param  {string} _containerId to nest into
	 */
	constructor(_containerId) {
		this.validated = true; // lines can't be validated
		this.containerId = checkExistenceNew(_containerId);
		this.createDefaultDomStructure();
		this.focusable = false;
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomControlDivider";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			class: "cControl-Divider",
		}).appendTo(cId2jqSel(this.containerId));
	}

	/** run this elements callback function */
	executeCallBack() {
		// I'm just here to avoid handling different customControl types when running all customControl callbacks from the parent dialog
	}
}

/** Defining a header element for all devices */
export class CustomDataNodeHeader {
	/**
	 * Standard constructor
	 * @param  {string} _id of the created element
	 * @param  {string} _containerId to nest into
	 * @param  {dataNode} _dataNode that gets presented
	 */
	constructor(_id, _containerId, _dataNode) {
		this.validated = true; // headers can't (shouldn't need to) be validated
		this.id = _id;
		this.containerId = checkExistenceNew(_containerId);
		this.parentDialog = null;
		this.dataNode = _dataNode;
		this.focusable = false;
		this.createDefaultDomStructure();

		this.name = new CustomHeadLineControl("subName", `${this.id}_cContainer_Controls`, this.dataNode.getType() == "Connection" ? this.dataNode.name : "databound");
		this.materialNumber = new CustomLabelControlHeader(
			"subMaterialNumber",
			`${this.id}_cContainer_Controls`,
			"modalDialog.material-number",
			technicalUnitEnum.EMPTY,
			schemaGenericName,
		);
		if (
			this.dataNode.materialNumber
				? this.materialNumber.setValue(this.dataNode.materialNumber)
				: this.materialNumber.setValue(getTranslation("modalDialog.no-materialNumber"))
		);
		this.description = new CustomInfoControl(
			"subDescription",
			`${this.id}_cContainer_Controls`,
			this.dataNode.getType() == "Connection" ? this.dataNode.description : "databound",
			customControlWarningModeEnum.NORMAL,
		);
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomDataNodeHeader";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			// control container
			id: `${this.id}_cContainer`,
			class: "cControl-PropertyHeader cControl-Container noselect",
		}).appendTo(cId2jqSel(this.containerId));

		let tmpImage = null;
		switch (this.dataNode.getType()) {
			case "Connection":
				tmpImage = cableIcon;
				break;
			case "AssemblyNode":
				tmpImage = this.dataNode.graphics.icon.file;
				break;
			case "InfrastructureNode":
				tmpImage = this.dataNode.graphics.icon.file;
				break;
			default:
				tmpImage = this.dataNode.graphics.activeGraphics.file;
				break;
		}

		$("<img>", {
			id: `${this.id}_cImage`,
			class: "cControl-PropertyHeader-Thumbnail",
			src: tmpImage,
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		$("<div>", {
			// control container
			id: `${this.id}_cContainer_Controls`,
			class: "cControl-PropertyHeader-SubContainer",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));
	}

	/**
	 * Wrapper for eventHandler for this elements sub-control description
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setDescriptionEventHandler(_eventName) {
		return this.description.setEventHandler(_eventName);
	}

	/**
	 * Wrapper for eventHandler for this elements sub-control name
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setNameEventHandler(_eventName) {
		return this.name.setEventHandler(_eventName);
	}

	/**
	 * Sets focus to this control
	 * TODO: does not inherit from baseClass atm
	 * @override
	 * @memberof CustomDataNodeHeader
	 */
	focus() {}

	/** run this elements callback function */
	executeCallBack() {
		// doesn't need a callBack, this elements name and description properties are solely for displaying. Within the dialog there are two other controls handling the data binding
	}
}

/** Variant of the CustomLabelControl with a much smaller distance between the label and input elements, better suited for headers */
class CustomLabelControlHeader extends CustomLabelControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of customControl that gets created
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, null);
		// replace existing label class with a smaller version
		document.getElementById(`${this.id}_cLabel`).classList.remove("cControl-Label");
		document.getElementById(`${this.id}_cLabel`).classList.add("cControl-Header-Label");
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomLabelControlHeader";
	}
}

/** Creates a bold label displaying some text (usually as a headline) */
export class CustomHeadLineControl {
	/**
	 * Standard constructor
	 * @param  {string} _id of the created element
	 * @param  {string} _containerId to nest into
	 * @param  {i18nKey} _text to display
	 */
	constructor(_id, _containerId, _text) {
		this.validated = true; // headings can't be validated
		this.id = _id;
		this.text = _text;
		this.containerId = checkExistenceNew(_containerId);
		this.focusable = false;
		this.createDefaultDomStructure();
	}

	/**
	 * Returns the type/name of this class
	 * @returns  {string} type/name of class
	 */
	getType() {
		return "CustomHeadLineControl";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			id: this.id,
			class: "cControl-Container cControl-HeadLine",
		}).appendTo(cId2jqSel(this.containerId));

		$("<span>", {
			id: `${this.id}_cLabel`,
			text: getTranslation(this.text),
			class: "cControl-HeadlineSpan noselect",
		}).appendTo(cId2jqSel(this.id));
	}

	/**
	 * Hooks an eventHandler for this elements input
	 * @param  {string} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		/**
		 *
		 * @param _value
		 */
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cLabel`)[0].innerHTML = _value;
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler); // checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}

	/** run this elements callback function */
	executeCallBack() {
		// I'm just here to avoid handling different customControl types when running all customControl callbacks from the parent dialog
	}

	/**
	 * Sets the text of this customControl
	 * @param  {string} _value to set
	 */
	setValue(_value) {
		cId2jqSel(this.id + "_cLabel").text(_value);
	}

	/**
	 * sets title
	 * @param  {string} _tooltip - title
	 */
	setTooltip(_tooltip) {
		cId2jqSel(this.id + "_cLabel").attr("title", _tooltip);
	}

	/**
	 * Hides this control
	 */
	hide() {
		document.getElementById(`${this.id}_cContainer`).classList.add("turnInvisible");
	}

	/**
	 * Shows this control
	 */
	show() {
		document.getElementById(`${this.id}_cContainer`).classList.remove("turnInvisible");
	}

	/**
	 * Sets focus to this control
	 * TODO: does not inherit from baseClass atm
	 * @override
	 * @memberof CustomDataNodeHeader
	 */
	focus() {}
}
