import {NodeGraphicsElement} from "../../graphics"; // convert to webpack import
import tmpTrashNodeIcon from "../../../images/outlinerIcons/trashNode.icon.svg";

const tmpTrashNodeImage = null; // convert to webpack import
const tmpTrashNodeSymbol = null; // convert to webpack import

export const trashNodeTemplate = {
	databaseId: -300,
	UUID: "trsh",
	type: "TrashNode",
	graphics: {
		image: new NodeGraphicsElement(tmpTrashNodeImage, null, null),
		symbol: new NodeGraphicsElement(tmpTrashNodeSymbol, null, null),
		icon: new NodeGraphicsElement(tmpTrashNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "TrashNodeToolTip",
	},

	i18nKey: "outliner.trash",
	description: "",
	ports: [],
	decentralControl: null,
};
