import {NodeGraphicsElement} from "../../graphics";

import tmpUnitNodeImage from "../../../images/canvasImages/unitNode.image.svg"; // convert to webpack import
import tmpUnitNodeSymbol from "../../../images/canvasImages/unitNode.symbol.svg"; // convert to webpack import
import tmpUnitNodeIcon from "../../../images/outlinerIcons/unitNode.icon.svg"; // convert to webpack import

export const unitNodeTemplate = {
	databaseId: -500,
	type: "UnitNode",
	graphics: {
		image: new NodeGraphicsElement(tmpUnitNodeImage, 614.81 * 0.2, 614.81 * 0.2),
		symbol: new NodeGraphicsElement(tmpUnitNodeSymbol, null, null),
		icon: new NodeGraphicsElement(tmpUnitNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "UnitNodeToolTip",
	},

	i18nKey: "dataManager.defaultname-unitNode",
	description: "",
	ports: [],
	referenceDesignator: {deviceToken: "U"},
	decentralControl: false,
};
