import {Application} from "../constantsAndEnumerations2";
import cableIcon from "../../images/cable.svg";
import {PortCompatibilityChecker} from "../compatibilityChecks/PortCompatibilityChecker";
import {interactionPresetsEnum} from "../constantsAndEnumerations";
import {EventManager} from "../eventManager";
import {portFactory} from "../ports/portFactory";
import {CableReferenceDesignator} from "../referenceDesignatorManager";

import type {BaseConnectionData, BaseConnectionSourceData, BaseConnectionSaveData} from "./baseConnection.schema";
import type {AssemblyNode, InfrastructureNode} from "../dataNode";
import type {Interface as BaseInterface} from "../interfaces/interface";
import type {Port as BasePort} from "../ports/port";
import type {PortData} from "../ports/port.schema";
import type {BasePortSourceData} from "../ports/basePort.schema";
import {interfaceFactory} from "../interfaces/interfaceFactory";

//REFACTOR projectInterfaces is not sustainable, a better way is described in jira under LQSK-1508

/** Base class describing a general connection between two Nodes. */
export abstract class BaseConnection {
	private readonly __application: Application;
	private readonly ____databaseId: number;
	private __description: string;
	private readonly __eventManager = new EventManager();
	private __geometry: object;
	private readonly __graphics: any; //REFACTOR needs typing
	private __parentUUID: UUID | null = null; //! remove, replaced by __parent
	private __parent: AssemblyNode | InfrastructureNode | null = null; //! replaces __parentUUID
	private readonly __interactionPreset = interactionPresetsEnum.CONNECTION; //REFACTOR should be stored only as "CONNECTION" (similar to application, but here we always have "CONNECTION" and not a keyof typeof enum)
	private __interfaces: BaseInterface[] = [];
	private readonly __isArchived: boolean;
	private __length: number;
	private readonly __minLength: number;
	private readonly __maxLength: number;
	private readonly __manufacturer: string;
	private readonly __materialNumber: string;
	private readonly __name: string;
	public readonly cleanName: string; //! just a temporary helper until the db fixes it's names (regular name stripped of all additional infos that are stored elsewhere, like shielded, composition, ...)
	private __referenceDesignator: CableReferenceDesignator; //? does this need a setter/getter/private readonly??? Not sure how to handle that...
	private readonly __ports: BasePort[] = [];
	private __sourcePort: BasePort | null = null; // set dynamically on connection
	private __targetPort: BasePort | null = null; // set dynamically on connection
	private readonly __type: string = "Connection";
	private readonly __UUID: UUID;

	/**
	 * Creates a new instance of BaseConnection.
	 * @param {BaseConnectionData} _connectionData dataset describing a base connection
	 */
	constructor(_connectionData: BaseConnectionData) {
		this.__UUID = _connectionData.UUID;
		this.__application = Application[_connectionData.application];
		this.____databaseId = _connectionData.databaseId; //REFACTOR deliberately with ____ and a getter with __; this will help to identify all places where we use __databaseId -> we shouldn't use __databaseId on the client anywhere and successively remove all occurrences and then set __databaseId to private readonly without a getter;
		this.__description = _connectionData.description;
		this.__geometry = _connectionData.geometry; //REFACTOR It would be nice, to hide that somewhere in graphics -> we need a cableGraphics Object
		this.__graphics = {icon: {file: cableIcon}}; //REFACTOR should be handled like all dataNode graphics (already prepared in sourceData) and filled by _connectionData.graphics
		this.__isArchived = _connectionData.isArchived;
		this.__length = _connectionData.length; //REFACTOR length/minLength/maxLength is a perfect example for customType
		this.__minLength = _connectionData.minLength;
		this.__maxLength = _connectionData.maxLength;
		this.__manufacturer = _connectionData.manufacturer;
		this.__materialNumber = _connectionData.materialNumber;
		this.__name = _connectionData.name;
		this.cleanName = _connectionData.cleanName; //! remove once the db fixes the regular names
		this.__referenceDesignator = new CableReferenceDesignator(this.UUID, _connectionData.referenceDesignator.deviceComponent.token);

		_connectionData.ports.forEach((_portData: BasePortSourceData) => {
			this.addPort(portFactory(_portData));
		});

		this.ports.forEach((port) =>
			port.eventManager.addHandler("port.isConnectedTo.changed", (port) => {
				this.portIsConnectedToChangedHandler(port);
			}),
		);
	}

	/**
	 * Returns the UUID of this connection.
	 */
	get UUID(): UUID {
		return this.__UUID;
	}

	/**
	 * Returns the parent DataGroupNode of this connection.
	 */
	get parent(): AssemblyNode | InfrastructureNode {
		return this.__parent;
	}

	/**
	 * Sets the parent DataGroupNode of this connection.
	 */
	set parent(_dataGroupNode: AssemblyNode | InfrastructureNode) {
		if (this.__parent === _dataGroupNode) return;
		this.__parent = _dataGroupNode;
		this.eventManager.dispatch("connection.parent.changed");
	}

	/**
	 * Returns the UUID of this connections parent-DataGroupNode.
	 * @deprecated Replaced by get parent!
	 */
	get parentUUID(): UUID | null {
		//REFACTOR remove
		return this.__parentUUID;
	}

	/**
	 * Sets the UUID of this connections parent-DataGroupNode.
	 * @deprecated Replaced by set parent!
	 */
	set parentUUID(_UUID: UUID) {
		//REFACTOR remove
		if (this.__parentUUID === _UUID) return;
		this.__parentUUID = _UUID;
	}

	/**
	 * Returns the application of this connection.
	 */
	get application(): Application {
		return this.__application;
	}

	/**
	 * Returns the databaseId of this connection.
	 * @deprecated Exists just for legacy reasons - don't use this!
	 */
	get __databaseId(): number {
		//REFACTOR deliberately with __ -> remove this getter once all occurrences of __databaseId have been removed in the client
		return this.____databaseId;
	}

	/**
	 * Returns the description of this connection.
	 */
	get description(): string {
		return this.__description;
	}

	/**
	 * Sets the description of this connection.
	 * @param {string} _description description
	 */
	set description(_description: string) {
		if (this.__description === _description) return;
		this.__description = _description;
		this.eventManager.dispatch("connection.description.changed");
	}

	/**
	 * Returns the local EventManager of this connection.
	 */
	get eventManager(): EventManager {
		return this.__eventManager;
	}

	/**
	 * Returns the geometry of this connection (in default jspEdge geometry format).
	 */
	get geometry(): object {
		//REFACTOR add jsp type
		return this.__geometry;
	}

	/**
	 * Sets the geometry of this connection.
	 * @param {object} _geometry geometry in default jspEdge geometry format
	 */
	set geometry(_geometry: object) {
		//REFACTOR add jsp type
		if (this.__geometry === _geometry) return; //TODO this may not work, '===' comparisons for nested objects/array are fishy; check that!
		this.__geometry = _geometry;
		this.eventManager.dispatch("connection.geometry.changed");
	}

	/**
	 * Returns the graphics of this connection.											//REFACTOR needs correct typing
	 */
	get graphics(): any {
		//REFACTOR should be merged with geometry to a 'real' graphics property like on dataNode (would need a ConnectionGraphics class derived from graphics class)
		return this.__graphics;
	}

	/**
	 * Returns the interactionPreset of this connection.
	 */
	get interactionPreset(): typeof interactionPresetsEnum.CONNECTION {
		//! should either always return interactionPresetsEnum.CONNECTION or only "CONNECTION" -> how to correctly type that?
		return this.__interactionPreset;
	}

	/**
	 * Returns the interfaces of this connection.
	 */
	get interfaces(): BaseInterface[] {
		return this.__interfaces;
	}

	/**
	 * Returns the isArchived status of this connection.
	 */
	get isArchived(): boolean {
		return this.__isArchived;
	}

	/**
	 * Returns the length [mm] of this connection.
	 */
	get length(): number {
		return this.__length;
	}

	/**
	 * Sets the length [mm] of this connection.
	 * @warning to be replaced by a CustomType in the future
	 * @param {number} _length length
	 */
	set length(_length: number) {
		if (this.__length === _length) return;

		if (_length < this.__minLength) throw new Error(`Connection length=${_length} must be ≥ ${this.__minLength}.`);
		if (_length > this.__maxLength) throw new Error(`Connection length=${_length} must be ≤ ${this.__maxLength}.`);
		if (_length % 10 !== 0) throw new Error(`Connection length=${_length} is not in whole centimeters. Necessary to correctly encode materialNumber.`);

		this.__length = _length;
		this.eventManager.dispatch("connection.length.changed");
	}

	/**
	 * Returns the minimum length [mm] of this connection.
	 * @warning to be replaced by a CustomType in the future
	 */
	get minLength(): number {
		return this.__minLength;
	}

	/**
	 * Returns the maximum length [mm] of this connection.
	 * @warning to be replaced by a CustomType in the future
	 */
	get maxLength(): number {
		return this.__maxLength;
	}

	/**
	 * Returns the manufacturer of this connection.
	 */
	get manufacturer(): string {
		return this.__manufacturer;
	}

	/**
	 * Returns the materialNumber of this connection.
	 */
	get materialNumber(): string {
		//* cable materialNumbers (1234567-xxxxx) consist of a fixed 7-digit-id and 5-digit-length in cm (padded with leading zeros)
		const newLength = String(this.__length / 10).padStart(5, "0"); // convert actual length from [mm] to [cm], convert to string and pad with zeros
		const oldLength = this.__materialNumber.split("-").pop(); // get existing 5-digit length component of materialNumber (usually defaults to xxxxx)
		return this.__materialNumber.replace(oldLength, newLength);
	}

	/**
	 * Returns name of this connection.
	 */
	get name(): string {
		return this.__name;
	}

	/**
	 * Returns the referenceDesignator of this connection.
	 */
	get referenceDesignator(): CableReferenceDesignator {
		return this.__referenceDesignator;
	}

	/**
	 * Set the referenceDesignator this connection.
	 */
	set referenceDesignator(_something: any) {
		//? not sure how this is used... Maybe a getter would be enough since the refDes gets handled by its own class (like labels)
		if (this.__referenceDesignator === _something) return;
		this.__referenceDesignator = _something;
	}

	/**
	 * Returns the ports of this connection.
	 */
	get ports(): BasePort[] {
		return this.__ports;
	}

	/**
	 * Returns the sourcePort of this connection.
	 */
	get sourcePort(): BasePort {
		return this.__sourcePort;
	}

	/**
	 * Returns the targetPort of this connection.
	 */
	get targetPort(): BasePort {
		return this.__targetPort;
	}

	/**
	 * Returns the type of this connection.
	 */
	get type(): string {
		return this.__type;
	}

	/**
	 * Adds a port to this connection.
	 * @param {BasePort} _port to add
	 */
	private addPort(_port: BasePort): void {
		this.__ports.push(_port);
		_port.parent = this;
	}

	/**
	 * Adds an interface to this connection.
	 * @param {BaseInterface} _interface interface to add
	 */
	protected addInterface(_interface: BaseInterface): void {
		this.__interfaces.push(_interface);
		_interface.parent = this;
	}

	/**
	 * Removes an interface from this connection.
	 * @param {BaseInterface} _interface interface to remove
	 */
	protected removeInterface(_interface: BaseInterface): void {
		this.__interfaces = this.__interfaces.filter((tmpInterface) => tmpInterface.UUID !== _interface.UUID);
	}

	/**
	 * Reduces an array of interfaces to a flat array of interface type strings
	 * Used for quick and dirty interface comparison
	 * ! We probably should not rely on this approach carelessly in production
	 * @returns {Array<string>} array of interface.type strings
	 */
	public flattenInterfaces(): string[] {
		return this.interfaces.map((_interface) => _interface.type.type);
	}

	/**
	 * Returns the type of this class.
	 * @returns {string} "Connection"
	 * @deprecated replaced by get type()
	 */
	public getType(): string {
		return "Connection";
	}

	/**
	 * Returns the variable characteristics of this base connection.
	 * @returns {BaseConnectionSaveData} object containing all info
	 */
	protected save(): BaseConnectionSaveData {
		return {
			application: this.application,
			databaseId: this.____databaseId,
			description: this.description,
			geometry: this.geometry,
			length: this.length,
			ports: this.ports.map((_basePort) => {
				const tmpPortSave = _basePort.save();
				_basePort.interfaces = []; // don't save interfaces on connections and their ports
				return tmpPortSave;
			}),
			sourceDevicePortUUID: this.targetPort.isConnectedTo.UUID,
			targetDevicePortUUID: this.sourcePort.isConnectedTo.UUID,
			UUID: this.UUID,
		};
	}

	/**
	 * Updates interfaces of this connection when sourcePort/targetPort get connected.
	 * @param {BasePort} _port that fired a isConnectedTo changed event
	 * @listens port.isConnectedTo.changed
	 */
	private portIsConnectedToChangedHandler(_port: BasePort): void {
		//REFACTOR This is just quick&dirty. A correct approach would project the interfaces along exact leads (which would need better sourcedata)
		// handle initial connection of both ports - set sourcePort/targetPort
		const devicePort = _port.isConnectedTo;
		if (devicePort.side === "SOURCE") {
			_port.side = "TARGET";
			this.__targetPort = _port;
		}

		if (devicePort.side === "TARGET") {
			_port.side = "SOURCE";
			this.__sourcePort = _port;
		}

		// abort further processing until both ports have been connected
		for (const port of this.ports) {
			if (port.isConnectedTo === null) return;
		}

		// --- now all ports have been connected, continue processing ---

		const sourceDevicePort = this.targetPort.isConnectedTo;
		const targetDevicePort = this.sourcePort.isConnectedTo;
		// 1st: project all interfaces needed by targetDevicePort and provided by sourceDevicePort
		targetDevicePort.interfaces.forEach((targetInterface) => {
			// find targetInterface on sourceDevicePort
			const sourceInterface = sourceDevicePort.interfaces.find((sourceInterface) => sourceInterface.type.type === targetInterface.type.type);
			this.targetPort.addInterface(interfaceFactory(sourceInterface.clone()));
			this.addInterface(interfaceFactory(sourceInterface.clone()));
			this.sourcePort.addInterface(interfaceFactory(sourceInterface.clone()));
		});

		// 2nd: try to project any interfaces sourceDevicePort additionally offers along the connection (as long as they are mappable)
		const unProjectedSourceInterfaces = sourceDevicePort.interfaces.filter(
			(sourcePortInterface) => !targetDevicePort.interfaces.find((targetPortInterface) => targetPortInterface.type.type === sourcePortInterface.type.type),
		);

		unProjectedSourceInterfaces.forEach((sourceInterface) => {
			try {
				this.targetPort.addInterface(interfaceFactory(sourceInterface.clone()));
				this.addInterface(interfaceFactory(sourceInterface.clone()));
				this.sourcePort.addInterface(interfaceFactory(sourceInterface.clone()));
			} catch {
				// eslint-disable-next-line no-console
				console.warn(`Could not project non-essential interface "${sourceInterface.name}" on some/all parts of connection.`);
			}
		});

		// check if projection succeeded
		const validConnection = new PortCompatibilityChecker().autoCheck(this.sourcePort, this.sourcePort.isConnectedTo);
		if (!validConnection) throw new Error("No valid connection");
	}
}
