import dragHandleIcon from "../../../images/dragHandle.svg";
import "./dragListItem.css";


/**
 * Base-class of listItems.
 * Used in conjunction with DragListControl.
 * @class DragListItemBase
 */
export class DragListItemBase {
	/**
	 * Creates an instance of DragListItemBase.
	 * @param {object} _payload Object containing id and content of the ListItem.
	 * @param {string} _payload.id id of the ListItem.
	 * @param {string} _payload.content content of the ListItem.
	 * @returns {HTMLElement} completed ListItem.
	 * @memberof DragListItemBase
	 */
	constructor(_payload = null) {
		return this.__createListItem(_payload);
	}

	/**
	 * Creates a ListItem with given payload.
	 * @param {object} _payload Object containing id and content of the ListItem.
	 * @returns {HTMLElement} completed ListItem
	 * @memberof DragListItemBase
	 */
	__createListItem(_payload) {
		const div = document.createElement("div");
		div.classList.add("listItem-wrapper");
		div.setAttribute("draggable", true);
		div.innerHTML = `
			<div id="js-ghostDivTop" class="listItem-ghostDivTop" data-blocked= "false"></div>
			<div id="js-ghostDivBottom" class="listItem-ghostDivBottom" data-blocked= "false"></div>
			<li class="dragListItem">
				<div class="dragListItem-contentContainer">
				${this.__createPayload(_payload)}
				<img src=${dragHandleIcon} class="dragListItem-contentContainer-handle">
				</div>
			</li>
		`;
		return div;
	}

	/**
	 * Creates a String containing HTML code representing the payload.
	 * @returns {string} returns empty String as a base-class default.
	 * @memberof DragListItemBase
	 */
	__createPayload() {
		return "";
	}
}
