import * as React from 			        "react";
import {withTranslation} from 			"react-i18next";
import IconButton from 							"@mui/material/IconButton";
import Tooltip from 								"@mui/material/Tooltip";
import {SvgIconTypeMap} from 				"@mui/material";
import {OverridableComponent} from 	"@mui/material/OverridableComponent";

type MenuButtonProps = {
	title: string,
	icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string;}|unknown,
	disabled?: boolean,
	callback?(event: React.MouseEvent<HTMLButtonElement>): void,
	t: Function,
};

const style = {	// placeholder for Icon before Promise fulfilled
	width: "1.75rem",
	height: "1.75rem",
}

/**
 * jsx for menu button
 * @returns {React.ReactElement} menu button
 */
 function MenuButton(props: MenuButtonProps): React.ReactElement {
	const Icon = props.icon as OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string;};
	return (
		<Tooltip title={props.t(props.title)} arrow  enterDelay={500} enterNextDelay={500}>
			<div className="menu-button-box">
				<IconButton size="small" className="menu-button" color="inherit" disabled={props.disabled} onClick={props.callback} centerRipple={false}>
					{Icon ? <Icon /> : <div style={style} ></div>}
				</IconButton>
			</div>
		</Tooltip>
	)
 }
 export default withTranslation()(MenuButton);
