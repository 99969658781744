import {createUUID, dataRoot} from "../dataManager";
import {portFactory} from "./portFactory";
import type {Port} from "./port";
import type {PortSourceData} from "./port.schema";
import type {Application} from "../constantsAndEnumerations2";

export enum PortFamily { //REFACTOR I think this should be called PortType
	XTEC15 = "XTEC15",
	XTEC23 = "XTEC23",
	XTEC32 = "XTEC32",
	M12A = "M12A",
	M12B = "M12B",
	M12D = "M12D",
	M12L = "M12L",
	M12T = "M12T",
	OPEN = "OPEN",
	RIBBON = "RIBBON",
	RJ45 = "RJ45",
	INCH78 = "INCH78",
}

export enum PortGender { //REFACTOR Remove OPEN from PortGender
	FEMALE = "FEMALE",
	MALE = "MALE",
	OPEN = "OPEN",
}

export enum PortMount { //REFACTOR Remove UNIVERSAL from PortMount (unless somebody convinces me otherwise)
	DEVICE = "DEVICE",
	CONNECTION = "CONNECTION",
	UNIVERSAL = "UNIVERSAL",
}

export enum PortSide {
	SOURCE = "SOURCE",
	TARGET = "TARGET",
}

interface SourcePortAttributes {
	application: Application;
	family: PortFamily;
	gender: PortGender;
	isShielded: boolean;
	mount: PortMount;
}

/** Returns sourcePortData matching the provided attributes. */
export function filterSourcePorts(_attributes: Partial<SourcePortAttributes>): PortSourceData[] {
	let result = dataRoot.portSourceData as PortSourceData[];

	result = _attributes.application !== undefined ? result.filter((_sourcePort: PortSourceData) => _sourcePort.application === _attributes.application) : result;
	result = _attributes.family !== undefined ? result.filter((_sourcePort: PortSourceData) => _sourcePort.family === _attributes.family) : result;
	result = _attributes.gender !== undefined ? result.filter((_sourcePort: PortSourceData) => _sourcePort.gender === _attributes.gender) : result;
	result = _attributes.isShielded !== undefined ? result.filter((_sourcePort: PortSourceData) => _sourcePort.isShielded === _attributes.isShielded) : result;
	result = _attributes.mount !== undefined ? result.filter((_sourcePort: PortSourceData) => _sourcePort.mount === _attributes.mount) : result;

	result.map((_sourcePort) => structuredClone(_sourcePort));

	return result;
}

/** Takes a port and returns a new Port of the opposite side and gender (or open if corresponding argument is passed). */
export function invertPort(_port: Port, _mount: PortMount, _forceOpen = false): Port | never {
	//JIRA LQSK-1686 Without a mount info, it's not possible to identify the correct port variant within sourceData
	const newGender = _forceOpen ? PortGender.OPEN : invertPortGender(_port.gender); //! if OPEN would use MALE/FEMALE this expression would be unnecessary
	const newFamily = _forceOpen ? PortFamily.OPEN : _port.family;

	const possiblePorts = filterSourcePorts({application: _port.application, family: newFamily, gender: newGender, isShielded: _port.isShielded}); // ! here a check for mount is missing, replace with code below once mount is correctly provided by server
	// const possiblePorts = filterSourcePorts({application: _port.application, family: newFamily, gender: newGender, isShielded: _port.isShielded, mount: _mount});

	if (possiblePorts.length !== 1)
		console.error(`Could not invertPort ${_port.name} [${_port.UUID}]. ${possiblePorts.length === 0 ? "No" : "Multiple"} matching sourcePort(s) found.`); //! once mount is working, replace with code below (right now throwing errors would trigger way to often for _forceOpen = false)
	// throw new Error(`Could not invertPort ${_port.name} [${_port.UUID}]. ${possiblePorts.length === 0 ? "No" : "Multiple"} matching sourcePort(s) found.`);

	const invertedPortData = {
		...structuredClone(possiblePorts[0]),
		side: invertPortSide(_port.side),
		UUID: createUUID(), // make that null as soon as portFactory can handle it
		interfaces: _port.clone().interfaces,
	};

	const invertedPort = portFactory(invertedPortData);

	return invertedPort;
}

/** Returns the opposite gender of given port. */
export function invertPortGender(_gender: PortGender): PortGender | never {
	// return (_gender === PortGender.MALE ? PortGender.FEMALE : PortGender.MALE) //! version without OPEN case, replace code below once gender.OPEN is removed, don't forget to remove never as a return type
	switch (true) {
		case _gender === PortGender.MALE:
			return PortGender.FEMALE;
		case _gender === PortGender.FEMALE:
			return PortGender.MALE;
		case _gender === PortGender.OPEN:
			return PortGender.OPEN;
		default:
			throw new Error("Could not invert port gender.");
	}
}

/** Returns the opposite side of given port. */
export const invertPortSide = (_side: PortSide): PortSide => (_side === PortSide.SOURCE ? PortSide.TARGET : PortSide.SOURCE);
