import * as React from 			        "react";
import {withTranslation} from 			"react-i18next";
import ToggleButton from 						"@mui/material/ToggleButton";
import Tooltip from 								"@mui/material/Tooltip";
import {SvgIconTypeMap} from 				"@mui/material";
import {OverridableComponent} from 	"@mui/material/OverridableComponent";

type MenuToggleButtonProps = {
	title: string,
	icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {muiName: string;},
	disabled: boolean,
	value: string,
	selected: boolean,
	callback(event: React.MouseEvent<HTMLElement>): void,
	t: Function,
}

/**
 * jsx for toggle button
 * @returns {React.ReactElement} toggle button
 */
 function MenuToggleButton(props: MenuToggleButtonProps): React.ReactElement {
	const Icon = props.icon;
	return (
		<Tooltip title={props.t(props.title)} arrow enterDelay={500} enterNextDelay={500}>
			<div className="menu-button-box">
				<ToggleButton className="menu-toggle-button" size="small" color="error" value={props.value} selected={props.selected} disabled={props.disabled}
					onChange={props.callback}>
						<Icon />
				</ToggleButton>
			</div>
		</Tooltip>
		)
 }
 export default withTranslation()(MenuToggleButton);