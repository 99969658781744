import {GLOBALEVENTMANAGER} from "./applicationManager";
import {eUpdateCanvasReferenceDesignator} from "./canvasManager";
import {getDataNodeByUUID, getUniqueDataNodeByType} from "./dataManager";
import {eUpdateJspNodeReferenceDesignator} from "./jsPlumb/jspManager";
import {eUpdateTreeNodeReferenceDesignator} from "./GUI/outliner/outliner";

/* Provides functionality to construct & manage referenceDesignators (BMK)
 *
 * CONCEPT
 *		referenceDesignator:	[functionComponent] [placeComponent] [deviceComponent] [portComponent]:	[=][A][#] [+][P] [-][M][#] [:][X][#]
 *		[functionComponent]:	[functionPrefix] [functionToken] [functionNumber]:	[=][A][#]
 *		[placeComponent]:		[placePrefix] [placeToken]:							[+][P]
 *		[deviceComponent]:		[devicePrefix] [deviceToken] [deviceNumber]:		[-][M][#]
 *		[portComponent]:		[portPrefix] [portToken][portNumber]:				[:][X][#]
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		Load defaults from server
 *		Some general thoughts: We have to differentiate between "our" default reference designator scheme (DRDS) and user-specific schemes (URDS)
 *		Login in the first time, our DRDS is copied to the user directory and made his default URDS which is loaded for new projects as a default.
 *		Further changes to URDS will get stored in the user directory. In the long run we should consider implementing a concept to allow users to create/store/edit various URDS.
 *		That would allow for project specific schemata (pE Trumpf-specific, Daimler-specific etc...). But regardless of that, we need to define a default URDS that is loaded without user interaction
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

const referenceDesignatorRoot = {
	// top level referenceDesignatorManager object (analogous to dataRoot of dataManager)
	referenceDesignatorList: [], // array of all referenceDesignators
};

/** Standard initialization routine (load templates from server, setting event handlers etc..) */
export function initializeReferenceDesignatorManager() {
	GLOBALEVENTMANAGER.addHandler("eRDM_ReferenceDesignatorChanged", eUpdateJspNodeReferenceDesignator);
	GLOBALEVENTMANAGER.addHandler("eRDM_ReferenceDesignatorChanged", eUpdateTreeNodeReferenceDesignator);
	GLOBALEVENTMANAGER.addHandler("eRDM_ReferenceDesignatorChanged", eUpdateCanvasReferenceDesignator);
	GLOBALEVENTMANAGER.addHandler("eRDM_ReferenceDesignatorChanged", eUpdateCanvasReferenceDesignator);
}

/** Standard reset routine (setting Manager back to initial values etc..) */
function resetReferenceDesignatorManager() {
	console.debug(referenceDesignatorRoot);
}

/** Base class representing a reference Designator */
class BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators DataNode
	 */
	constructor(_associatedDataNodeUUID) {
		this.functionComponent = {
			prefix: null,
			token: null,
			number: null,
			/**
			 *
			 */
			string: () => this.functionComponent.prefix + this.functionComponent.token + this.functionComponent.number,
			type: "functionComponent",
		};

		this.placeComponent = {
			prefix: getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getPlaceComponentTemplate().prefix,
			token: null,
			/**
			 *
			 */
			string: () => this.placeComponent.prefix + this.placeComponent.token,
			type: "placeComponent",
		};

		this.deviceComponent = {
			prefix: getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getDeviceComponentTemplate().prefix,
			token: null,
			number: null,
			/**
			 *
			 */
			string: () => this.deviceComponent.prefix + this.deviceComponent.token + this.deviceComponent.number,
			type: "deviceComponent",
		};

		this.UUID = _associatedDataNodeUUID;
	}

	// ----------------------------------------------------

	/** Returns this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler */
	getNumberingDependendComponent() {
		console.debug(`${this.getType()} getNumberingDependendComponent`);
	}

	/**
	 * Sets the token of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentToken to set
	 */
	setNumberingDependendComponentToken(_componentToken) {
		if (this.getNumberingDependendComponent().token == _componentToken) return; // abort if nothing has changed
		this.getNumberingDependendComponent().token = _componentToken;
		GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", getDataNodeByUUID(this.UUID)); // raise update event for _dataNode
	}

	/**
	 * Sets the number of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentNumber to set
	 */
	setNumberingDependendComponentNumber(_componentNumber) {
		if (this.getNumberingDependendComponent().number == _componentNumber) return; // abort if nothing has changed
		this.getNumberingDependendComponent().number = _componentNumber;
		GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", getDataNodeByUUID(this.UUID)); // raise update event for _dataNode
	}

	/**
	 * Sets the placeComponent  of this elements referenceDesignator (normally inherited from a superior dataGroupNode aka projectNode or AssemblyNode)
	 * @param {string} _placeToken to set
	 */
	setPlaceToken(_placeToken) {
		if (this.placeComponent.token == _placeToken) return; // abort if nothing has changed
		this.placeComponent.token = _placeToken;
		GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", getDataNodeByUUID(this.UUID)); // raise update event for _dataNode
	}

	/**
	 * Sets this elements referenceDesignator, overriding the automatic routines
	 * @param {string} _referenceDesignatorString to set
	 */
	overrideAutomaticReferenceDesignator(_referenceDesignatorString) {
		const tmpReferenceDesignator = parseReferenceDesignatorString(
			_referenceDesignatorString,
			getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getTemplate(),
		);
		this.setPlaceToken(tmpReferenceDesignator.placeComponent.token);

		this.setNumberingDependendComponentToken(tmpReferenceDesignator[this.getNumberingDependendComponent().type].token);
		this.setNumberingDependendComponentNumber(tmpReferenceDesignator[this.getNumberingDependendComponent().type].number);

		// fucking horrible exception for infrastructure Nodes (which don't have a "real" parent)
		if (getDataNodeByUUID(this.UUID).parentUUID == "root") {
			getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.edit(getDataNodeByUUID(this.UUID));
		} else {
			getDataNodeByUUID(getDataNodeByUUID(this.UUID).parentUUID).referenceDesignatorHandler.edit(getDataNodeByUUID(this.UUID));
		}
	}

	/**
	 * @returns {object} referenceDesignator of this dataNode
	 */
	getReferenceDesignator() {
		return {
			functionComponent: getDataNodeByUUID(this.UUID).getParentGroupNode().referenceDesignator.functionComponent,
			placeComponent: this.placeComponent,
			deviceComponent: this.deviceComponent,
			/**
			 *
			 */
			string: () => {
				if (getDataNodeByUUID(this.UUID).getParentGroupNode().nodeType == "TrashNode") {
					return "- Trashed -"; // TODO Ugly but working, implement a better solution in the future
				} else {
					return (
						getDataNodeByUUID(this.UUID).getParentGroupNode().referenceDesignator.functionComponent.string() +
						this.placeComponent.string() +
						this.deviceComponent.string()
					);
				}
			},
		};
	}
}

/** TrashNode specific implementation of BaseReferenceDesignator (necessary to avoid exception handling when trashing stuff) */
export class TrashNodeReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators AssemblyNode
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
		this.functionComponent.token = "XX";
		this.placeComponent.token = "XX";
	}
}

/** AssemblyNode specific implementation of BaseReferenceDesignator */
export class AssemblyNodeReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators AssemblyNode
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
		(this.functionComponent.prefix = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getFunctionComponentTemplate().prefix),
			(this.functionComponent.token = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getFunctionComponentTemplate().token);
		this.placeComponent.token = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getPlaceComponentTemplate().token;
	}

	/**
	 * @returns {object} functionComponent
	 */
	getNumberingDependendComponent() {
		return this.functionComponent;
	}

	/**
	 * Sets the token of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentToken to set
	 */
	setNumberingDependendComponentToken(_componentToken) {
		if (this.getNumberingDependendComponent().token == _componentToken) return; // abort if nothing has changed
		super.setNumberingDependendComponentToken(_componentToken);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
		});
	}

	/**
	 * Sets the number of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentNumber to set
	 */
	setNumberingDependendComponentNumber(_componentNumber) {
		if (this.getNumberingDependendComponent().number == _componentNumber) return; // abort if nothing has changed
		super.setNumberingDependendComponentNumber(_componentNumber);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
		});
	}

	/**
	 * Sets this AssemblyNodeReferenceDesignators placeComponent token and updates all children
	 * @param {string} _placeToken to set
	 */
	setPlaceToken(_placeToken) {
		if (this.placeComponent.token == _placeToken) return; // abort if nothing has changed
		super.setPlaceToken(_placeToken);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
			e.referenceDesignator.setPlaceToken(this.placeComponent.token);
		});
	}

	/**
	 * @returns {object} partial referenceDesignator of this AssemblyNode
	 */
	getReferenceDesignator() {
		return {
			functionComponent: this.functionComponent,
			placeComponent: this.placeComponent,
			deviceComponent: null,
			/**
			 *
			 */
			string: () => this.functionComponent.string() + this.placeComponent.string(),
		};
	}

	/**
	 * Returns the variable characteristics of an AssemblyNodeReferenceDesignator.
	 * @returns {object} containing functionComponent.token, functionComponent.number and placeComponent.token.
	 */
	save() {
		return {
			functionComponent: {token: this.functionComponent.token, number: this.functionComponent.number},
			placeComponent: {token: this.placeComponent.token},
		};
	}
}

/** Infrastructure specific implementation of BaseReferenceDesignator */
export class InfrastructureNodeReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators AssemblyNode
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
		(this.functionComponent.prefix = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getFunctionComponentTemplate().prefix),
			(this.functionComponent.token = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getFunctionComponentTemplate().infrastructureToken);
		// this.functionComponent.number = 99;
		this.placeComponent.token = getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getPlaceComponentTemplate().token;
	}

	/**
	 * @returns {object} functionComponent
	 */
	getNumberingDependendComponent() {
		return this.functionComponent;
	}

	/**
	 * Sets the token of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentToken to set
	 */
	setNumberingDependendComponentToken(_componentToken) {
		if (this.getNumberingDependendComponent().token == _componentToken) return; // abort if nothing has changed
		super.setNumberingDependendComponentToken(_componentToken);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
		});
	}

	/**
	 * Sets the number of this elements referenceDesignatorComponent that is depending on the numbering of a superordinate referenceDesignatorHandler
	 * @param {number} _componentNumber to set
	 */
	setNumberingDependendComponentNumber(_componentNumber) {
		if (this.getNumberingDependendComponent().number == _componentNumber) return; // abort if nothing has changed
		super.setNumberingDependendComponentNumber(_componentNumber);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
		});
	}

	/**
	 * Sets this AssemblyNodeReferenceDesignators placeComponent token and updates all children
	 * @param {string} _placeToken to set
	 */
	setPlaceToken(_placeToken) {
		if (this.placeComponent.token == _placeToken) return; // abort if nothing has changed
		super.setPlaceToken(_placeToken);
		// update children
		getDataNodeByUUID(this.UUID).children.forEach((e) => {
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", e); // raise update event for childNodes
			e.referenceDesignator.setPlaceToken(this.placeComponent.token);
		});
	}

	/**
	 * @returns {object} partial referenceDesignator of this AssemblyNode
	 */
	getReferenceDesignator() {
		return {
			functionComponent: this.functionComponent,
			placeComponent: this.placeComponent,
			deviceComponent: null,
			/**
			 *
			 */
			string: () => this.functionComponent.string() + this.placeComponent.string(),
		};
	}

	/**
	 * Returns the variable characteristics of an InfrastructureNodeReferenceDesignator.
	 * @returns {object} containing functionComponent.token, functionComponent.number and placeComponent.token.
	 */
	save() {
		return {
			functionComponent: {token: this.functionComponent.token, number: this.functionComponent.number},
			placeComponent: {token: this.placeComponent.token},
		};
	}
}

/** UnitNode specific implementation of BaseReferenceDesignator */
export class UnitNodeReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators UnitNode
	 * @param {string} _unitToken of this referenceDesignators UnitNode
	 */
	constructor(_associatedDataNodeUUID, _unitToken) {
		super(_associatedDataNodeUUID);
		this.deviceComponent.token = _unitToken;
	}

	/**
	 * @returns {object} deviceComponent
	 */
	getNumberingDependendComponent() {
		return this.deviceComponent;
	}

	/**
	 * Returns the variable characteristics of an UnitNodeReferenceDesignator.
	 * @returns {object} containing deviceComponent.token and deviceComponent.number.
	 */
	save() {
		return {deviceComponent: {token: this.deviceComponent.token, number: this.deviceComponent.number}};
	}
}

/** DeviceNode specific implementation of BaseReferenceDesignator */
export class DeviceNodeReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators DeviceNode
	 * @param {string} _deviceToken of this referenceDesignators DeviceNode
	 */
	constructor(_associatedDataNodeUUID, _deviceToken) {
		super(_associatedDataNodeUUID);
		this.deviceComponent.token = _deviceToken;
	}

	/**
	 * @returns {object} deviceComponent
	 */
	getNumberingDependendComponent() {
		return this.deviceComponent;
	}

	/**
	 * Returns the variable characteristics of an DeviceNodeReferenceDesignator.
	 * @returns {object} containing deviceComponent.token and deviceComponent.number.
	 */
	save() {
		return {deviceComponent: {token: this.deviceComponent.token, number: this.deviceComponent.number}};
	}
}

/** Cable specific implementation of BaseReferenceDesignator */
export class CableReferenceDesignator extends BaseReferenceDesignator {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this referenceDesignators DeviceNode
	 * @param {string} _cableToken of this referenceDesignators DeviceNode
	 */
	constructor(_associatedDataNodeUUID, _cableToken) {
		super(_associatedDataNodeUUID);
		this.deviceComponent.token = _cableToken;
	}
}

/** Base class representing a reference Designator Handler (managing referenceDesignator of childNodes) */
class BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this DataNodes referenceDesignatorHandler
	 */
	constructor(_associatedDataNodeUUID) {
		this.tokenCounter = {}; // object storing all functionToken specific counters
		this.UUID = _associatedDataNodeUUID;
	}

	/**
	 * Registers a DataNode referenceDesignator on parent.child.add (respectively the relocation equivalent) and assigns a number to its numberingDependendComponent
	 * @param {DataNode} _dataNode that gets added
	 */
	add(_dataNode) {
		if (!this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token]) {
			// check if a counter for the provided token has already been registered, if not...
			this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token] = []; // create a new counter
		}
		const tmpElement = findSmallestAvailableNumber(this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token]);
		this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token].splice(tmpElement.index, 0, {
			number: tmpElement.number,
			UUID: _dataNode.UUID,
		}); // update tmpCounter
		_dataNode.referenceDesignator.setNumberingDependendComponentNumber(tmpElement.number); // write tokenNumber back to childNode
		registerReferenceDesignator(_dataNode.referenceDesignator);
		GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", _dataNode); // raise update event for _dataNode
	}

	/**
	 * Removes a DataNodes referenceDesignator on parent.child.remove (respectively the relocation equivalent) and frees the number of its numberingDependendComponent
	 * @param {DataNode} _dataNode that gets removed
	 */
	remove(_dataNode) {
		// find tokenCounter subArray and Index of dataNode within that subarray
		let tmpElement = {};
		Object.entries(this.tokenCounter).forEach((e) => {
			e[1].forEach((f) => {
				if (f.UUID == _dataNode.UUID) {
					tmpElement = {tokenCounter: e[0], index: e[1].indexOf(f)};
				}
			});
		});
		if (tmpElement.tokenCounter) {
			// dataNode is not trashed yet
			this.tokenCounter[tmpElement.tokenCounter].splice(tmpElement.index, 1); // remove (splice) elements tokenNumber from tmpCounter
			_dataNode.referenceDesignator.setNumberingDependendComponentNumber(null); // reset deviceTokenNumber of _dataNode
			unregisterReferenceDesignator(_dataNode.referenceDesignator);
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", _dataNode); // raise update event for _dataNode
		}
	}

	/**
	 * Edits a DataNodes referenceDesignator
	 * @param {DataNode} _dataNode that gets removed
	 */
	edit(_dataNode) {
		let tmpElement = {};
		Object.entries(this.tokenCounter).forEach((e) => {
			e[1].forEach((f) => {
				if (f.UUID == _dataNode.UUID) {
					tmpElement = {tokenCounter: e[0], index: e[1].indexOf(f)};
				}
			});
		});
		this.tokenCounter[tmpElement.tokenCounter].splice(tmpElement.index, 1); // remove (splice) elements tokenNumber from tmpCounter

		if (!this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token]) {
			// check if a counter for the provided token has already been registered, if not...
			this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token] = []; // create a new counter
		}
		const tmpElement2 = findSmallestAvailableNumber(this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token]);
		this.tokenCounter[_dataNode.referenceDesignator.getNumberingDependendComponent().token].splice(tmpElement2.index, 0, {
			number: _dataNode.referenceDesignator.getNumberingDependendComponent().number,
			UUID: _dataNode.UUID,
		}); // update tmpCounter

		GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorChanged", _dataNode); // raise update event for _dataNode
	}
}

/** InfrastructureNode specific implementation of BaseReferenceDesignatorHandler */
export class InfrastructureReferenceDesignatorHandler extends BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this InfrastructureNodes referenceDesignatorHandler
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
	}

	/**
	 * @param {DataNode} _dataNode that gets added
	 */
	add(_dataNode) {
		_dataNode.referenceDesignator.setPlaceToken(getDataNodeByUUID(this.UUID).referenceDesignator.placeComponent.token);
		super.add(_dataNode);
	}
}

/** ProjectNode specific implementation of BaseReferenceDesignatorHandler */
export class ProjectReferenceDesignatorHandler extends BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this projectNodes referenceDesignatorHandler
	 * @param {object} _referenceDesignatorTemplate the project-wide referenceDesignatorTemplate // either provided by restoreData, ProjectBaseTemplate or UserInteraction
	 */
	constructor(_associatedDataNodeUUID, _referenceDesignatorTemplate) {
		super(_associatedDataNodeUUID);
		this.referenceDesignatorTemplate = {};
		this.setTemplate(_referenceDesignatorTemplate);
	}

	/**
	 * Switches the currently active ReferenceDesignatorTemplate
	 * @param {object} _referenceDesignatorTemplate to set
	 */
	setTemplate(_referenceDesignatorTemplate) {
		if (this.getTemplate() != _referenceDesignatorTemplate) {
			this.referenceDesignatorTemplate = _referenceDesignatorTemplate;
			GLOBALEVENTMANAGER.dispatch("eRDM_ReferenceDesignatorUpdated", {});
		}
	}

	/**
	 * Returns this projects referenceDesignatorTemplate
	 * @returns {object} this projects referenceDesignator
	 */
	getTemplate() {
		return this.referenceDesignatorTemplate;
	}

	/**
	 * Returns the functionComponent of the momentarily project-specific referenceDesignatorTemplate
	 * @returns {object} functionComponent of referenceDesignatorTemplate
	 */
	getFunctionComponentTemplate() {
		return {prefix: this.getTemplate().functionPrefix, token: this.getTemplate().functionToken, infrastructureToken: this.getTemplate().infrastructureToken};
	}

	/**
	 * Returns the placeComponent of the momentarily project-specific referenceDesignatorTemplate
	 * @returns {object} placeComponent of referenceDesignatorTemplate
	 */
	getPlaceComponentTemplate() {
		return {prefix: this.getTemplate().placePrefix, token: this.getTemplate().placeToken};
	}

	/**
	 * Returns the deviceComponent of the momentarily project-specific referenceDesignatorTemplate
	 * @returns {object} deviceComponent of referenceDesignatorTemplate
	 */
	getDeviceComponentTemplate() {
		return {prefix: this.getTemplate().devicePrefix};
	}
}

/** TrashNode specific implementation of BaseReferenceDesignatorHandler */
export class TrashNodeReferenceDesignatorHandler extends BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this TrashNodes referenceDesignatorHandler
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
	}
}

/** LimboNode specific implementation of BaseReferenceDesignatorHandler */
class LimboNodeReferenceDesignatorHandler extends BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this LimboNodes referenceDesignatorHandler
	 */
	constructor(_associatedDataNodeUUID) {
		super();
	}
}

/** AssemblyNode specific implementation of BaseReferenceDesignatorHandler */
export class AssemblyNodeReferenceDesignatorHandler extends BaseReferenceDesignatorHandler {
	/**
	 * standard constructor
	 * @param {string} _associatedDataNodeUUID of this AssemblyNodes referenceDesignatorHandler
	 */
	constructor(_associatedDataNodeUUID) {
		super(_associatedDataNodeUUID);
	}

	/**
	 * @param {DataNode} _dataNode that gets added
	 */
	add(_dataNode) {
		_dataNode.referenceDesignator.setPlaceToken(getDataNodeByUUID(this.UUID).referenceDesignator.placeComponent.token);
		super.add(_dataNode);
	}
}

/* ======================================== HELPERS ======================================== */

/**
 * Parses a tokenCounter and returns the index of the smallest free number
 * @param {Array} _array to evaluate
 * @returns {Integer} valid number
 */
function findSmallestAvailableNumber(_array) {
	_array.sort((a, b) => {
		if (a.number < b.number) {
			return -1;
		}
		if (a.number > b.number) {
			return 1;
		}
		return 0;
	});

	if (_array.length == 0) {
		// _array is empty
		return {index: 0, number: 1};
	} else {
		for (let i = 0; i < _array.length; i++) {
			// _array is not empty, try to find a missing number
			if (_array[i].number != i + 1) {
				return {index: i, number: i + 1};
			}
		}
		return {index: _array.length, number: _array.length + 1}; // no missing number in _array, append new at the end
	}
}

/**
 * Adds a referenceDesignator to referenceDesignatorRoot.referenceDesignatorList
 * @param {BaseReferenceDesignator} _referenceDesignator to add
 */
function registerReferenceDesignator(_referenceDesignator) {
	if (!checkReferenceDesignatorUniqueness(_referenceDesignator))
		throw new Error(`ReferenceDesignator "${_referenceDesignator.getReferenceDesignator().string()}" is not unique.`);
	referenceDesignatorRoot.referenceDesignatorList.push(_referenceDesignator);
}

/**
 * Removes a referenceDesignator from referenceDesignatorRoot.referenceDesignatorList
 * @param {BaseReferenceDesignator} _referenceDesignator to remove
 * @returns {boolean} success of operation
 */
function unregisterReferenceDesignator(_referenceDesignator) {
	let tmpIndex = null;
	referenceDesignatorRoot.referenceDesignatorList.forEach((e) => {
		if (e.UUID == _referenceDesignator.UUID) tmpIndex = referenceDesignatorRoot.referenceDesignatorList.indexOf(e);
	});
	if (tmpIndex != null) {
		referenceDesignatorRoot.referenceDesignatorList.splice(tmpIndex, 1);
		return true;
	} else {
		return false;
	}
}

/**
 * Checks if a referenceDesignator is unique
 * @param {BaseReferenceDesignator} _referenceDesignator to check
 * @returns {boolean} true (_referenceDesignator is unique), false (_referenceDesignator is not unique)
 */
function checkReferenceDesignatorUniqueness(_referenceDesignator) {
	let result = true;
	referenceDesignatorRoot.referenceDesignatorList.forEach((e) => {
		if (e == _referenceDesignator) result = false;
	});
	return result;
}

/**
 * Checks if a referenceDesignator is unique
 * @param {string} _referenceDesignatorString to check
 * @returns {boolean} true (_referenceDesignator is unique), false (_referenceDesignator is not unique)
 */
export function checkReferenceDesignatorStringUniqueness(_referenceDesignatorString) {
	let result = true;
	referenceDesignatorRoot.referenceDesignatorList.forEach((e) => {
		if (e.getReferenceDesignator().string() == _referenceDesignatorString) result = false;
	});
	return result;
}

/**
 * Deconstructs a referenceDesignatorString and returns a neatly sorted object consisting of component objects (functionComponent, placeComponent, deviceComponent)
 * @param {string} _referenceDesignatorString to parse
 * @param {object} _referenceDesignatorTemplate used as the active template for _referenceDesignatorString
 * @returns {object} component object
 */
export function parseReferenceDesignatorString(_referenceDesignatorString, _referenceDesignatorTemplate) {
	const tmpFunctionSubstring = getSubString(_referenceDesignatorString, _referenceDesignatorTemplate.functionPrefix, _referenceDesignatorTemplate.placePrefix);
	const tmpPlaceSubstring = getSubString(_referenceDesignatorString, _referenceDesignatorTemplate.placePrefix, _referenceDesignatorTemplate.devicePrefix);
	const tmpDeviceSubstring = getSubString(_referenceDesignatorString, _referenceDesignatorTemplate.devicePrefix, _referenceDesignatorTemplate.portPrefix);
	const tmpPortSubstring = getSubString(_referenceDesignatorString, _referenceDesignatorTemplate.portPrefix);

	const tmpFunctionComponent = {
		prefix: _referenceDesignatorTemplate.functionPrefix,
		token: extractToken(tmpFunctionSubstring, _referenceDesignatorTemplate.functionPrefix),
		number: extractNumber(tmpFunctionSubstring),
	};

	const tmpPlaceComponent = {
		prefix: _referenceDesignatorTemplate.placePrefix,
		token: tmpPlaceSubstring.replace(_referenceDesignatorTemplate.placePrefix, ""), // the general extractToken function can not be used here, since placeComponent doesn't have a number element
	};

	const tmpDeviceComponent = {
		prefix: _referenceDesignatorTemplate.devicePrefix,
		token: extractToken(tmpDeviceSubstring, _referenceDesignatorTemplate.devicePrefix),
		number: extractNumber(tmpDeviceSubstring),
	};

	const tmpPortComponent = {
		prefix: _referenceDesignatorTemplate.portPrefix,
		token: extractToken(tmpPortSubstring, _referenceDesignatorTemplate.portPrefix),
		number: extractNumber(tmpPortSubstring),
	};
	const result = {
		functionComponent: tmpFunctionComponent,
		placeComponent: tmpPlaceComponent,
		deviceComponent: tmpDeviceComponent, // depending on the  input (_referenceDesignatorString) this subObject may also refer to a wire
		port: tmpPortComponent,
	};
	return result;

	/**
	 * Extracts the number of a referenceDesignator component
	 * @param {string} _string source
	 * @returns {number} number
	 */
	function extractNumber(_string) {
		if (_string == null) return null;
		return parseInt(/\d+/.exec(_string)[0]);
	}

	/**
	 * Extracts the token of a referenceDesignator component
	 * @param {string} _string source
	 * @param {string} _prefix source
	 * @returns {string} token
	 */
	function extractToken(_string, _prefix) {
		if (_string == null) return null;
		return _string.replace(extractNumber(_string), "").replace(_prefix, "");
	}

	/**
	 * Extract a substring defined by one/two limiters
	 * @param {string} _string source
	 * @param {string} _start first character to match (including)
	 * @param {string} _end (Optional) last character to match (excluding) - if not provided, the full length of the string will be taken as the second limiter
	 * @returns {string} substring
	 */
	function getSubString(_string, _start, _end = null) {
		const i1 = _string.indexOf(_start);
		if (i1 == -1) return null;

		let i2 = _string.indexOf(_end);
		if (i2 == -1) i2 = _string.length;

		return _string.substr(i1, i2 - i1);
	}
}
