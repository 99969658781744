/* eslint-disable no-unused-vars */
/* cSpell:disable */
import {applicationRoot} from "../applicationManager";
import {GLOBALEVENTMANAGER} from "../applicationManager";
import {canvasRoot, getCanvasByUUID} from "../canvasManager";
import {FindAllCompatiblePorts} from "../compatibilityChecks/FindAllCompatiblePorts.ts";
import {PortCompatibilityChecker} from "../compatibilityChecks/PortCompatibilityChecker.ts";
import {technicalUnitEnum, portOrientationEnum, deviceRoleEnum} from "../constantsAndEnumerations";
import {
	getConnectionByUUID,
	getUniqueDataNodeByType,
	eRepositionDataNode,
	eUpdateGroupNodeLayout,
	dataRoot,
	getDeviceSourceDataByDatabaseId,
	getPortByUUID,
	createUUID,
	getDataNodeByUUID,
	recalculateCurrents,
	ePathEditStopped,
	ePathEditStarted,
	getPortSourceDataByDatabaseId,
} from "../dataManager";
import {UnitNode, DeviceNode} from "../dataNode";
import {searchArrayForElementByKeyValuePair, cId2jqSel, makeFirstLetterLowerCase, roundFloat} from "../helper";
import {getTranslation, bindDomElementToTranslateEvent} from "../localization/localizationManager";
import {eSetActiveTreeNode} from "../GUI/outliner/outliner";
import {
	markPorts,
	unMarkPorts,
	markUnitNodes,
	unMarkUnitNodes,
	markEdge,
	unMarkEdge,
	setMarkEdgesHandlers,
	removeAllMarkEdgesHandlers,
	markLink,
	unMarkLink,
	setMarkLinksHandlers,
	removeAllMarkLinksHandlers,
} from "./jspMarkHelpers.ts";

// this is the version provided via npm (which is prefered but does not work...)
// import jsPlumbToolkit from "jsplumbtoolkit";
// import {SurfaceDropManager} from "jsplumbtoolkit-drop";
// import {jsPlumbToolkitEditableConnectors} from "jsplumbtoolkit-editable-connectors";

// ye olde way of directly importing
import {jsPlumbToolkit} from "../../../non-node_modules/jsplumb_2.4.15/js/jsplumbtoolkit";
import {SurfaceDropManager} from "../../../non-node_modules/jsplumb_2.4.15/js/jsplumbtoolkit-drop.min";
import "../../../non-node_modules/jsplumb_2.4.15/js/jsplumbtoolkit-editable-connectors.min";

import "./jspColors.css";
import "./jspEdge.css";
import "./jspPort.css";

import $ from "jquery";
import {invertPort, PortMount, PortSide} from "../ports/utils";
import portArrow from "../../images/portDirection.svg";

//* ##############################################################################
//* all possible uses of jquery are marked with @jquery (remove that shit one day)
//* ##############################################################################

//! ######################################################################
//! obsolete stuff is marked with "@remove" and (mostly) jsdoc/@deprecated
//! ######################################################################

//TODO		Reactivate parameter table & validation markers (finalizeJspNode Function)
//TODO		replace direct access to _jspContainer.surface.getMiniview().update() with a class method like .updateSomething() (that way we don't need to check if miniView exists)
//TODO		for trashing to work, you may need to implement an invisible JspTrashNode like JspRootNode
//TODO		make eCreateJspNode work for all objects to mitigate the switch statement
//TODO		updatePosition when moving jspNodes should be delegated/automated via jspContainer.addChild method
//TODO		make infoProvider translatable

/* ######### Quick and dirty hack to satisfy eslint-jsdoc/no-undefined-types (TS - I wish you were here!) ######### */

//! Don't rely on the type definitions made here!!!

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} BaseDataNode
 * @typedef {object} AssemblyNode
 * @typedef {object} InfrastructureNode
 * @typedef {object} TrashNode
 * @typedef {object} Connection
 * @typedef {object} JspContainer
 * @typedef {object} JspSurface
 * @typedef {object} JspNode
 * @typedef {object} JspPort
 * @typedef {object} JspEdge
 * @typedef {object} Port
 * @typedef {string} UUID
 */
/* eslint-enable jsdoc/require-property */

/* ################################################################################################################ */

// Central, top most jsPlumb parent
const jspRoot = {
	jspContainerList: [], // stores all jspContainerObjects (atm only Assemblies) separately for quick access and convenience
	preventAccordionDragging: true, // is switched via event when activeCanvas changes and prevents/allows dragging of palette elements
	debugMode: false,
};

// special object representing the JspRootNode (see class description below)
let CtrlKey = false;
const portSize = 8; // size of jspEdge-endpoint (something also happens to the position of the jspPort icon on the device?!)

/**
 * Standard initialization routine (mainly setting event handlers)
 * @param {boolean} [_debugMode=false] turn chatty log on/off
 */
export function initializeJsPlumbManager(_debugMode = false) {
	jspRoot.debugMode = _debugMode;
	if (jspRoot.debugMode) {
		window.jspRoot = jspRoot;
		window.getJspNodeByUUID = getJspNodeByUUID;
		window.getJspPortByUUID = getJspPortByUUID;
		window.getJspEdgeByUUID = getJspEdgeByUUID;
		window.getJspDomElementByUUID = getJspDomElementByUUID;
		window.getJspContainerByUUID = getJspContainerByUUID;
		window.getJspContainerByJspNodeUUID = getJspContainerByJspNodeUUID;
		window.getJspContainerByJspPortUUID = getJspContainerByJspPortUUID;
		window.getJspContainerByJspEdgeUUID = getJspContainerByJspEdgeUUID;
		// eslint-disable-next-line no-console
		console.debug("%cDebug Mode: true", "color: green");
	}

	GLOBALEVENTMANAGER.addHandler("eJSP_NodeRepositioned", eRepositionDataNode);
	GLOBALEVENTMANAGER.addHandler("eJSP_SurfaceLayoutChanged", eUpdateGroupNodeLayout);
	GLOBALEVENTMANAGER.addHandler("eJSP_NodeSelected", eSetActiveTreeNode);
	// GLOBALEVENTMANAGER.addHandler("eJSP_PathEditStopped", ePathEditStopped);
	// GLOBALEVENTMANAGER.addHandler("eJSP_PathEditStarted", ePathEditStarted);
	// GLOBALEVENTMANAGER.addHandler("eJSP_CanvasMouseLeave", stopEditContainerPaths);
	GLOBALEVENTMANAGER.addHandler("eDTM_UpdateJspPortPosition", eUpdateJspPortPosition);
	GLOBALEVENTMANAGER.addHandler("eDTM_ToggleJspPortLink", eToggleJspPortLink);
	GLOBALEVENTMANAGER.addHandler("focusNode", eFocusJspElement);

	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodePortAdded", eCreateJspPort);
	GLOBALEVENTMANAGER.addHandler("eDTM_DataNodePortRemoved", eRemoveJspPort);
	GLOBALEVENTMANAGER.addHandler("eDTM_CreateConnection", eCreateJspEdge);
	GLOBALEVENTMANAGER.addHandler("eDTM_RemoveConnection", eRemoveJspEdge);

	GLOBALEVENTMANAGER.addHandler("eDTM_SuspendRendering", eSuspendRendering);

	watchKeys(); // :..(
}

/** Standard reset routine (setting Manager back to initial values etc..) */
function resetJsPlumbManager() {}

/**
 * Base class representing a top level jsp object (surface, instance)
 */
export class JspBaseContainer {
	/**
	 * default constructor
	 * @param {BaseDataNode} _groupDataNode that gets represented by this jspObject
	 */
	constructor(_groupDataNode) {
		// TODO the creation of a jspObject gets triggered by the creation of an AssemblyNode. Make sure the associated canvas already exists

		this.UUID = _groupDataNode.UUID;
		this.trashed = _groupDataNode.getTrashed();
		this.canvasContainer = getCanvasByUUID(_groupDataNode.UUID).container;
		this.miniViewContainer = getCanvasByUUID(_groupDataNode.UUID).miniView;
		this.suppressLayoutEvents = true; // suppresses zoom & pan events while restoring the surface layout
		this.children = [];

		this.register();
		createJspInstance(this);
		createJspSurface(this);
		this.restoreLayout();
	}

	/** Adds this jspObject to jsPlumbRoot.jsPlumbList */
	register() {
		jspRoot.jspContainerList.push(this);
	}

	/** Restores zoom and pan of the associated surface (if available in parentAssemblyNode)*/
	restoreLayout() {
		//? This code would restore the saved zoom/pan properties but SBI is overwriting this with "eZoomCanvasToFit" triggered by "eDTM_CanvasAssembled" event
		//? I think both variants (restoring/zoomtofit) have their pros & cons. Zooming to fit is probably the most common scenario
		// this.suppressLayoutEvents = true;
		// const tmpLayout = getDataNodeByUUID(this.UUID).graphics.layout;
		// if (tmpLayout.zoom) this.surface.setZoom(tmpLayout.zoom);
		// if (tmpLayout.pan.x && tmpLayout.pan.y) this.surface.pan(tmpLayout.pan.x, tmpLayout.pan.y);
		// this.suppressLayoutEvents = false;
	}

	/**
	 * Removes this jspObject from jsPlumbRoot.jsPlumbList
	 */
	unregister() {
		jspRoot.jspContainerList.splice(jspRoot.jspContainerList.indexOf(searchArrayForElementByKeyValuePair(jspRoot.jspContainerList, "UUID", this.UUID)), 1);
	}

	/**
	 * Updates this jspContainer
	 */
	updateMiniview() {
		this.surface.getMiniview().update();
	}

	/** Deletes this JspContainer */
	delete() {
		delete this.surface; // TODO delete surface
		delete this.instance; // TODO delete instance
		this.unregister();
	}

	/**
	 * Adds a jspNode to its parents (jspObject) children
	 * @param {JspNode} _jspNode that gets added
	 */
	addChildNode(_jspNode) {
		this.children.push(_jspNode);
	}

	/**
	 * Removes a jspNode from its parents (jspObject) children
	 * @param {JspNode} _jspNode that gets removed
	 */
	removeChildNode(_jspNode) {
		this.children.splice(this.children.indexOf(searchArrayForElementByKeyValuePair(this.children, "id", _jspNode.id)), 1);
	}
}

/**
 * Pseudo class based on (but not extending!) JspBaseContainer;
 * Special (invisible) container for rootCanvas; All newly created nodes get dropped here first and then relocated to their appropriate parent canvas (via dataNode.relocate...)
 */
class JspRootNode {
	/** default constructor */
	constructor() {
		this.UUID = "root";
		this.trashed = false;
		this.canvasContainer = document.getElementById("rootCanvas");
		this.miniViewContainer = false;
		this.children = [];

		this.register();
		createJspInstance(this);
		createJspSurface(this);
		registerPaletteNodes(this);
		// this.setDrop(); we need a better solution
	}

	/** Adds this jspObject to jspRoot.jspContainerList */
	register() {
		jspRoot.jspContainerList.push(this);
	}

	/** Removes this jspObject from jspRoot.jspContainerList */
	unregister() {
		// JspRootNode can't be removed
	}

	/** Updates this jspContainer */
	updateMiniview() {
		// just for compatibility; JspRootNode doesn't have a miniview
	}

	/** Deletes this JspContainer */
	delete() {
		// root container can't be deleted
	}

	/**
	 * Adds a jspNode to this jspContainer.children
	 * @param {JspNode} _jspNode that gets added
	 */
	addChildNode(_jspNode) {
		this.children.push(_jspNode);
		this.instance.update(_jspNode);
	}

	/**
	 * Removes a jspNode from this jspContainer.children
	 * @param {JspNode} _jspNode that gets removed
	 */
	removeChildNode(_jspNode) {
		this.children.splice(this.children.indexOf(searchArrayForElementByKeyValuePair(this.children, "id", _jspNode.id)), 1);
	}

	/**
	 * Move canvas to foreground
	 * move root canvas to the foreground to allow drop from SurfaceDropManager
	 */
	raiseCanvas() {
		this.canvasContainer.style.visibility = "visible";
		this.canvasContainer.style.zIndex = 99999; //REFACTOR don't hardcore z-indices, use temporary css classes instead
	}

	/**
	 * Move canvas to background
	 * move root canvas to the background after drop from SurfaceDropManager
	 */
	lowerCanvas() {
		this.canvasContainer.style.visibility = "hidden";
		this.canvasContainer.style.zIndex = 0; //REFACTOR don't hardcore z-indices, just remove the temporary css classes set for raising
	}
}

/** InfrastructureNode specific implementation of JspBaseContainer */
class JspInfrastructureNode extends JspBaseContainer {
	/**
	 * Creates a new instance of JspInfrastructureNode.
	 * @param {InfrastructureNode} _infrastructureNode that gets represented by this jspObject
	 */
	constructor(_infrastructureNode) {
		super(_infrastructureNode);
	}

	/**
	 * Overloads parent method
	 */
	unregister() {
		// infrastructure container can't be removed
	}

	/**
	 * Overloads parent method
	 */
	delete() {
		// infrastructure container can't be deleted
	}
}

/** TrashNode specific implementation of JspBaseContainer */
class JspTrashNode extends JspBaseContainer {
	/**
	 * Creates a new instance of JspTrashNode.
	 * @param {TrashNode} _trashNode that gets represented by this jspObject
	 */
	constructor(_trashNode) {
		super(_trashNode);
	}

	/**
	 * Overloads parent method
	 */
	unregister() {
		// trash container can't be removed
	}

	/**
	 * Overloads parent method
	 */
	delete() {
		// trash container can't be deleted
	}
}

/** AssemblyNode specific implementation of JspBaseContainer */
class JspAssemblyNode extends JspBaseContainer {
	/**
	 * Creates a new instance of JspAssemblyNode
	 * @param {AssemblyNode} _assemblyNode that gets represented by this jspObject
	 */
	constructor(_assemblyNode) {
		super(_assemblyNode);
	}
}

/**
 * Creates a new JSP Instance for a given JspBaseContainer
 * @param {JspContainer} _jspContainer to add instance to
 */
function createJspInstance(_jspContainer) {
	_jspContainer.instance = jsPlumbToolkit.newInstance({
		//! @remove most of these type/id functions could be removed once we stop forcing our UUID onto jsp
		/**
		 *
		 * @param _data
		 */
		idFunction: (_data) => _data.id, // Extracts ID from data argument used for creating jspNodes programmatically

		/**
		 *
		 * @param _data
		 */
		typeFunction: (_data) => _data.type, // Extracts TYPE from data argument used for creating jspNodes programmatically

		/**
		 *
		 * @param _port
		 */
		portIdFunction: (_port) => _port.id, // Extracts ID from a jspPort

		/**
		 *
		 * @param _port
		 */
		portTypeFunction: (_port) => _port.type, // Extracts type from a jspPort when connecting an edge

		/**
		 *
		 * @param _edge
		 */
		edgeIdFunction: (_edge) => _edge.id,

		nodeFactory: function (type, data, callback, originalEvent, isNative) {
			// Creating jspNodes via D&D from accordion (there is no matching dataNode at this moment!)
			let tmpDataNode = null;

			//REFACTOR Don't make DataNode operations here! Use an event to delegate to dataManager
			switch (data.type) {
				case "deviceNode":
					tmpDataNode = new DeviceNode(getDeviceSourceDataByDatabaseId(data.databaseId).name, createUUID(), getDeviceSourceDataByDatabaseId(data.databaseId));
					break;
				case "unitNode":
					tmpDataNode = new UnitNode(getDeviceSourceDataByDatabaseId(data.databaseId).name, createUUID(), getDeviceSourceDataByDatabaseId(data.databaseId));
					break;
				default:
					break;
			}

			getDataNodeByUUID(canvasRoot.activeCanvas.UUID).createChild(tmpDataNode);
			tmpDataNode.setPosition({x: data.left, y: data.top});
		},

		beforeStartConnect: function (_jspDragPort, _edgeType) {
			const dragPort = getPortByUUID(_jspDragPort.data.UUID);
			const jspDragPort = _jspDragPort;
			const jspDragPortEdge = jspDragPort.getEdges()[0]; // always only one edge per port for now in the konfigurator

			//REFACTOR Don't do that here, delegate the task of identifying ports/edge to dataManager via events and have a callback event (un)mark them on jspPort/jspEdge classes here

			const compatibleDropPorts = new FindAllCompatiblePorts(dragPort, new PortCompatibilityChecker()).result;
			const compatibleJspDropPorts = compatibleDropPorts.map((_jspPort) => getJspPortByUUID(_jspPort.UUID));
			const connectedCompatibleJspDropPorts = compatibleJspDropPorts.filter((_jspPort) => _jspPort.getEdges().length > 0);

			// handling unitNode marking
			const jspInstance = getJspContainerByJspPortUUID(jspDragPort.data.UUID).instance;
			const jspDragNode = jspDragPort.getNode();
			const compatibleJspUnitNodes = jspInstance.getNodes().filter((_jspNode) => _jspNode.data.type === "unitNode" && _jspNode.data.UUID !== jspDragNode.data.UUID);

			markUnitNodes(compatibleJspUnitNodes);
			markPorts(compatibleJspDropPorts);
			setMarkLinksHandlers(compatibleJspDropPorts);
			setMarkEdgesHandlers(connectedCompatibleJspDropPorts);
			markLink(jspDragPort);
			if (jspDragPortEdge) markEdge(jspDragPortEdge);

			// https://docs.jsplumbtoolkit.com/toolkit/2.x/articles/data-model#before-start-connect
			return {type: _edgeType}; //REFACTOR I don't think we need that anymore. edgeType may be useful to differentiate electricEdge, pneumaticEdge, etc
		},

		beforeConnect: function (_jspDragPort, _jspDropPort, _edgeData) {
			// gets called twice on edge creation
			// 1st call is triggered manually by cable drop onto port, triggers dataManger (from a jsp point of view this is an abort of edge creation)
			// 2nd call is triggered programmatically by eCreateJspEdge/jspInstance.addEdge - beforeConnect must return true for this to succeed.
			if (!_edgeData) return true; // satisfies 2nd call by eCreateJspEdge/jspInstance.addEdge

			const jspContainer = getJspContainerByJspPortUUID(_jspDragPort.data.UUID);
			const jspDragPortEdge = _jspDragPort.getEdges()[0]; // always only one edge per port for now in the konfigurator
			const jspDropPortEdge = _jspDropPort.getEdges()[0]; // always only one edge per port for now in the konfigurator

			// trigger automatic port creation on edge drop on unitNodes
			if (_jspDropPort.data.type === "unitNode") {
				const dragPort = getPortByUUID(_jspDragPort.data.UUID);

				const dropUnitNode = getDataNodeByUUID(_jspDropPort.data.id); // the UnitNode uuid is coded into the dropPort id (I know, I know...)
				const forceOpen = dropUnitNode.role.type === deviceRoleEnum.CABINET.type; // only force open ports on cabinets (for now)
				const newPort = invertPort(dragPort, PortMount.DEVICE, forceOpen);

				GLOBALEVENTMANAGER.dispatch("eJSP_CreatePort", _jspDropPort.data.id, newPort);
				GLOBALEVENTMANAGER.dispatch("eJSP_CreateConnection", jspContainer.UUID, dragPort.UUID, newPort.UUID);
				unMarkUnitNodes(); // not sure if this is necessary here
				return;
			}

			unMarkPorts();
			unMarkUnitNodes(); // not sure if this is necessary here
			// unMarkUnitNodes(jspContainer.UUID);
			if (jspDragPortEdge) unMarkEdge(jspDragPortEdge);
			if (jspDropPortEdge) unMarkEdge(jspDropPortEdge);
			unMarkLink(_jspDragPort);
			unMarkLink(_jspDropPort);
			removeAllMarkLinksHandlers();
			removeAllMarkEdgesHandlers();

			const jspSourcePort = _jspDragPort.data.side === "SOURCE" ? _jspDragPort : _jspDropPort;
			const jspTargetPort = _jspDragPort.data.side === "TARGET" ? _jspDragPort : _jspDropPort;

			// there is no geometry to extract from jsp during this stage
			GLOBALEVENTMANAGER.dispatch("eJSP_CreateConnection", jspContainer.UUID, jspSourcePort.data.UUID, jspTargetPort.data.UUID);
			// return true; would trigger jspEdgeCreation, but we hand that over to dataManager.eCreateConnection
		},

		beforeStartDetach: function (detached, edge) {
			// there is no real functionality here; you can't detach an edge and connect it anywhere else (yet), but we can mark compatible ports for user convenience

			// detached is always the jspPort that got detached, BUT thats not consistently edge.source or edge.target, that depends in which order the edge was created

			// get the other side
			const attachedJspPort = detached === edge.source ? edge.target : edge.source;
			const attachedPort = getPortByUUID(attachedJspPort.data.UUID);

			const compatibleDropPorts = new FindAllCompatiblePorts(attachedPort, new PortCompatibilityChecker()).result;
			const compatibleJspDropPorts = compatibleDropPorts.map((port) => getJspPortByUUID(port.UUID));

			//REFACTOR Don't do that here, delegate the task of identifying ports/edge to dataManager via events and have a callback event (un)mark them on jspPort/jspEdge classes here
			// handling unitNode marking
			const jspInstance = getJspContainerByJspPortUUID(attachedJspPort.data.UUID).instance;
			const attachedJspNode = attachedJspPort.getNode();
			const compatibleJspUnitNodes = jspInstance.getNodes().filter((_jspNode) => _jspNode.data.type === "unitNode" && _jspNode.data.UUID !== attachedJspNode.data.UUID);

			markUnitNodes(compatibleJspUnitNodes);
			markPorts(compatibleJspDropPorts);
		},

		beforeDetach: function (source, target, edge) {
			//REFACTOR Don't do that here, delegate the task of identifying ports/edge to dataManager via events and have a callback event (un)mark them on jspPort/jspEdge classes here
			unMarkUnitNodes();
			unMarkPorts();
		},
	});
}

/**
 * Creates a new JSP Surface (Renderer) for a given JspBaseContainer
 * @param {JspContainer} _jspContainer to add instance to
 */
function createJspSurface(_jspContainer) {
	_jspContainer.surface = _jspContainer.instance.render({
		container: _jspContainer.canvasContainer,
		UUID: _jspContainer.UUID, // useful to retrieve surface via toolkit.getRenderer(id)
		elementsDraggable: true,
		elementsDroppable: true,
		enableWheelZoom: true,
		wheelReverse: true,
		clamp: false, // allow panning outside of view
		enablePanButtons: false,
		consumeRightClick: false,
		storePositionsInModel: true, // store left/top properties in node.data
		layout: {
			type: "Absolute",
			magnetize: false,
			// padding: [750, 750],
		},
		events: {
			// GLOBAL instance events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#events, https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventlist
			canvasClick: function (params) {
				_jspContainer.instance.clearSelection();
				stopEditContainerPaths(params.currentTarget.id.substr(-4)); //REFACTOR WTF? Not acceptable!
			},
			canvasDblClick: function (params) {},
			nodeAdded: function (params) {},
			nodeRemoved: function (params) {
				const dataNode = getDataNodeByUUID(params.node.data.UUID);
				const parentDataNode = dataNode.getParentGroupNode();
				if (dataNode.getType() !== "AssemblyNode" && (parentDataNode.getType() == "TrashNode" || parentDataNode.getType() == "LimboNode")) {
					//REFACTOR WTF? Not acceptable!
					dataNode.parentEventManager.dispatch("eJSP_NodeDeleted", params.node.data.UUID);
				}
			},
			portAdded: function (params) {
				params.nodeEl.appendChild(params.portEl);
			},
			portRemoved: function (params) {},
			edgeAdded: function (params) {
				recalculateCurrents(params, true); //REFACTOR remove in the future, this is neither the right place nor the right way to recalc currents
			},
			edgeRemoved: function (params) {
				const jspEdgeUUID = params.edge.data.UUID;
				GLOBALEVENTMANAGER.dispatch("eJSP_RemoveConnection", jspEdgeUUID);
				recalculateCurrents(params, false); //REFACTOR remove in the future, this is neither the right place nor the right way to recalc currents
			},
			edgeUpdated: function (params) {},
			modeChanged: function (mode) {
				switch (mode) {
					case "pan":
						GLOBALEVENTMANAGER.dispatch("eJSP_DeactivateSelectMode");
						break;
					case "select":
						break;
					default:
						break;
				}
			},
			zoom: function (params) {
				if (_jspContainer.UUID != "root") updateLayoutParameters(_jspContainer); //REFACTOR Don't catch "root" have the function run into nothing
			},
			pan: function (params) {
				if (_jspContainer.UUID != "root") updateLayoutParameters(_jspContainer); //REFACTOR Don't catch "root" have the function run into nothing
			},
			connectionAborted: function (info, originalEvent) {
				// fires even when a connection is established, because on beforeConnect always breaks and hands connection creation over to dataManager.eCreateConnection
				unMarkPorts();
				unMarkUnitNodes();
				unMarkLink();
				removeAllMarkLinksHandlers();
				removeAllMarkEdgesHandlers();
			},
		},
		dragOptions: {
			filter: "#canvas-deviceNode-infoProvider, .port-IN", // exclude elements from being draggable or drag handles
			start: function (params) {
				// only for dragging objects that are already on canvas/surface
				// console.debug("OnCanvasDragStart");
			},
			drag: function (params) {
				// only for dragging objects that are already on canvas/surface
				// console.debug("DraggingInProgress");
			},
			stop: function (params) {
				// only for dragging objects that are already on canvas/surface
				// console.debug("OnCanvasDragStop");
				GLOBALEVENTMANAGER.dispatch("eJSP_NodeRepositioned", params.el.jtk.node.data.UUID, {x: params.pos[0], y: params.pos[1]});
				const editedEdges = params.el.jtk.node.getAllEdges();
				if (editedEdges.length > 0) {
					editedEdges.forEach((editedEdge) => {
						const geometry = getEdgeGeometry(editedEdge.UUID);
						GLOBALEVENTMANAGER.dispatch("eJSP_PathEditStopped", editedEdge.UUID, geometry);
					});
				}
			},
			magnetize: false, // prevent overlapping (can this option be specified?)
			// grid: [10, 10],																			// force movement on px grid
		},
		view: {
			nodes: {
				// assigning node types to templates (used to render the correct DOM structure)
				deviceNode: {
					template: "tmplDeviceNode",
					events: {
						// LOCAL view-element specific events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventview
						click: function (params) {
							// handle click on link-container-caption
							if (params.e.target.classList.contains("base-port-link-caption")) {
								eFocusJspElement({type: "port", UUID: params.e.target.dataset.linkedPortUuid});
								return;
							}

							GLOBALEVENTMANAGER.dispatch("eJSP_NodeSelected", getDataNodeByUUID(params.node.data.UUID));
						},
						dblclick: function (params) {
							GLOBALEVENTMANAGER.dispatch("OpenDeviceDialog", getDataNodeByUUID(params.node.data.UUID));
						},
						rightclick: function (params) {},
					},
				},
				unitNode: {
					template: "tmplUnitNode",
					events: {
						// LOCAL view-element specific events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventview
						click: function (params) {
							// handle click on link-container-caption
							if (params.e.target.classList.contains("base-port-link-caption")) {
								eFocusJspElement({type: "port", UUID: params.e.target.dataset.linkedPortUuid});
								return;
							}

							GLOBALEVENTMANAGER.dispatch("eJSP_NodeSelected", getDataNodeByUUID(params.node.data.UUID));
						},
						dblclick: function (params) {
							GLOBALEVENTMANAGER.dispatch("OpenDeviceDialog", getDataNodeByUUID(params.node.data.UUID));
						},
					},
				},
				assemblyNode: {
					template: "tmplAssemblyNode",
					events: {
						// LOCAL view-element specific events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventview
						click: function (params) {
							GLOBALEVENTMANAGER.dispatch("eJSP_NodeSelected", getDataNodeByUUID(params.node.data.UUID));
						},
						dblclick: function (params) {
							GLOBALEVENTMANAGER.dispatch("OpenDeviceDialog", getDataNodeByUUID(params.node.data.UUID));
						},
					},
				},
			},
			ports: {
				"port-base": {
					template: "jspPortTemplate",
					// reattachConnections: true,															// that is from the community edition, that this even work here?
					// maxConnections: 1,																			// not needed anymore, handled by us in beforeConnect (removal of edges)
					// allowLoopback: false,																	// do not allow loopback connections from a port to itself
					// allowNodeLoopback: false,															// DOES NOT WORK! Atm replaced by PortCompatibilityChecker.isSameParent
					events: {
						// LOCAL view-element events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventview
						click: function (params) {
							// console.debug(params);
						},
						dblclick: function (params) {
							// console.debug(params);
						},
						mouseover: function (params) {
							//REFACTOR (1) all data should be provided by jspPort wrapper class (which gets its data via events from dataPort)
							//REFACTOR (2) I'd prefer not to rely on jsp edge labels (due to styling limitations). It would be better to have some real HTML grid like structure...
							//REFACTOR     ... which gets defined during jspEdge wrapper constructor and called by html mousehover

							// needed safeguard, not sure why this is necessary but without an error occurs
							if (params.el === undefined) return;

							if (params.e.target.tagName === "path") {
								const connection = getJspPortByUUID(params.el.dataset.uuid).data.linkTarget.connection;
								params.e.target.querySelector("title").innerHTML = createJspEdgeTooltip(connection);

								return;
							}

							const tmpPort = getPortByUUID(params.el.dataset.uuid);

							const name = getTranslation(tmpPort.name);
							const gender = getTranslation(`ports.${tmpPort.gender.toLowerCase()}`);
							const shielded = tmpPort.isShielded ? ` | ${getTranslation("ports.shielded")}` : "";
							const side = getTranslation(`ports.${tmpPort.side.toLowerCase()}`);
							const composition = tmpPort.composition;

							const interfaces = tmpPort.interfaces.map((_interface) => `${getTranslation(_interface.type.caption)}: ${_interface.voltage}V / ${_interface.current}A \n`);

							let caption = `${name} (${gender}) [${composition}]${shielded} | ${side}\n`;
							interfaces.forEach((_interface) => (caption += _interface));

							caption += `\n[${tmpPort.referenceDesignator.getReferenceDesignator.string()}]`;

							params.el.title = caption;
						},
					},
				},
				electricPort: {
					edgeType: "electricEdge",
					parent: "port-base",
				},
			},
			edges: {
				"edge-base": {
					connector: ["EditableFlowchart", {alwaysRespectStubs: true, cornerRadius: 0.1}],
					cssClass: "base-edge",
					endpoint: ["Rectangle", {width: portSize, height: portSize}],
					events: {
						// LOCAL view-element events, for details see https://jsplumbtoolkit.com/docs/toolkit/widget-surface.html#eventview
						click: function (params) {
							// TODO not working
							// console.debug("click on edge");
							// console.debug(params);
							if (CtrlKey) {
								GLOBALEVENTMANAGER.dispatch("eCM_StartEditPath", params.edge.data.UUID);
							}
						},
						dblclick: function (params) {
							GLOBALEVENTMANAGER.dispatch("OpenDeviceDialog", getConnectionByUUID(params.edge.data.UUID));
						},
						mouseover: function (params) {
							//REFACTOR (1) all data should be provided by jspEdge wrapper class (which gets its data via events from dataConnection)
							//REFACTOR (2) I'd prefer not to rely on jsp edge labels (due to styling limitations). It would be better to have some real HTML grid like structure...
							//REFACTOR     ... which gets defined during jspEdge wrapper constructor and called by html mousehover

							const caption = createJspEdgeTooltip(getConnectionByUUID(params.edge.data.UUID));
							const edgeTitle = getJspDomElementByUUID(params.edge.data.UUID).getElementsByTagName("title")[0];
							edgeTitle.textContent = caption;
						},
						mouseout: function (params) {
							// maybe useful one day
						},
					},
					overlays: [],
				},
				electricEdge: {
					parent: "edge-base",
					// cssClass: "cable-base",		//! I don't see any need for that atm, maybe later when we have hydraulic/pneumatic edges...
					paintStyle: {stroke: "grey", strokeWidth: 1.5, outlineStroke: "transparent", outlineWidth: 0.01}, // strokeWidth should be same value as css/base-edge/stroke-dasharray
					hoverPaintStyle: {stroke: "red", outlineStroke: "red"},
				},
			},
		},
	});

	if (_jspContainer.miniViewContainer) {
		_jspContainer.surface.createMiniview({container: _jspContainer.miniViewContainer}); // create miniView
		/**
		 *
		 */
		document.getElementById(_jspContainer.miniViewContainer.id).onclick = () => _jspContainer.surface.zoomToFit(); // hook miniview-left-click-event to surface.zoomToFit

		cId2jqSel(_jspContainer.miniViewContainer.id).attr("title", getTranslation("jspManager.mnv-tt")); // set tooltip for miniView						//* @jquery
		bindDomElementToTranslateEvent(cId2jqSel(_jspContainer.miniViewContainer.id), "title", "jspManager.mnv-tt"); // hook tooltip  to translate event
	}

	_jspContainer.surface.setZoomRange([0, 2.5]); // limit zooming to sensible values

	_jspContainer.canvasContainer.querySelector(".jtk-surface-canvas").dataset.uuid = _jspContainer.UUID; // add dataset.uuid to jsp surface dom container
}

/**
 * Add a draggable nodes palette to a given JspRootNode (resp. it's surface/renderer)
 * @param {JspContainer} _jspContainer to add instance to
 */
function registerPaletteNodes(_jspContainer) {
	_jspContainer.palette = new SurfaceDropManager({
		// new, instead of _jspContainer.surface.registerDroppableNodes
		surface: _jspContainer.surface,
		source: document.getElementById("sidePanelLeft"),
		selector: ".jspPaletteNode",
		dataGenerator: function (el) {
			correctRootCanvasDeviation();
			getJspContainerByUUID("root").raiseCanvas(); // the canvas must be in the foreground during the dropping
			return {
				type: el.getAttribute("jtkNodeType"),
				databaseId: parseInt(el.getAttribute("databaseId")),
			};
		},
	});
}

/**
 * Set some final parameters on newly created jspNodes
 * @param {JspNode} _jspNode to set up
 */
function finalizeJspNode(_jspNode) {
	// update name and referenceDesignator properties of newly created jspNode
	if (applicationRoot.debugMode) {
		_jspNode.data.name = `${getDataNodeByUUID(_jspNode.data.UUID).getName()} |${_jspNode.data.UUID}|`;
	} else {
		_jspNode.data.name = getDataNodeByUUID(_jspNode.data.UUID).getName();
	}

	// _jspNode.data.tooltip = (getDataNodeByUUID(_jspNode.data.UUID).group == "functions") ? _jspNode.data.name + "\n" + getDeviceSourceDataByDatabaseId(_jspNode.data.databaseId).materialNumber + "\n" + _jspNode.data.referenceDesignator : _jspNode.data.name;
	// addValidationStatusSwitcherToJspNode(_jspNode);	// TODO reactivate later
	// _jspNode.setValidationStatus(getDataNodeByUUID(_jspNode.data.UUID).getValidationStatus());	// TODO replace by a function evaluating the corresponding dataNodes validated property and initializing the newly created node accordingly
	// addKeyParameterTableToJspNode(_jspNode);	// TODO reactivate later

	getJspContainerByJspNodeUUID(_jspNode.data.UUID).instance.updateNode(_jspNode);
}

/**
 * Adds a function to a jspNode to switch the graphical representation (infoProvider) of it's validation status
 * @param {JspNode} _jspNode to add the switcher to
 */
function addValidationStatusSwitcherToJspNode(_jspNode) {
	const tmpElement = $(`[data-jtk-node-id=${_jspNode.id}]`).find("#canvasNode-infoProvider"); // get infoProvider element of _jspNode DOM representation		//* @jquery

	/**
	 *
	 * @param validationStatus
	 */
	_jspNode.setValidationStatus = (validationStatus) => {
		const tmpI18nIdentifier = validationStatus.tooltip;
		tmpElement.removeClass(); // remove all classes
		tmpElement.addClass(validationStatus.class); // add class corresponding to validation mode
		tmpElement.attr("title", getTranslation(validationStatus.tooltip)); // add tooltip corresponding to validation mode																//* @jquery
		bindDomElementToTranslateEvent(tmpElement, "title", tmpI18nIdentifier);
	};
}

/**
 * Adds a table of technical parameters to a jspNode
 * @param {JspNode} _jspNode to add parameter table to
 */
function addKeyParameterTableToJspNode(_jspNode) {
	const tmpDataNode = getDataNodeByUUID(_jspNode.data.UUID);
	if (tmpDataNode.keyParameters.length == 0) return; // abort for dataNodes that don't have key parameters defined

	const tmpParameterContainer = document.querySelector(`[uuid='${_jspNode.data.UUID}']`).querySelector("#canvasNode-parameters");
	if (tmpParameterContainer.children.length > 0) return; // abort if keyParameterTable already is filled with data (that is a DIRTY HACK since finalizeJspNode and therefore addKeyParameterTableToJspNode is called to often (nodeFactory - ok | eCreateJspNode - ok | eRelocateJspNode - not ok) -> you need to fix eRelocateJspNode)

	tmpDataNode.keyParameters.forEach((tmpParameter) => {
		const tmpLabel = technicalUnitEnum[tmpParameter.name.toUpperCase()].translation;
		const tmpValue = getDataNodeByUUID(_jspNode.data.UUID).ports[tmpParameter.port].interfaces[tmpParameter.interface][tmpParameter.name];
		const tmpUnit = technicalUnitEnum[tmpParameter.name.toUpperCase()].unit;

		$("<tr>", {
			//* @jquery
			id: tmpParameter.name,
			class: "jspNode-parameterTable-data-row",
		}).appendTo(tmpParameterContainer);

		$("<td>", {
			//* @jquery
			id: "label",
			text: getTranslation(tmpLabel),
			class: "jspNode-parameterTable-label-element",
		}).appendTo(tmpParameterContainer.querySelector(`#${tmpParameter.name}`));
		bindDomElementToTranslateEvent(tmpParameterContainer.querySelector(`#${tmpParameter.name}`).querySelector("#label"), "textContent", tmpLabel);

		$("<td>", {
			//* @jquery
			id: "value",
			text: tmpValue,
			class: "jspNode-parameterTable-value-element",
		}).appendTo(tmpParameterContainer.querySelector(`#${tmpParameter.name}`));

		$("<td>", {
			//* @jquery
			id: "unit",
			text: tmpUnit,
			class: "jspNode-parameterTable-unit-element",
		}).appendTo(tmpParameterContainer.querySelector(`#${tmpParameter.name}`));
	});
}

/* ======================================== EVENT HANDLING ======================================== */

/**
 * Hooks the drawModeChangedHandler to a given jspNode
 * @param {JspNode} _jspNode to hook handler to
 */
function addDrawModeChangeHandler2jspNode(_jspNode) {
	/**
	 *
	 * @param _mode
	 */
	_jspNode.drawModeChangeHandler = (_mode) => {
		const tmpInstance = getJspContainerByJspNodeUUID(_jspNode.data.UUID).instance;
		const tmpDataNode = getDataNodeByUUID(_jspNode.data.UUID);

		// update jsp image
		_jspNode.data.image = tmpDataNode.graphics.activeGraphics.file;
		_jspNode.data.width = tmpDataNode.graphics.activeGraphics.width;
		_jspNode.data.height = tmpDataNode.graphics.activeGraphics.height;
		tmpInstance.updateNode(_jspNode);

		//REFACTOR this part should be extracted into a separate function. so it can be reused to also update the port positions on unitNodes (when the number of ports changes)
		//REFACTOR see eUpdateJspPortPosition
		//REFACTOR goal is to have one updateJspPortPosition to handle switching image/symbole & adding/removing ports on unitNodes
		//REFACTOR alternatively, unitNode-Ports could very well be distributed by css
		// update jsp ports
		_jspNode.getPorts().forEach((jspPort) => {
			// find entry in tmpDataNode.ports that corresponds with jspPort (via portId)
			const tmpPort = tmpDataNode.ports.find((_port) => _port.UUID === jspPort.data.UUID);

			if (tmpPort) {
				const newJspPortPosition = {
					x: roundFloat(tmpDataNode.graphics.activeGraphics.width * tmpPort.graphics.activeGraphics.position.x - portSize / 2, 2), // calculate absolute position of port
					y: roundFloat(tmpDataNode.graphics.activeGraphics.height * tmpPort.graphics.activeGraphics.position.y - portSize / 2, 2), // calculate absolute position of port
				};

				tmpInstance.updatePort(jspPort, {position: newJspPortPosition});
			}
		});
	};

	GLOBALEVENTMANAGER.addHandler("eGUI_ToggleDrawMode", _jspNode.drawModeChangeHandler);
}

/**
 * Removes the drawModeChangedHandler from a given jspNode
 * @param {JspNode} _jspNode to remove handler from
 */
function removeDrawModeChangeHandlerFromJspNode(_jspNode) {
	GLOBALEVENTMANAGER.removeHandler("eGUI_ToggleDrawMode", _jspNode.drawModeChangeHandler);
}

/**
 * Wrapper for createDataNode event raised from dataManager
 * @param {BaseDataNode} _dataNode that needs a jsp representation
 */
export function eCreateJspNode(_dataNode) {
	if (getJspNodeByUUID(_dataNode.UUID) != false) return; // abort if a jspNode for the given dataNode already exists (prevents event cycles)
	const tmpData = {
		left: _dataNode.graphics.position.x,
		top: _dataNode.graphics.position.y,
		image: _dataNode.graphics.activeGraphics.file,
		width: _dataNode.graphics.activeGraphics.width,
		height: _dataNode.graphics.activeGraphics.height,
		databaseId: _dataNode.__databaseId, //! @remove - we shouldn't need that in jsp
		type: makeFirstLetterLowerCase(_dataNode.nodeType),
		UUID: _dataNode.UUID,
		name: null,
		tooltip: _dataNode.graphics.tooltip,
		referenceDesignator: null,
		draggable: _dataNode.interactionPreset.draggable,
		renamable: _dataNode.interactionPreset.renamable,
		deletable: _dataNode.interactionPreset.deletable,
		clearable: _dataNode.interactionPreset.clearable,
		shoppable: _dataNode.interactionPreset.shoppable,
	};

	let tmpParentJspContainer = null; // is a special case since AssemblyJspNodes get created under InfrastructureContainer/-canvas

	switch (_dataNode.nodeType) {
		case "ProjectNode":
			new JspRootNode();
			return;
		case "InfrastructureNode":
			new JspInfrastructureNode(_dataNode);
			return;
		case "TrashNode":
			new JspTrashNode(_dataNode);
			return;
		case "LimboNode":
			// not relevant for js plumb
			return;
		case "AssemblyNode":
			new JspAssemblyNode(_dataNode);
			tmpParentJspContainer = getJspContainerByUUID("infr");
			setDefaultJspNodePosition(tmpParentJspContainer, tmpData);
			break;
		case "UnitNode":
			tmpParentJspContainer = getJspContainerByUUID(_dataNode.parentUUID);
			break;
		case "DeviceNode":
			tmpParentJspContainer = getJspContainerByUUID(_dataNode.parentUUID);
			break;
		default:
			break;
	}

	const tmpNode = tmpParentJspContainer.instance.addNode(tmpData); // create jspNode inside instance of parentJspContainer and store a reference in tmpNode

	// add handler for drawModeChange Event
	addDrawModeChangeHandler2jspNode(tmpNode);

	tmpParentJspContainer.addChildNode(tmpNode); // add newly created jspNode to its parentJspContainer children property
	finalizeJspNode(tmpNode); // add validation switcher etc ...
}

/**
 * Updates port position
 * @param {BaseDataNode} _dataNode (assembly/unit) node
 * @param {Port} _port port
 */
export function eUpdateJspPortPosition(_dataNode, _port) {
	//! it would be much cleaner to merge this logic with parts of eCreateJspPort, this way everything (incl. captions, tooltips) would be updated cleanly without the need for a second function to handle everything
	let container;
	if (_dataNode.nodeType === "AssemblyNode") {
		container = getJspContainerByUUID("infr");
	} else {
		container = getJspContainerByJspNodeUUID(_dataNode.UUID);
	}
	const jspPort = getJspPortByUUID(_port.UUID);
	const tmpData = jspPort.data;
	const parentDataNodeActiveGraphics = getDataNodeByUUID(_dataNode.UUID).graphics.activeGraphics;

	(tmpData.position = {
		x: roundFloat(parentDataNodeActiveGraphics.width * _port.graphics.activeGraphics.position.x - portSize / 2, 2), // calculate absolute position of port
		y: roundFloat(parentDataNodeActiveGraphics.height * _port.graphics.activeGraphics.position.y - portSize / 2, 2), // calculate absolute position of port
	}),
		// tmpData.rotation = calcPortRotation(_port, portOrientationEnum[_port.graphics.activeGraphics.orientation]);
		(tmpData.rotation = calcRotations(portOrientationEnum[_port.graphics.activeGraphics.orientation], _port.side).portAngle);

	container.instance.updatePort(jspPort, tmpData);
}

/**
 * Toggles the visibility of the link element on a given jspNode.
 * Toggles link tooltip if ports shadow is (un)connected.
 * @param {Port} _port to toggle
 * @param {Connection|null} _connection  on shadowPort
 */
function eToggleJspPortLink(_port, _connection) {
	const jspPort = getJspPortByUUID(_port.UUID);
	const jspDomPortLinkContainer = getJspDomElementByUUID(_port.UUID).querySelector(".base-port-link-container");

	// handle connection removed case / portlink deactivation
	if (_connection === null) {
		jspDomPortLinkContainer.classList.add("turnInvisible");
		const linkedPort = jspPort.data.linkTarget.port;
		const linkedEvent = jspPort.data.linkTarget.referenceDesignatorChangedHandler.event;
		const linkedCallback = jspPort.data.linkTarget.referenceDesignatorChangedHandler.callback;
		linkedPort.eventManager.removeHandler(linkedEvent, linkedCallback);

		jspPort.data.linkTarget = {
			port: null,
			connection: null,
			referenceDesignator: null,
			referenceDesignatorChangedHandler: null,
		};
		return; // skip the rest of this function
	}

	jspDomPortLinkContainer.classList.remove("turnInvisible");

	const linkedTargetPort = _connection.ports.find((_connectionPort) => _connectionPort.isConnectedTo.UUID !== _port.isShadowedBy).isConnectedTo;
	const jspInstance = getJspContainerByJspPortUUID(jspPort.data.UUID).instance;

	jspPort.data.linkTarget = {
		port: linkedTargetPort,
		connection: _connection,
		referenceDesignator: linkedTargetPort.referenceDesignator.getReferenceDesignator.string(),
		referenceDesignatorChangedHandler: linkedTargetPort.eventManager.addHandler(linkedTargetPort.signatures.referenceDesignatorChanged, (_referenceDesignator) => {
			jspPort.data.linkTarget.referenceDesignator = _referenceDesignator.getReferenceDesignator.string();
			jspInstance.updatePort(jspPort);
		}),
	};

	jspInstance.updatePort(jspPort);
}

/**
 * Wrapper for eDTM_DataNodeSendToLimbo event raised from dataManager
 * @param {BaseDataNode} _dataNode that needs a jsp representation
 */
export function eDeleteJspObject(_dataNode) {
	switch (_dataNode.nodeType) {
		case "ProjectNode":
			// ProjectJspContainer can't be deleted
			break;
		case "InfrastructureNode":
			// InfrastructureJspContainer can't be deleted
			break;
		case "TrashNode":
			// TrashJspContainer can't be deleted
			break;
		case "AssemblyNode":
			// remove infrastructure representation of assemblyJspNode
			removeDrawModeChangeHandlerFromJspNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).removeChildNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).instance.removeNode(getJspNodeByUUID(_dataNode.UUID));

			// remove assemblyJspContainer
			getJspContainerByUUID(_dataNode.UUID).delete();
			break;
		case "UnitNode":
			removeDrawModeChangeHandlerFromJspNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).removeChildNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).instance.removeNode(getJspNodeByUUID(_dataNode.UUID));
			break;
		case "DeviceNode":
			removeDrawModeChangeHandlerFromJspNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).removeChildNode(getJspNodeByUUID(_dataNode.UUID));
			getJspContainerByJspNodeUUID(_dataNode.UUID).instance.removeNode(getJspNodeByUUID(_dataNode.UUID));
			break;
		default:
			break;
	}
}

/**
 * Moves a jspNode to a new parentJspObject reflecting the relocation of a dataNode in dataManager
 * (Basic idea: store jspNode specifics (data, ports, ...), delete it from its current instance/surface & create a new jspNode on the target instance/surface with the stored data)
 * @param {string} _targetParentNodeUUID UUID of targetParentDataNode
 * @param {string} _dataNodeUUID UUID of dataNodes that gets relocated
 */
export function eRelocateJspNode(_targetParentNodeUUID, _dataNodeUUID) {
	// Aborts for jspNodes that just got created (and don't have a parentContainer yet.)
	// 		If we switch get*ByUUID to directly search the instances and NOT the jspContainers, that wouldn't be necessary...
	if (!getJspContainerByJspNodeUUID(_dataNodeUUID)) {
		// return;
	}

	// Relocation of assemblyJspNodes is a bit tricky (since those are usually stored under infrastructure (and not their real dataNodeParent) and may be moved to trash and back)
	if (getJspNodeByUUID(_dataNodeUUID).getType() === "assemblyNode") {
		if (getDataNodeByUUID(_targetParentNodeUUID).nodeType === "TrashNode") {
			// allow moving to trash
		} else if (getJspContainerByJspNodeUUID(_dataNodeUUID).UUID === "trsh") {
			// allow removing from trash (rerouting back to infrastructure instead of project)
			_targetParentNodeUUID = "infr";
		} else {
			return; // abort all other operations (needed while creating assemblyNodes under root)
		}
	}

	const tmpSourceJspContainer = getJspContainerByJspNodeUUID(_dataNodeUUID);
	const tmpTargetJspContainer = getJspContainerByUUID(_targetParentNodeUUID);

	const tmpSourceJspNode = getJspNodeByUUID(_dataNodeUUID);
	const tmpSourceData = tmpSourceJspNode.data;
	const tmpSourcePorts = tmpSourceJspNode.getPorts();

	// remove drawModeHandler
	removeDrawModeChangeHandlerFromJspNode(tmpSourceJspNode);

	// check positional information of tmpSourceData and set a default position (left, top) if necessary
	setDefaultJspNodePosition(tmpTargetJspContainer, tmpSourceData);

	// remove (old) jspNode from source container/instance
	tmpSourceJspContainer.removeChildNode(tmpSourceJspNode);
	tmpSourceJspContainer.instance.removeNode(tmpSourceJspNode);
	if (tmpSourceJspContainer.UUID === "root") getJspContainerByUUID("root").lowerCanvas(); // move root canvas to the background after relocation

	// create (new) jspNode in target instance/container
	const tmpTargetJspNode = tmpTargetJspContainer.instance.addNode(tmpSourceData);
	tmpTargetJspContainer.addChildNode(tmpTargetJspNode);

	// restore ports
	tmpSourcePorts.forEach((e) => {
		addPortWrapper(tmpTargetJspNode.data.UUID, e.data);
	});

	// restore drawModeHandler
	addDrawModeChangeHandler2jspNode(tmpTargetJspNode);

	finalizeJspNode(tmpTargetJspNode);
}

/**
 * Wrapper for eDTM_DataNodeRenamed event raised by dataManager
 * @param {BaseDataNode} _dataNode that got renamed
 */
export function eRenameJspNode(_dataNode) {
	const jspNode = getJspNodeByUUID(_dataNode.UUID);
	if (!jspNode) return; // abort for nodes without canvas-dom-representation

	const newName = _dataNode.getName();

	// that check should be handled by the dispatcher before firing of the event!
	if (jspNode.data.name === newName) return;

	jspNode.data.name = newName;
	getJspContainerByJspNodeUUID(_dataNode.UUID).instance.updateNode(jspNode);
}

/**
 * Wrapper for eDTM_DataNodeTooltipChanged event raised by dataManager
 * @param {BaseDataNode} _dataNode that got renamed
 */
export function eUpdateJspNodeTooltip(_dataNode) {
	const jspNode = getJspNodeByUUID(_dataNode.UUID);
	if (!jspNode) return; // abort for nodes without canvas-dom-representation

	const newTooltip = _dataNode.getTooltip();

	// that check should be handled by the dispatcher before firing of the event!
	if (jspNode.data.tooltip === newTooltip) return;

	jspNode.data.tooltip = newTooltip;
	getJspContainerByJspNodeUUID(_dataNode.UUID).instance.updateNode(jspNode);
}

/**
 * Wrapper for eRDM_ReferenceDesignatorChanged event raised by referenceDesignatorManager
 * @param {BaseDataNode} _dataNode that got renamed
 */
export function eUpdateJspNodeReferenceDesignator(_dataNode) {
	const jspNode = getJspNodeByUUID(_dataNode.UUID);
	if (!jspNode) return; // abort for nodes without canvas-dom-representation

	const newReferenceDesignator = _dataNode.referenceDesignator.getReferenceDesignator().string();

	// that check should be handled by the dispatcher before firing of the event!
	if (jspNode.data.referenceDesignator === newReferenceDesignator) return;

	jspNode.data.referenceDesignator = newReferenceDesignator;
	getJspContainerByJspNodeUUID(_dataNode.UUID).instance.updateNode(jspNode);
}

/**
 * Wrapper for eDTM_DataNodeValidationChanged event raised by dataManager
 * @param {BaseDataNode} _dataNode that got changed
 */
export function eUpdateJspNodeValidationIndicator(_dataNode) {
	getJspNodeByUUID(_dataNode.UUID).setValidationStatus(_dataNode.getValidationStatus());
}

/**
 * Wrapper for eDTM_ActivateGroupNodeChanged event raised from dataManager; deactivates dragging of accordion items if activeGroupNode is not an AssemblyNode
 * @param {BaseDataNode} _activeGroupNode new active GroupNode
 */
export function eSwitchNodePaletteMode(_activeGroupNode) {
	if (_activeGroupNode.nodeType === "AssemblyNode" || _activeGroupNode.nodeType === "InfrastructureNode") {
		jspRoot.preventAccordionDragging = false;
	} else {
		jspRoot.preventAccordionDragging = true;
	}
}

/**
 * Wrapper for AddPortEvent raised from dataManager
 * @param {Port} _port that got added
 */
export function eCreateJspPort(_port) {
	//! move that into a updatePortData routine (could be used for eUpdateJspPort, updateJspPortPosition as well - why do we need to differentiate between updateJspPort and updateJspPortPosition?)
	const parentDataNodeActiveGraphics = getDataNodeByUUID(_port.parentUUID).graphics.activeGraphics;
	const portPosition = {
		x: roundFloat(parentDataNodeActiveGraphics.width * _port.graphics.activeGraphics.position.x - portSize / 2, 2), // calculate absolute position of port
		y: roundFloat(parentDataNodeActiveGraphics.height * _port.graphics.activeGraphics.position.y - portSize / 2, 2), // calculate absolute position of port
	};

	const tmpPortOrientation = portOrientationEnum[_port.graphics.activeGraphics.orientation]; // helper to make code below more readable

	const portData = {
		side: _port.side, //! @remove? need to check...
		UUID: _port.UUID,
		id: _port.UUID, // replace by jtk internal id			//! @remove why should we do that? Leave jsp to it's internal ids! 		ok if everything is clear with data-uuid
		type: "electricPort",
		position: portPosition,
		anchor: tmpPortOrientation,
		portAngle: tmpPortOrientation.angle, // this is the global angle the port and all its dom children rotate around
		ioAngle: _port.side === PortSide.TARGET ? 180 : 0,
		portSize,
		portArrow,
		linkTarget: {
			port: null,
			connection: null,
			referenceDesignator: null,
			referenceDesignatorChangedHandler: null,
		},
		referenceDesignatorAngle: tmpPortOrientation === portOrientationEnum.SOUTH || tmpPortOrientation === portOrientationEnum.WEST ? 180 : 0, // avoid upside down text

		color: setJspPortColor(_port.flattenInterfaces(), _port.isShielded),
	};

	const jspInstance = getJspContainerByJspNodeUUID(_port.parent.UUID).instance;
	const jspNode = getJspNodeByUUID(_port.parent.UUID);
	const jspPort = jspInstance.addPort(jspNode, portData);
}

/**
 * Determines the color of a jspPort.
 * @param {Array} _flatInterfaces interfaces of underlying connection
 * @param {string} _isShielded property of underlying connection
 * @returns {string} cssClass to color port with
 */
function setJspPortColor(_flatInterfaces, _isShielded) {
	// turn flatInterfaces into kebab-case (css class naming)
	_flatInterfaces = _flatInterfaces.map((_flatInterface) => _flatInterface.toLowerCase().replaceAll("_", "-"));

	let cssClassSuffix = null;

	switch (true) {
		case _flatInterfaces.length === 1:
			cssClassSuffix = _flatInterfaces[0];
			break;
		case _flatInterfaces.length === 2:
			if (_isShielded && _flatInterfaces.includes("energy-ac") && _flatInterfaces.includes("measure-digital")) {
				cssClassSuffix = "energy-servo";
			} else {
				cssClassSuffix = _flatInterfaces[0];
			}
			break;
		case _flatInterfaces.length > 2:
			console.warn("Unsure how to color connections with more than 2 interfaces.");
			cssClassSuffix = _flatInterfaces[0];
			break;
		default:
			throw new Error("No interfaces to color.");
	}

	return `electric-port-${cssClassSuffix}`;
}

//! to be removed
/**
 * Calculates the rotation of the iLink element and its caption
 * @param {portOrientationEnum} _orientation of port (retrieved from database)
 * @param _side
 * @returns {object} rotations of port, link & caption in degrees
 */
function calcRotations(_orientation, _side) {
	switch (_orientation) {
		case portOrientationEnum.NORTH:
			return {portAngle: _side === PortSide.TARGET ? 180 : 0};
		case portOrientationEnum.EAST:
			return {portAngle: _side === PortSide.TARGET ? 270 : 90};
		case portOrientationEnum.SOUTH:
			return {portAngle: _side === PortSide.TARGET ? 0 : 180};
		case portOrientationEnum.WEST:
			return {portAngle: _side === PortSide.TARGET ? 90 : 270};
	}
}

/**
 * Wrapper for RemovePortEvent raised from dataManager
 * @param {Port} _port that got added
 * @param {BaseDataNode} _dataNode that _port was added to
 */
export function eRemoveJspPort(_port, _dataNode) {
	removePortWrapper(_dataNode.UUID, _port.UUID);
}

/**
 * Handler for eDTM_CreateConnection event raised from dataGroupNode
 * @param {Connection} _connection connection to create in jsp
 * @param {Port} _sourceDevicePort connection to create in jsp
 * @param {Port} _targetDevicePort connection to create in jsp
 * @listens eDTM_CreateConnection
 */
export function eCreateJspEdge(_connection, _sourceDevicePort, _targetDevicePort) {
	const jspInstance = getJspContainerByUUID(_connection.parent.UUID).instance;
	const jspSourcePort = getJspPortByUUID(_sourceDevicePort.UUID);
	const jspTargetPort = getJspPortByUUID(_targetDevicePort.UUID);
	const jspSourceNode = getJspNodeByUUID(_sourceDevicePort.parent.UUID);
	const jspTargetNode = getJspNodeByUUID(_targetDevicePort.parent.UUID);

	const jspData = {
		type: "electricEdge",
		UUID: _connection.UUID,
		id: _connection.UUID, //! @remove why should we do that? Leave jsp to it's internal ids!
	};

	const newJspEdge = jspInstance.addEdge({source: `${jspSourceNode.id}.${jspSourcePort.id}`, target: `${jspTargetNode.id}.${jspTargetPort.id}`, data: jspData});

	finalizeJspDomEdge(newJspEdge, _connection.UUID);

	if (_connection.geometry) {
		// setEdgeGeometry(_connection.UUID, _connection.geometry);		//! doesn't work atm, fix later
	}
	// calculateCableColor(_connection);			//! <-- schau dir mal an, ob da was schlaues dabei ist
	setJspEdgeColors(newJspEdge, _connection.flattenInterfaces(), _connection.isShielded);
}

/**
 * Determines the color of a jspEdge.
 * @param {JspEdge} _jspEdge to color
 * @param {Array} _flatInterfaces interfaces of underlying connection
 * @param {string} _isShielded property of underlying connection
 */
function setJspEdgeColors(_jspEdge, _flatInterfaces, _isShielded) {
	// turn flatInterfaces into kebab-case (css class naming)
	_flatInterfaces = _flatInterfaces.map((_flatInterface) => _flatInterface.toLowerCase().replaceAll("_", "-"));

	const tmpJspDomEdge = getJspDomElementByUUID(_jspEdge.data.UUID);

	let cssCableSuffixTOP = null;
	let cssCableSuffixBOTTOM = null;

	// for single color connections, both paths get the same color (they are still dashed though)

	switch (true) {
		case _flatInterfaces.length === 1:
			cssCableSuffixTOP = _flatInterfaces[0];
			cssCableSuffixBOTTOM = _flatInterfaces[0];
			break;
		case _flatInterfaces.length === 2:
			if (_isShielded && _flatInterfaces.includes("energy-ac") && _flatInterfaces.includes("measure-digital")) {
				cssCableSuffixTOP = "energy-servo";
				cssCableSuffixBOTTOM = "energy-servo";
			} else {
				cssCableSuffixTOP = _flatInterfaces[0];
				cssCableSuffixBOTTOM = _flatInterfaces[1];
			}
			break;
		case _flatInterfaces.length > 2:
			console.warn("Unsure how to color connections with more than 2 interfaces.");
			cssCableSuffixTOP = _flatInterfaces[0];
			cssCableSuffixBOTTOM = _flatInterfaces[1];
			break;
		default:
			throw new Error("No interfaces to color.");
	}
	tmpJspDomEdge.getElementById("upperPath").classList.add("electric-edge-top", `electric-edge-${cssCableSuffixTOP}`);
	tmpJspDomEdge.getElementById("lowerPath").classList.add("electric-edge-bottom", `electric-edge-${cssCableSuffixBOTTOM}`);
}

/**
 * Wrapper for eDTM_RemoveConnection event raised from dataManager
 * @param {Connection} _connection to remove
 */
function eRemoveJspEdge(_connection) {
	const jspEdge = getJspEdgeByUUID(_connection.UUID);
	if (!jspEdge) return; // prevent event chaining

	const jspInstance = getJspContainerByJspEdgeUUID(_connection.UUID).instance;
	jspInstance.removeEdge(jspEdge);
}

/**
 * Wrapper for eDTM_DataNodeRepositioned raised from dataManager
 * @param {BaseDataNode} _dataNode that got changed
 */
export function eRepositionJspNode(_dataNode) {
	if (!getJspNodeByUUID(_dataNode.UUID)) return; // abort if no matching jspNode exists (respectively has not yet been created)
	const tmpJspNode = getJspNodeByUUID(_dataNode.UUID);
	const tmpPosition = _dataNode.getPosition();
	if (tmpJspNode.data.left === tmpPosition.x && tmpJspNode.data.top === tmpPosition.y) return; // abort if position has not changed
	tmpJspNode.data.left = tmpPosition.x;
	tmpJspNode.data.top = tmpPosition.y;
	getJspContainerByJspNodeUUID(tmpJspNode.data.UUID).surface.setPosition(tmpJspNode, tmpPosition.x, tmpPosition.y);
}

/**
 * Wrapper for eDTM_TechnicalParameterChanged raised from dataManager
 * @param {object} _element that got changed: {parameter, interface, port, dataNode}
 */
export function eTechnicalParameterUpdate(_element) {
	const tmpTable = document.querySelector(`[uuid='${_element.dataNode.UUID}']`).querySelector("#canvasNode-parameters");
	const tmpDataRow = tmpTable.querySelector(`#${_element.parameter}`);
	const tmpValueCell = tmpDataRow.querySelector("#value");

	tmpValueCell.textContent = _element.interface[_element.parameter];
}

/**
 * Zoom the canvas to show all nodes
 * @param {string} _UUID of the canvas container
 */
export function eZoomCanvasToFit(_UUID) {
	if (_UUID) {
		const surface = getJspContainerByUUID(_UUID).surface;
		if (surface) {
			surface.zoomToFit();
		}
	}
}

/**
 * Focusses on a jspNode.
 * @param {{type: ("port"|"cable"|"dataNode"), UUID: string}} _element to focus
 */
function eFocusJspElement(_element) {
	//REFACTOR in a better world we wouldn't need that function or that switch,
	//REFACTOR .focus would be a method of jspNode-wrapper, jspPort-Wrapper,
	//REFACTOR  jspCable-wrapper, therefore the code below is quick&dirty!

	switch (_element.type) {
		case "port": {
			// jspSurface.centerOn/centerOnAndZoom is unreliable for jspPorts, instead we center on the parent jspNode
			const port = getPortByUUID(_element.UUID);
			const dataNode = getDataNodeByUUID(port.parentUUID);
			const parentGroupNode = dataNode.getType() === "AssemblyNode" ? getUniqueDataNodeByType("InfrastructureNode") : getDataNodeByUUID(dataNode.parentUUID); // assemblies are children of project... :-/
			const jspSurface = getJspContainerByUUID(parentGroupNode.UUID).surface;

			const element2Focus = getJspNodeByUUID(dataNode.UUID);

			parentGroupNode.setActive();
			jspSurface.centerOnAndZoom(element2Focus, 0.2); // jspSurface.centerOn(element2Focus);
			break;
		}

		case "cable": {
			// (try again in jsp5+) according to jsp docs, jspSurface.centerOn/centerOnAndZoom accept jspObject or domelement arguments,
			// 		but nothing happens when provided with a jspEdge or the svg container
			// therefore we take a different route here and use jspSurface.animateToSelection; as the selection we use both jspNodes at the ends of the cable

			const cable = getConnectionByUUID(_element.UUID);
			const parentGroupNode = getDataNodeByUUID(cable.parentUUID);

			const jspEdge = getJspEdgeByUUID(_element.UUID);
			const jspSourceNode = jspEdge.source.getNode();
			const jspTargetNode = jspEdge.target.getNode();

			const jspInstance = getJspContainerByJspEdgeUUID(_element.UUID).instance;
			const jspSurface = getJspContainerByJspEdgeUUID(_element.UUID).surface;

			const tmpSelection = jspInstance.select([jspSourceNode, jspTargetNode]);

			parentGroupNode.setActive();
			jspSurface.animateToSelection({selection: tmpSelection});
			break;
		}

		case "dataNode": {
			if (getDataNodeByUUID(_element.UUID).getType() === "LimboNode") return;

			const dataNode = getDataNodeByUUID(_element.UUID);
			const isGroupNode = !!(dataNode.getType() !== "DeviceNode" && dataNode.getType() !== "UnitNode");
			const isProjectNode = dataNode === getUniqueDataNodeByType("ProjectNode");

			const parentGroupNode = isGroupNode ? dataNode : getDataNodeByUUID(dataNode.parentUUID);
			const jspSurface = getJspContainerByUUID(parentGroupNode.UUID).surface;

			const element2Focus = getJspNodeByUUID(_element.UUID);

			parentGroupNode.setActive();

			if (isProjectNode) return;
			if (isGroupNode) jspSurface.zoomToFit();
			jspSurface.centerOnAndZoom(element2Focus, 0.2); // jspContainer.centerOn(element2Focus);
			break;
		}

		default:
			throw new Error("Unknown element type.");
	}
}

/* ======================================== HELPERS ======================================== */

/**
 * Gets a jspNode by its UUID.
 * @param {UUID} _UUID of jspNode to find
 * @returns {JspNode|false} jspNode with matching _UUID or false
 */
export function getJspNodeByUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	let result;

	for (const jspContainer of jspRoot.jspContainerList) {
		result = jspContainer.instance.getNodes().find((jspNode) => jspNode.data.UUID === _UUID);
		if (result) break;
	}

	return result || false;
}

/**
 * Gets a jspPort by its UUID.
 * @param {UUID} _UUID of jspPort to find
 * @returns {JspPort|false} jspPort with matching _UUID or false
 */
export function getJspPortByUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	let result;

	for (const jspContainer of jspRoot.jspContainerList) {
		for (const jspNode of jspContainer.instance.getNodes()) {
			result = jspNode.getPorts().find((jspPort) => jspPort.data.UUID === _UUID);
			if (result) break;
		}
		if (result) break;
	}

	return result || false;
}

/**
 * Gets a jspEdge by its UUID.
 * @param {UUID} _UUID of jspEdge to find
 * @returns {JspEdge|false} jspEdge with matching _UUID or false
 */
export function getJspEdgeByUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	let result;

	for (const jspContainer of jspRoot.jspContainerList) {
		result = jspContainer.instance.getAllEdges().find((jspEdge) => jspEdge.data.UUID === _UUID);
		if (result) break;
	}

	return result || false;
}

/**
 * Retrieves a jsp DOM element by its UUID.
 * @param {UUID} _UUID of DOM element to find.
 * @returns {HTMLElement|null} DOM element with matching UUID or null.
 */
export function getJspDomElementByUUID(_UUID) {
	/* Explanation:
			basic idea:	use document.querySelector(`[data-uuid=${_UUID}]`) to retrieve dom elements with data-uuid = _UUID
			problem:		data-uuid could exist on any arbitrary element in the dom (e.g. messenger spans), not only on jsp dom elements
			solution:		we need an additional attribute 'jspDomIdentifier' to only get jsp dom elements.
			result:			e.g. for jspDomIdentifier='.jtk-node', document.querySelector(`.jtk-node[data-uuid='${_UUID}']`) retrieves dom element with class .jtk-node AND data-uuid = _UUID
	 */

	/**
	 *
	 */
	const jspDomIdentifier = () => {
		switch (true) {
			case !!getJspContainerByUUID(_UUID): // '!!' converts successful calls of getJsp*ByUUID to true
				return ".jtk-surface-canvas";
			case !!getJspNodeByUUID(_UUID):
				return ".jtk-node";
			case !!getJspPortByUUID(_UUID):
				return ".jtk-port";
			case !!getJspEdgeByUUID(_UUID):
				return ".jtk-connector";
			default:
				return null;
		}
	};

	if (!jspDomIdentifier()) return false; // early return if no jsp-non-dom-element was found

	const tmpQuery = document.querySelector(`${jspDomIdentifier()}[data-uuid='${_UUID}']`);
	const result = tmpQuery || false;
	return result;
}

/**
 * Gets a jspContainer by its UUID.
 * @param {UUID} _UUID of jspContainer to find
 * @returns {JspContainer|false} jspContainer with matching _UUID or false
 */
export function getJspContainerByUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	const result = jspRoot.jspContainerList.find((jspContainer) => jspContainer.UUID === _UUID);
	return result || false;
}

/**
 * Returns the parent jspContainer of a jspNode.
 * @param {UUID} _UUID UUID of jspNode to find the parent jspContainer for
 * @returns {JspContainer|false} parent jspContainer of the provided jspNode UUID or false
 */
export function getJspContainerByJspNodeUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	const result = jspRoot.jspContainerList.find((jspContainer) => jspContainer.instance.getNodes().some((jspNode) => jspNode.data.UUID === _UUID));
	return result || false;
}

/**
 * Returns the parent jspContainer of a jspPort.
 * @param {UUID} _UUID UUID of jspPort to find the parent jspContainer for
 * @returns {JspContainer|false} parent jspContainer of the provided jspPort UUID or false
 */
export function getJspContainerByJspPortUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	const result = jspRoot.jspContainerList.find((jspContainer) =>
		jspContainer.instance.getNodes().some((jspNode) => jspNode.getPorts().some((jspPort) => jspPort.data.UUID === _UUID)),
	);
	return result || false;
}

/**
 * Returns the parent jspContainer of a jspEdge.
 * @param {UUID} _UUID UUID of jspEdge to find the parent jspContainer for
 * @returns {JspContainer|false} parent jspContainer of the provided jspEdge UUID or false
 */
export function getJspContainerByJspEdgeUUID(_UUID) {
	//TODO Don't export! This shouldn't be used anywhere else
	const result = jspRoot.jspContainerList.find((jspContainer) => jspContainer.instance.getAllEdges().some((jspEdge) => jspEdge.data.UUID === _UUID));
	return result || false;
}

/**
 * Adds several html Attributes to the DOM representation of a jspEdge.
 * (UUID to dataset, title, lowerPath/upperPath)
 * @param {JspEdge} _jspEdge to add a DOM-id to
 * @param {UUID} _UUID to set
 */
function finalizeJspDomEdge(_jspEdge, _UUID) {
	//! this is madness - I didn't find another way and I tested thoroughly. Thank you jsPlumb. I hate you!
	// the basic idea:
	// 1. get the surface of an edge
	// 2. get the surface.path representing the edge - you're not able to manipulate that "dom-like" element!
	// 3. add a temporary css-class as an identifier to path
	// 4. select the real DOM element via temporary css-class - now you can manipulate that dom element
	// 5. set the UUID
	// 6. remove the temporary css-class
	// 7. add lowerPath/upperPath to the path children
	// 8. add a title

	const tmpSurface = getJspContainerByJspEdgeUUID(_jspEdge.data.UUID).surface;

	const tmpPath = tmpSurface.getPath({
		source: _jspEdge.source,
		target: _jspEdge.target,
		/**
		 *
		 * @param jspEdge
		 */
		edgeFilter: (jspEdge) => jspEdge.data.UUID === _UUID,
	});
	tmpPath.addEdgeClass("tmpUUIDHelper");
	const tmpDomEdge = document.querySelector(".tmpUUIDHelper.jtk-connector");
	tmpDomEdge.dataset.uuid = _UUID;
	tmpPath.removeEdgeClass("tmpUUIDHelper");

	tmpDomEdge.children[0].id = "upperPath";
	tmpDomEdge.children[1].id = "lowerPath";

	tmpDomEdge.prepend(document.createElementNS("http://www.w3.org/2000/svg", "title"));
}

/**
 * Wrapper to consolidate all addPort functions.
 * @param {UUID} _UUID UUID of jspNode to add jspPort to
 * @param {object} _portData port details
 */
function addPortWrapper(_UUID, _portData) {
	getJspContainerByJspNodeUUID(_UUID).instance.addPort(getJspNodeByUUID(_UUID), _portData);
}

/**
 * Wrapper to consolidate all removePort functions.
 * @param {UUID} _UUID UUID of jspNode to remove jspPort from
 * @param {string} _portId of port that gets removed		//REFACTOR What is this? A jspPortUUID or the jsp internal id?
 */
function removePortWrapper(_UUID, _portId) {
	getJspContainerByJspNodeUUID(_UUID).instance.removePort(getJspNodeByUUID(_UUID), _portId);
}

/**
 * Places jspNodes without positional information / TODO Just an interim solution! A future implementation has to be much more elaborated!
 * @param {JspContainer} _jspContainer containing the jspNode to place
 * @param {object} _jspNodeData of the jspNode to place
 */
function setDefaultJspNodePosition(_jspContainer, _jspNodeData) {
	if (_jspNodeData.left != null || _jspNodeData.top != null) return; // don't alter jspNodes that already have a position
	// const numberChildren = _jspContainer.children.length;						// get the number of nodes inside the jpsContainer of _jspNode
	const children = _jspContainer.children.filter((child) => child.data.type === _jspNodeData.type);
	const numberChildren = children.length; // for assemblies in infrastructure
	const containerHeight = _jspContainer.surface.getHeight(); // get the height of the jpsContainer of _jspNode
	const step = 175; // default distance between jspNodes
	const margin = 50; // default distance from left & bottom canvas borders

	_jspNodeData.left = margin + step * numberChildren;
	children && children.length > 0
		? (_jspNodeData.top = children[children.length - 1].data.top)
		: (_jspNodeData.top = containerHeight - step + margin - _jspNodeData.height); //! this is unreadable!
	GLOBALEVENTMANAGER.dispatch("eJSP_NodeRepositioned", _jspNodeData.UUID, {x: _jspNodeData.left, y: _jspNodeData.top}); // update associated dataNode
}

/**
 * Returns zoom & apparentCanvasLocation of the provided jspContainer
 * @param {JspContainer} _jspContainer to analyze
 */
function updateLayoutParameters(_jspContainer) {
	if (_jspContainer.suppressLayoutEvents) return;

	const tmpLayout = {
		zoom: _jspContainer.surface.getZoom(),
		pan: {
			x: _jspContainer.surface.getApparentCanvasLocation()[0],
			y: _jspContainer.surface.getApparentCanvasLocation()[1],
		},
	};

	GLOBALEVENTMANAGER.dispatch("eJSP_SurfaceLayoutChanged", _jspContainer.UUID, tmpLayout);
}

/** Corrects the Zoom/Pan deviation between rootCanvas and ActiveCanvas (while dragging jspNodes from palette onto ActiveCanvas */
function correctRootCanvasDeviation() {
	const tmpRootSurface = getJspContainerByUUID("root").surface;
	const tmpActiveSurface = getJspContainerByUUID(canvasRoot.activeCanvas.UUID).surface;

	// reset rootCanvas
	tmpRootSurface.setZoom(1);
	tmpRootSurface.setPan(0, 0);

	// confirm rootCanvas to activeCanvas
	tmpRootSurface.setZoom(tmpActiveSurface.getZoom());
	tmpRootSurface.pan(tmpActiveSurface.getApparentCanvasLocation()[0], tmpActiveSurface.getApparentCanvasLocation()[1]);
}

/**
 * Corrects the Zoom/Pan deviation between two surfaces (useful for moving jspNodes between canvasses)
 * TODO not needed atm; maybe later depending on how we decide to handle jspNode placement when relocation; right now JspNode.mapLocation is simply copied
 * @param {JspSurface} _activeSurface that defines zoom/pan settings
 * @param {JspSurface} _otherSurface that needs to be altered
 */
function correctCanvasDeviation(_activeSurface, _otherSurface) {
	const tmpActiveSurface = _activeSurface;
	const tmpOtherSurface = _otherSurface;

	// store layout settings of activeCanvas
	const tmpActiveSurfaceLayout = {
		zoom: tmpActiveSurface.getZoom(),
		apparentCanvasLocation: {x: tmpActiveSurface.getApparentCanvasLocation()[1], y: tmpActiveSurface.getApparentCanvasLocation()[1]},
	};

	// store layout settings of otherCanvas
	const tmpOtherSurfaceLayout = {
		zoom: tmpOtherSurface.getZoom(),
		apparentCanvasLocation: {x: tmpOtherSurface.getApparentCanvasLocation()[1], y: tmpOtherSurface.getApparentCanvasLocation()[1]},
	};
}

/**
 * Sets a surface into selection/pan mode
 * @param {boolean} _selectionMode to set (true: select / false: pan)
 */
export function setSelectionMode(_selectionMode) {
	if (dataRoot.activeGroupNode === null) return; // only relevant during initialization
	if (dataRoot.activeGroupNode.nodeType === "ProjectNode") return;
	if (_selectionMode) {
		getJspContainerByUUID(dataRoot.activeGroupNode.UUID).surface.setMode("select");
	} else {
		getJspContainerByUUID(dataRoot.activeGroupNode.UUID).surface.setMode("pan");
	}
}

/** An embarrassing solution to allow manual detaching of edges only while shift key is pressed */
function watchKeys() {
	/**
	 *
	 * @param _e
	 */
	window.onkeydown = (_e) => {
		if (_e.key === "Control") CtrlKey = true;
	};

	/**
	 *
	 * @param e
	 */
	window.onkeyup = (e) => {
		if (e.key === "Control") CtrlKey = false;
	};
}

/**
 * Wrapper for eCM_StartEditPath event raised from ContextMenu
 * @param {UUID} _UUID UUID of JspEdge to edit
 */
export function eStartEditPath(_UUID) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	const jspContainer = getJspContainerByJspEdgeUUID(_UUID);
	jspContainer.instance.getAllEdges().forEach((jspEdge) => {
		if (jspEdge.data.UUID !== _UUID) {
			GLOBALEVENTMANAGER.dispatch("eCM_StopEditPath", jspEdge.data.UUID);
		}
	});
	const jspEdge = getJspEdgeByUUID(_UUID);
	jspContainer.surface.startEditing(jspEdge);
	GLOBALEVENTMANAGER.dispatch("eJSP_PathEditStarted", _UUID);
}

/**
 * Wrapper for eCM_StopEditPath event raised from ContextMenu
 * @param {UUID} _UUID UUID of the jspEdge to stop editing
 */
export function eStopEditPath(_UUID) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	const jspContainer = getJspContainerByJspEdgeUUID(_UUID);
	jspContainer.surface.stopEditing(getJspEdgeByUUID(_UUID));
	const geometry = getEdgeGeometry(_UUID);
	const edited = getEdgeEdited(_UUID);
	GLOBALEVENTMANAGER.dispatch("eJSP_PathEditStopped", _UUID, geometry, edited);
}

/**
 * Setting edge geometry
 * @param {UUID} _UUID UUID of the jspEdge to set geometry for
 * @param {object} _geometry to set
 */
function setEdgeGeometry(_UUID, _geometry) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	const jspSurface = getJspContainerByJspEdgeUUID(_UUID).surface;

	if (jspSurface) {
		const jspConnection = jspSurface.getRenderedConnection(_UUID);
		const jspConnector = jspConnection.getConnector();
		jspConnector.importGeometry(_geometry, jspConnection);
	}
}

/**
 * Getting edge geometry by uuid
 * @param {UUID} _UUID UUID of jspEdge to get geometry of
 * @returns {object} geometry to get
 */
function getEdgeGeometry(_UUID) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	let geometry;
	const jspSurface = getJspContainerByJspEdgeUUID(_UUID).surface;

	if (jspSurface) {
		const jspConnection = jspSurface.getRenderedConnection(_UUID);
		if (jspConnection) {
			const jspConnector = jspConnection.getConnector();
			if (jspConnector) {
				geometry = jspConnector.exportGeometry();
			}
		}
	}
	return geometry;
}

/**
 * Getting edge edited prop by uuid
 * @param {string} _UUID of the edge to get geometry
 * @returns {boolean} edited prop
 */
function getEdgeEdited(_UUID) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	let edited;
	const jspSurface = getJspContainerByJspEdgeUUID(_UUID).surface;

	if (jspSurface) {
		const jspConnection = jspSurface.getRenderedConnection(_UUID);
		if (jspConnection) {
			const jspConnector = jspConnection.getConnector();
			if (jspConnector) {
				edited = jspConnector.edited;
			}
		}
	}
	return edited;
}

/**
 * Exit edit path mode for all edges for the canvas
 * @param {string} _containerId id of canvas container
 */
function stopEditContainerPaths(_containerId) {
	//REFACTOR Needs some serious rethinking and proper documentation!
	const instance = getJspContainerByUUID(_containerId).instance;
	if (instance) {
		const tmpEdges = instance.getAllEdges();
		if (tmpEdges.length > 0) {
			tmpEdges.forEach((tmpEdge) => {
				GLOBALEVENTMANAGER.dispatch("eCM_StopEditPath", tmpEdge.data.UUID);
			});
		}
	}
}

/**
 * Toggles canvas updates.
 * @param {UUID} _UUID of groupNode to toggle
 * @param {boolean} _suspend pause/resume rendering
 */
function eSuspendRendering(_UUID, _suspend) {
	const jspInstance = getJspContainerByUUID(_UUID).instance;

	if (_suspend) {
		jspInstance.setSuspendRendering(_suspend);
	} else {
		jspInstance.setSuspendRendering(_suspend, true);
	}
}

/**
 *
 * @param _connection
 */
function createJspEdgeTooltip(_connection) {
	const cName = _connection.cleanName;
	const cShielded = _connection.isShielded ? ` | ${getTranslation("ports.shielded")}` : "";
	const cComposition = _connection.composition;
	const cDynamicApplication = _connection.dynamicApplication;
	const cInterfaces = _connection.interfaces.map((_interface) => getTranslation(_interface.type.caption)).join(" | ");

	// connection TargetPort (which is connected to sourceDevicePort)
	const tmpTargetPort = _connection.targetPort;
	const tpName = getTranslation(tmpTargetPort.name);
	const tpGender = getTranslation(`ports.${tmpTargetPort.gender.toLowerCase()}`);
	const tpLayout = getTranslation(tmpTargetPort.geometry.layout.caption);

	// connection SourcePort (which is connected to targetDevicePort)
	const tmpSourcePort = _connection.sourcePort;
	const spName = getTranslation(tmpSourcePort.name);
	const spGender = getTranslation(`ports.${tmpSourcePort.gender.toLowerCase()}`);
	const spLayout = getTranslation(tmpSourcePort.geometry.layout.caption);

	let caption = `${cName} [${cComposition}]${cShielded} | ${cDynamicApplication} | ${getTranslation("units.length")}: ${_connection.length}mm\n`;
	caption += `${getTranslation("partListManager.interfaces")}: ${cInterfaces}\n`;
	caption += `${getTranslation("ports.port")} ${getTranslation("ports.target")}: ${tpName} (${tpGender}) | ${tpLayout}\n`;
	caption += `${getTranslation("ports.port")} ${getTranslation("ports.source")}: ${spName} (${spGender}) | ${spLayout}`;

	return caption;
}
