// these constants are expandable, the main usage of them is still in dialogCable.js, not .ts
export const endTypesNoProcessing = ["---"] as const;
export const endTypesLug = ["M3", "M4", "M5", "M6", "M8", "M10", "M12"] as const;
export const endTypesFerrule = ["6 mm", "8 mm"] as const;
export const endTypesStripping = ["10 mm", "15 mm", "20 mm", "25 mm"] as const;
export const endTypesWelding = ["5 mm", "6 mm", "7 mm", "8 mm"] as const;

type EndTypesNoProcessing = typeof endTypesNoProcessing[number];
type EndTypesLug = typeof endTypesLug[number];
type EndTypesFerrule = typeof endTypesFerrule[number];
type EndTypesStripping = typeof endTypesStripping[number];
type EndTypesWelding = typeof endTypesWelding[number];
type EndSize = EndTypesNoProcessing | EndTypesLug | EndTypesFerrule | EndTypesStripping | EndTypesWelding;

enum EndType {
	NO_PROCESSING = "NO_PROCESSING",
	FORK_LUG = "FORK_LUG",
	RING_LUG = "RING_LUG",
	WIRE_END_FERRULE = "WIRE_END_FERRULE",
	PARTIAL_STRIPPING = "PARTIAL_STRIPPING",
	RESISTANCE_WELDING = "RESISTANCE_WELDING",
}

export interface WireProcessingData {
	wireType: string; // Text in accordion header, atm MAIN1 or AUX2, // TODO make translation keys for main and aux
	distance: number;
	length: number;
	type: keyof typeof EndType;
	size: EndSize;
	text: string;
}

/**
 * Wire processing for one wire
 */
export class WireProcessing {
	private __wireType: string;
	private __distance: number;
	private __length: number;
	private __type: keyof typeof EndType;
	private __size: EndSize;
	private __text: string;

	/**
	 * Creates a new instance of WireProcessing
	 */
	constructor(_wireProcessingData: WireProcessingData) {
		this.__wireType = _wireProcessingData.wireType;
		this.__distance = _wireProcessingData.distance;
		this.__length = _wireProcessingData.length;
		this.__type = _wireProcessingData.type;
		this.__size = _wireProcessingData.size;
		this.__text = _wireProcessingData.text;

		Object.seal(this);
	}

	/**
	 * Sets this wireProcessing's wire type.
	 */
	set wireType(_wireType: string) {
		if (this.__wireType === _wireType) return;
		this.__wireType = _wireType;
	}

	/**
	 * Returns this wireProcessing's wire type.
	 */
	get wireType(): string {
		return this.__wireType;
	}

	/**
	 * Sets this wireProcessing's distance.
	 */
	set distance(_distance: number) {
		if (this.__distance === _distance) return;
		this.__distance = _distance;
	}

	/**
	 * Returns this wireProcessing's distance.
	 */
	get distance(): number {
		return this.__distance;
	}

	/**
	 * Sets this wireProcessing's length.
	 */
	set length(_length: number) {
		if (this.__length === _length) return;
		this.__length = _length;
	}

	/**
	 * Returns this wireProcessing's length.
	 */
	get length(): number {
		return this.__length;
	}

	/**
	 * Sets this wireProcessing's type.
	 */
	set type(_type: keyof typeof EndType) {
		if (this.__type === _type) return;
		this.__type = _type;
	}

	/**
	 * Returns this wireProcessing's type.
	 */
	get type(): keyof typeof EndType {
		return this.__type;
	}

	/**
	 * Sets this wireProcessing's size.
	 */
	set size(_size: EndSize) {
		if (this.__size === _size) return;
		this.__size = _size;
	}

	/**
	 * Returns this wireProcessing's size.
	 */
	get size(): EndSize {
		return this.__size;
	}

	/**
	 * Sets this wireProcessing's text.
	 */
	set text(_text: string) {
		if (this.__text === _text) return;
		this.__text = _text;
	}

	/**
	 * Returns this wireProcessing's text.
	 */
	get text(): string {
		return this.__text;
	}

	/**
	 * Returns the variable characteristics of a wire processing.
	 */
	public save(): WireProcessingData {
		return {
			wireType: this.wireType,
			distance: this.distance,
			length: this.length,
			type: this.type,
			size: this.size,
			text: this.text,
		};
	}
}
