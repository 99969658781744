import {GLOBALEVENTMANAGER} from												"./applicationManager";
import {cId2jqSel} from																	"./helper";
import chevronLeft from																	"../images/sidePanelWidgetIcons/chevronLeft.svg";
import chevronRight from																"../images/sidePanelWidgetIcons/chevronRight.svg";
// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;

/* Provides panel buttons
 */


/** standard initialization for panel buttons
 * mouse events for semi-hidden panels and their control buttons
 */
export function initializePanelButtons() {
	GLOBALEVENTMANAGER.addHandler("eGUI_ClickSidePanelButton", eClickSidePanelButton);
	GLOBALEVENTMANAGER.addHandler("eGUI_MouseEnterPanel", eMouseEnterPanel);
	GLOBALEVENTMANAGER.addHandler("eGUI_MouseLeavePanel", eMouseLeavePanel);
}

/** fixed shows/hides panel on click the corresponding button
 * @param {string} _side "left" or "right" for the corresponding panel
 * @param {object} _button DOM element
 * @param {object} _panel DOM element
*/
function eClickSidePanelButton(_side, _button, _panel) {
	const allCanvas = $(".canvas-assemblyNode, .canvas-projectNode, .canvas-infrastructureNode, .canvas-trashNode, #rootCanvas");
	const canvasWidth = getCanvasWidth(_side);											// canvas width after the panel button click
	_button.toggleClass("opened");
	switch (_side) {
		case "left":
			if (_button.hasClass("opened")) {											// left panel was hidden and must be opened
				_button.attr("src", chevronLeft);
				_panel.animate({left: 0}, "slow");
				allCanvas.animate({left: _panel.width(), width: canvasWidth}, "slow");
			} else {																		// left panel was opened and must be hidden
				_button.attr("src", chevronRight);
				_panel.animate({left: -Math.abs(_panel.width()-20)}, "slow");
				allCanvas.animate({left: 20, width: canvasWidth}, "slow");
			}
			break;
		case "right":
			if (_button.hasClass("opened")) {											// right panel was hidden and must be opened
				_button.attr("src", chevronRight);
				_panel.animate({right: 0}, "slow");
				allCanvas.animate({width: canvasWidth}, "slow");
			} else {																		// right panel was opened and must be hidden
				_button.attr("src", chevronLeft);
				_panel.animate({right: -Math.abs(_panel.width()-20)}, "slow");
				allCanvas.animate({width: canvasWidth}, "slow");
			}
			break;
		default:
			throw new Error("The panel side is not defined!");
	}
}

/** temporary shows the panel on mouse enter
 * @param {object} _target panel DOM element
 * @param {string} _side "left" or "right" for the corresponding panel
 * @param {object} _button DOM element
 * @param {object} _panel DOM element
*/
function eMouseEnterPanel(_target, _side, _button, _panel) {
	if (!_button.hasClass("opened") && $(_target).attr("id")!==_button.attr("id")) {	// if the panel is semi-hidden (exclude the button)
		_side == "left" ? _panel.animate({left: -2}, "slow") : _panel.animate({right: -2}, "slow");
	}
}

/** hides the panel back on mouse leave
 * @param {object} _target panel DOM element
 * @param {string} _side "left" or "right" for the corresponding panel
 * @param {object} _button DOM element
 * @param {object} _panel DOM element
*/
function eMouseLeavePanel(_target, _side, _button, _panel) {
	if (!_button.hasClass("opened") && $(_target).attr("id")!==_button.attr("id")) {	// if the panel is semi-hidden ??(exclude the button)
		_side == "left" ? _panel.animate({left: -Math.abs(_panel.width()-20)}, "slow") : _panel.animate({right: -Math.abs(_panel.width()-20)}, "slow");
	}
}

/** calculates canvas width after panel button click or window resize
 * @param {string} _side left or right for the corresponding panel button
 * @returns {number} res - canvas width
*/
function getCanvasWidth(_side) {
	let widthLeftPanel;
	let widthRightPanel;
	const leftPanel = cId2jqSel("sidePanelLeft");
	const rightPanel = cId2jqSel("sidePanelRight");
	const isLeftPanelHidden = !cId2jqSel("sidePanelLeftButton").hasClass("opened");		// left panel is semi-hidden
	const isRightPanelHidden = !cId2jqSel("sidePanelRightButton").hasClass("opened");		// right panel is semi-hidden
	switch (_side) {
		case "left":																	// left panel button clicked
			widthLeftPanel = isLeftPanelHidden ? leftPanel.width() : 20;				// future width
			widthRightPanel = isRightPanelHidden ? 20 : rightPanel.width();				// current width
			break;
		case "right":																	// right panel button clicked
			widthLeftPanel = isLeftPanelHidden ? 20 : leftPanel.width();				// current width
			widthRightPanel = isRightPanelHidden ? rightPanel.width() : 20; 			// future width
			break;
		default:																		// no panel button clicked => window resized
			widthLeftPanel = isLeftPanelHidden ? 20 : leftPanel.width();				// current width
			widthRightPanel = isRightPanelHidden ? 20 : rightPanel.width();				// current width
	}
	const res = cId2jqSel("canvasContainer").width() - widthLeftPanel - widthRightPanel;
	return res;
}

/** fits canvas width on window resize
 * no solution with css only found
*/
// Webpack fix:  $(window).on ---> window.onresize =
window.onresize = function() {
	const allCanvas = $(".canvas-assemblyNode, .canvas-projectNode, .canvas-infrastructureNode, .canvas-trashNode, #rootCanvas");
	const leftPanel = cId2jqSel("sidePanelLeft");
	const rightPanel = cId2jqSel("sidePanelRight");
	const canvasWidth = getCanvasWidth(0);
	if (!cId2jqSel("sidePanelLeftButton").hasClass("opened")) {							// left panel is semi-hidden
		leftPanel.css("left", -Math.abs(leftPanel.width()-20));
		allCanvas.css("left", 20);
	} else {
		leftPanel.css("left", 0);
		allCanvas.css("left", leftPanel.width());
	}
	if (!cId2jqSel("sidePanelRightButton").hasClass("opened")) {						// right panel is semi-hidden
		rightPanel.css("right", -Math.abs(rightPanel.width()-20));
	} else {
		rightPanel.css("right", 0);
	}
	allCanvas.width(canvasWidth);
};
