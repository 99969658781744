import {USER} from														"./applicationManager";
import {BACKEND} from													"./applicationManager";
import {splashTypeEnum} from									"./constantsAndEnumerations";
import {loadProject} from											"./dataManager";
import {EventManager} from										"./eventManager";
import {compareObjectProperty} from						"./helper";
import {searchProjectIdInProjectList} from				"./helper";
import {getTranslation} from									"./localization/localizationManager";
import {Modal} from														"./modal";

import {marked} from														"marked";
import "simplebar";


/** Central structure for handling splashScreens
 *
 * INFO
 *		to submit a splash-subject simply remove the leading underscore from the markdown files in the splash folder
 *
 * UTILIZING:
 *		SimpleBar as a replacement for the standard scrollbar, details under https://www.npmjs.com/package/simplebar
 *		marked.js for parsing markdown, details under https://www.npmjs.com/package/marked
 *
 * BUGS:
 * 		content-wrapper height is clumsily inherited from the parent window -> that is awful! Using a percentage or flex grow (which should be preferred) results in different window heights for different active content-tabs depending on the vertical size of the markdown content
 *
 * TODO
 *		getContent() is way to tightly coupled to communicationManager, USER etc. -> consider delegating that to a constructor argument (best would be a complex object containing all the necessary data)
 *
 * AUTHOR(S):
 *		Christian Lange
 */


/** Class representing a splashScreen
 * @class SplashScreen
 */
export class SplashScreen {
	/** Creates an instance of SplashScreen
	 * @param {boolean} [_forceSplash=false]  optional parameter to force the splashscreen despite of user preferences [defaults to false]
	 * @memberof SplashScreen
	 */
	constructor(_forceSplash = false) {
		this.splashSubjects = [																										// Helper object to buffer splash content from database
			{type: "ANNOUNCEMENT",		content: null,	i18nKey: "modalDialog.splashScreen.announcement",			updateSetting: null},
			{type: "GREETER",					content: null,	i18nKey: "modalDialog.splashScreen.greeter",					updateSetting: null},
			{type: "CHANGELOG",				content: null,	i18nKey: "modalDialog.splashScreen.changelog",				updateSetting: null},
			{type: "RECENTPROJECTS",	content: null,	i18nKey: "modalDialog.splashScreen.recentProjects",		updateSetting: null},
		];
		this.projectList = null; //! very ugly helper
		this.getContent(_forceSplash).then(() => {	// ugly, I know...
			if (this.splashSubjects.filter((tmpSubject) => {return tmpSubject.content !== null;}).length === 0) return;		// check if there is something to show at all, otherwise abort dialog creation (may be overwritten by _forceSplash)

			this.modal = {};																													// modal background of this splashScreen
			this.dialog = {};																													// the actual splashScreen dialog window
			this.headingWrapper = {};																									// the heading-container on top of the splashScreen
			this.contentWrapper = {};																									// the content-container of the splashScreen
			this.footerWrapper = {};																									// the footer-container on top of the splashScreen

			this.tabs = [];																														// stores tabs of all subjects (tabObject = {subject: "NAME", header: ..., content..., footer...})
			this.activeTab = null;
			this.selectedProject = null;																							// buffer for project to load (on splashscreen recentProject selection)

			this.localEventManager = new EventManager();

			this.createDomStructure();
			this.populateSplashScreen();
			this.setActiveTab(this.tabs[0]);																					// set first tab active
		},
		);
	}


	/** Retrieves splash content from server
	 * @param {Boolean} _forceSplash force splashScreen despite of user settings
	 * @memberof SplashScreen
	 */
	async getContent(_forceSplash) {
		//! Xou should add requestRecentProject to this promise.all
	/** Asynchronously fetches splashscreen items
	 * @returns {Promise} collection of splashscreen items
	 * @memberof SplashScreen
	 */
		async function _fetchContent() {
			const [announcementResponse, greeterResponse, changelogResponse, tmpUnsortedRecentProjects] = await Promise.all([
				BACKEND.getSplashItem(splashTypeEnum.ANNOUNCEMENT, USER.language),
				BACKEND.getSplashItem(splashTypeEnum.GREETER, USER.language),
				BACKEND.getSplashItem(splashTypeEnum.CHANGELOG, USER.language),
				(USER.isRegistered) ? BACKEND.getProjectsInfo(USER.id) : null,
			]);

			console.log()

			const tmpContent = {
				tmpAnnouncement: await announcementResponse,
				tmpGreeter: await greeterResponse,
				tmpChangeLog: await changelogResponse,
				tmpUnsortedRecentProjects: await tmpUnsortedRecentProjects,
			};
			return tmpContent;
		}

		await _fetchContent().then((tmpContent) => {
			const tmpProjectList = [];
			// ANNOUNCEMENT
			if (tmpContent.tmpAnnouncement) this.splashSubjects.filter((tmpSubject) => {return tmpSubject.type === "ANNOUNCEMENT";})[0].content = marked(tmpContent.tmpAnnouncement);
			// GREETER
			if (USER.showGreeter || _forceSplash) this.splashSubjects.filter((tmpSubject) => {return tmpSubject.type === "GREETER";})[0].content = marked(tmpContent.tmpGreeter);
			// CHANGELOG
			if (USER.showChangelog && tmpContent.tmpChangeLog) this.splashSubjects.filter((tmpSubject) => {return tmpSubject.type === "CHANGELOG";})[0].content = marked(tmpContent.tmpChangeLog);
			// RECENTPROJECTS
			if (USER.showRecentProjects) {
				const tmpUnsortedRecentProjects = tmpContent.tmpUnsortedRecentProjects;						// get user projects from Server
				let tmpRecentProjects = [];
				if (tmpUnsortedRecentProjects.length > 0) {
					tmpUnsortedRecentProjects.sort(compareObjectProperty("modified", true));		// sort user projects descendingly by modified timestamp
					tmpUnsortedRecentProjects.forEach((project) => {
						tmpRecentProjects.push(project.name);
						tmpProjectList.push({name: project.name, id: project.id});
					});

					if (tmpRecentProjects.length >= USER.recentProjectsCount) tmpRecentProjects = tmpRecentProjects.slice(tmpRecentProjects.length - USER.recentProjectsCount, tmpRecentProjects.length); // tmpRecentProjects is ordered descendingly! So get the last (=USER.recentProjectsCount) elements
				};
				this.projectList = tmpProjectList;
				this.splashSubjects.filter((tmpSubject) => {return tmpSubject.type === "RECENTPROJECTS";})[0].content = tmpRecentProjects;
			}
		});
	}

	/** Creates the necessary dom elements for this splashScreen
	 * @memberof SplashScreen
	 */
	createDomStructure() {
		this.modal = new Modal();

		this.dialog = document.createElement("div");
		this.dialog.classList.add("splashScreen-window", "noselect");
		this.modal.domContainer.appendChild(this.dialog);

		this.headingWrapper = document.createElement("div");
		this.headingWrapper.classList.add("splashScreen-heading-wrapper");
		this.dialog.appendChild(this.headingWrapper);

		this.contentWrapper = document.createElement("div");
		this.contentWrapper.classList.add("splashScreen-content-wrapper");
		this.contentWrapper.setAttribute("data-simplebar", "");
		this.dialog.appendChild(this.contentWrapper);

		this.footerWrapper = document.createElement("div");
		this.footerWrapper.classList.add("splashScreen-footer-wrapper");
		this.dialog.appendChild(this.footerWrapper);
	}

	/** Fills this splashScreen with content (if any was fetched via getContent)
	 * @memberof SplashScreen
	 */
	populateSplashScreen() {
		this.splashSubjects.forEach((subject) => {
			if (!subject.content) return;	// skip empty subjects
			const tmpSubject = splashScreenItemFactory(subject, this.localEventManager);

			this.localEventManager.addHandler(tmpSubject.activateEvent, () => this.setActiveTab(tmpSubject));
			this.localEventManager.addHandler(tmpSubject.closeEvent, () => this.closeTab(tmpSubject));
			if (tmpSubject.projectLoadEvent) this.localEventManager.addHandler(tmpSubject.projectLoadEvent, (project) => this.selectedProject = project);

			this.tabs.push(tmpSubject);
		});

		this.tabs.forEach((tab) => {
			this.headingWrapper.appendChild(tab.header);
			this.contentWrapper.appendChild(tab.content);
			this.footerWrapper.appendChild(tab.footer);
		});
	}

	/** Sets the active tab of this splashScreen
	 * @param {SplashScreenItem} _tab to activate
	 * @memberof SplashScreen
	 */
	setActiveTab(_tab) {
		if (_tab == this.activeTab) return;																				// skip if _tab is already active
		if (this.activeTab) this.activeTab.setActive(false);											// skip if this.activeTab is empty (only during initialization)
		this.activeTab = _tab;
		this.activeTab.setActive(true);
	}

	/** Removes the provided tab from this splashScreen
	 * @param {SplashScreenItem} _tab to remove (is always an activeTab since otherwise you couldn't reach the ok-button)
	 * @memberof SplashScreen
	 */
	closeTab(_tab) {
		this.tabs.splice(this.tabs.indexOf(_tab), 1);															// remove _tab from tabs[]
		_tab.close();

		if (this.tabs.length > 0) {																								// check if any tabs remain and activate first remaining tab or...
			this.setActiveTab(this.tabs[0]);
		} else {
			this.close();																														//  quit the splashScreen
		}
	}

	/** Quits this splashScreen
	 * @memberof SplashScreen
	 */
	async close() {
		this.modal.remove();
		this.updateUserSettings();
		if (this.selectedProject) {
			const tmpProjectId = searchProjectIdInProjectList(this.projectList, this.selectedProject);
			await loadProject(USER.id, tmpProjectId);
		}
	}

	/** Update userSettings
	 * @memberof SplashScreen
	 */
	updateUserSettings() {
		const tmpSuppressGreeter = this.splashSubjects.filter((subject) => {return subject.type === "GREETER";})[0].updateSetting;
		const tmpSuppressChangelog = this.splashSubjects.filter((subject) => {return subject.type === "CHANGELOG";})[0].updateSetting;
		const tmpSuppressRecentProjects = this.splashSubjects.filter((subject) => {return subject.type === "RECENTPROJECTS";})[0].updateSetting;

		if (tmpSuppressGreeter) USER.showGreeter = !tmpSuppressGreeter;
		if (tmpSuppressChangelog) USER.showChangelog = !tmpSuppressChangelog;
		if (tmpSuppressRecentProjects) USER.showRecentProjects = !tmpSuppressRecentProjects;
	}
}


/** Creates a new SplashScreenItem depending on the provided subject
 * @param {splashSubjects} _subject to create SplashScreenItem for
 * @returns {SplashScreenItem} matching the provided subject
 * @param {EventManager} _localEventManager of SplashScreen
 * @throws Error if no matching SplashScreenItem for the provided subject was found
 */
function splashScreenItemFactory(_subject, _localEventManager) {
	switch (_subject.type) {
		case "ANNOUNCEMENT":
			return new SplashScreenItemAnnouncement(_subject, _localEventManager);
		case "GREETER":
			return new SplashScreenItem(_subject, _localEventManager);
		case "CHANGELOG":
			return new SplashScreenItem(_subject, _localEventManager);
		case "RECENTPROJECTS":
			return new SplashScreenItemRecentProjects(_subject, _localEventManager);
		default:
			throw new Error(`Subject ${_subject} not recognized!`);
	}
}


/** Class representing splashScreen content
 * @class SplashScreenItem
 */
class SplashScreenItem {
	/** Creates an instance of SplashScreenItem.
	 * @param {splashSubjects} _subject to create SplashScreenItem for
	 * @param {EventManager} _parentEventManager local eventManager of SplashScreen
	 * @memberof SplashScreenItem
	 */
	constructor(_subject, _parentEventManager) {
		this.type = _subject.type;
		this.parentEventManager = _parentEventManager;
		this.activateEvent = `${this.type}_activate`;
		this.closeEvent = `${this.type}_close`;
		this.header = {};
		this.content = {};
		this.footer = {};
		this.checkbox = {};
		this.checkboxCaption = {};
		this.button = {};
		this.isActive = false;
		this.createDomStructure(_subject);
		this.content.innerHTML = _subject.content;
	}

	/** Creates the necessary dom elements for this splashScreenItem
	 * @memberof SplashScreenItem
	 * @param {splashSubjects} _subject containing necessary details
	 */
	createDomStructure(_subject) {
		this.header = document.createElement("div");															// heading dom element
		this.header.textContent = getTranslation(_subject.i18nKey);
		this.header.classList.add("splashScreen-heading-tab");
		this.header.addEventListener("click", () => this.parentEventManager.dispatch(this.activateEvent));

		this.content = document.createElement("div");															// content dom element
		this.content.classList.add("splashScreen-content-tab");

		this.footer = document.createElement("div");															// footer dom element
		this.footer.classList.add("splashScreen-footer-tab");

		this.checkbox = document.createElement("input");
		this.checkbox.type = "checkbox";
		this.checkbox.classList.add("splashScreen-checkbox");
		this.checkbox.addEventListener("change", () => _subject.updateSetting = this.checkbox.checked);
		this.footer.appendChild(this.checkbox);

		this.checkboxCaption = document.createElement("span");
		this.checkboxCaption.textContent = getTranslation("modalDialog.splashScreen.checkbox-noShow");
		this.checkboxCaption.classList.add("splashScreen-checkbox-caption");
		this.footer.appendChild(this.checkboxCaption);

		this.button = document.createElement("BUTTON");
		this.button.textContent = getTranslation("modalDialog.button.ok");
		this.button.classList.add("splashScreen-button");
		this.button.addEventListener("click", () => this.parentEventManager.dispatch(this.closeEvent));
		this.footer.appendChild(this.button);
	}

	/** Switches this SplashScreenItems active mode
	 * @param {Boolean} _active mode to set
	 * @memberof SplashScreenItem
	 */
	setActive(_active) {
		if (this.isActive == _active) return;		// skip if item is already _active
		if (_active) {
			this.header.classList.add("active");
			this.content.classList.add("active");
			this.footer.classList.add("active");
		} else {
			this.header.classList.remove("active");
			this.content.classList.remove("active");
			this.footer.classList.remove("active");
		}
		this.isActive = _active;
	}

	/** Removes this splashScreenItem from DOM
	 * Always triggered by parent!
	 * @memberof SplashScreenItem
	 */
	close() {
		this.header.remove();
		this.content.remove();
		this.footer.remove();
	}
}


/** Class representing splashScreen announcement
 * @class SplashScreenItem
 */
class SplashScreenItemAnnouncement extends SplashScreenItem {
	/** Creates an instance of SplashScreenItem.
	 * @param {splashSubjects} _subject containing necessary details
	 * @param {EventManager} _parentEventManager local eventManager of SplashScreen
	 * @memberof SplashScreenItemAnnouncement
	 */
	constructor(_subject, _parentEventManager) {
		super(_subject, _parentEventManager);
		// hide checkbox & caption since announcements don't offer a checkbox to be suppressed in the future
		this.checkbox.classList.add("turnInvisible");
		this.checkboxCaption.classList.add("turnInvisible");
	}
}


/** Class representing splashScreen recentProjectList
 * RecentProjectList has a different dom layout for the content tab
 * @class SplashScreenItem
 */
class SplashScreenItemRecentProjects extends SplashScreenItem {
	/** Creates an instance of SplashScreenItem.
	 * @param {splashSubjects} _subject containing necessary details
	 * @param {EventManager} _parentEventManager local eventManager of SplashScreen
	 * @memberof SplashScreenItemRecentProjects
	 */
	constructor(_subject, _parentEventManager) {
		super(_subject, _parentEventManager);
		this.selectedProjectSpan = null;
		this.projectLoadEvent = `${this.type}_projectLoad`;
		this.extendDomStructure(_subject);
	}

	/** Adds the necessary dom elements for this splashScreenItem
	 * @param {splashSubjects} _subject containing necessary details
	 * @memberof SplashScreenItemRecentProjects
	 */
	extendDomStructure(_subject) {
		this.content.innerHTML = "";																							// remove default content that was wrongly set by base class (ugly I know... We should use a neutral ItemBaseClass)
		this.content.classList.add("recentProjectList");
		if (_subject.content.length > 0) {
			_subject.content.forEach((projectName) => {
				const tmpProjectSpan = document.createElement("span");									// create span for each projectName
				tmpProjectSpan.classList.add("recentProjectList-item");
				tmpProjectSpan.textContent = projectName;
				tmpProjectSpan.addEventListener("click", () => {
					tmpProjectSpan.classList.toggle("selected");
					if (this.selectedProjectSpan == tmpProjectSpan) {
						this.selectedProjectSpan = null;
						this.parentEventManager.dispatch(this.projectLoadEvent, null);
					} else {
						if (this.selectedProjectSpan) this.selectedProjectSpan.classList.remove("selected");
						this.selectedProjectSpan = tmpProjectSpan;
						this.parentEventManager.dispatch(this.projectLoadEvent, projectName);
					}
				});
				this.content.appendChild(tmpProjectSpan);
			});
		} else {
			const tmpProjectNotFoundSpan = document.createElement("span");									// create a single span for message
			tmpProjectNotFoundSpan.classList.add("recentProjectList-NoProjectWarning");
			tmpProjectNotFoundSpan.textContent = getTranslation("modalDialog.splashScreen.noProjectsWarning");
			this.content.appendChild(tmpProjectNotFoundSpan);
		}
	}
}
