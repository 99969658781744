import {GLOBALEVENTMANAGER} from													"./applicationManager";
import {technicalUnitEnum} from														"./constantsAndEnumerations";
import {CustomTextControl} from														"./customControl";
import {CustomHeadLineControl} from												"./customControl";
import {customControlValidationIndicatorEnum} from				"./customControl";
import {CustomBaseControl} from														"./customControl";
import {getUniqueDataNodeByType} from											"./dataManager";
import {getTranslation} from															"./localization/localizationManager";
import {cId2jqSel} from																		"./helper";
import {checkUniqueness} from															"./helper";
import {checkExistenceNew} from														"./helper";
import {stringCompare2dArray} from												"./helper";
import {dateCompare2dArray} from													"./helper";
import {numberCompare2dArray} from												"./helper";
import {booleanCompare2dArray} from												"./helper";
import {searchArrayForElementByKeyValuePair} from					"./helper";
import {schemaReferenceDesignator} from										"../resources/validationSchemata";
import {parseReferenceDesignatorString} from							"./referenceDesignatorManager";
import {checkReferenceDesignatorStringUniqueness} from		"./referenceDesignatorManager";

import cloneDeep from																			"lodash.clonedeep";
import isEqual from																				"lodash.isequal";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;
// Webpack imports (work even if shown not used)
import scrollbar from "jquery.scrollbar";
import floatThead from "floatthead/dist/jquery.floatThead.min";
import Joi from "joi-browser";	// TODO replace by below
// import Joi from "joi";


/* Central structure for the creation and manipulation of customControl objects
 *
 * UTILIZING:
 *		lodash for deep cloning arguments passed to the constructor and debouncing function calls, details under https://lodash.com/
 *		jQuery Scrollbar as a replacement for the standard scrollbar, details under https://gromo.github.io/jquery.scrollbar/
 *
 * INFO:
 *		Summary of common html5 input elements: https://www.w3schools.com/html/html_form_input_types.asp
 *
 * TODO:
 *		BUG, CustomTableControl reacts on window resize event even when its dialog is not active ???
 *
 * AUTHOR(S):
 *		Christian Lange
 *		Stanislav Biryukov
 */

/** Defining a table element */
export class CustomTableControl {
	/** Standard constructor
	 * @param  {String} _id of table that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {Array} _tableData 2D array of table data; first line is interpreted datatype, second line as heading
	 * @param  {Number} _boundDataColumn Zero based Index of table column to bind click event to (specifies which dataCell in a dataRow is send as click payload), defaults to 0
	 */
	constructor(_id, _containerId, _tableData, _boundDataColumn = 0) {
		this.validated = true;											// tables are always validated
		this.focusable = false;

		this.id = checkUniqueness(_id);
		this.containerId = checkExistenceNew(_containerId);
		this.tableBody = `${this.id}_tbody`;							// DOM data area of this table

		this.event = `${this.id}_click`;
		this.formatData = this.extractFormat(_tableData);
		this.headingData = this.extractHeadings(_tableData);
		this.tableData = this.extractData(_tableData);
		this.boundDataColumn = this.extractBoundDataColumn(_boundDataColumn, _tableData);

		this.createDefaultDomStructure();
		this.createTableHeading(this.headingData);
		this.createTableContent(this.tableData);

		$(`#${this.id}_table`).floatThead({								// set fixed heading
			scrollContainer: () => {
				return $(`#${this.id}_table`).closest(".cControl-table-wrap");
			},
		});

		$(`#${this.id}`).scrollbar();									// set (and correct) customScrollbar
		this.correctScrollbar();
	}

	/** Builds a default table DOM structure */
	createDefaultDomStructure() {
		$("<div>", {													// div table wrap
			id: this.id,
			class: "cControl-Container cControl-table-wrap customScrollbarContainer customScrollbarContainer-table",
		}).appendTo(cId2jqSel(this.containerId));

		$("<table>", {													// creating the table container
			id: `${this.id}_table`,
			class: "cControl-table noselect",
		}).appendTo(cId2jqSel(this.id));


		$("<thead>", {													// creating the table head
			id: `${this.id}_thead`,
			class: "cControl-table-head",
		}).appendTo(cId2jqSel(`${this.id}_table`));


		$("<tbody>", {													// creating the table body
			id: this.tableBody,
			class: "cControl-table-body",
		}).appendTo(cId2jqSel(`${this.id}_table`));
	}

	/** (Very) simple check if provided _tableData is conform to the requirements
	 * @param  {Object} _tableData to check
	 * @throws Exception if something went wrong
	 */
	checkTableData(_tableData) {
		if (_tableData.length < 3) throw new Error(`Table "${this.id}" _tableData invalid! Format is [[dataTypes], [headings], [data]]`);
	}

	/** Extract types from provided tableData (used to determine correct sorting algorithm)
	 * @param  {Object} _tableData to extract types from
	 * @returns {Array} of types
	 */
	extractFormat(_tableData) {
		const tmpFormatData = [];
		const tmpInput = this.makeArray(_tableData.shift());

		tmpInput.forEach((type) => {
			tmpFormatData.push(this.getCompareFunction(type));
		});
		return tmpFormatData;
	}

	/** Extract headlines from provided tableData
	 * @param  {Object} _tableData to extract headings from
	 * @returns {Array} of headings
	 */
	extractHeadings(_tableData) {
		const tmpHeadings = this.makeArray(_tableData.shift());
		return tmpHeadings;
	}

	/** Convert format of incoming tableData
	 * @param  {Object} _tableData provided as table argument
	 * @returns {Object} converted _tableData
	 */
	extractData(_tableData) {
		const tmpData = [];
		_tableData.forEach((dataRow) => {
			dataRow = this.makeArray(dataRow);
			tmpData.push(dataRow);
		});
		return tmpData;
	}
	/** Checks if _boundDataColumn is within table boundaries
	 * @param  {Number} _boundDataColumn of column to bind to click event payload
	 * @param  {Object} _tableData provided as table argument
	 * @returns {Number} index of _boundDataColumn
	 */
	extractBoundDataColumn(_boundDataColumn, _tableData) {
		if (_tableData.length == 0) return;											// don't handle empty tables (pE empty partsList)

		if (_boundDataColumn > _tableData[0].length) {
			throw new Error(`Index "${_boundDataColumn}" of boundDataColumn is not within tableColumnsLength "${_tableData[0].length}"`);
		} else {
			return _boundDataColumn;
		}
	}

	/** Creates the table heading
	 * @param  {Array} _headingData to construct headings from
	 */
	createTableHeading(_headingData) {
		const tmpHeadings = _headingData;
		$("<tr>", {																// create a Heading container
			id: `${this.id}_heading-row`,
			// class: "cControl-table-head-row",
		}).appendTo(cId2jqSel(`${this.id}_thead`));

		tmpHeadings.forEach((heading) => {										// parse tmpHeadings and create a column for each element
			$("<th>", {
				id: `${this.id}_heading-cell${tmpHeadings.indexOf(heading)}`,
				title: getTranslation("modalDialog.sortColumn"),
				text: heading,
				click: () => this.sortTable(this.headingData.indexOf(heading)),
				class: "cControl-table-head-cell",
			}).appendTo(cId2jqSel( `${this.id}_heading-row`));
		});
	}

	/** Creates the table data area
	 * @param  {Array} _tableData 2D array of table data to construct table data area from
	 */
	createTableContent(_tableData) {
		const tmpData = _tableData;

		tmpData.forEach((dataRow) => {											// parse tmpData and create a dataRow for each element
			this.addDataRow(dataRow);
		});
	}

	/** Update the table data area
	 * @param  {Array} _tableData 2D array of table data to construct table data area from
	 */
	updateTableContent(_tableData) {
		cId2jqSel(this.tableBody).empty();
		this.createTableContent(_tableData);
	}

	/** Adds a dataRow to the table DOM
	 * @param  {Object} _dataRow to add
	 */
	addDataRow(_dataRow) {
		const tmpRowIndex = _dataRow[0];
		const tmpRowId = `${this.id}_data-row_${tmpRowIndex}`;

		$("<tr>", {
			id: tmpRowId,
			class: "cControl-table-data-row",
		}).appendTo(cId2jqSel( `${this.id}_tbody`));

		_dataRow.forEach((dataCell) => {										// parse _dataRow and fill each cell with the appropriate data
			const tmpId = `${this.id}_data-cell_${tmpRowIndex}_${_dataRow.indexOf(dataCell)}`;
			$("<td>", {
				id: tmpId,
				text: dataCell,
				click: () => GLOBALEVENTMANAGER.dispatch(this.event, _dataRow[this.boundDataColumn]),
				class: "cControl-table-data-cell",
			}).appendTo($(`[id="${tmpRowId}"]`));
		});
	}

	/** Toggles a dataRows css class (pE used to mark a dataRow as ready for deletion)
	 * @param  {Object} _dataRow to manipulate
	 * @param  {cssClass} _cssClass to set/unset
	 */
	toggleDataRowMarking(_dataRow, _cssClass) {
		_dataRow.classList.toggle(_cssClass);
	}

	/** Removes a dataRow from the table and updates the DOM
	 * @param  {String} _index of dataRow to remove
	 */
	removeDataRow(_index) {
		if (_index >= this.tableData.length) throw new Error(`Table "${this.id}" removeDataRow invalid for index="${_index}"`);
		this.tableData.splice(_index, 1);
		this.updateTableContent(this.tableData);
	}

	/** Removes all dataRows from the table and updates the DOM */
	removeAllDataRows() {
		this.tableData = [];
		this.updateTableContent(this.tableData);
	}


	/** Sorts the table for the provided column index
	 * @param  {Number} _columnIndex to sort for
	 */
	sortTable(_columnIndex) {
		// Basic idea and problems:
		// - don't directly manipulate this.tableData, work on a locale copy called tableDataCopy and check if anything has changed at all!
		// - prevent that clicking on a column returns a wrong / no result -> is the column already correctly ordered? -> change order direction -> did that change anything -> no? abort!

		if (this.tableData.length <= 1) return;															// abort, you can't sort a single line of tableData

		const tmpComparator = this.formatData[_columnIndex];											// get the correct comparator for convenience
		const tableDataCopy = cloneDeep(this.tableData).sort(tmpComparator(_columnIndex, true));		// make a copy and sort it ascendingly

		// check for changes
		if (isEqual(this.tableData, tableDataCopy)) {												// sorting ascendingly did nothing
			tableDataCopy.sort(tmpComparator(_columnIndex, false));									// changing direction
			if (isEqual(this.tableData, tableDataCopy)) {											// sorting descendingly did nothing
				return;																				// abort, sorting for this column is not viable
			}
		}

		// something changed
		this.tableData = tableDataCopy;																// write copy back to tableData
		this.updateTableContent(this.tableData);
	}


	/** Returns a compare function (used by sort algorithm)
	 * @param  {String} _dataType to retrieve a fitting compare function for
	 * @returns {Function} compare function matching _dataType
	 * @throws Exception if no matching compare function was found for _dataType
	 */
	getCompareFunction(_dataType) {
		switch (_dataType) {
			case "string":
				return stringCompare2dArray;
			case "number":
				return numberCompare2dArray;
			case "boolean":
				return booleanCompare2dArray;
			case "date":
				return dateCompare2dArray;
			default:
				throw new Error(`Table "${this.id}" no compare function found for dataType "${_dataType}"`);
		}
	}


	/** Small helper to ensure that a tableRow is an array (which it is not if _tableData is a 1D array)
	 * @param  {Object} _tableRow to check
	 * @returns {Array} converted _dataRow
	 */
	makeArray(_tableRow) {
		if (Array.isArray(_tableRow)) {
			return _tableRow;
		} else {
			return [_tableRow];
		}
	}

	/** Returns the name of the event this elements uses to submit data
	 * @returns {String} name of the event
	 */
	getEvent() {
		return (this.event);
	}

	/** run this elements callback function */
	executeCallBack() {
		if (this.callBack == null) return; // abort if no callBack was provided
		console.debug(`CallBack CustomTableControl ${this.label}`);
	}

	/** Correct the length of customScrollbar on tables that have a floatThead
	 * floatThead fixes the heading row to the top of the table - jquery.scrollbar doesn't account for that "lost" row and needs to be corrected (position and length) - correcting the position by pushing scrollbar down for one row is handled via css
	 */
	correctScrollbar() {
		const tmpScrollElement = document.querySelector(`#${this.id}`).parentNode.querySelector(".scroll-y.scroll-scrolly_visible");		// retrieve scrollbar container
		if (tmpScrollElement === null) return;																								// abort if no scrollbar is shown/necessary

		const tmpScrollBar = tmpScrollElement.querySelector(".scroll-bar");																	// retrieve scrollbar
		const tmpHeight = parseFloat(tmpScrollBar.style.height);																			// retrieve uncorrected length of scrollbar
		const tmpFontSize = parseFloat(window.getComputedStyle(document.querySelector(`#${this.id}`)).fontSize) * 1.1;						// calculate table row height from fontSize (rough but working fine)

		tmpScrollBar.style.height = String(tmpHeight - tmpFontSize - 2) + "px";																// set corrected length of scrollbar (-2 is an "arbitrary" correction factor)
	}

	/** Sets focus to this control
	 * TODO: does not inherit from baseClass atm
	 * @override focus() of CustomControlBase
	 * @memberof CustomDataNodeHeader
	 */
	focus() {}
}

/** Defining the text variant of the customControl base template */
export class CustomTextControlNoUnit extends CustomTextControl {
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Function} _callBack to hook this elements value to
	 * @param  {Number} _width of control
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _callBack, _width) {
		super(_id, _containerId, _label, _unit, _schema, _callBack);
		this.correctUnit(_width);
	}

	/** Do not use unit format
	 * @param  {String} _unit - others things than untit
	 * @returns {String} _unit
	 */
	formatUnit(_unit) {	// do nothing
		return _unit;
	}

	/** Sets control's width
	 * @param  {number} _width to set
	 */
	correctUnit(_width) {
		cId2jqSel(`${this.id}_cUnit`).removeClass("cControl-Unit");
		cId2jqSel(`${this.id}_cUnit`).addClass("cControl-Comment");
		cId2jqSel(`${this.id}_cInput`).css({"width": _width});
	}

	/** Sets control's width
	 * @param  {String} _value to set
	 */
	setUnit(_value) {
		cId2jqSel(this.id+"_cUnit").text(_value);
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "CustomTextControlNoUnit";
	}
}

/** Defining the referenceDesignator variant of the CustomTextControl */
export class CustomReferenceDesignatorControl extends CustomTextControl {
	// TODO hier muss auch noch geprüft werden um welchen Typ dataNode es sich handelt.
	// Bei Asseemblies werden nur die ersten zwei Komponenten gecheckt
	// Bei unit & deviceNodes muss die Funktionskomponente exakt mit dem parentAssembly übereinstimmen
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {dataNode} _dataNode of represented element
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _dataNode, _callBack = null) {
		super(_id, _containerId, _label, technicalUnitEnum.EMPTY, schemaReferenceDesignator(_dataNode.nodeType), _callBack);
		this.focusable = true;
		this.dataNode = _dataNode;
	}

	/** overwriting baseclass to include necessary checks for referenceDesignator specifics
	 * @param  {Object} _value to validate
	 * @returns {Promise} when finished (the following chain of promises needed to handle the async nature of chained promises)
	 */
	hardValidate(_value) {
		return new Promise((resolve, reject) => {
			if (checkTrashed(this, _value)) {
				this.disable(true);
				resolveHardValidation(this, true, customControlValidationIndicatorEnum.CORRECT);
			} else {
				this.disable(false);
				checkPattern(this, _value)
				.then(() => checkFunctionComponent(this, _value))
				.then(() => checkUniquenessLEGACY(this, _value))
				.then(() => {
					resolveHardValidation(this, true, customControlValidationIndicatorEnum.CORRECT);
				})
				.catch((error) => {
					resolveHardValidation(this, false, customControlValidationIndicatorEnum.ERROR, error);
				});
			}

			/** Resolves the result of the enclosing promise
			 * @param  {Object} _that helper to access "this"
			 * @param  {Boolean} _validated result of validation
			 * @param  {customControlValidationIndicatorEnum} _indicator mode to hand over to validationIndicator
			 * @param  {String} _error to hand over to validationIndicator
			 */
			function resolveHardValidation(_that, _validated, _indicator, _error = "") {
				_that.validated = _validated;
				_that.toggleValidationIndicator(_indicator, _error);
				GLOBALEVENTMANAGER.dispatch(`${_that.id}_ValidityChanged`, _validated);
				resolve();
			}
		});

		/** Checks if input is respecting the defined referenceDesignator templatea
		 * @param  {Object} _that helper to access "this"
		 * @param  {String} _value to validate
		 * @returns {Boolean} indicating result (true = trashed)
		 */
		function checkTrashed(_that, _value) {
			if (_that.dataNode.getParentGroupNode().nodeType == "TrashNode") {
				return true;
			} else {
				return false;
			}
		}

		/** Checks if input is respecting the defined referenceDesignator templatea
		 * @param  {Object} _that helper to access "this"
		 * @param  {String} _value to validate
		 * @returns {Promise} indicating result of validation operation
		 */
		function checkPattern(_that, _value) {
			return new Promise((resolve, reject) => {
				const result = Joi.validate({value: _value}, _that.schema);
				if (result.error == null) {
					resolve();
				} else {
					reject(new Error(result.error.details[0].message));
				}
			});
		}

		/** Checks if input function component matches its parent
		 * @param  {Object} _that helper to access "this"
		 * @param  {String} _value to validate
		 * @returns {Promise} indicating result of validation operation
		 */
		function checkFunctionComponent(_that, _value) {
			return new Promise((resolve, reject) => {
				if (_that.dataNode.nodeType == "AssemblyNode" || _that.dataNode.nodeType == "InfrastructureNode") {
					resolve();
				} else {
					const input = parseReferenceDesignatorString(_value, getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.getTemplate()).functionComponent;
					const reference = _that.dataNode.referenceDesignator.getReferenceDesignator().functionComponent;
					if (input.number == reference.number && input.token == reference.token) {
						resolve();
					} else {
						reject(new Error(getTranslation("validation.error-referenceDesignator-function-mismatch")));
					}
				}
			});
		}

		/** Checks if input is unique
		 * @param  {Object} _that helper to access "this"
		 * @param  {String} _value to validate
		 * @returns {Promise} indicating result of validation operation
		 */
		function checkUniquenessLEGACY(_that, _value) {
			return new Promise((resolve, reject) => {
				// the "old" _value is already stored in referenceDesignator.referenceDesignatorList; therefore checking for uniqueness return false if the actual _value has not changed
				// we need to check first, if _value has changed at all
				if (_that.dataNode.referenceDesignator.getReferenceDesignator().string() == _value) {	// _value has not changed
					resolve();
				} else {																										// _value has change, check uniqueness
					if (checkReferenceDesignatorStringUniqueness(_value)) {
						resolve();
					} else {
						reject(new Error(getTranslation("validation.error-referenceDesignator-notUnique")));
					}
				}
			});
		}
	}
}

/** Defining the interface accordion headline control */
export class CustomHeadAccordionControl extends CustomHeadLineControl {
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _text to display
	 */
	constructor(_id, _containerId, _text) {
		super(_id, _containerId, _text);
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "CustomHeadAccordionControl";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			id: this.id,
			title: this.text,
			class: "cControl-Container cControl-HeadLine",
		}).appendTo(cId2jqSel(this.containerId));

		$("<span>", {
			id: `${this.id}_cLabel`,
			text: getTranslation(this.text),
			class: "cControl-HeadlineSpanAccordion noselect",
		}).appendTo(cId2jqSel(this.id));
	}

	/** Hooks an eventHandler for this elements input
	 * @param  {String} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cLabel`)[0].innerHTML = _value;
			cId2jqSel(`${this.id}_cLabel`)[0].title = _value;
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		// if (this.parentDialog != undefined) this.parentDialog.registerDataBinding(_eventName, tmpHandler);	// checking for parentDialog != undefined is a dirty hack for nested controls
		return tmpHandler;
	}
}

/** Defining the header field for port accordion */
export class CustomHeadAccordionPortControl extends CustomHeadLineControl {
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _text to display
	 * @param  {String} _side - source or target
	 * @param  {Number} _portIndex - port index
	 * @param  {Number} _interfaceIndex - interface index
	 */
	constructor(_id, _containerId, _text, _side, _portIndex, _interfaceIndex) {
		super(_id, _containerId, _text)
		,
		this.side = _side;
		this.portIndex = _portIndex;
		cId2jqSel(this.id+"_item").prop("side", this.side);
		cId2jqSel(this.id+"_item").prop("portIndex", this.portIndex);
		if (_interfaceIndex != 0) this.addToDefaultDomStructure();
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "CustomHeadAccordionPortControl";
	}

	/** Builds the DOM structure */
	createDefaultDomStructure() {
		$("<div>", {
			id: this.id,
			title: this.text,
			class: "cControl-Container cControl-HeadLine",
		}).appendTo(cId2jqSel(this.containerId));

		$("<div>", {
			id: this.id+"_item",
			class: "accSpanContainer",
			mouseenter: this.onMouseEnter,
		}).appendTo(cId2jqSel(this.id));

		$("<span>", {
			id: `${this.id}_cLabel`,
			text: getTranslation(this.text),
			class: "cControl-HeadlineSpanAccordion noselect",
		}).appendTo(cId2jqSel(this.id+"_item"));
	}

	/** Adds separator */
	addToDefaultDomStructure() {
		$("<div>", {
			id: this.id+"_itemSeparator",
			class: "accSeparator",
			text: "\xA0\|\xA0",
		}).prependTo(cId2jqSel(this.id));
	}

	/** Hooks an eventHandler for this elements input
	 * @param  {String} _eventName to set
	 * @returns  {Function} callback
	 */
	setEventHandler(_eventName) {
		const tmpHandler = (_value) => {
			cId2jqSel(`${this.id}_cLabel`)[0].innerHTML = _value;
			cId2jqSel(`${this.id}_cLabel`)[0].title = _value;
		};
		GLOBALEVENTMANAGER.addHandler(_eventName, tmpHandler);
		return tmpHandler;
	}

	/** removes from DOM
	 * @param  {boolean} _separator - true : remove also a separator
	 */
	remove(_separator) {
		if (_separator) {
			cId2jqSel(this.id).next().find(".accSeparator").remove();
		}
		cId2jqSel(this.id).remove();
	}

	/** sets control width
	 * @param  {string} _width - control width
	 */
	setWidth(_width) {
		cId2jqSel(this.id).find("div[id$=_item]").css("width", _width);
	}

	/** sets title
	 * @param  {string} _tooltip - title
	 */
	setTooltip(_tooltip) {
		cId2jqSel(this.id).attr("title", _tooltip);
	}

	/** Event on mouseenter
	 * @param {w.Event} e event
	 */
	onMouseEnter(e) {
		// if($(e.currentTarget).hasClass("unvalid")){	// for all or for invalid only ?
		// calculate active indexes to open the interface panel only if its port panel is already open (activ), i.e. the interface panel is potentially visible
		let portIndex = -1;
		cId2jqSel("aggregate_ports_"+e.currentTarget.side).find("button[id^='aggregate_ports'][id$='deleteButton']").each((i, element) => {
			if (element.index == e.currentTarget.portIndex) portIndex = i;
		});
		if (portIndex == cId2jqSel("aggregate_ports_"+e.currentTarget.side).accordion("option", "active")) { // mouseenter on activ port panel header, without the code above works exactly the same, but opening the interface panel may be hidden
			let interfaceIndex = -1;
			cId2jqSel("aggregate_ports_"+e.currentTarget.side+"_"+e.currentTarget.portIndex+"_header_parameters").find("[id$=_item]").each((i, element) => {
				if (element.id == e.currentTarget.id) interfaceIndex = i;
			});
			if (interfaceIndex != -1) cId2jqSel("aggregate_interfaces_"+e.currentTarget.side+"_port_"+e.currentTarget.portIndex).accordion("option", "active", interfaceIndex);
		}
	}
}

/** Defining the datalist variant of the customControl base template, alternative to CustomComboBoxControl */
class CustomDataListControl extends CustomBaseControl {
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _entries, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, "ValueChanged", _callBack);
		this.entries = _entries;
		this.extendDefaultDomStructure();											// create needed DOM elements
		this.focusable = true;
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "CustomDataListControl";
	}

	/** Adds datalist elements to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<input>", {
			id: `${this.id}_cInput`,
			type: "text",
			spellcheck: "false",
			class: "cControl-Input cControl-Input-Textbox",
			disabled: false,
			value: null,															// TODO set to a variables value if databound or keep null otherwise
			list: `${this.id}_cInput_dataList`,
			title: getTranslation("modalDialog.info-enterSelect"),
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));
		cId2jqSel(`${this.id}_cInput`).attr("autocomplete", "off"); // prevent giving options for completion based on previous input

		$("<datalist>", {
			id: `${this.id}_cInput_dataList`, // `${this.id}_cInput`,
			class: "cControl-Input cControl-Input-Dropdown",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		this.update(this.entries);

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value));			// registerInputEventHandler
		// cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => this.handleInput(cId2jqSel(`${this.id}_cInput_dataList`)[0].value));
		this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value);
		// this.handleInput(cId2jqSel(`${this.id}_cInput_dataList`)[0].value);
	}
	/** Write the provided _entries into the DOM element
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 */
	update(_entries) {
		this.entries = _entries;
		cId2jqSel(`${this.id}_cInput_dataList`).empty();
		this.entries.forEach((entry) => {
			$("<option>", {
				text: getTranslation(entry.caption),
				value: entry.value,
			}).appendTo(cId2jqSel(`${this.id}_cInput_dataList`));
		});
	}

	/** Sets the value of this customControl and calls the handleInput routine
	 * @param  {Object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).value = _value;						// programmatically setting a value doesn't trigger oninput event...
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}
}

/** Defining the combobox variant of the customControl base template */
export class CustomComboBoxControl extends CustomBaseControl {
	/** Standard constructor
	 * @param  {String} _id of customControl that gets created
	 * @param  {String} _containerId to nest into
	 * @param  {i18nKey} _label to display
	 * @param  {technicalUnitEnum} _unit technical unit to display
	 * @param  {joi-schema} _schema to validate user input against
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 * @param  {Function} _callBack to hook this elements value to
	 */
	constructor(_id, _containerId, _label, _unit, _schema, _entries, _callBack = null) {
		super(_id, _containerId, _label, _unit, _schema, "ValueChanged", _callBack);
		this.entries = _entries;
		this.extendDefaultDomStructure();											// create needed DOM elements
		this.focusable = true;
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "CustomComboBoxControl";
	}

	/** Adds combobox elements to baseControlDomStructure */
	extendDefaultDomStructure() {
		$("<select>", {
			id: `${this.id}_cInputS`,
			class: "cControl-Input cControl-Input-Dropdown left",
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));

		$("<input>", {
			id: `${this.id}_cInput`,
			type: "text",
			spellcheck: "false",
			class: "cControl-Input cControl-Input-Textbox cControl-comboInput left",
			disabled: false,
			value: null,															// TODO set to a variables value if databound or keep null otherwise
			// style: "width: calc(var(--default-cControl-Input-width) - 1rem); margin-left: -8.75rem; border: none; margin-top: 1px;",	//  float:left;
		}).appendTo(cId2jqSel(`${this.id}_cContainer`));
		cId2jqSel(`${this.id}_cContainer`).find("image").addClass("cControl-comboContainer"); // .css("margin-left", "1.5rem");
		cId2jqSel(`${this.id}_cInputS`).change(() => {
			this.setValue(cId2jqSel(`${this.id}_cInputS`).val());
		});
		this.update(this.entries);

		cId2jqSel(`${this.id}_cInput`)[0].addEventListener("input", () => this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value, [1000, 2000, 3000, 5000, 10000]));			// registerInputEventHandler

		this.handleInput(cId2jqSel(`${this.id}_cInput`)[0].value, [1000, 2000, 3000, 5000, 10000]);					// write active dropdown value on initialisation
	}
	/** Write the provided _entries into the DOM element
	 * @param  {Array} _entries to display as dropdown, format: array of entry objects {caption: i18nKey, value: someData}
	 */
	update(_entries) {
		this.entries = _entries;
		cId2jqSel(`${this.id}_cInputS`).empty();
		this.entries.forEach((entry) => {
			$("<option>", {
				text: getTranslation(entry.caption),
				value: entry.value,
			}).appendTo(cId2jqSel(`${this.id}_cInputS`));
		});
	}

	/** Sets the value of this customControl and calls the handleInput routine
	 * @param  {Object} _value to set
	 */
	setValue(_value) {
		document.getElementById(`${this.id}_cInput`).value = _value;						// programmatically setting a value doesn't trigger oninput event...
		document.getElementById(`${this.id}_cInput`).dispatchEvent(new Event("input"));
	}

	/** Validates user input against provided joi-schema
	 * @param  {Object} _value to validate
	 * @returns {Promise} indicating function has finished
	 */
	hardValidate(_value) {
		return new Promise((resolve, reject) => {
			if (this.isLoading) {								// don't validate while loading (validation will be called by toggleLoadingIndicator when loading is turned off)
				this.validated = false;
			} else {
				let result = null;
				let tmpErrorMessage = "";
				let tmpValidationStatus = null;

				result = Joi.validate({value: _value}, this.schema);
				if (result.error == null) {
					this.validated = true;
					if (searchArrayForElementByKeyValuePair(this.entries, "caption", _value)) {
						tmpValidationStatus = customControlValidationIndicatorEnum.CORRECT;
					}	else {
						tmpValidationStatus = customControlValidationIndicatorEnum.WARNING;
						tmpErrorMessage = getTranslation("modalDialog.cableDialog.standardLength-Info");
					}
				} else {
					this.validated = false;
					tmpErrorMessage = result.error.details[0].message;
					tmpValidationStatus = customControlValidationIndicatorEnum.ERROR;
				}

				this.toggleValidationIndicator(tmpValidationStatus, tmpErrorMessage);
			}

			GLOBALEVENTMANAGER.dispatch(`${this.id}_ValidityChanged`, this.validated);
			resolve();
		});
	}
}
