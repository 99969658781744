// Class provides means for finding compatiblePorts for a given referencePort

//BUG ts import Problem: // https://stackoverflow.com/questions/40616272/an-import-path-cannot-end-with-ts-nodejs-and-visual-code/49970851

//REFACTOR this should not be a singleton, but instead a method on dataGroupNodes (Infrastructure, Assembly)
//REFACTOR You should already provide a groupNode to the constructor to eliminate unnecessary checks outside the scope

import {getUniqueDataNodeByType} from							"../dataManager";

import type {DeviceNode} from											"../dataNode";
import type {AssemblyNode} from										"../dataNode";
import type {UnitNode} from												"../dataNode";
import type {InfrastructureNode} from							"../dataNode";
import type {Port} from														"../ports/port";
import type {PortCompatibilityChecker} from				"./PortCompatibilityChecker";
import type {PortAutoCheckConditions} from				"./PortCompatibilityChecker";


/**
 * Finds all compatible ports for a given reference port.
 */
export class FindAllCompatiblePorts {
	private readonly referencePort: Port;
	private readonly parentNode: DeviceNode|UnitNode|AssemblyNode;
	private readonly groupNode: AssemblyNode|InfrastructureNode;
	private readonly groupNodePorts: Array<Port>;
	private readonly compatiblePorts: Array<Port> = [];

	/**
	 * Creates an instance of FindAllCompatiblePorts.
	 * This class uses PortCompatibilityChecker to filter compatible ports.
	 * @param {Port} _referencePort to find compatible ports for.
	 * @param {PortCompatibilityChecker} _portCompatibilityChecker PortCompatibilityChecker instance to be used for filtering
	 * @param {boolean} [_debugMode=false] turn chatty log on/off
	 */
	constructor(_referencePort: Port, _portCompatibilityChecker: PortCompatibilityChecker, _debugMode = false) {
		this.referencePort = _referencePort;
		this.parentNode = this.referencePort.parent;
		this.groupNode = this.getGroupNode();
		this.groupNodePorts = this.getAllGroupNodePorts();		// starting point for filtering, contains ALL ports in groupNode, including referencePort and its siblings on parentNode

		this.compatiblePorts = this.filterPorts(_portCompatibilityChecker);

		if (_debugMode) this.logResult(_portCompatibilityChecker.conditions);

		Object.seal(this);
	}


	/**
	 * Returns the groupNode of of provided this.parentNode.
	 * @returns {AssemblyNode|InfrastructureNode} groupNode of parentNode
	 */
	private getGroupNode(): AssemblyNode|InfrastructureNode {
		const result = (this.parentNode.getType() === "AssemblyNode") ? getUniqueDataNodeByType("InfrastructureNode") : this.parentNode.parent;
		return result;
	}

	/**
	 * Returns all ports within this.groupNode.
	 * @warning No filters applied, includes referencePort and its sibling ports on parentNode!
	 * @returns {Array<Port>} All ports of groupNode
	 */
	private getAllGroupNodePorts(): Array<Port> {
		// collect all dataNodes (DeviceNodes, UnitNodes, AssemblyNodes) in _groupNode (there is a particularity for _groupNode=Infrastructure, see below)
		let tmpDataNodes = new Set<DeviceNode|UnitNode|AssemblyNode>();

		// _groupNode=AssemblyNode|InfrastructureNode: this only retrieves DeviceNodes|UnitNodes (AssemblyNodes are unfortunately not children of InfrastructureNode but of ProjectNode)
		this.groupNode.children.forEach((child) => {
			tmpDataNodes.add(child);
		});

		// groupNode=InfrastructureNode: additionally collect all AssemblyNodes manually (AssemblyNodes are unfortunately not children of Infrastructure but of ProjectNode)
		if (this.groupNode.getType() === "InfrastructureNode") {
			const tmpAssemblies = getUniqueDataNodeByType("ProjectNode").children.filter((child: AssemblyNode) => child.getType() === "AssemblyNode");		// retrieve all assemblies
			tmpDataNodes = new Set([...tmpDataNodes, ...tmpAssemblies]);
		}

		// extract all ports from collected dataNodes
		const result = new Set<Port>();

		tmpDataNodes.forEach((tmpDataNode: DeviceNode|UnitNode|AssemblyNode) => {
			tmpDataNode.ports.forEach((port) => {
				result.add(port);
			});
		});

		return [...result];			// converting to array (using sets ensures to only have unique ports in result)
	}

	/**
	 * Removes all incompatible ports from this.groupNodePorts array.
	 * @param {PortCompatibilityChecker} _portCompatibilityChecker instance of PortCompatibilityChecker, provided by constructor
	 * @returns {Array<Port>} Provided ports array excluding incompatible ports.
	 */
	private filterPorts(_portCompatibilityChecker: PortCompatibilityChecker): Array<Port> {
		const result = this.groupNodePorts.filter((port) => _portCompatibilityChecker.autoCheck(this.referencePort, port));

		return result;
	}

	/**
	 * Logs the result of all checks nicely formatted to the console for debugging.
	 * @param {PortAutoCheckConditions} _portAutoCheckConditions provided as constructor argument
	 */
	private logResult(_portAutoCheckConditions: PortAutoCheckConditions): void {
		/* eslint-disable no-console */
		console.group(`PortCompatibilityAnalysis`);
		console.log("excludeSameParentNode", _portAutoCheckConditions.excludeSameParentNode);
		console.log("excludeConnected", _portAutoCheckConditions.excludeConnected);

		console.group("DragStartPort");
		console.log(this.referencePort);
		console.log("Parent:     ", `${this.parentNode.getType()} "${this.parentNode.getName()}" within ${this.groupNode.getType()} "${this.groupNode.getName()}"`);
		console.log("family:     ", this.referencePort.family);
		console.log("Side:       ", this.referencePort.side);
		console.log("Interfaces: ", this.referencePort.flattenInterfaces());
		console.groupEnd();

		console.group("CompatiblePorts");
		if (this.compatiblePorts.length === 0 ) console.log("No compatible Ports found");
		this.compatiblePorts.forEach((port) => {
			const tmpParentNodeType = port.parent.getType();
			const tmpParentNodeName = port.parent.getName();
			const tmpGroupNodeType = this.groupNode.getType();
			const tmpGroupNodeName = this.groupNode.getName();

			console.group(port);
			console.log("Parent:     ", `${tmpParentNodeType} "${tmpParentNodeName}" within ${tmpGroupNodeType} "${tmpGroupNodeName}"`);
			console.log("family:     ", port.family);
			console.log("Side:       ", port.side);
			console.log("Interfaces: ", port.flattenInterfaces());
			console.groupEnd();
		});

		console.groupEnd();
		console.groupEnd();
		console.log("");
		/* eslint-enable no-console */
	}

	/**
	 * Returns the result of all checks.
	 * @returns {Array<Port>} All ports within the same groupNode that are compatible to referencePort.
	 */
	get result(): Array<Port> {
		return this.compatiblePorts;
	}
}
