import {schemaCheckbox} from "../../resources/validationSchemata";
import {USER} from "../applicationManager";
import {fieldBusEnum, technicalUnitEnum, systemBusEnum} from "../constantsAndEnumerations";
import {
	CustomCheckboxControl,
	CustomControlSpacer,
	customControlWarningModeEnum,
	CustomDropdownControl,
	CustomHeadLineControl,
	CustomInfoControl,
} from "../customControl";
import {DragListControl} from "../customControls/dragListControl/DragListControl";
import {DragListItemText} from "../customControls/dragListControl/DragListItemText";
import {eAutoConfig} from "../dataManager";
import {isMotor, isConsumer} from "../helper";
import {getTranslation} from "../localization/localizationManager";
import {CancelButton, ConfirmButton, ModalDialog} from "../modalDialog";

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} DataNode
 */
/* eslint-enable jsdoc/require-property */

/**
 * Opens an autoConfig dialog
 * @param  {DataNode} _groupNode context of autoConfigDialog (assemblyNode or InfrastructureNode)
 */
export function autoConfigDialog(_groupNode) {
	/* Enumeration of autoConfig modes */
	const autoConfigLevelEnum = {
		ASSEMBLY: {type: "ASSEMBLY", caption: "modalDialog.autoConfig.level-assembly", value: 1},
		INFRASTRUCTURE: {type: "INFRASTRUCTURE", caption: "modalDialog.autoConfig.level-infrastructure", value: 2},
	};

	// prepare list of available autoConfigLevels
	const autoConfigLevelList = [];
	autoConfigLevelList.push({caption: autoConfigLevelEnum.ASSEMBLY.caption, value: autoConfigLevelEnum.ASSEMBLY.type});
	autoConfigLevelList.push({caption: autoConfigLevelEnum.INFRASTRUCTURE.caption, value: autoConfigLevelEnum.INFRASTRUCTURE.type});

	// prepare field bus preferences list for DragListControl
	const fieldBusPreferences = [];
	USER.fieldBusPreferences.forEach((bus) => {
		fieldBusPreferences.push(new DragListItemText({id: fieldBusEnum[bus].type, content: getTranslation(fieldBusEnum[bus].caption)}));
	});

	// prepare system bus preferences list for DragListControl
	const systemBusPreferences = [];
	USER.systemBusPreferences.forEach((bus) => {
		systemBusPreferences.push(new DragListItemText({id: systemBusEnum[bus].type, content: getTranslation(systemBusEnum[bus].caption)}));
	});

	const modal = new ModalDialog("modalDialog.heading-AutoConfig");

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok", okButtonCallBack));

	// Controls
	modal.addControl(new CustomDropdownControl("configLevel", modal.contentArea, "modalDialog.autoConfig.level", technicalUnitEnum.EMPTY, autoConfigLevelList));
	modal.addControl(new CustomInfoControl("configInfo", modal.contentArea, "databound", customControlWarningModeEnum.NORMAL));

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(new CustomCheckboxControl("createCabinet", modal.contentArea, "modalDialog.chckbx-createCabinet", technicalUnitEnum.EMPTY, schemaCheckbox));
	modal.getControlById("createCabinet").setValue(USER.createCabinet);

	modal.addControl(
		new CustomCheckboxControl("configureRibbonAdapter", modal.contentArea, "modalDialog.chckbx-configureRibbonAdapter", technicalUnitEnum.EMPTY, schemaCheckbox),
	);
	modal.getControlById("configureRibbonAdapter").setValue(false);

	modal.addControl(
		new CustomCheckboxControl("preferDecentralMotors", modal.contentArea, "modalDialog.chckbx-preferDecentralMotors", technicalUnitEnum.EMPTY, schemaCheckbox, () => {}),
	);
	modal.getControlById("preferDecentralMotors").setValue(USER.preferDecentralMotors);

	const motorConsumersInfo = _groupNode.children.filter(
		(_node) => isConsumer(_node) && isMotor(_node) && _node.decentralControl !== null && !(_node.decentralControl === USER.preferDecentralMotors),
	);

	if (motorConsumersInfo.length > 0) {
		const motorConsumersNameRefDes = motorConsumersInfo.map((_node) => `${_node.getName()} [${_node.referenceDesignator.getReferenceDesignator().string()}]`);
		const motorConsumersString = motorConsumersNameRefDes.join("\n");
		modal.getControlById("preferDecentralMotors").showInfo(`${getTranslation("modalDialog.autoConfig.decentralControlToolTip")}\n\n${motorConsumersString}`);
	}

	modal.addControl(
		new CustomCheckboxControl(
			"preferDecentralConsumers",
			modal.contentArea,
			"modalDialog.chckbx-preferDecentralConsumers",
			technicalUnitEnum.EMPTY,
			schemaCheckbox,
			() => {},
		),
	);
	modal.getControlById("preferDecentralConsumers").setValue(USER.preferDecentralConsumers);

	const nonMotorConsumersInfo = _groupNode.children.filter(
		(_node) => isConsumer(_node) && !isMotor(_node) && _node.decentralControl !== null && !(_node.decentralControl === USER.preferDecentralConsumers),
	);

	if (nonMotorConsumersInfo.length > 0) {
		const nonMotorConsumersNameRefDes = nonMotorConsumersInfo.map((_node) => `${_node.getName()} [${_node.referenceDesignator.getReferenceDesignator().string()}]`);
		const nonMotorConsumersString = nonMotorConsumersNameRefDes.join("\n");
		modal.getControlById("preferDecentralConsumers").showInfo(`${getTranslation("modalDialog.autoConfig.decentralControlToolTip")}\n\n${nonMotorConsumersString}`);
	}

	modal.addControl(new CustomControlSpacer(modal.contentArea));
	if (_groupNode.isAutoConfigured()) {
		modal.addControl(new CustomInfoControl("warningAutoConfigDelete", modal.contentArea, "modalDialog.autoConfig.delete-Warning", customControlWarningModeEnum.WARNING));
	}

	modal.addControl(new CustomHeadLineControl("busPreferences", modal.contentArea, getTranslation("busPreferences.heading")));

	modal.addControl(
		new CustomInfoControl("fieldBusLevel", modal.contentArea, getTranslation("busPreferences.fieldBusPreferenceList"), customControlWarningModeEnum.NORMAL),
	);
	modal.addControl(new DragListControl("fieldBusPreferences", modal.contentArea, null, fieldBusPreferences));
	modal.addControl(new CustomControlSpacer(modal.contentArea));

	modal.addControl(
		new CustomInfoControl("systemBusLevel", modal.contentArea, getTranslation("busPreferences.systemBusPreferenceList"), customControlWarningModeEnum.NORMAL),
	);
	modal.addControl(new DragListControl("systemBusPreferences", modal.contentArea, null, systemBusPreferences));
	modal.addControl(new CustomControlSpacer(modal.contentArea));

	// Default Values
	if (_groupNode.nodeType === "InfrastructureNode") {
		modal.getControlById("configLevel").setValue(autoConfigLevelEnum.INFRASTRUCTURE.type);
		modal.getControlById("configLevel").disable(true);
	}

	// Switch configInfo text and displayed checkbox (createCabinet/ configureRibbonAdapter) depending on selected configLevel
	modal.setLocalDataBinding(modal.getControlById("configLevel").getEvent(), () => {
		switch (autoConfigLevelEnum[modal.getControlById("configLevel").getValue()]) {
			case autoConfigLevelEnum.ASSEMBLY:
				modal.getControlById("configInfo").setValue(getTranslation("modalDialog.autoConfig.level-assembly-info"));
				modal.getControlById("createCabinet").hide();
				modal.getControlById("configureRibbonAdapter").show();
				break;
			case autoConfigLevelEnum.INFRASTRUCTURE:
				modal.getControlById("configInfo").setValue(getTranslation("modalDialog.autoConfig.level-infrastructure-info"));
				modal.getControlById("createCabinet").show();
				modal.getControlById("configureRibbonAdapter").hide();
				break;
			default:
				throw new Error("AutoConfigLevel invalid!");
		}
	});

	modal.setLocalDataBinding(modal.getControlById("preferDecentralMotors").getEvent(), () => {
		const motorConsumersInfo = _groupNode.children.filter(
			(_node) =>
				isConsumer(_node) && isMotor(_node) && _node.decentralControl !== null && !(_node.decentralControl === modal.getControlById("preferDecentralMotors").getValue()),
		);

		if (motorConsumersInfo.length > 0) {
			const motorConsumersNameRefDes = motorConsumersInfo.map((_node) => `${_node.getName()} [${_node.referenceDesignator.getReferenceDesignator().string()}]`);
			const motorConsumersString = motorConsumersNameRefDes.join("\n");
			modal.getControlById("preferDecentralMotors").showInfo(`${getTranslation("modalDialog.autoConfig.decentralControlToolTip")}\n\n${motorConsumersString}`);
		}
	});

	modal.setLocalDataBinding(modal.getControlById("preferDecentralConsumers").getEvent(), () => {
		const nonMotorConsumers = _groupNode.children.filter(
			(_node) =>
				isConsumer(_node) &&
				!isMotor(_node) &&
				_node.decentralControl !== null &&
				!(_node.decentralControl === modal.getControlById("preferDecentralConsumers").getValue()),
		);

		if (nonMotorConsumers.length > 0) {
			const nonMotorConsumersNameRefDes = nonMotorConsumers.map((_node) => `${_node.getName()} [${_node.referenceDesignator.getReferenceDesignator().string()}]`);
			const nonMotorConsumersString = nonMotorConsumersNameRefDes.join("\n");
			modal.getControlById("preferDecentralConsumers").showInfo(`${getTranslation("modalDialog.autoConfig.decentralControlToolTip")}\n\n${nonMotorConsumersString}`);
		}
	});

	// Callbacks
	/** Initiates autoConfig request with groupNode and configLevel, createCabinet and configureRibbonAdapter from user input*/
	function okButtonCallBack() {
		const tmpAutoConfigLevel = autoConfigLevelEnum[modal.getControlById("configLevel").getValue()];
		const tmpAutoConfigParameters = {
			groupNode: _groupNode,
			configLevel: tmpAutoConfigLevel.value,
			fieldBusPreferences: modal.getControlById("fieldBusPreferences").value,
			systemBusPreferences: modal.getControlById("systemBusPreferences").value,
			preferDecentralMotors: modal.getControlById("preferDecentralMotors").value,
			preferDecentralConsumers: modal.getControlById("preferDecentralConsumers").value,
		};
		if (tmpAutoConfigLevel.type === "ASSEMBLY") {
			tmpAutoConfigParameters.createCabinet = null;
			tmpAutoConfigParameters.configureRibbonAdapter = modal.getControlById("configureRibbonAdapter").value;
		}
		if (tmpAutoConfigLevel.type === "INFRASTRUCTURE") {
			tmpAutoConfigParameters.createCabinet = modal.getControlById("createCabinet").value;
			tmpAutoConfigParameters.configureRibbonAdapter = null;
		}
		eAutoConfig(tmpAutoConfigParameters);
	}
}
