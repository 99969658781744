/* eslint-disable no-inner-declarations */

import {addAccordionPanelToShellDialog, createAccordionShell, createDialogAccordionCableHeader, finalizeAccordionDialog} from "./accordion";
import {USER} from "./applicationManager";
import {CheckerAndFilterForCableDialog} from "./compatibilityChecks/CheckerAndFilterForCableDialogSBI";
import {FilterCompatibleConnections} from "./compatibilityChecks/FilterCompatibleConnectionsSBI";
import {FindAllCompatibleElectricConnections} from "./compatibilityChecks/FindAllCompatibleElectricConnections.ts";
import {connectionFactory} from "./connections/connectionFactory.ts";
import {createGenericElectricConnectionSourceData} from "./connections/createGenericConnection.ts";
import {
	technicalUnitEnum,
	cableStandardLengthsEnum,
	dynamicApplicationsEnum,
	interfaceOperatingModeEnum,
	portLabelStyleEnum,
	portLayoutEnum,
} from "./constantsAndEnumerations";
import {
	CustomControlSpacer,
	customControlWarningModeEnum,
	CustomDataNodeHeader,
	CustomDropdownControl,
	CustomDropdownControlWIthEmptyOption,
	CustomHeadLineControl,
	CustomInfoControl,
	CustomTextControl,
	CustomTextAreaControl,
} from "./customControl";
import {CustomComboBoxControl} from "./customControlSpecial";
import {dataRoot, getConnectionByUUID} from "./dataManager";
import {cId2jqSel, searchArrayForElementByKeyValuePair} from "./helper";
import {formatter, getTranslation} from "./localization/localizationManager";
import {AccordionModalPanel, CancelButton, ConfirmButton, DialogButtonAccordion, ModalDialogCable, ModalDialogTabs} from "./modalDialog";
import {schemaCableMinMaxInteger, schemaGenericName, schemaPositiveInteger} from "../resources/validationSchemata";
import cableDE from "../images/cableDialog/cableDE.png";
import cableEN from "../images/cableDialog/cableEN.png";
import wiresDE from "../images/cableDialog/wiresDE.png";
import wiresEN from "../images/cableDialog/wiresEN.png";
import {PortGender, PortSide} from "./ports/utils";
import {WireProcessing, endTypesFerrule, endTypesLug, endTypesNoProcessing, endTypesStripping, endTypesWelding} from "./ports/wireProcessing";

/* Central structure for creation and manipulation of cable dialog
 *
 * UTILIZING:
 *	joi-browser validation library, details under https://github.com/jeffbski/joi-browser, https://github.com/hapijs/joi
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Stanislav Biryukov
 */

const shieldedOptions = [
	{caption: "modalDialog.cableDialog.shielded", value: true},
	{caption: "modalDialog.cableDialog.unshielded", value: false},
];
const endTypesNoProcessingOptions = [{caption: "---", value: "---"}];
const cableProcessingEnum = {
	NO_PROCESSING: {caption: "modalDialog.cableDialog.noProcessing", value: "NO_PROCESSING", entries: endTypesNoProcessing},
	FORK_LUG: {caption: "modalDialog.cableDialog.forkLug", value: "FORK_LUG", entries: endTypesLug},
	RING_LUG: {caption: "modalDialog.cableDialog.ringLug", value: "RING_LUG", entries: endTypesLug},
	WIRE_END_FERRULE: {caption: "modalDialog.cableDialog.wireEndFerrule", value: "WIRE_END_FERRULE", entries: endTypesFerrule},
	PARTIAL_STRIPPING: {caption: "modalDialog.cableDialog.partialStripping", value: "PARTIAL_STRIPPING", entries: endTypesStripping},
	RESISTANCE_WELDING: {caption: "modalDialog.cableDialog.resistanceWelding", value: "RESISTANCE_WELDING", entries: endTypesWelding},
};

/**
 * converts wire string to show in the dialog
 * @param  {string} _string to convert
 * @returns {string} converted string
 */
const convertToLocale = (_string) => _string.replaceAll(".", formatter.getDecimalDelimiter());
const defaultWireProcessingData = {
	distance: 1,
	length: 1,
	size: "---",
	type: "NO_PROCESSING",
	text: "",
};

/**
 * Opens a cable property dialog
 * @param  {string} _UUID of dataNode that is presented by this dialog
 */
export function cablePropertyDialog(_UUID) {
	const oldCable = getConnectionByUUID(_UUID);
	const oldConnectionData = oldCable.save();
	const sourceDevicePort = oldCable.targetPort.isConnectedTo;
	const targetDevicePort = oldCable.sourcePort.isConnectedTo;
	let isGeneric = false;
	let newConnection;
	let tmpConnection;

	if (oldCable.__databaseId === 0) {
		// genericCable
		const sourceConnections = dataRoot.sourceData.filter((sourceElement) => sourceElement.group === "cables");
		const genericConnectionSourceData = createGenericElectricConnectionSourceData(sourceConnections, sourceDevicePort, targetDevicePort);
		if (!genericConnectionSourceData) throw new Error("Could not create generic connection.");
		const connectionSaveData = {...genericConnectionSourceData, ...oldConnectionData};
		newConnection = connectionFactory(structuredClone(connectionSaveData));
		isGeneric = true;
		tmpConnection = connectionFactory(structuredClone(connectionSaveData));
	} else {
		newConnection = connectionFactory(structuredClone(oldConnectionData));
		tmpConnection = connectionFactory(structuredClone(oldConnectionData));
	}
	let modal = null;
	const dynamicApplications = [];
	const cableStandardLengths = [];
	const cableProcessing = [];
	const cableLabelingMethod = [];

	for (const element in dynamicApplicationsEnum) {
		if (Object.prototype.hasOwnProperty.call(dynamicApplicationsEnum, element)) {
			dynamicApplications.push({caption: dynamicApplicationsEnum[element].caption, value: dynamicApplicationsEnum[element].type});
		}
	}
	for (const element in cableStandardLengthsEnum) {
		if (Object.prototype.hasOwnProperty.call(cableStandardLengthsEnum, element)) {
			cableStandardLengths.push({caption: cableStandardLengthsEnum[element].caption, value: cableStandardLengthsEnum[element].value});
		}
	}
	for (const element in cableProcessingEnum) {
		if (Object.prototype.hasOwnProperty.call(cableProcessingEnum, element)) {
			cableProcessing.push({caption: cableProcessingEnum[element].caption, value: cableProcessingEnum[element].value});
		}
	}
	for (const element in portLabelStyleEnum) {
		if (Object.prototype.hasOwnProperty.call(portLabelStyleEnum, element)) {
			cableLabelingMethod.push({caption: portLabelStyleEnum[element].caption, value: portLabelStyleEnum[element].type});
		}
	}

	const oldCableTargetPort = oldCable.targetPort;
	const oldCableSourcePort = oldCable.sourcePort;
	newConnection.__targetPort = newConnection.ports.find((_port) => _port.side === PortSide.TARGET);
	newConnection.__sourcePort = newConnection.ports.find((_port) => _port.side === PortSide.SOURCE);
	tmpConnection.__targetPort = tmpConnection.ports.find((_port) => _port.side === PortSide.TARGET);
	tmpConnection.__sourcePort = tmpConnection.ports.find((_port) => _port.side === PortSide.SOURCE);
	const mandatoryShielded =
		sourceDevicePort.isShielded && targetDevicePort.isShielded && targetDevicePort.interfaces[0].operatingMode.type === interfaceOperatingModeEnum.SPEED_REGULATED.type;
	const filters = {
		isShielded: mandatoryShielded || newConnection.isShielded,
		dynamicApplication: newConnection.dynamicApplication,
		composition: newConnection.composition,
		sourcePortType: newConnection.sourcePort.family,
		sourcePortGeometry: isGeneric ? null : newConnection.sourcePort.geometry.layout.type,
		targetPortType: newConnection.targetPort.family,
		targetPortGeometry: isGeneric ? null : newConnection.targetPort.geometry.layout.type,
	};

	newConnection.sourcePort.isConnectedTo = targetDevicePort;
	newConnection.targetPort.isConnectedTo = sourceDevicePort;
	tmpConnection.sourcePort.isConnectedTo = targetDevicePort;
	tmpConnection.targetPort.isConnectedTo = sourceDevicePort;

	newConnection.sourcePort.__label = oldCableSourcePort.label;
	newConnection.targetPort.__label = oldCableTargetPort.label;
	if (oldCableSourcePort.wiresProcessing.length === 0 && newConnection.sourcePort.gender === "OPEN") {
		for (let j = 0; j < newConnection.leads.filter((lead) => lead.role === "MAIN").length; j++) {
			tmpConnection.sourcePort.wiresProcessing[j] = new WireProcessing({...defaultWireProcessingData, wireType: "MAIN" + (j + 1).toString()});
			newConnection.sourcePort.wiresProcessing[j] = new WireProcessing({...defaultWireProcessingData, wireType: "MAIN" + (j + 1).toString()});
		}
		for (let k = 0; k < newConnection.leads.filter((lead) => lead.role === "AUX").length; k++) {
			tmpConnection.sourcePort.wiresProcessing.push(new WireProcessing({...defaultWireProcessingData, wireType: "AUX" + (k + 1).toString()}));
			newConnection.sourcePort.wiresProcessing.push(new WireProcessing({...defaultWireProcessingData, wireType: "AUX" + (k + 1).toString()}));
		}
	}
	if (oldCableTargetPort.wiresProcessing.length === 0 && newConnection.targetPort.gender === "OPEN") {
		for (let j = 0; j < newConnection.leads.filter((lead) => lead.role === "MAIN").length; j++) {
			tmpConnection.targetPort.wiresProcessing[j] = new WireProcessing({...defaultWireProcessingData, wireType: "MAIN" + (j + 1).toString()});
			newConnection.targetPort.wiresProcessing[j] = new WireProcessing({...defaultWireProcessingData, wireType: "MAIN" + (j + 1).toString()});
		}
		for (let k = 0; k < newConnection.leads.filter((lead) => lead.role === "AUX").length; k++) {
			tmpConnection.targetPort.wiresProcessing.push(new WireProcessing({...defaultWireProcessingData, wireType: "AUX" + (k + 1).toString()}));
			newConnection.targetPort.wiresProcessing.push(new WireProcessing({...defaultWireProcessingData, wireType: "AUX" + (k + 1).toString()}));
		}
	}

	const connectionAutoCheckConditions = {
		forceShielded: false,
		excludeGenerics: false,
		forceSourcePortLayout: false,
		forceTargetPortLayout: false,
		forceDynamicApplication: false,
		forceComposition: false,
	};

	modal = new ModalDialogCable("modalDialog.heading-Properties", getConnectionByUUID(_UUID), newConnection);
	const sourceCables = dataRoot.sourceData.filter((sourceCable) => sourceCable.group === "cables");
	const sourceFamilyCables = new FindAllCompatibleElectricConnections(
		sourceDevicePort,
		targetDevicePort,
		sourceCables,
		new CheckerAndFilterForCableDialog(connectionAutoCheckConditions),
	).result;

	let tempCables = null;
	// arrays of possible source/target layouts
	let connectorLayoutsTarget = [];
	let connectorLayoutsSource = [];
	if (!isGeneric) getConnectorLayouts();
	let wireStructures = [];
	if (!isGeneric) getWireStructures();

	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));
	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, newConnection));
	modal.getControlById("propertyHeader").materialNumber.value = newConnection.materialNumber;
	// tab1
	modal.tabsHandler = new ModalDialogTabs(modal.contentArea, "tabsCable", "tabsCableHeader", "tabsCableContent", modal);
	modal.tabs.push(modal.tabsHandler.addTab("cableBaseProperties", "modalDialog.cableDialog.tab-general"));
	// Filters
	modal.tabs[0].addControl(new CustomHeadLineControl("selectFilters", modal.tabs[0].contentArea, "modalDialog.cableDialog.selectFilters"));
	modal.tabs[0].addButton(
		new DialogButtonAccordion(
			"selectFilters",
			"filtersResetButton",
			"modalDialog.cableDialog.btn-reset",
			false,
			"modalDialog.cableDialog.btn-resetFilters",
			onClickFiltersResetButton,
		),
	);
	modal.tabs[0].addControl(
		new CustomDropdownControlWIthEmptyOption(
			"propertyIsShielded",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.isShielded",
			technicalUnitEnum.EMPTY,
			shieldedOptions,
			null,
		),
	);
	modal.tabs[0].getControlById("propertyIsShielded").setValue(filters.isShielded);
	if (mandatoryShielded || isGeneric) modal.tabs[0].getControlById("propertyIsShielded").disable(true);
	else modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("propertyIsShielded").getEvent(), propertyIsShieldedChanged);
	modal.tabs[0].addControl(
		new CustomDropdownControlWIthEmptyOption(
			"propertyDynamic",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.dynamicApplication",
			technicalUnitEnum.EMPTY,
			dynamicApplications,
			null,
		),
	);
	modal.tabs[0].getControlById("propertyDynamic").setValue(filters.dynamicApplication);
	modal.tabs[0].getControlById("propertyDynamic").showInfo(getTranslation(`modalDialog.cableDialog.${filters.dynamicApplication}-info`));
	if (isGeneric) modal.tabs[0].getControlById("propertyDynamic").disable(true);
	else modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("propertyDynamic").getEvent(), propertyDynamicChanged);
	if (isGeneric) {
		modal.tabs[0].addControl(
			new CustomDropdownControlWIthEmptyOption(
				"propertyWiresNumber",
				modal.tabs[0].contentArea,
				"modalDialog.cableDialog.composition",
				technicalUnitEnum.SECTION,
				[{caption: "modalDialog.cableDialog.empty-option", value: newConnection.composition}],
				null,
			),
		);
		modal.tabs[0].getControlById("propertyWiresNumber").setValue(newConnection.composition);
		modal.tabs[0].getControlById("propertyWiresNumber").disable(true);
	} else {
		modal.tabs[0].addControl(
			new CustomDropdownControlWIthEmptyOption(
				"propertyWiresNumber",
				modal.tabs[0].contentArea,
				"modalDialog.cableDialog.composition",
				technicalUnitEnum.SECTION,
				wireStructures,
				null,
			),
		);
		if (filters.composition) modal.tabs[0].getControlById("propertyWiresNumber").setValue(filters.composition);
		modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("propertyWiresNumber").getEvent(), propertyWiresNumberChanged);
	}

	// Connectors' props
	modal.tabs[0].addControl(
		new CustomTextControl(
			"propertySourceType",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.connectorSource-type",
			technicalUnitEnum.EMPTY,
			schemaGenericName,
			null,
		),
	);
	modal.tabs[0].getControlById("propertySourceType").setValue(newConnection.targetPort.name); // newConnection.sourcePort.name)
	modal.tabs[0].getControlById("propertySourceType").showInfo(getTranslation("modalDialog.cableDialog.propertyType-info"));
	modal.tabs[0].getControlById("propertySourceType").disable(true);

	modal.tabs[0].addControl(
		new CustomDropdownControlWIthEmptyOption(
			"propertySourceLayout",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.connectorSource-layout",
			technicalUnitEnum.EMPTY,
			connectorLayoutsSource,
			null,
		),
	);
	modal.tabs[0].getControlById("propertySourceLayout").setValue(filters.targetPortGeometry); // filters.sourcePortGeometry
	if (isGeneric) modal.tabs[0].getControlById("propertySourceLayout").disable(true);

	modal.tabs[0].addControl(
		new CustomTextControl(
			"propertyTargetType",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.connectorTarget-type",
			technicalUnitEnum.EMPTY,
			schemaGenericName,
			null,
		),
	);
	modal.tabs[0].getControlById("propertyTargetType").setValue(newConnection.sourcePort.name); // newConnection.targetPort.name
	modal.tabs[0].getControlById("propertyTargetType").showInfo(getTranslation("modalDialog.cableDialog.propertyType-info"));
	modal.tabs[0].getControlById("propertyTargetType").disable(true);

	modal.tabs[0].addControl(
		new CustomDropdownControlWIthEmptyOption(
			"propertyTargetLayout",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.connectorTarget-layout",
			technicalUnitEnum.EMPTY,
			connectorLayoutsTarget,
			null,
		),
	);
	modal.tabs[0].getControlById("propertyTargetLayout").setValue(filters.sourcePortGeometry); // filters.targetPortGeometry
	if (isGeneric) {
		modal.tabs[0].getControlById("propertyTargetLayout").disable(true);
		modal.tabs[0].addControl(new CustomHeadLineControl("availableCables", modal.tabs[0].contentArea, "modalDialog.cableDialog.availableCables"));
		modal.tabs[0].addControl(
			new CustomInfoControl("warningNoCable", modal.tabs[0].contentArea, "modalDialog.cableDialog.generic-cable-warning", customControlWarningModeEnum.WARNING),
		);
	} else {
		modal.tabs[0].addControl(new CustomHeadLineControl("availableCables", modal.tabs[0].contentArea, "modalDialog.cableDialog.availableCables"));
		modal.tabs[0].addControl(
			new CustomInfoControl("warningNoCable", modal.tabs[0].contentArea, "modalDialog.cableDialog.noCable-Warning", customControlWarningModeEnum.WARNING),
		);
		modal.tabs[0].addControl(
			new CustomInfoControl("warningDummyCable", modal.tabs[0].contentArea, "modalDialog.cableDialog.dummyCable-Warning", customControlWarningModeEnum.WARNING),
		);
		tempCables = getCablesForDropdown();
		modal.tabs[0].addControl(
			new CustomDropdownControl(
				"cablesForDropdown",
				modal.tabs[0].contentArea,
				"modalDialog.cableDialog.cablesToSelect",
				technicalUnitEnum.EMPTY,
				tempCables,
				cablesForDropdownCallBack,
			),
		);
		modal.tabs[0].getControlById("cablesForDropdown").setValue(newConnection.__databaseId);
		modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("cablesForDropdown").getEvent(), cableChanged);
	}
	modal.tabs[0].addControl(new CustomControlSpacer(modal.tabs[0].contentArea));

	modal.tabs[0].addControl(new CustomHeadLineControl("commonProperties", modal.tabs[0].contentArea, "modalDialog.commonProperties"));
	modal.tabs[0].addControl(
		new CustomComboBoxControl(
			"cableLength",
			modal.tabs[0].contentArea,
			"modalDialog.cableDialog.length",
			technicalUnitEnum.LENGTH_MM,
			schemaCableMinMaxInteger(newConnection.minLength, newConnection.maxLength),
			cableStandardLengths,
			cableLengthCallBack,
		),
	);
	modal.tabs[0].getControlById("cableLength").setValue(newConnection.length);
	modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("cableLength").getEvent(), cableLengthChanged);
	modal.tabs[0].addControl(new CustomTextAreaControl("cableDescription", modal.tabs[0].contentArea, "modalDialog.designation", cableDescriptionCallBack));
	modal.tabs[0].getControlById("cableDescription").setValue(newConnection.description !== "Dummy Kabel für Konfigurator" ? newConnection.description : "");
	modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("cableDescription").getEvent(), cableDescriptionChanged);

	// cable labels
	modal.tabs.push(modal.tabsHandler.addTab("cableLabelProperties", "modalDialog.cableDialog.tab-labels"));
	modal.tabs[1].addButton(
		new DialogButtonAccordion(
			"cableLabelProperties",
			"cableLabelPropertiesInfoButton",
			"modalDialog.button.info",
			false,
			"modalDialog.cableDialog.btn-showHideInfo",
			onClickCableLabelPropertiesInfoButton,
		),
	);
	document.getElementById("cableLabelPropertiesInfoButton").classList.remove("right"); // TODO refactor
	document.getElementById("cableLabelPropertiesInfoButton").classList.add("margined-bottom");
	modal.tabs[1].addImage("cableLabelPropertiesImage", USER.language.short === "de" ? cableDE : cableEN, 470, "auto"); // TODO "zh" variant or another solution

	modal.tabs[1].addControl(
		new CustomDropdownControl(
			"cableLabelingMethod",
			modal.tabs[1].contentArea,
			"modalDialog.cableDialog.label.method",
			technicalUnitEnum.EMPTY,
			cableLabelingMethod,
			cableLabelingMethodCallBack,
		),
	);
	modal.tabs[1].getControlById("cableLabelingMethod").setValue(newConnection.sourcePort.label.style); //! tmpCable.labelingMethod, make separate for both sides?

	modal.tabs[1].addControl(new CustomHeadLineControl("labelSource", modal.tabs[1].contentArea, "modalDialog.cableDialog.source-port"));
	modal.tabs[1].addControl(
		new CustomTextControl(
			"labelSourceDistance",
			modal.tabs[1].contentArea,
			"modalDialog.cableDialog.label.distance",
			technicalUnitEnum.LENGTH_MM,
			schemaPositiveInteger,
			labelSourceDistanceCallBack,
		),
	);
	modal.tabs[1].getControlById("labelSourceDistance").setValue(newConnection.sourcePort.label.distance);
	modal.tabs[1].addControl(new CustomTextAreaControl("labelSourceText", modal.tabs[1].contentArea, "modalDialog.cableDialog.label.text", labelSourceTextCallBack));
	modal.tabs[1].getControlById("labelSourceText").setValue(newConnection.sourcePort.label.text);
	modal.tabs[1].addControl(new CustomControlSpacer(modal.tabs[1].contentArea));
	modal.tabs[1].addControl(new CustomHeadLineControl("labelTarget", modal.tabs[1].contentArea, "modalDialog.cableDialog.target-port"));
	modal.tabs[1].addControl(
		new CustomTextControl(
			"labelTargetDistance",
			modal.tabs[1].contentArea,
			"modalDialog.cableDialog.label.distance",
			technicalUnitEnum.LENGTH_MM,
			schemaPositiveInteger,
			labelTargetDistanceCallBack,
		),
	);
	modal.tabs[1].getControlById("labelTargetDistance").setValue(newConnection.targetPort.label.distance);
	modal.tabs[1].addControl(new CustomTextAreaControl("labelTargetText", modal.tabs[1].contentArea, "modalDialog.cableDialog.label.text", labelTargetTextCallBack));
	modal.tabs[1].getControlById("labelTargetText").setValue(newConnection.targetPort.label.text);
	modal.tabs.push(modal.tabsHandler.addTab("cableWiresProperties", "modalDialog.cableDialog.tab-wires"));
	modal.tabs[2].addButton(
		new DialogButtonAccordion(
			"cableWiresProperties",
			"cableWiresPropertiesInfoButton",
			"modalDialog.button.info",
			false,
			"modalDialog.cableDialog.btn-showHideInfo",
			onClickCableWiresPropertiesInfoButton,
		),
	);
	document.getElementById("cableWiresPropertiesInfoButton").classList.remove("right"); // TODO refactor
	document.getElementById("cableWiresPropertiesInfoButton").classList.add("margined-bottom");
	modal.tabs[2].addImage("cableWiresPropertiesImage", USER.language.short === "de" ? wiresDE : wiresEN, 342, "auto"); // TODO "zh" variant or another solution
	modal.tabs[2].setDisabled(true, "modalDialog.cableDialog.cableTab-tooltip");
	if (newConnection.sourcePort.gender === PortGender.OPEN) {
		modal.tabs[0].getControlById("propertyTargetLayout").disable(true); // "propertySourceLayout"
		modal.tabs[2].setDisabled(false, "");
		createAccordion(0);
	} else {
		modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("propertyTargetLayout").getEvent(), propertySourceLayoutChanged); // "propertySourceLayout"
	}
	if (newConnection.targetPort.gender === PortGender.OPEN) {
		modal.tabs[0].getControlById("propertySourceLayout").disable(true); // "propertyTargetLayout"
		modal.tabs[2].setDisabled(false, "");
		if (newConnection.sourcePort.gender === PortGender.OPEN) modal.tabs[2].addControl(new CustomControlSpacer(modal.tabs[2].contentArea));
		createAccordion(1);
	} else {
		modal.tabs[0].setLocalDataBinding(modal.tabs[0].getControlById("propertySourceLayout").getEvent(), propertyTargetLayoutChanged); // "propertyTargetLayout"
	}
	modal.tabsHandler.setActiveTab(modal.tabs[0]);

	/**
	 * creates accordion for wires
	 * @param  {number} _side source - 0, target - 1
	 */
	function createAccordion(_side) {
		_side === 1
			? createAccordionShell(modal.tabs[2].contentArea, "cableWires_" + _side, "modalDialog.cableDialog.source-port")
			: createAccordionShell(modal.tabs[2].contentArea, "cableWires_" + _side, "modalDialog.cableDialog.target-port");
		const wiresResetButton = new DialogButtonAccordion(
			"cableWires_" + _side + "Heading",
			"wiresResetButton_" + _side,
			"modalDialog.cableDialog.btn-reset",
			false,
			"modalDialog.cableDialog.btn-reset",
			onClickWiresResetButton,
		);
		modal.tabs[2].addButton(wiresResetButton);
		wiresResetButton.addProperties({side: _side});
		const wiresApplyButton = new DialogButtonAccordion(
			"cableWires_" + _side + "Heading",
			"wiresApplyButton_" + _side,
			"modalDialog.button.apply",
			false,
			"modalDialog.cableDialog.btn-applyTooltip",
			onClickWiresApplyButton,
		);
		modal.tabs[2].addButton(wiresApplyButton);
		wiresApplyButton.addProperties({side: _side});
		if (_side === 0) {
			newConnection.sourcePort.wiresProcessing.forEach((wire) => {
				const headerDialogAccordionContent = createDialogAccordionCableHeader(_side, wire.wireType);
				addAccordionPanelToShellDialog(
					"cableWires_" + _side,
					wire.wireType + _side,
					wire.wireType + _side + "Heading",
					headerDialogAccordionContent.html(),
					"wires" + _side,
				);

				const accPanel = new AccordionModalPanel("cableWires_" + _side, wire.wireType + _side, wire.wireType + _side + "Heading", _side, 0, null);
				modal.tabs[2].accordionPanels.source.push(accPanel);
				accPanel.setHeaderText(wire.wireType);
				accPanel.addControl(
					new CustomTextControl(
						"wireLength" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.wireLength",
						technicalUnitEnum.LENGTH_MM,
						schemaPositiveInteger,
						null,
					),
				);
				accPanel.getControlById("wireLength" + wire.wireType + _side).setValue(wire.length);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireLength" + wire.wireType + _side).getEvent(), wireLengthChanged);

				accPanel.addControl(new CustomHeadLineControl("wireEnd" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.end"));
				accPanel.addControl(
					new CustomDropdownControl(
						"wireEndType" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.endType",
						technicalUnitEnum.EMPTY,
						cableProcessing,
						null,
					),
				);
				accPanel.addControl(
					new CustomDropdownControl(
						"wireEndSize" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.endSize",
						technicalUnitEnum.EMPTY,
						endTypesNoProcessingOptions,
						null,
					),
				);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireEndType" + wire.wireType + _side).getEvent(), wireEndTypeChanged);
				accPanel.getControlById("wireEndType" + wire.wireType + _side).setValue(wire.type);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireEndSize" + wire.wireType + _side).getEvent(), wireEndSizeChanged);
				accPanel.getControlById("wireEndSize" + wire.wireType + _side).setValue(wire.size);
				accPanel.addControl(new CustomHeadLineControl("wireLabel" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.label.leads-labeling"));
				accPanel.addControl(
					new CustomTextControl(
						"labelDistance" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.label.distance",
						technicalUnitEnum.LENGTH_MM,
						schemaPositiveInteger,
						labelDistanceChanged,
					),
				);
				accPanel.getControlById("labelDistance" + wire.wireType + _side).setValue(wire.distance);
				accPanel.setLocalDataBinding(accPanel.getControlById("labelDistance" + wire.wireType + _side).getEvent(), labelDistanceChanged);
				accPanel.addControl(new CustomTextAreaControl("labelText" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.label.text", null));
				accPanel.getControlById("labelText" + wire.wireType + _side).setValue(wire.text);
				accPanel.setLocalDataBinding(accPanel.getControlById("labelText" + wire.wireType + _side).getEvent(), labelTextChanged);

				/**
				 * Wire length changed event
				 * @param  {number} _length to set
				 */
				function wireLengthChanged(_length) {
					tmpConnection.sourcePort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].length = parseInt(_length);
				}

				/**
				 * Wire end type changed event
				 * @param  {string} _type end wire type to set
				 */
				function wireEndTypeChanged(_type) {
					if (_type) {
						const endSizeControl = accPanel.getControlById("wireEndSize" + wire.wireType + _side);
						const newEntries = [];
						cableProcessingEnum[_type].entries.forEach((element) => {
							newEntries.push({caption: element, value: element});
						});
						endSizeControl.update(newEntries);
						endSizeControl.setValue(wire.size);
						tmpConnection.sourcePort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].type = _type;
					}
				}

				/**
				 * Wire end size changed event
				 * @param  {string} _size to set
				 */
				function wireEndSizeChanged(_size) {
					tmpConnection.sourcePort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].size = _size;
				}

				/**
				 * Label distance changed event
				 * @param  {number} _distance to set
				 */
				function labelDistanceChanged(_distance) {
					tmpConnection.sourcePort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].distance = parseInt(_distance);
				}

				/**
				 * Label text changed event
				 * @param  {string} _text to set
				 */
				function labelTextChanged(_text) {
					tmpConnection.sourcePort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].text = _text;
				}
			});
		} else {
			newConnection.targetPort.wiresProcessing.forEach((wire) => {
				const headerDialogAccordionContent = createDialogAccordionCableHeader(_side, wire.wireType);
				addAccordionPanelToShellDialog(
					"cableWires_" + _side,
					wire.wireType + _side,
					wire.wireType + _side + "Heading",
					headerDialogAccordionContent.html(),
					"wires" + _side,
				);

				const accPanel = new AccordionModalPanel("cableWires_" + _side, wire.wireType + _side, wire.wireType + _side + "Heading", _side, 0, null);
				modal.tabs[2].accordionPanels.target.push(accPanel);
				accPanel.setHeaderText(wire.wireType);
				accPanel.addControl(
					new CustomTextControl(
						"wireLength" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.wireLength",
						technicalUnitEnum.LENGTH_MM,
						schemaPositiveInteger,
						null,
					),
				);
				accPanel.getControlById("wireLength" + wire.wireType + _side).setValue(wire.length);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireLength" + wire.wireType + _side).getEvent(), wireLengthChanged);

				accPanel.addControl(new CustomHeadLineControl("wireEnd" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.end"));
				accPanel.addControl(
					new CustomDropdownControl(
						"wireEndType" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.endType",
						technicalUnitEnum.EMPTY,
						cableProcessing,
						null,
					),
				);
				accPanel.addControl(
					new CustomDropdownControl(
						"wireEndSize" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.endSize",
						technicalUnitEnum.EMPTY,
						endTypesNoProcessingOptions,
						null,
					),
				);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireEndType" + wire.wireType + _side).getEvent(), wireEndTypeChanged);
				accPanel.getControlById("wireEndType" + wire.wireType + _side).setValue(wire.type);
				accPanel.setLocalDataBinding(accPanel.getControlById("wireEndSize" + wire.wireType + _side).getEvent(), wireEndSizeChanged);
				accPanel.getControlById("wireEndSize" + wire.wireType + _side).setValue(wire.size);
				accPanel.addControl(new CustomHeadLineControl("wireLabel" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.label.leads-labeling"));
				accPanel.addControl(
					new CustomTextControl(
						"labelDistance" + wire.wireType + _side,
						accPanel.contentArea,
						"modalDialog.cableDialog.label.distance",
						technicalUnitEnum.LENGTH_MM,
						schemaPositiveInteger,
						labelDistanceChanged,
					),
				); // () => labelDistanceCallBack(_side, wire)
				accPanel.getControlById("labelDistance" + wire.wireType + _side).setValue(wire.distance);
				accPanel.setLocalDataBinding(accPanel.getControlById("labelDistance" + wire.wireType + _side).getEvent(), labelDistanceChanged);
				accPanel.addControl(new CustomTextAreaControl("labelText" + wire.wireType + _side, accPanel.contentArea, "modalDialog.cableDialog.label.text", null));
				accPanel.getControlById("labelText" + wire.wireType + _side).setValue(wire.text);
				accPanel.setLocalDataBinding(accPanel.getControlById("labelText" + wire.wireType + _side).getEvent(), labelTextChanged);

				/**
				 * Wire length changed event
				 * @param  {number} _length to set
				 */
				function wireLengthChanged(_length) {
					tmpConnection.targetPort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].length = parseInt(_length);
				}

				/**
				 * Wire end type changed event
				 * @param  {string} _type end wire type to set
				 */
				function wireEndTypeChanged(_type) {
					if (_type) {
						const endSizeControl = accPanel.getControlById("wireEndSize" + wire.wireType + _side);
						const newEntries = [];
						cableProcessingEnum[_type].entries.forEach((element) => {
							newEntries.push({caption: element, value: element});
						});
						endSizeControl.update(newEntries);
						endSizeControl.setValue(wire.size);
						tmpConnection.targetPort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].type = _type;
					}
				}

				/**
				 * Wire end size changed event
				 * @param  {string} _size to set
				 */
				function wireEndSizeChanged(_size) {
					tmpConnection.targetPort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].size = _size;
				}

				/**
				 * Label distance changed event
				 * @param  {number} _distance to set
				 */
				function labelDistanceChanged(_distance) {
					tmpConnection.targetPort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].distance = parseInt(_distance);
				}

				/**
				 * Label text changed event
				 * @param  {string} _text to set
				 */
				function labelTextChanged(_text) {
					tmpConnection.targetPort.wiresProcessing.filter((tmpWire) => tmpWire.wireType === wire.wireType)[0].text = _text;
				}
			});
		}

		finalizeAccordionDialog("cableWires_" + _side, "wires" + _side);

		/**
		 * Callback on click wires apply button
		 * @param {w.Event} e event
		 */
		function onClickWiresApplyButton(e) {
			const side = e.currentTarget.side ? "target" : "source";
			const valuesToApply = [];
			const accordionPanels = modal.tabs[2].accordionPanels[side];
			const controlsToApply = accordionPanels[0].controls;
			for (let i = 0; i < controlsToApply.length; i++) {
				if (Object.prototype.hasOwnProperty.call(controlsToApply[i], "value")) valuesToApply.push(controlsToApply[i].value);
				else valuesToApply.push(null);
			}
			updateWiresInfo(1, accordionPanels, valuesToApply, side);
		}

		/**
		 * Callback on click wires reset button
		 * @param {w.Event} e event
		 */
		function onClickWiresResetButton(e) {
			const side = e.currentTarget.side ? "target" : "source";
			const valuesToApply = [1, null, "NO_PROCESSING", "---", null, 1, ""];
			const accordionPanels = modal.tabs[2].accordionPanels[side];
			updateWiresInfo(0, accordionPanels, valuesToApply, side);
		}

		/**
		 * Callback on click wires reset button
		 * @param {number} _start panel to start with, 1 or 0
		 * @param {Array} _accordionPanels panels definitions
		 * @param {Array} _valuesToApply values to update with
		 * @param {string} _side source or target
		 */
		function updateWiresInfo(_start, _accordionPanels, _valuesToApply, _side) {
			for (let j = _start; j < _accordionPanels.length; j++) {
				for (let k = 0; k < _accordionPanels[j].controls.length; k++) {
					if (Object.prototype.hasOwnProperty.call(_accordionPanels[j].controls[k], "value")) {
						_accordionPanels[j].controls[k].setValue(_valuesToApply[k]);
						if (k === 2)
							_side === "source"
								? (newConnection.sourcePort.wiresProcessing[j].type = _valuesToApply[k])
								: (newConnection.targetPort.wiresProcessing[j].type = _valuesToApply[k]);
						if (k === 3)
							_side === "source"
								? (newConnection.sourcePort.wiresProcessing[j].size = _valuesToApply[k])
								: (newConnection.targetPort.wiresProcessing[j].size = _valuesToApply[k]);
					}
				}
			}
		}
	}

	/**
	 * Cables for the dropdown
	 * @returns  {Array} _suitable cables
	 */
	function getCablesForDropdown() {
		// use argument object besed on filters
		connectionAutoCheckConditions.excludeGenerics = true;
		connectionAutoCheckConditions.forceShielded = filters.isShielded;
		connectionAutoCheckConditions.forceDynamicApplication = filters.dynamicApplication ? filters.dynamicApplication : false;
		connectionAutoCheckConditions.forceSourcePortLayout = filters.targetPortGeometry ? filters.targetPortGeometry : false; // filters.sourcePortGeometry ? filters.sourcePortGeometry : false;
		connectionAutoCheckConditions.forceTargetPortLayout = filters.sourcePortGeometry ? filters.sourcePortGeometry : false; // filters.targetPortGeometry ? filters.targetPortGeometry : false;
		connectionAutoCheckConditions.forceComposition = filters.composition;
		const compatibleConnections = new FilterCompatibleConnections(sourceFamilyCables, new CheckerAndFilterForCableDialog(connectionAutoCheckConditions), {
			debugMode: true,
		}).result;
		if (compatibleConnections) modal.tabs[0].getControlById("warningNoCable").toggleVisibility(false);
		else modal.tabs[0].getControlById("warningNoCable").toggleVisibility(true);
		const result = [];
		if (compatibleConnections) {
			compatibleConnections.forEach((connection) => {
				if (connection.name === "Dummy Kabel für Konfigurator") {
					connection.name = createNameForDummy(connection);
				}
				result.push({caption: connection.name, value: connection.databaseId});
			});
		}
		return result;

		/**
		 * Creates the dummy cable name for the port list
		 * @param {string} _connection of the cable
		 * @returns {string} new version of cable name
		 */
		function createNameForDummy(_connection) {
			let tmpName = _connection.ports[0].family === "XTEC15" || _connection.ports[0].family === "XTEC15" ? "W-TEC 15" : "";
			tmpName += _connection.isShielded ? "M " : "K ";
			tmpName += "Sti ";
			tmpName += getTranslation(_connection.ports[0].layout.caption);
			tmpName += "/Bu ";
			tmpName += getTranslation(_connection.ports[1].layout.caption);
			tmpName += " ";
			tmpName += convertToLocale(_connection.composition);
			tmpName += " ";
			tmpName += _connection.dynamicApplication;
			return tmpName;
		}
	}

	/**
		Layouts for dropdown
		*/
	function getConnectorLayouts() {
		connectorLayoutsTarget = [];
		connectorLayoutsSource = [];
		sourceFamilyCables.forEach((sourceFamilyCable) => {
			const currentLayoutSource = {caption: sourceFamilyCable.ports[0].layout.caption, value: sourceFamilyCable.ports[0].geometry.layout.type};
			const sourceIndex = connectorLayoutsSource.map((el) => el.caption).indexOf(currentLayoutSource.caption);
			if (sourceIndex === -1) connectorLayoutsSource.push(currentLayoutSource);
			const currentLayoutTarget = {caption: sourceFamilyCable.ports[1].layout.caption, value: sourceFamilyCable.ports[1].geometry.layout.type};
			const targetIndex = connectorLayoutsTarget.map((el) => el.caption).indexOf(currentLayoutTarget.caption);
			if (targetIndex === -1) connectorLayoutsTarget.push(currentLayoutTarget);
		});
		Object.keys(portLayoutEnum).forEach((layout) => {
			if (!searchArrayForElementByKeyValuePair(connectorLayoutsSource, "value", layout)) {
				connectorLayoutsSource.push({caption: portLayoutEnum[layout].caption, value: layout.type, disabled: true});
			}
			if (!searchArrayForElementByKeyValuePair(connectorLayoutsTarget, "value", layout)) {
				connectorLayoutsTarget.push({caption: portLayoutEnum[layout].caption, value: layout.type, disabled: true});
			}
		});
	}

	/**
		Wire structures for dropdown
		*/
	function getWireStructures() {
		wireStructures = [];
		for (const sourceCable of sourceFamilyCables.filter((sourceCable) =>
			filters.isShielded === null ? true : sourceCable.isShielded.toString() === filters.isShielded.toString(),
		)) {
			if (
				Object.prototype.hasOwnProperty.call(sourceCable, "composition") &&
				!searchArrayForElementByKeyValuePair(wireStructures, "value", sourceCable.composition) &&
				sourceCable.leads[0].currentMax >= targetDevicePort.interfaces[0].current // && //! check others interfaces if any
				// sourceCable.leads.length >= targetDevicePort.leads.length
			) {
				wireStructures.push({caption: convertToLocale(sourceCable.composition), value: sourceCable.composition});
			}
		}
	}

	/**
		Cable length changed event
		*/
	function cableLengthChanged() {
		const lengthString = formatter.number2matNrLength(modal.tabs[0].getControlById("cableLength").value);
		const matNrControl = modal.getControlById("propertyHeader").materialNumber;
		matNrControl.setValue(matNrControl.value.substring(0, matNrControl.value.indexOf("-") + 1) + lengthString);
	}

	/**
		Cable description changed event
		*/
	function cableDescriptionChanged() {
		const valueDescription = modal.tabs[0].getControlById("cableDescription").value;
		const headerDescription = modal.getControlById("propertyHeader").description;
		headerDescription.setValue(valueDescription);
	}

	/**
	 * Dynamic changed event
	 * @param  {string} _da dynamicApplication property to set
	 */
	function propertyDynamicChanged(_da) {
		if (_da) {
			if (_da === "null") filters.dynamicApplication = false;
			else {
				filters.dynamicApplication = _da;
				modal.tabs[0].getControlById("propertyDynamic").showInfo(getTranslation(`modalDialog.cableDialog.${filters.dynamicApplication}-info`));
			}
			updateCables();
		}
	}

	/**
	 * Wires number changed event
	 * @param  {string} _wn wires number property to set === "null"
	 */
	function propertyWiresNumberChanged(_wn) {
		if (_wn) {
			if (_wn === "null") {
				filters.composition = false;
			} else {
				filters.composition = _wn;
			}
			updateCables();
		}
	}

	/**
	 * Shielded changed event
	 * @param  {boolean} _sh shielded property to set
	 */
	function propertyIsShieldedChanged(_sh) {
		if (_sh === "null") filters.isShielded = null;
		else filters.isShielded = _sh === "true";
		updateConnectorLayouts();
		updateWireStructures();
		updateCables();
	}

	/**
	 * Target layout changed event
	 * @param  {string} _la layout to set
	 */
	function propertyTargetLayoutChanged(_la) {
		if (_la) {
			if (_la === "null") {
				filters.targetPortGeometry = false;
			} else {
				filters.targetPortGeometry = portLayoutEnum[_la].type;
			}
			updateCables();
		}
	}

	/**
	 * Source layout changed event
	 * @param  {string} _la layout to set
	 */
	function propertySourceLayoutChanged(_la) {
		if (_la) {
			if (_la === "null") {
				filters.sourcePortGeometry = false;
			} else {
				filters.sourcePortGeometry = portLayoutEnum[_la].type;
			}
			updateCables();
		}
	}

	/**
	 * Cable changed event
	 * @param  {number} _databaseId layout to set
	 */
	function cableChanged(_databaseId) {
		const newCable = sourceFamilyCables.filter((sourceCable) => sourceCable.databaseId === parseInt(_databaseId))[0];
		const matNrControl = modal.getControlById("propertyHeader").materialNumber;
		const lengthString = matNrControl.value.substring(matNrControl.value.indexOf("-") + 1);
		if (newCable && newCable.materialNumber) matNrControl.setValue(newCable.materialNumber.substring(0, newCable.materialNumber.indexOf("-") + 1) + lengthString);
		const nameControl = modal.getControlById("propertyHeader").name;
		if (newCable && newCable.name) nameControl.setValue(newCable.name);
		if (newCable && newCable.description && newCable.description === "Dummy Kabel für Konfigurator")
			modal.tabs[0].getControlById("warningDummyCable").toggleVisibility(true);
		else modal.tabs[0].getControlById("warningDummyCable").toggleVisibility(false);
	}

	/** function for updating connector layouts */
	function updateConnectorLayouts() {
		getConnectorLayouts();
		modal.tabs[0].getControlById("propertySourceLayout").update(connectorLayoutsSource);
		modal.tabs[0].getControlById("propertySourceLayout").setValue(filters.targetPortGeometry === false ? "null" : filters.targetPortGeometry); // filters.sourcePortGeometry
		modal.tabs[0].getControlById("propertyTargetLayout").update(connectorLayoutsTarget);
		modal.tabs[0].getControlById("propertyTargetLayout").setValue(filters.sourcePortGeometry === false ? "null" : filters.sourcePortGeometry); // filters.targetPortGeometry
	}

	/** function for updating wire structures */
	function updateWireStructures() {
		getWireStructures();
		modal.tabs[0].getControlById("propertyWiresNumber").update(wireStructures);
		modal.tabs[0].getControlById("propertyWiresNumber").setValue(filters.composition === false ? "null" : filters.composition);
	}

	/** function for updating cables */
	function updateCables() {
		tempCables = getCablesForDropdown();
		if (tempCables && modal.tabs[0].getControlById("cablesForDropdown")) {
			modal.tabs[0].getControlById("cablesForDropdown").update(tempCables);
			modal.tabs[0].getControlById("cablesForDropdown").setValue(newConnection.__databaseId);
		}
	}

	/** function for filters reset button */
	function onClickFiltersResetButton() {
		if (!mandatoryShielded) {
			modal.tabs[0].getControlById("propertyIsShielded").setValue("null");
			filters.isShielded = null;
		}
		modal.tabs[0].getControlById("propertyDynamic").setValue("null");
		modal.tabs[0].getControlById("propertyWiresNumber").setValue("null");
		filters.dynamicApplication = false;
		filters.composition = false;
		if (!cId2jqSel("propertySourceLayout_cInput")[0].disabled) {
			modal.tabs[0].getControlById("propertySourceLayout").setValue("null");
			filters.sourcePortGeometry = false;
		}
		if (!cId2jqSel("propertyTargetLayout_cInput")[0].disabled) {
			modal.tabs[0].getControlById("propertyTargetLayout").setValue("null");
			filters.targetPortGeometry = false;
		}
	}

	/** function for labels info button */
	function onClickCableLabelPropertiesInfoButton() {
		const image = document.getElementById("cableLabelPropertiesImage");
		if (image.classList.contains("image-cable")) {
			image.classList.remove("image-cable");
			image.classList.add("image-cable-hidden");
		} else {
			image.classList.remove("image-cable-hidden");
			image.classList.add("image-cable");
		}
	}

	/** function for wires info button */
	function onClickCableWiresPropertiesInfoButton() {
		const image = document.getElementById("cableWiresPropertiesImage");
		if (image.classList.contains("image-cable")) {
			image.classList.remove("image-cable");
			image.classList.add("image-cable-hidden");
		} else {
			image.classList.remove("image-cable-hidden");
			image.classList.add("image-cable");
		}
	}

	// Callbacks !!!Execute child callbacks on pressing OK!!!

	/**
	 * Wrapper for deviceName Control callback
	 * @param  {string} _id to set
	 */
	function cablesForDropdownCallBack(_id) {
		const id = parseInt(_id);
		if (id !== newConnection.__databaseId) {
			const activeConnection = getConnectionByUUID(_UUID);
			const sourceDevicePort = activeConnection.targetPort.isConnectedTo;
			const targetDevicePort = activeConnection.sourcePort.isConnectedTo;
			const saveData = {
				application: "ELECTRIC",
				databaseId: id,
				description: activeConnection.description,
				geometry: null,
				length: activeConnection.length,
				sourceDevicePortUUID: sourceDevicePort.UUID,
				targetDevicePortUUID: targetDevicePort.UUID,
				UUID: _UUID,
			};
			const changedConnection = connectionFactory(saveData);
			const assemblyNode = activeConnection.parent;
			assemblyNode.removeConnection(activeConnection);
			assemblyNode.addConnection(changedConnection, sourceDevicePort, targetDevicePort);
		}
	}

	/**
	 * Wrapper for cableLength Control callback
	 * @param  {number} _length to set
	 */
	function cableLengthCallBack(_length) {
		getConnectionByUUID(_UUID).length = _length;
	}

	/**
	 * Wrapper for cableDescription Control callback
	 * @param  {number} _name to set
	 */
	function cableDescriptionCallBack(_name) {
		getConnectionByUUID(_UUID).description = _name;
	}

	/**
	 * Wrapper for LabelingMethod Control callback
	 * @param  {string} _method to set
	 */
	function cableLabelingMethodCallBack(_method) {
		getConnectionByUUID(_UUID).sourcePort.label.style = _method;
		getConnectionByUUID(_UUID).targetPort.label.style = _method;
	}

	/**
	 * Wrapper for source label distance callback
	 * @param  {number} _distance to set
	 */
	function labelSourceDistanceCallBack(_distance) {
		getConnectionByUUID(_UUID).sourcePort.label.distance = parseInt(_distance);
	}

	/**
	 * Wrapper for target label distance callback
	 * @param  {number} _distance to set
	 */
	function labelTargetDistanceCallBack(_distance) {
		getConnectionByUUID(_UUID).targetPort.label.distance = parseInt(_distance);
	}

	/**
	 * Wrapper for source label text callback
	 * @param  {string} _text to set
	 */
	function labelSourceTextCallBack(_text) {
		getConnectionByUUID(_UUID).sourcePort.label.text = _text;
		getConnectionByUUID(_UUID).sourcePort.wiresProcessing = tmpConnection.sourcePort.wiresProcessing;
	}

	/**
	 * Wrapper for target label text callback
	 * @param  {string} _text to set
	 */
	function labelTargetTextCallBack(_text) {
		getConnectionByUUID(_UUID).targetPort.label.text = _text;
		getConnectionByUUID(_UUID).targetPort.wiresProcessing = tmpConnection.targetPort.wiresProcessing;
	}
	// }
}
