/* eslint-disable require-jsdoc */

import type {Interface} from "./interface";

export interface Lead {
	id: number;
	section: number;
	currentMax: number;
	voltageMax: number;
	role: "MAIN" | "AUX";
	mappedInterface: Interface;
}

/**
 *
 */
export class ElectricInterfaceMapper {
	private readonly __leads: Lead[] = [];

	constructor(_leadData: Lead[]) {
		this.__leads = _leadData;

		Object.seal(this);
	}

	/**
	 * Returns the leads of this electric interface mapper.
	 * @returns {Array<Lead>} leads of this electrical connection
	 */
	get leads(): Lead[] {
		return this.__leads;
	}

	/**
	 *
	 */
	public mapInterface(_interface: Interface): void {
		if (this.interfaceExists(_interface)) throw new Error("Interface already mapped onto leads.");

		//REFACTOR you need a way to determine the role -either from sourceData or via an educated guess^^
		const interfaceRole = this.guessRole(_interface);

		// find all unmapped leads with the correct role
		const unmappedRoleLeads = this.__leads.filter((lead) => lead.role === interfaceRole && lead.mappedInterface === null);

		// check if there are enough free unmapped leads for the role
		if (unmappedRoleLeads.length < _interface.requiredLeads) {
			throw new Error(`Not enough free leads to map interface "${_interface.type.type}".`);
			// throw new Error(`Not enough free leads to map interface "${_interface.type.type}" of "${_interface.parent.name}".`);
		}

		for (let i = 0; i < _interface.requiredLeads; i++) {
			this.checkElectrics(unmappedRoleLeads[i], _interface);
			unmappedRoleLeads[i].mappedInterface = _interface;
		}
	}

	/**
	 *
	 */
	public unmapInterface(_interface: Interface): void {
		if (!this.interfaceExists(_interface)) throw new Error("Interface not mapped onto leads.");

		this.__leads.filter((lead) => lead.mappedInterface === _interface).forEach((lead) => (lead.mappedInterface = null));
	}

	/**
	 *
	 */
	public updateInterface(_interface: Interface): void {
		if (!this.interfaceExists(_interface)) throw new Error("Interface not mapped onto leads.");

		const interfaceLeads = this.__leads.filter((lead) => lead.mappedInterface === _interface);
		interfaceLeads.forEach((interfaceLead) => {
			this.checkElectrics(interfaceLead, _interface);
		});
	}

	/**
	 *
	 */
	private checkElectrics(_lead: Lead, _interface: Interface): void {
		if (_interface.current > _lead.currentMax) throw new Error(`Interface.current (${_interface.current}) > lead.currentMax. (${_lead.currentMax})`);
		if (_interface.voltage > _lead.voltageMax) throw new Error(`Interface.voltage (${_interface.voltage}) > lead.voltageMax. (${_lead.voltageMax})`);
	}

	/**
	 *
	 */
	private interfaceExists(_interface: Interface): boolean {
		return Boolean(this.__leads.find((lead) => lead.mappedInterface === _interface));
	}

	// for predefined ports, this info should be provided by the db
	/**
	 *
	 */
	private guessRole(_interface: Interface): "MAIN" | "AUX" {
		const mainExists = Boolean(this.__leads.find((lead) => lead.role === "MAIN"));
		const auxExists = Boolean(this.__leads.find((lead) => lead.role === "AUX"));

		// if there is only MAIN -> return MAIN
		if (!auxExists) return "MAIN";

		// always map ENERGY_AC to MAIN -> return MAIN
		if (!mainExists && _interface.type.type === "ENERGY_AC") throw new Error(`No MAIN for ${_interface.type.type} interface`);
		if (_interface.type.type === "ENERGY_AC") return "MAIN";

		// always map DATA_ASI to MAIN -> return MAIN
		if (!mainExists && _interface.type.type === "DATA_ASI") throw new Error(`No MAIN for ${_interface.type.type} interface`);
		if (_interface.type.type === "DATA_ASI") return "MAIN";

		// always map non-ENERGY_AC if MAIN exists AND ENERGY_AC exists return AUX for all non-ENERGY_AC interfaces
		if (!auxExists && _interface.type.type !== "ENERGY_AC") throw new Error(`No AUX for ${_interface.type.type} interface`);
		if (_interface.type.type !== "ENERGY_AC") return "AUX";
	}
}
