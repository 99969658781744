import {BACKEND} from "./applicationManager";
import {accordionLayoutEnum, colorSchemeEnum, drawModeEnum, languageEnum} from "./constantsAndEnumerations";
import {isEqual} from "./helper";
import {getTranslation} from "./localization/localizationManager";

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} fieldBusEnum
 * @typedef {string} fieldBusEnum.key
 * @typedef {object} systemBusEnum
 * @typedef {string} systemBusEnum.key
 */
/* eslint-enable jsdoc/require-property */

/**
 * Class representing the active user.
 */
export class User {
	/**
	 * Creates an instance of User.
	 * @param {boolean} [_debugMode=false] turn chatty log on/off
	 */
	constructor(_debugMode = false) {
		// Prevent multiple instances
		if (User.__instance) throw new Error("Singleton classes can't be instantiated more than once.");
		User.__instance = this;

		this.__debugMode = _debugMode;

		this.__id = null;
		this.__isRegistered = null;
		this.__firstName = null;
		this.__lastName = null;
		this.__company = null;
		this.__groups = null;
		this.__showGreeter = null;
		this.__showChangelog = null;
		this.__language = null;
		this.__showRecentProjects = null;
		this.__recentProjectsCount = null;
		this.__accordionLayout = null;
		this.__colorScheme = null;
		this.__drawMode = null;
		this.__createCabinet = null;
		this.__preferDecentralMotors = null;
		this.__preferDecentralConsumers = null;
		this.__fieldBusPreferences = null;
		this.__systemBusPreferences = null;

		if (this.__debugMode) {
			// eslint-disable-next-line no-console
			console.debug("%cDebugMode: USER", "color: green");
			window.USER = this;
		}
	}

	/**
	 * Retrieves userData from Server via request and sets this users preferences accordingly.
	 * Non-registered users get default preferences provided by the backend.
	 */
	async update() {
		const tmpUserData = await BACKEND.getUserSettings();

		this.__id = tmpUserData.id;
		this.__isRegistered = tmpUserData.isRegistered;
		this.__firstName = tmpUserData.firstName;
		this.__lastName = tmpUserData.lastName;
		this.__company = tmpUserData.company;
		this.__groups = tmpUserData.groups;
		this.__showGreeter = tmpUserData.showGreeter;
		this.__showChangelog = tmpUserData.showChangelog;
		this.__language = tmpUserData.language === null ? null : languageEnum[tmpUserData.language]; // set by Localizer from browser language for non registered users
		this.__showRecentProjects = tmpUserData.showRecentProjects;
		this.__recentProjectsCount = tmpUserData.recentProjectsCount;
		this.__accordionLayout = accordionLayoutEnum[tmpUserData.accordionLayout];
		this.__colorScheme = colorSchemeEnum[tmpUserData.colorScheme];
		this.__drawMode = drawModeEnum[tmpUserData.drawMode];
		this.__createCabinet = tmpUserData.createCabinet;
		this.__preferDecentralMotors = tmpUserData.preferDecentralMotors;
		this.__preferDecentralConsumers = tmpUserData.preferDecentralConsumers;
		this.__fieldBusPreferences = tmpUserData.fieldBusPreferences;
		this.__systemBusPreferences = tmpUserData.systemBusPreferences;
	}

	/**
	 * Returns debugMode.
	 * @returns {boolean} debugMode.
	 */
	get debugMode() {
		return this.__debugMode;
	}

	/**
	 * Returns this users id.
	 * @returns {number} id of this user.
	 */
	get id() {
		return this.__id;
	}

	/**
	 * Returns this users isRegistered state.
	 * @returns {boolean} isRegistered.
	 */
	get isRegistered() {
		return this.__isRegistered;
	}

	/**
	 * Returns this users full name or "guest" if user is not registered.
	 * @returns {string} user full name or 'guest' if user is unregistered.
	 */
	get fullName() {
		if (this.isRegistered) {
			return `${this.__firstName} ${this.__lastName}`;
		} else {
			return getTranslation("user.guest");
		}
	}

	/**
	 * Returns this users company.
	 * @returns {string | null} company or null if user is unregistered.
	 */
	get company() {
		if (this.isRegistered) {
			return this.__company;
		} else {
			return getTranslation("user.notSpecified");
		}
	}

	/**
	 * Returns this users group.
	 * @returns {Array} group names or empty array  if user is unregistered.
	 */
	get groups() {
		if (this.isRegistered) {
			return this.__groups;
		} else {
			return [];
		}
	}

	/**
	 * Sets this users preference of showing the greeter splashscreen tab.
	 * @param {boolean} _showGreeter to set.
	 */
	set showGreeter(_showGreeter) {
		if (this.showGreeter === _showGreeter) return;
		this.__showGreeter = _showGreeter;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {showGreeter: this.showGreeter});
	}

	/**
	 * Returns this users preference of showing the greeter splashscreen tab.
	 * @returns {boolean} showGreeter
	 */
	get showGreeter() {
		return this.__showGreeter;
	}

	/**
	 * Sets this users preference of showing the changelog splashscreen tab.
	 * @param {boolean} _showChangelog to set.
	 */
	set showChangelog(_showChangelog) {
		if (this.showChangelog === _showChangelog) return;
		this.__showChangelog = _showChangelog;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {showChangelog: this.showChangelog});
	}

	/**
	 * Returns this users preference of showing the changelog splashscreen tab.
	 * @returns {boolean} showChangelog
	 */
	get showChangelog() {
		return this.__showChangelog;
	}

	/**
	 * Sets this users preference of showing the recent projects splashscreen tab.
	 * @param {boolean} _showRecentProjects to set.
	 */
	set showRecentProjects(_showRecentProjects) {
		if (this.showRecentProjects === _showRecentProjects) return;
		this.__showRecentProjects = _showRecentProjects;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {showRecentProjects: this.showRecentProjects});
	}

	/**
	 * Returns this users preference of showing the recent projects splashscreen tab.
	 * @returns {boolean} showRecentProjects
	 */
	get showRecentProjects() {
		return this.__showRecentProjects;
	}

	/**
	 * Sets this users preferred recent project count in the recent projects splashscreen tab.
	 * @param {number} _recentProjectsCount to set.
	 */
	set recentProjectsCount(_recentProjectsCount) {
		if (this.recentProjectsCount === _recentProjectsCount) return;
		this.__recentProjectsCount = _recentProjectsCount;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {recentProjectsCount: this.recentProjectsCount});
	}

	/**
	 * Returns this users preferred recent project count in the recent projects splashscreen tab.
	 * @returns {number} recentProjectsCount
	 */
	get recentProjectsCount() {
		return this.__recentProjectsCount;
	}

	/**
	 * Sets this users preferred language.
	 * @param {languageEnum} _language to set.
	 */
	set language(_language) {
		if (this.__language === _language) return;
		this.__language = _language;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {language: this.language.type});
	}

	/**
	 * Returns this users preferred language.
	 * @returns {languageEnum} language
	 */
	get language() {
		return this.__language;
	}

	/**
	 * Sets this users preferred accordionLayout.
	 * @param {accordionLayoutEnum} _accordionLayout to set.
	 */
	set accordionLayout(_accordionLayout) {
		if (this.accordionLayout === _accordionLayout) return;
		this.__accordionLayout = _accordionLayout;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {accordionLayout: this.accordionLayout.type});
	}

	/**
	 * Returns this users preferred accordionLayout.
	 * @returns {accordionLayoutEnum.type} layout
	 */
	get accordionLayout() {
		return this.__accordionLayout;
	}

	/**
	 * Sets this users preferred device drawing mode.
	 * @param {drawModeEnum} _drawMode to set
	 */
	set drawMode(_drawMode) {
		if (this.drawMode === _drawMode) return;
		this.__drawMode = _drawMode;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {drawMode: this.drawMode.type});
	}

	/**
	 * Returns this users preferred device drawing mode.
	 * @returns {drawModeEnum.type} drawing mode
	 */
	get drawMode() {
		return this.__drawMode;
	}

	/**
	 * Sets this users preferred color scheme.
	 * @param {colorSchemeEnum} _colorScheme to set
	 */
	set colorScheme(_colorScheme) {
		if (this.colorScheme === _colorScheme) return;
		this.__colorScheme = _colorScheme;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {colorScheme: this.colorScheme.type});
	}

	/**
	 * Returns this users preferred color scheme.
	 * @returns {colorSchemeEnum.type} color scheme
	 */
	get colorScheme() {
		return this.__colorScheme;
	}

	/**
	 * Sets this users infrastructure configuration preference.
	 * @param {boolean} _createCabinet value to set createCabinet attribute.
	 */
	set createCabinet(_createCabinet) {
		if (this.createCabinet === _createCabinet) return;
		this.__createCabinet = _createCabinet;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {createCabinet: this.createCabinet});
	}

	/**
	 * Returns this users infrastructure configuration preference.
	 * @returns {boolean} createCabinet
	 */
	get createCabinet() {
		return this.__createCabinet;
	}

	/**
	 * Sets this users decentral motor configuration preference.
	 * @param {boolean} _preferDecentralMotor value to set preferDecentralMotors attribute.
	 */
	set preferDecentralMotors(_preferDecentralMotor) {
		if (this.preferDecentralMotors === _preferDecentralMotor) return;
		this.__preferDecentralMotors = _preferDecentralMotor;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {preferDecentralMotors: this.preferDecentralMotors});
	}

	/**
	 * Returns this users decentral motor configuration preference.
	 * @returns {boolean} preferDecentralMotors
	 */
	get preferDecentralMotors() {
		return this.__preferDecentralMotors;
	}

	/**
	 * Sets this users decentral consumers configuration preference.
	 * @param {boolean} _preferDecentralConsumers value to set preferDecentralConsumers attribute.
	 */
	set preferDecentralConsumers(_preferDecentralConsumers) {
		if (this.preferDecentralConsumers === _preferDecentralConsumers) return;
		this.__preferDecentralConsumers = _preferDecentralConsumers;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {preferDecentralConsumers: this.preferDecentralConsumers});
	}

	/**
	 * Returns this users decentral consumers configuration preference.
	 * @returns {boolean} preferDecentralConsumers
	 */
	get preferDecentralConsumers() {
		return this.__preferDecentralConsumers;
	}

	/**
	 * Sets this users fieldBus preferences.
	 * @param {Array<fieldBusEnum.key>} _fieldBusPreferences array containing fieldBuses.
	 */
	set fieldBusPreferences(_fieldBusPreferences) {
		if (isEqual(this.fieldBusPreferences, _fieldBusPreferences, true)) return;
		this.__fieldBusPreferences = _fieldBusPreferences;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {fieldBusPreferences: this.fieldBusPreferences});
	}

	/**
	 * Returns this users fieldBus preferences.
	 * @returns {Array<fieldBusEnum.key>} fieldBus preferences
	 */
	get fieldBusPreferences() {
		return this.__fieldBusPreferences;
	}

	/**
	 * Sets this users systemBus preferences.
	 * @param {Array<systemBusEnum.key>} _systemBusPreferences array containing systemBuses.
	 */
	set systemBusPreferences(_systemBusPreferences) {
		if (isEqual(this.systemBusPreferences, _systemBusPreferences, true)) return;
		this.__systemBusPreferences = _systemBusPreferences;
		if (this.isRegistered) BACKEND.updateUserSettings(this.__id, {systemBusPreferences: this.systemBusPreferences});
	}

	/**
	 * Returns this users systemBus preferences.
	 * @returns {Array<systemBusEnum.key>} systemBus preferences
	 */
	get systemBusPreferences() {
		return this.__systemBusPreferences;
	}
}
