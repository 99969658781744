import {DragListItemBase} from "./DragListItemBase";


/**
 * Represents a draggable ListItem with text-content.
 * @class DragListItemText
 * @augments DragListItemBase
 */
export class DragListItemText extends DragListItemBase {
	/**
	 * Creates an instance of DragListItemText.
	 * @param {object} _payload Object containing id and content of the ListItem.
	 * @param {string} _payload.id id of the ListItem.
	 * @param {string} _payload.content text of the ListItem.
	 * @memberof DragListItemText
	 */
	constructor(_payload) {
		super(_payload);
	}

	/**
	 * @param {object} _payload Object containing id and content of the ListItem.
	 * @returns {string} HTML code with given payload as innerText.
	 * @memberof DragListItemText
	 * @override
	 */
	__createPayload(_payload) {
		return `<p class="dragListItem-contentContainer-text" data-payload="${_payload.id}">${_payload.content}</p>`;
	}
}
