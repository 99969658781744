import {getDataNodeByUUID} from 												"./dataManager";
import {getDeviceSourceDataByDatabaseId} from						"./dataManager";
import {getPortByUUID} from 														"./dataManager";
import {getUniqueDataNodeByType} from 									"./dataManager";


/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} GroupNode
 * @typedef {string} uuid
 * @typedef {object} Port
 */
/* eslint-enable jsdoc/require-property */


/**
 * Does the layout of the given groupNode.
 * @param {GroupNode} _groupNode to lay out.
 */
export function layOutGroupNode(_groupNode) {
	const grid = new Grid(_groupNode.UUID);
	if (_groupNode.getType() === "InfrastructureNode") {
		const assemblyNodes = getUniqueDataNodeByType("ProjectNode").children.filter((child) => child.getType() == "AssemblyNode");
		assemblyNodes.forEach((assemblyNode) => {grid.add(new GridNode({level: 0, uuid: assemblyNode.UUID, ports: assemblyNode.ports, graphicsHeight: assemblyNode.graphics.activeGraphics.height}));});
	}
	_groupNode.children.forEach((dataNode) => {
		const tmpSourceData = getDeviceSourceDataByDatabaseId(dataNode.__databaseId);
		grid.add(new GridNode({level: tmpSourceData.level - 1, uuid: dataNode.UUID, ports: dataNode.ports, graphicsHeight: dataNode.graphics.activeGraphics.height}));
	});

	grid.layout();
}

/**
 * Data Structure to represent a groupNode and its children and lay out them with the help of various rules.
 */
class Grid {
	/**
	 * Creates an instance of Grid.
	 * @param {uuid} _groupNodeUUID uuid of the corresponding groupNode.
	 */
	constructor(_groupNodeUUID) {
		this.groupNodeUUID = _groupNodeUUID;
		this.gridNodes = [];
		this.grid = [[], [], [], [], [], [], [], [], []];
		this.offsets = [0, 0, -100, 0, -100, 250, -240, 0, 0];
	}

	/**
	 * runs all active layout rules, sadly the order does matter.
	 */
	layout() {
		this.layoutConsumersNotConnected();
		this.layoutConsumersNotConnectedToLevel1();
		this.layoutPassiveDistributors();
		this.layoutConsumersConnectedToLevel1();
		this.layoutRibbonAdapters();
		this.layoutRemainingNodes();

		this.alignPortsOfConsumersConnectedToSouthPort();

		this.setInfrastructureXOffsets();

		this.assignIndices();

		this.removeEmptyGridRows();
		this.assignCoordinates();
	}

	/**
	 * Adds a GridNode to list of all nodes to lay out.
	 * @param {GridNode} _gridNode grid node to include in layout.
	 */
	add(_gridNode) {
		this.gridNodes.push(_gridNode);
	}

	/**
	 * Adds a GridNode to grid structure.
	 * @param {number} _level grid node's level in device hierarchy.
	 * @param {GridNode} _gridNode to add.
	 */
	add2Grid(_level, _gridNode) {
		_gridNode.arranged = true;
		this.grid[_level].push(_gridNode);
	}

	/**
	 * Searches a gridNode with the given and returns it.
	 * @param {uuid} _uuid of the gridNode to find.
	 * @returns {GridNode} gridNode according to given uuid.
	 */
	getGridNodeByUUID(_uuid) {
		const result = this.gridNodes.find((gridNode) => gridNode.uuid === _uuid);
		return result ? result : false;
	}

	/**
	 * Returns all gridNodes with a given level.
	 * @param {number} _level in device hierarchy.
	 * @returns {Array<GridNode>} all grid nodes with the given level.
	 */
	getNodesOfLevel(_level) {
		return this.gridNodes.filter((gridNode) => gridNode.level == _level);
	}

	/**
	 * Test if the given gridNode has a connection to a device from the given level.
	 * @param {GridNode} _gridNode to test.
	 * @param {number} _level in device hierarchy.
	 * @returns {boolean} true when the gridNode has a connection to a device from the given level, false if not.
	 */
	connectedToLevel(_gridNode, _level) {
		const level = this.getNodesOfLevel(_level);
		let result = false;
		_gridNode.connections.forEach((connection) => {
			if (level.indexOf(this.getGridNodeByUUID(connection.deviceUUID)) !== -1) result = true;
		});
		return result;
	}

	/**
	 * Adds all nodes to the grid, that are not included in any active rule.
	 * @warning has to be the last layout rule called.
	 */
	layoutRemainingNodes() {
		const notArranged = this.gridNodes.filter((node) => !node.arranged);
		notArranged.forEach((node) => this.add2Grid(node.level, node));
	}

	/**
	 * Adds all nodes of level 0, that are not connected to any other device, to the grid.
	 * @warning Positions nodes depending on the call of it.
	 */
	layoutConsumersNotConnected() {
		const level0Nodes = this.getNodesOfLevel(0);
		const notConnected = level0Nodes.filter((node) => node.connections.length === 0);
		notConnected.forEach((node) => {
			this.add2Grid(0, node);
			this.add2Grid(1, new FillerGridNode());
		});
	}

	/**
	 * Adds all nodes of level 0, that are connected to a device with level > 2, to the grid.
	 * For each of them add a FillerGridNode, as a spacer, to level 1 and add the device its connected to to level 4.
	 * @warning Positions nodes depending on the call of it.
	 */
	layoutConsumersNotConnectedToLevel1() {
		if (getDataNodeByUUID(this.groupNodeUUID).getType() === "InfrastructureNode") return;
		const level0Nodes = this.getNodesOfLevel(0);
		const notConnectedToLevel1 = level0Nodes.filter((node) => !(this.connectedToLevel(node, 1)) && node.connections.length !== 0);
		notConnectedToLevel1.forEach((node) => {
			const connectedTo = this.getGridNodeByUUID(node.connections[0].deviceUUID);
			this.add2Grid(0, node);
			this.add2Grid(1, new FillerGridNode());
			this.add2Grid(4, (this.grid[4].indexOf(connectedTo) == -1) ? connectedTo : new FillerGridNode());
		});
	}

	/**
	 * Adds and positions all nodes of level 0 and 1 that are connected to each other.
	 * 1) If a level 1 node has children at ports with the orientation West position them on the right side, add fillerGridNodes above.
	 * 		Position the level 1 Node and a fillerGridNode below.
	 * 		If a level 1 node has children at ports with the orientation East position them on the left side, add fillerGridNodes above.
	 * 2) If a level 1 node has a child at a port with the orientation South position both above each other.
	 * @warning Atm there are no devices which have children at ports with the orientation South and also East|West. They could break this rule.
	 */
	layoutConsumersConnectedToLevel1() {
		const level0Nodes = this.getNodesOfLevel(0);
		const level1Nodes = this.getNodesOfLevel(1);
		level1Nodes.forEach((node) => {
			const westernChildren = [];
			let southernChild = null;
			const easternChildren = [];
			node.connections.forEach((connection) => {
				if (level0Nodes.indexOf(this.getGridNodeByUUID(connection.deviceUUID)) !== -1) {
					if (getPortByUUID(connection.portUUID).graphics.activeGraphics.orientation === "SOUTH") {
						southernChild = this.getGridNodeByUUID(connection.deviceUUID);
					}
					if (getPortByUUID(connection.portUUID).graphics.activeGraphics.orientation === "EAST") {
						easternChildren.push(this.getGridNodeByUUID(connection.deviceUUID));
					}
					if (getPortByUUID(connection.portUUID).graphics.activeGraphics.orientation === "WEST") {
						westernChildren.push(this.getGridNodeByUUID(connection.deviceUUID));
					}
				}
			});
			westernChildren.forEach((child) => {
				this.add2Grid(0, child);
				this.add2Grid(1, new FillerGridNode());
			});
			southernChild ? this.add2Grid(0, southernChild) : this.add2Grid(0, new FillerGridNode());
			this.add2Grid(1, node);
			easternChildren.forEach((child) => {
				this.add2Grid(0, child);
				this.add2Grid(1, new FillerGridNode());
			});
		});
	}

	/**
	 * Adds and positions all level 2 nodes connected to motor controllers (passive distributors).
	 * Positions them as first nodes in level 1 and sets their yOffset to 100, add a fillerGridNode below.
	 * @warning has to be called as first rule that affects level 1.
	 */
	layoutPassiveDistributors() {
		const level2Nodes = this.getNodesOfLevel(2);
		level2Nodes.forEach((node) => {
			node.connections.forEach((connection) => {
				const tmpConnectedDevice = getDataNodeByUUID(connection.deviceUUID);
				if (tmpConnectedDevice.subGroup === "accordionManager.protect-switch-400-motors" || tmpConnectedDevice.subGroup === "accordionManager.protect-switch-400-motors") {
					if (!node.arranged) {
						this.add2Grid(1, node);
						this.add2Grid(0, new FillerGridNode());
						this.add2Grid(4, new FillerGridNode());
						node.yOffset = 100;
					}
				}
			});
		});
	}

	/**
	 * Adds and Positions all level 2 nodes connected to a protection and switching 24V or measure and monitor device (ribbon adapters).
	 * Positions the node on the grid position left from the connected device on level 2, fills the space to its left with fillerGridNodes.
	 * @warning has to be called after all rules that affect level 1.
	 */
	layoutRibbonAdapters() {
		const level2Nodes = this.getNodesOfLevel(2);
		level2Nodes.forEach((node) => {
			node.connections.forEach((connection) => {
				const tmpConnectedDevice = getDataNodeByUUID(connection.deviceUUID);
				if (tmpConnectedDevice.subGroup === "accordionManager.protect-switch-24" || tmpConnectedDevice.subGroup === "accordionManager.measure-monitor") {
					if (!node.arranged) {
						const tmpIoBox = this.getGridNodeByUUID(tmpConnectedDevice.UUID);
						const tmpIoBoxIndex = this.grid[1].indexOf(tmpIoBox);
						while (this.grid[2].length < tmpIoBoxIndex - 1) {this.add2Grid(2, new FillerGridNode());}
						this.add2Grid(2, node);
					}
				}
			});
		});
	}

	/**
	 * Sets all xOffsets of level 0 nodes, that are connected to a South port, to align their ports their connected port.
	 * @warning Has to be called after all layout functions.
	 */
	alignPortsOfConsumersConnectedToSouthPort() {
		const level0Nodes = this.getNodesOfLevel(0);
		level0Nodes.forEach((node) => {
			if (node.connections?.length == 1) {
				const portActiveGraphics = getPortByUUID(node.connections[0].portUUID).graphics.activeGraphics;
				const associatedPortActiveGraphics = node.connections[0].associatedPort.graphics.activeGraphics;
				const nodeActiveGraphics = getDataNodeByUUID(node.uuid).graphics.activeGraphics;
				const associatedNodeActiveGraphics = getDataNodeByUUID(node.connections[0].deviceUUID).graphics.activeGraphics;
				if (associatedPortActiveGraphics.orientation == "SOUTH" && portActiveGraphics.orientation == "NORTH") {
					node.xOffset = -(nodeActiveGraphics.width * portActiveGraphics.position.x - associatedNodeActiveGraphics.width * associatedPortActiveGraphics.position.x);
				}
				// calculate offset for level 4 parentDevices
				if (this.connectedToLevel(node, 4)) {
					node.xOffset = -100;
				}
			}
		});
	}

	/**
	 * Sets the infrastructure offset template if the groupNode to lay out is an InfrastructureNode.
	 */
	setInfrastructureXOffsets() {
		if (getDataNodeByUUID(this.groupNodeUUID).getType() === "InfrastructureNode") this.offsets = [0, 0, 0, -360, 120, 250, 200, 1, -240];
	}

	/**
	 * Assigns x and y indices to all grid nodes.
	 */
	assignIndices() {
		this.grid.forEach((gridRow) => {
			gridRow.forEach((gridNode) => {
				gridNode.x = gridRow.indexOf(gridNode);
				gridNode.y = this.grid.indexOf(gridRow);
			});
		});
	}

	/**
	 * Decrements all gridNode's y-index above an empty gridRow for any empty gridRow detected.
	 * @warning has to be called after assignIndices.
	 */
	removeEmptyGridRows() {
		for (let gridRowIndex = 0; gridRowIndex < this.grid.length; gridRowIndex++) {
			if (this.grid[gridRowIndex].length === 0) {
				for (let rowIndex = gridRowIndex; rowIndex < this.grid.length; rowIndex++) {
					this.grid[rowIndex].forEach((gridNode) => gridNode.y = gridNode.y - 1);
				}
			}
		}
	}

	/**
	 * Sets canvas position of every dataNode with a corresponding gridNode.
	 */
	assignCoordinates() {
		this.grid.forEach((gridRow) => {
			gridRow.forEach((gridNode) => {
				if (!(gridNode instanceof FillerGridNode)) {
					const tmpDataNode = getDataNodeByUUID(gridNode.uuid);
					const graphicsHeight = tmpDataNode.graphics.activeGraphics.height;
					const gridNodeXIndex = this.grid.indexOf(gridRow);
					tmpDataNode.setPosition({x: gridNode.x * 210 + this.offsets[gridNodeXIndex] + gridNode.__xOffset, y: 500 - graphicsHeight - gridNode.y * 200 + gridNode.yOffset});
				}
			});
		});
	}
}

/**
 * Represents a dataNode for the layout process.
 */
export class GridNode {
	/**
	 * Creates an instance of GridNode.
	 * @param {object} _data all relevant details about a dataNode to do the layout.
	 * @param {number} _data.level of in the device hierarchy.
	 * @param {number} _data.graphicsHeight of the dataNodes activeGraphics.
	 * @param {uuid} _data.uuid of the dataNode.
	 * @param {Array<Port>} _data.ports of the dataNode.
	 */
	constructor(_data) {
		this.level = _data.level;
		this.graphicsHeight = _data.graphicsHeight;
		this.y = null;
		this.x = null;
		this.__xOffset = 0;
		this.yOffset = 0;
		this.uuid = _data.uuid;
		this.connections = [];
		const connectedPorts = _data.ports.filter((port) => port.isConnectedTo);
		this.connections = connectedPorts.map((port) => {
			const connection = port.isConnectedTo.parent;
			const tmpDevicePort = (port.side === "TARGET") ? connection.targetPort.isConnectedTo : connection.sourcePort.isConnectedTo;
			return {
				portUUID: port.UUID,
				deviceUUID: tmpDevicePort.parent.UUID,
				associatedPort: tmpDevicePort,
			};
		});
	}

	/**
	 * Adds a given value to the current x offset.
	 * @param {number} _xOffset to add to the current x offset.
	 */
	set xOffset(_xOffset) {
		this.__xOffset = this.__xOffset + _xOffset;
	}
}

/**
 * Spacer to block a grid position.
 */
class FillerGridNode {
	/**
	 * Creates an instance of FillerGridNode.
	 */
	constructor() {
		this.uuid = null;
	}
}
