import {getDataNodeByUUID} from								"../dataManager";
import {GLOBALEVENTMANAGER} from							"../applicationManager";
import {PdfLineNode} from											"./pdfLineNode";
import {PdfPage} from													"./pdfPage";
import {PdfSymbolNode} from										"./pdfSymbolNode";
import "svg2pdf.js";
import {jsPDF} from "jspdf";


/**
 * Central structure for exporting drawings to PDF.
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *
 * AUTHOR(S):
 *		Frederic Hoffmann
 *		Christian Lange
 */


/**
 * Central structure for exporting drawings to PDF.
 * @export
 * @class PdfController
 */
export class PdfController {
	/**
	 * Creates an instance of PdfController.
	 * @memberof PdfController
	 */
	constructor() {
		this.canvasContainerId = "pdfExportWrapper";
		this.pages = [];
		this.addPdfPage("root", false);

		GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeCreated", (node) => this.pdfNodeFactory(node));
		GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeSendToLimbo", (node) => this.deletePdfNode(node));
		GLOBALEVENTMANAGER.addHandler("eDTM_DataNodeRelocated", (target, node) => this.relocatePdfNode(target, node));
	}

	/**
	 * Factory method that creates a pdfNode instance according to the type of _dataNode.
	 * @param {DataNode} _dataNode whose pdfNode representation should be created
	 * @memberof PdfController
	 */
	pdfNodeFactory(_dataNode) {
		switch (_dataNode.getType()) {
			case "ProjectNode":
				break;
			case "LimboNode":
				break;
			case "InfrastructureNode":
				this.addPdfPage(_dataNode.UUID, true, this.canvasContainerId);
				break;
			case "TrashNode":
				this.addPdfPage(_dataNode.UUID, false, this.canvasContainerId);
				break;
			case "AssemblyNode":
				this.addPdfPage(_dataNode.UUID, true, this.canvasContainerId);
				this.getPdfPageByUUID("infr").addNode(new PdfSymbolNode(_dataNode.UUID));
				break;
			case "UnitNode":
				this.getPdfPageByUUID("root").addNode(new PdfSymbolNode(_dataNode.UUID));
				break;
			case "DeviceNode":
				this.getPdfPageByUUID("root").addNode(new PdfSymbolNode(_dataNode.UUID));
				break;
			case "CableNode":
				this.getPdfPageByUUID("root").addNode(new PdfLineNode(_dataNode.UUID));
				break;
			default:
				throw new Error(`unknown node type "${_dataNode.getType()}"`);
		}
	}

	/**
	 * Getter for pdfPages Array.
	 * @readonly
	 * @memberof PdfController
	 */
	get printablePages() {
		const tmpPages = this.pages.filter((page) => {
			return page.printable == true && page.trashed == false;
		});
		return tmpPages;
	}

	/**
	 * Creates a pdfPage and adds it to pages array of PdfController.
	 * @param {String} _UUID of the pdfPage one wants to create
	 * @param	{Boolean} _printable indicates if a page can be exported to pdf
	 * @param {String} _canvasContainerId to buffer pdfPage into
	 * @memberof PdfController
	 */
	addPdfPage(_UUID, _printable, _canvasContainerId) {
		this.pages.push(new PdfPage(_UUID, _printable, _canvasContainerId));
	}

	/**
	 * Removes a pdfPage from pages array of PdfController.
	 * @param {String} _UUID of the pdfPage one wants to remove
	 * @memberof PdfController
	 */
	removePdfPage(_UUID) {
		const tmpPage = this.getPdfPageByUUID(_UUID);
		if (tmpPage) {
			const tmpIndex = this.pages.indexOf(tmpPage);
			this.pages.splice(tmpIndex, 1);
			return;
		} else {
			throw new Error(`PdfPage with UUID "${_UUID}" not found`);
		}
	}

	/**
	 * Searches for a specific id in pages array and returns the corresponding pdfPage.
	 * @param {String} _UUID of the pdfPage one wants to find
	 * @returns {PdfPage | false} if the pdfPage is contained in pages array it's returned, otherwise false is returned
	 * @memberof PdfController
	 */
	getPdfPageByUUID(_UUID) {
		let result = false;
		this.pages.forEach((page) => {
			if (page.UUID === _UUID) result = page;
		});
		return result;
	}

	/**
	 * Searches all pages for a specific pdfNode id and returns the page containing it.
	 * @param {String} _UUID of pdfNode to find the page containing it
	 * @returns {PdfPage | false} if the pdfNode is contained in any page the page is returned, if no page contains the pdfNode false is returned
	 * @memberof PdfController
	 */
	getPdfPageByPdfNodeUUID(_UUID) {
		let result = false;
		this.pages.forEach((page) => {
			if (page.getPdfNodeByUUID(_UUID)) result = page;
		});
		return result;
	}

	/**
	 * Searches all pages for a specific pdfNode and removes it.
	 * @param {DataNode} _dataNode that gets removed
	 * @memberof PdfController
	 */
	deletePdfNode(_dataNode) {
		const tmpDataNodeType = _dataNode.getType();
		if (!(tmpDataNodeType === "DeviceNode" || tmpDataNodeType === "UnitNode" || tmpDataNodeType === "CableNode")) {
			if (tmpDataNodeType === "AssemblyNode") {
				this.removePdfPage(_dataNode.UUID);
				this.getPdfPageByUUID("infr").removeNode(_dataNode);
			}
			return;
		}
		const tmpPage = this.getPdfPageByPdfNodeUUID(_dataNode.UUID);
		tmpPage.removeNode(_dataNode);
	}

	/**
	 * Relocates a pdfNode to the target page.
	 * @param {String} _targetPageUUID of target pdfPage
	 * @param {String} _dataNodeUUID of the pdfNode to move
	 * @memberof PdfController
	 */
	relocatePdfNode(_targetPageUUID, _dataNodeUUID) {
		const tmpDataNode = getDataNodeByUUID(_dataNodeUUID);
		const tmpDataNodeType = tmpDataNode.getType();
		const tmpTargetPage = this.getPdfPageByUUID(_targetPageUUID);

		if ((tmpDataNodeType === "DeviceNode" || tmpDataNodeType === "UnitNode" || tmpDataNodeType === "CableNode")) {
			const tmpSourcePage = this.getPdfPageByPdfNodeUUID(_dataNodeUUID);
			const tmpNode = tmpSourcePage.getPdfNodeByUUID(_dataNodeUUID);
			tmpSourcePage.removeNode(tmpDataNode);
			tmpTargetPage.addNode(tmpNode);
		}
	}

	/**
	 * Creates a PDF document thats saved afterwards.
	 * @memberof PdfController
	 * @param {Array<PdfPage>} _pagesToDraw array with every page the user wants to draw
	 * @param {String} _drawMode defines whether images or symbols are desired in drawing
	 * @param {Object} _titleBlockContent contains all contents for titleBlock
	 */
	async exportPdf(_pagesToDraw, _drawMode, _titleBlockContent) {
		// sort _pagesToDraw in advance, since the page number has to be set before calling draw()
		// maybe move the pdfObjects meta data collection out here
		const pdfObjects = await _drawEachPageWithItsPageNumber(_pagesToDraw, _drawMode, _titleBlockContent);
		const canvasObjects = _extractCanvasRelevantInfo(pdfObjects);

		const fileName = _createFileName(_titleBlockContent.projectTitle, _titleBlockContent.drawingId);

		_renderPdf(canvasObjects, fileName);


		/**
		 * Adds the page number to every titleBlock and draws the page.
		 * @param {Array<PdfPage>} _pagesToDraw array with every page the user wants to draw
		 * @param {String} _drawMode defines whether images or symbols are desired in drawing
		 * @param {Object} _titleBlockContent contains all contents for titleBlock
		 * @returns {Array<Object>} array with every page's canvasInfo, id, name, and reference designator
		 */
		async function _drawEachPageWithItsPageNumber(_pagesToDraw, _drawMode, _titleBlockContent) {
			const pdfObjects = [];
			for (let i = 0; i < _pagesToDraw.length; i++) {
				_titleBlockContent.page = `${i+1}/${_pagesToDraw.length}`;
				pdfObjects.push(_pagesToDraw[i].draw(_drawMode, _titleBlockContent));
			}
			return await Promise.all(pdfObjects);
		}

		/**
		 * Extracts canvas relevantInfo into a new array.
		 * @param {Array<Object>} _pdfObjects array with every page's canvasInfo, id, name, and reference designator
		 * @returns {Array<Object>} array with every page's canvasInfo
		 */
		function _extractCanvasRelevantInfo(_pdfObjects) {
			const canvasObjects = [];
			for (let i = 0; i < _pdfObjects.length; i++) {
				canvasObjects.push(_pdfObjects[i].canvas);
			}
			return canvasObjects;
		}

		/**
		 * Creates file name of format: projectTitle_drawingId.pdf.
		 * DrawingId is omitted if none is set.
		 * @param {String} _title to set
		 * @param {String} _drawingId to set
		 * @returns {String} fileName
		 * @memberof PdfController
		 */
		function _createFileName(_title, _drawingId) {
			return `${_title}${_drawingId ? `_${_drawingId}` : ""}.pdf`;
		}

		/**
		 * Renders PDF document.
		 * @param {Array<Object>} _canvasObjects all svgCanvasObjects that should be rendered on pdf
		 * @param {String} _fileName name of output file
		 * @memberof PdfController
		 */
		async function _renderPdf(_canvasObjects, _fileName) {
			let doc;
			const firstPage = _canvasObjects[0];
			const widthOfFirstPage = firstPage.width + 2*firstPage.padding.horizontal;
			const heightOfFirstPage = firstPage.height + 2*firstPage.padding.vertical;

			// eslint-disable-next-line new-cap
			if (widthOfFirstPage > heightOfFirstPage) doc = new jsPDF("1", "pt", [widthOfFirstPage, heightOfFirstPage]);
			// eslint-disable-next-line new-cap
			else 																			doc = new jsPDF("0", "pt", [widthOfFirstPage, heightOfFirstPage]);

			await _renderMultiPagePdf(_canvasObjects, doc);
			doc.save(`${_fileName}`);

			const div = document.getElementById("pdfExportWrapper");
			while (div.firstChild) {
				div.removeChild(div.firstChild);
			}

			/**
			 * Renders multi page PDF document.
			 * @param {Array<Object>} _canvasObjects all svgCanvasObjects that should be rendered on pdf
			 * @param {jsPdf} _doc	jsPdf document
			 */
			async function _renderMultiPagePdf(_canvasObjects, _doc) {
				for (let index = 0; index < _canvasObjects.length; index++) {
					const canvas = _canvasObjects[index];
					const width = canvas.width + 2*canvas.padding.horizontal;
					const height = canvas.height + 2*canvas.padding.vertical;
					const svgElement = document.getElementById(canvas.id);
					if (_canvasObjects.indexOf(canvas) != 0) {
						if (width > height) _doc.addPage([width, height], "1");
						else 								_doc.addPage([width, height], "0");
					}
					// It is not possible to avoid this await in loop since each call of doc.svg has to be finished before the next iteration
					// eslint-disable-next-line no-await-in-loop
					await _doc.svg(svgElement, {x: 0 + canvas.padding.horizontal, y: 0 + canvas.padding.vertical, width: width, height: height});
				}
			}
		}
	}
}
