/**
 * Provides anything needed for marking jspPorts and jspEdges during manual cable creation
 *
 * MARKING CONDITIONS:
 *
 *		While dragging a new jspEdge, all compatible jspPorts get marked
 *		While dragging a jspNewEdge, when hovering over a jspDropPort with an existing jspOldEdge, jspOldEdge gets marked
 *		When dragging a jspNewEdge from a jspDragPort with an existing jspOldEdge, jspOldEdge gets marked
 */

//TODO (un)markPorts & (un)MarkEdges should be a method of some kind of jspPort/jspEdge-class-wrapper, like jspPort/jspEdge.mark(boolean)
//TODO JspPort & JspEdges don't exist as a type (yet)

//JIRA [LQSK-1394] Markedges Fehler für bestehende Verbindung

import {getJspDomElementByUUID, getJspPortByUUID} from "./jspManager";

//! ### Just an interim fix; once jsp stuff is moved to typescript, use the internally provided jsp types ###
type JspPort = {
	data: {
		UUID: string;
		linkIsActive: boolean;
	};
};

type JspEdge = {
	data: {
		UUID: string;
	};
};

type JspNode = {
	data: {
		UUID: string;
	};
};

//! #################################################################

/**
 * Highlight all provided jspUnitNodes.
 * Used to mark all jspUnitNodes during manual cable creation.
 * @warning UnitNodes are a universally viable target during manual cable creation.
 */
export function markUnitNodes(_jspUnitNodes: JspNode[]): void {
	_jspUnitNodes.forEach((_jspUnitNode) => {
		document.querySelector(`[data-uuid="${_jspUnitNode.data.UUID}"].canvasUnitNode`)?.classList.add("marked");
	});
}

/**
 * Remove highlight from (all) jspUnitNodes.
 * If argument is passed, will only remove highlight from provided _jspUnitNodes.
 * Used to cancel marking of jspUnitNodes during manual cable creation.
 */
export function unMarkUnitNodes(_jspUnitNodes?: JspNode[]): void {
	if (_jspUnitNodes) {
		_jspUnitNodes.forEach((_jspUnitNode) => {
			document.querySelector(`[data-uuid="${_jspUnitNode.data.UUID}"].canvasUnitNode`)?.classList.remove("marked");
		});
	} else {
		document.querySelectorAll(".canvasUnitNode.marked").forEach((_element) => {
			_element.classList.remove("marked");
		});
	}
}

/**
 * Highlight all provided jspPorts.
 * Used to mark all compatible ports during manual cable creation.
 */
export function markPorts(_jspPorts: JspPort[]): void {
	_jspPorts.forEach((_jspPort) => {
		getJspDomElementByUUID(_jspPort.data.UUID)?.classList.add("marked");
	});
}

/**
 * Remove highlight from (all) jspPorts.
 * If argument is passed, will only remove highlight from provided _jspPorts.
 * Used to cancel marking of compatible ports during manual cable creation.
 */
export function unMarkPorts(_jspPorts?: JspPort[]): void {
	if (_jspPorts) {
		_jspPorts.forEach((_jspPort) => {
			getJspDomElementByUUID(_jspPort.data.UUID)?.classList.remove("marked");
		});
	} else {
		document.querySelectorAll(".base-port.marked").forEach((_element) => {
			_element.classList.remove("marked");
		});
	}
}

/**
 * Highlight provided jspEdge.
 * Used to mark edges that will be removed during manual cable creation.
 */
export function markEdge(_jspEdge: JspEdge): void {
	getJspDomElementByUUID(_jspEdge.data.UUID)?.classList.add("marked");
}

/**
 * Remove highlight from jspEdge.
 * Used to cancel marking of edges that will be removed during manual cable creation.
 */
export function unMarkEdge(_jspEdge: JspEdge): void {
	getJspDomElementByUUID(_jspEdge.data.UUID)?.classList.remove("marked");
}

/**
 * Highlight link on provided port.
 * Used to mark link (to edges) that will be removed during manual cable creation.
 * @warning links (to edges) are technically graphics inside jspPorts, hence jspPorts get handled here
 */
export function markLink(_jspPort: JspPort): void {
	if (!_jspPort.data.linkIsActive) return;
	getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-link-container")?.classList.add("marked");
}

/**
 * Remove link highlight on provided port.
 * Used to cancel marking of links (to edges) that will be removed during manual cable creation.
 * If argument is passed, will only remove highlight from provided _jspPort.
 * @warning links (to edges) are technically graphics inside jspPorts, hence jspPorts get handled here
 */
export function unMarkLink(_jspPort?: JspPort): void {
	if (_jspPort) {
		if (!_jspPort.data.linkIsActive) return;
		getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-link-container")?.classList.remove("marked");
	} else {
		document.querySelectorAll(".base-port-link-container.marked").forEach((_element) => {
			_element.classList.remove("marked");
		});
	}
}

const markEdgesHoverJspPorts: JspPort[] = []; // small ugly helper for removing 'ports-hover-markEdges-eventHandlers'

/**
 * Set handlers for mouseenter/mouseleave on jspPorts 🔼 element to trigger (un)markEdges.
 * Used to mark connected edges when hovering a jspDropPort.
 */
export function setMarkEdgesHandlers(_jspPorts: JspPort[]): void {
	markEdgesHoverJspPorts.push(..._jspPorts);

	markEdgesHoverJspPorts.forEach((_jspPort) => {
		const jspDomPortRect = getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-direction") as HTMLElement; // casting to HTMLElement would be unnecessary if getJspDomElementByUUID would return a correct type
		jspDomPortRect.addEventListener("mouseenter", markEdgesOnPortMouseEnter);
		jspDomPortRect.addEventListener("mouseleave", unMarkEdgesOnPortMouseLeave);
	});
}

/**
 * Remove handlers for mouseenter/mouseleave on jspPorts 🔼 element to trigger (un)markEdges.
 * Used to cancel marking of connected edges when hovering a jspDropPort.
 */
export function removeAllMarkEdgesHandlers(): void {
	markEdgesHoverJspPorts.forEach((_jspPort) => {
		const jspDomPortRect = getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-direction") as HTMLElement; // casting to HTMLElement would be unnecessary if getJspDomElementByUUID would return a correct type
		jspDomPortRect.removeEventListener("mouseenter", markEdgesOnPortMouseEnter);
		jspDomPortRect.removeEventListener("mouseleave", unMarkEdgesOnPortMouseLeave);
	});

	markEdgesHoverJspPorts.length = 0;
}

/**
 * Callback for jspPort mouseenter.
 * Used to mark connected edges when mouse-entering their parentPort.
 */
function markEdgesOnPortMouseEnter(_event: MouseEvent): void {
	const jspDomPort = (_event.target as HTMLImageElement).parentElement;
	const jspPort = getJspPortByUUID(jspDomPort.dataset.uuid);
	const connectedJspEdge = jspPort.getEdges()[0]; // always only one edge per port for now in the konfigurator
	markEdge(connectedJspEdge);
}

/**
 * Callback for jspPort mouseleave.
 * Used to cancel marking connected edges when mouse-leaving their parentPort.
 */
function unMarkEdgesOnPortMouseLeave(_event: MouseEvent): void {
	const jspDomPort = (_event.target as HTMLImageElement).parentElement;
	const jspPort = getJspPortByUUID(jspDomPort.dataset.uuid);
	const connectedJspEdge = jspPort.getEdges()[0]; // always only one edge per port for now in the konfigurator
	unMarkEdge(connectedJspEdge);
}

const markLinksHoverJspPorts: JspPort[] = []; // small ugly helper for removing 'ports-hover-markLinks-eventHandlers'

/**
 * Set handlers for mouseenter/mouseleave on jspPorts 🔼 element to trigger (un)markLinks.
 * Used to mark active links when hovering a jspDropPort.
 */
export function setMarkLinksHandlers(_jspPorts: JspPort[]): void {
	markLinksHoverJspPorts.push(..._jspPorts);

	markLinksHoverJspPorts.forEach((_jspPort) => {
		const jspDomPortRect = getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-direction") as HTMLElement; // casting to HTMLElement would be unnecessary if getJspDomElementByUUID would return a correct type
		jspDomPortRect.addEventListener("mouseenter", markLinksOnPortMouseEnter);
		jspDomPortRect.addEventListener("mouseleave", unMarkLinksOnPortMouseLeave);
	});
}

/**
 * Remove handlers for mouseenter/mouseleave on jspPorts 🔼 element to trigger (un)markLinks.
 * Used to cancel marking active links when hovering a jspDropPort.
 */
export function removeAllMarkLinksHandlers(): void {
	markLinksHoverJspPorts.forEach((_jspPort) => {
		const jspDomPortRect = getJspDomElementByUUID(_jspPort.data.UUID)?.querySelector(".base-port-direction") as HTMLElement; // casting to HTMLElement would be unnecessary if getJspDomElementByUUID would return a correct type
		jspDomPortRect.removeEventListener("mouseenter", markLinksOnPortMouseEnter);
		jspDomPortRect.removeEventListener("mouseleave", unMarkLinksOnPortMouseLeave);
	});

	markLinksHoverJspPorts.length = 0;
}

/**
 * Callback for jspPort mouseenter.
 * Used to mark active links when mouse-entering their parentPort.
 */
function markLinksOnPortMouseEnter(_event: MouseEvent): void {
	const jspDomPort = (_event.target as HTMLImageElement).parentElement;
	const jspPort = getJspPortByUUID(jspDomPort.dataset.uuid);
	markLink(jspPort);
}

/**
 * Callback for jspPort mouseleave.
 * Used to cancel marking active links when mouse-leaving their parentPort.
 */
function unMarkLinksOnPortMouseLeave(_event: MouseEvent): void {
	const jspDomPort = (_event.target as HTMLImageElement).parentElement;
	const jspPort = getJspPortByUUID(jspDomPort.dataset.uuid);
	unMarkLink(jspPort);
}
