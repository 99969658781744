import {GLOBALEVENTMANAGER} from										"./applicationManager";
import {eUpdateActiveNode} from											"./dataManager";
import {getDataNodeByUUID} from											"./dataManager";
import {getUniqueDataNodeByType} from								"./dataManager";
import {searchArrayForElementByKeyValuePair} from		"./helper";
import {makeFirstLetterLowerCase} from							"./helper";

// WEBPACK import of slidr
import slidr from "../../non-node_modules/slidr/slidr";

/** Handles display and manipulation of canvases
 *
 * UTILIZING:
 *		slidr for canvas switch animation, details under https://github.com/bchanx/slidr
 *		(atm deactivated) html2canvas for capturing canvas images that are displayed in projectview, details under https://html2canvas.hertzen.com/documentation
 *
 * TODO:
 *		some kind of visual feedback when resorting the canvasPreviews (maybe fading the moving element in/out?)
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */


export const canvasRoot = {													// top level canvasManager object (analogous to dataRoot of dataManager)
	canvasGlobalDOMContainer: null,										// top level DOM canvas container (defined in index.html)
	canvasPreviewLocalDOMContainer: {},								// DOM container for AssemblyCanvasPreviews (should usually be the projectCanvas, it's therefore atm set by projectCanvas constructor)
	canvasList: [],																		// array of all canvasses disregarding of their type
	canvasPreviewList: [],														// array of all AssemblyCanvasPreviews
	activeCanvas: null,
	slider: {},																				// global sliderObject (canvas switch animation)
};


/** Standard initialization routine (mainly setting event handlers) */
export function initializeCanvasManager() {
	canvasRoot.canvasGlobalDOMContainer = document.getElementById("canvasContainer");
	createSlider();
	GLOBALEVENTMANAGER.addHandler("eCM_Click", eUpdateActiveNode);		// hook click on assemblyCanvasPreviews to UpdateActiveNode
}

/** Standard reset routine (setting Manager back to initial values etc..) */
function resetCanvasManager() {
	console.debug(canvasRoot);
}


/** Base class representing a canvas object- */
class CanvasBase {
	/** standard constructor
	 * @param  {DataNode} _dataGroupNode that gets represented by this canvas
	 */
	constructor(_dataGroupNode) {
		this.UUID = _dataGroupNode.UUID;
		this.name = _dataGroupNode.getName();
		this.referenceDesignator = null;
		this.container = null;																													// stores associated DOM canvasDiv-element
		this.infoSubContainer = null;																										// stores div container for canvasSpan elements
		this.infoNameSpan = null;																												// stores associated DOM canvasSpan-element
		this.infoReferenceDesignatorSpan = null;																				// stores associated DOM canvasSpan-element
		this.miniView = null;																														// stores associated DOM miniViewDiv-element
		this.createDomStructure();
		canvasRoot.canvasList.push(this);																								// TODO move to canvasRoot.addCanvas
	}


	/** Returns the type/name of this class
	 * @throws  {Error} indicating that an abstract method was called
	 */
	getType() {
		throw new Error("This is an abstract base class method!");
	}

	/** Update this canvas name on dataNode renaming
	 * @param  {String} _newName to set
	 */
	updateName(_newName) {
		this.name = _newName;
		this.infoNameSpan.innerHTML = this.name;
	}

	/** Update this canvas referenceDesignator on dataNode referenceDesignator change
	 * @param  {String} _newReferenceDesignator to set
	 */
	updateReferenceDesignator(_newReferenceDesignator) {
		this.referenceDesignator = _newReferenceDesignator;
		this.infoReferenceDesignatorSpan.innerHTML = `［${this.referenceDesignator}］`;
	}

	/** Toggles trashed status of this canvas (and sets associated div/span accordingly)
	 * @param  {Boolean} _trashed trashed property of associated dataNode
	 */
	setTrashed(_trashed) {
		console.debug("CanvasBase toggleTrashed");
	}


	/** Adds this canvas to canvasList and the DOM canvas container
	 * @param  {Canvas} _canvas to add
	 * @throws exception providing additional information on error
	 */
	createDomStructure() {
		this.container = document.createElement("div");
		this.container.id = `canvasContainer_${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;																											// TODO this.container.id = `${this.getType()}Container_${this.UUID}`;
		this.container.UUID = this.UUID;
		this.container.classList.add("canvas-base", `canvas-${makeFirstLetterLowerCase(getDataNodeByUUID(this.UUID).nodeType)}`);
		this.container.setAttribute("data-slidr", this.UUID);																					// add slidr attribute to div
		canvasRoot.canvasGlobalDOMContainer.appendChild(this.container);

		this.infoSubContainer = document.createElement("div");
		this.infoSubContainer.id = `canvasInfoSubContainer_${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.infoSubContainer.classList.add("canvas-info-subContainer", `${this.getType()}`, "noselect");
		this.container.appendChild(this.infoSubContainer);
		this.container.addEventListener("mouseleave", (e) => {	// must not fire on child, but do it
			const target = e.toElement || e.relatedTarget;
			if (target && target.id != "context-menu-layer" && !target.classList.contains("jtk-flowchart-handle"))	GLOBALEVENTMANAGER.dispatch("eJSP_CanvasMouseLeave", this.UUID);
		});

		this.infoNameSpan = document.createElement("span");
		this.infoNameSpan.id = `canvasInfoName_${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.infoNameSpan.classList.add("canvas-info-name", `${makeFirstLetterLowerCase(getDataNodeByUUID(this.UUID).nodeType)}`);
		this.infoNameSpan.textContent = this.name;
		this.infoSubContainer.appendChild(this.infoNameSpan);

		this.infoReferenceDesignatorSpan = document.createElement("span");
		this.infoReferenceDesignatorSpan.id = `canvasInfoReferenceDesignator_${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.infoReferenceDesignatorSpan.classList.add("canvas-info-referenceDesignator", `${makeFirstLetterLowerCase(getDataNodeByUUID(this.UUID).nodeType)}`);
		this.infoReferenceDesignatorSpan.textContent = this.referenceDesignator;
		this.infoSubContainer.appendChild(this.infoReferenceDesignatorSpan);
	}

	/** */
	createMiniView() {
		this.miniView = document.createElement("div");
		this.miniView.id = `miniView_${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.miniView.classList.add("canvas-miniView", "noselect");
		this.container.appendChild(this.miniView);
	}

	/** Removes this canvas from canvasRoot.canvasList and the DOM canvas container
	 * @throws exception providing additional information on error
	 */
	remove() {
		console.debug("CanvasBase remove");
	}
}


/** ProjectNode specific implementation of CanvasBase class */
class ProjectCanvas extends CanvasBase {
	/**
	 * @param  {ProjectNode} _projectNode that is represented by this canvas
	 */
	constructor(_projectNode) {
		super(_projectNode);
		canvasRoot.canvasPreviewLocalDOMContainer = this.container;
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "ProjectCanvas";
	}

	/** */
	setTrashed() {
		throw new Error("ProjectNodes and therefore their associated canvas object don't get trashed");
	}

	/** */
	remove() {
		throw new Error("ProjectNodes and therefore their associated canvas object don't get deleted");
	}
}


/** InfrastructureNode specific implementation of CanvasBase class */
class InfrastructureCanvas extends CanvasBase {				// will be used similar to project and assembly canvases. You can add devices (like assembly canvas) but assemblies/units will be represented by abstract objects (like project canvas)
	/**
	 * @param  {InfrastructureNode} _infrastructureNode that is represented by this canvas
	 */
	constructor(_infrastructureNode) {
		super(_infrastructureNode);
		this.createMiniView();
		this.preview = new CanvasPreview(this);
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "InfrastructureCanvas";
	}

	/** */
	setTrashed() {
		throw new Error("InfrastructureNodes and therefore their associated canvas object don't get trashed");
	}

	/** */
	createDomStructure() {
		super.createDomStructure();																							// create div for miniView
	}

	/** */
	remove() {
		throw new Error("InfrastructureNodes and therefore their associated canvas object don't get deleted");
	}

	/**
	 * @param  {String} _newName of canvas
	 */
	updateName(_newName) {
		super.updateName(_newName);
		this.preview.updateName(_newName);
	}

	/**
	 * @param  {String} _newReferenceDesignator of canvas
	 */
	updateReferenceDesignator(_newReferenceDesignator) {
		super.updateReferenceDesignator(_newReferenceDesignator);
		this.preview.updateReferenceDesignator(_newReferenceDesignator);
	}
}


/** TrashNode specific implementation of CanvasBase class */
class TrashCanvas extends CanvasBase {						// will be used similar to project canvases. Devices (like assembly canvas) can not be added, and all dataNodes will be represented by abstract objects (like project canvas)
	/**
	 * @param  {TrashNode} _trashNode that is represented by this canvas
	 */
	constructor(_trashNode) {
		super(_trashNode);
		this.createMiniView();
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "TrashCanvas";
	}

	/** */
	setTrashed() {
		throw new Error("TrashNodes and therefore their associated canvas object don't get trashed");
	}

	/** */
	createDomStructure() {
		super.createDomStructure();																							// create div for miniView
	}

	/** */
	remove() {
		throw new Error("TrashNodes and therefore their associated canvas object don't get deleted");
	}
}


/** AssemblyNode specific implementation of CanvasBase class */
class AssemblyCanvas extends CanvasBase {
	/**
	 * @param  {AssemblyNode} _assemblyNode that is represented by this canvas
	 */
	constructor(_assemblyNode) {
		super(_assemblyNode);
		this.createMiniView();
		this.preview = new CanvasPreview(this);
		getCanvasByUUID(_assemblyNode.UUID).container.style.visibility = "hidden";		// crude bugfix: slidr doesn't correctly update on project reset; formerly existing, newly loaded groupContainers don't get correctly hidden
	}

	/** Returns the type/name of this class
	 * @returns  {String} type/name of class
	 */
	getType() {
		return "AssemblyCanvas";
	}

	/** Toggles trashed status of this canvas (and sets associated containerDiv/infoNameSpan accordingly)
	 * @param  {Boolean} _trashed indicator if this canvases dataNode is trashed
	 */
	setTrashed(_trashed) {
		this.container.classList.toggle("trash");
		this.infoSubContainer.classList.toggle("trash");
		this.preview.toggleVisibility(!_trashed);
	}

	/** */
	createDomStructure() {
		super.createDomStructure();																							// create div for miniView
	}

	/** Removes this canvas from canvasRoot.canvasList and the DOM canvas container
	 * @throws exception providing additional information on error
	 */
	remove() {
		this.preview.remove();
		const tmpList = canvasRoot.canvasList;		// shortcut to improve readability
		tmpList.splice(tmpList.indexOf(searchArrayForElementByKeyValuePair(tmpList, "UUID", this.UUID)), 1);
		this.container.remove();
	}

	/**
	 * @param  {String} _newName of canvas
	 */
	updateName(_newName) {
		super.updateName(_newName);
		this.preview.updateName(_newName);
	}

	/**
	 * @param  {String} _newReferenceDesignator of canvas
	 */
	updateReferenceDesignator(_newReferenceDesignator) {
		super.updateReferenceDesignator(_newReferenceDesignator);
		this.preview.updateReferenceDesignator(_newReferenceDesignator);
	}
}


/** TODO */
class CanvasPreview {
	/** standard constructor
	 * @param  {CanvasBase} _canvas that is represented by this preview (assemblyCanvas or infrastructureCanvas)
	 */
	constructor(_canvas) {
		this.name = _canvas.name;
		this.referenceDesignator = _canvas.referenceDesignator;
		this.UUID = _canvas.UUID;
		this.container = null;																													// stores associated DOM canvasPreviewDiv-element
		this.infoNameSpan = null;																												// stores associated DOM canvasPreviewSpan-element
		this.infoReferenceDesignatorSpan = null;																				// stores associated DOM canvasPreviewSpan-element
		this.createDomStructure(_canvas);
		canvasRoot.canvasPreviewList.push(this);																				// add to global canvasPreviewList
	}

	/** Adds this CanvasPreview to the ProjectCanvas
	 * @param  {CanvasBase} _canvas to add a preview for
	 */
	createDomStructure(_canvas) {
		this.container = document.createElement("div");
		this.container.id = `canvasPreviewContainer-${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;																								// TODO this.container.id = `${this.getType()}Container_${this.UUID}`;
		this.container.classList.add(`${getDataNodeByUUID(this.UUID).nodeType}`, "canvas-preview-container");
		this.container.addEventListener("click", () => {GLOBALEVENTMANAGER.dispatch("eCM_Click", this.UUID);});
		canvasRoot.canvasPreviewLocalDOMContainer.appendChild(this.container);

		this.infoNameSpan = document.createElement("span");
		this.infoNameSpan.id = `canvasPreviewText-${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.infoNameSpan.classList.add("canvas-preview-text", "noselect");
		this.infoNameSpan.textContent = this.name;
		this.container.appendChild(this.infoNameSpan);

		this.infoReferenceDesignatorSpan = document.createElement("span");
		this.infoReferenceDesignatorSpan.id = `canvasPreviewReferenceDesignator-${getDataNodeByUUID(this.UUID).nodeType}_${this.UUID}`;
		this.infoReferenceDesignatorSpan.classList.add("canvas-preview-text", "canvas-preview-referenceDesignator", "noselect");
		this.infoReferenceDesignatorSpan.textContent = "";
		this.container.appendChild(this.infoReferenceDesignatorSpan);
	}

	/** Renames this CanvasPreview
	 * @param  {String} _newName that is represented by this preview
	 */
	updateName(_newName) {
		this.name = _newName;
		this.infoNameSpan.innerHTML = _newName;						// update canvasPreview name on DOM
	}

	/** sets the referenceDesignator component of this CanvasPreview
	 * @param  {String} _newReferenceDesignator that is represented by this preview
	 */
	updateReferenceDesignator(_newReferenceDesignator) {
		this.referenceDesignator = _newReferenceDesignator;
		this.infoReferenceDesignatorSpan.innerHTML = `${_newReferenceDesignator}`;						// update canvasPreview name on DOM
	}

	/** Switches the visibility of this AssemblyCanvasPreview (on AssemblyCanvas trash/untrash)
	 * @param  {Boolean} _visible that is represented by this preview
	 */
	toggleVisibility(_visible) {
		this.container.classList.toggle("turnInvisible");
	}

	/** Removes this AssemblyCanvasPreview (on AssemblyCanvas remove) */
	remove() {
		const tmpList = canvasRoot.canvasPreviewList;		// shortcut to improve readability
		tmpList.splice(tmpList.indexOf(searchArrayForElementByKeyValuePair(tmpList, "UUID", this.UUID)), 1);
		this.container.remove();
	}
}


/* ======================================== EVENTHANDLING ======================================== */

/** Wrapper for createDataNode event raised from dataManager
 * @param  {String} _dataNode dataNode that is represented on canvas
 * @throws Exception providing additional information on error
 */
export function eCreateCanvas(_dataNode) {
	switch (_dataNode.nodeType) {
		case "ProjectNode":
			new ProjectCanvas(_dataNode);
			reorderCanvasElements();
			break;
		case "InfrastructureNode":
			new InfrastructureCanvas(_dataNode);
			reorderCanvasElements();
			break;
		case "TrashNode":
			new TrashCanvas(_dataNode);
			reorderCanvasElements();
			break;
		case "AssemblyNode":
			new AssemblyCanvas(_dataNode);
			reorderCanvasElements();
			break;
		default:
			break; 													// do nothing for unit- / deviceNodes
	}
}

/** Wrapper for eDTM_DataNodeSendToLimbo event raised from dataManager
 * @param  {String} _dataNode dataNode that is represented on canvas
 * @throws Exception providing additional information on error
 */
export function eDeleteCanvas(_dataNode) {
	if (_dataNode.nodeType == "AssemblyNode") {
		getCanvasByUUID(_dataNode.UUID).remove();
		reorderCanvasElements();
	}
}

/** Wrapper for eDTM_DataNodeSendToTrash event raised from dataManager
 * @param  {DataNode} _dataNode that got renamed
 * @throws Exception providing additional information on error
 */
export function eTrashCanvas(_dataNode) {
	if (_dataNode.nodeType == "AssemblyNode") getCanvasByUUID(_dataNode.UUID).setTrashed(true);
	reorderCanvasElements();
}

/** Wrapper for eDTM_DataNodeRemovedFromTrash event raised from dataManager
 * @param  {DataNode} _dataNode that got renamed
 * @throws Exception providing additional information on error
 */
export function eUnTrashCanvas(_dataNode) {
	if (_dataNode.nodeType == "AssemblyNode") getCanvasByUUID(_dataNode.UUID).setTrashed(false);
	reorderCanvasElements();
}

/** Wrapper for rename AssemblyNode event raised from dataManager
 * @param  {DataNode} _dataNode that got renamed
 * @throws Exception providing additional information on error
 */
export function eUpdateCanvasName(_dataNode) {
	if (_dataNode.nodeType == "DeviceNode" || _dataNode.nodeType == "UnitNode" || _dataNode.nodeType == "LimboNode") return; // abort for dataNode types that don't have a canvas representation
	getCanvasByUUID(_dataNode.UUID).updateName(_dataNode.getName());
	reorderCanvasElements();
}

/** Wrapper for rename ReferenceDesignator event raised from ReferenceDesignatorManager
 * @param  {DataNode} _dataNode that got changed
 * @throws Exception providing additional information on error
 */
export function eUpdateCanvasReferenceDesignator(_dataNode) {
	if (_dataNode.nodeType == "DeviceNode" || _dataNode.nodeType == "UnitNode" || _dataNode.nodeType == "LimboNode") return; // abort for dataNode types that don't have a canvas representation
	getCanvasByUUID(_dataNode.UUID).updateReferenceDesignator(_dataNode.referenceDesignator.getReferenceDesignator().string());
}

/** Wrapper for eDTM_ActivateGroupNodeChanged event raised from dataManager
 * @param  {DataNode} _activeGroupNode new active GroupNode
 * @throws Exception providing additional information on error
 */
export function eSetActiveCanvas(_activeGroupNode) {
	canvasRoot.activeCanvas = getCanvasByUUID(_activeGroupNode.UUID);					// set new active Canvas
	canvasRoot.slider.slide(_activeGroupNode.UUID);										// move View to new active Canvas
}


/* ======================================== HELPERS ======================================== */

/** creates a slider parent object
 */
function createSlider() {
	canvasRoot.slider = slidr.create("canvasContainer", {															// see for details https://github.com/bchanx/slidr#settings
		breadcrumbs: false,
		controls: false,
		direction: "horizontal",
		fade: true,
		keyboard: false,
		overflow: false,																																// don't draw transition effect outside parent div
		pause: false,
		theme: "#222",
		touch: false,
		transition: "linear",
	});
}


/** Updates the order of all canvas elements keeping preview and slide order in sync with outliner */
function reorderCanvasElements() {
	reorderCanvasList();
	reorderAssemblyCanvasPreviewList();
	reorderDOMAssemblyCanvasPreviews();
	updateSlides();

	/** */
	function reorderCanvasList() {
		if (canvasRoot.canvasList.length < 1) return;																		// don't reorder when there is nothing to reorder

		// extract projectCanvas
		let tmpProjectCanvas = null;
		canvasRoot.canvasList.forEach((e) => {
			if (e.getType() == "ProjectCanvas") tmpProjectCanvas = e;
		});

		// extract infrastructureCanvas
		let tmpInfrastructureCanvas = null;
		canvasRoot.canvasList.forEach((e) => {
			if (e.getType() == "InfrastructureCanvas") tmpInfrastructureCanvas = e;
		});

		// extract trashCanvas
		let tmpTrashCanvas = null;
		canvasRoot.canvasList.forEach((e) => {
			if (e.getType() == "TrashCanvas") tmpTrashCanvas = e;
		});

		// extract assemblyCanvasses
		const tmpAssemblyCanvasses = [];
		canvasRoot.canvasList.forEach((e) => {
			if (e.getType() == "AssemblyCanvas") tmpAssemblyCanvasses.push(e);
		});

		// sort assemblyCanvasses
		if (tmpAssemblyCanvasses.length > 0) {
			tmpAssemblyCanvasses.sort(function(a, b) {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
		}


		// construct resulting canvasList; it is vital to reflect the order of the outliner (Project, Assemblies, Infrastructure, Trash)!
		const tmpCanvasList = [];

		if (tmpProjectCanvas != null) tmpCanvasList.push(tmpProjectCanvas);

		if (tmpAssemblyCanvasses.length > 0) {
			tmpAssemblyCanvasses.forEach((tmpAssemblyCanvas) => {
				tmpCanvasList.push(tmpAssemblyCanvas);
			});
		}

		if (tmpInfrastructureCanvas != null) tmpCanvasList.push(tmpInfrastructureCanvas);
		if (tmpTrashCanvas != null) tmpCanvasList.push(tmpTrashCanvas);

		// update original
		canvasRoot.canvasList = tmpCanvasList;
	}

	/** */
	function reorderAssemblyCanvasPreviewList() {
		if (canvasRoot.canvasPreviewList.length < 2) return;														// don't reorder when there is nothing to reorder (there is always an infrastructureCanvasPreview!)

		// Store and remove InfrastructureCanvasPreview from canvasPreviewList
		const tmpInfrastructureCanvasPreview = canvasRoot.canvasPreviewList.find((e) => {
			return e.UUID == getUniqueDataNodeByType("InfrastructureNode").UUID;
		});
		canvasRoot.canvasPreviewList.splice(canvasRoot.canvasPreviewList.indexOf(tmpInfrastructureCanvasPreview), 1);

		canvasRoot.canvasPreviewList.sort(function(a, b) {
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});

		// append InfrastructureCanvasPreview back to canvasPreviewList
		canvasRoot.canvasPreviewList.push(tmpInfrastructureCanvasPreview);
	}

	/** */
	function reorderDOMAssemblyCanvasPreviews() {
		if (canvasRoot.canvasPreviewList.length < 2) return;														// don't reorder when there is nothing to reorder (there is always an infrastructureCanvasPreview!)
		canvasRoot.canvasPreviewList.forEach((e) => {
			canvasRoot.canvasPreviewLocalDOMContainer.appendChild(e.container);
		});
	}

	/** Updates the slider (graphical effect while switching canvasses) canvasList has changed (pE remove/add canvas) */
	function updateSlides() {
		const tmpSlideList = [];
		canvasRoot.canvasList.forEach((e) => {
			tmpSlideList.push(e.UUID);
		});
		canvasRoot.slider.add("h", tmpSlideList, "", true);
		canvasRoot.slider.start();
	}
}


/** Returns a canvas with matching UUID
 * @param  {string} _UUID identifier of canvas to find
 * @returns  {Canvas} with matching UUID
 * @throws  exception providing additional information on error
 */
export function getCanvasByUUID(_UUID) {
	return searchArrayForElementByKeyValuePair(canvasRoot.canvasList, "UUID", _UUID);
}
