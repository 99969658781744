import {dataRoot} from													"./dataManager";
import {checkUniqueness} from										"./helper";
import {cId2jqSel} from													"./helper";
import {checkExistenceNew} from									"./helper";
import {convertFaultyString} from								"./helper";
import {checkExistence} from										"./helper";
import {checkUniquenessLEGACY} from							"./helper";
import {checkStringValidity} from								"./helper";
import {checkContains} from											"./helper";
import {bindDomElementToTranslateEvent} from		"./localization/localizationManager";
import {getTranslation} from										"./localization/localizationManager";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;
import "jquery-ui/ui/widgets/accordion";


/** Central structure for the creation and manipulation of accordions (collapsible content panels for presenting information in a limited amount of space)
 *
 * UTILIZING:
 *		jquery-ui accordion widget, details under https://api.jqueryui.com/accordion/
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *		Stanislav Biryukov
 */

/** Creates an accordion for the given deviceGroup
 * @param  {String} _deviceGroup to build accordion for (taken from dataRoot.sourceData)
 */
export function createAccordion(_deviceGroup) {
	const tmpI18nKey = `accordionManager.defaultHeading-${_deviceGroup}`;																															// create correct i18nKey for given deviceGroup

	const tmpDeviceGroup = dataRoot.sourceData.filter((device) => {																											// gather all devices belonging to provided deviceGroup
		return device.group === _deviceGroup;
	});

	const tmpUniqueDeviceSubGroups = [...new Set(tmpDeviceGroup.map((x) => x.subGroup))];																// gather all unique subGroups of tmpDeviceGroup


	createAccordionShell("sidePanelLeft", _deviceGroup, tmpI18nKey);																										// create an accordionShell for given deviceGroup

	tmpUniqueDeviceSubGroups.forEach((subGroup) => {																																		// traverse all unique subGroups for given deviceGroup
		addAccordionPanelToShell(_deviceGroup, convertFaultyString(subGroup), subGroup);																	// create an accordionPanel for each unique subGroup

		const tmpDeviceSubGroup = dataRoot.sourceData.filter((device) => {																								// retrieve all devices belonging to subGroup
			return device.subGroup === subGroup;
		});

		tmpDeviceSubGroup.forEach((device) => {																																					// traverse all devices for unique subGroup
			addItemToAccordionPanel(device.databaseId, device.type, device.name, device.graphics.activeGraphics.file, convertFaultyString(subGroup));			// add device to accordionPanel
		});
	});

	finalizeAccordion(_deviceGroup);
	tmpUniqueDeviceSubGroups.forEach((subGroup) => {	// bind to translation
		bindDomElementToTranslateEvent($("[myid="+subGroup + "Heading]"), "title", subGroup);
		bindDomElementToTranslateEvent($("[myid="+subGroup + "Heading] span:last"), "text", subGroup);
	});
}


/** Creates an EMPTY accordion container (div) inside a parent element.
 * @param  {String} _parentId container to nest into
 * @param  {String} _shellId id of accordion shell that gets created
 * @param  {i18nKey} _i18nIdentifier heading of accordionShell
 * @throws Exception providing additional information on error
 */
export function createAccordionShell(_parentId, _shellId, _i18nIdentifier) {
	$("<div>", {																		// creates a div for the accordion shell inside the parent container
		id: checkUniqueness(_shellId),
		class: "accordion-container",
	}).appendTo(cId2jqSel(checkExistenceNew(_parentId)));

	$("<div>", {																		// creates a div for the accordion heading inside the shell
		id: _shellId + "Heading",
		class: "accordion-container-heading accordion-ignore noselect",
		text: getTranslation(_i18nIdentifier),
	}).appendTo(cId2jqSel(_shellId));
	bindDomElementToTranslateEvent(cId2jqSel(_shellId + "Heading"), "text", _i18nIdentifier);
}

/** Adds an EMPTY accordion item (h3 + div) to an accordion shell.
 * @param  {String} _parentAccordionShellId shell to add the item to
 * @param  {String} _itemId id of accordion item that gets created
 * @param  {String} _i18nIdentifier heading of item that gets created
 * @throws Exception providing additional information on error
 */
export function addAccordionPanelToShell(_parentAccordionShellId, _itemId, _i18nIdentifier) {
	checkExistence(_parentAccordionShellId);											// verify _parentAccordionShellId exists

	checkUniquenessLEGACY(_itemId);															// verify uniqueness of _itemId

	checkStringValidity(_i18nIdentifier);															// verify _text

	$("<h3>", {																			// creates a h3 inside accordionShell serving as the heading component of the accordion item
		myid: _itemId + "Heading",
		class: "accordion-itemHeader noselect",
		title: getTranslation(_i18nIdentifier),
		// text: _text,
	}).appendTo(cId2jqSel(_parentAccordionShellId));

	$("<span>", {																		// creates a span to bind to translation event, otherwise the prepended triangle will be deleted with text changing
		text: getTranslation(_i18nIdentifier),
	}).appendTo($("[myid="+_i18nIdentifier + "Heading]"));

	$("<div>", {																		// creates a div inside accordionShell serving as the panel component of the accordion item
		id: _itemId,
		class: "accordion-itemPanel",
	}).appendTo(cId2jqSel(_parentAccordionShellId));
}

/** Converts an accordion shell containing accordion item(s) into a "real" accordion.
 * @param  {String} _AccordionShellId accordion shell to convert
 * @throws Exception providing additional information on error
 */
export function finalizeAccordion(_AccordionShellId) {
	checkExistence(_AccordionShellId);													// verify that _AccordionShellId exists

	checkContains(".accordion-itemHeader", _AccordionShellId);							// verify that _AccordionShellId contains an accordion-item-header

	checkContains(".accordion-itemPanel", _AccordionShellId);							// verify that _AccordionShellId contains an accordion-item-itemPanel

	cId2jqSel(_AccordionShellId).accordion({											// converts accordion shell containing valid accordion items into a working accordion
		header: "h3:not(.accordion-ignore)",		// don't convert elements that are marked as .accordion-ignore
		heightStyle: "content",						// restrict height to content
		collapsible: true,							// allow all sub-elements to be closed
		active: false,								// initialize all elements as closed
		classes: {									// augment/redirect default classes
			// "ui-accordion": "BLA",
			"ui-accordion-header": "accordion-itemHeader",
			"ui-accordion-header-active": "accordion-itemHeader-active",
			"ui-accordion-header-collapsed": "accordion-itemHeader-collapsed",
			// "ui-accordion-icons": "BLA",
			// "ui-accordion-header-icon": "BLA",
			// "ui-accordion-content": "BLA",
			// "ui-accordion-content-active": "BLA",
		},
	});

	// removing obsolete jquery classes
	$(".accordion-container").removeClass("ui-widget ui-helper-reset");
	$(".accordion-container-heading").removeClass("accordion-ignore");
	$(".accordion-itemHeader").removeClass("ui-accordion-header ui-accordion-header-collapsed ui-corner-all ui-state-default ui-accordion-icons");
}

/** Adds an item (icon and associated data) to an accordion panel
 * @param  {String} _itemId program-wide identifier of device
 * @param  {String} _type of node (deviceNode or unitNode)
 * @param  {String} _toolTip to show while hovering above
 * @param  {String} _icon to use
 * @param  {String} _accordionPanelId panel to add item to
 */
function addItemToAccordionPanel(_itemId, _type, _toolTip, _icon, _accordionPanelId) {
	checkExistence(_accordionPanelId);													// verify existence of accordionPanel

	checkUniquenessLEGACY(_itemId);															// verify uniqueness of _itemId

	$("<div>", {
		id: _itemId,
		class: "accordion-item noselect",												// starting deactivated and greyed out (switching to a assemblyNode will switch to active)
	}).appendTo(cId2jqSel(_accordionPanelId));

	$("<img>", {
		class: "accordion-item-image noselect grayout accordion-item-image-nodrag",		// starting deactivated and greyed out (switching to a assemblyNode will switch to active)
		jtkNodeType: _type,
		databaseId: _itemId,
		title: _toolTip,
		src: _icon,																		// displays thumbnail initially (for changing to symbol view, a separate event is triggered)
	}).prependTo(cId2jqSel(_itemId));
}

/** Helper to find an accordion item by its name (or parts of it)
 * @param  {String} _name of accordionItem to find
 * @returns {Boolean} indicator of success
 */
export function eFindAccordionItem(_name) {
	if (_name == "") return;	// abort if search field is empty

	let tmpAccordionContainer = null;
	let tmpAccordion = null;
	let tmpAccordionIndex = null;

	// try to find element with _name and identify its parent accordion and accordionContainer
	Array.from(document.getElementsByClassName("accordion-item-image")).forEach((item) => {
		if (item.title.toLowerCase().includes(_name.toLowerCase())) {
			tmpAccordion = item.parentNode.parentNode;
			tmpAccordionContainer = item.parentNode.parentNode.parentNode;
		}
	});

	if (tmpAccordionContainer == null) return false;	// abort if nothing was found

	// find index of tmpAccordion
	for (let i = 0; i < tmpAccordionContainer.childNodes.length; i++) {
		if (tmpAccordionContainer.childNodes[i].id == tmpAccordion.id) {
			tmpAccordionIndex = i/2 - 1;
		}
	}

	// collapse all accordions
	$(".ui-accordion").accordion().accordion("option", "active", false);

	// expand found accordion
	cId2jqSel(tmpAccordionContainer.id).accordion().accordion("option", "active", tmpAccordionIndex);
}

/* Accordion variants for dialogs*/

/** Adds an EMPTY accordion item (h3 + div) to an accordion shell.
 * @param  {String} _AccordionShellId - shell to add the item to
 * @param  {String} _itemId - id of accordion item that gets created
 * @param  {String} _headerId of item that gets created
 * @param  {String} _headerHtml - header content
 * @param  {String} _headerClass - additional class for accordion header
 */
export function addAccordionPanelToShellDialog(_AccordionShellId, _itemId, _headerId, _headerHtml, _headerClass) {
	checkExistence(_AccordionShellId);											// verify _parentAccordionShellId exists

	checkUniquenessLEGACY(_itemId);															// verify uniqueness of _itemId

	checkStringValidity(_headerHtml);															// verify _text

	$("<div>", {																			// creates a h3 inside accordionShell serving as the heading component of the accordion item
		class: "accordion-itemHeader noselect "+_headerClass,
		id: _headerId,
		html: _headerHtml,
	}).appendTo(cId2jqSel(_AccordionShellId));

	$("<div>", {																		// creates a div inside accordionShell serving as the panel component of the accordion item
		id: _itemId,
		class: "accordion-itemPanel",
	}).appendTo(cId2jqSel(_AccordionShellId));
}

/** Converts an accordion shell containing accordion item(s) into a "real" accordion.
 * @param  {String} _AccordionShellId accordion shell to convert
 * @param  {String} _headerClass to define accordion's header
 */
export function finalizeAccordionDialog(_AccordionShellId, _headerClass) {
	checkExistence(_AccordionShellId);													// verify that _AccordionShellId exists

	checkContains(".accordion-itemHeader", _AccordionShellId);							// verify that _AccordionShellId contains an accordion-item-header

	checkContains(".accordion-itemPanel", _AccordionShellId);							// verify that _AccordionShellId contains an accordion-item-itemPanel
	if (cId2jqSel(_AccordionShellId).accordion("instance")) {
		cId2jqSel(_AccordionShellId).accordion("refresh");
	}	else {
		cId2jqSel(_AccordionShellId).accordion({											// converts accordion shell containing valid accordion items into a working accordion
			header: "div."+_headerClass,		// +" :not(.accordion-ignore)" don't convert elements that are marked as .accordion-ignore
			heightStyle: "content",						// restrict height to content
			collapsible: true,							// allow all subelements to be closed
			active: false,								// initialize all elements as closed
			classes: {									// augment/redirect default classes
			// "ui-accordion": "BLA",
				"ui-accordion-header": "accordion-itemHeaderDialog",
				"ui-accordion-header-active": "accordion-itemHeader-active",
				"ui-accordion-header-collapsed": "accordion-itemHeader-collapsed",
			},
		}).on("click", function() {	//  close source on target open und visa versa   }).on('accordionchange', function(event, ui) {
			if (_AccordionShellId == "aggregate_ports_target" && cId2jqSel("aggregate_ports_source").accordion("instance")) cId2jqSel("aggregate_ports_source").accordion("option", "active", false);
			else if (_AccordionShellId == "aggregate_ports_source" && cId2jqSel("aggregate_ports_target").accordion("instance")) cId2jqSel("aggregate_ports_target").accordion("option", "active", false);
		});
	}

	// removing obsolete jquery classes
	$(".accordion-container").removeClass("ui-widget ui-helper-reset");
	$(".accordion-container-heading").removeClass("accordion-ignore");
	$(".accordion-itemHeader").removeClass("ui-accordion-header ui-accordion-header-collapsed ui-corner-all ui-state-default ui-accordion-icons");
}

/** Prepared a header structure for port accordion panel
 * @param  {String} _side - source or target
 * @param  {String} _wire - source or target index
 * @returns {DOM} headerContent
 */
export function createDialogAccordionCableHeader(_side, _wire) {
	// const idString = "aggregate_ports_"+_side+"_"+_wire+"_header";
	const idString = _wire+_side+"Heading";
	const headerContent = $("<div>", {
		id: idString,
		class: "accordion-header-container",
	});

	$("<div>", {
		id: idString+"_description",
		class: "accordion-header-description",
		text: _wire,
	}).appendTo(headerContent);

	/* $("<div>", {
		id: idString+"_referenceDesignator",
		class: "accordion-header-referenceDesignator",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_parameters",
		class: "accordion-header-port-parameters",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_connector",
		class: "accordion-header-connector",
	}).appendTo(headerContent); */

	const validationContainer = $("<div>", {
		id: idString+"_validationContainer",
		class: "accordion-header-validationContainer",
	}).appendTo(headerContent);

	$("<image>", {
		id: idString+"_validationIndicator",
		class: "cControl-ValidationIndicator-Header",
	}).appendTo(validationContainer);

	return headerContent;
}

/** Prepared a header structure for port accordion panel
 * @param  {String} _side - source or target
 * @param  {String} _index - source or target index
 * @returns {DOM} headerContent
 */
export function createDialogAccordionPortHeader(_side, _index) {
	const idString = "aggregate_ports_"+_side+"_"+_index+"_header";
	const headerContent = $("<div>", {
		id: idString,
		class: "accordion-header-container",
	});

	$("<div>", {
		id: idString+"_description",
		class: "accordion-header-description",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_referenceDesignator",
		class: "accordion-header-referenceDesignator",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_parameters",
		class: "accordion-header-port-parameters",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_connector",
		class: "accordion-header-connector",
	}).appendTo(headerContent);

	const validationContainer = $("<div>", {
		id: idString+"_validationContainer",
		class: "accordion-header-validationContainer",
	}).appendTo(headerContent);

	$("<image>", {
		id: idString+"_validationIndicator",
		class: "cControl-ValidationIndicator-Header",
	}).appendTo(validationContainer);

	return headerContent;
}

/** Prepared a header structure for port accordion panel
 * @param  {Number} _portIndex - port index
 * @param  {Number} _index - interface index
 * @param  {String} _side - source or target
 * @returns {DOM} headerContent
 */
export function createDialogAccordionInterfaceHeader(_portIndex, _index, _side) {
	const idString = "aggregate_interfaces_"+_side+"_"+_portIndex+"_"+_index+"_header";
	const headerContent = $("<div>", {
		id: idString,
		class: "accordion-header-container",
	});

	$("<div>", {
		id: idString+"_description",
		class: "accordion-header-description",
	}).appendTo(headerContent);

	const headerParameters = $("<div>", {
		id: idString+"_parameters",
		class: "accordion-header-interface-parameters",
	}).appendTo(headerContent);

	$("<div>", {
		id: idString+"_type",
		class: "accordion-header-type",
	}).appendTo(headerParameters);

	$("<div>", {
		id: idString+"_voltage",
		class: "accordion-header-voltage",
	}).appendTo(headerParameters);

	$("<div>", {
		id: idString+"_current",
		class: "accordion-header-current",
	}).appendTo(headerParameters);

	$("<div>", {
		id: idString+"_operatingMode",
		class: "accordion-header-operatingMode",
	}).appendTo(headerParameters);

	$("<div>", {
		id: idString+"_safe",
		class: "accordion-header-safe",
	}).appendTo(headerParameters);

	const validationContainer = $("<div>", {
		id: idString+"_validationContainer",
		class: "accordion-header-validationContainer",
	}).appendTo(headerContent);

	$("<image>", {
		id: idString+"_validationIndicator",
		class: "cControl-ValidationIndicator-Header",
	}).appendTo(validationContainer);

	return headerContent;
}
