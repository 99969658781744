/* eslint-disable require-jsdoc */


import type {ElectricConnectionSourceData}							from "../connections/electricConnection.schema";
import type {Port}																			from "../ports/port";
import type {ConnectionAutoCheckConditions}							from "./ElectricConnectionCompatibilityChecker";
import type {ElectricConnectionCompatibilityChecker}		from "./ElectricConnectionCompatibilityChecker";


//! In the end, all compatible cables must be sorted by some algorithm

/**
 * Argument for FindAllCompatibleElectricConnections.
 * @property {boolean} debugMode - turn chatty log on/off
 */
export interface FindAllCompatibleConnectionsConditions {
	debugMode: boolean;
}

export class FindAllCompatibleElectricConnections {
	private readonly port1: Port;
	private readonly port2: Port;
	private readonly sourceConnections: Array<ElectricConnectionSourceData>;
	private readonly compatibleConnections: Array<ElectricConnectionSourceData>;
	public readonly conditions: FindAllCompatibleConnectionsConditions = {
		debugMode: false,
	};

	constructor(_port1: Port, _port2: Port, _sourceConnections: Array<ElectricConnectionSourceData>, _connectionCompatibilityChecker: ElectricConnectionCompatibilityChecker, _options?: Partial<FindAllCompatibleConnectionsConditions>) {
		this.conditions = {...this.conditions, ..._options};

		this.port1 = _port1;
		this.port2 = _port2;
		this.sourceConnections = _sourceConnections;

		this.compatibleConnections = this.filterConnections(_connectionCompatibilityChecker);

		if (this.conditions.debugMode) this.logResult(_connectionCompatibilityChecker.conditions);

		Object.preventExtensions(this);
	}

	private filterConnections(_connectionCompatibilityChecker: ElectricConnectionCompatibilityChecker): Array<ElectricConnectionSourceData> {
		const result = this.sourceConnections.filter((connection) => _connectionCompatibilityChecker.autoCheck(connection, this.port1, this.port2));

		return result;
	}

	private logResult(_connectionAutoCheckConditions: ConnectionAutoCheckConditions): void {
		const sourceDevicePort = (this.port1.side === "SOURCE") ? this.port1 : this.port2;
		const targetDevicePort = (this.port1.side === "TARGET") ? this.port1 : this.port2;

		/* eslint-disable no-console */
		console.group(`FindAllCompatibleElectricConnections`);
		console.log("Conditions");
		console.log("  forceShielded           ", _connectionAutoCheckConditions.forceShielded);
		console.log("  forceDynamicApplication ", _connectionAutoCheckConditions.forceDynamicApplication);
		console.log("  forceSourcePortLayout   ", _connectionAutoCheckConditions.forceSourcePortLayout);
		console.log("  forceTargetPortLayout   ", _connectionAutoCheckConditions.forceTargetPortLayout);

		console.log("");

		console.group(`sourceDevicePort: ${sourceDevicePort.parent.name} || ${sourceDevicePort.family} | ${sourceDevicePort.side} | ${sourceDevicePort.gender}`);
		console.log("shielded   ", sourceDevicePort.isShielded);
		console.log("interfaces ", sourceDevicePort.flattenInterfaces());
		console.log("leads      ", sourceDevicePort.leads);
		console.groupEnd();
		console.log("");

		console.group(`targetDevicePort: ${targetDevicePort.parent.name} || ${targetDevicePort.family} | ${targetDevicePort.side} | ${targetDevicePort.gender}`);
		console.log("shielded   ", targetDevicePort.isShielded);
		console.log("interfaces ", targetDevicePort.flattenInterfaces());
		console.log("leads      ", targetDevicePort.leads);
		console.groupEnd();
		console.log("");

		console.group(`CompatibleConnections [${this.compatibleConnections.length}]`);
		if (this.compatibleConnections.length === 0 ) console.error("No compatible Connections found");
		this.compatibleConnections.forEach((connection) => {
			console.group(`${connection.name} [${connection.databaseId}]`);
			console.log("shielded           ", connection.isShielded);
			console.log("dynamicApplication ", connection.dynamicApplication);
			console.log("leads              ", connection.leads);

			console.group(`Port0: ${connection.ports[0].family} | ${connection.ports[0].gender}`);
			console.log("shielded ", connection.ports[0].isShielded);
			console.log("layout   ", connection.ports[0].layout);
			console.log("leads    ", connection.ports[0].leads);
			console.groupEnd();

			console.group(`Port1: ${connection.ports[1].family} | ${connection.ports[1].gender}`);
			console.log("shielded ", connection.ports[1].isShielded);
			console.log("layout   ", connection.ports[1].layout);
			console.log("leads    ", connection.ports[1].leads);
			console.groupEnd();
			console.log("");

			console.groupEnd();
		});
		console.groupEnd();

		console.groupEnd();
		/* eslint-enable no-console */
	}

	/**
	 * Returns the result of all checks.
	 * @returns {Array<ElectricConnectionSourceData>|false} All connections within sourceData that are compatible to provided ports or false.
	 */
	get result(): Array<ElectricConnectionSourceData>|false {
		return (this.compatibleConnections.length > 0) ? this.compatibleConnections : false;
	}
}
