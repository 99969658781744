import {Application} from "../constantsAndEnumerations2";
import {BaseConnection} from "./BaseConnection";
import type {PneumaticConnectionData, PneumaticConnectionSaveData} from "./pneumaticConnection.schema";

/** Class describing a hydraulic connection between two Nodes. */
export class PneumaticConnection extends BaseConnection {
	/**
	 * Creates a new instance of PneumaticConnection
	 * @param {PneumaticConnectionData} _connectionData  dataset describing a pneumatic connection
	 */
	constructor(_connectionData: PneumaticConnectionData) {
		throw new Error(`Pneumatic Connection is not yet implemented :(`);
	}

	/**
	 * Returns the variable characteristics of this pneumatic connection.
	 * @returns {PneumaticConnectionSaveData} object containing all info
	 */
	public save(): PneumaticConnectionSaveData {
		return {
			...super.save(),
			application: Application.PNEUMATIC, //REFACTOR this should be handled by BaseConnection
			ports: this.ports.map((_pneumaticPort) => {
				const tmpPortSave = _pneumaticPort.save();
				_pneumaticPort.interfaces = []; // don't save interfaces on connections and their ports
				return tmpPortSave;
			}),
		};
	}
}
