import {interfaceTypeEnum, leadSectionEnum} from "../constantsAndEnumerations";

import type {portLayoutEnum} from "../constantsAndEnumerations";
import type {Interface as ElectricInterface} from "../interfaces/interface";
import type {Port as ElectricPort} from "../ports/port";
import type {ElectricPortSourceData} from "../ports/electricPort.schema";
import type {ElectricConnectionSourceData} from "./electricConnection.schema";

import cloneDeep from "lodash.clonedeep"; //! get rid of clonedeep one day
import {DynamicApplication} from "../constantsAndEnumerations2";
import {getConnectionSourceDataByDatabaseId} from "../dataManager";
import type {PortFamily, PortGender} from "../ports/utils";
import {invertPortGender} from "../ports/utils";

//! THIS IS JUST Q QUICK & DIRTY HACK, NOT A SUSTAINABLE SOLUTION !!!
//! For a sensible implementation we'd need:
//!		- a way to programmatically determine leads for a given set of interfaces
//!		- a way to programmatically determine ports for a given set of targetDevice/sourceDevice interfaces

/**
 * Options arguments for createGenericElectricConnectionSourceData
 * @property {boolean} debugMode turn chatty log on/off
 * @property {boolean} forceShielded - filter out unshielded connections
 * @property {DynamicApplication} forceDynamicApplication force dynamic application other
 * @property {keyof typeof portLayoutEnum} forceSourcePortLayout force a sourcePort layout
 * @property {keyof typeof portLayoutEnum} forceTargetPortLayout force a targetPort layout
 */
interface createGenericElectricConnectionOptions {
	debugMode: boolean;
	forceShielded: boolean;
	dynamicApplication: DynamicApplication;
	sourcePortLayout: keyof typeof portLayoutEnum;
	targetPortLayout: keyof typeof portLayoutEnum;
}

/**
 * Creates ElectricConnectionSourceData from given arguments.
 * @param {Array<ElectricConnectionSourceData>} _sourceConnections all available electricConnection source data
 * @param {ElectricPort} _sourceDevicePort port of sourceDevice to connect to
 * @param {ElectricPort} _targetDevicePort port of targetDevice to connect to
 * @param {Partial<createGenericElectricConnectionOptions>} [_options] options argument
 * @returns {ElectricConnectionSourceData|false} ElectricConnectionSourceData or false if no data could be determined
 */
export function createGenericElectricConnectionSourceData(
	_sourceConnections: ElectricConnectionSourceData[],
	_sourceDevicePort: ElectricPort,
	_targetDevicePort: ElectricPort,
	_options?: Partial<createGenericElectricConnectionOptions>,
): ElectricConnectionSourceData | false {
	// defaults
	let conditions: createGenericElectricConnectionOptions = {
		debugMode: false,
		forceShielded: false,
		dynamicApplication: DynamicApplication.DA4,
		sourcePortLayout: "STRAIGHT",
		targetPortLayout: "STRAIGHT",
	};

	conditions = {...conditions, ..._options};

	if (conditions.debugMode) {
		/* eslint-disable no-console */
		console.group("createGenericElectricConnectionSourceData");
		console.log("Conditions");
		console.log("  forceShielded:      ", conditions.forceShielded);
		console.log("  dynamicApplication: ", conditions.dynamicApplication);
		console.log("  sourcePortLayout:   ", conditions.sourcePortLayout);
		console.log("  targetPortLayout:   ", conditions.targetPortLayout);
		console.log("");
		console.group(`sourceDevicePort: ${_sourceDevicePort.parent.name} || ${_sourceDevicePort.family} | ${_sourceDevicePort.side} | ${_sourceDevicePort.gender}`);
		console.log("shielded   ", _sourceDevicePort.isShielded);
		console.log("interfaces ", _sourceDevicePort.flattenInterfaces());
		console.log("leads      ", _sourceDevicePort.leads);
		console.groupEnd();
		console.log("");

		console.group(`targetDevicePort: ${_targetDevicePort.parent.name} || ${_targetDevicePort.family} | ${_targetDevicePort.side} | ${_targetDevicePort.gender}`);
		console.log("shielded   ", _targetDevicePort.isShielded);
		console.log("interfaces ", _targetDevicePort.flattenInterfaces());
		console.log("leads      ", _targetDevicePort.leads);
		console.groupEnd();
		console.log("");
		/* eslint-enable no-console */
	}

	// find sourcePorts that match targetDevicePort & sourceDevicePort
	const connectionSourcePort = findSourcePort(
		_sourceConnections,
		_targetDevicePort.family,
		invertPortGender(_targetDevicePort.gender),
		conditions.forceShielded ? true : _targetDevicePort.isShielded,
		conditions.sourcePortLayout,
	);
	const connectionTargetPort = findSourcePort(
		_sourceConnections,
		_sourceDevicePort.family,
		invertPortGender(_sourceDevicePort.gender),
		conditions.forceShielded ? true : _targetDevicePort.isShielded,
		conditions.targetPortLayout,
	);
	if (connectionSourcePort === false || connectionTargetPort === false) return false;

	// find a sourceConnection with a port matching targetDevicePort (for leads/composition)
	const similarConnection = findSourceConnection(_sourceConnections, _targetDevicePort.family, invertPortGender(_targetDevicePort.gender));
	if (similarConnection === false) return false;

	const genericElectricConnectionSourceData: ElectricConnectionSourceData = {
		...(getConnectionSourceDataByDatabaseId(0) as ElectricConnectionSourceData),
		composition: similarConnection.composition,
		dynamicApplication: conditions.dynamicApplication,
		isShielded: conditions.forceShielded,
		leads: similarConnection.leads,
		ports: [connectionSourcePort, connectionTargetPort],
	};

	if (conditions.debugMode) {
		/* eslint-disable no-console */
		console.group("Result", genericElectricConnectionSourceData);
		console.group(`connectionSourcePort: ${connectionSourcePort.family} | SOURCE | ${connectionSourcePort.gender}`);
		console.log("shielded   ", connectionSourcePort.isShielded);
		console.log("leads      ", connectionSourcePort.leads);
		console.groupEnd();
		console.log("");

		console.group(`connectionTargetPort: ${connectionTargetPort.family} | TARGET | ${connectionTargetPort.gender}`);
		console.log("shielded   ", connectionTargetPort.isShielded);
		console.log("leads      ", connectionTargetPort.leads);
		console.groupEnd();
		console.log("");

		console.groupEnd();
		console.groupEnd();
		/* eslint-enable no-console */
	}

	return genericElectricConnectionSourceData;
}

/**
 * Finds a sourcePort matching provided arguments
 * @param {Array<ElectricConnectionSourceData>} _sourceConnections all available electricConnection source data
 * @param {PortFamily} _type of port to find
 * @param {PortGender} _gender of port to find
 * @param {boolean} _isShielded property of port to find
 * @param {keyof typeof portLayoutEnum} _layout of port to find
 * @returns {ElectricPortSourceData|false} ElectricPortSourceData or false if no data could be determined
 * @deprecated Replace with /ports/utils/filterSourcePorts
 */
function findSourcePort(
	_sourceConnections: ElectricConnectionSourceData[],
	_type: PortFamily,
	_gender: PortGender,
	_isShielded: boolean,
	_layout: keyof typeof portLayoutEnum,
): ElectricPortSourceData | false {
	let result;

	for (const sourceConnection of _sourceConnections) {
		result = sourceConnection.ports.find(
			(_port) => _port.family === _type && _port.gender === _gender && _port.isShielded === _isShielded && _port.layout.type === _layout,
		);
		if (result !== undefined) break;
	}

	// eslint-disable-next-line no-console
	if (result === undefined) console.error("GenericElectricConnectionCreation: No matching Port found in sourceData for.");
	return result !== undefined ? cloneDeep(result) : false;
}

/**
 * Finds a sourceConnection matching provided arguments
 * @param {Array<ElectricConnectionSourceData>} _sourceConnections all available electricConnection source data
 * @param {PortFamily} _type of one port
 * @param {PortGender} _gender of one port
 * @returns {ElectricConnectionSourceData|false} ElectricConnectionSourceData or false if no data could be determined
 */
function findSourceConnection(_sourceConnections: ElectricConnectionSourceData[], _type: PortFamily, _gender: PortGender): ElectricConnectionSourceData | false {
	const result = _sourceConnections.find((sourceConnection) => sourceConnection.ports.some((port) => port.family === _type && port.gender === _gender));

	// eslint-disable-next-line no-console
	if (result === undefined) console.error("GenericElectricConnectionCreation: No matching Connection found in sourceData.");
	return result !== undefined ? cloneDeep(result) : false;
}

/**
 * Determines standard lead section for a given ElectricInterface.
 * @param {ElectricInterface} _interface to determine lead section for
 * @returns {number} standard section for given interface
 */
function getStandardLeadSection(_interface: ElectricInterface): typeof leadSectionEnum[keyof typeof leadSectionEnum] | never {
	const isEnergyAC = _interface.type === interfaceTypeEnum.ENERGY_AC;
	switch (true) {
		case isEnergyAC && _interface.current <= 13: // regardless of current, we never use energyAC with a section smaller 1.5
			return leadSectionEnum["1.5"];
		case _interface.current <= 4:
			return leadSectionEnum["0.34"];
		case _interface.current <= 12:
			return leadSectionEnum["1.5"];
		case _interface.current <= 16:
			return leadSectionEnum["2.5"];
		case _interface.current <= 30:
			return leadSectionEnum["4.0"]; // estimation for large currents, not based on lq data sheets
		case _interface.current <= 60:
			return leadSectionEnum["10.0"]; // estimation for large currents, not based on lq data sheets
		default:
			throw new Error(`Could not determine section for interface "${_interface.type.type}" and current=${_interface.current}.`);
	}
}
