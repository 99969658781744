import {SVG} from "@svgdotjs/svg.js";
import {getTranslation} from "../localization/localizationManager";


/**
 * Wraps SVG.js library functions
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *
 * AUTHOR(S):
 *		Frederic Hoffmann
 *		Christian Lange
 */


/**
 * Wraps SVG.js library functions
 * @export
 * @class SvgLibWrapper
 */
export class SvgLibWrapper {
	/**
	 * Creates an instance of SvgLibWrapper.
	 * @param {UUID} _uuid of controller
	 * @param {HTMLDivElement} _canvasContainer in DOM, where the outermost SVG container gets located
	 * @param {Object} _canvasDetails holds canvas info such as width, height
	 * @param {Object} _titleBlockTemplate holds every width, height, positions and contents for titleBlock
	 * @memberof SvgLibWrapper
	 */
	constructor(_uuid, _canvasContainer, _canvasDetails, _titleBlockTemplate) {
		this.titleBlockTemplate = _titleBlockTemplate;
		this.titleBlockOutlineDetails = _titleBlockTemplate.titleBlockOutline;

		this.canvasDetails = _canvasDetails;
		this.canvas = this.createCanvas(`#${_canvasContainer}`);

		this.domBufferContainer = document.body;
		this.drawBox({
			"position": {"x": 0, "y": 0},
			"width": this.canvasDetails.width,
			"height": this.canvasDetails.height,
		});
	}

	/**
	 * Creates an empty SVG container under the given div and makes it changeable by returning it.
	 * @param {HTMLDivElement} _canvasContainer in DOM, where the outermost SVG container gets located
	 * @returns {HTMLDocument} SVG Container
	 * @memberof SvgLibWrapper
	 */
	createCanvas(_canvasContainer) {
		// eslint-disable-next-line new-cap
		return SVG()
		.addTo(_canvasContainer)
		.id(this.canvasDetails.id)
		.attr({
			width: this.canvasDetails.width,
			height: this.canvasDetails.height,
		});
	}

	/**
	 * Get canvas and basic info.
	 * @returns {Object} Object that contains canvas itself, id, width, height and padding
	 * @memberof SvgLibWrapper
	 */
	getCanvas() {
		const tmpCanvasObject = {
			canvas: this.canvas,
			id: this.canvasDetails.id,
			width: this.canvasDetails.width,
			height: this.canvasDetails.height,
			padding: this.canvasDetails.padding,
		};
		return tmpCanvasObject;
	}

	/**
	 * Draws a box with given details on active canvas.
	 * @param {Object} _boxDetails holds position, width, height
	 * @memberof SvgLibWrapper
	 */
	drawBox(_boxDetails) {
		this.canvas
		.rect()
		.attr({
			"width": _boxDetails.width,
			"height": _boxDetails.height,
			"x": _boxDetails.x,
			"y": _boxDetails.y,
			"fill": "NONE",
			"stroke": "rgb(0, 0, 0)",
			"stroke-width": 1,
		});
	}

	/**
	 * Draws a box with given details on active canvas.
	 * @param {Object} _boxDetails holds position, width, height
	 * @memberof SvgLibWrapper
	 */
	drawPort(_boxDetails) {
		this.canvas
		.rect()
		.attr({
			"width": _boxDetails.width,
			"height": _boxDetails.height,
			"x": _boxDetails.x,
			"y": _boxDetails.y,
			"fill": "rgb(0, 0, 0)",
			"stroke": "rgb(0, 0, 0)",
			"stroke-width": 1,
		});
	}

	/**
	 * Draws a text (fontSize 12) with given details inside active SVG container.
	 * @param {Object} _textDetails holds text information such as string, posX, posY
	 * @memberof SvgLibWrapper
	 */
	drawText(_textDetails) {
		const fontSize = 12;
		this.canvas
		.text(_textDetails.string)
		.attr({
			x: _textDetails.posX,
			y: _textDetails.posY + fontSize,
		})
		.font({
			family: "Arial",
			size: fontSize,
			anchor: "middle",
		});
	}

	/**
	 * Draws a rasterImage with given details inside active SVG container.
	 * @param {Object} _rasterImageDetails holds rasterImage information such as path, width, height, scale, posX, posY
	 * @memberof SvgLibWrapper
	 */
	drawRasterImage(_rasterImageDetails) {
		this.canvas
		.image(_rasterImageDetails.path)
		.attr({
			width: _rasterImageDetails.width,
			height: _rasterImageDetails.height,
			x: _rasterImageDetails.posX,
			y: _rasterImageDetails.posY,
		});
	}

	/**
	 * Draws a vectorImage with given details inside active SVG container.
	 * @param {Object} _svgImageDetails holds vectorImage information such as path, width, height, scale, posX, posY
	 * @memberof SvgLibWrapper
	 */
	async drawVectorImage(_svgImageDetails) {
		const tmpContentDocument = await _extractContentDocumentFromSourceImage(_svgImageDetails, this.domBufferContainer);
		const tmpViewBox = _extractViewBoxFromContentDocument();
		const tmpContainer = _createLocalSvgContainerInCanvas(this.canvas);
		tmpContainer.viewbox(tmpViewBox);
		_transferContent2LocalSvgContainer();

		/**
		 * Extracts the content document of a vectorImage.
		 * @param {Object} _svgImage holds vectorImage information such as path, width, height, scale, posX, posY
		 * @param {Object} _domBufferContainerId for preparing svg content document extraction
		 * @returns {Promise} thats resolved when contentDocument is extracted successfully
		 */
		function _extractContentDocumentFromSourceImage(_svgImage, _domBufferContainerId) {
			return new Promise((resolve, reject) => {
				const sourceImage = _svgImage;
				const tmpImageBuffer = _createTmpImageBuffer(_domBufferContainerId);
				const tmpImageObject = _createImageObjectInBuffer();

				tmpImageObject.addEventListener("load", () => {
					resolve(tmpImageObject.contentDocument);
					_clearTmpImageBuffer(tmpImageBuffer, _domBufferContainerId);
				});

				tmpImageObject.addEventListener("error", (error) => {
					reject(error);
					_clearTmpImageBuffer(tmpImageBuffer, _domBufferContainerId);
				});

				/**
				 * Creates a new invisible div container to buffer vectorImages.
				 * @param {String} _domBufferContainerId for preparing svg content document extraction
				 * @returns {HTMLDivElement} the new div container
				 */
				function _createTmpImageBuffer(_domBufferContainerId) {
					const tmpBuffer = document.createElement("div");
					tmpBuffer.id = "imageBuffer";
					_domBufferContainerId.appendChild(tmpBuffer);
					return tmpBuffer;
				}

				/**
				 * Draws the vectorImage with HTML-object tag.
				 * @returns {HTMLObjectElement} HTML object with drawn vector image inside
				 */
				function _createImageObjectInBuffer() {
					const tmpObject = document.createElement("object");
					tmpObject.data = sourceImage.path;
					tmpObject.width = sourceImage.width;
					tmpObject.height = sourceImage.height;
					tmpImageBuffer.appendChild(tmpObject);
					return tmpObject;
				};

				/**
				 * Deletes the invisible div container.
				 * @param {Object} _tmpImageBuffer svg container
				 * @param {Object} _domBufferContainer container for preparing svg content document extraction
				 */
				function _clearTmpImageBuffer(_tmpImageBuffer, _domBufferContainer) {
					_domBufferContainer.removeChild(tmpImageBuffer);
				};
			});
		};

		/**
		 * Extracts viewBox information from contentDocument.
		 * @returns {SVGElement} the viewBox
		 */
		function _extractViewBoxFromContentDocument() {
			return tmpContentDocument.lastChild.getAttribute("viewBox");
		};

		/**
		 * Creates a nested SVG container to transfer contentDocument in it.
		 * @param {Svg} _canvas container to draw inside
		 * @returns {SVGDocument} SVG image container
		 */
		function _createLocalSvgContainerInCanvas(_canvas) {
			return _canvas
			.nested()
			.attr({
				id: "_svgImage.id",
				width: _svgImageDetails.width,
				height: _svgImageDetails.height,
				x: _svgImageDetails.posX,
				y: _svgImageDetails.posY,
			});
		};

		/**
		 * Copies each entry of contentDocument to nested SVG container without sodipodi information.
		 */
		function _transferContent2LocalSvgContainer() {
			Array.from(tmpContentDocument.children[0].childNodes).forEach((childNode) => {
				if (!(childNode.nodeName == "sodipodi:namedview")) {														// remove inkScape information "sodipodi"
					tmpContainer.node.appendChild(childNode);
				}
				if (childNode.nodeName == "defs") {
					_appendStopsToEmptyGradients(Array.from(childNode.children));
				}
			});
		};

		/**
		 * Appends stops to a svg gradient.
		 * @param {Array<SVGElement>} defChildrenArray array of all tags in Svg def-tag
		 */
		function _appendStopsToEmptyGradients(defChildrenArray) {
			/**
			 * Structure to save all stop relevant info about a single radialGradient.
			 * @class Gradient
			 */
			class Gradient {
				/**
				 * Creates an instance of Gradient.
				 * @param {String} gradientId identifier
				 * @param {String} referencedLinearGradientId identifier
				 * @memberof Gradient
				 */
				constructor(gradientId, referencedLinearGradientId) {
					this.id = gradientId;
					this.referencedLinearGradientId = referencedLinearGradientId;
					this.stops = [];
				}
			}
			const gradients = [];
			let gradientId;
			let referencedLinearGradientId;
			defChildrenArray.forEach((childNode) => {
				// only add empty gradients ( without stops )
				if ((childNode.nodeName == "radialGradient" || childNode.nodeName == "linearGradient") && childNode.children.length == 0) {
					gradientId = childNode.id;
					const referencedLinearGradient = childNode.href.baseVal;
					referencedLinearGradientId = referencedLinearGradient.substring(1);
					gradients.push(new Gradient(gradientId, referencedLinearGradientId));
				}
			});
			gradients.forEach((gradient) => {
				defChildrenArray.forEach((childNode) => {
					if (childNode.id == gradient.referencedLinearGradientId) {
						gradient.stops = Array.from((childNode.children));
					}
				});
				defChildrenArray.forEach((childNode) => {
					if (childNode.id == gradient.id) {
						gradient.stops.forEach((stop) => {
							const clonedStop = stop.cloneNode(true);
							childNode.appendChild(clonedStop);
						});
					}
				});
			});
		};
	}

	/**
	 * Draws complete titleBlock.
	 * @memberof SvgLibWrapper
	 */
	drawTitleBlock() {
		_drawBox(this.canvas, this.titleBlockOutlineDetails, this.canvasDetails, this.titleBlockOutlineDetails);
		for (const titleBlockElementKey in this.titleBlockTemplate) {
			if (this.titleBlockTemplate.hasOwnProperty(titleBlockElementKey) && titleBlockElementKey!="titleBlockOutline") {
				const tmpElement = this.titleBlockTemplate[titleBlockElementKey];
				if (titleBlockElementKey == "assemblyName") {
					_drawBox(this.canvas, tmpElement, this.canvasDetails, this.titleBlockOutlineDetails);
					_drawAssemblyName(this.canvas, tmpElement, this.canvasDetails, this.titleBlockOutlineDetails);
				} else {
					_drawTitleBlockElement(this.canvas, tmpElement, this.canvasDetails, this.titleBlockOutlineDetails);
				}
			}
		}

		/**
		 * Draws a box with caption text (fontSize 9) and content text (fontSize 12).
		 * @param {SVG-object} _canvas to draw on
		 * @param {Object} _titleBlockElement one box of titleBlock with width, height, position, content, caption
		 * @param {Object} _canvasDetails info such aus width, height
		 * @param {Object} _titleBlockOutlineDetails info such as width, height
		 */
		function _drawTitleBlockElement(_canvas, _titleBlockElement, _canvasDetails, _titleBlockOutlineDetails) {
			const captionFontSize = 9;
			const contentFontSize = 12;
			_drawBox(_canvas, _titleBlockElement, _canvasDetails, _titleBlockOutlineDetails);
			_drawCaption(_canvas, _titleBlockElement, _canvasDetails, _titleBlockOutlineDetails);
			_drawContent(_canvas, _titleBlockElement, _canvasDetails, _titleBlockOutlineDetails);

			/**
			 * Draws a caption of a textBox, position relative to lower right corner of drawing area// position 3px from left and 9px from top corner of box.
			* @param {SVG-object} _canvas to draw on
			* @param {Object} _captionDetails contains text, position
			* @param {Object} _canvasDetails info such aus width, height
			* @param {Object} _titleBlockOutlineDetails info such as width, height
			*/
			function _drawCaption(_canvas, _captionDetails, _canvasDetails, _titleBlockOutlineDetails) {
				_canvas
				.text(getTranslation(_captionDetails.caption))
				.attr({
					x: _canvasDetails.width - _titleBlockOutlineDetails.width + _captionDetails.position.x+3,
					y: _canvasDetails.height - _titleBlockOutlineDetails.height + _captionDetails.position.y+ captionFontSize,
				})
				.font({
					family: "Arial",
					size: captionFontSize,
				});
			}

			/**
			 * Draws content of a textBox, position relative to lower right corner of drawing area // position 6px from left and 22px from top corner of box.
			 * @param {SVG-object} _canvas to draw on
			 * @param {Object} _contentDetails contains text, position and nr of lines
			 * @param {Object} _canvasDetails info such aus width, height
			 * @param {Object} _titleBlockOutlineDetails info such as width, height
			 */
			function _drawContent(_canvas, _contentDetails, _canvasDetails, _titleBlockOutlineDetails) {
				const monotypeWidth = 5.8;
				_canvas
				.text(_fitString(_contentDetails.content, _contentDetails.width-2, monotypeWidth, _contentDetails.lines))
				.attr({
					x: _canvasDetails.width - _titleBlockOutlineDetails.width + _contentDetails.position.x+6,
					y: _canvasDetails.height - _titleBlockOutlineDetails.height + _contentDetails.position.y+ captionFontSize + contentFontSize +1,
				})
				.font({
					family: "Arial",
					size: contentFontSize,
				});
			}
		}

		/**
		 * Draws a box, position relative to lower right corner of drawing area.
		 * @param {SVG-object} _canvas to draw on
		 * @param {Object} _boxDetails contains width, height, posX, posY
		 * @param {Object} _canvasDetails info such aus width, height
		 * @param {Object} _titleBlockOutlineDetails info such as width, height
		 */
		function _drawBox(_canvas, _boxDetails, _canvasDetails, _titleBlockOutlineDetails) {
			_canvas
			.rect()
			.attr({
				"width": _boxDetails.width,
				"height": _boxDetails.height,
				"x": _canvasDetails.width - _titleBlockOutlineDetails.width + _boxDetails.position.x,
				"y": _canvasDetails.height - _titleBlockOutlineDetails.height + _boxDetails.position.y,
				"fill": "NONE",
				"stroke": "rgb(0, 0, 0)",
				"stroke-width": 1,
			});
		}

		/**
		 * Draws assembly name with middle anchor and font-size 16.
		 * @param {SVG-object} _canvas to draw on
		 * @param {Object} _assemblyNameDetails contains text, position and nr of lines
		 * @param {Object} _canvasDetails info such aus width, height
		 * @param {Object} _titleBlockOutlineDetails info such as width, height
		 */
		function _drawAssemblyName(_canvas, _assemblyNameDetails, _canvasDetails, _titleBlockOutlineDetails) {
			_canvas
			.text(_fitString(_assemblyNameDetails.content, _assemblyNameDetails.width-2, 8.0, _assemblyNameDetails.lines))
			.attr({
				x: _canvasDetails.width - _titleBlockOutlineDetails.width + _assemblyNameDetails.position.x +_assemblyNameDetails.width/2,
				y: _canvasDetails.height - _titleBlockOutlineDetails.height + _assemblyNameDetails.position.y +_assemblyNameDetails.height/2 -16,
			})
			.font({
				family: "Arial",
				size: 16,
				anchor: "middle",
			});
		};

		/**
		 * Fit text to titleBlock box with ellipsis and line breaking.
		 * @param {String} _text to fit
		 * @param {Number} _targetWidth max width for _text
		 * @param {Number} _monotypeWidth width of one char
		 * @param {Number} _lines number of lines in box
		 * @returns {String} fitted _text with added "..." and line breaks
		 * @memberof SvgLibWrapper
		 */
		function _fitString(_text, _targetWidth, _monotypeWidth, _lines) {
			const textWidth = _targetWidth/_monotypeWidth;
			if (_text.length*_monotypeWidth > _targetWidth) {
				if (_lines > 1) {
					return _text.substring(0, textWidth) +"\n" +
									_fitString(_text.substring(textWidth, _text.length), _targetWidth, _monotypeWidth, _lines - 1);
				} else {
					return _text.substring(0, textWidth - 3) + "...";
				}
			} else {
				return _text;
			}
		}
	}
}
