import {GLOBALEVENTMANAGER}		from "../../applicationManager";
import {getDataNodeByUUID}		from "../../dataManager";

import $ from "jquery";
import "jquery.fancytree/dist/modules/jquery.fancytree.edit";


// for details on Fancytree-edit extension, see https://github.com/mar10/fancytree/wiki/ExtEdit


export const fancytreeEdit = {																						// direct node edit in tree
	adjustWidthOfs: 4,																		// null: don't adjust input size to content
	inputCss: {minWidth: "3em"},
	triggerStart: ["shift+click"],
	beforeEdit: $.noop,																		// Return false to prevent edit mode
	edit: $.noop,																			// Editor was opened (available as data.input)
	beforeClose: $.noop,																	// Return false to prevent cancel/save (data.input is available)
	save: $.noop,																			// Save data.input.val() or return false to keep editor open
	close: $.noop,																			// Editor was removed

	beforeEdit: (event, data) => {
		// `data.node` is about to be edited.
		// Return false to prevent this.
	},
	edit: (event, data) => {
		$(".fancytree-edit-input")[0].value = getDataNodeByUUID(data.node.key).name;		// replace fullName (incl. suffix) by simpleName (excl. suffix); needed to prevent sending fullName (incl. suffix) to dataRoot.nodeCounter
		$(".fancytree-edit-input").select();												// highlight (select) whole text on edit
		// `data.node` switched into edit mode.
		// The <input> element was created (available as jQuery object `data.input`)
		// and contains the original `data.node.title`.
	},
	beforeClose: (event, data) => {
		data.tmpTitle = data.input.val();
		data.save = false;
		// Editing is about to end (either cancel or save).
		// Additional information is available:
		// - `data.dirty`:    true if text was modified by user.
		// - `data.input`:    The input element (jQuery object).
		//                    `data.input.val()` returns the new node title.
		// - `data.isNew`:    true if this node was newly created using `editCreateNode()`.
		// - `data.orgTitle`: The previous node title text.
		// - `data.originalEvent`:
		//                    Contains the originating event (i.e. blur, mousedown, keydown).
		// - `data.save`:     false if saving is not required, i.e. user pressed
		//                    cancel or text is unchanged.
		//                    This value may be changed to override default behavior.
		// Return false to prevent this (keep the editor open), for example when
		// validations fail.
	},
	save: (event, data) => {
		// Only called when the text was modified and the user pressed enter or
		// the <input> lost focus.
		// Additional information is available (see `beforeClose`).
		// Return false to keep editor open, for example when validations fail.
		// Otherwise the user input is accepted as `node.title` and the <input>
		// is removed.
		// Typically we would also issue an Ajax request here to send the new data
		// to the server (and handle potential errors when the asynchronous request
		// returns).
	},
	close: (event, data) => {
		// Editor was removed.
		// Additional information is available (see `beforeClose`).
		GLOBALEVENTMANAGER.dispatch("eOL_RenameTreeNode", getDataNodeByUUID(data.node.key), data.tmpTitle);
	},
};
