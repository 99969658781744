import {GLOBALEVENTMANAGER, gitInfo, USER} from "../../applicationManager";
import {dataRoot, getUniqueDataNodeByType} from "../../dataManager";
import {deleteProjectsDialog, loadProjectDialog, newProjectDialog, pdfExportDialog, partsListDialog, savePartslistDialog, saveProjectDialog} from "../../dialogManager";
import {autoConfigDialog} from "../../dialogs/autoConfig";
import {globalSettingsDialog} from "../../dialogs/globalSettings";
import {createSplashScreen, toggleDrawModeHandler} from "../../guiManager";
import {getJspContainerByUUID, setSelectionMode} from "../../jsPlumb/jspManager";
import {export2Shop} from "../../partListManager";
import * as React from "react";
import {useState, useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import FolderOpen from "@mui/icons-material/FolderOpen";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import PlaylistAdd from "@mui/icons-material/PlaylistAdd";
import PlaylistRemove from "@mui/icons-material/PlaylistRemove";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TuneIcon from "@mui/icons-material/Tune";
import ViewListIcon from "@mui/icons-material/ViewList";
import {gitBranchTypeEnum} from "../../constantsAndEnumerations";
import GitInfo from "./gitInfo/gitInfo";
import MenuButton from "./menuButton/menuButton";
import MenuToggleButton from "./menuButton/menuToggleButton";
import UserAccount from "./account/account";
import {svgFile2SvgIcon} from "../helpers";
import menuBarIcons from "../../../images/menubar-icons.svg";

/** * menubar component */
export default function MenuBar(): React.ReactElement {
	const [autoConfigEnable, setAutoConfigEnable] = useState(false);
	const [partListEnable, enablePartList] = useState(false);
	const [partListSaveEnable, enablePartListSave] = useState(false);
	const [pdfExportEnable, enablePdfExport] = useState(false);
	const [export2ShopEnable, enableExport2Shop] = useState(false);
	const [marqueeButtonEnabled, enableMarqueeButton] = useState(false);

	const [marqueeButtonSelected, selectMarqueeButton] = useState(false);
	const [imageButtonSelected, selectImageButton] = useState(true);

	GLOBALEVENTMANAGER.addHandler("eDTM_ActivateGroupNodeChanged", updateButtonStates);
	GLOBALEVENTMANAGER.addHandler("eDTM_ChildDataNodeAdded", updateButtonStates);
	GLOBALEVENTMANAGER.addHandler("eDTM_ChildDataNodeRemoved", updateButtonStates);
	GLOBALEVENTMANAGER.addHandler("eJSP_DeactivateSelectMode", () => {
		selectMarqueeButton(false);
	}); //! is it the same as click on canvas?

	useEffect(() => {
		toggleDrawModeHandler(!imageButtonSelected);
	});
	useEffect(() => {
		setSelectionMode(marqueeButtonSelected);
	});

	// https://www.robinwieruch.de/react-hooks-fetch-data/
	//? Why it runs twice
	// https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar

	let HomeIcon: unknown;
	let SaveIcon: unknown;
	let ImageIcon: unknown;
	let SymbolIcon: unknown;
	let AutoconfigIcon: unknown;
	const [Home, setHomeIcon] = useState(HomeIcon);
	const [Save, setSaveIcon] = useState(SaveIcon);
	const [Image, setImageIcon] = useState(ImageIcon);
	const [Symbol, setSymbolIcon] = useState(SymbolIcon);
	const [Autoconfig, setAutoconfigIcon] = useState(AutoconfigIcon);
	useEffect(() => {
		/** fetching svg icons */
		const fetchIcons = async (): Promise<void> => {
			const fetchedSaveIcon = await svgFile2SvgIcon(menuBarIcons, "save", "Save");
			const fetchedHomeIcon = await svgFile2SvgIcon(menuBarIcons, "lq-logo", "Home");
			const fetchedImageIcon = await svgFile2SvgIcon(menuBarIcons, "image", "Image");
			const fetchedSymbolIcon = await svgFile2SvgIcon(menuBarIcons, "symbol", "Symbol");
			const fetchedAutoconfigIcon = await svgFile2SvgIcon(menuBarIcons, "autoConfig", "Autoconfig");
			if (fetchedHomeIcon) setHomeIcon(fetchedHomeIcon);
			if (fetchedSaveIcon) setSaveIcon(fetchedSaveIcon);
			if (fetchedImageIcon) setImageIcon(fetchedImageIcon);
			if (fetchedSymbolIcon) setSymbolIcon(fetchedSymbolIcon);
			if (fetchedAutoconfigIcon) setAutoconfigIcon(fetchedAutoconfigIcon);
		};

		fetchIcons();
	}, []);

	/**
	 * Enables/disables buttons
	 */
	function updateButtonStates(): void {
		if (dataRoot.activeGroupNode) {
			setAutoConfigEnable(false);
			enablePartList(false);
			enableMarqueeButton(false);
			enablePartListSave(!checkEmptyProject());
			enableExport2Shop(!checkEmptyProject());
			enablePdfExport(!checkEmptyProject());

			switch (dataRoot.activeGroupNode.nodeType) {
				case "InfrastructureNode":
					if (dataRoot.activeGroupNode.children.length >= 1 || getJspContainerByUUID("infr").children.length >= 1) {
						enableMarqueeButton(true);
						setAutoConfigEnable(true);
						enablePartList(true);
					}
					break;
				case "TrashNode":
					if (dataRoot.activeGroupNode.children.length >= 1) {
						enableMarqueeButton(true);
					}
					break;
				case "AssemblyNode":
					if (!dataRoot.activeGroupNode.trashed && dataRoot.activeGroupNode.children.length > 0) {
						enableMarqueeButton(true);
						setAutoConfigEnable(true);
						enablePartList(true);
					}
					break;
				default:
			}
		}

		/**
		 * Checks if the project contains anything (excludes empty assemblies)
		 * @returns {boolean} is project empty
		 */
		function checkEmptyProject(): boolean {
			let isEmpty = true;
			getUniqueDataNodeByType("ProjectNode").children.forEach((groupNode: {children: any[]}) => {
				if (groupNode.children.length > 0) isEmpty = false;
			});
			return isEmpty;
		}
	}

	return (
		<AppBar position="static">
			<Toolbar variant="dense">
				<ButtonGroup className="button-group">
					<MenuButton
						title="menuBar.home-tt"
						icon={Home}
						callback={(): void => {
							createSplashScreen(true);
						}}
					></MenuButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuButton title="menuBar.newProject-tt" icon={PlaylistAdd} callback={newProjectDialog}></MenuButton> {/* () => setOpenNewProjectDialog(true)  */}
					<MenuButton title="menuBar.loadProject-tt" icon={FolderOpen} callback={loadProjectDialog}></MenuButton> {/* () => setOpenLoadProjectDialog(true) */}
					<MenuButton title="menuBar.saveProject-tt" icon={Save} callback={saveProjectDialog}></MenuButton>
					<MenuButton title="menuBar.deleteProjects-tt" icon={PlaylistRemove} callback={deleteProjectsDialog}></MenuButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuButton
						title="menuBar.autoConfig-tt"
						icon={Autoconfig}
						disabled={!autoConfigEnable}
						callback={(): void => {
							autoConfigDialog(dataRoot.activeGroupNode);
						}}
					></MenuButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuButton title="menuBar.partsList-tt" icon={ViewListIcon} disabled={!partListEnable} callback={partsListDialog}></MenuButton>
					<MenuButton title="menuBar.partsListSave-tt" icon={ReceiptIcon} disabled={!partListSaveEnable} callback={savePartslistDialog}></MenuButton>
					<MenuButton title="menuBar.pdfExport-tt" icon={PictureAsPdf} disabled={!pdfExportEnable} callback={pdfExportDialog}></MenuButton>
					<MenuButton title="menuBar.export2Shop-tt" icon={AddShoppingCartIcon} disabled={!export2ShopEnable} callback={async () => export2Shop()}></MenuButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuToggleButton
						title={marqueeButtonEnabled && marqueeButtonSelected ? "menuBar.marqueeTool-active-tt" : "menuBar.marqueeTool-passive-tt"}
						icon={HighlightAltIcon}
						selected={marqueeButtonSelected}
						disabled={!marqueeButtonEnabled}
						callback={(): void => {
							selectMarqueeButton(!marqueeButtonSelected);
						}}
						value="marquee"
					></MenuToggleButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuButton
						title={imageButtonSelected ? "menuBar.drawModeToggle-passive-tt" : "menuBar.drawModeToggle-active-tt"}
						icon={imageButtonSelected ? Image : Symbol}
						callback={(): void => {
							selectImageButton(!imageButtonSelected);
						}}
					></MenuButton>
				</ButtonGroup>
				<Divider orientation="vertical" variant="middle" flexItem />
				<ButtonGroup className="button-group">
					<MenuButton title="menuBar.settings-tt" icon={TuneIcon} callback={globalSettingsDialog}></MenuButton>
				</ButtonGroup>

				<GitInfo gitInfo={gitInfo} gitClass={`${gitBranchTypeEnum[gitInfo.branchType].cssClass}`} style={{marginLeft: "auto"}} />
				<UserAccount userName={USER.fullName} />
			</Toolbar>
		</AppBar>
	);
}
