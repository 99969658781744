/* eslint-disable require-jsdoc */

import {isEqual} from "../helper";

import type {dynamicApplicationsEnum, portLayoutEnum} from "../constantsAndEnumerations";
import type {Port as ElectricPort} from "../ports/port";
import type {ElectricConnectionSourceData} from "../connections/electricConnection.schema";
import {invertPortGender} from "../ports/utils";

/**
 * Argument for ElectricConnectionCompatibilityChecker.autoCheck() method.
 * @property {boolean} debugMode - turn chatty log on/off
 * @property {boolean} forceShielded - filter out unshielded connections
 * @property {false|keyof typeof dynamicApplicationsEnum} forceDynamicApplication - force DynamicApplication
 * @property {false|keyof typeof portLayoutEnum} forceSourcePortLayout - force SourcePort Layout
 * @property {false|keyof typeof portLayoutEnum} forceTargetPortLayout - force TargetPort Layout
 */
export interface ConnectionAutoCheckConditions {
	debugMode: boolean;
	forceShielded: boolean; //! should be extracted to a filterConnections function (BUT then here we would need to check: if targetDevicePort.isShielded=false -> get shielded AND unshielded cables, if targetDevicePort.isShielded=true -> ONLY get shielded cables)
	forceDynamicApplication: false | keyof typeof dynamicApplicationsEnum; //! should be extracted to a filterConnections function
	forceSourcePortLayout: false | keyof typeof portLayoutEnum; //! should be extracted to a filterConnections function
	forceTargetPortLayout: false | keyof typeof portLayoutEnum; //! should be extracted to a filterConnections function
	forceComposition: false | string;
}

/**
 *
 */
export class CheckerAndFilterForCableDialog {
	public readonly conditions: ConnectionAutoCheckConditions = {
		debugMode: false,
		forceShielded: false,
		forceDynamicApplication: false,
		forceSourcePortLayout: false,
		forceTargetPortLayout: false,
		forceComposition: false,
	};

	/**
	 *
	 */
	constructor(_options?: Partial<ConnectionAutoCheckConditions>) {
		this.conditions = {...this.conditions, ..._options};

		Object.seal(this);
	}

	/**
	 *
	 */
	private checkSameApplication(_connection: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		const result = _port1.application === _connection.application && _port2.application === _connection.application;

		if (this.conditions.debugMode && !result) {
			const referencePortsApplication = [_port1, _port2].map((port) => port.application);
			// eslint-disable-next-line no-console
			console.error(`checkSameApplication: Ports ${referencePortsApplication} | Connection [(${String(_connection.application)}]`);
		}

		return result;
	}

	/**
	 *
	 */
	private checkPluggable(_connection: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		const referencePorts = [_port1, _port2].map((_port) => `${_port.family}:${invertPortGender(_port.gender)}`); // results in eg ["XTEC15:MALE", "OPEN_FEMALE"]
		const connectionPorts = _connection.ports.map((_port) => `${_port.family}:${_port.gender}`); // results in eg ["M12", "XTEC15:MALE"]

		const result = isEqual(referencePorts, connectionPorts);

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(`checkPluggable: Ports [${referencePorts}] | Connection [${connectionPorts}]`);
		}

		return result;
	}

	/**
	 *
	 */
	private checkLeads(_connection: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = true;

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");

		const connectionTargetPort = _connection.ports.find((port) => port.gender === invertPortGender(sourceDevicePort.gender) && port.family === sourceDevicePort.family);
		const connectionSourcePort = _connection.ports.find(
			(port) => port.gender === invertPortGender(targetDevicePort.gender) && port.family === targetDevicePort.family && port !== connectionTargetPort,
		);

		// check if there are enough leads of the correct role for the given interface on each part of the connection
		for (const _interface of targetDevicePort.interfaces) {
			const requiredLeads = _interface.requiredLeads;
			const role = targetDevicePort.leads.find((lead) => lead.mappedInterface === _interface).role;

			const connectionSourcePortLeads = connectionSourcePort.leads.filter((leads) => leads.role === role).length;
			if (connectionSourcePortLeads < requiredLeads) {
				result = false;
				break;
			}

			const connectionLeads = _connection.leads.filter((leads) => leads.role === role).length;
			if (connectionLeads < requiredLeads) {
				result = false;
				break;
			}

			const connectionTargetPortLeads = connectionTargetPort.leads.filter((leads) => leads.role === role).length;
			if (connectionTargetPortLeads < requiredLeads) {
				result = false;
				break;
			}
		}

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(
				`checkLeads: SourceDevicePort [${targetDevicePort.composition}] | Connection [${_connection.composition}] | TargetDevicePort [${targetDevicePort.composition}]`,
			);
		}

		return result;
	}

	/**
	 *
	 */
	private checkElectrics(_connection: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = true;

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");

		const connectionTargetPort = _connection.ports.find((port) => port.gender === invertPortGender(sourceDevicePort.gender) && port.family === sourceDevicePort.family);
		const connectionSourcePort = _connection.ports.find(
			(port) => port.gender === invertPortGender(targetDevicePort.gender) && port.family === targetDevicePort.family && port !== connectionTargetPort,
		);

		// check if there are correct currentMax/voltageMax for the given interface on each part of the connection
		for (const _targetDeviceInterface of targetDevicePort.interfaces) {
			const role = targetDevicePort.leads.find((lead) => lead.mappedInterface === _targetDeviceInterface).role;

			// get all leads of the correct role for the given interface
			const connectionTargetPortLeads = connectionTargetPort.leads.find((lead) => lead.role === role);
			const connectionLeads = _connection.leads.find((lead) => lead.role === role);
			const connectionSourcePortLeads = connectionSourcePort.leads.find((lead) => lead.role === role);

			// check voltage from sourceDevicePort: connectionTargetPort -> connection -> connectionSourcePort
			const sourceDevicePortInterfaceVoltage = sourceDevicePort.interfaces.find((_interface) => _interface.type.type === _targetDeviceInterface.type.type).voltage;

			if (connectionTargetPortLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			if (connectionLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			if (connectionSourcePortLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			// check current from targetDevicePort: connectionSourcePort -> connection -> connectionTargetPort
			const targetDevicePortInterfaceCurrent = targetDevicePort.interfaces.find((_interface) => _interface.type.type === _targetDeviceInterface.type.type).current;

			if (connectionSourcePortLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}

			if (connectionLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}

			if (connectionTargetPortLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}
		}

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(`checkElectrics: voltageMax/currentMax mismatch in connectionTargetPort|connection|connectionSourcePort.`);
		}

		return result;
	}

	/**
	 *
	 */
	private filterShielded(_connection: ElectricConnectionSourceData): boolean {
		if (this.conditions.forceShielded == null) return true;
		else if (this.conditions.forceShielded) return _connection.isShielded;
		else if (!this.conditions.forceShielded) return !_connection.isShielded;
	}

	/**
	 *
	 */
	private filterLayout(_connection: ElectricConnectionSourceData): boolean {
		if (this.conditions.forceSourcePortLayout === false && this.conditions.forceTargetPortLayout === false)
			return true; // accept all layouts if forceSourcePortLayout/forceTargetPortLayout is not set
		else if (this.conditions.forceSourcePortLayout === false && _connection.ports[1].layout.type === this.conditions.forceTargetPortLayout) return true;
		else if (_connection.ports[0].layout.type === this.conditions.forceSourcePortLayout && this.conditions.forceTargetPortLayout === false) return true;
		else if (_connection.ports[0].layout.type === this.conditions.forceSourcePortLayout && _connection.ports[1].layout.type === this.conditions.forceTargetPortLayout)
			return true;

		return false;
	}

	/**
	 *
	 */
	private filterDynamics(_connection: ElectricConnectionSourceData): boolean {
		if (this.conditions.forceDynamicApplication !== false) return _connection.dynamicApplication === this.conditions.forceDynamicApplication;

		return true; // accept all dynamicApplications if forceDynamicApplication is not set
	}

	/**
	 *
	 */
	private filterComposition(_connection: ElectricConnectionSourceData): boolean {
		if (this.conditions.forceComposition !== false) return _connection.composition === this.conditions.forceComposition;

		return true;
	}

	/**
	 * Runs all compatibility checks
	 * @param {ElectricConnectionSourceData} _connection connection to check
	 * @param {ElectricPort} _port1 first port to check
	 * @param {ElectricPort} _port2 second port to check
	 * @returns {boolean} result
	 */
	public autoCheck(_connection: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = this.checkSameApplication(_connection, _port1, _port2);
		if (result) result = this.checkPluggable(_connection, _port1, _port2);
		if (result) result = this.checkLeads(_connection, _port1, _port2);
		if (result) result = this.checkElectrics(_connection, _port1, _port2);

		return result;
	}

	/**
	 * Runs all filter checks
	 * @param {ElectricConnectionSourceData} _connection connection to check
	 * @returns {boolean} result
	 */
	public autoFilter(_connection: ElectricConnectionSourceData): boolean {
		let result = this.filterShielded(_connection);
		if (result) result = this.filterLayout(_connection);
		if (result) result = this.filterDynamics(_connection);
		if (result) result = this.filterComposition(_connection);

		return result;
	}
}
