/**
 * Realizes a central object to provide program-wide event functionality
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */


/** System-wide handling of events */
export class EventManager {
	/** Creates a new instance of EventManager. */
	constructor() {
		this.events = {};													// stores all local events
	}

	/**
	 * Dispatches an event
	 * @param {string} _eventName name of event to dispatch
	 * @param {object} _args arguments of event
	 */
	dispatch(_eventName, ..._args) {
		const event = this.events[_eventName];
		if (event) {														// check if event exists
			event.raiseEvent(..._args);
		}
	}

	/**
	 * Add handler to provided event
	 * @param {string} _eventName of this event
	 * @param {Function} _callback that is attached to this event
	 * @returns {object} eventName and callback function (a way to store the dataBinding and remove it later via removeHandler, even for anonymous functions)
	 */
	addHandler(_eventName, _callback) {
		let event = this.events[_eventName];

		if (!event) {														// create new event if it does not (already) exist
			event = new CustomEvent(_eventName);
			this.events[_eventName] = event;
		}

		event.registerCallback(_callback);									// add the callback to the event
		return {event: _eventName, callback: _callback};
	}

	/**
	 * Remove handler from provided event.
	 * @param {string} _eventName of this event.
	 * @param {Function} _callback that is attached to this event.
	 */
	removeHandler(_eventName, _callback) {
		const tmpEvent = this.events[_eventName];
		if (tmpEvent && tmpEvent.callbacks.some((callback) => callback === _callback)) {		// Check that the event exists and it has the callback registered
			tmpEvent.unregisterCallback(_callback);							// if it is registered then unregister it!

			if (tmpEvent.callbacks.length === 0) {								// if the event has no callbacks left, delete the event
				delete this.events[_eventName];
			}
		}
	}
}

/** custom event implementation */
class CustomEvent {
	/**
	 * Creates a new instance of CustomEvent.
	 * @param {string} _eventName name of event
	 */
	constructor(_eventName) {
		this.eventName = _eventName;
		this.callbacks = [];												// stores all callback function to the event
	}


	/**
	 * register a callback function to the event
	 * @param {Function} _callback that should be called on Event
	 */
	registerCallback(_callback) {
		this.callbacks.push(_callback);
	}


	/**
	 * unregister a callback function of the event
	 * @param {Function} _callback that gets removed from Event
	 */
	unregisterCallback(_callback) {
		this.callbacks = this.callbacks.filter((callback) => callback !== _callback);
	}


	/**
	 * Raises event and execute all registered callback functions
	 * @param {object} _args Arguments of Event
	 */
	raiseEvent(..._args) {
		const callbacks = this.callbacks.slice(0); 							// loop over a cloned version of the callbacks array in case the original array is spliced while looping
		callbacks.forEach((callback) => {									// loop through the callbacks and call each one
			callback(..._args);
		});
	}
}
