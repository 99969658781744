/* eslint-disable require-jsdoc */
import {GLOBALEVENTMANAGER}		from "../../applicationManager";
import {fancytreeDnd5}				from "./fancytree-dnd5";
import {fancytreeEdit}				from "./fancytree-edit";

import $ from "jquery";


// for details on Fancytree, see https://github.com/mar10/fancytree/wiki/
// options configurator, see http://wwwendt.de/tech/fancytree/demo/sample-configurator.html


export function createFancytree(_container) {
	_container.fancytree({
		debugLevel: 0, // 0:quiet, 1:normal, 2:debug
		tooltip: false,
		nodata: false,
		keyboard: false,																					// prevent keyboard navigation
		clickFolderMode: 1,																				// single click activates (double click toggle expands group nodes by dblclick event, see below)
		source: [],																								// start with an empty source
		minExpandLevel: 1,																				// top-level nodes (project, infrastructure, trash) are collapsible

		// --- treeNode events ---
		activate: (event, data) => {
			const node = data.node;
			$("#echoActive").text(node.title);
			if (!$.isEmptyObject(node.data)) {
			// throw new Error("Empty treeNode");											// handling empty treeNodes
			}
		},

		click: (event, data) => {
			GLOBALEVENTMANAGER.dispatch("eOL_Click", data.node.key);
		// return false to prevent default behavior (i.e. activation, ...)
		},

		dblclick: (event, data) => {
			data.node.toggleExpanded();															// toggle expand group nodes
		},

		focus: (event, data) => {
			$("echoFocused").text(data.node.title);
		},

		extensions: ["dnd5", "edit"],
		dnd5: fancytreeDnd5,
		edit: fancytreeEdit,
	});
}
