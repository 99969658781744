import {languageEnum} from								"./constantsAndEnumerations";
import {checkExistence} from							"./helper";
import {cId2jqSel} from										"./helper";
import {checkUniquenessLEGACY} from				"./helper";
import {switchLanguage} from							"./localization/localizationManager";


// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;

/** Basic statusbar containing a message area for user-feedback and a language selector
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/** Adds a language selector to a parent container
 * @param  {string} _parentId parent container to add language selector to
 */
export function createLanguageSwitcher(_parentId) {
	checkExistence(_parentId);																						// verify _parentId exists
	checkUniquenessLEGACY("languageSwitcherShell");												// verify languageSwitcher is unique

	$("<div>", {																		// outer container
		id: "languageSwitcherShell",
		class: "languageSwitcherShell flex-container noselect",
	}).appendTo(cId2jqSel(_parentId));

	$("<img>", {																		// icon to click on
		id: "languageSwitcherIcon",
		src: "",
		class: "languageSwitcherIcon noselect",
	}).appendTo(cId2jqSel("languageSwitcherShell"));

	$("<div>", {																		// dropdown menu containing all available languages
		id: "languageSelection",
		class: "languageSelection flex-container noselect",
	}).appendTo(cId2jqSel("languageSwitcherShell"));
}


/** Adds a new element (flag) to languageSwitcher
 * @param  {languageEnum} _language to add
 */
export function addElementToLanguageSwitcher(_language) {
	checkExistence("languageSwitcherShell");													// verify languageSwitcher exists
	checkExistence("languageSelection");															// verify languageSelector exists
	checkUniquenessLEGACY(_language.type);														// verify language has not been added yet

	$("<img>", {																				// flag icon
		id: _language.type,
		src: _language.flag,
		click: function()	{
			switchLanguage(_language);													// TODO should be replaced by an event
		},
		class: "flag noselect",
	}).appendTo(cId2jqSel("languageSelection"));
}


/** Switches the languageSwitcher icon when a flag has been selected (clicked)
 * @param  {languageEnum} _language to switch to
 */
export function updateLanguageSwitcherIcon(_language) {
	cId2jqSel("languageSwitcherIcon")[0].src = _language.flag;
}

/** Creates a statusBar inside an arbitrary parent DOM element */
export class StatusBar {
	/** Standard constructor
	 * @param  {String} _id of statusBar that gets created
	 * @param  {String} _containerId to nest into
	 */
	constructor(_id, _containerId) {
		this.id = checkUniqueness(_id);													// ensure no other statusBar exists (el cheapo singleton)
		this.containerId = checkExistenceNew(_containerId);
		this.container = {};
		this.languageSwitcher = {};
		this.createDomStructure();
	}

	/** creates the needed DOM elements for the menuBar */
	createDomStructure() {
		this.container = document.createElement("div");
		this.container.id = this.id;
		this.container.classList.add("statusBar", "noselect");
		getDomElementById(this.containerId).appendChild(this.container);

		this.languageSwitcher = document.createElement("div");
		this.languageSwitcher.id = "languageSwitcherShell";
		this.languageSwitcher.classList.add("languageSwitcherShell");
		this.container.appendChild(this.languageSwitcher);

		const tmpImg = document.createElement("img");
		tmpImg.id = "languageSwitcherIcon";
		tmpImg.src = {};
		tmpImg.classList.add("languageSwitcherIcon");
		this.languageSwitcher.appendChild(tmpImg);

		const tmpDropDown = document.createElement("div");
		tmpDropDown.id = "languageSelection";
		tmpDropDown.classList.add("languageSelection");
		this.languageSwitcher.appendChild(tmpDropDown);
	}

	/** Adds a new element (flag) to languageSwitcher
 * @param  {languageEnum} _language to add
 */
	addLanguage(_language) {
		checkExistence("languageSwitcherShell");													// verify languageSwitcher exists
		checkExistence("languageSelection");															// verify languageSelector exists
		checkUniquenessLEGACY(_language.type);														// verify language has not been added yet

		$("<img>", {																											// flag icon
			id: _language.type,
			src: _language.flag,
			click: function()	{
				switchLanguage(_language);																		// TODO should be replaced by an event
			},
			class: "flag noselect",
		}).appendTo(cId2jqSel("languageSelection"));
	}
}
