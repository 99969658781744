/* eslint-disable require-jsdoc */
import {isEqual} from "../helper";
import {invertPortGender} from "../ports/utils";

import type {dynamicApplicationsEnum, portLayoutEnum} from "../constantsAndEnumerations";
import type {Port as ElectricPort} from "../ports/port";
import type {ElectricConnectionSourceData} from "../connections/electricConnection.schema";

/**
 * Argument for ElectricConnectionCompatibilityChecker.autoCheck() method.
 * @property {boolean} debugMode - turn chatty log on/off
 * @property {boolean} forceShielded - filter out unshielded connections
 * @property {false|keyof typeof dynamicApplicationsEnum} forceDynamicApplication - force DynamicApplication
 * @property {false|keyof typeof portLayoutEnum} forceSourcePortLayout - force SourcePort Layout
 * @property {false|keyof typeof portLayoutEnum} forceTargetPortLayout - force TargetPort Layout
 */
export interface ConnectionAutoCheckConditions {
	debugMode: boolean;
	forceShielded: boolean; //! should be extracted to a filterConnections function (BUT then here we would need to check: if targetDevicePort.isShielded=false -> get shielded AND unshielded cables, if targetDevicePort.isShielded=true -> ONLY get shielded cables)
	forceDynamicApplication: false | keyof typeof dynamicApplicationsEnum; //! should be extracted to a filterConnections function
	forceSourcePortLayout: false | keyof typeof portLayoutEnum; //! should be extracted to a filterConnections function
	forceTargetPortLayout: false | keyof typeof portLayoutEnum; //! should be extracted to a filterConnections function
}

/**
 *
 */
export class ElectricConnectionCompatibilityChecker {
	public readonly conditions: ConnectionAutoCheckConditions = {
		debugMode: false,
		forceShielded: false,
		forceDynamicApplication: false,
		forceSourcePortLayout: false,
		forceTargetPortLayout: false,
	};

	/**
	 *
	 */
	constructor(_options?: Partial<ConnectionAutoCheckConditions>) {
		this.conditions = {...this.conditions, ..._options};

		Object.seal(this);
	}

	/**
	 *
	 */
	private __checkSameApplication(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		const result = _port1.application === _electricConnectionSourceData.application && _port2.application === _electricConnectionSourceData.application;

		if (this.conditions.debugMode && !result) {
			const referencePortsApplication = [_port1, _port2].map((port) => port.application);
			// eslint-disable-next-line no-console
			console.error(`checkSameApplication: Ports ${referencePortsApplication} | Connection [${_electricConnectionSourceData.application}]`);
		}

		return result;
	}

	/**
	 *
	 */
	private __checkPluggable(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		const referencePorts = [_port1, _port2].map((_port) => `${_port.family}:${invertPortGender(_port.gender)}`); // results in eg ["XTEC15:MALE", "OPEN_FEMALE"]
		const connectionPorts = _electricConnectionSourceData.ports.map((_port) => `${_port.family}:${_port.gender}`); // results in eg ["M12", "XTEC15:MALE"]

		const result = isEqual(referencePorts, connectionPorts);

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(`checkPluggable: Ports [${referencePorts}] | Connection [${connectionPorts}]`);
		}

		return result;
	}

	/**
	 *
	 */
	private __checkShielded(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		if (this.conditions.forceShielded) return _electricConnectionSourceData.isShielded;

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");

		const result = targetDevicePort.isShielded === _electricConnectionSourceData.isShielded;

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(
				`checkShielded: forceShielded [${this.conditions.forceShielded}]| sourceDevicePort [${sourceDevicePort.isShielded}] | targetDevicePort [${targetDevicePort.isShielded}] | Connection [${_electricConnectionSourceData.isShielded}]`,
			);
		}

		return result;
	}

	/**
	 *
	 */
	private __checkLeads(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = true;

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");

		const connectionTargetPort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(sourceDevicePort.gender) && port.family === sourceDevicePort.family,
		);
		const connectionSourcePort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(targetDevicePort.gender) && port.family === targetDevicePort.family && port !== connectionTargetPort,
		);

		// check if there are enough leads of the correct role for the given interface on each part of the connection
		for (const _interface of targetDevicePort.interfaces) {
			const requiredLeads = _interface.requiredLeads;
			const role = targetDevicePort.leads.find((lead) => lead.mappedInterface === _interface).role;

			const connectionSourcePortLeads = connectionSourcePort.leads.filter((leads) => leads.role === role).length;
			if (connectionSourcePortLeads < requiredLeads) {
				result = false;
				break;
			}

			const connectionLeads = _electricConnectionSourceData.leads.filter((leads) => leads.role === role).length;
			if (connectionLeads < requiredLeads) {
				result = false;
				break;
			}

			const connectionTargetPortLeads = connectionTargetPort.leads.filter((leads) => leads.role === role).length;
			if (connectionTargetPortLeads < requiredLeads) {
				result = false;
				break;
			}
		}

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(
				`checkLeads: SourceDevicePort [${targetDevicePort.composition}] | Connection [${_electricConnectionSourceData.composition}] | TargetDevicePort [${targetDevicePort.composition}]`,
			);
		}

		return result;
	}

	/**
	 *
	 */
	private __checkElectrics(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = true;

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");

		const connectionTargetPort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(sourceDevicePort.gender) && port.family === sourceDevicePort.family,
		);
		const connectionSourcePort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(targetDevicePort.gender) && port.family === targetDevicePort.family && port !== connectionTargetPort,
		);

		// check if there are correct currentMax/voltageMax for the given interface on each part of the connection
		for (const _targetDeviceInterface of targetDevicePort.interfaces) {
			const role = targetDevicePort.leads.find((lead) => lead.mappedInterface === _targetDeviceInterface).role;

			// get all leads of the correct role for the given interface
			const connectionTargetPortLeads = connectionTargetPort.leads.find((lead) => lead.role === role);
			const connectionLeads = _electricConnectionSourceData.leads.find((lead) => lead.role === role);
			const connectionSourcePortLeads = connectionSourcePort.leads.find((lead) => lead.role === role);

			// check voltage from sourceDevicePort: connectionTargetPort -> connection -> connectionSourcePort
			const sourceDevicePortInterfaceVoltage = sourceDevicePort.interfaces.find((_interface) => _interface.type.type === _targetDeviceInterface.type.type).voltage;

			if (connectionTargetPortLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			if (connectionLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			if (connectionSourcePortLeads.voltageMax < sourceDevicePortInterfaceVoltage) {
				result = false;
				break;
			}

			// check current from targetDevicePort: connectionSourcePort -> connection -> connectionTargetPort
			const targetDevicePortInterfaceCurrent = targetDevicePort.interfaces.find((_interface) => _interface.type.type === _targetDeviceInterface.type.type).current;

			if (connectionSourcePortLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}

			if (connectionLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}

			if (connectionTargetPortLeads.currentMax < targetDevicePortInterfaceCurrent) {
				result = false;
				break;
			}
		}

		if (this.conditions.debugMode && !result) {
			// eslint-disable-next-line no-console
			console.error(`checkElectrics: voltageMax/currentMax mismatch in connectionTargetPort|connection|connectionSourcePort.`);
		}

		return result;
	}

	/**
	 *
	 */
	private __checkLayout(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		if (this.conditions.forceSourcePortLayout === false && this.conditions.forceTargetPortLayout === false) return true; // accept all layouts if forceSourcePortLayout/forceTargetPortLayout is not set

		const sourceDevicePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetDevicePort = [_port1, _port2].find((port) => port.side === "TARGET");
		const connectionTargetPort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(sourceDevicePort.gender) && port.family === sourceDevicePort.family,
		);
		const connectionSourcePort = _electricConnectionSourceData.ports.find(
			(port) => port.gender === invertPortGender(targetDevicePort.gender) && port.family === targetDevicePort.family && port !== connectionTargetPort,
		);

		if (this.conditions.forceSourcePortLayout !== false && connectionSourcePort.layout !== this.conditions.forceSourcePortLayout) return false;
		if (this.conditions.forceTargetPortLayout !== false && connectionTargetPort.layout !== this.conditions.forceTargetPortLayout) return false;

		return true;
	}

	/**
	 *
	 */
	private __checkDynamics(_electricConnectionSourceData: ElectricConnectionSourceData): boolean {
		if (this.conditions.forceDynamicApplication !== false) return _electricConnectionSourceData.dynamicApplication === this.conditions.forceDynamicApplication;

		return true; // accept all dynamicApplications if forceDynamicApplication is not set
	}

	/**
	 *
	 */
	public autoCheck(_electricConnectionSourceData: ElectricConnectionSourceData, _port1: ElectricPort, _port2: ElectricPort): boolean {
		let result = this.__checkSameApplication(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkPluggable(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkShielded(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkLeads(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkElectrics(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkLayout(_electricConnectionSourceData, _port1, _port2);
		if (result) result = this.__checkDynamics(_electricConnectionSourceData);

		return result;
	}
}
