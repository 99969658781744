import {Application} from "../constantsAndEnumerations2";
import type {DynamicApplication} from "../constantsAndEnumerations2";
import {BaseConnection} from "./BaseConnection";
import type {ElectricConnectionData, ElectricConnectionSaveData} from "./electricConnection.schema";

import {ElectricInterfaceMapper} from "../interfaces/ElectricInterfaceMapper";
import type {Lead} from "../interfaces/ElectricInterfaceMapper";
import type {Interface} from "../interfaces/interface";

/** Class describing an electric connection between two Nodes. */
export class ElectricConnection extends BaseConnection {
	private readonly __composition: string;
	private readonly __dynamicApplication: DynamicApplication; //REFACTOR should be a keyof typeof dynamicApplicationEnum
	private readonly __isShielded: boolean;
	private readonly __leadMap: ElectricInterfaceMapper;

	/**
	 * Creates a new instance of ElectricConnection
	 * @param {ElectricConnectionData} _connectionData  dataset describing an electric connection
	 */
	constructor(_connectionData: ElectricConnectionData) {
		super(_connectionData);

		this.__composition = _connectionData.composition;
		this.__dynamicApplication = _connectionData.dynamicApplication;
		this.__isShielded = _connectionData.isShielded;
		this.__leadMap = new ElectricInterfaceMapper(_connectionData.leads);

		Object.seal(this);
	}

	/**
	 * Returns a string representation (e.g. '3x1.5+2x1.5') of leads of this electrical connection.
	 * @returns {string} representation of leads of this electrical connection
	 */
	get composition(): string {
		return this.__composition;
	}

	/**
	 * Returns the dynamicApplication property of this connection.
	 */
	get dynamicApplication(): DynamicApplication {
		return this.__dynamicApplication;
	}

	/**
	 * Returns the isShielded property of this connection.
	 */
	get isShielded(): boolean {
		return this.__isShielded;
	}

	/**
	 * Returns the leads of this electrical connection.
	 */
	get leads(): Lead[] {
		return this.__leadMap.leads;
	}

	/**
	 * Adds an interface to this connection.
	 * @param {Interface} _interface interface to add
	 */
	protected addInterface(_interface: Interface): void {
		this.__leadMap.mapInterface(_interface);
		super.addInterface(_interface);
	}

	/**
	 * Removes an interface from this connection.
	 * @param {Interface} _interface interface to remove
	 */
	protected removeInterface(_interface: Interface): void {
		super.removeInterface(_interface);
		this.__leadMap.unmapInterface(_interface);
	}

	/**
	 * Returns the variable characteristics of this electrical connection.
	 * @returns {ElectricConnectionSaveData} object containing all info
	 */
	public save(): ElectricConnectionSaveData {
		return {
			...super.save(),
			application: Application.ELECTRIC, //REFACTOR this should be handled by BaseConnection
			isShielded: this.isShielded,
			dynamicApplication: this.dynamicApplication,
			ports: this.ports.map((_electricPort) => {
				const tmpPortSave = _electricPort.save();
				_electricPort.interfaces = []; // don't save interfaces on connections and their ports
				return tmpPortSave;
			}),
		};
	}
}
