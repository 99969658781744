// launching chrome temporarily with another Language from cmd: start chrome --lang=ru --user-data-dir=c:\chrome-profile-ru
//! @CL: Clean this mess up. (Stanislav has changed the way resources are loaded and lots of stuff in here is not needed anymore. You can also probably remove getTranslationResources from backendAdapter)
//! @CL: How does the new http backend handle async and lazy loading of resources?

// TODO 1:	Change this code to async/await (including the Request inside communicationManager)
// TODO 2:	Make this file a class (like BackendAdapter)
// TODO 3:	Add a switch to hook i18next to a debug mode (like the backendAdapter)
// TODO 4:	Use eslint-i18next (helps you find hardcoded strings that should be translated via i18next)
//					->	https://github.com/edvardchen/eslint-plugin-i18next
// TODO 6:	Find a way to cache already loaded translations on the client side (LanguageDetector does this by default, something into some cache dir)
//?							maybe we should calculate a hash for each translation file to determine if anything changed and if an update of the local client files is necessary
//?							we could try to code that on our own. but i18next also provides some plugins for that
//					->	https://github.com/i18next/i18next-localstorage-backend
//?							There is also a webpack loader. We could compose our translation files during webpack build!
//					->	https://github.com/atroo/i18next-resource-store-loader
//					->	https://github.com/Perlmint/ya-i18next-webpack-plugin

// TODO 7:	Find a way to minimize/avoid unnecessary loading of translations (pE don't always force load chinese translations)
//?							Lazy Loading:
//					->	https://www.i18next.com/how-to/add-or-load-translations
//					->	https://linguinecode.com/post/lazy-loading-react-i18next-translations
//					->	https://github.com/i18next/i18next/issues/771
//?							That probably means using a different backend, like:
//					->	https://github.com/i18next/i18next-chained-backend
//					->	https://github.com/i18next/i18next-http-backend
//					->	https://github.com/dotcore64/i18next-fetch-backend

// TODO 8:	Change all i18nKey to translationKey and all i18nValue to translationValue
// TODO 9:	Move languageSwitcher GUI element to menubar (statusbar is for messages only!)
// TODO 10:	numeral/moment/etc in i18next as a formatter plugin

// REFACTOR Don't always fetch all the languages. Fetch the user.language or the browser language and only fetch another language if the user choses to!

// --------------------------------------------------------------------------------------------------------------------------------

import {BACKEND, USER, GLOBALEVENTMANAGER} from "../applicationManager";
import {languageEnum} from "../constantsAndEnumerations";
import {NumberFormatter} from "./numberFormatter";

import {flatten} from "flat";
import i18next from "i18next"; //? https://www.i18next.com/overview/getting-started, https://react.i18next.com/legacy-v9/step-by-step-guide
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import HttpApi from "i18next-http-backend";

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {string} i18nKey
 */
/* eslint-enable jsdoc/require-property */

export const formatter = new NumberFormatter();

/**
 * Standard initialization routine (loading translations, setting up i18next, event handlers etc..)
 * TODO You need to init numeral as well!
 * @param  {languageEnum} _initialLanguage to set
 * @param  {boolean} [_debugMode=false] chatty initialization
 */
export function initializeLocalizationManager(_initialLanguage, _debugMode = false) {
	if (_debugMode) {
		// eslint-disable-next-line no-console
		console.debug("%cDebug Mode: true", "color: green");
		window.getTranslation = getTranslation;
	}

	i18next
		.use(initReactI18next) // passes i18n down to react-i18next
		.use(HttpApi)
		.use(LanguageDetector)
		.init(
			{
				detection: {
					order: ["navigator"],
					// https://github.com/i18next/i18next-browser-languageDetector#detector-numeral.options
					// with caches: ["cookie"] browser remembers last language set by user
					caches: [], // empty array prevents caching of LanguageDetector results
				},
				returnEmptyString: false,
				supportedLngs: Object.values(languageEnum).map((value) => value.type),
				fallbackLng: languageEnum.en.type,
				debug: _debugMode,
				nsSeparator: false,
				interpolation: {
					escapeValue: false, // react already safes from xss
				},
				backend: {
					loadPath: `${location.href}assets/translations/{{lng}}.json`,
				},
			},
			(err) => {
				if (err) {
					console.log("something went wrong loading", err);
					return;
				}
				// Only for non-registered users: set USER.language to language detected by i18next.LanguageDetector
				if (_initialLanguage === null) {
					// reconstruct languageEnum member from currently active i18next language
					let tmpActiveLanguage = Object.values(languageEnum).filter((tmpValue) => tmpValue.type === i18next.language)[0];
					if (!tmpActiveLanguage) tmpActiveLanguage = i18next.options.fallbackLng;
					USER.language = tmpActiveLanguage;
				}
			},
		);
}

/**
 * Checks if a given i18nKey exists for the active and fallback language and returns the keys value for the currently active language
 * @param {string} _translationKey to check
 * @param {object} [_dynamicValues=null] Object of key-value pairs to get injected dynamically into the translated string, //! object destructuring syntax required
 * @returns {string} translated value for given _i18nKey
 */
export function getTranslation(_translationKey, _dynamicValues = null) {
	_checkValidity(_translationKey);
	// _checkExistence(_translationKey); //! deactivated due to missing translations of device names

	// if (_forceLanguage != null) {	//! deactivated because it's unused atm
	// 	return i18next.t(_translationKey, _dynamicValues, {lng: _forceLanguage.type});
	// }

	return i18next.t(_translationKey, _dynamicValues); // i18next automatically returns the fallback language and/or the _i18nKey if no translations were found

	/**
	 * Checks if a translationKey exists, warns on console if not
	 * @param {string} _translationKey to check
	 */
	function _checkExistence(_translationKey) {
		// eslint-disable-next-line no-console
		if (!i18next.exists(_translationKey)) console.warn("No translation found for: ", i18next.t(_translationKey, {lng: i18next.language}));
	}

	/**
	 * Checks if a given translationKey is a valid string (not undefined, null or sth else)
	 * @param {string} _translationKey to check
	 */
	function _checkValidity(_translationKey) {
		// eslint-disable-next-line no-console
		if (typeof _translationKey !== "string") console.error(`translationKey "${_translationKey}" invalid`);
	}
}

/**
 * Switches the active language and raises an event to forces an update of all localizable elements
 * @param  {languageEnum} _language to switch to
 */
export async function switchLanguage(_language) {
	/*	Some trickery here:
			- general idea: skip translation if app is already set to _language (happens if user selects same language successively)
			- but: on app start, i18next is initialized with a certain language, though no "eTM_Translate" Event has been fired
			-> we have to fire the event, even if i18next.language == _language
			- on app start, i18next.previousLng is always initialized to null, this enables us to indirectly check
				if the app is just starting for the first time and bypass the return statement
			- on every successive call of switchLanguage, i18next.previousLng is not null, therefore the if statement is called and successively calling the same language is prevented
	*/
	if (i18next.previousLng != null) {
		// on application start, i18next is initialized
		if (i18next.language === _language.type) return; // skip if app is already set to _language (happens if user selects same language successively)
	}

	i18next.previousLng = i18next.language;

	await i18next.changeLanguage(_language.type);
	GLOBALEVENTMANAGER.dispatch("eTM_Translate", _language);
	document.title = getTranslation("app-title"); // update the caption of the browser tab
	USER.language = _language;
}

/**
 * Checks if a given string is a valid (i18next) translationValue associated to a valid (i18next) translationKey
 * Used to prevent translation of user provided captions, names, etc...
 * @param {string} _translationValue to check
 * @returns {boolean} result of inquiry
 */
export function isDefaultTranslationValue(_translationValue) {
	// get translation resource for the previous language (isDefaultTranslationValue only gets called after the current language is already updated via the global translation event)
	const tmpTranslationData = flatten(i18next.services.resourceStore.data[i18next.previousLng].translation);

	let result = false;
	Object.values(tmpTranslationData).forEach((tmpValue) => {
		if (tmpValue === _translationValue) {
			result = true;
		}
	});
	return result;
}

/**
 * Hooks an arbitrary DOM elements property to the eTM_Translate event
 * @param  {string} _DOMElement	to bind with
 * @param  {string} _elementProperty to bind with
 * @param  {i18nKey} _i18nIdentifier i18n to bin elementProperty to
 */
export function bindDomElementToTranslateEvent(_DOMElement, _elementProperty, _i18nIdentifier) {
	// TODO restore check for i18nKey validity
	// Check if (at least) the default EN fallback json-structure contains the provided key
	// if (!enTranslation.hasOwnProperty(_i18nIdentifier)) {
	// 	throw new Error(`i18nKey: "${_i18nIdentifier}" does not exist in DE json structure`);
	// }

	GLOBALEVENTMANAGER.addHandler("eTM_Translate", () => {
		if (_elementProperty === "text") {
			// handle different dom attributes
			_DOMElement.text(getTranslation(_i18nIdentifier)); // TODO that is fishy: it should be: _DOMElement.text = getTranslation(_i18nIdentifier);
		} else if (_elementProperty === "textContent") {
			_DOMElement.textContent = getTranslation(_i18nIdentifier);
		} else if (_elementProperty === "data") {
			_DOMElement.data = getTranslation(_i18nIdentifier);
		} else if (_elementProperty === "title") {
			_DOMElement.title = getTranslation(_i18nIdentifier);
		} else if (_elementProperty === "placeholder") {
			_DOMElement.placeholder = getTranslation(_i18nIdentifier);
		} else {
			_DOMElement.attr(_elementProperty, getTranslation(_i18nIdentifier)); // update text on event
		}
	});
}
