// import {eRelocateDataNode}							from "./dataManager";
import {applicationRoot}									from "./applicationManager";
import {GLOBALEVENTMANAGER}								from "./applicationManager";
import {eDeleteDataNode}									from "./dataManager";
import {eClearDataNode}										from "./dataManager";
import {eNewAssemblyNode}									from "./dataManager";
import {getDataNodeByUUID}								from "./dataManager";
import {getConnectionByUUID}							from "./dataManager";
import {autoConfigDialog}									from "./dialogs/autoConfig";
import {eStartEditPath}										from "./jsPlumb/jspManager";
import {eStopEditPath}										from "./jsPlumb/jspManager";
import {getJspContainerByJspNodeUUID}			from "./jsPlumb/jspManager";
import {getJspContainerByUUID}						from "./jsPlumb/jspManager";
import {getTranslation}										from "./localization/localizationManager";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;


/* Adds a context menu (right click) to the outliner (may be used for other context-menu functionality too, though)
 *
 * UTILIZING:
 *		jQuery-contextMenu, details under https://swisnl.github.io/jQuery-contextMenu/
 *
 * TODO:
 *		add icons for menu items
 *		it would be convenient if jspNode.data also included info like clearable, deletable, shoppable similar to treeNodes (or we put this data into the dataNodes - that way we would have all necessary info in one place)
 *		implement grouping functionality
 *		implement ungrouping functionality
 *		tweak canvasContextMenu: assemblyJspNode don't get recognized if you click the name span (only the image works atm)
 *		from partsList: when clicking "show in shop" a nasty regex is used to identify the material number. We should instead rely on tje boundDataColumn attribute that tables provide
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/** standard initialization routine (mainly setting event handlers) */
export function initializeContextMenuManager() {
	createOutlinerContextMenu();
	createNodeContextMenu();
	createCanvasContextMenu();
	createCableContextMenu();
	createPartsListContextMenu();
	GLOBALEVENTMANAGER.addHandler("eCM_DeleteNode", eDeleteDataNode);
	GLOBALEVENTMANAGER.addHandler("eCM_StartEditPath", eStartEditPath);
	GLOBALEVENTMANAGER.addHandler("eCM_StopEditPath", eStopEditPath);
	GLOBALEVENTMANAGER.addHandler("eCM_ClearNode", eClearDataNode);
	// GLOBALEVENTMANAGER.addHandler("eCM_GroupNodes", eRelocateDataNode);
	// GLOBALEVENTMANAGER.addHandler("eCM_UnGroupNodes", eRelocateDataNode);
	GLOBALEVENTMANAGER.addHandler("eCM_CreateAssemblyNode", eNewAssemblyNode);
	// GLOBALEVENTMANAGER.addHandler("eCM_ExportData", eExportData);
	// GLOBALEVENTMANAGER.addHandler("eCM_ImportData", eImportData);
}


/** Creates an outliner context-menu and sets event handling on user interaction */
function createOutlinerContextMenu() {
	$.contextMenu({
		selector: "span.fancytree-title",
		zIndex: 100000000,
		animation: {duration: 250, show: "fadeIn", hide: "fadeOut"},
		autoHide: true,
		build: function($trigger, e) {														// dynamic building of context-menu (this callback is executed every time the menu is to be shown, its results are destroyed every time the menu is hidden)
			const tmpNode = getDataNodeByUUID($.ui.fancytree.getNode($trigger).key);
			return {
				callback: function(key, options) {},
				items: {
					"properties": propertyEntry(tmpNode),
					// "portDefinition": portDefinitionEntry(tmpNode),
					"shop": shopEntry(tmpNode),
					"sep0": "----",
					"delete": deleteEntry(tmpNode),
					"clear": clearEntry(tmpNode),
					"sep1": "----",
					"group": groupEntry(tmpNode),
					"ungroup": ungroupEntry(tmpNode),
					"sep2": "----",
					"newAssemblyNode": newAssemblyNodeEntry(tmpNode),
					"sep3": "----",
					"importData": importDataEntry(tmpNode),
					"exportData": exportDataEntry(tmpNode),
					"sep4": "----",
					"autoConfig": autoConfigEntry(tmpNode),
				},
			};
		},
	});
}

/** Creates a canvas context-menu and sets event handling on user interaction */
function createNodeContextMenu() {
	$.contextMenu({
		selector: "#canvasContainer div.jtk-node",
		zIndex: 100000000,
		animation: {duration: 250, show: "fadeIn", hide: "fadeOut"},
		autoHide: true,
		build: function($trigger, e) {														// dynamic building of context-menu (this callback is executed every time the menu is to be shown, its results are destroyed every time the menu is hidden)
			const tmpNode = getDataNodeByUUID($trigger[0].dataset.uuid);
			return {
				callback: function(key, options) {},
				items: {
					"properties": propertyEntry(tmpNode),
					// "portDefinition": portDefinitionEntry(tmpNode),
					"shop": shopEntry(tmpNode),
					"sep0": "----",
					"delete": deleteEntry(tmpNode),
					"clear": clearEntry(tmpNode),
					"sep1": "----",
					"group": groupEntry(tmpNode),
					"ungroup": ungroupEntry(tmpNode),
					"sep2": "----",
					"autoConfig": autoConfigEntry(tmpNode),
				},
			};
		},
	});
}

/** TODO Refactor */
function createCanvasContextMenu() {
	$.contextMenu({
		selector: ".jtk-surface",	// TODO assemblyJspNode don't get recognized if you click the name span
		// zIndex: 100000000,
		animation: {duration: 250, show: "fadeIn", hide: "fadeOut"},
		autoHide: true,
		build: function($trigger, e) {														// dynamic building of context-menu each time
			// this callback is executed every time the menu is to be shown
			// its results are destroyed every time the menu is hidden
			// e is the original canvasContextMenu event, containing e.pageX and e.pageY (amongst other data)

			const tmpJspContainer = getJspContainerByUUID($trigger[0].UUID);
			return {
				callback: function(key, options) {},
				items: {
					"FitAllIn": {name: getTranslation("contextMenu.fitAllIn"), icon: "TODO",
						disabled: function(key, options) {
							// Atm there are no conditions that call for prevention of FitAllIn
						},
						callback: function(key, options) {
							tmpJspContainer.surface.zoomToFit();
						},
					},
					"Paste": {name: getTranslation("contextMenu.pasteJspNode"), icon: "TODO",
						disabled: function(key, options) {
							return true;
							// Wenn kein JspNode im Speicher
							// wenn activeCanvas kein zulässiges Ziel für paste ist
						},
						callback: function(key, options) {
							console.debug("PASTE");
						},
					},
					"ToggleMarquee": {name: getTranslation("menuBar.marqueeTool-passive-tt"), icon: "TODO",		// mb-btn-marqueeTool-active-tt switch!
						disabled: function(key, options) {
							return true;
						// Atm there are no conditions that call for prevention of rename
						},
						callback: function(key, options) {
							menuBar.getElementById("btn_marqueeTool").setMode(true);
						},
					},
				},
			};
		},
	});
}

/** TODO Refactor */
function createCableContextMenu() {
	$.contextMenu({
		selector: ".base-edge",
		zIndex: 100000000,
		animation: {duration: 250, show: "fadeIn", hide: "fadeOut"},
		autoHide: true,
		build: function($trigger, e) {														// dynamic building of context-menu each time
			// this callback is executed every time the menu is to be shown
			// its results are destroyed every time the menu is hidden
			// e is the original canvasContextMenu event, containing e.pageX and e.pageY (amongst other data)

			const tmpConnection = getConnectionByUUID($trigger[0]._jsPlumb.edge.data.UUID);

			return {
				callback: function(key, options) {},
				items: {
					"properties": propertyEntry(tmpConnection),
					"shop": shopEntry(tmpConnection),
					"edit": editPath(tmpConnection),
					"sep0": "----",
					"delete": deleteCable(tmpConnection),
				},
			};
		},
	});
}

/** TODO Refactor */
function createPartsListContextMenu() {
	$.contextMenu({
		selector: "#consumerList_table .cControl-table-data-row, #functionList_table .cControl-table-data-row, #cableList_table .cControl-table-data-row",
		zIndex: 100000000,
		animation: {duration: 250, show: "fadeIn", hide: "fadeOut"},
		autoHide: true,
		build: function($trigger, e) {																														// dynamic building of context-menu each time
			// this callback is executed every time the menu is to be shown
			// its results are destroyed every time the menu is hidden
			// e is the original canvasContextMenu event, containing e.pageX and e.pageY (amongst other data)
			// const elementId =

			const regex = /^\d{7,}-[A-Za-z0-9]{5}$/;																								// regex to find default avista material number: 7 or more digits + dash + exactly 5 chars or digits
			const tmpElement = {materialNumber: null, interactionPreset: {shoppable: true}};

			$trigger[0].childNodes.forEach((tmpCell) => {																						// check all cells in row for regex match
				if (regex.test(tmpCell.innerText)) tmpElement.materialNumber = tmpCell.innerText;
			});

			return {
				callback: function(key, options) {},
				items: {
					"shop": shopEntry(tmpElement),
				},
			};
		},
	});
}


/* ======================================== CONTEXTMENU ELEMENTS ======================================== */


const autoConfigEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.autoConfig"),
		icon: "TODO",

		disabled: function(key, options) {
			if (tmpNode.getTrashed()) return true;
			if (tmpNode.nodeType != "AssemblyNode" && tmpNode.nodeType != "InfrastructureNode") return true;
			if (tmpNode.nodeType == "AssemblyNode" && tmpNode.children.length == 0) return true;
			if (tmpNode.nodeType == "InfrastructureNode" && getJspContainerByUUID(tmpNode.UUID).children.length == 0) return true;
			return false;
		},
		callback: function(key) {
			autoConfigDialog(tmpNode);
		},
	};
};

const clearEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.clear"),
		icon: "TODO",

		disabled: function(key, options) {
			return !tmpNode.interactionPreset.clearable;
		},
		callback: function(key) {
			GLOBALEVENTMANAGER.dispatch("eCM_ClearNode", tmpNode);
		},
	};
};

const deleteEntry = (tmpElement) => {				// maybe/better merge with deleteCable?
	return {
		name: getTranslation("contextMenu.delete"),
		icon: "delete",

		disabled: function(key, options) {
			return !tmpElement.interactionPreset.deletable;
		},
		callback: function(key, options, e) {
			const tmpDeletePermanently = e.shiftKey;								// holding shift deletes dataNodes permanently (instead of trashing)
			const selectedNodes = getSelectedNodes(tmpElement.UUID);
			if (selectedNodes.length > 0) {
				selectedNodes.forEach((node) => {
					if (tmpElement.getTrashed()) {
						GLOBALEVENTMANAGER.dispatch("eCM_DeleteNode", node, true);
					} else {
						GLOBALEVENTMANAGER.dispatch("eCM_DeleteNode", node, tmpDeletePermanently);
					}
				});
			} else {
				if (tmpElement.getTrashed()) {
					GLOBALEVENTMANAGER.dispatch("eCM_DeleteNode", tmpElement, true);
				} else {
					GLOBALEVENTMANAGER.dispatch("eCM_DeleteNode", tmpElement, tmpDeletePermanently);
				}
			}
		},
	};
};

/** Gets marqueed nodes list
 * @param {String} _uuid of the right-clicked device
 * @returns {Array} of selected elements if the right-clicked device also selected
*/
function getSelectedNodes(_uuid) {
	const res = [];
	const jspSelectedNodes = getJspContainerByJspNodeUUID(_uuid).instance.getSelection().getNodes();
	let isSelected = false;
	if (jspSelectedNodes && jspSelectedNodes.length > 0) {
		jspSelectedNodes.forEach((node) => {
			res.push(getDataNodeByUUID(node.data.UUID));
			if (node.data.UUID == _uuid) isSelected = true;
		});
	}
	if (isSelected) return res;
	else return [];
}

const deleteCable = (tmpConnection) => {			// maybe/better merge with deleteEntry?
	return {
		name: getTranslation("contextMenu.delete"),
		icon: "delete",

		disabled: function(key, options) {
			return false;
		},
		callback: function(key, options, e) {
			GLOBALEVENTMANAGER.dispatch("eCM_RemoveConnection", tmpConnection.UUID);
		},
	};
};

const editPath = (tmpConnection) => {
	return {
		name: getTranslation(tmpConnection.editStarted ? "contextMenu.stopEditPath" : "contextMenu.startEditPath"),
		icon: "edit",

		disabled: function(key, options) {
			return false;
		},
		callback: function(key, options, e) {
			tmpConnection.editStarted ? GLOBALEVENTMANAGER.dispatch("eCM_StopEditPath", tmpConnection.UUID) : GLOBALEVENTMANAGER.dispatch("eCM_StartEditPath", tmpConnection.UUID);
		},
	};
};

const groupEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.group"),
		icon: "TODO",

		disabled: function(key, options) {
			return true;					// ToDo: not trivial since you need a way to multi-select items...)
		},
		callback: function(key) {
			console.debug(`${tmpNode.getName()}: implement node grouping `);
		},
	};
};

const importDataEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.importData"),
		icon: "TODO",

		disabled: function(key, options) {
			return true;												// TODO working but momentarily always disabled (we need some clever routine to guide the user that the hierarchy enforces you to only embedded certain dataNode types in other, pE only deviceNodes and UnitNodes in AssemblyNodes)
		},
		callback: function(key) {
			GLOBALEVENTMANAGER.dispatch("eCM_ImportData", tmpNode);
		},
	};
};

const exportDataEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.exportData"),
		icon: "TODO",

		disabled: function(key, options) {
			return true;												// TODO working but momentarily always disabled (right now would overwrite the saved project)
		},
		callback: function(key) {
			GLOBALEVENTMANAGER.dispatch("eCM_ExportData", tmpNode);
		},
	};
};

const ungroupEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.ungroup"),
		icon: "TODO",

		disabled: function(key, options) {
			return true;					// ToDo formulate a fitting condition (not trivial! what to do with children when there is not other assembly?)
		},
		callback: function(key) {
			console.debug(`${tmpNode.getName()}: implement node ungrouping `);
		},
	};
};

const newAssemblyNodeEntry = (tmpNode) => {
	return {
		name: getTranslation("contextMenu.newAssembly"),
		icon: "TODO",

		disabled: function(key, options) {
			if (tmpNode.nodeType == "ProjectNode") {
				return false;
			} else {
				return true;
			}
		},
		callback: function(key, options, e) {
			GLOBALEVENTMANAGER.dispatch("eCM_CreateAssemblyNode");
		},
	};
};


const propertyEntry = (tmpElement) => {
	return {
		name: getTranslation("contextMenu.properties"),
		icon: "TODO",

		disabled: function(key, options) {
			if (tmpElement.nodeType == "TrashNode") return true;
		},
		callback: function(key, options) {
			GLOBALEVENTMANAGER.dispatch("OpenDeviceDialog", tmpElement);
		},
	};
};

const shopEntry = (tmpElement) => {
	return {
		name: getTranslation("contextMenu.shop"),
		icon: "TODO",

		disabled: function(key, options) {
			if (tmpElement.materialNumber == null) return true;		// deactivate shop link for devices without material number
			return !tmpElement.interactionPreset.shoppable;
		},
		callback: function(key) {
			const orderNumber = tmpElement.materialNumber;
			window.open(`${applicationRoot.shopURL}/search?sSearch=${orderNumber}`);
		},
	};
};
