export const genericElectricConnectionTemplate = {
	application: "ELECTRIC",
	group: "cables",
	isArchived: false,
	referenceDesignator: {
		deviceComponent: {
			token: "W",
		},
	},
	description: "Für diese Verbindung existiert kein Produkt in der Datenbank.",
	databaseId: 0,
	manufacturer: "-",
	materialNumber: "0000000-xxxxx",
	name: "Platzhalterkabel",
	cleanName: "Platzhalterkabel",
	i18nKey: null,
	price: null,
	graphics: null,
	dynamicApplication: null,																					// set dynamically
	length: 1000,
	minLength: 300,
	maxLength: 999990,
	isShielded: null,																									// set dynamically
	leads: [],																												// set dynamically
	composition: null,																								// set dynamically
	ports: [],																												// set dynamically
	interfaces: [],
};
