/** Class representing a modal background
 * Used as dialog / splashScreen background
 * @class Modal
 */
export class Modal {
	/** Creates an instance of Modal
	 * @memberof Modal
	 */
	constructor() {
		this.domContainer = document.createElement("div");														// top level container (=background) of modal dialog
		this.domContainer.classList.add("modal-background");
		document.getElementById("root").appendChild(this.domContainer);
	}

	/** Removes this modal from the DOM
	 * @memberof Modal
	 */
	remove() {
		this.domContainer.remove();
	}
}
