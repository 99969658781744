import {GLOBALEVENTMANAGER, MESSENGER} from "./applicationManager";
import {makeConnectorArrayByPort} from "./communicationDialog";
import {getPortByUUID, getDataNodeByUUID} from "./dataManager";
import {cId2jqSel, checkExistenceNew, checkUniqueness, checkFunctionExistenceNew, searchArrayForElementByKeyValuePair} from "./helper";
import {interfaceFactory} from "./interfaces/interfaceFactory";
import {bindDomElementToTranslateEvent, getTranslation} from "./localization/localizationManager";
import {Port} from "./ports/port";
import {portFactory} from "./ports/portFactory";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;

/* Central structure for creation and manipulation of (modal) dialogs
 *
 * UTILIZING:
 *	joi-browser validation library, details under https://github.com/jeffbski/joi-browser, https://github.com/hapijs/joi
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *		Stanislav Biryukov
 */

/** Defines a standardized modalDialog */
export class ModalDialog {
	/**
	 * Standard constructor
	 * @param  {i18nKey} _heading text to display as dialog heading
	 */
	constructor(_heading) {
		this.validated = false; // all dialogs get initialized with a negative validation status
		this.heading = _heading;
		this.header = "modal-dialog-header";
		this.contentArea = "modal-dialog-body";
		this.footer = "modal-dialog-footer";
		this.buttons = [];
		this.controls = [];
		this.dataBindings = []; // list of all dataBindings defined for this dialog
		this.tabs = [];
		this.createDomStructure();
	}

	/** creates the needed DOM elements for the modalDialog */
	createDomStructure() {
		$("<div>", {
			// top level container (= background) of modal dialog
			id: "modal",
			class: "modal-background",
		}).appendTo(cId2jqSel("root"));

		$("<div>", {
			// the actual dialog window
			id: "modal-dialog",
			class: "modal-dialog",
		}).appendTo(cId2jqSel("modal"));

		$("<div>", {
			// dialog window header
			id: this.header,
			text: getTranslation(this.heading),
			class: "modal-dialog-header noselect",
		}).appendTo(cId2jqSel("modal-dialog"));

		$("<div>", {
			// dialog window content
			id: this.contentArea,
			class: "modal-dialog-body",
		}).appendTo(cId2jqSel("modal-dialog"));

		$("<div>", {
			// dialog window footer
			id: this.footer,
			class: "modal-dialog-footer",
		}).appendTo(cId2jqSel("modal-dialog"));
	}

	/**
	 * Adds an image
	 * @param  {string} _id image id
	 * @param  {string} _src image source
	 * @param  {number} _width image width
	 * @param  {number} _height image height
	 */
	addImage(_id, _src, _width, _height) {
		$("<img>", {
			id: _id,
			class: "image-cable",
			src: _src,
			title: "",
			width: _width,
			height: _height,
		}).appendTo(cId2jqSel(this.contentArea));
		bindDomElementToTranslateEvent(cId2jqSel(_id), "src", _src);
	}

	/**
	 * Adds a standard button to the dialog
	 * @param  {DialogButtonBase} _button to add
	 * @param  {Function} _callbackFunction to call on button.click
	 */
	addButton(_button, _callbackFunction) {
		this.buttons.push(_button);
		this.updateValidity();
	}

	/**
	 * Adds a customControl to this dialog
	 * @param  {CustomBaseControl} _control to add to the dialog
	 */
	addControl(_control) {
		// Iterate through all existing controls of this dialog and check if the newly added _control is the first control with focusable=true and setFocus accordingly
		let setFocus = true;
		for (let i = 0; i < this.controls.length; i++) {
			if (this.controls[i].focusable == true) setFocus = false;
		}

		if (setFocus) _control.focus();

		_control.parentDialog = this;

		// add _control
		this.controls.push(_control);
		if (_control.id != "spacer") {
			GLOBALEVENTMANAGER.addHandler(`${_control.id}_ValidityChanged`, (_control) => {
				this.updateValidity();
			});
			this.registerDataBinding(
				GLOBALEVENTMANAGER.events[`${_control.id}_ValidityChanged`].eventName,
				GLOBALEVENTMANAGER.events[`${_control.id}_ValidityChanged`].callbacks[0],
			);
			this.updateValidity();
		}
	}

	/** Cancel this dialog */
	cancelDialog() {
		this.removeDialog();
	}

	/** Confirm this dialog and raise callbacks of all nested controls */
	confirmDialog() {
		this.controls.forEach((control) => {
			control.executeCallBack();
		});
		this.removeDialog();
	}

	/** Removes this dialog and all of its children from DOM, ultimately destroying the whole object via garbage collector */
	removeDialog() {
		this.unregisterAllDataBindings();
		cId2jqSel("modal").remove();
	}

	/** Iterates over all child-controls, checks their local validity and sets the dialog validity accordingly */
	updateValidity() {
		let tmpValidationStatus = true;
		this.controls.forEach((item) => {
			if (!item.validated) tmpValidationStatus = false;
		});

		this.buttons.forEach((button) => {
			button.disable(tmpValidationStatus);
		});

		this.validated = tmpValidationStatus;
	}

	/** Forces this dialog into invalid mode regardless of local controls */
	forceInvalidity() {
		this.validated = false;
		const tmpConfirmButton = this.buttons.find((button) => button.buttonId === "confirmButton");
		tmpConfirmButton.disable();
	}

	/**
	 * Searches controls in this modal by controlId
	 * @param  {string} _id of control to find
	 * @returns {CustomControl} with matching id
	 */
	getControlById(_id) {
		let result = false;
		this.controls.forEach((e) => {
			if (e.id == _id) result = e;
		});
		return result;
	}

	/**
	 * Establishes a one-way dataBinding between two controls of this Dialog
	 * @param  {string} _sourceControlId to send data
	 * @param  {string} _targetControlId to receive data
	 * @param  {Function} _targetHandler (Optional) specify a certain eventHandler (useful for binding nested controls)
	 */
	setControlBinding(_sourceControlId, _targetControlId, _targetHandler = "setEventHandler") {
		if (!this.getControlById(_sourceControlId)) throw new Error(`A source control with ID "${_sourceControlId}" does not exist in this dialog!`);
		if (!this.getControlById(_targetControlId)) throw new Error(`A target control with ID "${_targetControlId}" does not exist in this dialog!`);
		const tmpSourceControl = this.getControlById(_sourceControlId);
		const tmpTargetControl = this.getControlById(_targetControlId);
		const event = tmpSourceControl.getEvent();
		const callback = tmpTargetControl[_targetHandler](event);
		this.registerDataBinding(event, callback);
	}

	/**
	 * Helper called by children to register their bindings
	 * @param  {string} _eventName to register
	 * @param  {Function} _callback hooked to eventName
	 */
	setLocalDataBinding(_eventName, _callback) {
		const tmpBinding = GLOBALEVENTMANAGER.addHandler(_eventName, _callback);
		this.registerDataBinding(tmpBinding.event, tmpBinding.callback);
	}

	/**
	 * Helper to register dialog scoped bindings
	 * @param  {string} _eventName to register
	 * @param  {Function} _callback hooked to eventName
	 */
	registerDataBinding(_eventName, _callback) {
		this.dataBindings.push({
			eventName: _eventName,
			callback: _callback,
		});
	}

	/** Removes all local data bindings previously set in the scope of this dialog (applied when closing this dialog)*/
	unregisterAllDataBindings() {
		this.dataBindings.forEach((tmpBinding) => {
			GLOBALEVENTMANAGER.removeHandler(tmpBinding.eventName, tmpBinding.callback);
		});
	}
}

/** Defines a modal panel as a child part of the ModalDialogUnit (aggregate) */
export class AccordionModalPanel extends ModalDialog {
	/**
	 * Standard constructor
	 * @param  {string} _AccordionShellId - accordion container id
	 * @param  {string} _itemId - accordion id
	 * @param  {string} _headerId - accordion header id
	 * @param  {string} _side - source or target
	 * @param  {number} _portIndex - port index
	 * @param  {number} _interfaceIndex - interface index
	 */
	constructor(_AccordionShellId, _itemId, _headerId, _side, _portIndex, _interfaceIndex) {
		super("");
		this.heading = "";
		this.footer = "";
		this.contentArea = _itemId;
		this.containerId = _AccordionShellId;
		this.id = _itemId;
		this.header = _headerId;
		this.side = _side;
		this.portIndex = _portIndex;
		this.interfaceIndex = _interfaceIndex;
		this.validationIndicator = _headerId + "_validationIndicator";
		// this.connector = _headerId + "_connector";
	}

	/** the structure is already done on accordion creating */
	createDomStructure() {}

	/** set div as a handle for interface accordion */
	addAccordion() {
		this.interfacesAccordion = cId2jqSel("aggregate_interfaces_" + this.side + "_port_" + this.portIndex);
	}

	/** removes this panel */
	remove() {
		cId2jqSel(this.header).remove();
		cId2jqSel(this.contentArea).remove();
	}

	/** Iterates over all child-controls, checks their local validity and sets the panel and dialog validity accordingly */
	updateValidity() {
		let tmpValidationStatus = true;
		this.controls.forEach((control) => {
			if (!control.validated) tmpValidationStatus = false;
		});
		if (tmpValidationStatus) {
			cId2jqSel(this.validationIndicator).removeClass("cControl-ValidationIndicator-error");
			if (this.interfaceIndex !== null) GLOBALEVENTMANAGER.dispatch("childValidityChanged", false, this.side, this.portIndex, this.interfaceIndex);
		} else {
			cId2jqSel(this.validationIndicator).addClass("cControl-ValidationIndicator-error");
			if (this.interfaceIndex !== null) GLOBALEVENTMANAGER.dispatch("childValidityChanged", true, this.side, this.portIndex, this.interfaceIndex);
		}
		GLOBALEVENTMANAGER.dispatch("validateModal");
	}

	/**
	 * Sets header text
	 * @param {string} _text to set
	 */
	setHeaderText(_text) {
		cId2jqSel(this.containerId)
			.find("[title=" + _text + "]")
			.text(_text);
	}
}

/** Defines a modal tab as a child part of the ModalDialog */
export class ModalDialogTabs {
	// extends ModalDialog
	/**
	 * Standard constructor
	 * @param  {string} _containerId - tabs container id
	 * @param  {string} _itemId - tabs id
	 * @param  {string} _headerId - tabs header id
	 * @param  {string} _contentId - tabs content id
	 * @param  {object} _parent - parent object, i.e. ModalDialog // to delete??
	 */
	constructor(_containerId, _itemId, _headerId, _contentId, _parent) {
		// super("");
		this.contentArea = _contentId;
		this.id = _itemId;
		this.header = _headerId;
		// this.validationIndicator = _headerId + "_validationIndicator";
		this.container = cId2jqSel(_containerId)[0];
		this.headingWrapper = {};
		this.contentWrapper = {};

		this.tabs = _parent.tabs;
		this.createDomStructure();
		// this.setActiveTab(this.tabs[0]);
	}

	/** DOM structure for tabs */
	createDomStructure() {
		this.tabsContainer = document.createElement("div");
		this.tabsContainer.setAttribute("id", this.id);
		this.tabsContainer.classList.add("cable-tabs-content", "noselect");
		this.container.appendChild(this.tabsContainer);

		this.headingWrapper = document.createElement("div");
		this.headingWrapper.setAttribute("id", this.header);
		this.headingWrapper.classList.add("cable-tabs-heading-wrapper");
		this.tabsContainer.appendChild(this.headingWrapper);

		this.contentWrapper = document.createElement("div");
		this.contentWrapper.setAttribute("id", this.contentArea);
		this.contentWrapper.classList.add("cable-tabs-content-wrapper");
		this.tabsContainer.appendChild(this.contentWrapper);
	}

	/**
	 * Adds tab
	 * @param {string} _tabId id of the tab to add
	 * @param {string} _tabName name of the tab to add
	 * @returns {ModalDialogTab} newTab
	 */
	addTab(_tabId, _tabName) {
		// this.interfacesAccordion = cId2jqSel("aggregate_interfaces_"+this.side+"_port_"+this.portIndex);
		const newTab = new ModalDialogTab(_tabId, this.header, _tabName, this.contentArea);
		cId2jqSel(_tabId + "_cContainer").on("click", () => {
			this.setActiveTab(newTab);
		});
		return newTab;
	}

	/**
	 * Removes tab
	 * @param {string} _tabId id of the tab to remove
	 */
	removeTab(_tabId) {
		cId2jqSel(_tabId + "_cContainer").off("click");
		cId2jqSel(_tabId + "_cContainer").remove();
		cId2jqSel(_tabId).remove();
	}

	/** tabs have no validation indicator, but each tab has one */
	updateValidity() {}

	/**
	 * Sets active tab
	 * @param {string} _tab to activate
	 */
	setActiveTab(_tab) {
		if (_tab == this.activeTab || _tab.isDisabled) return; // skip if _tab is already active
		if (this.activeTab) this.activeTab.setActive(false); // skip if this.activeTab is empty (only during initialization)
		this.activeTab = _tab;
		this.activeTab.setActive(true);
	}
}

/** Defines a modal panel as a child part of the ModalDialogUnit (aggregate) */
export class ModalDialogTab extends ModalDialog {
	/**
	 * Standard constructor
	 * @param  {string} _itemId - new tab id
	 * @param  {string} _headerId - tab header container id
	 * @param  {i18nKey} _i18name text to display as tab name
	 * @param  {object} _tabsContainer - tabs content container id
	 */
	constructor(_itemId, _headerId, _i18name, _tabsContainer) {
		super(_i18name);
		this.id = _itemId;
		this.contentArea = _itemId;
		this.heading = this.id + "_cContainer";
		this.accordionPanels = {};
		this.accordionPanels.source = [];
		this.accordionPanels.target = [];
		this.footer = "";
		this.content = "";
		this.container = document.getElementById(_tabsContainer); // ??
		this.headerId = _headerId;
		this.label = _i18name;
		this.validationIndicator = _itemId + "_cValidationIndicator";
		this.extendDomStructure();
	}

	/** the structure does not match, see extendDomStructure */
	createDomStructure() {}

	/** the real structure*/
	extendDomStructure() {
		this.header = document.getElementById(this.headerId);
		this.headContainer = document.createElement("div");
		this.headContainer.setAttribute("id", this.heading);
		this.headContainer.classList.add("cable-heading-tab"); // , "active"
		this.header.appendChild(this.headContainer);

		/* 		$("<div>", {										// control container
			id: `${this.id}_cContainer`,
			class: "cControl-Container noselect",
		}).appendTo(cId2jqSel(this.containerId)); */

		$("<span>", {
			// control label TODO translate into JS
			id: `${this.id}_cLabel`,
			text: getTranslation(this.label),
			class: "cControl-Label",
		}).appendTo(this.headContainer);

		$("<image>", {
			// control validationIndicator
			id: `${this.id}_cValidationIndicator`,
			class: "cControl-ValidationIndicator-Cable",
			// style: "display: inline-block;",
			title: "",
		}).appendTo(this.headContainer);

		this.content = document.createElement("div");
		this.content.setAttribute("id", this.contentArea);
		this.content.classList.add("cable-tab-content");
		this.container.appendChild(this.content);
	}

	/** Iterates over all child-controls, checks their local validity and sets the panel and dialog validity accordingly */
	updateValidity() {
		let tmpValidationStatus = true;
		this.controls.forEach((control) => {
			if (!control.validated) tmpValidationStatus = false;
		});
		if (tmpValidationStatus) {
			cId2jqSel(this.validationIndicator).removeClass("cControl-ValidationIndicator-error");
		} else {
			cId2jqSel(this.validationIndicator).addClass("cControl-ValidationIndicator-error");
		}
		this.validated = tmpValidationStatus;
		GLOBALEVENTMANAGER.dispatch("validateModal");
	}

	/**
	 * Switches this tab active mode
	 * @param {boolean} _active mode to set
	 */
	setActive(_active) {
		if (this.isActive == _active) return; // skip if tab is already _active
		if (_active) {
			this.headContainer.classList.add("active");
			this.content.classList.add("active");
		} else {
			this.headContainer.classList.remove("active");
			this.content.classList.remove("active");
		}
		this.isActive = _active;
	}

	/**
	 * Switches this tab disable mode
	 * @param {boolean} _disabled mode to set
	 * @param {string} _tooltip to set
	 */
	setDisabled(_disabled, _tooltip = "") {
		if (this.isDisabled == _disabled) return; // skip if tab is already _disabled
		if (_disabled) {
			this.headContainer.classList.add("disabled");
			this.headContainer.title = getTranslation(_tooltip);
			this.headContainer.classList.remove("active");
			this.content.classList.remove("active");
		} else {
			this.headContainer.classList.remove("disabled");
			this.headContainer.title = getTranslation(_tooltip);
		}
		this.isDisabled = _disabled;
	}
}

/** Defines a wider modal dialog */
export class ModalDialogWide extends ModalDialog {
	/**
	 * Standard constructor
	 * @param  {i18nKey} _heading text to display as dialog heading
	 */
	constructor(_heading) {
		super(_heading);
		// replace existing label class with a smaller version
		document.getElementById("modal-dialog").classList.remove("modal-dialog");
		document.getElementById("modal-dialog").classList.add("modal-dialog-wide");
	}
}

/** Defines a modal dialog for cables */
export class ModalDialogCable extends ModalDialog {
	/**
	 * Standard constructor
	 * @param  {i18nKey} _heading text to display as dialog heading
	 * @param  {DataNode} _dataNode data node
	 * @param  {DataNode} _tmpDataNode temp data node
	 */
	constructor(_heading, _dataNode, _tmpDataNode) {
		super(_heading);
		this.dataNode = _dataNode;
		this.tmpDataNode = _tmpDataNode;
		this.accordionPanels = {}; // placeholder for child dialog panels
		this.accordionPanels.source = [];
		this.accordionPanels.target = [];
		document.getElementById("modal-dialog").classList.remove("modal-dialog");
		document.getElementById("modal-dialog").classList.add("modal-dialog-cable");
		this.setLocalDataBinding("validateModal", () => {
			this.updateValidity();
		});
		this.setLocalDataBinding("childValidityChanged", this.updateChildValidity);
	}

	/** Confirm this dialog and raise callbacks of all nested controls */
	confirmDialog() {
		this.controls.forEach((control) => {
			control.executeCallBack();
		});

		//  for lower hierarchy tabs
		this.executeChildCallBacks();

		this.removePanelEvents();
		this.destroyAccordions();
		this.removeDialog();
	}

	/** Cancel this dialog */
	cancelDialog() {
		this.removePanelEvents();
		this.destroyAccordions();
		this.removeDialog();
	}

	/** Iterates over all child-controls, checks their local validity and sets the dialog validity accordingly */
	updateValidity() {
		// TODO
		let tmpValidationStatus = true;
		this.controls.forEach((item) => {
			if (!item.validated) tmpValidationStatus = false;
		});
		this.tabs.forEach((item) => {
			let tabValidationStatus = true;
			item.controls.forEach((control) => {
				if (!control.validated) {
					tabValidationStatus = false;
					tmpValidationStatus = false;
				}
			});

			// for lower hierarchy panels TODO better
			if (tabValidationStatus && item.accordionPanels && item.accordionPanels.target && item.accordionPanels.target.length > 0)
				checkPanelValidity(item.accordionPanels.target);
			if (tabValidationStatus && item.accordionPanels && item.accordionPanels.source && item.accordionPanels.source.length > 0)
				checkPanelValidity(item.accordionPanels.source);
			/**
			 * checks validity of port panels
			 * @param {Array} _panels - port panels (AccordionModalPanel)
			 */
			function checkPanelValidity(_panels) {
				_panels.forEach((panel) => {
					let panelValidationStatus = true;
					panel.controls.forEach((control) => {
						if (!control.validated) {
							tmpValidationStatus = false;
							tabValidationStatus = false;
							panelValidationStatus = false;
						}
					});
					panel.validated = panelValidationStatus;
				});

				/**
				 * checks validity of interface panels
				 * @param {Array} _panels - port panels (AccordionModalPanel)
				 */
				/* 			function checkInterfacePanelValidity(_panels) {
					_panels.forEach((panel) => {
						panel.controls.forEach((control) => {
							if (!control.validated) tmpValidationStatus = false;
						});
					});
				} */
			}
			if (tabValidationStatus) {
				cId2jqSel(item.validationIndicator).removeClass("cControl-ValidationIndicator-error");
			} else {
				cId2jqSel(item.validationIndicator).addClass("cControl-ValidationIndicator-error");
			}
			//  end lower hierarchy panels
		});

		this.validated = tmpValidationStatus;
		/*		this.buttons.forEach((button) => {
			button.disable(tmpValidationStatus);
		});*/
		const confirmButton = searchArrayForElementByKeyValuePair(this.buttons, "buttonId", "confirmButton");
		if (confirmButton) confirmButton.disable(tmpValidationStatus);
	}

	/** unregisters all child tab/panels' events */
	removePanelEvents() {
		// loop modal tabs/panels & remove all dialog-specific event handlers on dialog closing
		this.tabs.forEach((tab) => {
			tab.unregisterAllDataBindings();
			if (tab.accordionPanels.source.length > 0) tab.accordionPanels.source[0].unregisterAllDataBindings();
			if (tab.accordionPanels.target.length > 0) tab.accordionPanels.target[0].unregisterAllDataBindings();
		});
	}

	/** destroy dialog accordions */
	destroyAccordions() {
		if (this.sourcePortsAccordion && this.sourcePortsAccordion.accordion()) this.sourcePortsAccordion.accordion("destroy");
		if (this.targetPortsAccordion && this.targetPortsAccordion.accordion()) this.targetPortsAccordion.accordion("destroy");
	}

	/**
	 * updates interface validity in the port header
	 * @param  {boolean} _valid - true for valid, false for invalid
	 * @param  {string} _side - "source" or "target"
	 * @param  {number} _portIndex - port index
	 * @param  {number} _interfaceIndex - interface index
	 */
	updateChildValidity(_valid, _side, _portIndex, _interfaceIndex) {
		const myString = "aggregate_interfaces_" + _side + "_" + _portIndex + "_" + _interfaceIndex;
		_valid ? cId2jqSel(myString + "_typeHeaderPort_item").addClass("unvalid") : cId2jqSel(myString + "_typeHeaderPort_item").removeClass("unvalid");
	}

	/**
	 * execute callbacks for child accordion panels
	 * @param {Array} _panels - port panels (AccordionModalPanel)
	 */
	executeChildCallBacks() {
		this.tabs.forEach((tab) => {
			tab.controls.forEach((control) => {
				control.executeCallBack();
			});
			if (tab.accordionPanels.source.length > 0) {
				tab.accordionPanels.source[0].controls.forEach((control) => {
					control.executeCallBack();
				});
			}
			if (tab.accordionPanels.target.length > 0) {
				tab.accordionPanels.target[0].controls.forEach((control) => {
					control.executeCallBack();
				});
			}
		});
	}
}

/** Defines a modal dialog for aggregates */
export class ModalDialogUnit extends ModalDialog {
	/**
	 * Standard constructor
	 * @param  {i18nKey} _heading text to display as dialog heading
	 * @param  {DataNode} _dataNode data node
	 * @param  {DataNode} _tmpDataNode temp data node
	 */
	constructor(_heading, _dataNode, _tmpDataNode) {
		super(_heading);
		this.dataNode = _dataNode;
		this.tmpDataNode = _tmpDataNode;
		this.accordionPanels = {}; // placeholder for child dialog panels
		this.accordionPanels.source = [];
		this.accordionPanels.target = [];
		document.getElementById("modal-dialog").classList.remove("modal-dialog");
		document.getElementById("modal-dialog").classList.add("modal-dialog-unit");
		this.setLocalDataBinding("validateModal", () => {
			this.updateValidity();
		});
		this.setLocalDataBinding("childValidityChanged", this.updateChildValidity);
	}

	/**
	 * Help function for making new port list for dropdown
	 * @param {Port} _port for which connector list made
	 * @returns {Array} availablePortsList - list of available ports as created
	 */
	makeAvailablePortsForDropdown(_port) {
		const availablePortsForDropdown = [];
		const connectorList = makeConnectorArrayByPort(_port, true);
		connectorList.forEach((port) => {
			availablePortsForDropdown.push({caption: port.desc, value: port.id});
		});
		return availablePortsForDropdown;
	}

	/**
	 * Help function for making new port list
	 * @param {Port} _port for which connector list made
	 * @returns {Array} availablePortsList - list of available ports as created
	 */
	makeAvailablePorts(_port) {
		const availablePorts = [];
		const connectorList = makeConnectorArrayByPort(_port, true);
		connectorList.forEach((port) => {
			availablePorts.push({...port});
		});
		return availablePorts;
	}

	/**
	 * Help function for updating port list
	 * @param {Port} _port for which connector list updated
	 * @param {number} _portDomIndex - port index
	 * @param {string} _side - source or target
	 */
	changePortList(_port, _portDomIndex, _side) {
		const availablePortsList = this.makeAvailablePortsForDropdown(_port);
		const portIndex = this.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(this.accordionPanels[_side], "id", _portDomIndex));
		const modalPanel = this.accordionPanels[_side][portIndex];
		modalPanel.getControlById("aggregate_ports_" + _side + "_" + _portDomIndex + "_connector").update(availablePortsList);
		modalPanel.getControlById("aggregate_ports_" + _side + "_" + _portDomIndex + "_connector").setValue(_port.databaseId);
	}

	/** Confirm this dialog and raise callbacks of all nested controls */
	confirmDialog() {
		this.controls.forEach((control) => {
			control.executeCallBack();
		});

		//  for lower hierarchy panels
		this.executeChildCallBacks();

		// replace ports with temp ports
		const tmpPorts = this.tmpDataNode.targetPorts.concat(this.tmpDataNode.sourcePorts);
		const unitDataNode = getDataNodeByUUID(this.tmpDataNode.UUID);
		// remove deleted ports
		unitDataNode.ports.forEach((unitPort) => {
			let existsInTemp = false;
			tmpPorts.forEach((tmpPort) => {
				if (tmpPort.UUID == unitPort.UUID) existsInTemp = true;
			});
			if (!existsInTemp) {
				const myUUID = unitPort.UUID;
				unitDataNode.removePort(unitPort);
				const portSideTranslationKey = `ports.${unitPort.side.toLowerCase()}`;
				MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portRemoved", {port: unitPort, portSide: portSideTranslationKey, dataNode: unitDataNode});
				unitDataNode.parentEventManager.dispatch("eDTM_PortParameterChanged", myUUID, "isConnected", true);
			}
		});
		tmpPorts.forEach((tmpPort) => {
			if (getPortByUUID(tmpPort.UUID)) {
				const unitNodePort = unitDataNode.ports.find((port) => port.UUID === tmpPort.UUID);
				unitNodePort.interfaces.forEach((_interface) => unitNodePort.removeInterface(_interface));

				const interfacesSave = tmpPort.interfaces.map((_interface) => _interface.save());
				interfacesSave.forEach((interfaceSave) => (interfaceSave.UUID = null));
				interfacesSave.forEach((interfaceSave) => unitNodePort.addInterface(interfaceFactory(interfaceSave)));
			} else {
				// create new port
				tmpPort.UUID = null;

				const tmpPortData = tmpPort.save();

				const tmpNewPort = portFactory(tmpPortData);

				tmpNewPort.interfaces.forEach((_interface) => {
					tmpNewPort.removeInterface(_interface);
				});

				const interfacesSave = tmpPort.interfaces.map((_interface) => _interface.save());
				interfacesSave.forEach((interfaceSave) => (interfaceSave.UUID = null));
				interfacesSave.forEach((interfaceSave) => {
					tmpNewPort.addInterface(interfaceFactory(interfaceSave));
				});

				unitDataNode.addPort(tmpNewPort);
				const portSideTranslationKey = `ports.${tmpNewPort.side.toLowerCase()}`;
				MESSENGER.post2statusbar("NORMAL", "modalDialog.msg-portAdded", {port: tmpNewPort, portSide: portSideTranslationKey, dataNode: unitDataNode});
			}
		});
		unitDataNode.eventManager.dispatch(unitDataNode.signatures.portsUpdated, unitDataNode);
		unitDataNode.recalculatePortPositions();
		this.removePanelEvents();
		this.destroyAccordions();
		this.removeDialog();
	}

	/** Cancel this dialog */
	cancelDialog() {
		this.removePanelEvents();
		this.destroyAccordions();
		this.removeDialog();
	}

	/** Iterates over all child-controls, checks their local validity and sets the dialog validity accordingly */
	updateValidity() {
		let tmpValidationStatus = true;
		this.controls.forEach((item) => {
			if (!item.validated) tmpValidationStatus = false;
		});

		// for lower hierarchy panels TODO better
		if (tmpValidationStatus && this.accordionPanels && this.accordionPanels.target && this.accordionPanels.target.length > 0)
			checkPanelValidity(this.accordionPanels.target);
		if (tmpValidationStatus && this.accordionPanels && this.accordionPanels.source && this.accordionPanels.source.length > 0)
			checkPanelValidity(this.accordionPanels.source);

		/**
		 * checks validity of port panels
		 * @param {Array} _panels - port panels (AccordionModalPanel)
		 */
		function checkPanelValidity(_panels) {
			_panels.forEach((panel) => {
				panel.controls.forEach((control) => {
					if (!control.validated) tmpValidationStatus = false;
					if (tmpValidationStatus && panel.accordionPanels && panel.accordionPanels.length > 0) checkInterfacePanelValidity(panel.accordionPanels);
				});
			});

			/**
			 * checks validity of interface panels
			 * @param {Array} _panels - port panels (AccordionModalPanel)
			 */
			function checkInterfacePanelValidity(_panels) {
				_panels.forEach((panel) => {
					panel.controls.forEach((control) => {
						if (!control.validated) tmpValidationStatus = false;
					});
				});
			}
		}
		//  end lower hierarchy panels
		this.validated = tmpValidationStatus;
		/*		this.buttons.forEach((button) => {
			button.disable(tmpValidationStatus);
		});*/
		const confirmButton = searchArrayForElementByKeyValuePair(this.buttons, "buttonId", "confirmButton");
		if (confirmButton) confirmButton.disable(tmpValidationStatus);
	}

	/** set divs as handles for port accordions */
	addAccordions() {
		this.sourcePortsAccordion = cId2jqSel("aggregate_ports_source");
		this.targetPortsAccordion = cId2jqSel("aggregate_ports_target");
	}

	/**
	 unregisters all child panels' events
	 */
	removePanelEvents() {
		// loop modal panels & remove all dialog-specific event handlers on dialog closing
		this.accordionPanels.target.concat(this.accordionPanels.source).forEach((portPanel) => {
			portPanel.unregisterAllDataBindings();
			if (portPanel.accordionPanels && portPanel.accordionPanels.length > 0) {
				portPanel.accordionPanels.forEach((interfacePanel) => {
					interfacePanel.unregisterAllDataBindings();
				});
			}
		});
	}

	/**
	 destroy dialog accordions
	 */
	destroyAccordions() {
		this.accordionPanels.target.concat(this.accordionPanels.source).forEach((portPanel) => {
			portPanel.interfacesAccordion.accordion("destroy");
		});
		if (this.sourcePortsAccordion.accordion()) this.sourcePortsAccordion.accordion("destroy");
		if (this.targetPortsAccordion.accordion()) this.targetPortsAccordion.accordion("destroy");
	}

	/**
	 * updates interface validity in the port header
	 * @param  {boolean} _valid - true for valid, false for invalid
	 * @param  {string} _side - "source" or "target"
	 * @param  {number} _portIndex - port index
	 * @param  {number} _interfaceIndex - interface index
	 */
	updateChildValidity(_valid, _side, _portIndex, _interfaceIndex) {
		const myString = "aggregate_interfaces_" + _side + "_" + _portIndex + "_" + _interfaceIndex;
		_valid ? cId2jqSel(myString + "_typeHeaderPort_item").addClass("unvalid") : cId2jqSel(myString + "_typeHeaderPort_item").removeClass("unvalid");
	}

	/**
	 * execute callbacks for child accordion panels
	 * @param {Array} _panels - port panels (AccordionModalPanel)
	 */
	executeChildCallBacks() {
		// set new user defined ports/interfaces/values
		if (this.accordionPanels.target || this.accordionPanels.source) {
			let panels;
			if (this.accordionPanels.target && this.accordionPanels.target.length > 0) {
				panels = this.accordionPanels.target;
				executePanelCallBacks(panels);
			}
			if (this.accordionPanels.source && this.accordionPanels.source.length > 0) {
				panels = this.accordionPanels.source;
				executePanelCallBacks(panels);
			}

			/**
			 * execute callbacks for child accordion panels
			 * @param {Array} _panels - port panels (AccordionModalPanel)
			 */
			function executePanelCallBacks(_panels) {
				_panels.forEach((panel) => {
					if (panel.accordionPanels && panel.accordionPanels.length > 0) {
						panel.accordionPanels.forEach((interfacePanel) => {
							interfacePanel.controls.forEach((control) => {
								control.executeCallBack();
							});
						});
					}
					panel.controls.forEach((control) => {
						control.executeCallBack();
					});
				});
			}
		}
	}
}

/** Defines a standardized button for modalDialog */
class DialogButtonBase {
	/**
	 * Standard constructor
	 * @param  {string} _containerId to nest into
	 * @param  {string} _buttonId of button that gets created
	 * @param  {i18nKey} _caption i18n key to display as button caption
	 * @param  {boolean} _disabled initial state of button
	 * @param  {Function} _callback to call on button.click
	 */
	constructor(_containerId, _buttonId, _caption, _disabled, _callback) {
		this.containerId = checkExistenceNew(_containerId);
		this.buttonId = checkUniqueness(_buttonId);
		this.caption = _caption;
		this.disabled = _disabled;
		this.callbackFunction = checkFunctionExistenceNew(_callback);
		this.createDomStructure();
	}

	/** creates the needed DOM elements for the button */
	createDomStructure() {
		$("<button>", {
			id: this.buttonId,
			class: "dialog-button noselect",
			text: getTranslation(this.caption),
			disabled: this.disabled,
			click: this.callbackFunction, // binding _callbackFunction to click Event
		}).appendTo(cId2jqSel(this.containerId));
	}

	/**
	 * Switches between button enabled/disabled
	 * @param  {boolean} _bool set button enabled (true) / disabled (false)
	 */
	disable(_bool) {
		document.getElementById(this.buttonId).disabled = !_bool;
	}
}

/** Variant of the standard button for modalDialog */
export class CancelButton extends DialogButtonBase {
	/**
	 * Standard constructor
	 * @param  {ModalDialog} _dialog to nest into
	 * @param  {Function} _callback additional callback to run on button.click (besides cancelling the dialog)
	 */
	constructor(_dialog, _callback = null) {
		/** Helper to add a provided _callbackFunction to the default callback of this element */
		function composeCallback() {
			_dialog.cancelDialog(); // default callback
			if (_callback !== null) checkFunctionExistenceNew(_callback)();
		}

		super(_dialog.footer, "cancelButton", "modalDialog.button.cancel", false, composeCallback);
	}

	/** Cancel buttons don't get disabled */
	disable() {
		// do nothing
	}
}

/** Variant of the standard button for modalDialog */
export class ConfirmButton extends DialogButtonBase {
	/**
	 * Standard constructor
	 * @param  {ModalDialog} _dialog to nest into
	 * @param  {i18nKey} _caption i18n key to display as button caption
	 * @param  {Function} _callback additional callback to run on button.click (besides confirming the dialog)
	 */
	constructor(_dialog, _caption, _callback = null) {
		/** Helper to add a provided _callbackFunction to the default callback of this element */
		function composeCallback() {
			_dialog.confirmDialog(); // default callback
			if (_callback !== null) checkFunctionExistenceNew(_callback)();
		}

		super(_dialog.footer, "confirmButton", _caption, true, composeCallback);
	}
}

/** Variant of the standard button for accordions */
export class DialogButtonAccordion extends DialogButtonBase {
	/**
	 * Standard constructor
	 * @param  {string} _containerId to nest into
	 * @param  {string} _buttonId of button that gets created
	 * @param  {i18nKey} _caption i18n key to display as button caption
	 * @param  {boolean} _disabled initial state of button
	 * @param  {i18nKey} _tooltip to set
	 * @param  {Function} _callback to call on button.click
	 */
	constructor(_containerId, _buttonId, _caption, _disabled, _tooltip, _callback) {
		super(_containerId, _buttonId, _caption, _disabled, _callback), cId2jqSel(this.buttonId).addClass("right");
		cId2jqSel(this.buttonId).attr("title", getTranslation(_tooltip));
	}

	/**
	 * set additional properties for button DOM
	 * @param  {object} _properties - properties
	 */
	addProperties(_properties) {
		cId2jqSel(this.buttonId).prop(_properties);
	}
}
