/* eslint-disable key-spacing */
/* cSpell:disable */

import toggleToLayoutListIcon from "../images/sidePanelWidgetIcons/toggleToLayoutList.svg";
import toggleToLayoutTileIcon from "../images/sidePanelWidgetIcons/toggleToLayoutTile.svg";
import flag_DE from "../images/statusbarIcons/flag_DE.svg";
import flag_EN from "../images/statusbarIcons/flag_EN.svg";
import flag_ZH from "../images/statusbarIcons/flag_ZH.svg";

/* Enumeration of different color schemes */
export const colorSchemeEnum = {
	STANDARD: {type: "STANDARD", caption: "modalDialog.colorScheme-standard"},
	DIFFERENTIATED: {type: "DIFFERENTIATED", caption: "modalDialog.colorScheme-differentiated"},
	REDUCED: {type: "REDUCED", caption: "modalDialog.colorScheme-reduced"},
};

/* Enumeration of different connection label styles */
export const portLabelStyleEnum = {
	NONE: {type: "NONE", caption: "modalDialog.cableDialog.label.style.none"},
	PLATED: {type: "PLATED", caption: "modalDialog.cableDialog.label.style.plated"},
	LAMINATED: {type: "LAMINATED", caption: "modalDialog.cableDialog.label.style.laminated"},
	IMPRINTED: {type: "IMPRINTED", caption: "modalDialog.cableDialog.label.style.imprinted"},
};

/* Enumeration of different applications */
export const applicationEnum = {
	ELECTRIC: {type: "ELECTRIC"},
	HYDRAULIC: {type: "HYDRAULIC"},
	PNEUMATIC: {type: "PNEUMATIC"},
};

/* Enumeration of different branch types */
export const gitBranchTypeEnum = {
	HOTFIX: {type: "HOTFIX", bitbucketName: "hotfix/", cssClass: "menuBar-element-gitInfo-text-HOTFIX"},
	BUGFIX: {type: "BUGFIX", bitbucketName: "bugfix/", cssClass: "menuBar-element-gitInfo-text-BUGFIX"},
	FEATURE: {type: "FEATURE", bitbucketName: "feature/", cssClass: "menuBar-element-gitInfo-text-FEATURE"},
	DEVELOPMENT: {type: "DEVELOPMENT", bitbucketName: "develop", cssClass: "menuBar-element-gitInfo-text-DEVELOPMENT"},
	TESTING: {type: "TESTING", bitbucketName: "release/", cssClass: "menuBar-element-gitInfo-text-TESTING"},
	PRODUCTION: {type: "PRODUCTION", bitbucketName: "master", cssClass: "menuBar-element-gitInfo-text-PRODUCTION"},
	OTHER: {type: "OTHER", bitbucketName: "", cssClass: "menuBar-element-gitInfo-text-OTHER"},
};

/**
 * Enumeration of different device functions/roles.
 * Atm used to distinguish generic aggregates and cabinets.
 * In the future will be used to help organizing the layout and to construct the graph network (assign different roles like distributor, energy-source / energy-sink to graphNodes).
 */
export const deviceRoleEnum = {
	UNIT: {type: "UNIT"},
	CABINET: {type: "CABINET"},
	OTHER: {type: "OTHER"}, // will get specified in the future with roles like source|sink|distributor|...
};

/* Enumeration of available translations */
export const languageEnum = {
	de: {type: "de", caption: "Deutsch", flag: flag_DE},
	en: {type: "en", caption: "English", flag: flag_EN},
	zh: {type: "zh", caption: "中文", flag: flag_ZH},
};

/* Enumeration of different splash type modes */
export const drawModeEnum = {
	SYMBOL: {type: "SYMBOL", caption: "modalDialog.drawMode-symbol"},
	IMAGE: {type: "IMAGE", caption: "modalDialog.drawMode-image"},
};

/* Enumeration of different splash type modes */
export const splashTypeEnum = {
	ANNOUNCEMENT: {type: "ANNOUNCEMENT", postKey: null},
	CHANGELOG: {type: "CHANGELOG", postKey: "showChangelog"},
	GREETER: {type: "GREETER", postKey: "showGreeter"},
	RECENTPROJECTS: {type: "RECENTPROJECTS", postKey: "showRecentProjects"},
};

/* Enumeration of accordion layouts */
export const accordionLayoutEnum = {
	LIST: {type: "LIST", caption: "accordionManager.layout-list", css: "layout-list", widgetIcon: toggleToLayoutTileIcon},
	TILE: {type: "TILE", caption: "accordionManager.layout-tile", css: "layout-tile", widgetIcon: toggleToLayoutListIcon},
};

/* Enumeration of different operating modes */
export const interfaceOperatingModeEnum = {
	// TODO that screams for refactoring!!!
	CONTINUOUS: {type: "CONTINUOUS", caption: "modalDialog.param-operatingMode-continuous", level: 1, nonmotor: true, number: 1},
	ON_OFF: {type: "ON_OFF", caption: "modalDialog.param-operatingMode-onOff", level: 2, nonmotor: true, number: 2},
	LEFT_RIGHT: {type: "LEFT_RIGHT", caption: "modalDialog.param-operatingMode-leftRight", level: 3, nonmotor: false, number: 3},
	// SOFT_START: {type: "SOFT_START", caption: "mDlg-param-operatingMode-softStart", level: 3, number: 4},
	SPEED_REGULATED: {type: "SPEED_REGULATED", caption: "modalDialog.param-operatingMode-speedRegulated", level: 4, nonmotor: false, number: 4}, // number will be deleted
};

/* Enumeration of consumer subgroups */
/* const consumerSubgroupEnum = {
	ACTORS: {type: "ACTORS", caption: "actors"},
	SENSORS: {type: "SENSORS", caption: "sensors"},
	COMMANDERS: {type: "COMMANDERS", caption: "commanders"},
	SPECIALS: {type: "SPECIALS", caption: "specials"},
}; */

/* Enumeration of product subgroups */
/* const functionSubgroupEnum = {
	PROTECT_SWITCH_MANUALLY_400: {type: "PROTECT_SWITCH_MANUALLY_400", caption: "protect-switch-manually-400"},
	DISTRIBUTE_PROTECT_400_24: {type: "DISTRIBUTE_PROTECT_400_24", caption: "distribute-protect-400-24"},
	PROTECT_SWITCH_400: {type: "PROTECT_SWITCH_400", caption: "protect-switch-400"},
	PROTECT_SWITCH_400_MOTORS: {type: "PROTECT_SWITCH_400_MOTORS", caption: "protect-switch-400-motors"},
	CONTROL_400_MOTORS: {type: "CONTROL_400_MOTORS", caption: "control-400-motors"},
	CONVERT_400_24: {type: "CONVERT_400_24", caption: "convert-400-24"},
	CONVERT_BUS: {type: "CONVERT_BUS", caption: "convert-bus"},
	PROTECT_SWITCH_24: {type: "PROTECT_SWITCH_24", caption: "protect-switch-24"},
	DISTRIBUTE_ENERGY_DATA: {type: "DISTRIBUTE_ENERGY_DATA", caption: "distribute-energy-data"},
	MEASURE_MONITOR: {type: "MEASURE_MONITOR", caption: "measure-monitor"},
}; */

/* Enumeration of interfaces */
export const interfaceTypeEnum = {
	ENERGY_AC: {type: "ENERGY_AC", caption: "interfaces.energy-ac", value: 10, group: 1},
	ENERGY_DC: {type: "ENERGY_DC", caption: "interfaces.energy-dc", value: 20, group: 1},
	DATA_ASI: {type: "DATA_ASI", caption: "interfaces.asi", value: 40, group: 2},
	DATA_IOLINK: {type: "DATA_IOLINK", caption: "interfaces.io_link", value: 41, group: 2},
	DATA_PARALLEL: {type: "DATA_PARALLEL", caption: "interfaces.parallel", value: 47, group: 2},
	DATA_PROFIBUS: {type: "DATA_PROFIBUS", caption: "interfaces.profibus", value: 42, group: 2},
	DATA_PROFINET: {type: "DATA_PROFINET", caption: "interfaces.profinet", value: 43, group: 2},
	DATA_ETHERCAT: {type: "DATA_ETHERCAT", caption: "interfaces.ethercat", value: 44, group: 2},
	DATA_POWERLINK: {type: "DATA_POWERLINK", caption: "interfaces.powerlink", value: 45, group: 2},
	DATA_ETHERNET_IP: {type: "DATA_ETHERNET_IP", caption: "interfaces.ethernet_ip", value: 46, group: 2},
	DATA_CANOPEN: {type: "DATA_CANOPEN", caption: "interfaces.canopen", value: 48, group: 2},
	MEASURE_DIGITAL: {type: "MEASURE_DIGITAL", caption: "interfaces.measure-digital", value: 50, group: 3},
	MEASURE_ANALOG_4_20MA: {type: "MEASURE_ANALOG_4_20MA", caption: "interfaces.measure-analog-4-20ma", value: 60, group: 4},
	MEASURE_ANALOG_0_10V: {type: "MEASURE_ANALOG_0_10V", caption: "interfaces.measure-analog-0-10v", value: 62, group: 4},
	CONTROL_ANALOG_4_20MA: {type: "CONTROL_ANALOG_4_20MA", caption: "interfaces.control-analog-4-20ma", value: 61, group: 4},
	CONTROL_ANALOG_0_10V: {type: "CONTROL_ANALOG_0_10V", caption: "interfaces.control-analog-0-10v", value: 63, group: 4},
};

/* Enumeration for field buses */
export const fieldBusEnum = {
	ASI: {type: "ASI", caption: "interfaces.asi"},
	IOLINK: {type: "IOLINK", caption: "interfaces.io_link"},
	PARALLEL: {type: "PARALLEL", caption: "interfaces.parallel"},
};

/* Enumeration for system buses */
export const systemBusEnum = {
	PROFIBUS: {type: "PROFIBUS", caption: "interfaces.profibus"},
	PROFINET: {type: "PROFINET", caption: "interfaces.profinet"},
	ETHERCAT: {type: "ETHERCAT", caption: "interfaces.ethercat"},
	POWERLINK: {type: "POWERLINK", caption: "interfaces.powerlink"},
	ETHERNETIP: {type: "ETHERNETIP", caption: "interfaces.ethernet_ip"},
	CANOPEN: {type: "CANOPEN", caption: "interfaces.canopen"},
};

/* Enumeration of message priorities, used by messageService and CustomInfoLabel */
export const messagePriorityEnum = {
	NORMAL: {type: "NORMAL", class: "messageBox-text-normal", timeout: 30000},
	INFO: {type: "INFO", class: "messageBox-text-info", timeout: 5000},
	ERROR: {type: "ERROR", class: "messageBox-text-error", timeout: 10000}, // timeOut 0 -> no timeout!
};

/* Enumeration of validation modes */
export const validationStatusEnum = {
	NONE: {type: "NONE", class: "canvasNode-infoProvider-none", tooltip: null},
	CORRECT: {type: "CORRECT", class: "canvasNode-infoProvider-correct", tooltip: "jspManager.infoProvider-correct-tt"},
	WARNING: {type: "WARNING", class: "canvasNode-infoProvider-warning", tooltip: "jspManager.infoProvider-warning-tt"},
	ERROR: {type: "ERROR", class: "canvasNode-infoProvider-error", tooltip: "jspManager.infoProvider-error-tt"},
	LOADING: {type: "LOADING", class: "canvasNode-infoProvider-loading", tooltip: "jspManager.infoProvider-loading-tt"},
};

/* Enumeration of common technical units */
export const technicalUnitEnum = {
	EMPTY: {type: "EMPTY", translation: "", unit: ""},
	NONE: {type: "NONE", translation: "", unit: " / "},
	VOLTAGE: {type: "VOLTAGE", translation: "units.voltage", unit: "V"},
	CURRENT: {type: "CURRENT", translation: "units.current", unit: "A"},
	POWER: {type: "POWER", translation: "units.power", unit: "kW"},
	TORQUE: {type: "TORQUE", translation: "units.torque", unit: "Nm"},
	FREQUENCY: {type: "FREQUENCY", translation: "units.frequency", unit: "Hz"},
	MOTORCURRENT: {type: "CURRENT", translation: "units.motor-current", unit: "A"},
	MOTORPOWER: {type: "POWER", translation: "units.motor-power", unit: "kW"},
	LENGTH_M: {type: "LENGTH_M", translation: "units.length", unit: "m"},
	LENGTH_MM: {type: "LENGTH_MM", translation: "units.length", unit: "mm"},
	SECTION: {type: "SECTION", translation: "unit-section", unit: "mm" + "2".sup()}, // "mm^2"
	// MODE: {type: "MODE", translation: "mDlg-param-operatingMode", unit: ""},
};

/* Enumeration of outliner & canvas properties for the different node types (mainly used for contextMenu evaluation) */
export const interactionPresetsEnum = {
	// show in outliner, show as folder, allow dragging, allow renaming, allow deletion, allow clearing, allow shopping
	PROJECTNODE: {outliner: true, folder: true, draggable: false, renamable: true, deletable: false, clearable: true, shoppable: false},
	ASSEMBLYNODE: {outliner: true, folder: true, draggable: true, renamable: true, deletable: true, clearable: true, shoppable: false},
	UNITNODE: {outliner: true, folder: false, draggable: true, renamable: true, deletable: true, clearable: false, shoppable: false},
	DEVICENODE: {outliner: true, folder: false, draggable: true, renamable: true, deletable: true, clearable: false, shoppable: true},
	INFRASTRUCTURENODE: {outliner: true, folder: true, draggable: false, renamable: false, deletable: false, clearable: true, shoppable: false},
	TRASHNODE: {outliner: true, folder: true, draggable: false, renamable: false, deletable: false, clearable: true, shoppable: false},
	LIMBONODE: {outliner: false, folder: true, draggable: false, renamable: false, deletable: false, clearable: false, shoppable: false},
	CONNECTION: {outliner: false, folder: false, draggable: true, renamable: true, deletable: true, clearable: false, shoppable: true},
};

// TO BE REPLACED BY PortLayout in constantsAndEnumeration2.ts
export const portLayoutEnum = {
	// TODO remove angled? (do we really need three versions of angled?)
	STRAIGHT: {type: "STRAIGHT", caption: "ports.layout-straight"},
	ANGLED: {type: "ANGLED", caption: "ports.layout-angled"},
	ANGLED_LEFT: {type: "ANGLED_LEFT", caption: "ports.layout-angledLeft"},
	ANGLED_RIGHT: {type: "ANGLED_RIGHT", caption: "ports.layout-angledRight"},
};

// TO BE REPLACED BY PortOrientation in constantsAndEnumeration2.ts
export const portOrientationEnum = {
	// for anchor coords [ax,ay] & anchor orientation [ox,oy], see: https://docs.jsplumbtoolkit.com/toolkit/2.x/articles/anchors.html
	NORTH: {angle: 270, ax: 0.5, ay: -0.75, ox: 0, oy: -1},
	EAST: {angle: 0, ax: 1.75, ay: 0.5, ox: 1, oy: 0},
	SOUTH: {angle: 90, ax: 0.5, ay: 1.75, ox: 0, oy: 1},
	WEST: {angle: 180, ax: -0.75, ay: 0.5, ox: -1, oy: 0},
};

/* export const wiresTypeEnum = {
	MAIN: {type: "MAIN", caption: "wires-type-main"},
	AUX: {type: "AUX", caption: "wires-type-aux"},
}; */

/* Enumeration of connections (cables) */
export const connectionTypeEnum = {
	// TODO that screams for refactoring!!!
	cRIBBONd: {type: "cRIBBONd", name: "cables.cRIBBONd", shielded: false, jspEdgeType: "cRIBBONd"}, // Flachbandkabel Daten
	cRIBBONe: {type: "cRIBBONe", name: "cables.cRIBBONe", shielded: false, jspEdgeType: "cRIBBONe"}, // Flachbandkabel 24V
	cWTEC12e: {type: "cWTEC12e", name: "cables.cWTEC12e", shielded: false, jspEdgeType: "cWTEC12e"}, // W-TEC 12
	cWTEC12d: {type: "cWTEC12d", name: "cables.cWTEC12d", shielded: false, jspEdgeType: "cWTEC12d"}, // W-TEC 12 Daten
	cWTEC12s: {type: "cWTEC12s", name: "cables.cWTEC12s", shielded: true, jspEdgeType: "cWTEC12s"}, // W-TEC 12 geschirmt
	cWTEC15: {type: "cWTEC15", name: "cables.cWTEC15", shielded: false, jspEdgeType: "cWTEC15"}, // W-TEC 15
	cWTEC15g: {type: "cWTEC15g", name: "cables.cWTEC15g", shielded: false, jspEdgeType: "cWTEC15g"}, // W-TEC 15
	cWTEC15s: {type: "cWTEC15s", name: "cables.cWTEC15s", shielded: true, jspEdgeType: "cWTEC15s"}, // W-TEC 15 geschirmt
	cWTEC15sg: {type: "cWTEC15sg", name: "cables.cWTEC15sg", shielded: true, jspEdgeType: "cWTEC15sg"}, // W-TEC 15 geschirmt
	cWTEC23: {type: "cWTEC23", name: "cables.cWTEC23", shielded: false, jspEdgeType: "cWTEC23"}, // W-TEC 23
	cWTEC23s: {type: "cWTEC23s", name: "cables.cWTEC23s", shielded: true, jspEdgeType: "cWTEC23s"}, // W-TEC 23 geschirmt
	cWTEC32: {type: "cWTEC32", name: "cables.cWTEC32", shielded: false, jspEdgeType: "cWTEC32"}, // W-TEC 32
	cWTEC32s: {type: "cWTEC32s", name: "cables.cWTEC32s", shielded: true, jspEdgeType: "cWTEC32s"}, // W-TEC 32 geschirmt
	cWTEC80a: {type: "cWTEC80a", name: "cables.cWTEC80a", shielded: false, jspEdgeType: "cWTEC80a"}, // 400V bis 80A
	cWTEC250a: {type: "cWTEC250a", name: "cables.cWTEC250a", shielded: false, jspEdgeType: "cWTEC250a"}, // 400V bis 250A // TODO how to handle open cables? there are different types of open ports (are interfaces enough to specify the open end?)
	notFound: {type: "notFound", name: "notFound", shielded: false, jspEdgeType: "notFound"}, // Kabel nicht vorhanden
};

/* Folder for info images (cables) */
export const infoImagePath = "./client/images/cableDialog/";

export const cableStandardLengthsEnum = {
	100: {caption: "1000", value: 1000},
	150: {caption: "1500", value: 1500},
	200: {caption: "2000", value: 2000},
	300: {caption: "3000", value: 3000},
	400: {caption: "4000", value: 4000},
	500: {caption: "5000", value: 5000},
	1000: {caption: "10000", value: 10000},
	1200: {caption: "12000", value: 12000},
	1500: {caption: "15000", value: 15000},
	2000: {caption: "20000", value: 20000},
};

// REPLACED BY DynamicApplication in constantsAndEnumeration2.ts
export const dynamicApplicationsEnum = {
	DA0: {caption: "modalDialog.cableDialog.da0", type: "DA0"},
	DA4: {caption: "modalDialog.cableDialog.da4", type: "DA4"},
	DA6: {caption: "modalDialog.cableDialog.da6", type: "DA6"},
};

/* Enumeration of standard lead sections in [mm2] according to IEC 60228 */
export const leadSectionEnum = {
	0.25: 0.25,
	0.34: 0.34,
	0.38: 0.38,
	0.5: 0.5,
	0.75: 0.75,
	"1.0": 1.0,
	1.5: 1.5,
	2.5: 2.5,
	"4.0": 4.0,
	"6.0": 6.0,
	"10.0": 10.0,
	"16.0": 16.0,
	"25.0": 25.0,
	"35.0": 35.0,
	"50.0": 50.0,
};
