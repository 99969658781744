import * as React from 				"react";
import AccountCircle from 		"@mui/icons-material/AccountCircle";
// import Menu from 							"@mui/material/Menu";
// import MenuItem from 					"@mui/material/MenuItem";
import MenuButton from 				"../menuButton/menuButton";

type AccountProps = {
	userName: string,
}

const userAccountStyle = {
	display: "flex",
	alignItems: "center",
};

/**
 * jsx example for user account
 * @returns {React.ReactElement} user account
 */
export default function UserAccount(props: AccountProps): React.ReactElement {
	//! make userName hook after set fullName in USER
/*  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

   const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; */

  return (
		<div style={userAccountStyle}>
			<MenuButton title={props.userName} icon={AccountCircle} /> {/* callback={handleMenu} */}
{/* 			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			> 
				<MenuItem onClick={handleClose}>Profile</MenuItem>
				<MenuItem onClick={handleClose}>My account</MenuItem>
			</Menu>*/}
		</div>
	)
}
