//REFACTOR the event documentation used here is a joke (https://jsdoc.app/tags-event.html)

//REFACTOR Messenger is a shitty name! (too close to MessageService)

//TODO play with span colors for different states (link, hover, deleted, trashed), use a simple shift of hsl components to vary the base color (see messenger.css for examples)
//TODO drastically improve the visual design of this element (css)
//TODO for line by line scrolling: https://www.mediaevent.de/javascript/scroll.html

//JIRA [LQSK-1396] Statusbar-Messenger: Eventhandling Links
//TODO handle the following events for  port|connection|dataNode (parse through the whole list for the uuid and add set the appropriate classes
//TODO			eDTM_DataNodeSendToTrash
//TODO			eDTM_DataNodeRemovedFromTrash
//TODO			eDTM_DataNodeSendToLimbo
//TODO			eDTM_DataNodeRenamed
//TODO			eDTM_DataNodePortRemoved
//TODO			eDTM_CreateConnection
//TODO			eDTM_RemoveConnection

//BUG fetching the referenceDesignator for dataNodes throws "DataNode with UUID "root" does not exist!"
//BUG when using simplebar, double scroll handles are shown

import {BaseDataNode} from "../../dataNode";
import {BaseConnection} from "../../connections/BaseConnection"; //! WTF: If sorted correctly -> Uncaught ReferenceError: Cannot access 'BaseConnection' before initialization, btw shouldn't this be a type?
import {getLocalTime} from "../../helper";
import {getTranslation} from "../../localization/localizationManager";
import {Port} from "../../ports/port";

import type {LogLevels, TranslationKey, TranslationVariables} from "../../MessageService";
import type {EventManager} from "../../eventManager";

import "simplebar"; //! generally not needed, but maybe useful here to manually set up with certain config details

import "./messenger.css";

type UUID = string; // someday we define that in a better way & place

/**
 * Class representing the Messenger-component of Statusbar.
 */
export class Messenger {
	private static instance: Messenger; // singleton helper
	private readonly globalEventManager: EventManager;
	private readonly id = "messenger";
	private readonly HTML: HTMLUListElement;

	/**
	 * Creates an instance of Messenger.
	 * @param {HTMLDivElement} _parentContainer DOM element to nest into
	 * @param {EventManager} _globalEventManager to hook into
	 */
	constructor(_parentContainer: HTMLDivElement, _globalEventManager: EventManager) {
		// Prevent multiple instances
		if (Messenger.instance) throw new Error("Singleton classes can't be instantiated more than once.");
		Messenger.instance = this;

		this.globalEventManager = _globalEventManager;
		this.HTML = this.createDOM(_parentContainer);

		Object.seal(this);
	}

	/**
	 * Creates the DOM components of Messenger.
	 * @param {HTMLDivElement} _parentContainer DOM element to nest into (usually a Statusbar)
	 * @returns {HTMLUListElement} Messenger DOM element
	 */
	private createDOM(_parentContainer: HTMLDivElement): HTMLUListElement {
		const tmpUnorderedList = document.createElement("ul");
		tmpUnorderedList.id = this.id;
		//JIRA [LQSK-1392] Statusbar-Messenger: Simplebar fehlerhaft
		// tmpUnorderedList.setAttribute("data-simplebar", "");
		_parentContainer.appendChild(tmpUnorderedList);

		tmpUnorderedList.addEventListener("click", () => this.HTML.classList.toggle("extended"));
		tmpUnorderedList.addEventListener("mouseleave", () => {
			this.HTML.classList.remove("extended");
		});
		tmpUnorderedList.addEventListener("mouseleave", () => {
			this.scroll2Bottom();
		});

		return tmpUnorderedList;
	}

	/**
	 * Displays a (localized) message on the statusbar.
	 * @warning Usually not called directly but wrapped by MessageService.post2statusbar().
	 * @param {LogLevels} _level of message
	 * @param {string|TranslationKey} _message to post
	 * @param {TranslationVariables} [_messageVariables] optional for i18next interpolation
	 */
	public display(_level: LogLevels, _message: string | TranslationKey, _messageVariables?: TranslationVariables): void {
		if (_messageVariables) {
			for (const [key, value] of Object.entries(_messageVariables)) {
				_messageVariables[key] = this.formatMessageVariable(value);
			}
			_messageVariables.interpolation = {escapeValue: false};
		}

		const processedMessage = getTranslation(_message, _messageVariables);
		this.addListElement(_level, processedMessage);
	}

	/**
	 * Clears all messages from the statusbar.
	 * @warning Usually not called directly but wrapped by MessageService.clear().
	 */
	public clear(): void {
		this.HTML.innerHTML = "";
	}

	/**
	 * Adds a message, wrapped as a listElement, to messenger.
	 * @param {LogLevels} _level of message
	 * @param {string} _content preprocessed/formatted content of message
	 */
	private addListElement(_level: LogLevels, _content: string): void {
		const tmpLI = document.createElement("li");
		tmpLI.classList.add("messenger-item", `messenger-item-${_level.toLowerCase()}`);

		const time = getLocalTime();

		tmpLI.innerHTML = `${time} | ${_content}`;
		[...tmpLI.children].forEach((child) => {
			child.addEventListener("click", (e) => {
				e.stopPropagation(); // prevent bubbling of span click (would toggle HTML.extend otherwise)
				this.setSpanOnClickHandler(e.target as HTMLSpanElement);
			});
		});
		this.HTML.appendChild(tmpLI);
		this.scroll2Bottom();
	}

	/**
	 * Parses the value of a messageVariable and formats it to a span if appropriate.
	 * @param {string|Port|BaseConnection|BaseDataNode} _value to parse
	 * @returns {string} formatted messageVariable
	 */
	private formatMessageVariable(_value: string | Port | BaseConnection | BaseDataNode): string {
		if (typeof _value === "string") return getTranslation(_value);

		let tmpElement: {type: string; uuid: string; text: string};

		//JIRA [LQSK-1390] Statusbar-Messenger: BMK Fehler
		switch (
			true //! using _value.type maybe would be better!?
		) {
			case _value instanceof Port:
				tmpElement = {text: `${getTranslation("ports.port")} ${(_value as Port).family} [${_value.UUID}]`, type: "port", uuid: _value.UUID}; //TODO replace by line below once referenceDesignator bug is fixed
				// tmpElement = {text: `${getTranslation("ports.port")} ${_value.family} [${_value.referenceDesignator.getReferenceDesignator().string()]}`, type: "port", uuid: _value.UUID};
				break;
			case _value instanceof BaseConnection:
				tmpElement = {text: `${getTranslation("cables.cable")} ${_value.name} [${_value.UUID}]`, type: "cable", uuid: _value.UUID}; //TODO replace by line below once referenceDesignator bug is fixed
				// tmpElement = {text: `${getTranslation("CABLE")} ${_value.name} [${_value.referenceDesignator.getReferenceDesignator().string()]}`, type: "cable", uuid: _value.UUID};
				break;
			case _value instanceof BaseDataNode:
				tmpElement = {text: `${(_value as BaseDataNode).getName()} [${_value.UUID}]`, type: "dataNode", uuid: _value.UUID}; //TODO replace by line below once referenceDesignator bug is fixed
				// tmpElement = {text: `${_value.getName()} [${_value.referenceDesignator.getReferenceDesignator().string()]}`, type: "dataNode", uuid: _value.UUID};
				break;
			default:
				throw new Error("Unknown messageVariable type.");
		}

		return `<span class="messenger-item-link" data-type=${tmpElement.type} data-uuid=${tmpElement.uuid}>${tmpElement.text}</span>`;
	}

	/**
	 * Hooks the (global) "focusNode" event to the provided spans click event as a handler.
	 * @param {HTMLSpanElement} _span to add click handler to
	 * @listens this#click of all spans
	 * @fires this#focusNode on GLOBALEVENTMANAGER
	 */
	private setSpanOnClickHandler(_span: HTMLSpanElement): void {
		this.focusNodeEvent(_span);
	}

	/**
	 * Fires the (global) "focusNode" event for the provided span.
	 * @param {HTMLSpanElement} _span to fire event for
	 * @event this#focusNode
	 */
	private focusNodeEvent(_span: HTMLSpanElement): void {
		this.globalEventManager.dispatch("focusNode", {type: _span.dataset.type, UUID: _span.dataset.uuid});
	}

	/**
	 * Scrolls the list to the last entry added.
	 * @listens this#mouseleave of HTML
	 */
	private scroll2Bottom(): void {
		this.HTML.scroll(0, this.HTML.scrollHeight); // in reverse because list is upside down
	}

	/**
	 * Sets trashed status to all message spans with the provided UUID.
	 * @param {UUID} _UUID of span to mark
	 * @listens someEventName(s) probably one/some/many/all of the delete events for ports|cables|dataNodes
	 */
	private markLinksAsTrashed(_UUID: UUID): void {
		// differentiate between trashed (still clickable, focusNode in the trash) and deleted (object strike through, not clickable, no action on focusNode)
		// const tmpElements = [...this.HTML.querySelectorAll(`[data-uuid=${_UUID}]`)];
	}

	/**
	 * Sets deleted status to all message spans with the provided UUID.
	 * @param {UUID} _UUID of span to mark
	 */
	private markLinksAsDeleted(_UUID: UUID): void {
		// differentiate between trashed (still clickable, focusNode in the trash) and deleted (object strike through, not clickable, no action on focusNode)
		// const tmpElements = [...this.HTML.querySelectorAll(`[data-uuid=${_UUID}]`)];
	}
}
