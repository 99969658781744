/* eslint-disable jsdoc/require-jsdoc */
import * as React from "react";
import {createSvgIcon} from "@mui/material";
import type {SvgIconTypeMap} from "@mui/material";
import type {OverridableComponent} from "@mui/material/OverridableComponent";

export const svgFile2SvgIcon = async (
	_file: string,
	_pathId: string,
	_displayName: string,
): Promise<OverridableComponent<SvgIconTypeMap> & {muiName: string}> | never => {
	const file = await fetch(_file);
	const content = await file.text();

	const path = new DOMParser().parseFromString(content, "application/xml").getElementById(_pathId);
	if (path === null) throw new Error(`Could not extract path element from ${_file}`);

	const d = path.getAttribute("d");
	if (d === null) throw new Error(`Could not extract d element from ${_file}`);

	return createSvgIcon(<path d={d} />, _displayName);
};
