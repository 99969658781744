import {createUUID, getDataNodeByUUID, getPortByUUID} from "../dataManager";
import {EventManager} from "../eventManager";
import {portGraphicsFactory} from "../graphics";
import {searchArrayForElementByKeyValuePair} from "../helper";
import {ElectricInterfaceMapper} from "../interfaces/ElectricInterfaceMapper.ts";
import {Interface} from "../interfaces/interface";
import {interfaceFactory} from "../interfaces/interfaceFactory.ts";
import {getTranslation} from "../localization/localizationManager";
import {PortLabel} from "./PortLabel.ts";
import {portFactory} from "./portFactory.ts";
import {GLOBALEVENTMANAGER} from "../applicationManager";
import {PortSide} from "./utils";
import {WireProcessing} from "./wireProcessing";

//REFACTOR connection right now refers to the connected port on the other end of a cable
//REFACTOR 		but it should refer to the connected cable
//REFACTOR 		don't use cable, in the future we might have different kinds of connections like pneumatic tubes etc.

/** Class representing a standard device connector (aka port) */
export class Port {
	/**
	 * Standard constructor
	 * @param  {object} _portData detailed port properties
	 *					 portData = {
	 *						UUID: if already existing
	 *						family: PortFamily
	 *						type: portTypeEnum
	 *						side: PortSide
	 *						layout: portLayoutEnum
	 *						isShielded: Boolean;
	 *						isArchived: Boolean
	 *						position: {x: null, y: null}
	 *						orientation: portOrientationEnum
	 *					}
	 */
	constructor(_portData) {
		this.eventManager = new EventManager();
		this.parentUUID = null; // only legacy reasons
		this.__parent = null;
		this.UUID = _portData.UUID ? _portData.UUID : createUUID();
		this.application = _portData.application;
		this.family = _portData.family; // family of port

		this.__isConnectedTo = null; // initially not connected to any other port
		this.__connectedCable = null; //REFACTOR should be named __connection (see get connectedCable below)
		this.__composition = _portData.composition;
		this.databaseId = _portData.databaseId;
		this.dbNumber = _portData.dbNumber; // database internal number of port eventually existing other ports on device/cable
		this.__referenceDesignator = {
			prefix: ":",
			token: "X",
			number: this.dbNumber,
			/**
			 * makes port item designation string
			 * @returns {string} item designation string
			 */
			string: () => `${this.__referenceDesignator.prefix}${this.__referenceDesignator.token}${this.__referenceDesignator.number}`,
		};
		this.isShielded = _portData.isShielded;
		this.isArchived = _portData.isArchived;
		this.interfaces = [];
		this.side = _portData.side;
		this.gender = _portData.gender;
		this.geometry = _portData.geometry;
		this.__leadMap = new ElectricInterfaceMapper(_portData.leads);
		this.__label = new PortLabel(_portData.label);
		this.graphics = portGraphicsFactory(_portData.graphics);
		this.__materialNumber = _portData.materialNumber;

		this.description = _portData.description;
		this.isShadowedBy = _portData.isShadowedBy; // corresponding shadowPort
		this.shadows = _portData.shadows; // corresponding originalPort

		_portData.interfaces.forEach((_interfaceData) => {
			this.addInterface(interfaceFactory(_interfaceData));
		});

		this.wiresProcessing = [];
		this.wiresProcessing = _portData.wiresProcessing.map((_wireProcessingData) => new WireProcessing(_wireProcessingData));

		this.signatures = {
			isConnectedToChanged: "port.isConnectedTo.changed",
			interfaceParameterChanged: "port.interface.parameter.changed",
			referenceDesignatorChanged: "port.referenceDesignator.changed",
		};

		//! This is just a temporary hack to have the port fire referenceDesignatorChanged event when the referenceDesignator of the parent changes
		//! The main problem is, that all referenceDesignatorChanged events are fired via GLOBALEVENTMANAGER
		//! a correct solution would be to have all dataNodes fire referenceDesignatorChanged events via their own eventManagers and whenever a port gets added to a dataNode, it subscribes to the referenceDesignatorChanged event of the dataNode (and unsubscribes when it gets removed)
		GLOBALEVENTMANAGER.addHandler("eRDM_ReferenceDesignatorChanged", (_dataNode) => {
			if (_dataNode === this.parent) {
				this.eventManager.dispatch(this.signatures.referenceDesignatorChanged, this.referenceDesignator);
			}
		});
	}

	/**
	 * Get connected cable of a port.
	 * @memberof Port
	 */
	get connectedCable() {
		//REFACTOR should be called connection (and return the connected connection -> in the future we might also have different kinds of connections and not only cables)
		return this.__connectedCable;
	}

	/**
	 * Sets connected cable of a port.
	 * @param {Connection|null} _connection to set/remove
	 */
	set connectedCable(_connection) {
		//REFACTOR should be merged into addConnection/removeConnection
		this.__connectedCable = _connection;
	}

	/**
	 * Returns a string representation (e.g. '3x1.5+2x1.5') of leads of this electrical port.
	 * @returns {string} composition of electrical port leads.
	 */
	get composition() {
		return this.__composition;
	}

	/**
	 * Returns the label of this port.
	 */
	get label() {
		return this.__label;
	}

	/**
	 * Returns the leads of this electrical port.
	 * @returns {Array} of leads
	 */
	get leads() {
		return this.__leadMap.leads;
	}

	/**
	 * Returns the materialNumber of this port.
	 */
	get materialNumber() {
		return this.__materialNumber;
	}

	/** ToDo */
	get name() {
		return getTranslation(`ports.${this.family}`);
	}

	/**
	 * Returns the referenceDesignator of this port.
	 * @returns {object} full item designation string
	 */
	get referenceDesignator() {
		const parentComponent = this.parent?.referenceDesignator.getReferenceDesignator().string();
		return {
			parentComponent,
			getReferenceDesignator: {
				prefix: this.__referenceDesignator.prefix,
				token: this.__referenceDesignator.token,
				number: this.__referenceDesignator.number,

				/** @returns {string} full item designation string */
				string: () => `${parentComponent}${this.__referenceDesignator.string()}`,
			},
		};
	}

	/**
	 * Sets the number component of the referenceDesignator of this port.
	 * @param {number} _number item designation index == dbNumber
	 * @fires port.referenceDesignator.changed
	 */
	set referenceDesignator(_number) {
		if (_number === this.__referenceDesignator.number) return;

		this.__referenceDesignator.number = _number;
		this.eventManager.dispatch(this.signatures.referenceDesignatorChanged, this.referenceDesignator);
	}

	/** ToDo */
	getDescription() {}

	/**
	 * Adds an interface to this port.
	 * @param  {Interface} _interface to add
	 */
	addInterface(_interface) {
		this.__leadMap.mapInterface(_interface);
		_interface.parentUUID = this.UUID;
		_interface.parent = this;
		this.interfaces.push(_interface);
		// event probably not necessary atm, I guess shadow ports will need this later on.
	}

	/**
	 * Removes an interface from this port.
	 * @param {Interface} _interface to remove
	 */
	removeInterface(_interface) {
		this.__leadMap.unmapInterface(_interface);
		this.interfaces = this.interfaces.filter((_tmpInterface) => _tmpInterface.UUID !== _interface.UUID);
		// event probably not necessary atm, I guess shadow ports will need this later on.
	}

	/** Remove ALL interfaces from this port */
	clearAllInterfaces() {
		this.interfaces.forEach((_interface) => {
			this.removeInterface(_interface);
		});
	}

	/**
	 * Sets connection info for this port.
	 * @param  {Port} _correspondingPort port that is connected to this port
	 */
	addConnection(_correspondingPort) {
		// @CL @SBI check compatibility of ports here
		getPortByUUID(this.UUID).isConnectedTo = _correspondingPort.UUID;
		if (this.side === PortSide.TARGET && getDataNodeByUUID(this.parentUUID).parentEventManager) {
			getDataNodeByUUID(this.parentUUID).parentEventManager.dispatch("eDTM_PortParameterChanged", this.UUID, "isConnected", true);
		}
	}

	/** Removes a connection from this port */
	removeConnection() {
		if (this.side === PortSide.TARGET && getDataNodeByUUID(this.parentUUID).parentEventManager && this.isConnectedTo) {
			getDataNodeByUUID(this.parentUUID).parentEventManager.dispatch("eDTM_PortParameterChanged", this.UUID, "isConnected", false);
		} else if (this.side === PortSide.SOURCE && getDataNodeByUUID(this.parentUUID).parentEventManager && this.isConnectedTo) {
			getDataNodeByUUID(this.parentUUID).parentEventManager.dispatch("eDTM_PortParameterChanged", this.isConnectedTo, "isConnected", false);
		}
		this.isConnectedTo = null;
		this.connectedCable = null;
	}

	/**
	 * Returns port! this port is connected to
	 * @returns {object} port or false if not connected
	 */
	getConnection() {
		//REFACTOR remove that (this info is stored in the connection)
		return this.isConnectedTo == null ? false : this.isConnectedTo;
	}

	/**
	 * Retrieves port data for autoConfig request
	 * @returns  {object} port data
	 */
	getAutoConfigData() {
		const tmpPort = {
			uuid: this.UUID,
			name: this.name,
			// name: convertPortName2REST(this.type),	// TODO
			id: this.databaseId,
			port: this.dbNumber,
			side: this.side,
			interfaceList: [],
		};
		tmpPort.name = getTranslation(tmpPort.name); // temp // TODO delete later
		this.interfaces.forEach((e) => {
			tmpPort.interfaceList.push(e.getAutoConfigData());
		});
		return tmpPort;
	}

	/**
	 * updates currents in port interfaces
	 * @param  {Array} _interfaces of the attached cable with new currents
	 */
	updateCurrent(_interfaces) {
		for (let i = 0; i < _interfaces.length; i++) {
			for (let j = 0; j < this.interfaces.length; j++) {
				if (_interfaces[i].id == this.interfaces[j].databaseId) {
					const current = parseFloat(_interfaces[i].current);
					this.interfaces[j].current = current;
				}
			}
		}
	}

	/**
	 *	Retrieves an interface from local interfaces[] by its unique ID
	 * @param  {string} _UUID of interface to get
	 * @returns  {Interface} with matching Id
	 */
	getInterfaceByUUID(_UUID) {
		return searchArrayForElementByKeyValuePair(this.interfaces, "UUID", _UUID);
	}

	/**
	 * Reduces an array of interfaces to a flat array of interface type strings
	 * Used for quick and dirty interface comparison during port compatibility check
	 * ! We probably should not rely on this approach carelessly in production
	 * @returns {Array<string>} array of interface.type strings
	 */
	flattenInterfaces() {
		const result = this.interfaces.map((_interface) => _interface.type.type);
		return result;
	}

	/**
	 * Returns the port this port is connected to.
	 * @returns {Port|null} connected port or null
	 */
	get isConnectedTo() {
		return this.__isConnectedTo;
	}

	/**
	 * Sets the port this port is connected to.
	 * @param {Port|null} _port to connect to.
	 * @fires port.isConnectedTo.changed
	 */
	set isConnectedTo(_port) {
		if (this.__isConnectedTo === _port) return;
		this.__isConnectedTo = _port;
		this.eventManager.dispatch(this.signatures.isConnectedToChanged, this);
	}

	/**
	 *
	 */
	get parent() {
		return this.__parent;
	}

	/**
	 *
	 */
	set parent(_parent) {
		if (this.__parent === _parent) return;
		this.__parent = _parent;
	}

	/**
	 * Returns the variable characteristics of an Interface.
	 * @returns {object} containing port parameters, UUID, and databaseId.
	 */
	save() {
		return {
			UUID: this.UUID,
			application: this.application,
			description: this.description,
			databaseId: this.databaseId,
			dbNumber: this.dbNumber,
			isShadowedBy: this.isShadowedBy, // necessary to reconstruct connections to shadowPort
			graphics: this.graphics.save(),
			interfaces: this.interfaces.map((_interface) => _interface.save()),
			label: this.__label.save(),
			side: this.side,
			wiresProcessing: this.wiresProcessing.map((_wireProcessing) => _wireProcessing.save()),
		};
	}

	/**
	 * Returns a clone of this Port (including it's electrical parameters and interfaces)
	 * Resets all UUID's involved!
	 * @returns {object} new PortSourceData
	 */
	clone() {
		const tmpClone = {...this.save(), UUID: null};
		tmpClone.interfaces.forEach((_interfaceData) => (_interfaceData.UUID = null));
		return portFactory(tmpClone);
	}
}
