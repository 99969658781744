import {addSourceDataToDataRoot, dataRoot} from "./dataManager";
import {applicationEnum, interfaceTypeEnum, deviceRoleEnum, portLayoutEnum, interfaceOperatingModeEnum} from "./constantsAndEnumerations";
import {Application} from "./constantsAndEnumerations2";
import {setNullIfUndefined, warnIfMandatoryValueNotSet, setDefaultIfUndefined} from "./helper";

import deviceNodeIcon from "../images/outlinerIcons/deviceNode.icon.svg";
import unitNodeIcon from "../images/outlinerIcons/unitNode.icon.svg";
import {PortFamily, PortGender, PortSide} from "./ports/utils";

//TODO	Implement runtime type validation via zod

//TODO			see https://lq-group.atlassian.net/browse/LQSK-1571?atlOrigin=eyJpIjoiM2ZkNDU2ZWNlYWE2NGY5MWE4YmE1NjU0MTVmODE0NjkiLCJwIjoiaiJ9
//TODO			https://www.npmjs.com/package/zod

/**
 * Handles any data conversion between client and REST API
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		type should be called nodeType (line 127)
 *		image file path should be part of imageList (atm is part of sourceDeviceData)
 *		Fully switch to REST-API-4 definition
 *
 * AUTHOR(S):
 *		Christian Lange
 */

const verboseParsing = true; // turn error messages on/off while parsing

/**
 * Processing the result of requestSourceData (writing sourceData into dataRoot)
 * @param {object} _data returned from server
 */
export function processSourceData(_data) {
	const group = _data.topic;
	const imagePath = `${location.href}${_data.imgPath}`;
	const sourceData = _data.deviceList; // @cl refactor server side into elementList

	// iterate over sourceData and extract unique device subGroups
	const tmpSourceDataArray = [];
	sourceData.forEach((_element) => {
		if (tmpSourceDataArray[convertRESTSubGroup(_element.type.name)] === undefined) {
			// prevent duplicates
			tmpSourceDataArray[convertRESTSubGroup(_element.type.name)] = []; // add subArray representing a unique subGroup
		}
	});

	// iterate over sourceData again and fill all device subgroups with deviceData
	sourceData.forEach((_element) => {
		_element.group = group; // TODO atm manually storing group (consumers, functions), REST 4.0 will provide some already integrated info later on
		_element.subGroup = convertRESTSubGroup(_element.type.name); // TODO atm manually storing subGroup (actors, sensors etc...), REST 4.0 will provide some already integrated info later on
		_element.imagePath = imagePath; // TODO atm manually storing imagePath, REST 4.0 should provide that info!
		if (_element.group === "cables") {
			tmpSourceDataArray[_element.subGroup].push(parseRESTCableData(_element));
		} else {
			tmpSourceDataArray[_element.subGroup].push(parseRESTDeviceData(_element));
		}
	});

	// Iterate over all converted sourceData and write each entry into dataRoot.sourceData
	for (const subGroup in tmpSourceDataArray) {
		if (Object.prototype.hasOwnProperty.call(tmpSourceDataArray, subGroup)) {
			tmpSourceDataArray[subGroup].forEach((_subGroup) => {
				addSourceDataToDataRoot(_subGroup);
			});
		}
	}

	/**
	 * Temp converts server subgroups sent in german
	 * @param {string} _subGroup in german
	 * @returns {string} subgroup code
	 */
	function convertRESTSubGroup(_subGroup) {
		/* cspell:disable */
		switch (_subGroup) {
			case "Aktoren":
				return "accordionManager.actors";
			case "Sensoren":
				return "accordionManager.sensors";
			case "Befehlsgeber":
				return "accordionManager.controllers";
			case "Sonderobjekte":
				return "accordionManager.specials";
			case "Schützen Schalten manuell 400 V":
				return "accordionManager.protect-switch-manually-400";
			case "Verteilen Schützen 400 V und 400 / 24 V":
				return "accordionManager.distribute-protect-400-24";
			case "Schützen Schalten 400 V":
				return "accordionManager.protect-switch-400";
			case "Schützen Schalten 400 V Motoren":
				return "accordionManager.protect-switch-400-motors";
			case "Regeln 400 V Motoren":
				return "accordionManager.control-400-motors";
			case "Wandeln 400 V AC / 24 V DC":
				return "accordionManager.convert-400-24";
			case "Wandeln Bussysteme":
				return "accordionManager.convert-bus";
			case "Schützen Schalten 24 V":
				return "accordionManager.protect-switch-24";
			case "Verteilen Energie und Daten":
				return "accordionManager.distribute-energy-data";
			case "Messen Überwachen":
				return "accordionManager.measure-monitor";
		}
		/* cspell:enable */
	}
}

/**
 * Parses the standard REST-API device definition into the clients default data structure
 * @param {JSON} _sourceDeviceData standard device data from REST api
 * @returns {object} device formatted to client defaults
 */
function parseRESTDeviceData(_sourceDeviceData) {
	const tmpDevice = {
		group: _sourceDeviceData.group, // TODO atm manually storing group (consumers, functions), REST 4.0 will provide some already integrated info later on
		subGroup: _sourceDeviceData.subGroup, // TODO atm manually storing subGroup (actors, sensors etc...), REST 4.0 will provide some already integrated info later on
		decentralControl: null, //JIRA LQSK-1666 we don't need hardcoded decentralControl for each device bc it conflicts with global decentralControl vs local decentralControl
		isArchived: setNullIfUndefined(_sourceDeviceData.archiveFlg),
		referenceDesignator: {deviceComponent: {token: setNullIfUndefined(_sourceDeviceData.bmk)}},
		description: setNullIfUndefined(_sourceDeviceData.desc),
		type: _convertRESTSubGroup2Type(_sourceDeviceData.subGroup), // TODO type should be called nodeType
		databaseId: warnIfMandatoryValueNotSet(_sourceDeviceData.id),
		sameSecondPort: !!(_sourceDeviceData.subGroup === "accordionManager.actors" && _sourceDeviceData.connectorList.length === 2), // for Valve 2 Coils now _sourceDeviceData.id === 6
		level: _sourceDeviceData.group === "cables" ? null : warnIfMandatoryValueNotSet(_sourceDeviceData.level),
		manufacturer: setDefaultIfUndefined(_sourceDeviceData.manufacturer, "-"), // TODO is mandatory for products
		materialNumber: setNullIfUndefined(_sourceDeviceData.matnr), // TODO is mandatory for products
		name: warnIfMandatoryValueNotSet(_sourceDeviceData.name),
		role: _sourceDeviceData.role ? deviceRoleEnum[_sourceDeviceData.role] : deviceRoleEnum.OTHER,
		i18nKey: null, // TODO fill with data once REST API has changed and it providing the necessary field
		price: setNullIfUndefined(_sourceDeviceData.price), // TODO is mandatory for products	// @cl is that really used anywhere? should we get rid of it?
		graphics: _parseRestImageData(
			_sourceDeviceData.imageList,
			_sourceDeviceData.imagePath,
			_convertRESTSubGroup2Type(_sourceDeviceData.subGroup),
			setDefaultIfUndefined(_sourceDeviceData.desc, _sourceDeviceData.name),
		),
		keyParameters: [
			{name: "voltage", port: 0, interface: 0},
			{name: "current", port: 0, interface: 0},
		],
		// availablePorts: [],
		ports: [],
	};

	_sourceDeviceData.connectorList.forEach((_connector) => {
		const tmpPort = parseRESTPortData(_connector, _sourceDeviceData.group, _sourceDeviceData.id);
		tmpDevice.ports.push(tmpPort);
	});

	// comment this block if you need server data instead of svg data
	// const svgContent = getSVGDoc(tmpDevice.graphics.image.file); // getting props from svg
	// const svgProperties = getSvgProperties(svgContent);
	// tmpDevice.graphics.image.width = svgProperties.nodeData.width;
	// tmpDevice.graphics.image.height = svgProperties.nodeData.height;
	// tmpDevice.graphics.image.scale = svgProperties.nodeData.scale;
	// tmpDevice.ports.forEach((tmpPort) => {
	// 	const svgPort = searchArrayForElementByKeyValuePair(svgProperties.portData, "dbNumber", tmpPort.dbNumber);
	// 	tmpPort.geometry.position = {x: svgPort.x, y: svgPort.y};
	// });

	/**
	 * Parses the standard REST-API imageList definition into the clients default data structure
	 * ! This functions arguments are a bit messy, because sourceImageList does not provide all the needed information on it's own
	 * @param {JSON} _sourceImageList standard device data from REST API
	 * @param {string} _filePath to provided graphic files
	 * @param {string} _type deviceNode or unitNode (ugly but necessary to choose correct icon)
	 * @returns {object} graphics object formatted to client defaults
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	function _parseRestImageData(_sourceImageList, _filePath, _type) {
		const imageSourceData = _sourceImageList.find((_element) => _element.typ === 1);

		const symbolSourceData = _sourceImageList.find((_element) => _element.typ === 2);

		const tmpImage = {
			file: _filePath + imageSourceData.filename,
			width: imageSourceData.imgWidth * imageSourceData.scale,
			height: imageSourceData.imgHeight * imageSourceData.scale,
		};

		const tmpSymbol = {
			file: _filePath + symbolSourceData.filename,
			width: symbolSourceData.imgWidth * symbolSourceData.scale,
			height: symbolSourceData.imgHeight * symbolSourceData.scale,
		};

		const tmpIcon = {
			file: _type === "deviceNode" ? deviceNodeIcon : unitNodeIcon,
			width: null,
			height: null,
		};

		const tmpGraphics = {
			image: tmpImage,
			symbol: tmpSymbol,
			icon: tmpIcon,
			position: {
				x: null,
				y: null,
			},
			layout: {
				pan: {x: null, y: null},
				zoom: null,
			},
			tooltip: setDefaultIfUndefined(_sourceDeviceData.desc, _sourceDeviceData.name),
		};

		return tmpGraphics;
	}

	/**
	 * Parses the standard REST-API subGroup definition into deviceNode or unitNode (used to differentiate between "Sonderobjekte" and normal devices)
	 * @param {string} _subGroup standard rest info
	 * @returns {string} deviceNode or unitNode
	 */
	// eslint-disable-next-line @typescript-eslint/naming-convention
	function _convertRESTSubGroup2Type(_subGroup) {
		if (_subGroup === "accordionManager.specials") {
			return "unitNode";
		} else {
			return "deviceNode";
		}
	}

	return tmpDevice;
}

/**
 * Parses the standard REST-API cable definition into the clients default data structure
 * @param {JSON} _sourceCableData standard cable data from REST api
 * @returns {object} cable formatted to client defaults
 */
function parseRESTCableData(_sourceCableData) {
	const tmpCable = {
		application: applicationEnum.ELECTRIC.type,
		group: _sourceCableData.group,
		isArchived: setNullIfUndefined(_sourceCableData.archiveFlg),
		referenceDesignator: {deviceComponent: {token: setNullIfUndefined(_sourceCableData.bmk)}},
		description: _sourceCableData.desc === "Dummy Kabel für Konfigurator" ? _sourceCableData.desc : "", // because of dummy cables
		databaseId: warnIfMandatoryValueNotSet(_sourceCableData.id),
		manufacturer: setDefaultIfUndefined(_sourceCableData.manufacturer, "-"), // TODO is mandatory for products
		materialNumber: setNullIfUndefined(_sourceCableData.matnr), // TODO is mandatory for products
		name: warnIfMandatoryValueNotSet(_sourceCableData.name),
		cleanName: convertCableName(_sourceCableData.name),
		i18nKey: null, // TODO fill with data once REST API has changed and it providing the necessary field
		price: setNullIfUndefined(_sourceCableData.price), // TODO is mandatory for products	// @cl is that really used anywhere? should we get rid of it?
		graphics: null,
		dynamicApplication: setNullIfUndefined(_sourceCableData.type.daField),
		length: 1000, //TODO default length in [mm], should be provided by AVISTA for each cable individually, probably varies for each cable type
		minLength: 300, //TODO minimum length in [mm], should be provided by AVISTA for each cable individually, surely varies for each cable type (driven e.g. by bending radius - a small asi cable will probably behave differently than a wtec15 cable)
		maxLength: 999990, // results in 99999cm -> max materialNumber length			//TODO maximum length in [mm], should be provided by AVISTA for each cable individually, probably varies for each cable type (an asi bus has different max segment length than a wtec15)
		isShielded: setNullIfUndefined(_sourceCableData.type.shieldFlg),
		leads: parseRestWiresData(_sourceCableData),
		composition: parseRestWiresComposition(_sourceCableData),
		ports: [],
		interfaces: [],
	};

	_sourceCableData.connectorSource.interfaceList = _sourceCableData.interfaceList;
	_sourceCableData.connectorTarget.interfaceList = _sourceCableData.interfaceList;
	tmpCable.ports.push(parseRESTPortData(_sourceCableData.connectorSource, _sourceCableData.group, _sourceCableData.matnr, tmpCable.wiresClient));
	tmpCable.ports.push(parseRESTPortData(_sourceCableData.connectorTarget, _sourceCableData.group, _sourceCableData.matnr, tmpCable.wiresClient));

	return tmpCable;

	/**
	 * Strips the cable name property of all kinds of stupid stuff
	 * @param {string} _cableName server provided name of cable
	 * @returns {string} cleaned version of cable name
	 */
	function convertCableName(_cableName) {
		let tmpName = _cableName;
		tmpName = tmpName.replace("UNGESCHIRMT ", "");
		tmpName = tmpName.replace("GESCHIRMT ", "");
		tmpName = tmpName.replace("DA0", "");
		tmpName = tmpName.replace("DA4", "");
		tmpName = tmpName.replace("STI/BU ", "");
		tmpName = tmpName.replace("STI/GLATT ", "");
		tmpName = tmpName.replace("GLATT/BU ", "");
		tmpName = tmpName.replace("4x2,5 ", "");
		tmpName = tmpName.replace("6x2,5 ", "");
		tmpName = tmpName.replace("6x1,5 ", "");
		tmpName = tmpName.replace("4x1,5 ", "");
		tmpName = tmpName.replace("4x0,34 ", "");
		tmpName = tmpName.replace("4x2,5+2x1,5 ", "");
		tmpName = tmpName.replace("4x4,0-G", "");
		tmpName = tmpName.replace("4x2,5-G ", "");
		tmpName = tmpName.replace("4x10,0-G ", "");
		tmpName = tmpName.replace("4x1,5-G ", "");
		tmpName = tmpName.replace("6x2,5-G ", "");
		tmpName = tmpName.replace("6x1,5-G ", "");
		tmpName = tmpName.replace("4xAWG22UL", "");
		tmpName = tmpName.replace("2x1,5", "");
		tmpName = tmpName.replace("4x1,5+", "");
		tmpName = tmpName.replace("GLATT/BU-90°R", "");
		tmpName = tmpName.replace("GLATT/BU-90°L", "");
		tmpName = tmpName.replace("STI/BU-90°R", "");
		tmpName = tmpName.replace("STI/BU-90°L", "");
		tmpName = tmpName.replace("Dummy Kabel", "Dummy-Kabel");
		tmpName = tmpName.trim();

		return tmpName;
	}

	/**
	 * Returns a string representation (e.g. '3x1.5+2x1.5') of leads of this connection.
	 * @param {JSON} _sourceCableData standard cable data from REST api
	 * @returns {object} composition of leads
	 */
	function parseRestWiresComposition(_sourceCableData) {
		const mainLead = _sourceCableData.wires.find((_wire) => _wire.sort === "MAIN");
		const mainLeadsNumber = mainLead ? mainLead.quantity : null;
		const mainLeadsSection = mainLead ? mainLead.section : null;
		const mainString = mainLead ? `${mainLeadsNumber}x${mainLeadsSection}` : null;

		const auxLead = _sourceCableData.wires.find((_wire) => _wire.sort === "AUX");
		const auxLeadsNumber = auxLead ? auxLead.quantity : null;
		const auxLeadsSection = auxLead ? auxLead.section : null;
		const auxString = auxLead ? `${auxLeadsNumber}x${auxLeadsSection}` : null;

		return `${[mainString, auxString].filter(Boolean).join("+")}`;
	}

	/**
	 * Parses the standard REST-API wires definition into the clients default data structure
	 * @param {JSON} _sourceCableData standard cable data from REST api
	 * @returns {object} wires formatted to client defaults
	 * @throws Exception providing additional information on error
	 */
	function parseRestWiresData(_sourceCableData) {
		if (_sourceCableData.wires.length !== _sourceCableData.interfaceList.length) {
			/* eslint-disable no-console */
			console.groupCollapsed(`%cERROR Leads/Interfaces mismatch for ${_sourceCableData.name} [${_sourceCableData.id}]`, "color: red");
			console.log("wires: ", _sourceCableData.wires);
			console.log("interfaces: ", _sourceCableData.interfaceList);
			console.groupEnd();
			/* eslint-enable no-console */
			throw new Error(`Leads/Interfaces mismatch for ${_sourceCableData.name} [${_sourceCableData.id}]`);
		}

		// server delivers wires and maxCurrent/maxVoltage in different arrays _sourceCableData.wires[] & _sourceCableData.interfaceList[] -> here both get mapped onto one helper object:
		const rawLeads = _sourceCableData.wires.map((_wire) => {
			const index = _sourceCableData.wires.indexOf(_wire);
			const tmpInterface = _sourceCableData.interfaceList[index]; // map wire index to interface index
			return {
				number: _wire.quantity,
				section: _wire.section,
				currentMax: tmpInterface.currentMax,
				voltageMax: tmpInterface.voltageMax,
				role: _wire.sort,
				mappedInterface: null,
			};
		});

		// now everything gets converted into the desirable client format
		const leads = [];

		rawLeads.forEach((_leadGroup) => {
			for (let i = 0; i < _leadGroup.number; i++) {
				leads.push({
					id: null,
					section: _leadGroup.section,
					currentMax: _leadGroup.currentMax,
					voltageMax: _leadGroup.voltageMax,
					role: _leadGroup.role,
					mappedInterface: null,
				});
			}
		});

		for (let i = 0; i < leads.length; i++) {
			leads[i].id = i;
		}

		return leads;
	}
}

/**
 * Parses the standard REST-API port definition into the clients default data structure
 * @param {JSON} _sourcePortData standard port data from REST api
 * @param {string} _group - device group
 * @returns {object} port formatted to client defaults
 */
export function parseRESTPortData(_sourcePortData, _group) {
	const tmpPort = {
		application: Application.ELECTRIC,
		dbNumber: _sourcePortData.port,
		databaseId: warnIfMandatoryValueNotSet(_sourcePortData.id),
		family: getPortFamilyById(_sourcePortData.id),
		side: _group !== "cables" ? PortSide[_sourcePortData.side] : null,
		isArchived: setNullIfUndefined(_sourcePortData.archiveFlg),
		isShielded: setNullIfUndefined(_sourcePortData.shieldFlg),
		isShadowedBy: null,
		shadows: null,
		description: setNullIfUndefined(_sourcePortData.descr),
		label: {style: "NONE", distance: _group === "cables" ? 100 : 0, text: null},
		gender: PortGender[_sourcePortData.gender],
		geometry: {
			// not for cables
			layout: portLayoutEnum[_sourcePortData.layout],
		},
		wiresProcessing: [],
		layout: portLayoutEnum[_sourcePortData.layout],
		interfaces: [],
		graphics: parseRestPortImageData(_sourcePortData),
		leads: convertRESTPortLeads(_sourcePortData.id),
		composition: convertRESTPortComposition(convertRESTPortLeads(_sourcePortData.id)),
	};

	if (_group !== "cables") {
		//! we don't map interfaces on cablePorts (it's done dynamically on connecting the cable)
		_sourcePortData.interfaceList.forEach((_element) => {
			tmpPort.interfaces.push(parseRESTInterfaceData(_element));
		});
	}
	return tmpPort;
}

/**
 *
 * @param _leads
 */
function convertRESTPortComposition(_leads) {
	const mainLeads = _leads.filter((_lead) => _lead.role === "MAIN");
	const mainLeadsNumber = mainLeads.length > 0 ? mainLeads.length : null;
	const mainString = mainLeads.length > 0 ? `${mainLeadsNumber}xMain` : null;
	const auxLeads = _leads.filter((_lead) => _lead.role === "AUX");
	const auxLeadsNumber = auxLeads.length > 0 ? auxLeads.length : null;
	const auxString = auxLeads.length > 0 ? `${auxLeadsNumber}xAux` : null;

	return `${[mainString, auxString].filter(Boolean).join(" | ")}`;
}

/**
 * Fakes the port leads object based on port ids.
 * @param {number} _databaseId old integer based port definition from server request
 * @returns {object} wires
 * @deprecated Just a temporary hack. Don't build pyramids on it!
 */
function convertRESTPortLeads(_databaseId) {
	let rawLeads = null;

	switch (_databaseId) {
		case 1100: // RIBBON_ENERGY
			rawLeads = [{number: 2, section: 1.5, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1110: // RIBBON_DATA
			rawLeads = [{number: 2, section: 1.5, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1200: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1201: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1202: // M12A
			rawLeads = [{number: 5, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1210: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1211: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1212: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1213: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 250, role: "MAIN"}];
			break;
		case 1214: // M12A
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 250, role: "MAIN"}];
			break;
		case 1215: // M12A
			rawLeads = [{number: 8, section: 0.25, currentMax: 2, voltageMax: 30, role: "MAIN"}];
			break;
		case 1216: // M12A
			rawLeads = [{number: 8, section: 0.25, currentMax: 2, voltageMax: 30, role: "MAIN"}];
			break;
		case 1220: // M12B
			rawLeads = [{number: 5, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1221: // M12B
			rawLeads = [{number: 5, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1222: // M12B
			rawLeads = [{number: 5, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1230: // M12T
			rawLeads = [{number: 4, section: 1.5, currentMax: 12, voltageMax: 63, role: "MAIN"}];
			break;
		case 1231: // M12T
			rawLeads = [{number: 4, section: 1.5, currentMax: 12, voltageMax: 63, role: "MAIN"}];
			break;
		case 1232: // M12T
			rawLeads = [{number: 4, section: 1.5, currentMax: 12, voltageMax: 63, role: "MAIN"}];
			break;
		case 1240: // M12D
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1241: // M12D
			rawLeads = [{number: 4, section: 0.34, currentMax: 4, voltageMax: 60, role: "MAIN"}];
			break;
		case 1250: // M12L
			rawLeads = [{number: 4, section: 2.5, currentMax: 16, voltageMax: 63, role: "MAIN"}];
			break;
		case 1251: // M12L
			rawLeads = [{number: 4, section: 2.5, currentMax: 16, voltageMax: 63, role: "MAIN"}];
			break;
		case 1260: // INCH78
			rawLeads = [{number: 5, section: 1.5, currentMax: 9, voltageMax: 250, role: "MAIN"}];
			break;
		case 1261: // INCH78
			rawLeads = [{number: 5, section: 1.5, currentMax: 9, voltageMax: 250, role: "MAIN"}];
			break;
		case 1500: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1501: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1502: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1503: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1504: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1505: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1510: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1511: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1512: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1513: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1514: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 1515: // XTEC15
			rawLeads = [
				{number: 4, section: 2.5, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 2, section: 2.5, currentMax: 10, voltageMax: 63, role: "AUX"},
			];
			break;
		case 2300: // XTEC23
			rawLeads = [
				{number: 4, section: 4.0, currentMax: 60, voltageMax: 630, role: "MAIN"},
				{number: 5, section: 1.5, currentMax: 10, voltageMax: 250, role: "AUX"},
			];
			break;
		case 2301: // XTEC23
			rawLeads = [
				{number: 4, section: 4.0, currentMax: 60, voltageMax: 630, role: "MAIN"},
				{number: 5, section: 1.5, currentMax: 10, voltageMax: 250, role: "AUX"},
			];
			break;
		case 3200: // XTEC32
			rawLeads = [
				{number: 4, section: 10.0, currentMax: 60, voltageMax: 630, role: "MAIN"},
				{number: 5, section: 1.5, currentMax: 10, voltageMax: 250, role: "AUX"},
			];
			break;
		case 3201: // XTEC32
			rawLeads = [
				{number: 4, section: 10.0, currentMax: 60, voltageMax: 630, role: "MAIN"},
				{number: 5, section: 1.5, currentMax: 10, voltageMax: 250, role: "AUX"},
			];
			break;
		case 4500: // RJ45 8pol
			rawLeads = [{number: 8, section: 0.25, currentMax: 1, voltageMax: 25, role: "MAIN"}];
			break;
		case 4501: // RJ45 8pol
			rawLeads = [{number: 8, section: 0.25, currentMax: 1, voltageMax: 25, role: "MAIN"}];
			break;
		case 4502: // RJ45 8pol
			rawLeads = [{number: 4, section: 0.25, currentMax: 1.5, voltageMax: 30, role: "MAIN"}];
			break;
		case 4503: // RJ45 8pol
			rawLeads = [{number: 4, section: 0.25, currentMax: 1.5, voltageMax: 30, role: "MAIN"}];
			break;
		case 9998: // OPEN			//! the values here are completely fictional
			rawLeads = [
				{number: 10, section: 33.0, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 10, section: 22.0, currentMax: 16, voltageMax: 600, role: "AUX"},
			];
			break;
		case 9999: // OPEN			//! the values here are completely fictional
			rawLeads = [
				{number: 10, section: 33.0, currentMax: 16, voltageMax: 600, role: "MAIN"},
				{number: 10, section: 22.0, currentMax: 16, voltageMax: 600, role: "AUX"},
			];
			break;
		default:
			throw new Error(`Could not parse port.pins:  Unknown port id "${_databaseId}"`);
	}

	const leads = [];

	rawLeads.forEach((_leadGroup) => {
		for (let i = 0; i < _leadGroup.number; i++) {
			leads.push({
				id: null,
				section: _leadGroup.section,
				currentMax: _leadGroup.currentMax,
				voltageMax: _leadGroup.voltageMax,
				role: _leadGroup.role,
				mappedInterface: null,
			});
		}
	});

	for (let i = 0; i < leads.length; i++) {
		leads[i].id = i;
	}

	return leads;
}

/**
 * Parses the standard REST-API port graphic definition into the clients default data structure
 * @param {object} _portData REST standard port description
 * @returns {object} graphics object formatted to client defaults
 */
export function parseRestPortImageData(_portData) {
	if (!_portData.picture) {
		// until now, cables didn't have "real" ports, just some pseudo port-like objects
		// cablePorts would basically not need PortGraphicsData, since their position, direction etc is handled very differently from regular devices
		// because of this the server didn't provide PortGraphicsData yet
		// since we switched cablePorts to real ports, we need to satisfy the port constructor for the portGraphics property/argument
		// the following code mocks fake PortGraphicsData until we find a better solution
		// maybe we need a cablePort class, maybe we should provide real PortGraphicsData, maybe this mock is enough forever)
		_portData.picture = {direction: "NORTH", xpos: 0, ypos: 0};
		_portData.symbol = {direction: "NORTH", xpos: 0, ypos: 0};
	}

	const tmpPortImage = {
		// orientation: portOrientationEnum[_portData.picture.direction],				//! correct usage of portOrientationEnum is not yet implemented in ports
		orientation: _portData.picture.direction,
		position: {
			x: _portData.picture.xpos,
			y: _portData.picture.ypos,
		},
	};

	const tmpPortSymbol = {
		// orientation: portOrientationEnum[_portData.symbol.direction],				//! correct usage of portOrientationEnum is not yet implemented in ports
		orientation: _portData.symbol.direction,
		position: {
			x: _portData.symbol.xpos,
			y: _portData.symbol.ypos,
		},
	};

	const tmpPortGraphics = {
		image: tmpPortImage,
		symbol: tmpPortSymbol,
		tooltip: "portDefaultTooltip",
	};

	return tmpPortGraphics;
}

/**
 * Extracts the port family from ports database id		// @CL refactor - this is just a temporary solution while reworking the ports! We need a better database model for ports
 * @param {number} _portId in database
 * @returns {PortFamily} matching _portId
 * @throws exception providing additional information on error
 */
function getPortFamilyById(_portId) {
	switch (_portId) {
		case 1100:
			return PortFamily.RIBBON;
		case 1110:
			return PortFamily.RIBBON;
		case 1200:
			return PortFamily.M12A;
		case 1201:
			return PortFamily.M12A;
		case 1202:
			return PortFamily.M12A;
		case 1210:
			return PortFamily.M12A;
		case 1211:
			return PortFamily.M12A;
		case 1212:
			return PortFamily.M12A;
		case 1213:
			return PortFamily.M12A;
		case 1214:
			return PortFamily.M12A;
		case 1215:
			return PortFamily.M12A;
		case 1216:
			return PortFamily.M12A;
		case 1220:
			return PortFamily.M12B;
		case 1221:
			return PortFamily.M12B;
		case 1222:
			return PortFamily.M12B;
		case 1230:
			return PortFamily.M12T;
		case 1231:
			return PortFamily.M12T;
		case 1232:
			return PortFamily.M12T;
		case 1240:
			return PortFamily.M12D;
		case 1241:
			return PortFamily.M12D;
		case 1250:
			return PortFamily.M12L;
		case 1251:
			return PortFamily.M12L;
		case 1260:
			return PortFamily.INCH78;
		case 1261:
			return PortFamily.INCH78;
		case 1500:
			return PortFamily.XTEC15;
		case 1501:
			return PortFamily.XTEC15;
		case 1502:
			return PortFamily.XTEC15;
		case 1503:
			return PortFamily.XTEC15;
		case 1504:
			return PortFamily.XTEC15;
		case 1505:
			return PortFamily.XTEC15;
		case 1510:
			return PortFamily.XTEC15;
		case 1511:
			return PortFamily.XTEC15;
		case 1512:
			return PortFamily.XTEC15;
		case 1513:
			return PortFamily.XTEC15;
		case 1514:
			return PortFamily.XTEC15;
		case 1515:
			return PortFamily.XTEC15;
		case 2300:
			return PortFamily.XTEC23;
		case 2301:
			return PortFamily.XTEC23;
		case 3200:
			return PortFamily.XTEC32;
		case 3201:
			return PortFamily.XTEC32;
		case 4500:
			return PortFamily.RJ45;
		case 4501:
			return PortFamily.RJ45;
		case 4502:
			return PortFamily.RJ45;
		case 4503:
			return PortFamily.RJ45;
		case 9998:
			return PortFamily.OPEN;
		case 9999:
			return PortFamily.OPEN;
		default:
			throw new Error(`PortId "${_portId}" cannot be mapped to a port family.`);
	}
}

/**
 * Parses the standard REST-API port interface definition into the clients default data structure
 * @param {JSON} _sourceInterfaceData standard port interface from REST api
 * @returns {object} interface formatted to client defaults
 */
function parseRESTInterfaceData(_sourceInterfaceData) {
	const tmpInterface = {
		application: Application.ELECTRIC,
		databaseId: warnIfMandatoryValueNotSet(_sourceInterfaceData.id),
		name: _sourceInterfaceData.name,
		type: convertRESTInterfaceType(_sourceInterfaceData.id),
		power: _sourceInterfaceData.power,
		frequency: _sourceInterfaceData.frequency,
		voltage:
			_sourceInterfaceData.voltage !== undefined ? warnIfMandatoryValueNotSet(_sourceInterfaceData.voltage) : warnIfMandatoryValueNotSet(_sourceInterfaceData.voltageMax),
		currentMax: setNullIfUndefined(_sourceInterfaceData.currentMax),
		currentMin: setNullIfUndefined(_sourceInterfaceData.currentMin),
		current: _sourceInterfaceData.currentRated ? _sourceInterfaceData.currentRated : setNullIfUndefined(_sourceInterfaceData.current),
		operatingMode: convertRESTInterfaceMode(_sourceInterfaceData.mode),
		isSafe: _sourceInterfaceData.safeFlg,
		isMotor: _sourceInterfaceData.motorFlg,
		isProtected: _sourceInterfaceData.protectionFlg,
		isMonitored: _sourceInterfaceData.monitorFlg,
		groupX: parseRestInterfaceTyp(_sourceInterfaceData.interfaceTyp),
		isSoftStarted: _sourceInterfaceData.softFlg,
		description: setNullIfUndefined(_sourceInterfaceData.descr),
		requiredLeads: convertRESTInterfaceRequiredLeads(_sourceInterfaceData.id), //TODO replace with real db data!
	};
	if (!dataRoot.interfaceSourceData.find((interfaceData) => interfaceData.databaseId === tmpInterface.databaseId)) dataRoot.interfaceSourceData.push(tmpInterface);
	return tmpInterface;
}

/**
 * Fakes the interface requiredLeads Property based on interface ids.
 * @param {number} _databaseId old integer based interface definition from server request
 * @returns {number} requiredLeads
 * @deprecated Just a temporary hack. Don't build pyramids on it!
 */
function convertRESTInterfaceRequiredLeads(_databaseId) {
	switch (_databaseId) {
		case 10: // ENERGY_AC
			return 4;
		case 20: // ENERGY_DC
			return 2;
		case 40: // DATA_ASI
			return 2;
		case 41: // DATA_IOLINK
			return 3;
		case 42: // DATA_PROFIBUS
			return 2;
		case 43: // DATA_PROFINET
			return 4;
		case 44: // DATA_ETHERCAT
			return 4;
		case 45: // DATA_POWERLINK
			return 8;
		case 46: // DATA_ETHERNET_IP
			return 4;
		case 47: // DATA_PARALLEL
			return 8; //! careful parallel is not restricted to 8 leads
		case 48: // DATA_CANOPEN
			return 4;
		case 50: // MEASURE_DIGITAL
			return 2; //! this is wrong! measure-digital should have 3 pins (this hack is necessary do deal with wrongly defined ports of ac-motor and motor-starters)
		case 60: // MEASURE_ANALOG_4_20MA
			return 3;
		case 61: // CONTROL_ANALOG_4_20MA
			return 3;
		case 62: // MEASURE_ANALOG_0_10V
			return 3;
		case 63: // CONTROL_ANALOG_0_10V
			return 3;
		default:
			throw new Error(`Could not parse interface.requiredWires:  Unknown interface id "${_databaseId}"`);
	}
}

/**
 * Converts REST interface TYPE definition into clients side interface.type definition
 * @param {number} _databaseId old integer based side definition from server request
 * @returns {interfaceTypeEnum} new enum based type definition
 */
export function convertRESTInterfaceType(_databaseId) {
	switch (_databaseId) {
		case 10:
			return interfaceTypeEnum.ENERGY_AC;
		case 20:
			return interfaceTypeEnum.ENERGY_DC;
		case 40:
			return interfaceTypeEnum.DATA_ASI;
		case 41:
			return interfaceTypeEnum.DATA_IOLINK;
		case 42:
			return interfaceTypeEnum.DATA_PROFIBUS;
		case 43:
			return interfaceTypeEnum.DATA_PROFINET;
		case 44:
			return interfaceTypeEnum.DATA_ETHERCAT;
		case 45:
			return interfaceTypeEnum.DATA_POWERLINK;
		case 46:
			return interfaceTypeEnum.DATA_ETHERNET_IP;
		case 47:
			return interfaceTypeEnum.DATA_PARALLEL;
		case 48:
			return interfaceTypeEnum.DATA_CANOPEN;
		case 50:
			return interfaceTypeEnum.MEASURE_DIGITAL;
		case 60:
			return interfaceTypeEnum.MEASURE_ANALOG_4_20MA;
		case 61:
			return interfaceTypeEnum.CONTROL_ANALOG_4_20MA;
		case 62:
			return interfaceTypeEnum.MEASURE_ANALOG_0_10V;
		case 63:
			return interfaceTypeEnum.CONTROL_ANALOG_0_10V;
		default:
			throw new Error("Could not parse interface type: " + _databaseId);
	}
}

/**
 * Converts REST interface MODE definition into clients side interface.mode definition
 * @param {number} _mode old integer based mode definition from server request
 * @returns {interfaceOperatingModeEnum} new enum based interface definition
 */
function convertRESTInterfaceMode(_mode) {
	switch (_mode) {
		case 1:
		case 0:
			return interfaceOperatingModeEnum.CONTINUOUS;
		case 2:
			return interfaceOperatingModeEnum.ON_OFF;
		case 3:
			return interfaceOperatingModeEnum.LEFT_RIGHT;
		case 4:
			return interfaceOperatingModeEnum.SPEED_REGULATED;
		default:
			// throw new Error("Could not parse interface mode: " +_mode);
			return null; // not relevant for cables
	}
}

/**
 *
 * @param _interfaceTyp
 */
export function parseRestInterfaceTyp(_interfaceTyp) {
	switch (_interfaceTyp) {
		case 1:
			return "ENERGY";
		case 2:
			return "DATA";
		case 3:
			return "SIGNAL";
		case 4:
			return "SIGNAL";
		default:
			throw new Error(`Unknown _interfaceTyp ${_interfaceTyp}`);
	}
}

/**
 * Processes connector data provided by the backend during initialization phase
 * @param {object} _data connector data
 */
export function processConnectorSourceData(_data) {
	//JIRA [LQSK-1558] Eigenschaft Kabelport/Geräteport einführen
	const purpose = "CONNECTION"; //! hardcoded until port.purpose is sent by backend

	dataRoot.portSourceData = _data.connectorList.map((_connector) => ({
		application: Application.ELECTRIC,
		databaseId: warnIfMandatoryValueNotSet(_connector.id),
		family: getPortFamilyById(_connector.id),
		isShielded: _connector.shieldFlg,
		description: setNullIfUndefined(_connector.description),
		gender: PortGender[_connector.gender],
		geometry: {
			layout: portLayoutEnum[_connector.layout],
		},
		graphics: parseRestPortImageData({}),
		side: null,
		isShadowedBy: null,
		shadows: null,
		interfaces: [],
		materialNumber: setNullIfUndefined(_connector.matnr),
		label: {style: "NONE", distance: purpose === "CONNECTION" ? 100 : 0, text: null},
		leads: convertRESTPortLeads(_connector.id),
		composition: convertRESTPortComposition(convertRESTPortLeads(_connector.id)),
		wiresProcessing: [],
	}));
}
