/* eslint-disable require-jsdoc */
/* eslint-disable jsdoc/require-jsdoc */

import {Application} from "../constantsAndEnumerations2";
import {createUUID, getConnectionSourceDataByDatabaseId} from "../dataManager";

import {ElectricConnection} from "./ElectricConnection";
import {HydraulicConnection} from "./HydraulicConnection";
import {PneumaticConnection} from "./PneumaticConnection";

import type {BaseConnectionData, BaseConnectionSaveData, BaseConnectionSourceData} from "./baseConnection.schema";

import type {ElectricConnectionData} from "./electricConnection.schema";
import type {HydraulicConnectionData} from "./hydraulicConnection.schema";
import type {PneumaticConnectionData} from "./pneumaticConnection.schema";

export function connectionFactory(_connectionDatabaseId: number): ElectricConnection | HydraulicConnection | PneumaticConnection;
export function connectionFactory(_connectionSaveData: BaseConnectionSaveData): ElectricConnection | HydraulicConnection | PneumaticConnection;
export function connectionFactory(_connectionSourceData: BaseConnectionSourceData): ElectricConnection | HydraulicConnection | PneumaticConnection;
export function connectionFactory(
	_connectionReference: number | BaseConnectionSaveData | BaseConnectionSourceData,
): ElectricConnection | HydraulicConnection | PneumaticConnection {
	let connectionData: BaseConnectionData;

	if (typeof _connectionReference === "number") {
		connectionData = {
			...(getConnectionSourceDataByDatabaseId(_connectionReference) as BaseConnectionSourceData), //TODO casting to BaseConnectionSourceData can be omitted once getConnectionSourceDataByDatabaseId has a correct return type
			UUID: createUUID(),
			sourceDevicePortUUID: null,
			targetDevicePortUUID: null,
		};
	} else if (!("UUID" in _connectionReference)) {
		// connectionSourceData
		connectionData = {
			...(_connectionReference as BaseConnectionSourceData),
			UUID: createUUID(),
			sourceDevicePortUUID: null,
			targetDevicePortUUID: null,
		};
	} else {
		// connectionSaveData
		connectionData = {
			...(getConnectionSourceDataByDatabaseId(_connectionReference.databaseId) as BaseConnectionSourceData), //TODO casting to BaseConnectionSourceData can be omitted once getConnectionSourceDataByDatabaseId has a correct return type
			...(_connectionReference as BaseConnectionSaveData),
		};
	}

	switch (Application[connectionData.application]) {
		case Application.ELECTRIC:
			return new ElectricConnection(connectionData as ElectricConnectionData);
		case Application.HYDRAULIC:
			return new HydraulicConnection(connectionData as HydraulicConnectionData);
		case Application.PNEUMATIC:
			return new PneumaticConnection(connectionData as PneumaticConnectionData);
		default:
			throw new Error(`ConnectionFactory: Unknown application "${connectionData.application}".`);
	}
}
