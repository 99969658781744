import {GLOBALEVENTMANAGER} from								"./applicationManager";
import {accordionLayoutEnum} from								"./constantsAndEnumerations";
import {checkUniqueness} from										"./helper";
import {bindDomElementToTranslateEvent} from		"./localization/localizationManager";

import "simplebar";


/** Objects for creation of collapsible dom containers
 *
 * UTILIZING:
 *		SimpleBar as a replacement for the standard scrollbar, details under https://www.npmjs.com/package/simplebar
 *
 * BUGS:
 *		nothing atm
 *
 * TODO
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 */


/** Base class for collapsible dom elements
 * @export
 * @class CollapsibleBase
 */
export class CollapsibleBase {
	/** Standard constructor
	 * @param  {String} _id of this collapsible
	 * @param  {i18nKey} _captionKey to display
	 */
	constructor(_id, _captionKey) {
		this.id = checkUniqueness(_id);
		this.captionKey = _captionKey;
		this.domContainer = {};							// DOM wrapper of this collapsible
		this.headingContainer = {};					// DOM caption-element of this collapsible
		this.contentContainer = {};					// DOM content-inner element of this collapsible
		this.items = [];										// array of all elements inside this collapsibles content-inner
		this.createDomStructure();
	}

	/** Creates the necessary dom elements for this collapsible */
	createDomStructure() {
		this.domContainer = document.createElement("div");
		this.domContainer.id = this.id;
		this.domContainer.classList.add("collapsible");

		this.headingContainer = document.createElement("div");
		this.headingContainer.classList.add("collapsible-heading-wrapper", "noselect");
		this.headingContainer.addEventListener("click", () => this.toggleExpanded());
		bindDomElementToTranslateEvent(this.headingContainer, "title", this.captionKey);
		this.domContainer.appendChild(this.headingContainer);

		const tmpCaption = document.createElement("span");
		tmpCaption.classList.add("collapsible-heading-caption");
		bindDomElementToTranslateEvent(tmpCaption, "textContent", this.captionKey);
		this.headingContainer.appendChild(tmpCaption);

		const tmpSymbol = document.createElement("span");
		tmpSymbol.classList.add("collapsible-heading-symbol");
		this.headingContainer.appendChild(tmpSymbol);

		const tmpContentWrapper = document.createElement("div");
		tmpContentWrapper.classList.add("collapsible-content-wrapper");
		tmpContentWrapper.setAttribute("data-simplebar", "");
		this.domContainer.appendChild(tmpContentWrapper);

		this.contentContainer = document.createElement("div");
		this.contentContainer.classList.add("collapsible-content");
		tmpContentWrapper.appendChild(this.contentContainer);
	}

	/** Sets this collapsibles expanded status
	 * @param  {Boolean} _expanded status to set
	 */
	setExpanded(_expanded) {
		(_expanded) ? this.domContainer.classList.add("collapsible-expanded") : this.domContainer.classList.remove("collapsible-expanded");
	}

	/** Toggles this collapsibles expanded status and raises event if expanded=true */
	toggleExpanded() {
		this.domContainer.classList.toggle("collapsible-expanded");
		if (this.domContainer.classList.contains("collapsible-expanded")) GLOBALEVENTMANAGER.dispatch(`collapsibleExpanded_${this.domContainer.id}`, this);
	}

	/** Adds an item to this collapsibles content
	 * @param  {CollapsibleItem} _item to add
	 */
	addItem(_item) {
		this.items.push(_item);
		this.contentContainer.appendChild(_item.domContainer);
	}

	/** Returns an item of this collapsible
	 * @param  {String} _id of item to find
	 * @returns {Item|false} with matching id or false if none was found
	 */
	getItemById(_id) {
		let result = false;
		this.items.forEach((element) => {
			if (element.id === _id) result = element;
		});
		return result;
	}
}

/** Accordion specific implementation of CollapsibleBase
 * @export
 * @class AccordionCollapsible
 * @extends {CollapsibleBase}
 */
export class AccordionCollapsible extends CollapsibleBase {
	/** Standard constructor
	 * @param  {String} _id of this collapsible
	 * @param  {i18nKey} _captionKey to display
	 * @param  {accordionLayoutEnum} [_layout = accordionLayoutEnum.TILE] layout to set (optional, defaults to accordionLayoutEnum.TILE)
	 */
	constructor(_id, _captionKey, _layout = accordionLayoutEnum.TILE) {
		super(_id, _captionKey);
		this.layout = _layout;
		this.extendDomStructure();
		GLOBALEVENTMANAGER.addHandler("eGUI_ToggleCollapsibleLayout", (layout) => this.toggleLayout(layout));
		GLOBALEVENTMANAGER.addHandler("eGUI_SearchForAccordionItem", (query) => this.findItem(query));
	}

	/** Creates the necessary dom elements for this collapsible child class */
	extendDomStructure() {
		this.contentContainer.classList.add(this.layout.css);
	}

	/** Overwrites base class functionality
	 * @param  {CollapsibleAccordionItem} _item to add
	 */
	addItem(_item) {
		this.items.push(_item);
		this.contentContainer.appendChild(_item.domContainer);
		_item.setLayout(this.layout);
	}

	/** Toggles this collapsible content layout between list and tiling and sets all items accordingly
	 * @param  {accordionLayoutEnum} _layout to set
	 */
	toggleLayout(_layout) {
		// remove former layout
		this.contentContainer.classList.remove(this.layout.css);

		// toggle active layout
		this.layout = _layout;
		// add new layout
		this.contentContainer.classList.add(this.layout.css);

		// update layout of all items
		this.items.forEach((item) => {
			item.setLayout(this.layout);
		});
	}

	/** Find items in this collapsible that match the search criteria
	 * @param  {[String]} _query name/materialNumber of item or parts of it
	 */
	findItem(_query) {																													// this operation is VERY expansive!
		let result = false;
		if (_query.length !== 0) {																								// only search items for real queries (saves a lot of time)
			this.items.forEach((item) => {
				if (item.searchForProperty(_query)) result = true;
			});
		} else {																																	// unmark all items for empty queries
			this.items.forEach((item) => {
				item.setMarked(result);
			});
		}

		this.setMarked(result);
	}

	/** Sets this collapsibles marked status
	 * Used to mark matches from Accordion searches
	 * @param  {Boolean} _marked status to set
	 */
	setMarked(_marked) {
		if (_marked) {
			this.headingContainer.classList.add("collapsible-marked");
		} else {
			this.headingContainer.classList.remove("collapsible-marked");
		}
	}
}

/** LayerController specific implementation of CollapsibleBase
 * @export
 * @class LayerControllerCollapsible
 * @extends {CollapsibleBase}
 */
export class LayerControllerCollapsible extends CollapsibleBase {
	/** Standard constructor
	 * @param  {String} _id of this collapsible
	 */
	constructor(_id) {
		super(_id, "layerController.heading");
		this.extendDomStructure();
	}

	/** Creates the necessary dom elements for this collapsible child class
	 * @memberof LayerControllerCollapsible
	 */
	extendDomStructure() {
		this.domContainer.classList.add("collapsible-layerController");
	}
}
