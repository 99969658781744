import {schemaCheckbox, schemaPositiveInteger} from "../../resources/validationSchemata";
import {USER} from "../applicationManager";
import {accordionLayoutEnum, colorSchemeEnum, drawModeEnum, fieldBusEnum, languageEnum, technicalUnitEnum, systemBusEnum} from "../constantsAndEnumerations";
import {
	CustomCheckboxControl,
	CustomControlSpacer,
	customControlWarningModeEnum,
	CustomDropdownControl,
	CustomHeadLineControl,
	CustomInfoControl,
	CustomTextControl,
} from "../customControl";
import {DragListControl} from "../customControls/dragListControl/DragListControl";
import {DragListItemText} from "../customControls/dragListControl/DragListItemText";
import {handleUnregisteredUser} from "../dialogManager";
import {setAccordionLayout, updateToggleDrawModeButton} from "../guiManager";
import {getTranslation, switchLanguage} from "../localization/localizationManager";
import {CancelButton, ConfirmButton, ModalDialog} from "../modalDialog";

/** Opens a global settings dialog. */
export function globalSettingsDialog() {
	const modal = new ModalDialog("modalDialog.heading-GlobalSettings");

	// prepare list of available languages.
	const languageList = [];
	Object.values(languageEnum).forEach((element) => {
		languageList.push({caption: element.caption, value: element.type});
	});

	// prepare list of available accordion layouts.
	const layoutList = [];
	Object.values(accordionLayoutEnum).forEach((element) => {
		layoutList.push({caption: element.caption, value: element.type});
	});

	// prepare list of available drawing modes.
	const drawModeList = [];
	Object.values(drawModeEnum).forEach((element) => {
		drawModeList.push({caption: element.caption, value: element.type});
	});

	const colorSchemeList = [];
	Object.values(colorSchemeEnum).forEach((element) => {
		colorSchemeList.push({caption: element.caption, value: element.type});
	});

	// prepare field bus preferences list for DragListControl.
	const fieldBusPreferences = [];
	USER.fieldBusPreferences.forEach((bus) => {
		fieldBusPreferences.push(new DragListItemText({id: fieldBusEnum[bus].type, content: getTranslation(fieldBusEnum[bus].caption)}));
	});

	// prepare system bus preferences list for DragListControl.
	const systemBusPreferences = [];
	USER.systemBusPreferences.forEach((bus) => {
		systemBusPreferences.push(new DragListItemText({id: systemBusEnum[bus].type, content: getTranslation(systemBusEnum[bus].caption)}));
	});

	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok", okButtonCallBack));

	if (handleUnregisteredUser(modal, "modalDialog.globalSettings-Deactivated-Warning")) {
		// check if active user is registered
		modal.addControl(new CustomHeadLineControl("splashscreen", modal.contentArea, "modalDialog.splashscreenSettings"));
		modal.addControl(
			new CustomCheckboxControl("showGreeter", modal.contentArea, "modalDialog.chckbx-showGreeter", technicalUnitEnum.EMPTY, schemaCheckbox, showGreeterCallback),
		);
		modal.addControl(
			new CustomCheckboxControl("showChangelog", modal.contentArea, "modalDialog.chckbx-showChangelog", technicalUnitEnum.EMPTY, schemaCheckbox, showChangelogCallback),
		);
		modal.addControl(
			new CustomCheckboxControl(
				"showRecentProjects",
				modal.contentArea,
				"modalDialog.chckbx-showRecentProjects",
				technicalUnitEnum.EMPTY,
				schemaCheckbox,
				showRecentProjectsCallback,
			),
		);
		modal.addControl(
			new CustomTextControl(
				"recentProjectsCount",
				modal.contentArea,
				"modalDialog.recentProjectsCount",
				technicalUnitEnum.EMPTY,
				schemaPositiveInteger,
				recentProjectsCountCallback,
			),
		);

		modal.addControl(new CustomHeadLineControl("uncategorizedSettings", modal.contentArea, "modalDialog.uncategorizedSettings"));
		modal.addControl(new CustomDropdownControl("language", modal.contentArea, "modalDialog.language", technicalUnitEnum.EMPTY, languageList, languageCallback));
		modal.addControl(
			new CustomDropdownControl("accordionLayout", modal.contentArea, "modalDialog.accordionLayout", technicalUnitEnum.EMPTY, layoutList, accordionLayoutCallback),
		);
		modal.addControl(new CustomDropdownControl("drawMode", modal.contentArea, "modalDialog.drawMode", technicalUnitEnum.EMPTY, drawModeList, drawModeCallback));
		modal.addControl(
			new CustomDropdownControl("colorScheme", modal.contentArea, "modalDialog.colorScheme", technicalUnitEnum.EMPTY, colorSchemeList, colorSchemeCallback),
		);

		modal.addControl(new CustomHeadLineControl("autoConfigSettings", modal.contentArea, "modalDialog.autoConfigSettings"));
		modal.addControl(
			new CustomCheckboxControl("createCabinet", modal.contentArea, "modalDialog.chckbx-createCabinet", technicalUnitEnum.EMPTY, schemaCheckbox, createCabinetCallback),
		);
		modal.addControl(
			new CustomCheckboxControl(
				"preferDecentralMotors",
				modal.contentArea,
				"modalDialog.chckbx-preferDecentralMotors",
				technicalUnitEnum.EMPTY,
				schemaCheckbox,
				preferDecentralMotorCallback,
			),
		);
		modal.addControl(
			new CustomCheckboxControl(
				"preferDecentralConsumers",
				modal.contentArea,
				"modalDialog.chckbx-preferDecentralConsumers",
				technicalUnitEnum.EMPTY,
				schemaCheckbox,
				preferDecentralConsumersCallback,
			),
		);

		modal.addControl(new CustomHeadLineControl("busPreferences", modal.contentArea, getTranslation("busPreferences.heading")));

		modal.addControl(
			new CustomInfoControl("fieldBusLevel", modal.contentArea, getTranslation("busPreferences.fieldBusPreferenceList"), customControlWarningModeEnum.NORMAL),
		);
		modal.addControl(new DragListControl("fieldBusPreferences", modal.contentArea, fieldBusPreferencesCallback, fieldBusPreferences));
		modal.addControl(new CustomControlSpacer(modal.contentArea));

		modal.addControl(
			new CustomInfoControl("systemBusLevel", modal.contentArea, getTranslation("busPreferences.systemBusPreferenceList"), customControlWarningModeEnum.NORMAL),
		);
		modal.addControl(new DragListControl("systemBusPreferences", modal.contentArea, systemBusPreferencesCallback, systemBusPreferences));
		modal.addControl(new CustomControlSpacer(modal.contentArea));

		// Default values.
		modal.getControlById("showGreeter").setValue(USER.showGreeter);
		modal.getControlById("showChangelog").setValue(USER.showChangelog);
		modal.getControlById("showRecentProjects").setValue(USER.showRecentProjects);
		modal.getControlById("recentProjectsCount").setValue(USER.recentProjectsCount);
		modal.getControlById("language").setValue(USER.language.type);

		modal.getControlById("accordionLayout").setValue(USER.accordionLayout.type);
		modal.getControlById("drawMode").setValue(USER.drawMode.type);
		modal.getControlById("colorScheme").setValue(USER.colorScheme.type);
		modal.getControlById("createCabinet").setValue(USER.createCabinet);
		modal.getControlById("preferDecentralMotors").setValue(USER.preferDecentralMotors);
		modal.getControlById("preferDecentralConsumers").setValue(USER.preferDecentralConsumers);

		modal.setLocalDataBinding(modal.getControlById("showRecentProjects").getEvent(), () => {
			modal.getControlById("recentProjectsCount").disable(!modal.getControlById("showRecentProjects").value);
		});
	}

	// Callbacks
	/**
	 * Show greeter in splashScreen.
	 * @param {boolean} _showGreeter to set.
	 */
	function showGreeterCallback(_showGreeter) {
		USER.showGreeter = _showGreeter;
	}

	/**
	 * Show changelog in splashScreen.
	 * @param {boolean} _showChangelog to set.
	 */
	function showChangelogCallback(_showChangelog) {
		USER.showChangelog = _showChangelog;
	}

	/**
	 * Show recent projects in splashScreen.
	 * @param {boolean} _showRecentProjects to set.
	 */
	function showRecentProjectsCallback(_showRecentProjects) {
		USER.showRecentProjects = _showRecentProjects;
	}

	/**
	 * Sets the number of recent projects to show in splashScreen.
	 * @param {number} _recentProjectsCount to set.
	 */
	function recentProjectsCountCallback(_recentProjectsCount) {
		USER.recentProjectsCount = _recentProjectsCount;
	}

	/**
	 * Sets active language.
	 * @param {languageEnum} _language to set.
	 */
	function languageCallback(_language) {
		switchLanguage(languageEnum[_language]); // TODO make this a global event
	}

	/**
	 * Sets active accordion layout.
	 * @param {accordionLayoutEnum.type} _accordionLayoutType to set.
	 */
	function accordionLayoutCallback(_accordionLayoutType) {
		USER.accordionLayout = accordionLayoutEnum[_accordionLayoutType];
		setAccordionLayout();
	}

	/**
	 * Sets active device drawing mode.
	 * @param {drawModeEnum.type} _drawModeType to set.
	 */
	function drawModeCallback(_drawModeType) {
		USER.drawMode = drawModeEnum[_drawModeType];
		updateToggleDrawModeButton(drawModeEnum[_drawModeType]);
	}

	/**
	 * Sets active color scheme.
	 * @param {colorSchemeEnum.type} _colorSchemeType to set.
	 */
	function colorSchemeCallback(_colorSchemeType) {
		USER.colorScheme = colorSchemeEnum[_colorSchemeType];
		document.documentElement.className = _colorSchemeType.toLowerCase();
	}

	/**
	 * Sets createCabinet attribute in User.
	 * @param {boolean} _createCabinet checkbox value for createCabinet.
	 */
	function createCabinetCallback(_createCabinet) {
		USER.createCabinet = _createCabinet;
	}

	/**
	 * Sets preferDecentralMotors attribute in User.
	 * @param {boolean} _preferDecentralMotor checkbox value for preferDecentralMotors.
	 */
	function preferDecentralMotorCallback(_preferDecentralMotor) {
		USER.preferDecentralMotors = _preferDecentralMotor;
	}

	/**
	 * Sets preferDecentralConsumers attribute in User.
	 * @param {boolean} _preferDecentralConsumers checkbox value for preferDecentralConsumers.
	 */
	function preferDecentralConsumersCallback(_preferDecentralConsumers) {
		USER.preferDecentralConsumers = _preferDecentralConsumers;
	}

	/**
	 * Sets fieldBusPreferences attribute in User.
	 * @param {Array} _fieldBusPreferences sorted array with fieldBuses.
	 */
	function fieldBusPreferencesCallback(_fieldBusPreferences) {
		USER.fieldBusPreferences = _fieldBusPreferences;
	}

	/**
	 * Sets systemBusPreferences attribute in User.
	 * @param {Array} _systemBusPreferences sorted array with systemBuses.
	 */
	function systemBusPreferencesCallback(_systemBusPreferences) {
		USER.systemBusPreferences = _systemBusPreferences;
	}

	/**
	 * Callback for ok button.
	 */
	function okButtonCallBack() {}
}
