//REFACTOR this is not really part of jsp. Move it closer to dataManager!

//JIRA [LQSK-1399] Erweiterte Prüfung der Portkompatiblität
//TODO electrics does nothing atm
//TODO mechanics does nothing atm
//TODO cables does nothing atm

//BUG ts import Problem: // https://stackoverflow.com/questions/40616272/an-import-path-cannot-end-with-ts-nodejs-and-visual-code/49970851

import {MESSENGER} from							"../applicationManager";
import {isSubset} from							"../helper";

import type {Port} from							"../ports/port";


/**
 * Argument for PortCompatibilityChecker.autoCheck() method.
 * @property {boolean} debugMode - turn chatty log on/off
 * @property {boolean} silent - suppress error messages / output to taskbar
 * @property {boolean} excludeSameParentNode - prevent port connections to sibling ports on same parentNode
 * @property {boolean} excludeConnected - prevent connections to ports already connected (to anything)
 */
export interface PortAutoCheckConditions {
	debugMode: boolean;
	silent: boolean;
	excludeSameParentNode: boolean;
	excludeConnected: boolean;
}


/**
 * Compare two ports for certain compatibility criteria.
 */
export class PortCompatibilityChecker {
	public readonly conditions: PortAutoCheckConditions = {
		debugMode: false,
		silent: true,
		excludeSameParentNode: true,
		excludeConnected: false,		// only set true in jsp.beforeStartConnect/beforeStartDetach
	};

	/**
	 * Creates a new instance of PortCompatibilityChecker.
	 * @param {Partial<PortAutoCheckConditions>} [_options] override default check conditions
	 */
	constructor(_options?: Partial<PortAutoCheckConditions>) {
		this.conditions = {...this.conditions, ..._options};

		if (this.conditions.debugMode) {
			/* eslint-disable no-console */
			console.group("PortCompatibilityChecker");
			console.log("conditions:", this.conditions);
			/* eslint-enable no-console */
		}
		Object.seal(this);
	}

	/**
	 * Checks if _port1 and _port2 have the same application.
	 * Used to prevent connecting an electrical port to an pneumatic port.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkSameApplication(_port1: Port, _port2: Port): boolean {
		const result = (_port1.application === _port2.application);

		if (!this.conditions.silent && !result) {
			const port1ApplicationTranslationKey = `application.${_port1.application.toLowerCase()}`;
			const port2ApplicationTranslationKey = `application.${_port2.application.toLowerCase()}`;
			MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.application", {port1: _port1, port1Application: port1ApplicationTranslationKey, port2: _port2, port2Application: port2ApplicationTranslationKey});
		}

		return result;
	}

	/**
	 * Checks if _port1 is different from _port2.
	 * Used to prevent connecting a port to itself.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkDifferentPort(_port1: Port, _port2: Port): boolean {
		const result = (_port1 !== _port2);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.samePort", {port: _port1});

		return result;
	}

	/**
	 * Checks if _port1 is a direct sibling of _port2 on the same dataNode.
	 * Used to prevent connecting a port to a sibling port on the same DataNode.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkDifferentParentNode(_port1: Port, _port2: Port): boolean {
		const result = (_port1.parent !== _port2.parent);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.sameParentNode", {port1: _port1, port2: _port2});

		return result;
	}

	/**
	 * Checks if _port1 is a of different side (source/target) than _port2.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkDifferentSide(_port1: Port, _port2: Port): boolean {
		const result = (_port1.side !== _port2.side);

		if (!this.conditions.silent && !result) {
			const port1SideTranslationKey = `ports.${_port1.side.toLowerCase()}`;
			const port2SideTranslationKey = `ports.${_port2.side.toLowerCase()}`;
			MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.sideMismatch", {port1: _port1, port1Side: port1SideTranslationKey, port2: _port2, port2Side: port2SideTranslationKey});
		}

		return result;
	}

	/**
	 * Checks if _port is already connected to anything.
	 * @param {Port} _port port to check
	 * @returns {boolean} result
	 */
	private checkNotConnected(_port: Port): boolean {
		const result = (_port.isConnectedTo === null);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.alreadyConnected", {port: _port});

		return result;
	}

	/**
	 * Checks if _port1.interfaces are compatible to _port2.interfaces.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkCompatibleInterfaces(_port1: Port, _port2: Port): boolean {
		const sourcePort = [_port1, _port2].find((port) => port.side === "SOURCE");
		const targetPort = [_port1, _port2].find((port) => port.side === "TARGET");

		const sourcePortFlatInterfaces = sourcePort.flattenInterfaces();
		const targetPortFlatInterfaces = targetPort.flattenInterfaces();

		const result = isSubset(sourcePortFlatInterfaces, targetPortFlatInterfaces);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.interfaceMismatch", {port1: _port1, port2: _port2});

		return result;
	}

	/**
	 * Checks if _port1 electrical properties are compatible to _port2 electrical properties.
	 * @todo Does nothing atm. Future place to check stuff like voltage, current  etc.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkCompatibleElectrics(_port1: Port, _port2: Port): boolean {
		//! You should probably determine source/target beforehand (like in interfaces check)
		const isElectricalMatch = true;		//TODO placeholder for expression to check for electrical compatibility of interfaces (voltage, current, ...)

		const result = (isElectricalMatch);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.electricalMismatch", {port1: _port1, port2: _port2});

		// eslint-disable-next-line no-console
		if (this.conditions.debugMode) console.log("NO ELECTRICAL CHECKS YET");
		return result;
	}

	/**
	 * Checks if _port1 mechanical properties are compatible to _port2 mechanical properties.
	 * @todo Does nothing atm. Future place to check stuff like wire diameter, wireEndFerrule, terminals etc.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	private checkCompatibleMechanics(_port1: Port, _port2: Port): boolean {
		const isMechanicalMatch = true;		//TODO placeholder for expression to check for mechanical compatibility of interfaces (pinSize, ferrules, wire diameter...)

		const result = (isMechanicalMatch);

		if (!this.conditions.silent && !result) MESSENGER.post2statusbar("ERROR", "portCompatibilityCheck.mechanicalMismatch", {port1: _port1, port2: _port2});

		// eslint-disable-next-line no-console
		if (this.conditions.debugMode) console.log("NO MECHANICAL CHECKS YET");

		return result;
	}

	/**
	 * Runs all checks and returns early on the first error.
	 * @param {Port} _port1 first port to check
	 * @param {Port} _port2 second port to check
	 * @returns {boolean} result
	 */
	public autoCheck(_port1: Port, _port2: Port): boolean {
		let result																										 = this.checkSameApplication(_port1, _port2);
		if (result)																							result = this.checkDifferentPort(_port1, _port2);
		if (result && this.conditions.excludeSameParentNode)		result = this.checkDifferentParentNode(_port1, _port2);
		if (result && this.conditions.excludeConnected)					result = this.checkNotConnected(_port2);												//! checks just the dropPort
		if (result)																							result = this.checkDifferentSide(_port1, _port2);
		if (result)																							result = this.checkCompatibleInterfaces(_port1, _port2);
		if (result)																							result = this.checkCompatibleElectrics(_port1, _port2);
		if (result)																							result = this.checkCompatibleMechanics(_port1, _port2);

		// eslint-disable-next-line no-console
		if (this.conditions.debugMode) console.groupEnd();

		return result;
	}
}
