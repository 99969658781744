import {NodeGraphicsElement} from "../../graphics"; // convert to webpack import
import tmpInfrastructureNodeIcon from "../../../images/outlinerIcons/infrastructureNode.icon.svg";

const tmpInfrastructureNodeImage = null; // convert to webpack import
const tmpInfrastructureNodeSymbol = null;

export const infrastructureNodeTemplate = {
	databaseId: -200,
	UUID: "infr",
	type: "InfrastructureNode",
	graphics: {
		image: new NodeGraphicsElement(tmpInfrastructureNodeImage, null, null),
		symbol: new NodeGraphicsElement(tmpInfrastructureNodeSymbol, null, null),
		icon: new NodeGraphicsElement(tmpInfrastructureNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "InfrastructureNodeToolTip",
	},

	i18nKey: "outliner.infrastructure",
	description: "",
	ports: [],
	decentralControl: null,
};
