import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: "hsl(0, 0%, 25%)",
			dark: "hsl(0, 0%, 20%)",
			light: "hsl(0, 0%, 33%)",
		},
		action: {
			hoverOpacity: 0.2,
		},
	},
	components: {
		MuiToolbar: {
			styleOverrides: {
				dense: {
					"paddingLeft": "0.3125rem",
					"paddingRight": "0.3125rem",
					"height": "3rem",
					"columnGap": "0.5rem",
					"@media (min-width: 600px)": {
						paddingLeft: "0.3125rem",
						paddingRight: "0.3125rem",
					},
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					height: "100%",
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					height: "1.75rem",
					width: "1.75rem",
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: "0.25rem",
					padding: "0.3125rem",
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					"padding": "0.3125rem",
					"color": "white",
					"border": 0,
					"borderRadius": "0.25rem",
					"&.Mui-disabled": {
						border: 0,
						borderRadius: "0.25rem",
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				vertical: {
					borderColor: "hsl(0, 0%, 50%)",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltipArrow: {
					backgroundColor: "hsl(0, 0%, 20%)",
				},
				arrow: {
					color: "hsl(0, 0%, 20%)",
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					margin: "0.25rem",
				},
			},
		},
	},
});
