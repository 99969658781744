import {initialization, applicationRoot, environmentTypeEnum} from "./applicationManager";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from "./GUI/app/App";
import "../styles/main.css";

/** Show warning  before closing/refreshing tab */
window.onbeforeunload = () => {
	if (applicationRoot.environment !== environmentTypeEnum.DEVELOPMENT) {
		return ""; // you could add a custom message here, but all modern browsers ignore those!
	}
};

/**
 *
 */
window.onload = async () => initialization();

/** react init */
export function initializeReact(): void {
	const rootElement = document.getElementById("topPanel"); // ! "root" will be later here
	if (!rootElement) throw new Error("Failed to find the root element");

	const root = ReactDOM.createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);
}
