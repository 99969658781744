import {createUUID, getDeviceSourceDataByDatabaseId} from "../dataManager";
import {AssemblyNode, DeviceNode, InfrastructureNode, LimboNode, ProjectNode, TrashNode, UnitNode} from "../dataNode";

import type {deviceRoleEnum} from "../constantsAndEnumerations";
import type {NodeGraphicsElement} from "../graphics";
import type {PortData, PortSaveData, PortSourceData} from "../ports/port.schema";

// Might be interesting to construct DataNodeData dynamically https://www.typescriptlang.org/docs/handbook/declaration-merging.html.
// My idea was to declare both DataNodeSaveData and DataNodeSourceData under the same name, and use aliases to discriminate them in the code.
interface DataNodeSaveData {
	UUID: string;
	databaseId: number;
	description: string;
	graphics: {
		icon: NodeGraphicsElement;
		image: NodeGraphicsElement;
		layout: {
			pan: {
				x: number;
				y: number;
			};
			zoom: number;
		};
		position: {
			x: number;
			y: number;
		};
		symbol: NodeGraphicsElement;
		tooltip: string;
	};
	name: string;
	ports: PortSaveData[];
	referenceDesignator: {
		deviceComponent: {
			token: string;
			number: number;
		};
	};
	type: "ProjectNode" | "InfrastructureNode" | "TrashNode" | "LimboNode" | "AssemblyNode" | "UnitNode" | "DeviceNode";
}

interface DataNodeSourceData {
	databaseId: number;
	description: string;
	graphics: {
		icon: NodeGraphicsElement;
		image: NodeGraphicsElement;
		layout: {
			pan: {
				x: number;
				y: number;
			};
			zoom: number;
		};
		position: {
			x: number;
			y: number;
		};
		symbol: NodeGraphicsElement;
		tooltip: string;
	};
	group: string;
	isArchived: boolean;
	level: number;
	manufacturer: string;
	materialNumber: string;
	name: string;
	ports: PortSourceData[];
	referenceDesignator: {
		deviceComponent: {
			token: string;
			number: number;
		};
	};
	role: keyof typeof deviceRoleEnum;
	subGroup: string;
	type: "ProjectNode" | "InfrastructureNode" | "TrashNode" | "LimboNode" | "AssemblyNode" | "UnitNode" | "DeviceNode";
}

interface DataNodeData extends Omit<DataNodeSourceData, "ports">, Omit<DataNodeSaveData, "ports"> {
	ports: (PortData | PortSaveData | PortSourceData)[];
}

//TODO call dataNodeFactory like this: dataNodeFactory(template.databaseId), when called with -200 instead
//TODO use portDataFactory to merge portData
//TODO use interfaceDataFactory tp merge interfaceData
//TODO rename getDeviceSourceDataByDatabaseId to getDataNodeSourceDataByDatabaseId ?
//TODO refactor all dataNode constructors, so that their only parameter is nodeData

/**
 * Creates a new DataNode.
 * @param {number} _databaseId reference to sourceData of DataNode.
 */
export function dataNodeFactoryFRH(_databaseId: number): ProjectNode | InfrastructureNode | TrashNode | LimboNode | AssemblyNode | UnitNode | DeviceNode;

/**
 * Creates a new DataNode.
 * @param {DataNodeSaveData} _saveData saved data of DataNode (via dataNode.save()).
 */
export function dataNodeFactoryFRH(_saveData: DataNodeSaveData): ProjectNode | InfrastructureNode | TrashNode | LimboNode | AssemblyNode | UnitNode | DeviceNode;

/**
 * Creates a new DataNode.
 * @param {number|DataNodeSaveData} _dataNodeReference either a databaseId or saved data of DataNode.
 * @returns {ProjectNode|InfrastructureNode|TrashNode|LimboNode|AssemblyNode|UnitNode|DeviceNode} new DataNode from provided input.
 */
export function dataNodeFactoryFRH(
	_dataNodeReference: number | DataNodeSaveData,
): ProjectNode | InfrastructureNode | TrashNode | LimboNode | AssemblyNode | UnitNode | DeviceNode {
	const databaseId: number = typeof _dataNodeReference === "number" ? _dataNodeReference : _dataNodeReference.databaseId;
	const dataNodeSaveData: DataNodeSaveData = typeof _dataNodeReference !== "number" ? _dataNodeReference : null;

	const dataNodeSourceData: DataNodeSourceData = getDeviceSourceDataByDatabaseId(databaseId);

	if (dataNodeSaveData !== null) {
		// in the case of reconstructing a dataNode from saveData, we set the port information of dataNodeSourceData to null.
		// this prioritizes the mutable port and interface properties over the source data and prevents overriding them with sourceData.
		dataNodeSourceData.ports = null;
		// Since we always prioritize the sourceData graphics element when merging, we have to update it with the saved position beforehand.
		dataNodeSourceData.graphics.position = dataNodeSaveData.graphics.position;
	}

	// Since dataNodeGraphics are already stored as class instances in sourceData, we prioritize sourceData.graphics over the saveData.
	// After a refactoring of graphics, we should get rid of this explicit step.
	const dataNodeData: DataNodeData = {...dataNodeSourceData, ...dataNodeSaveData, graphics: dataNodeSourceData.graphics};

	if (dataNodeData.UUID === undefined) dataNodeData.UUID = createUUID();

	switch (dataNodeData.type) {
		case "ProjectNode":
			return new ProjectNode(dataNodeData.name, dataNodeData);
		case "InfrastructureNode":
			return new InfrastructureNode();
		case "TrashNode":
			return new TrashNode();
		case "LimboNode":
			return new LimboNode();
		case "AssemblyNode":
			return new AssemblyNode(dataNodeData.name, dataNodeData.UUID, dataNodeData);
		case "UnitNode":
			return new UnitNode(dataNodeData.name, dataNodeData.UUID, dataNodeData);
		case "DeviceNode":
			return new DeviceNode(dataNodeData.name, dataNodeData.UUID, dataNodeData);
		default:
			throw new Error(`nodeType "${dataNodeData.type}" unknown!`);
	}
}
