import {MESSENGER} from "./applicationManager";
import {schemaDropdown, schemaGenericName, schemaPositiveFloat, schemaSafeOffVoltage, schemaCheckbox} from "../resources/validationSchemata";
import {
	createAccordionShell,
	finalizeAccordionDialog,
	addAccordionPanelToShellDialog,
	createDialogAccordionPortHeader,
	createDialogAccordionInterfaceHeader,
} from "./accordion";
import {interfaceOperatingModeEnum, interfaceTypeEnum, technicalUnitEnum} from "./constantsAndEnumerations";
import {
	CustomDataNodeHeader,
	CustomHeadLineControl,
	CustomTextControl,
	CustomTextAreaControl,
	CustomDropdownControl,
	CustomCheckboxControl,
	CustomControlSpacer,
} from "./customControl";
import {CustomReferenceDesignatorControl, CustomHeadAccordionPortControl, CustomTextControlNoUnit, CustomHeadAccordionControl} from "./customControlSpecial";
import {convertRESTInterfaceType, parseRestInterfaceTyp, parseRESTPortData} from "./dataConverter";
import {getDataNodeByUUID, getPortSourceDataByDatabaseId} from "./dataManager";
import {getTranslation, formatter} from "./localization/localizationManager";
import {searchArrayForElementByKeyValuePair} from "./helper";
import {interfaceFactory} from "./interfaces/interfaceFactory";
import {ModalDialogUnit, CancelButton, ConfirmButton, DialogButtonAccordion, AccordionModalPanel} from "./modalDialog";
import {Port} from "./ports/port";
import {portFactory} from "./ports/portFactory";
import {PortSide} from "./ports/utils";
// import {schemaDropdownAggr} from											"../resources/validationSchemata";

import cloneDeep from "lodash.clonedeep";
// structuredClone does not work atm because of debounce/timeout function

/* Central structure for creation and manipulation of aggregate (unit) dialog
 *
 * UTILIZING:
 *	joi-browser validation library, details under https://github.com/jeffbski/joi-browser, https://github.com/hapijs/joi
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *		Stanislav Biryukov
 */

/**
 * Opens an unit property dialog
 * @param  {string} _UUID of unitNode that is presented by this dialog
 */
export function unitPropertyDialog(_UUID) {
	const tmpDataNode = cloneDeep(getDataNodeByUUID(_UUID));
	const modal = new ModalDialogUnit("modalDialog.heading-Properties", getDataNodeByUUID(_UUID), tmpDataNode);
	let currentItemDesignationIndex = 0;
	// Buttons
	modal.addButton(new CancelButton(modal));
	modal.addButton(new ConfirmButton(modal, "modalDialog.button.ok"));

	// Controls
	modal.addControl(new CustomDataNodeHeader("propertyHeader", modal.contentArea, tmpDataNode));
	modal.addControl(new CustomHeadLineControl("commonProperties", modal.contentArea, "modalDialog.commonProperties"));
	modal.addControl(new CustomTextControl("unitName", modal.contentArea, "modalDialog.name", technicalUnitEnum.EMPTY, schemaGenericName, unitNameCallBack));
	modal.getControlById("unitName").setValue(tmpDataNode.getName());

	modal.addControl(new CustomTextAreaControl("unitDescription", modal.contentArea, "modalDialog.description", unitDescriptionCallBack));
	modal.getControlById("unitDescription").setValue(tmpDataNode.getDescription());

	const deviceReferenceDesignator = new CustomReferenceDesignatorControl(
		"referenceDesignatorEdit",
		modal.contentArea,
		"modalDialog.reference-designator",
		tmpDataNode,
		referenceDesignatorEditCallBack,
	);
	modal.addControl(deviceReferenceDesignator);
	modal.setLocalDataBinding(deviceReferenceDesignator.getEvent(), deviceReferenceDesignatorChanged);
	modal.getControlById("referenceDesignatorEdit").setValue(tmpDataNode.referenceDesignator.getReferenceDesignator().string());

	//! there is no difference between PLC and Aggregate atm, both are treated as "UNIT", therefore I use databaseId as a temporary workaround
	if (tmpDataNode.__databaseId === 125) {
		modal.addControl(
			new CustomCheckboxControl(
				"decentralControl",
				modal.contentArea,
				"modalDialog.chckbx-decentralControl",
				technicalUnitEnum.EMPTY,
				schemaCheckbox,
				decentralControlCallBack,
			),
		);
		modal.getControlById("decentralControl").setValue(tmpDataNode.decentralControl === null ? USER.preferDecentralConsumers : tmpDataNode.decentralControl);
	}

	modal.addControl(new CustomControlSpacer(modal.contentArea));

	const portReferenceDesignatorLabel = `${tmpDataNode.referenceDesignator.getReferenceDesignator().string()}:`;
	createUnitAccordions();

	// DataBinding
	modal.setControlBinding("unitName", "propertyHeader", "setNameEventHandler");
	modal.setControlBinding("unitDescription", "propertyHeader", "setDescriptionEventHandler");

	// automatic select of name value
	modal.getControlById("unitName").select();

	/**
	  Creates target and source accordions
	 */
	function createUnitAccordions() {
		tmpDataNode.targetPorts = tmpDataNode.ports.filter((port) => port.side === PortSide.TARGET);
		createAccordionShell("modal-dialog-body", "aggregate_ports_target", "modalDialog.table-headerTargets");
		const addTargetPortButton = new DialogButtonAccordion(
			"aggregate_ports_targetHeading",
			"aggregate_ports_target_addButton",
			"modalDialog.button.add",
			false,
			"",
			onClickAddButton,
		);
		modal.addButton(addTargetPortButton);
		addTargetPortButton.addProperties({side: "target", panel: "port"});
		if (tmpDataNode.targetPorts.length > 0) {
			tmpDataNode.targetPorts.forEach((targetPort) => {
				const nextPanelIndex = modal.accordionPanels.target.length;
				addConnector(nextPanelIndex, "target", targetPort, portReferenceDesignatorLabel);
			});
			finalizeAccordionDialog("aggregate_ports_target", "target-ports");
		}
		tmpDataNode.sourcePorts = tmpDataNode.ports.filter((port) => port.side === PortSide.SOURCE);
		createAccordionShell("modal-dialog-body", "aggregate_ports_source", "modalDialog.table-headerSources");
		const addSourcePortButton = new DialogButtonAccordion(
			"aggregate_ports_sourceHeading",
			"aggregate_ports_source_addButton",
			"modalDialog.button.add",
			false,
			"",
			onClickAddButton,
		);
		modal.addButton(addSourcePortButton);
		addSourcePortButton.addProperties({side: "source", panel: "port"});
		if (tmpDataNode.sourcePorts.length > 0) {
			tmpDataNode.sourcePorts.forEach((sourcePort) => {
				const nextPanelIndex = modal.accordionPanels.source.length;
				addConnector(nextPanelIndex, "source", sourcePort, portReferenceDesignatorLabel);
			});
			finalizeAccordionDialog("aggregate_ports_source", "source-ports");
		}
		modal.addAccordions();
		if (tmpDataNode.targetPorts.length > 0) {
			modal.targetPortsAccordion.accordion("option", "active", 0);
			modal.accordionPanels.target[0].interfacesAccordion.accordion("option", "active", 0);
		}
	}

	// Events
	/**
	 * on change reference designator
	 * @param  {string} _value to set
	 */
	function deviceReferenceDesignatorChanged(_value) {
		tmpDataNode.referenceDesignator.overrideAutomaticReferenceDesignator(_value);
		modal.accordionPanels.target.concat(modal.accordionPanels.source).forEach((accPanel) => {
			accPanel.controls.forEach((control) => {
				if (control.getType() === "CustomTextControlNoUnit") {
					control.setUnit(`${_value}:`);
				}
			});
		});
	}

	// Callbacks
	/**
	 * Wrapper for unitName Control callback
	 * @param  {string} _name to set
	 */
	function unitNameCallBack(_name) {
		getDataNodeByUUID(_UUID).setName(_name);
	}

	/**
	 * Wrapper for unitDescription Control callback
	 * @param  {string} _description to set
	 */
	function unitDescriptionCallBack(_description) {
		getDataNodeByUUID(_UUID).setDescription(_description);
	}

	/**
	 * Wrapper for referenceDesignatorEdit Control callback
	 * @param  {string} _referenceDesignatorString to set
	 */
	function referenceDesignatorEditCallBack(_referenceDesignatorString) {
		getDataNodeByUUID(_UUID).referenceDesignator.overrideAutomaticReferenceDesignator(_referenceDesignatorString);
	}

	/**
	 * Wrapper for decentralControl callback.
	 * @param {boolean} _decentralControl to set
	 */
	function decentralControlCallBack(_decentralControl) {
		getDataNodeByUUID(_UUID).decentralControl = _decentralControl;
	}

	/**
	 * Adds connector/port
	 * @param {number} _index of target/source connector
	 * @param {string} _side - source or target
	 * @param {Port} _port - port object
	 * @param {string} _portReferenceDesignatorLabel - port reference designator
	 */
	function addConnector(_index, _side, _port, _portReferenceDesignatorLabel) {
		const idPrefix = `aggregate_ports_${_side}_${_index}_`;
		const headerDialogAccordionContent = createDialogAccordionPortHeader(_side, _index);
		addAccordionPanelToShellDialog(`aggregate_ports_${_side}`, `${idPrefix}content`, `${idPrefix}header`, headerDialogAccordionContent[0].innerHTML, `${_side}-ports`);

		const accPortPanel = new AccordionModalPanel(`aggregate_ports_${_side}`, `${idPrefix}content`, `${idPrefix}header`, _side, _index, null);
		accPortPanel.id = _index;
		modal.accordionPanels[_side].push(accPortPanel);
		const nextRDIndex = calculateNextReferenceDesignatorIndex(modal.accordionPanels.target.concat(modal.accordionPanels.source));
		const portReferenceDesignatorString = `X${nextRDIndex.toString()}`;

		const portReferenceDesignatorControl = new CustomTextControlNoUnit(
			`${idPrefix}portReferenceDesignator`,
			`${idPrefix}content`,
			getTranslation("modalDialog.reference-designator"),
			_portReferenceDesignatorLabel,
			schemaGenericName,
			portReferenceDesignatorControlCallBack,
			76,
		);
		accPortPanel.addControl(portReferenceDesignatorControl);
		const portReferenceDesignatorHeaderControl = new CustomHeadAccordionControl(`${idPrefix}portReferenceDesignatorHeader`, `${idPrefix}header_referenceDesignator`, "");
		accPortPanel.addControl(portReferenceDesignatorHeaderControl);
		portReferenceDesignatorControl.setValue(portReferenceDesignatorString);
		portReferenceDesignatorHeaderControl.setValue(portReferenceDesignatorString); // setControlBinding does not work at this point
		const portDescriptionString = _port.description ? _port.description : "";
		const portDescriptionControl = new CustomTextAreaControl(
			`${idPrefix}portDescription`,
			`${idPrefix}content`,
			getTranslation("modalDialog.description"),
			portDescriptionCallBack,
		);
		accPortPanel.addControl(portDescriptionControl);
		const portDescriptionHeaderControl = new CustomHeadAccordionControl(`${idPrefix}portDescriptionHeader`, `${idPrefix}header_description`, "");
		accPortPanel.addControl(portDescriptionHeaderControl);
		portDescriptionControl.setValue(portDescriptionString);

		accPortPanel.accordionPanels = [];
		createAccordionShell(`${idPrefix}content`, `aggregate_interfaces_${_side}_port_${_index}`, getTranslation("modalDialog.dialogAggregate.table-headerInterfaces"));
		accPortPanel.addAccordion();
		const addInterfaceButton = new DialogButtonAccordion(
			`aggregate_interfaces_${_side}_port_${_index}Heading`,
			`aggregate_interfaces_${_side}_port_${_index}addButton`,
			"modalDialog.button.add",
			false,
			"",
			onClickAddButton,
		);
		accPortPanel.addButton(addInterfaceButton);
		addInterfaceButton.addProperties({uuid: _port.UUID, index: _index, side: _side, panel: "interface"});
		if (_port.interfaces.length > 0) {
			_port.interfaces.forEach((portInterface) => {
				const nextPanelIndex = accPortPanel.accordionPanels.length;
				addInterface(nextPanelIndex, _index, _side, portInterface);
			});
		} else {
			// add one interface anyway
			const myNewInterface = interfaceFactory(10);
			_port.addInterface(myNewInterface);
			myNewInterface.operatingMode = interfaceOperatingModeEnum.CONTINUOUS;
			myNewInterface.isMotor = false;
			myNewInterface.isProtected = false;
			addInterface(0, _index, _side, myNewInterface);
		}
		finalizeAccordionDialog(`aggregate_interfaces_${_side}_port_${_index}`, `${_side}-port-${_index}`);
		const availablePortsForDropdown = modal.makeAvailablePortsForDropdown(_port);
		let availablePorts = modal.makeAvailablePorts(_port);
		const connectorListControl = new CustomDropdownControl(
			`${idPrefix}connector`,
			`${idPrefix}content`,
			getTranslation("modalDialog.param-port"),
			technicalUnitEnum.EMPTY,
			availablePortsForDropdown,
			portConnectorCallBack,
		);
		accPortPanel.addControl(connectorListControl);
		connectorListControl.schema = schemaDropdown;
		accPortPanel.setLocalDataBinding(connectorListControl.getEvent(), portConnectorEvent); // event!==callback
		const portConnectorHeaderControl = new CustomHeadAccordionControl(`${idPrefix}portConnectorHeader`, `${idPrefix}header_connector`, _port.name);
		accPortPanel.addControl(portConnectorHeaderControl);
		connectorListControl.setValue(_port.databaseId);

		accPortPanel.setControlBinding(`${idPrefix}portReferenceDesignator`, `${idPrefix}portReferenceDesignatorHeader`, "setEventHandler");
		accPortPanel.setControlBinding(`${idPrefix}portDescription`, `${idPrefix}portDescriptionHeader`, "setEventHandler");

		const deletePortButton = new DialogButtonAccordion(accPortPanel.header, `${idPrefix}deleteButton`, "modalDialog.button.delete", false, "", onClickDeleteButton);
		accPortPanel.addButton(deletePortButton);
		deletePortButton.addProperties({uuid: _port.UUID, index: _index, side: _side, panel: "port"});
		modal.updateValidity();
		// open first interface accordion panel
		accPortPanel.interfacesAccordion.accordion("option", "active", 0);

		/**
		 * Gets next index based on accordion header values
		 * @param {Array} _panels - port panels (AccordionModalPanel)
		 * @returns  {number} _index to consolidate with "X"
		 */
		function calculateNextReferenceDesignatorIndex(_panels) {
			//! do not read custom control value in automatic case as it will be set later in promise
			if (_panels[currentItemDesignationIndex].controls[0] === undefined) {
				//! a local variable is used instead.
				currentItemDesignationIndex++;
				return currentItemDesignationIndex;
			} else {
				let currentIndex = 0;
				_panels.forEach((accPanel) => {
					accPanel.controls.forEach((control) => {
						if (control.getType() === "CustomTextControlNoUnit") {
							const currentNumber = parseInt(control.getValue().substr(1));
							if (control.getValue().indexOf("X") === 0 && Number.isInteger(currentNumber) && currentNumber > currentIndex) currentIndex = currentNumber;
						}
					});
				});
				return currentIndex + 1;
			}
		}

		// Callbacks
		/**
		 * Wrapper for portReferenceDesignator Control callback
		 * @param  {string} _referenceDesignatorString to set
		 */
		function portReferenceDesignatorControlCallBack(_referenceDesignatorString) {
			_port.referenceDesignator = _referenceDesignatorString;
		}

		/**
		 * Wrapper for portDescription Control callback
		 * @param  {string} _description to set
		 */
		function portDescriptionCallBack(_description) {
			_port.description = _description;
		}

		/**
		 * Wrapper for portConnector Control callback
		 * @param  {string} _connectorId to set
		 */
		function portConnectorCallBack(_connectorId) {
			if (!_connectorId) return;
			if (parseInt(_connectorId) === getPortByUUID(_port.UUID).databaseId) return;

			availablePorts = modal.makeAvailablePorts(_port);
			const tmpPortData = availablePorts.find((port) => port.id === parseInt(_connectorId));

			if (tmpPortData) {
				tmpPortData.picture = {xpos: _port.graphics.image.position.x, ypos: _port.graphics.image.position.y, direction: _port.graphics.image.orientation};
				tmpPortData.symbol = {xpos: _port.graphics.symbol.position.x, ypos: _port.graphics.symbol.position.y, direction: _port.graphics.symbol.orientation};
				tmpPortData.port = _port.dbNumber;
				const portData = parseRESTPortData(tmpPortData, modal.tmpDataNode.group, modal.tmpDataNode.dataBaseId);

				modal.tmpDataNode.ports = modal.tmpDataNode.ports.filter((port) => port.UUID !== _port.UUID);
				const tmpNewPort = portFactory(portData);
				modal.tmpDataNode.ports.push(tmpNewPort);
				const tmpPort = modal.tmpDataNode.ports[modal.tmpDataNode.ports.length - 1];
				tmpPort.interfaces = _port.interfaces;

				// consider separate source and target ports
				if (_port.side === PortSide.TARGET) {
					modal.tmpDataNode.targetPorts = modal.tmpDataNode.targetPorts.filter((targetPort) => targetPort.UUID !== _port.UUID);
					modal.tmpDataNode.targetPorts.push(tmpNewPort);
				} else {
					modal.tmpDataNode.sourcePorts = modal.tmpDataNode.sourcePorts.filter((sourcePort) => sourcePort.UUID !== _port.UUID);
					modal.tmpDataNode.sourcePorts.push(tmpNewPort);
				}
			} else {
				throw new Error("Could not find the available port!");
			}
		}

		/**
		 * Wrapper for portConnector Control event
		 * @param  {string} _connectorId to set the name in header
		 */
		function portConnectorEvent(_connectorId) {
			_port.databaseId = parseInt(_connectorId);
			availablePorts = modal.makeAvailablePorts(_port);
			const tmpPortData = availablePorts.find((port) => port.id === parseInt(_connectorId));
			// show connector name in header
			if (tmpPortData) {
				portConnectorHeaderControl.setValue(tmpPortData.name);
				portConnectorHeaderControl.setTooltip(tmpPortData.name);
			}
		}
	}

	/**
	 * Adding interface
	 * @param  {number} _index - interface index in port
	 * @param  {number} _portIndex - port index in source/target panel
	 * @param  {string} _side - source or target
	 * @param  {object} _interface to add
	 */
	function addInterface(_index, _portIndex, _side, _interface) {
		const idPrefix = `aggregate_interfaces_${_side}_${_portIndex}_${_index}_`;
		const headerDialogAccordionContent = createDialogAccordionInterfaceHeader(_portIndex, _index, _side);
		const operatingModeList = [];
		for (const mode in interfaceOperatingModeEnum) {
			if (Object.prototype.hasOwnProperty.call(interfaceOperatingModeEnum, mode)) {
				// && interfaceOperatingModeEnum[mode].nonmotor
				operatingModeList.push({caption: interfaceOperatingModeEnum[mode].caption, value: interfaceOperatingModeEnum[mode].type});
			}
		}
		const interfaceTypeList = [];
		for (const type in interfaceTypeEnum) {
			if (Object.prototype.hasOwnProperty.call(interfaceTypeEnum, type)) {
				interfaceTypeList.push({caption: interfaceTypeEnum[type].caption, value: interfaceTypeEnum[type].value});
			}
		}
		addAccordionPanelToShellDialog(
			`aggregate_interfaces_${_side}_port_${_portIndex}`,
			`${idPrefix}content`,
			`${idPrefix}header`,
			headerDialogAccordionContent[0].innerHTML,
			`${_side}-port-${_portIndex}`,
		);
		// get indexes in modal/tmpDataNode structure
		const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
		const accInterfacePanel = new AccordionModalPanel(
			`aggregate_interfaces_${_side}_port_${_portIndex}`,
			`${idPrefix}content`,
			`${idPrefix}header`,
			_side,
			_portIndex,
			_index,
		);
		accInterfacePanel.id = _index;
		modal.accordionPanels[_side][portIndex].accordionPanels.push(accInterfacePanel);
		// type
		const typeControl = new CustomDropdownControl(
			`${idPrefix}type`,
			`${idPrefix}content`,
			getTranslation("modalDialog.table-type"),
			technicalUnitEnum.EMPTY,
			interfaceTypeList,
		);
		accInterfacePanel.addControl(typeControl);
		const typeHeaderControl = new CustomHeadAccordionControl(`${idPrefix}typeHeader`, `${idPrefix}header_type`, "");
		accInterfacePanel.addControl(typeHeaderControl);
		const typeHeaderControlPort = new CustomHeadAccordionPortControl(
			`${idPrefix}typeHeaderPort`,
			`aggregate_ports_${_side}_${_portIndex}_header_parameters`,
			_interface.type.caption,
			_side,
			_portIndex,
			modal.accordionPanels[_side][portIndex].accordionPanels.length - 1,
		);
		accInterfacePanel.addControl(typeHeaderControlPort);
		typeControl.setValue(_interface.databaseId);
		accInterfacePanel.setLocalDataBinding(typeControl.getEvent(), interfaceTypeEvent);
		// voltage
		const voltageControl = new CustomTextControl(
			`${idPrefix}volt`,
			`${idPrefix}content`,
			"modalDialog.param-voltage",
			technicalUnitEnum.VOLTAGE,
			schemaPositiveFloat,
			interfaceVoltageCallBack,
		);
		accInterfacePanel.addControl(voltageControl);
		const voltageHeaderControl = new CustomHeadAccordionControl(`${idPrefix}voltageHeader`, `${idPrefix}header_voltage`, "");
		accInterfacePanel.addControl(voltageHeaderControl);
		voltageControl.setValue(typeof _interface.voltage !== "undefined" ? formatter.number2string(_interface.voltage) : "");
		accInterfacePanel.setLocalDataBinding(voltageControl.getEvent(), interfaceVoltageEvent);
		// current
		const currentControl = new CustomTextControl(
			`${idPrefix}current`,
			`${idPrefix}content`,
			getTranslation("modalDialog.param-current"),
			technicalUnitEnum.CURRENT,
			schemaPositiveFloat,
			interfaceCurrentCallBack,
		);
		accInterfacePanel.addControl(currentControl);
		const currentHeaderControl = new CustomHeadAccordionControl(`${idPrefix}currentHeader`, `${idPrefix}header_current`, "");
		accInterfacePanel.addControl(currentHeaderControl);
		currentControl.setValue(typeof _interface.current !== "undefined" ? formatter.number2string(_interface.current) : "");
		accInterfacePanel.setLocalDataBinding(currentControl.getEvent(), interfaceCurrentEvent);
		// operating mode
		const operatingModeControl = new CustomDropdownControl(
			`${idPrefix}operatingMode`,
			`${idPrefix}content`,
			getTranslation("modalDialog.param-operatingMode"),
			technicalUnitEnum.EMPTY,
			cloneDeep(operatingModeList),
			interfaceOperatingModeCallBack,
		);
		accInterfacePanel.addControl(operatingModeControl);
		const operatingModeHeaderControl = new CustomHeadAccordionControl(`${idPrefix}operatingModeHeader`, `${idPrefix}header_operatingMode`, "");
		accInterfacePanel.addControl(operatingModeHeaderControl);
		operatingModeControl.setValue(_interface.operatingMode.type);
		accInterfacePanel.setLocalDataBinding(operatingModeControl.getEvent(), interfaceOperatingModeEvent);
		// safe
		const safeControl = new CustomCheckboxControl(
			`${idPrefix}safe`,
			`${idPrefix}content`,
			getTranslation("modalDialog.param-safeVoltageOff"),
			technicalUnitEnum.EMPTY,
			schemaSafeOffVoltage(_interface),
			interfaceSafeCallBack,
		);
		accInterfacePanel.addControl(safeControl);
		const safeHeaderControl = new CustomHeadAccordionControl(`${idPrefix}safeHeader`, `${idPrefix}header_safe`, "");
		accInterfacePanel.addControl(safeHeaderControl);
		safeControl.setValue(_interface.isSafe);
		accInterfacePanel.setLocalDataBinding(safeControl.getEvent(), interfaceSafeEvent);
		// description
		const descriptionString = _interface.description ? _interface.description : "";
		const descriptionControl = new CustomTextAreaControl(
			`${idPrefix}description`,
			`${idPrefix}content`,
			getTranslation("modalDialog.description"),
			interfaceDescriptionCallBack,
		);
		accInterfacePanel.addControl(descriptionControl);
		const descriptionHeaderControl = new CustomHeadAccordionControl(`${idPrefix}descriptionHeader`, `${idPrefix}header_description`, "");
		accInterfacePanel.addControl(descriptionHeaderControl);
		descriptionControl.setValue(descriptionString);

		accInterfacePanel.setControlBinding(`${idPrefix}description`, `${idPrefix}descriptionHeader`, "setEventHandler");

		const deleteInterfaceButton = new DialogButtonAccordion(
			accInterfacePanel.header,
			`${idPrefix}deleteButton`,
			"modalDialog.button.delete",
			false,
			"",
			onClickDeleteButton,
		);
		accInterfacePanel.addButton(deleteInterfaceButton);
		deleteInterfaceButton.addProperties({uuid: _interface.UUID, index: _index, portIndex: _portIndex, side: _side, panel: "interface"});
		modal.updateValidity();

		formatPortHeader(_side, _portIndex);

		// Events
		/**
		 * Wrapper for interfaceType Control callback
		 * @param  {number} _databaseId id to set
		 */
		function interfaceTypeEvent(_databaseId) {
			_interface.databaseId = parseInt(_databaseId);
			_interface.type = convertRESTInterfaceType(parseInt(_databaseId));
			_interface.name = getTranslation(convertRESTInterfaceType(parseInt(_databaseId)).caption);
			_interface.groupX = parseRestInterfaceTyp(_interface.type.group);
			const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
			if (portIndex !== -1) {
				if (!getDataNodeByUUID(tmpDataNode.UUID).ports.some((port) => port.interfaces.some((tmpInterface) => tmpInterface.UUID === _interface.UUID))) {
					// set standard values for new Interface
					const fakeInterface = interfaceFactory(_interface.databaseId);
					voltageControl.setValue(formatter.number2string(fakeInterface.voltage));
					currentControl.setValue(formatter.number2string(fakeInterface.current));
					safeControl.setValue(fakeInterface.isSafe);
					descriptionControl.setValue(fakeInterface.description);
				}
				modal.changePortList(tmpDataNode[`${_side}Ports`][portIndex], _portIndex, _side);
				typeHeaderControl.setValue(_interface.name);
				const index = modal.accordionPanels[_side][portIndex].accordionPanels.indexOf(
					searchArrayForElementByKeyValuePair(modal.accordionPanels[_side][portIndex].accordionPanels, "id", _index),
				);
				modal.accordionPanels[_side][portIndex].accordionPanels[index]
					.getControlById(`aggregate_interfaces_${_side}_${_portIndex}_${_index}_typeHeaderPort`)
					.setValue(_interface.name);
				typeHeaderControlPort.setTooltip(_interface.name);
			}
		}

		/**
		 * Wrapper for interfaceVoltage event
		 * @param  {number} _voltage to set
		 */
		function interfaceVoltageEvent(_voltage) {
			if (_voltage !== null) {
				const voltageFormatted = formatter.number2string(_voltage);
				_interface.voltage = parseInt(_voltage);
				const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
				if (portIndex !== -1) {
					modal.changePortList(tmpDataNode[`${_side}Ports`][portIndex], _portIndex, _side);
					voltageHeaderControl.setValue("\xA0/\xA0" + voltageFormatted + "V");
				}
			}
		}

		/**
		 * Wrapper for interfaceCurrent event
		 * @param  {number} _current to set
		 */
		function interfaceCurrentEvent(_current) {
			if (_current !== null) {
				const currentFormatted = formatter.number2string(_current);
				_interface.current = parseFloat(_current);
				const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
				if (portIndex !== -1) {
					modal.changePortList(tmpDataNode[`${_side}Ports`][portIndex], _portIndex, _side);
					currentHeaderControl.setValue("\xA0/\xA0" + currentFormatted + "A");
				}
			}
		}

		/**
		 * Wrapper for interfaceOperatingMode event
		 * @param  {string} _operatingMode to set
		 */
		function interfaceOperatingModeEvent(_operatingMode) {
			if (_operatingMode) {
				interfaceOperatingModeCallBack(_operatingMode);
				const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
				if (portIndex !== -1) {
					operatingModeHeaderControl.setValue("\xA0/\xA0" + getTranslation(_interface.operatingMode.caption));
					safeControl.schema = schemaSafeOffVoltage(_interface);
					safeControl.hardValidate(_interface.isSafe);
				}
			}
		}

		/**
		 * Wrapper for interfaceSafe event
		 * @param  {boolean} _safe to set
		 */
		function interfaceSafeEvent(_safe) {
			_interface.isSafe = _safe;
			safeHeaderControl.setValue(_safe ? "\xA0/\xA0" + getTranslation("modalDialog.param-safeVoltageOff") : "");
			safeControl.schema = schemaSafeOffVoltage(_interface);
			safeControl.hardValidate(_safe);
		}

		// Callbacks
		/**
		 * Wrapper for interfaceVoltage Control callback
		 * @param  {number} _voltage to set
		 */
		function interfaceVoltageCallBack(_voltage) {
			_interface.voltage = _voltage;
			if (tmpDataNode[`${_side}Ports`][portIndex])
				tmpDataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", tmpDataNode[`${_side}Ports`][portIndex].UUID, _interface.UUID, "voltage", _voltage);
		}

		/**
		 * Wrapper for interfaceCurrent Control callback
		 * @param  {number} _current to set
		 */
		function interfaceCurrentCallBack(_current) {
			_interface.current = _current;
			if (tmpDataNode[`${_side}Ports`][portIndex])
				tmpDataNode.parentEventManager.dispatch("eDTM_InterfaceParameterChanged", tmpDataNode[`${_side}Ports`][portIndex].UUID, _interface.UUID, "current", _current);
		}

		/**
		 * Wrapper for interfaceOperatingMode Control callback
		 * @param  {string} _operatingMode to set
		 */
		function interfaceOperatingModeCallBack(_operatingMode) {
			_interface.operatingMode = interfaceOperatingModeEnum[_operatingMode];
		}

		/**
		 * Wrapper for interfaceSafe Control callback
		 * @param  {boolean} _safe checkbox value to set
		 */
		function interfaceSafeCallBack(_safe) {
			_interface.isSafe = _safe;
		}

		/**
		 * Wrapper for interfaceDescription Control callback
		 * @param  {string} _description to set
		 */
		function interfaceDescriptionCallBack(_description) {
			_interface.description = _description;
		}
	}

	/**
	 * Callback on click add button
	 * @param {string} _side - source or target
	 * @param {number} _portIndex - port index
	 */
	function formatPortHeader(_side, _portIndex) {
		const portIndex = modal.accordionPanels[_side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[_side], "id", _portIndex));
		const portPanel = modal.accordionPanels[_side][portIndex];
		const panelLength = portPanel.accordionPanels.length;
		const width = ((24 - (panelLength - 1) * 0.6) / panelLength).toFixed(2);
		portPanel.accordionPanels.forEach((accPanel) => {
			accPanel.controls.forEach((control) => {
				if (control.containerId === `aggregate_ports_${_side}_${_portIndex}_header_parameters`) control.setWidth(panelLength > 3 ? `${width}rem` : "auto");
			});
		});
	}

	/**
	 * Callback on click add button
	 * @param {w.Event} e event
	 */
	function onClickAddButton(e) {
		const side = e.currentTarget.side;
		if (e.currentTarget.panel === "port") {
			// port panel
			const nextPortPanelIndex = getNextPortIndex();
			let newPort;
			if (side === "target") {
				const tmpPortData = getPortSourceDataByDatabaseId(1505);
				tmpPortData.description = "";
				tmpPortData.side = PortSide.TARGET;
				newPort = portFactory(tmpPortData);
			} else {
				const tmpPortData = getPortSourceDataByDatabaseId(1504);
				tmpPortData.description = "";
				tmpPortData.side = PortSide.SOURCE;
				newPort = portFactory(tmpPortData);
			}
			newPort.dbNumber = null;
			newPort.interfaces.forEach((_interface) => newPort.removeInterface(_interface));

			tmpDataNode[`${side}Ports`].push(newPort);
			addConnector(nextPortPanelIndex, side, newPort, modal.getControlById("referenceDesignatorEdit").getValue() + ":");
			finalizeAccordionDialog(`aggregate_ports_${side}`, `${side}-ports`);
			const activeAccordionPortIndex = modal.accordionPanels[side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[side], "id", nextPortPanelIndex));
			side === "target"
				? modal.targetPortsAccordion.accordion("option", "active", activeAccordionPortIndex)
				: modal.sourcePortsAccordion.accordion("option", "active", activeAccordionPortIndex);

			/**
			 * get new port index
			 * @returns {number} - next port index
			 */
			function getNextPortIndex() {
				const accPanels = modal.accordionPanels[side];
				if (accPanels.length === 0) return 0;
				else {
					let currentIndex = 0;
					accPanels.forEach((accPanel) => {
						if (accPanel.id > currentIndex) currentIndex = accPanel.id;
					});
					return currentIndex + 1;
				}
			}
		} else {
			// interface panel
			const portDomIndex = e.currentTarget.index;
			const portPanel = searchArrayForElementByKeyValuePair(modal.accordionPanels[side], "id", portDomIndex);
			const nextInterfacePanelIndex = getNextInterfaceIndex();
			// recalculate port index
			const portIndex = modal.accordionPanels[side].indexOf(portPanel);
			const myNewInterface = interfaceFactory(20);
			tmpDataNode[`${side}Ports`][portIndex].addInterface(myNewInterface);
			myNewInterface.operatingMode = interfaceOperatingModeEnum.CONTINUOUS;
			myNewInterface.isMotor = false;
			myNewInterface.isProtected = false;
			addInterface(nextInterfacePanelIndex, portDomIndex, side, myNewInterface);
			finalizeAccordionDialog(`aggregate_interfaces_${side}_port_${portDomIndex}`, `${side}-port-${portDomIndex}`);
			const activeAccordionInterfaceIndex = modal.accordionPanels[side][portIndex].accordionPanels.indexOf(
				searchArrayForElementByKeyValuePair(modal.accordionPanels[side][portIndex].accordionPanels, "id", nextInterfacePanelIndex),
			);
			portPanel.interfacesAccordion.accordion("option", "active", activeAccordionInterfaceIndex);
			MESSENGER.post2statusbar("NORMAL", "modalDialog.dialogAggregate.msg-interfaceAdded");

			/**
			 * get new interface index
			 * @returns {number} - next interface index
			 */
			function getNextInterfaceIndex() {
				let currentIndex = 0;
				portPanel.accordionPanels.forEach((accPanel) => {
					if (accPanel.id > currentIndex) currentIndex = accPanel.id;
				});
				return currentIndex + 1;
			}
		}
	}

	/**
	 * Callback on click delete button
	 * @param {w.Event} e event
	 */
	function onClickDeleteButton(e) {
		const side = e.currentTarget.side;
		const portDomIndex = e.currentTarget.index;
		const portIndex = modal.accordionPanels[side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[side], "id", portDomIndex));
		if (e.currentTarget.panel === "port") {
			// port panel
			deletePortDOMPanel();
			modal.accordionPanels[side].splice(portIndex, 1);
			tmpDataNode[`${side}Ports`].splice(portIndex, 1);
			if (tmpDataNode[`${side}Ports`].length > 0) {
				finalizeAccordionDialog(`aggregate_ports_${side}`, `${side}-ports`);
			}
			/**
			 * remove ports' events and delete port panel from DOM
			 */
			function deletePortDOMPanel() {
				// remove event handlers for the port panel and child interface panels
				const portPanel = modal.accordionPanels[side][portIndex];
				portPanel.unregisterAllDataBindings();
				if (portPanel.accordionPanels && portPanel.accordionPanels.length > 0) {
					portPanel.accordionPanels.forEach((interfacePanel) => {
						interfacePanel.unregisterAllDataBindings();
					});
				}
				portPanel.interfacesAccordion.accordion("destroy");
				portPanel.remove();
			}
		} else {
			// interface panel
			const portDomIndex = e.currentTarget.portIndex;
			const portIndex = modal.accordionPanels[side].indexOf(searchArrayForElementByKeyValuePair(modal.accordionPanels[side], "id", portDomIndex));
			const interfaceDomIndex = e.currentTarget.index;
			const interfaceIndex = modal.accordionPanels[side][portIndex].accordionPanels.indexOf(
				searchArrayForElementByKeyValuePair(modal.accordionPanels[side][portIndex].accordionPanels, "id", interfaceDomIndex),
			);
			if (tmpDataNode[`${side}Ports`][portIndex].interfaces.length > 1) {
				deleteInterfaceDOMPanel();
				modal.accordionPanels[side][portIndex].accordionPanels.splice(interfaceIndex, 1);
				tmpDataNode[`${side}Ports`][portIndex].interfaces.splice(interfaceIndex, 1);
				finalizeAccordionDialog(`aggregate_interfaces_${side}_port_${portDomIndex}`, `${side}-port-${portIndex}`);
				// update connector list
				modal.changePortList(tmpDataNode[`${side}Ports`][portIndex], portDomIndex, side);
				formatPortHeader(side, portDomIndex);
				MESSENGER.post2statusbar("NORMAL", "modalDialog.dialogAggregate.msg-interfaceDeleted");
				/**
				  remove controls' events and delete interface panel from DOM
				 */
				function deleteInterfaceDOMPanel() {
					const interfacePanel = modal.accordionPanels[side][portIndex].accordionPanels[interfaceIndex];
					interfacePanel.unregisterAllDataBindings();
					interfacePanel.remove();
					const headAccordionPortControl = searchArrayForElementByKeyValuePair(
						interfacePanel.controls,
						"id",
						`aggregate_interfaces_${side}_${portDomIndex}_${interfaceDomIndex}_typeHeaderPort`,
					);
					headAccordionPortControl.remove(interfaceIndex === 0);
					interfacePanel.controls.splice(interfacePanel.controls.indexOf(headAccordionPortControl), 1);
				}
			} else {
				alert(getTranslation("modalDialog.dialogAggregate.msg-lastInterface"));
			}
		}
	}
}
