/* eslint-disable require-jsdoc */

//! This is just a rough sketch...
//! Could be used in the future to log keyboard input for:
//! -----------------------------------------------------------------
//! RADIAL MENUS (via Spacebar), like in
//!		Blender: https://www.youtube.com/watch?v=meVfv-ewhlk
//!		Maya: https://www.youtube.com/watch?v=j15MLgnIKfI

//!		examples:
//!			- this one is nice, although a bit slow - https://www.youtube.com/watch?v=ShPPkZEeLPo
//!			- lots of examples: https://freefrontend.com/css-circle-menus/
//! -----------------------------------------------------------------
//! Forcing tooltips on certain elements like ports|connections|etc (via AltKey), like in 
//!		MS Word: https://support.content.office.net/en-us/media/d9f54f19-aa53-4582-9ac4-a85207d9ad5a.png

//! could be very useful to see electrical info on all elements on screen with the flick of a button


export class Keylogger {
	private static instance: Keylogger;	// singleton helper
	private readonly debugMode: boolean;
	private lastKey: string|null = null;
	private readonly __loggedKeys: Array<string> = ["AltLeft", "Space"];

	constructor(_debugMode = false) {
		// Prevent multiple instances
		if (Keylogger.instance !== undefined) throw new Error("Singleton classes can't be instantiated more than once.");
		Keylogger.instance = this;

		this.debugMode = _debugMode;

		if (this.debugMode) {
			// eslint-disable-next-line no-console
			console.debug("%cDebug Mode: true", "color: green");
			(window as any).keylogger = this;
		}

		document.onkeydown = this.keydown;		// this formulation is more than fishy
		document.onkeyup = this.keyup;				// this formulation is more than fishy
		// document.addEventListener("keydown", () => this.handleKeydown);

		Object.seal(this);
	}

	private keydown(e: KeyboardEvent): void {
		// if (!this.__loggedKeys.includes(e.code)) return;		//! doesn't work, probably function must be hooked differently to document.onkeydown
		if (this.lastKey === e.code) return;									// prevent rapid firing when holding a key down

		this.lastKey = e.code;
		if (e.code === "AltLeft") {
			e.preventDefault();								//! do we need this, what does AltKeyDown by default do in Browsers?
			// eslint-disable-next-line no-console
			console.log("ALT DOWN");
			//! fire a global event that elements can react on, eg force to show a tooltip...
		}
	}

	private keyup(e: KeyboardEvent): void {
		// if (!this.__loggedKeys.includes(e.code)) return;		//! doesn't work, probably function must be hooked differently to document.onkeyup
		if (this.lastKey === e.code) this.lastKey = null;

		if (e.code === "AltLeft") {
			e.preventDefault();								//! do we need this, what does AltKeyUp by default do in Browsers?
			// eslint-disable-next-line no-console
			console.log("ALT UP");
			//! fire a global event that elements can react on, eg force to hide a tooltip...
		}
	}
}
