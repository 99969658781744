import {GLOBALEVENTMANAGER}					from "../../applicationManager";
import {eRelocateDataNode}					from "../../dataManager";
import {eRenameDataNode}						from "../../dataManager";
import {eUpdateActiveNode}					from "../../dataManager";
import {getUniqueDataNodeByType}		from "../../dataManager";
import {getDataNodeByUUID}					from "../../dataManager";
import {checkExistence}							from "../../helper";
import {checkUniqueness}						from "../../helper";
import {cId2jqSel}									from "../../helper";
import {createFancytree}						from "./fancytree";

import $ from "jquery";
import "jquery-ui/ui/widgets/draggable";
import "jquery-contextmenu";
import "simplebar";


/**
 *		jQuery Scrollbar as a replacement for the standard scrollbar, details under https://gromo.github.io/jquery.scrollbar/
 *
 * TODO:
 *		Import fancytree as intended by the authors, see https://github.com/mar10/fancytree/wiki
 */

let treeRoot; 																					// Central, top most tree structure (!= project)
const alwaysExpand = true;															// TODO Workaround to expand ALL nodes. A more sophisticated approach is needed though
let labelNameMode = true;


/** Standard initialization routine (creating outliner, setting event handlers) */
export function initializeOutliner() {
	createOutliner("sidePanelRight", "outliner");
	GLOBALEVENTMANAGER.addHandler("eOL_RelocateTreeNode", eRelocateDataNode);
	GLOBALEVENTMANAGER.addHandler("eOL_RenameTreeNode", eRenameDataNode);
	GLOBALEVENTMANAGER.addHandler("eOL_Click", eUpdateActiveNode);
	GLOBALEVENTMANAGER.addHandler("eGUI_ToggleOutlinerLabels", eToggleLabels);
	GLOBALEVENTMANAGER.addHandler("eGUI_ToggleCollapseOutliner", eToggleCollapse);
	GLOBALEVENTMANAGER.addHandler("eDTM_EditTreeNode", editTreeNodeHandler);
}


/**
 * Adds an EMPTY outliner container (div) to a parent element.
 * @param  {string} _parentId container to nest into
 * @param  {string} _outlinerId id of outliner shell that gets created
 * @throws Exception providing additional information on error
 */
function createOutliner(_parentId, _outlinerId) {
	checkExistence(_parentId);																	// verify _parentId exists

	checkUniqueness(_outlinerId);																// verify uniqueness of _shellId

	$("<div>", {																								// creates a div for the outliner inside the parent container
		id: _outlinerId,
		class: "outliner-container noselect",
		// class: "outliner-container customScrollbarContainer flex-container noselect",
	}).appendTo(cId2jqSel(_parentId));
	document.getElementById(_outlinerId).setAttribute("data-simplebar", "");
	createFancytree(cId2jqSel(_outlinerId));

	treeRoot = cId2jqSel(_outlinerId).fancytree("getTree").rootNode;
	treeRoot.key = "root";																		// overwrite standard rootNode key
	treeRoot.folder = true;
}

/**
 * Creates a treeNode representing a dataNode
 * @param  {dataNode} _correspondingDataNode dataNode that is represented in tree
 * @throws Exception providing additional information on error
 */
export function eCreateTreeNode(_correspondingDataNode) {
	if (!_correspondingDataNode.interactionPreset.outliner) return;		// abort for nodes that are not shown in outliner
	const tmpTreeNode = {
		title: _correspondingDataNode.getName(),
		key: _correspondingDataNode.UUID,
		type: _correspondingDataNode.nodeType,
		tooltip: _correspondingDataNode.graphics.tooltip,
		icon: _correspondingDataNode.graphics.icon.file,
		folder: _correspondingDataNode.interactionPreset.folder,
		draggable: _correspondingDataNode.interactionPreset.draggable,
		renamable: _correspondingDataNode.interactionPreset.renamable,
		deletable: _correspondingDataNode.interactionPreset.deletable,
		clearable: _correspondingDataNode.interactionPreset.clearable,
		shoppable: _correspondingDataNode.interactionPreset.shoppable,
	};
	getTreeNodeByKey(_correspondingDataNode.parentUUID).addNode(tmpTreeNode);					// all treeNodes get created under root (and via dataNode.addChild event moved into the correct parentNode)
}

/**
 * Deletes a treeNode after removing the corresponding a dataNode
 * @param  {dataNode} _correspondingDataNode dataNode that was deleted
 * @throws Exception providing additional information on error
 */
export function eDeleteTreeNode(_correspondingDataNode) {
	checkTreeNodeExistence(_correspondingDataNode.UUID);								// check that treeNode exists

	getTreeNodeByKey(_correspondingDataNode.UUID).remove();
	sortTree();
}

/**
 * Moves a treeNode to a new parentTreeNode
 * @param  {key} _targetParentTreeNodeKey treeNode to move to
 * @param  {key} _treeNodeKey treeNode that is moved
 * @throws Exception providing additional information on error
 */
export function eRelocateTreeNode(_targetParentTreeNodeKey, _treeNodeKey) {
	getTreeNodeByKey(_treeNodeKey).moveTo(getTreeNodeByKey(_targetParentTreeNodeKey), "child");
	if (alwaysExpand) {
		getTreeNodeByKey(_targetParentTreeNodeKey).setExpanded();
	}
	sortTree();
}

/**
 * Wrapper to update the name of a treeNode after it's corresponding dataNode got changed
 * @param  {dataNode} _correspondingDataNode dataNode that was changed
 * @throws Exception providing additional information on error
 */
export function eUpdateTreeNodeName(_correspondingDataNode) {
	checkTreeNodeExistence(_correspondingDataNode.UUID);								// check that treeNode exists

	const tmpProperty = (() => {
		if (labelNameMode) {
			return "title";
		} else {
			return "tooltip";
		}
	});

	switch (_correspondingDataNode.nodeType) {
		case "ProjectNode":
			getTreeNodeByKey(_correspondingDataNode.UUID).title = _correspondingDataNode.getName();
			getTreeNodeByKey(_correspondingDataNode.UUID).tooltip = _correspondingDataNode.graphics.tooltip;
			break;
		case "TrashNode":
			getTreeNodeByKey(_correspondingDataNode.UUID).title = _correspondingDataNode.getName();
			getTreeNodeByKey(_correspondingDataNode.UUID).tooltip = _correspondingDataNode.graphics.tooltip;
			break;
		default:
			getTreeNodeByKey(_correspondingDataNode.UUID)[tmpProperty()] = _correspondingDataNode.getName();
			break;
	}

	getTreeNodeByKey(_correspondingDataNode.UUID).renderTitle();						// update TreeNode display
	sortTree();
}

/**
 * Wrapper to update the referenceDesignator of a treeNode after it's corresponding dataNode got changed
 * @param  {dataNode} _correspondingDataNode dataNode that was changed
 * @throws Exception providing additional information on error
 */
export function eUpdateTreeNodeReferenceDesignator(_correspondingDataNode) {
	checkTreeNodeExistence(_correspondingDataNode.UUID);								// check that treeNode exists

	const tmpProperty = (() => {
		if (labelNameMode) {
			return "tooltip";
		} else {
			return "title";
		}
	});

	switch (_correspondingDataNode.nodeType) {
		case "ProjectNode":
			getTreeNodeByKey(_correspondingDataNode.UUID).title = tmpNode.getName();
			getTreeNodeByKey(_correspondingDataNode.UUID).tooltip = tmpNode.graphics.tooltip;
			break;
		case "TrashNode":
			getTreeNodeByKey(_correspondingDataNode.UUID).title = tmpNode.getName();
			getTreeNodeByKey(_correspondingDataNode.UUID).tooltip = tmpNode.graphics.tooltip;
			break;
		default:
			getTreeNodeByKey(_correspondingDataNode.UUID)[tmpProperty()] = _correspondingDataNode.referenceDesignator.getReferenceDesignator().string();
			break;
	}

	getTreeNodeByKey(_correspondingDataNode.UUID).renderTitle();						// update TreeNode display
	sortTree();
}

/**
 * Wrapper for eDTM_DataNodeTooltipChanged event raised from dataManager
 * @param  {dataNode} _correspondingDataNode dataNode that was changed
 * @throws Exception providing additional information on error
 */
export function eUpdateTreeNodeTooltip(_correspondingDataNode) {
	checkTreeNodeExistence(_correspondingDataNode.UUID);								// check that treeNode exists
	getTreeNodeByKey(_correspondingDataNode.UUID).tooltip = _correspondingDataNode.getTooltip();
	getTreeNodeByKey(_correspondingDataNode.UUID).renderTitle();						// update TreeNode display
}

/**
 * Wrapper for eDTM_ActivateDataNodeChanged event, activates the corresponding treeNode
 * @param  {dataNode} _correspondingDataNode dataNode that was deleted
 * @throws Exception providing additional information on error
 */
export function eSetActiveTreeNode(_correspondingDataNode) {
	checkTreeNodeExistence(_correspondingDataNode.UUID);
	treeRoot.tree.activateKey(_correspondingDataNode.UUID);
	getTreeNodeByKey(_correspondingDataNode.UUID).setExpanded();
}

/** Switches outliner labels between name and referenceDesignator */
function eToggleLabels() {
	labelNameMode = !labelNameMode;

	const tmpProperty1 = (() => {
		if (labelNameMode) {
			return "tooltip";
		} else {
			return "title";
		}
	});

	const tmpProperty2 = (() => {
		if (labelNameMode) {
			return "title";
		} else {
			return "tooltip";
		}
	});

	treeRoot.visit((node) => {
		const tmpNode = getDataNodeByUUID(node.key);
		switch (tmpNode.nodeType) {
			case "ProjectNode":
				node.title = tmpNode.getName();
				node.tooltip = tmpNode.graphics.tooltip;
				break;
			case "TrashNode":
				node.title = tmpNode.getName();
				node.tooltip = tmpNode.graphics.tooltip;
				break;
			default:
				node[tmpProperty1()] = tmpNode.referenceDesignator.getReferenceDesignator().string();
				node[tmpProperty2()] = tmpNode.getName();
				break;
		}
		node.renderTitle();
	});
	sortTree();
}

/** Toggles outliner collapsed status */
function eToggleCollapse() {
	treeRoot.visit((node) => {
		node.toggleExpanded();
	});
}

/* ======================================== HELPERS ======================================== */


/**
 * Sort the tree by custom compare function
 * see for details: https://stackoverflow.com/questions/22581701/how-to-sort-fancytree-nodes-with-folders-at-the-top,
 *					http://www.wwwendt.de/tech/fancytree/doc/jsdoc/FancytreeNode.html#sortChildren
 */
function sortTree() {
	const tmpSortFunction = function(a, b) {														// sort alphabetically and by folder
		const x = (a.isFolder() ? "0" : "1") + a.title.toLowerCase();
		const y = (b.isFolder() ? "0" : "1") + b.title.toLowerCase();
		return x === y ? 0 : x > y ? 1 : -1;
	};

	getTreeNodeByKey(getUniqueDataNodeByType("ProjectNode").UUID).sortChildren(tmpSortFunction, true);
	getTreeNodeByKey(getUniqueDataNodeByType("InfrastructureNode").UUID).sortChildren(tmpSortFunction, true);
	// getTreeNodeByKey(getUniqueDataNodeByType("TrashNode").UUID).sortChildren(tmpSortFunction, true);
}


/**
 * Checks if a treeNode with matching _key exists
 * @param  {string} _key unique treeNode identifier (= UUID of corresponding dataNode)
 * @throws Exception providing additional information on error
 */
function checkTreeNodeExistence(_key) {
	if (treeRoot.tree.getNodeByKey(_key) == null) {
		throw new Error(`TreeNode "${_key}" does not exist.`);
	}
}


/**
 * Returns a treeNode with matching _key
 * @param  {string} _key unique treeNode identifier (= UUID of corresponding dataNode)
 * @returns  {treeNode} treeNode with _key
 */
function getTreeNodeByKey(_key) {
	checkTreeNodeExistence(_key);
	return treeRoot.tree.getNodeByKey(_key);
}


/**
 * Sets the treeNode with given _key into edit mode
 * @param {string} _UUID of treeNode to alter
 */
function editTreeNodeHandler(_UUID) {
	getTreeNodeByKey(_UUID).editStart();
}


/**
 * Helper for logging treeNode events
 * @param  {Event} _event that got raised
 * @param  {object} _data argument of event
 */
/* function eventLogger(_event, _data) {
	$.ui.fancytree.info("Event \"" + _event.type + "\", node: " + _data.node.title);
} */
