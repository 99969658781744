export enum Application {
	ELECTRIC = "ELECTRIC",
	HYDRAULIC = "HYDRAULIC",
	PNEUMATIC = "PNEUMATIC",
}

export enum DynamicApplication {
	DA0 = "DAO",
	DA4 = "DA4",
	DA6 = "DA6",
}

export enum PortLayout {
	STRAIGHT = "STRAIGHT",
	ANGLED = "ANGLED", //REFACTOR Do we really need 3 variants of angled? maybe remove ANGLED
	ANGLED_LEFT = "ANGLED_LEFT",
	ANGLED_RIGHT = "ANGLED_RIGHT",
}

export enum PortOrientation {
	NORTH = "NORTH",
	EAST = "EAST",
	SOUTH = "SOUTH",
	WEST = "WEST",
}
