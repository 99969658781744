import type {portLabelStyleEnum}			from "../constantsAndEnumerations";


/**
 * Definition of PortLabel (saveData) format.
 * @property {number} distance - inner distance in [mm???] from connection end
 * @property {keyof typeof connectionLabelStyleEnum} style - style of label
 * @property {string} text - text to display on label
 */
export interface PortLabelData {
	distance: number;
	style: keyof typeof portLabelStyleEnum;
	text: string;
}


/** Class describing a label of a port. */
export class PortLabel {
	private __style: keyof typeof portLabelStyleEnum;
	private __distance: number;
	private __text: string;

	/**
	 * Creates a new instance of PortLabel.
	 * @param {PortLabelData} _labelData dataset describing a connection label (either as pure sourceData or merged with saveData)
	 */
	constructor(_labelData: PortLabelData) {
		this.__style			= _labelData.style;
		this.__distance		= _labelData.distance;
		this.__text				= _labelData.text;
	}

	/**
	 * Returns the style of this PortLabel.
	 */
	get style(): keyof typeof portLabelStyleEnum {
		return this.__style;
	}

	/**
	 * Sets the style of this PortLabel.
	 * @param {keyof typeof portLabelStyleEnum} _style to set
	 */
	set style(_style: keyof typeof portLabelStyleEnum) {
		if (this.__style === _style) return;
		this.__style = _style;
	}

	/**
	 * Returns the distance of this PortLabel from the end of the connection.
	 */
	get distance(): number {
		return this.__distance;
	}

	/**
	 * Sets the distance of this PortLabel to the end of the connection.
	 * @param {number} _distance to set
	 */
	set distance(_distance: number) {
		if (this.__distance === _distance) return;
		this.__distance = _distance;
	}

	/**
	 * Returns the text of this PortLabel.
	 */
	get text(): string {
		return this.__text;
	}

	/**
	 * Sets the text of this PortLabel.
	 * @param {string} _text to set
	 */
	set text(_text: string) {
		if (this.__text === _text) return;
		this.__text = _text;
	}

	/**
	 * Returns the variable characteristics of this PortLabel.
	 * @returns {PortLabelData} object containing all info
	 */
	public save(): PortLabelData {
		return {
			distance: this.distance,
			style: this.style,
			text: this.text,
		};
	}
}
