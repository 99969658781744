import {NodeGraphicsElement} from "../../graphics"; // convert to webpack import
import tmpProjectNodeIcon from "../../../images/outlinerIcons/projectNode.icon.svg";

const tmpProjectNodeImage = null; // convert to webpack import
const tmpProjectNodeSymbol = null;

export const projectNodeTemplate = {
	databaseId: -100,
	UUID: "proj",
	type: "ProjectNode",
	graphics: {
		image: new NodeGraphicsElement(tmpProjectNodeImage, null, null),
		symbol: new NodeGraphicsElement(tmpProjectNodeSymbol, null, null),
		icon: new NodeGraphicsElement(tmpProjectNodeIcon, null, null),
		position: {
			x: null,
			y: null,
		},
		layout: {
			pan: {x: null, y: null},
			zoom: null,
		},
		tooltip: "ProjectNodeToolTip",
	},

	i18nKey: "dataManager.defaultname-projectNode",
	description: "",
	ports: [],
	referenceDesignatorTemplate: {
		// TODO don't hardwire the referenceDesignator template here; the user might have another default template, that needs to be fetched referenceDesignatorManager on client start
		"@metadata": {
			authors: ["Christian Lange"],
			description: "reference designator (de: BMK) default variant",
			"last-updated": "2019-01-16",
			locale: "/",
		},
		regex: {
			start: "^",
			function: "(\\=)([\u00C0-\u017Fa-zA-Z]+)([1-9]\\d*)",
			place: "(\\+)([\u00C0-\u017Fa-zA-Z]+)",
			device: "(\\-)([\u00C0-\u017Fa-zA-Z]+)([1-9]\\d*)",
			port: "",
			end: "$",
		},
		functionPrefix: "=",
		functionToken: "A",
		infrastructureToken: "I",
		functionExample: "=A#+P",
		placePrefix: "+",
		placeToken: "P",
		devicePrefix: "-",
		deviceToken: "null",
		deviceExample: "=A#+P-D#",
		unitToken: "U",
		unitExample: "=A#+P-U#",
		cableToken: "W",
		cableExample: "=A#+P-W#",
		portPrefix: ":",
		portToken: "X",
		portExample: "=A#+P-D#:X#",
	},
	decentralControl: null,
};
