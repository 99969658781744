import {GLOBALEVENTMANAGER} from								"./applicationManager";
import {checkUniqueness} from										"./helper";
import {bindDomElementToTranslateEvent} from		"./localization/localizationManager";
import {getTranslation} from										"./localization/localizationManager";


/** Base class for all collapsible items
 * @export
 * @class CollapsibleItemBase
 */
export class CollapsibleItemBase {
	/** Standard constructor
	 * @param  {String} _id of this item
	 */
	constructor(_id) {
		this.id = checkUniqueness(_id);
		this.domContainer = {};
		this.createDomStructure();
	}

	/** Creates the necessary dom elements for this accordion item */
	createDomStructure() {
		this.domContainer = document.createElement("div");
		this.domContainer.id = this.id;
		this.domContainer.classList.add("collapsible-item", "noselect");
	}
}


/** CollapsibleAccordionItem version of CollapsibleItemBase
 * @export
 * @class CollapsibleAccordionItem
 * @extends {CollapsibleItemBase}
 */
export class CollapsibleAccordionItem extends CollapsibleItemBase {
	/** Standard constructor
	 * @param  {Object} _sourceData element to show in this collapsible
	 */
	constructor(_sourceData) {
		super(_sourceData.databaseId);
		this.captionKey = _sourceData.name;
		this.materialNumber = _sourceData.materialNumber;
		this.type = _sourceData.type;
		this.marked = false;
		this.layout = {};
		this.image = {};
		this.graphicsSource = {
			image: _sourceData.graphics.image.file,
			symbol: _sourceData.graphics.symbol.file,
		};
		this.extendDomStructure();
		this.setActive(false);																																												// always starting deactivated
		GLOBALEVENTMANAGER.addHandler("eDTM_ToggleAccordionCollapsibleItemsActiveMode", (e) => this.setActive(e));		// Wrapper for event raised from DataManager, toggling the activeMode of this collapsibleItem depending on the type of canvas
		GLOBALEVENTMANAGER.addHandler("eGUI_ToggleDrawMode", (e) => this.toggleImage(e));															// Wrapper for event raised from guiManager, toggling the image of this collapsibleItem depending on the drawMode
	}


	/** Creates the necessary dom elements for this accordion */
	extendDomStructure() {
		bindDomElementToTranslateEvent(this.domContainer, "title", this.captionKey);
		this.domContainer.classList.add("jspPaletteNode");
		this.domContainer.setAttribute("jtkNodeType", this.type);
		this.domContainer.setAttribute("databaseId", this.id);

		this.image = document.createElement("IMG");
		this.image.classList.add("collapsible-item-image");
		// this.image.src gets initially set by "eGUI_ToggleDrawMode" (raised from applicationManager)
		this.domContainer.appendChild(this.image);

		const tmpCaption = document.createElement("span");
		tmpCaption.classList.add("collapsible-item-caption");
		bindDomElementToTranslateEvent(tmpCaption, "textContent", this.captionKey);
		this.domContainer.appendChild(tmpCaption);
	}

	/** Sets this elements active mode
	 * Used to allow/prevent dragging onto different canvas types
	 * @param  {Boolean} _active mode to set
	 */
	setActive(_active) {
		if (_active) {
			this.domContainer.classList.remove("grayout", "noDrag");
		} else {
			this.domContainer.classList.add("grayout", "noDrag");
		}
	}

	/** Toggles between image/symbol
	 * @param  {Boolean} _drawMode mode to set
	 * @memberof CollapsibleAccordionItem
	 */
	toggleImage(_drawMode) {
		this.image.src = this.graphicsSource[_drawMode.type.toLowerCase()];
	}

	/** Sets this items layout
	 * @param  {accordionLayoutEnum} _layout to set
	 */
	setLayout(_layout) {
		// remove former layout
		this.domContainer.classList.remove(this.layout.css);
		// update layout property
		this.layout = _layout;

		// add new layout
		this.domContainer.classList.add(this.layout.css);
	}

	/** Sets this items marked mode
	 * Used to mark matches from Accordion searches
	 * @param  {Boolean} _marked mode to set
	 */
	setMarked(_marked) {
		if (_marked) {
			this.domContainer.classList.add("marked");
		} else {
			this.domContainer.classList.remove("marked");
		}
	}

	/** Checks if one of this collapsibleItems properties matches the search criteria
	 * Used for accordion search (atm only checks for name/materialNumber)
	 * @param  {[String]} _query name/materialNumber of item or parts of it
	 * @returns {Boolean} if item matching the search criteria was found
	 */
	searchForProperty(_query) {
		let result = false;
		for (let i = 0; i < _query.length; i++) {																	// using traditional for loop because forEach doesn't break
			const tmpNameResult = getTranslation(this.captionKey).toLowerCase().includes(_query[i].toLowerCase());
			const tmpMaterialNumberResult = (this.materialNumber !== null) ? this.materialNumber.includes(_query[i]) : false;
			result = (tmpNameResult || tmpMaterialNumberResult) ? true : false;
			if (result === false) break;
		}
		this.setMarked(result);
		return result;
	}
}


/** CollapsibleLayerControl base class version of CollapsibleItemBase
 * @export
 * @class CollapsibleLayerControlItemBase
 * @extends {CollapsibleItemBase}
 */
export class CollapsibleLayerControlItemBase extends CollapsibleItemBase {
	/** Creates an instance of CollapsibleLayerControlItemBase.
	 * @param {String} _id  of this item
	 * @param {i18nKey} _captionKey  of this item
	 * @param {[String]} _cssSelector array of css classes to bind to this control
	 * @memberof CollapsibleLayerControlItemBase
	 */
	constructor(_id, _captionKey, _cssSelector) {
		super(_id);
		this.captionKey = _captionKey;
		this.cssRules = this.findCssRules(_cssSelector);
		this.extendDomStructure();
	}

	/**
	 *
	 *
	 * @memberof CollapsibleLayerControlItemBase
	 */
	extendDomStructure() {
		const tmpCaption = document.createElement("span");
		tmpCaption.classList.add("collapsible-item-caption");
		bindDomElementToTranslateEvent(tmpCaption, "textContent", this.captionKey);
		bindDomElementToTranslateEvent(this.domContainer, "title", this.captionKey);
		this.domContainer.appendChild(tmpCaption);
		this.domContainer.classList.add("collapsible-item-layerControl");
	}


	/** Finds cssRules from document.styleSheets matching the given cssSelectors
	 * @param {[String]} _cssSelector array of css classes to bind to this control
	 * @returns {Object} cssRule with matching cssSelector
	 * @memberof CollapsibleLayerControlItemBase
	 * @throws if no matching cssRule was found
	 */
	findCssRules(_cssSelector) {
		const result = [];

		_cssSelector.forEach((element) => {
			for (let i = 0; i < document.styleSheets.length; i++) {
				for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
					if (document.styleSheets[i].cssRules[j].selectorText === element) {
						result.push(document.styleSheets[i].cssRules[j]);
						break;
					}
				}
			}
		});

		if (result.length !== 0) {
			return result;
		} else {
			console.error(`cssSelector(s) "${_cssSelector}" not found in document.styleSheets!`);
			// throw new Error(`cssSelector(s) "${_cssSelector}" not found in document.styleSheets!`);
		}
	}

	/**
	 * @memberof CollapsibleLayerControlItemBase
	 */
	toggleVisibility() {
		throw new Error("This method is abstract and must be implemented in all derived classes");
	}
}


/** CollapsibleLayerControl checkbox version of CollapsibleLayerControlItemBase
 * @export
 * @class CollapsibleLayerControlItemDropdown
 * @extends {CollapsibleLayerControlItemBase}
 */
export class CollapsibleLayerControlItemCheckbox extends CollapsibleLayerControlItemBase {
	/** Creates an instance of CollapsibleLayerControlItemCheckbox.
	 * @param {String} _id  of this item
	 * @param {i18nKey} _captionKey  of this item
	 * @param {[String]} _cssSelector array of css classes to bind to this control
	 * @memberof CollapsibleLayerControlItemCheckbox
	 */
	constructor(_id, _captionKey, _cssSelector) {
		super(_id, _captionKey, _cssSelector);
		this.extendDomStructure2();
	}

	/**
	 * @memberof CollapsibleLayerControlItemCheckbox
	 */
	extendDomStructure2() {
		const tmpInput = document.createElement("input");
		tmpInput.type = "checkbox";
		tmpInput.checked = true;
		// tmpInput.classList.add("no need for an extra class atm");
		bindDomElementToTranslateEvent(tmpInput, "title", "layerController.tt");
		tmpInput.addEventListener("input", () => this.toggleVisibility(tmpInput.checked));
		this.domContainer.appendChild(tmpInput);
	}

	/**
	 * @param {String} _show
	 * @memberof CollapsibleLayerControlItemCheckbox
	 */
	toggleVisibility(_show) {
		let tmpDisplayValue;
		(_show) ? tmpDisplayValue = "" : tmpDisplayValue = "none";

		this.cssRules.forEach((rule) => {
			rule.style.display = tmpDisplayValue;
		});
	}
}


/** CollapsibleLayerControl slider version of CollapsibleLayerControlItemBase
 * @export
 * @class CollapsibleLayerControlItemDropdown
 * @extends {CollapsibleLayerControlItemBase}
 */
export class CollapsibleLayerControlItemSlider extends CollapsibleLayerControlItemBase {
	/** Creates an instance of CollapsibleLayerControlItemSlider.
	 * @param {String} _id  of this item
	 * @param {i18nKey} _captionKey  of this item
	 * @param {[String]} _cssSelector array of css classes to bind to this control
	 * @memberof CollapsibleLayerControlItemSlider
	 */
	constructor(_id, _captionKey, _cssSelector) {
		super(_id, _captionKey, _cssSelector);
		this.extendDomStructure2();
	}

	/**
	 *
	 *
	 * @memberof CollapsibleLayerControlItemSlider
	 */
	extendDomStructure2() {
		const tmpInput = document.createElement("input");
		tmpInput.type = "range";
		tmpInput.min = 0.1;
		tmpInput.max = 1;
		tmpInput.step = 0.1;
		tmpInput.defaultValue = 1;
		// tmpInput.classList.add("no need for an extra class atm");
		bindDomElementToTranslateEvent(tmpInput, "title", "layerController.tt");
		tmpInput.addEventListener("input", () => this.toggleVisibility(tmpInput.value));
		this.domContainer.appendChild(tmpInput);
	}

	/**
	 * @param {Number} _value
	 * @memberof CollapsibleLayerControlItemSlider
	 */
	toggleVisibility(_value) {
		this.cssRules.forEach((rule) => {
			rule.style.opacity = _value;
		});
	}
}


/** CollapsibleLayerControl dropdown version of CollapsibleLayerControlItemBase
 * @export
 * @class CollapsibleLayerControlItemDropdown
 * @extends {CollapsibleLayerControlItemBase}
 */
export class CollapsibleLayerControlItemDropdown extends CollapsibleLayerControlItemBase {
}
