import {GLOBALEVENTMANAGER} from								"./applicationManager";
import {LayerControllerCollapsible} from				"./collapsible";
import {checkExistenceNew} from									"./helper";
import {checkUniqueness} from										"./helper";
import {getDomElementById} from									"./helper";
import {bindDomElementToTranslateEvent} from		"./localization/localizationManager";


/** Container for collapsible elements
 * Vanilla-JS implementation as a replacement for jquery-accordion
 * @class LayerController
 */
export class LayerController {
	/** Standard constructor
	 * @param  {String} _parentId of DOM element to nest into (usually sidePanelRight)
	 */
	constructor(_parentId) {
		this.parentId = checkExistenceNew(_parentId);
		this.id = checkUniqueness("layerController");																						// ensure no other layerController exists (el cheapo singleton)
		this.collapsible = new LayerControllerCollapsible(`${this.id}_collapsible`);
		this.domContainer = {};
		this.createDomStructure();
	}

	/** Creates the necessary dom element for this accordion */
	createDomStructure() {
		this.domContainer = document.createElement("div");
		this.domContainer.id = this.id;
		this.domContainer.classList.add("XXlayerControllerXX");
		this.domContainer.appendChild(this.collapsible.domContainer);
		getDomElementById(this.parentId).appendChild(this.domContainer);
	}

	/** Adds a layerControllerItem to this layerController
	 * @param  {CollapsibleLayerControllerItemBase} _element to add
	 */
	addElement(_element) {
		this.collapsible.addItem(_element);
	}

	/** Returns a collapsible of this accordion
	 * @param  {String} _id of layerControllerItem to find
	 * @returns {CollapsibleLayerControllerItemBase|false} with matching id or false if none was found
	 */
	getElementById(_id) {
		// TODO search in this collapsible elements
		console.log(this.collapsible.getItemById(_id));
	}
}
