/* eslint-disable require-jsdoc */
/* eslint-disable jsdoc/require-jsdoc */

//TODO replace by commented code once different interfaces have been implemented

import {Application} from "../constantsAndEnumerations2";
import {createUUID, getInterfaceSourceDataByDatabaseId} from "../dataManager";

import {Interface} from "./interface";
// import {ElectricInterface}										from "./ElectricInterface";
// import {HydraulicInterface}										from "./HydraulicInterface";
// import {PneumaticInterface}										from "./PneumaticInterface";

import type {BaseInterfaceData, BaseInterfaceSaveData, BaseInterfaceSourceData} from "./baseInterface.schema";

import type {ElectricInterfaceData} from "./electricInterface.schema";
import type {HydraulicInterfaceData} from "./hydraulicInterface.schema";
import type {PneumaticInterfaceData} from "./pneumaticInterface.schema";

export function interfaceFactory(interfaceDatabaseId: number): Interface; // export function interfaceFactory(interfaceDatabaseId: number): ElectricInterface|HydraulicInterface|PneumaticInterface;
export function interfaceFactory(interfaceSaveData: BaseInterfaceSaveData): Interface; // export function interfaceFactory(interfaceSaveData: BaseInterfaceSaveData): ElectricInterface|HydraulicInterface|PneumaticInterface;
export function interfaceFactory(interfaceSourceData: BaseInterfaceSourceData): Interface; // export function interfaceFactory(interfaceSourceData: BaseInterfaceSourceData): ElectricInterface|HydraulicInterface|PneumaticInterface;
export function interfaceFactory(interfaceReference: number | BaseInterfaceSaveData | BaseInterfaceSourceData): Interface {
	// export function interfaceFactory(interfaceReference: number|BaseInterfaceSaveData|BaseInterfaceSourceData): ElectricInterface|HydraulicInterface|PneumaticInterface {
	let interfaceData: BaseInterfaceData;

	if (typeof interfaceReference === "number") {
		interfaceData = {
			...(getInterfaceSourceDataByDatabaseId(interfaceReference) as BaseInterfaceSourceData), //TODO casting to BaseInterfaceSourceData can be omitted once getInterfaceSourceDataByDatabaseId has a correct return type
			UUID: createUUID(),
		};
		//! ATTENTATION: When cloning ports/interfaces UUIDs get set to null, you need to catch that here
	} else if (!("UUID" in interfaceReference)) {
		// } else if (!("UUID" in interfaceReference) || interfaceReference.UUID === null) {	// useful when cloning ports/interfaces set UUIDs to null
		interfaceData = {
			...(interfaceReference as BaseInterfaceSourceData),
			UUID: createUUID(),
		};
	} else {
		// interfaceSaveData
		interfaceData = {
			...(getInterfaceSourceDataByDatabaseId(interfaceReference.databaseId) as BaseInterfaceSourceData), //TODO casting to BaseInterfaceSourceData can be omitted once getInterfaceSourceDataByDatabaseId has a correct return type
			...(interfaceReference as BaseInterfaceSaveData),
		};
	}

	switch (Application[interfaceData.application]) {
		case Application.ELECTRIC:
			return new Interface(interfaceData as ElectricInterfaceData); // return new ElectricInterface(interfaceData as ElectricInterfaceData);
		case Application.HYDRAULIC:
			return new Interface(interfaceData as HydraulicInterfaceData); // return new HydraulicInterface(interfaceData as HydraulicInterfaceData);
		case Application.PNEUMATIC:
			return new Interface(interfaceData as PneumaticInterfaceData); // return new PneumaticPort(interfaceData as PneumaticInterfaceData);
		default:
			throw new Error(`InterfaceFactory: Unknown application "${interfaceData.application}".`);
	}
}
