
import {getDataNodeByUUID}				from "../dataManager";
import {findCssRule}							from "../helper";
import {getJspContainerByUUID}		from "../jsPlumb/jspManager";
import {getJspDomElementByUUID}		from "../jsPlumb/jspManager";
import {SvgLibWrapper}						from "./svgLibWrapper";
import titleBlockTemplate					from "./titleBlockTemplate";


/**
 * Central structure, representing a single pdfPage.
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		add routine to fit pages to normed page formats
 *		refactor connection and port routine
 *
 * AUTHOR(S):
 *		Frederic Hoffmann
 *		Christian Lange
 */

/**
 * Central structure, representing a single pdfPage.
 * @export
 * @class PdfPage
 */
export class PdfPage {
	/**
	 * Creates an instance of PdfPage.
	 * @param {String} _UUID id of the pdfPage to create
	 * @param {Boolean} _printable indicates whether page is printable
	 * @param {String} _canvasContainerId to buffer pdfPage into
	 * @memberof PdfPage
	 */
	constructor(_UUID, _printable, _canvasContainerId) {
		this.canvasContainerId = _canvasContainerId;
		this.UUID = _UUID;
		this.nodes = [];
		this.connections = [];
		this.imageCaptionPadding = 12;
		this.trashed;
		this.printable = _printable;
		this.canvasDetails = {};
		this.drawPaddingHorizontal = 100;
		this.drawPaddingVertical = 20;
		this.rightPanelWidth = 575;
		this.leftPanelWidth = 430;
		this.minTitleBlockWidth = 1075;
		this.titleBlockHeight = 120;
	}

	/**
	 * Returns the trashed state of a pdfPage.
	 * @readonly
	 * @memberof PdfPage
	 */
	get trashed() {
		if (this.UUID !== "root") return getDataNodeByUUID(this.UUID).trashed;		// dirty little secret -> cl was is
	}

	/**
	 * Returns pdfPage name.
	 * @readonly
	 * @memberof PdfPage
	 */
	get name() {
		return getDataNodeByUUID(this.UUID).getName();
	}

	/**
	 * Adds a pdfNode to the nodes array of this page.
	 * @param {PdfNode} _pdfNode to add
	 * @memberof PdfPage
	 */
	addNode(_pdfNode) {
		this.nodes.push(_pdfNode);
	}

	/**
	 * Removes a pdfNode from the nodes array of this page.
	 * @param {PdfNode} _pdfNode to remove
	 * @memberof PdfPage
	 */
	removeNode(_pdfNode) {
		const tmpNode = this.getPdfNodeByUUID(_pdfNode.UUID);
		if (tmpNode) {
			const indexToRemove = this.nodes.indexOf(tmpNode);
			this.nodes.splice(indexToRemove, 1);
			return;
		} else {
			throw new Error(`PdfNode with UUID "${_pdfNode.UUID}" not found`);
		}
	}

	/**
	 * Searches this page for a specific pdfNode id and returns it.
	 * @param {String} _UUID of the pdfNode one wants to find
	 * @returns {PdfNode | false} if the pdfNode is contained in nodes array it's returned, otherwise false is returned
	 * @memberof PdfPage
	 */
	getPdfNodeByUUID(_UUID) {
		let result = false;
		this.nodes.forEach((node) => {
			if (node.UUID === _UUID) result = node;
		});
		return result;
	}

	/**
	 * Creates a svg/canvas Object for this page and returns it.
	 * @param {String} _drawMode defines whether symbols or images are used to draw
	 * @param {Object} _titleBlockContent all contents for titleBlock
	 * @returns {Object} returns pdf/blob...
	 * @memberof PdfPage
	 */
	async draw(_drawMode, _titleBlockContent) {
		const tmpPageNode = getDataNodeByUUID(this.UUID);

		this.canvasDetails = _fillCanvasDetails(this.UUID, this.minTitleBlockWidth, this.titleBlockHeight, this.drawPaddingHorizontal, this.drawPaddingVertical, this.imageCaptionPadding);

		_fillTitleBlockContent(this.name, this.canvasDetails.width, this.leftPanelWidth, this.rightPanelWidth);

		const svgController = new SvgLibWrapper(this.UUID, this.canvasContainerId, this.canvasDetails, titleBlockTemplate);

		svgController.drawTitleBlock(_titleBlockContent);

		const promises = [];
		this.nodes.forEach((node) => {
			const tmpPdfNode = getDataNodeByUUID(node.UUID);
			promises.push(_drawDevice(tmpPdfNode, this.canvasDetails.shift.x, this.canvasDetails.shift.y, this.imageCaptionPadding));
		});
		await Promise.all(promises);

		tmpPageNode.connections.forEach((connection) => {
			const connectionSvg = getJspDomElementByUUID(connection.UUID).cloneNode(true);
			connectionSvg.style.x = `${parseFloat(connectionSvg.style.left) - this.canvasDetails.shift.x}px`;
			connectionSvg.style.y = `${parseFloat(connectionSvg.style.top) - this.canvasDetails.shift.y}px`;
			connectionSvg.removeChild(connectionSvg.firstChild);
			svgController.canvas.node.appendChild(connectionSvg);

			const children = [...connectionSvg.children];
			children.forEach((path) => {
				let classList = [...path.classList];
				classList = classList.filter((className) => className.match(new RegExp(/electric-edge/)));	// extracts all css classes beginning with electric-edge
				classList.forEach((className) => {
					let cssText = findCssRule(className).cssText;
					const colorName = cssText.match(new RegExp(/(?<=var\().*(?=\))/));												// extracts name of css variable - var(name)
					if (colorName) {
						const color = getComputedStyle(document.documentElement).getPropertyValue(colorName);
						cssText = cssText.replace(new RegExp(/var\(--(.*?)\)/), `${color}`);										// replaces --var(--color) with true color
					}
					const style = document.createElementNS("http://www.w3.org/2000/svg", "style");
					style.innerHTML = cssText;
					svgController.canvas.node.appendChild(style);
				});
			});
		});

		const cablePorts = [...getJspDomElementByUUID(this.UUID).querySelectorAll(".jtk-endpoint")];
		cablePorts.forEach((port) => {
			const portInfo = {
				x: `${parseFloat(port.style.left) - this.canvasDetails.shift.x}px`,
				y: `${parseFloat(port.style.top) - this.canvasDetails.shift.y}px`,
				width: port.style.width,
				height: port.style.height,
			};
			svgController.drawPort(portInfo);
		});

		const tmpPageOutput = {
			id: tmpPageNode.UUID,
			name: tmpPageNode.getName(),
			referenceDesignator: tmpPageNode.referenceDesignator,
			canvas: {},
		};
		tmpPageOutput.canvas = svgController.getCanvas();
		return tmpPageOutput;

		/**
		 * Fills the canvasDetails from jsPlumbBoundInfo and adds paddings.
		 * @param {UUID} _UUID of pdfPage
		 * @param {Number} _minTitleBlockWidth minimal width of titleBlockHeight
		 * @param {Number} _titleBlockHeight height of titleBlock
		 * @param {Number} _drawPaddingHorizontal padding of the drawing - horizontal
		 * @param {Number} _drawPaddingVertical padding of the drawing - vertical
		 * @param {Number} _imageCaptionPadding padding of the image captions
		 * @returns {Object} containing all canvas relevant infos such as width, height, padding, shift
		 */
		function _fillCanvasDetails(_UUID, _minTitleBlockWidth, _titleBlockHeight, _drawPaddingHorizontal, _drawPaddingVertical, _imageCaptionPadding) {
			const jsPlumbBoundInfo = getJspContainerByUUID(_UUID).surface.getBoundsInfo();
			if (jsPlumbBoundInfo.w < _minTitleBlockWidth) {
				jsPlumbBoundInfo.w = _minTitleBlockWidth;
			}
			const canvasWidth = jsPlumbBoundInfo.w + 2*_drawPaddingHorizontal;
			const canvasHeight = jsPlumbBoundInfo.h + _titleBlockHeight + 4*_imageCaptionPadding + 2*_drawPaddingVertical;

			// translates coordinates to jsPlumbBoundInfo coordinates respecting drawPaddings
			const shiftX = jsPlumbBoundInfo.x - _drawPaddingHorizontal;
			const shiftY = jsPlumbBoundInfo.y - _drawPaddingVertical;


			// ? function transformCoords(_originalCoords)

			// In case every pdfPage needs a Din format
			// increase page dimensions to Din aspect ratio
			// if (canvasWidth > canvasHeight && canvasHeight < canvasWidth*0.7071 ) {
			// 	canvasHeight = canvasWidth * 0.7071;
			// }
			// if (canvasHeight > canvasWidth && canvasWidth < canvasHeight*0.7071 ) {
			// 	canvasWidth = canvasHeight * 0.7071;
			// }

			const canvasDetails = {
				id: tmpPageNode.UUID,
				width: canvasWidth,
				height: canvasHeight,
				shift: {
					x: shiftX,
					y: shiftY,
				},
				padding: {
					horizontal: 10,
					vertical: 15,
				},
			};
			return canvasDetails;
		}

		/**
		 * Fills the content from UI in titleBlockTemplate object.
		 * @param {String} _name of active assembly
		 * @param {Number} _canvasWidth width of canvas
		 * @param {Number} _leftPanelWidth width of left titleBlock panel
		 * @param {Number} _rightPanelWidth width of right titleBlock panel
		 */
		function _fillTitleBlockContent(_name, _canvasWidth, _leftPanelWidth, _rightPanelWidth) {
			for (const key in _titleBlockContent) {
				if (_titleBlockContent.hasOwnProperty(key)) {
					titleBlockTemplate[key].content = _titleBlockContent[key];
				}
			}

			titleBlockTemplate["assemblyName"].content = _name;
			const middlePanelWidth = _canvasWidth - _leftPanelWidth - _rightPanelWidth;
			titleBlockTemplate["assemblyName"].position.x = - middlePanelWidth;
			titleBlockTemplate["assemblyName"].width = middlePanelWidth;

			for (let i = 1; i < 5; i++) {
				titleBlockTemplate[`creator${i}`].position.x = -middlePanelWidth - _leftPanelWidth;
				titleBlockTemplate[`version${i}`].position.x = -middlePanelWidth - _leftPanelWidth +140;
				titleBlockTemplate[`date${i}`].position.x = -middlePanelWidth - _leftPanelWidth +190;
				titleBlockTemplate[`change${i}`].position.x = -middlePanelWidth - _leftPanelWidth +290;
			}
		}

		/**
		 * Collects drawInfos and calls svgController to draw the Svg, caption and referenceDesignator.
		 * @param {PdfNode} _tmpPdfNode contains all drawInfos
		 * @param {Number} _horizontalShift shifts coordinate system to jsPlumbBound horizontal
		 * @param {Number} _verticalShift shifts coordinate system to jsPlumbBound vertical
		 * @param {Number} _imageCaptionPadding padding for image captions
		 */
		async function _drawDevice(_tmpPdfNode, _horizontalShift, _verticalShift, _imageCaptionPadding) {
			const tmpGraphicsInfo = {
				path: _tmpPdfNode.graphics.activeGraphics.file,
				id: _tmpPdfNode.UUID,
				posX: _tmpPdfNode.graphics.position.x - _horizontalShift,
				posY: _tmpPdfNode.graphics.position.y - _verticalShift,
				width: _tmpPdfNode.graphics.activeGraphics.width,
				height: _tmpPdfNode.graphics.activeGraphics.height,
			};
			await svgController.drawVectorImage(tmpGraphicsInfo);

			const tmpNameInfo = {
				string: _tmpPdfNode.getName(),
				posX:	tmpGraphicsInfo.posX + tmpGraphicsInfo.width/2,
				posY: tmpGraphicsInfo.posY + tmpGraphicsInfo.height + _imageCaptionPadding,
			};
			svgController.drawText(tmpNameInfo);

			const tmpRefDesignatorInfo = {
				string: _tmpPdfNode.referenceDesignator.getReferenceDesignator().string(),
				posX: tmpGraphicsInfo.posX + tmpGraphicsInfo.width/2,
				posY: tmpGraphicsInfo.posY + tmpGraphicsInfo.height + 2*_imageCaptionPadding,
			};
			svgController.drawText(tmpRefDesignatorInfo);
		}
	}
}
