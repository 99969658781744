import {createUUID} from "../dataManager";
import {EventManager} from "../eventManager";

//TODO fix usage of addInterface/removeInterface to decouple events in setters correctly.

/** Class representing a standard port interface */
export class Interface {
	/**
	 * Standard constructor.
	 * @param {object} _interfaceData of interface.
	 */
	constructor(_interfaceData) {
		this.eventManager = new EventManager();
		this.application = _interfaceData.application;
		this.UUID = createUUID();
		this.parentUUID = null; // just for legacy
		this.parent = null;
		this.databaseId = _interfaceData.databaseId;
		this.currentMax = _interfaceData.currentMax;
		this.currentMin = _interfaceData.currentMin;
		this.__current = _interfaceData.current ? _interfaceData.current : 0;
		this.__description = _interfaceData.description;
		this.frequency = _interfaceData.frequency;
		this.groupX = _interfaceData.groupX; //REFACTOR to 'group' -> atm REST is sending this as interfaceTyp ["1", "2", "3", "4"] -> make server send group ["ENERGY"|"DATA"|"SIGNAL"] and remove conversion from dataConverter
		this.__isMotor = _interfaceData.isMotor; //? I cannot remember if its mutable or not
		this.__isMonitored = _interfaceData.isMonitored;
		this.__isProtected = _interfaceData.isProtected;
		this.__isSafe = _interfaceData.isSafe;
		this.__isSoftStarted = _interfaceData.isSoftStarted;
		this.__operatingMode = _interfaceData.operatingMode == undefined ? undefined : JSON.parse(JSON.stringify(_interfaceData.operatingMode));
		this.name = _interfaceData.name;
		this.power = _interfaceData.power;
		this.__type = _interfaceData.type;
		this.__voltage = _interfaceData.voltage;
		this.requiredLeads = _interfaceData.requiredLeads;

		this.signatures = {
			parameterChanged: "interface.parameter.changed",
		};
	}

	/**
	 * Sets this interface's current.
	 * @param {number} _current to set.
	 */
	set current(_current) {
		if (this.__current === _current) return;
		this.__current = _current;
		//REFACTOR due to the inconsistent use of addInterface/removeInterface I had to jump to the port directly in all setters, sorry.
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns this interface's current.
	 * @returns {number} current.
	 */
	get current() {
		return this.__current;
	}

	/**
	 * Sets this interface's description.
	 * @param {string} _description to set.
	 */
	set description(_description) {
		if (this.__description === _description) return;
		this.__description = _description;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns this interface's description.
	 * @returns {string} description.
	 */
	get description() {
		return this.__description;
	}

	/**
	 * Sets this interface's isMotor status.
	 * @param {boolean} _isMotor to set.
	 */
	set isMotor(_isMotor) {
		if (this.__isMotor === _isMotor) return;
		this.__isMotor = _isMotor;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns true if this is an motoric interface, false if not.
	 * @returns {boolean} isMotor.
	 */
	get isMotor() {
		return this.__isMotor;
	}

	/**
	 * Sets this interface's isMonitored status.
	 * @param {boolean} _isMonitored to set.
	 */
	set isMonitored(_isMonitored) {
		if (this.__isMonitored === _isMonitored) return;
		this.__isMonitored = _isMonitored;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns true if this interface is monitored, false if not.
	 * @returns {boolean} isMonitored.
	 */
	get isMonitored() {
		return this.__isMonitored;
	}

	/**
	 * Sets this interface's isProtected status.
	 * @param {boolean} _isProtected to set.
	 */
	set isProtected(_isProtected) {
		if (this.__isProtected === _isProtected) return;
		this.__isProtected = _isProtected;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns true if this interface is protected, false if not.
	 * @returns {boolean} isProtected.
	 */
	get isProtected() {
		return this.__isProtected;
	}

	/**
	 * Sets this interface's isSafe status.
	 * @param {boolean} _isSafe to set.
	 */
	set isSafe(_isSafe) {
		if (this.__isSafe === _isSafe) return;
		this.__isSafe = _isSafe;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns true if this interface is safe, false if not.
	 * @returns {boolean} isSafe.
	 */
	get isSafe() {
		return this.__isSafe;
	}

	/**
	 * Sets this interface's isSoftStarted status.
	 * @param {boolean} _isSoftStarted to set.
	 */
	set isSoftStarted(_isSoftStarted) {
		if (this.__isSoftStarted === _isSoftStarted) return;
		this.__isSoftStarted = _isSoftStarted;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns true if this interface is soft started, false if not.
	 * @returns {boolean} isSoftStarted.
	 */
	get isSoftStarted() {
		return this.__isSoftStarted;
	}

	/**
	 * Sets this interface's operating mode.
	 * @param {object} _operatingMode to set.
	 */
	set operatingMode(_operatingMode) {
		if (this.__operatingMode == _operatingMode) return;
		this.__operatingMode = _operatingMode;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns the operating mode of this interface.
	 * @returns {object} operatingMode.
	 */
	get operatingMode() {
		return this.__operatingMode;
	}

	/**
	 * Sets this interface's type.
	 * @param {object} _type to set.
	 */
	set type(_type) {
		if (this.__type == _type) return;
		this.__type = _type;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns the type of this interface.
	 * @returns {object} type.
	 */
	get type() {
		return this.__type;
	}

	/**
	 * Sets this interface's voltage.
	 * @param {number} _voltage to set.
	 */
	set voltage(_voltage) {
		if (this.__voltage === _voltage) return;
		this.__voltage = _voltage;
		// this.eventManager.dispatch(this.signatures.parameterChanged, this.save());
		this.parent.eventManager.dispatch(this.parent.signatures.interfaceParameterChanged);
	}

	/**
	 * Returns this interface's voltage.
	 * @returns {number} voltage.
	 */
	get voltage() {
		return this.__voltage;
	}

	/**
	 * Retrieves interface data for autoConfig request
	 * @returns  {object} interface data
	 */
	getAutoConfigData() {
		const tmpInterface = {
			uuid: this.UUID,
			interfaceTyp: this.type.group,
			name: this.name,
			id: this.databaseId,
			safeFlg: this.isSafe,
			voltage: this.voltage,
			current: this.current,
			mode: this.operatingMode ? this.operatingMode.number : null,
			motorFlg: this.isMotor,
			protectionFlg: this.isProtected,
			softFlg: this.isSoftStarted,
			monitorFlg: this.isMonitored,
			address: null,
		};
		return tmpInterface;
	}

	// Not in use, until technical parameters are defined
	// /**
	//  * setter for technicalParameters
	//  * @param  {object} _parameter key/value pair, pE {frequency: 10}
	//  */
	// setParameter(_parameter) {
	// 	const tmpParameter = Object.entries(_parameter)[0][0];
	// 	const tmpValue = Object.entries(_parameter)[0][1];

	// 	 check if property exists
	// 	if (!this.hasOwnProperty(tmpParameter)) throw new Error(`This object does not have a property "${tmpParameter}"!`);

	// 	this[tmpParameter] = tmpValue;

	// 	const result = {
	// 		parameter: tmpParameter,
	// 		interface: this,
	// 		port: getPortByUUID(this.parentUUID),
	// 		dataNode: getDataNodeByUUID(getPortByUUID(this.parentUUID).parentUUID),
	// 	};

	// 	GLOBALEVENTMANAGER.dispatch("eDTM_TechnicalParameterChanged", result);
	// }

	/**
	 * Returns the variable characteristics of an Interface.
	 * @returns {object} containing electrical parameters, UUID, and databaseId.
	 */
	save() {
		return {
			UUID: this.UUID,
			application: this.application,
			databaseId: this.databaseId,
			description: this.description,
			voltage: this.voltage,
			current: this.current,
			operatingMode: this.operatingMode,
			isMonitored: this.isMonitored,
			isMotor: this.isMotor,
			isProtected: this.isProtected,
			isSafe: this.isSafe,
			isSoftStarted: this.isSoftStarted,
		};
	}

	/**
	 * Returns a clone of this interface (including it's electrical parameters)
	 * Resets the UUID!
	 * @returns {object} new InterfaceSourceData
	 */
	clone() {
		return {...this.save(), UUID: null};
	}
}
