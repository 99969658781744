import {dataRoot} from "./dataManager";
import {formatter, getTranslation} from "./localization/localizationManager";
import {PortSide} from "./ports/utils";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;

/* Collection of common helpers providing program-wide convenience functions
 *
 * UTILIZING:
 *		nothing atm
 *
 * TODO:
 *		nothing atm
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/* Enumeration of version info modes, used by menuBar */ // ToDo stupid to separate that from environmentTypeEnum
const versionInfoModeEnum = {
	MASTER: {type: "MASTER", class: "versionInfo-master"},
	DEVELOPMENT: {type: "DEVELOPMENT", class: "versionInfo-development"},
	RELEASE: {type: "RELEASE", class: "versionInfo-release"},
	FEATURE: {type: "FEATURE", class: "versionInfo-feature"},
};

/**
 * Parses a variable array for elements with a given key/value combination
 * @param  {Array} _array to parse
 * @param  {string} _key to find
 * @param  {object} _value to find
 * @returns  {DataNode|[DataNode]|false} single or array of matching dataNode(s) or false if no matches were found
 * @throws exception providing additional information on error
 */
export function searchArrayForElementByKeyValuePair(_array, _key, _value) {
	if (!Array.isArray(_array)) {
		// validate _array exists
		throw new Error("Invalid array.");
	}

	const number = _array.filter((e) => (typeof e[_key] === "function" ? e[_key]() : e[_key]) === _value).length; // determine number of matching dataNodes
	if (number == 0) {
		// no matching dataNode found
		return false;
	} else if (number == 1) {
		// single matching dataNode found
		return _array.filter((e) => (typeof e[_key] === "function" ? e[_key]() : e[_key]) === _value)[0];
	} else if (number > 1) {
		// multiple matching dataNodes found
		const tmpArray = [];
		for (let i = 0; i < number; i++) {
			tmpArray.push(_array.filter((e) => (typeof e[_key] === "function" ? e[_key]() : e[_key]) === _value)[i]);
		}
		return tmpArray;
	}
}

// ! LEGACY to be replaced by getDomElementById
/**
 * Converts a container id (String) into a jquery selector.
 * @param  {string} _id id to convert
 * @returns  {jQuerySelector} jquery id-based selector expression
 */
export const cId2jqSel = (_id) => $("#" + _id);

/**
 * Returns an element from DOM specified by its id							//	! Replaces cId2jqSel in the long run (to get rid of jquery)
 * @param  {string} _id DOM element to find
 * @returns {object | false} DOM element with matching _id or false
 */
export function getDomElementById(_id) {
	let result = false;
	if (document.getElementById(_id)) result = document.getElementById(_id);
	return result;
}

/**
 * Returns an element from DOM specified by its id							//	! Replaces jquery in the long run
 * @param  {string} _class of element to find
 * @returns {Array | object | false} DOM element(s) with matching _class or false
 */
export function getDomElementByClass(_class) {
	const result = [...document.getElementsByClassName(_class)];

	if (result.length === 0) {
		return false;
	} else if (result.length === 1) {
		return result[0];
	} else {
		return result;
	}
}

/**
 * OBSOLETE
 * @param  {string} _UUID to check
 * @throws exception providing additional information on error
 */
function checkUUIDValidity(_UUID) {
	// @CL refactor to work with cables, ports etc
	if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _UUID)) {
		throw new Error(`DataNodeUUID "${_UUID}" is not unique.`);
	}
}

/**
 * Checks uniqueness of UUID
 * @param  {string} _UUID to check
 * @returns {string} _UUID that was provided as argument
 * @throws Exception if UUID is not unique
 */
export function checkUUIDValidityNew(_UUID) {
	// @CL refactor to work with cables, ports etc
	if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "UUID", _UUID)) {
		throw new Error(`UUID "${_UUID}" is not unique.`);
	} else {
		return _UUID;
	}
}

/**
 * OBSOLETE
 * @param  {string} _id of element to check
 * @throws Exception providing additional information on error
 */
export function checkExistence(_id) {
	if (cId2jqSel(_id).length == 0) {
		throw new Error(_id + " does not exist");
	}
}

/**
 * Checks if DOM element with certain id exists
 * (Replacement for old version)
 * @param  {string} _id of element to check
 * @returns {string} _id that was provided as argument
 * @throws Exception if DOM element with _id does not exist
 */
export function checkExistenceNew(_id) {
	if (cId2jqSel(_id).length === 0) {
		throw new Error(`DOM element with id "${_id}" does not exist!`);
	} else {
		return _id;
	}
}

/**
 * OBSOLETE
 * @param  {string} _id id of element to check
 * @throws Exception providing additional information on error
 */
export function checkUniquenessLEGACY(_id) {
	if (cId2jqSel(_id).length > 0) {
		throw new Error(_id + " is not unique");
	}
}

/**
 * Checks if DOM element with certain id is unique
 * (Replacement for old version)
 * @param  {string} _id id of element to check
 * @returns {string} _id that was provided as argument
 * @throws Exception if DOM element with _id is not unique
 */
export function checkUniqueness(_id) {
	if (cId2jqSel(_id).length !== 0) {
		throw new Error(`DOM id "${_id}" is not unique!`);
	} else {
		return _id;
	}
}

/**
 * Checks if DOM element of certain class exist inside DOM parent element
 * @param  {string} _class class of nested element to check
 * @param  {string} _parentId id of parent element
 * @throws Exception providing additional information on error
 */
export function checkContains(_class, _parentId) {
	if (cId2jqSel(_parentId).find(_class).length == 0) {
		throw new Error(_parentId + " does not contain object off class " + _class);
	}
}

/**
 * OBSOLETE
 * @param  {string} _string to check
 * @throws Exception providing additional information on error
 */
export function checkStringValidity(_string) {
	if (_string == null || _string.length == 0 || _string.trim() == 0) {
		throw new Error(`String "${_string}" is invalid`);
	}
}

/**
 * Checks if string is valid
 * (Replacement for old version)
 * @param  {string | null} _string to check (null omits string checking)
 * @returns {string} _string that was provided as argument
 * @throws Exception of _string is not valid
 */
export function checkStringValidityNew(_string) {
	if (_string == null) {
		return null;
	} else if (typeof _string === "number" || _string.length == 0 || _string.trim() == 0) {
		throw new Error(`String <${_string}> is invalid`);
	} else {
		return _string;
	}
}

/**
 * OBSOLETE
 * @param  {string} _functionName to check
 * @throws Exception providing additional information on error
 */
/* function checkFunctionExistence(_functionName) {
	if (!typeof _functionName === "function") {
		throw new Error(`Function with name ${_functionName} does not exist`);
	}
} */

/**
 * Checks if a function exists
 * (Replacement for old version)
 * @param  {string} _functionName to check
 * @returns {string} _functionName that was provided as argument
 * @throws Exception providing additional information on error
 */
export function checkFunctionExistenceNew(_functionName) {
	if (typeof _functionName !== "function") {
		throw new Error(`"${_functionName}" is not a function or does not exist!`);
	} else {
		return _functionName;
	}
}

/**
 * Checks if value is valid member of an enumeration
 * @param  {object} _value enumeration member to check
 * @param  {object} _enumeration enumeration
 * @throws exception providing additional information on error
 */
/* function checkEnumValidity(_value, _enumeration) {
	if (!Object.values(_enumeration).includes(_value)) {
		throw new Error("Value is not a member of enumeration");
	}
} */

/**
 * Asynchronous wait for execution of something
 * @param  {number} _time time to wait in ms
 * @returns {Promise} to indicate result of wait
 */
// export function asyncWait(_time) {
// 	return new Promise((resolve, reject) => {
// 		setTimeout(() => {
// 			resolve();
// 		}, _time);
// 	});
// }

/**
 * Checks a value and returns null if it is undefined
 * @param  {object} _value to check
 * @returns  {object} _value or null
 */
export function setNullIfUndefined(_value) {
	if (_value != undefined) {
		return _value;
	} else {
		return null;
	}
}

/**
 * Checks a value and returns a provided fallback if it is undefined
 * @param  {object} _value to check for existence and retrieve
 * @param  {object} _fallback to set if _value doesn't exist
 * @returns  {object} _value or provided _fallback
 */
export function setDefaultIfUndefined(_value, _fallback) {
	if (_value != undefined) {
		return _value;
	} else {
		return _fallback;
	}
}

/**
 * Checks a value and returns "" if it is null
 * @param  {object} _value to check
 * @returns  {object} _other value to replace
 */
export function setEmptyIfNull(_value) {
	if (_value != null) {
		return _value;
	} else {
		return "";
	}
}

/**
 * Checks a mandatory value and returns an exception if it is undefined/null
 * @param  {object} _value to check
 * @returns  {object} _value if defined
 * @throws exception providing additional information on error
 */
export function warnIfMandatoryValueNotSet(_value) {
	if (_value == undefined || _value == null) {
		throw new Error("Mandatory value " + _value + " is not defined/null!");
	} else {
		return _value;
	}
}

/**
 * Converts Strings that freak js out to something less offensive (via regular expression)
 * @param  {string} _string to convert
 * @returns  {string} converted string
 */
export const convertFaultyString = (_string) => _string.replace(/ /g, "_").replace(/\//g, "_");

/**
 * Find and return an available smallest number in an array of ascending integers
 * @param  {Array} _array to evaluate
 * @returns  {Integer} valid number
 */
export function findSmallestMissingNumber(_array) {
	if (_array.length == 0) {
		// _array is empty
		return 0;
	} else {
		for (let i = 0; i < _array.length; i++) {
			// _array is not empty, try to find a missing number
			if (_array[i] != i) {
				return i;
			}
		}
		return _array.length; // no missing number in _array, append new at the end
	}
}

/**
 * Turns the first character of a string to lowerCase
 * @param  {string} _string to manipulate
 * @returns {string} with first letter converted to lowerCase
 */
export const makeFirstLetterLowerCase = (_string) => _string.charAt(0).toLowerCase() + _string.slice(1);

/**
 * Returns an object key identified by its value
 * @param  {object} _object to parse
 * @param  {string} _value	to find
 * @returns {string} key matching _value
 */
/* function getKeyByValue(_object, _value) {
 	return Object.keys(_object).find((key) => _object[key] === _value);
} */

/**
 * Returns enumeration entry identified by a key/value combination
 * @param  {enumeration} _enumeration to traverse
 * @param  {object} _key to find
 * @param  {object} _value to find
 * @returns {object} child of _enumeration
 */
export function getEnumItemByKeyValuePair(_enumeration, _key, _value) {
	let result = false;
	Object.entries(_enumeration).forEach((entry) => {
		// console.log(entry[1]);
		if (entry[1][_key] == _value) result = entry[1];
	});
	return result;
}

/**
 * Checks if a given i18nKey exists for the active and fallback language
 * @param  {i18nKey} _i18nKey to check
 * @returns {string} translated value for given _i18nKey
 */
export const getTranslationXX = (_i18nKey) => _i18nKey; // DEACTIVATED DUE TO WEBPACK
// let activeValue = false;
// let fallbackValue = false;

// if (_i18nKey == null) return "";

// if ($.i18n().options.messageStore.messages[$.i18n().locale] && $.i18n().options.messageStore.messages[$.i18n().locale][_i18nKey]) {
// 	activeValue = true;
// }

// if ($.i18n().options.messageStore.messages[$.i18n().options.fallbackLocale][_i18nKey]) {
// 	fallbackValue = true;
// }

// if (!fallbackValue && !activeValue) {
// 	console.debug(`%ci18nKey "${_i18nKey}" not found for active language (${$.i18n().locale}) and fallback language (${$.i18n().fallback})!`, "color: yellow");
// }

// if (fallbackValue && !activeValue) {
// 	console.debug(`%ci18nKey "${_i18nKey}" not found for active language (${$.i18n().locale}), using fallback language (${$.i18n().fallback})!`, "color: yellow");
// }

// return $.i18n(_i18nKey);;

/**
 * Retrieves parameters/variables from url
 * @param  {string} _key to search for
 * @returns {string} value matching key
 */
function getUrlParameters(_key) {
	const tmpUrl = new URL(window.location.href); // get URL
	const queryString = tmpUrl.search; // get parameter string
	const searchParams = new URLSearchParams(queryString); // utility to extract parameters from queryString
	const tmpResult = searchParams.get(_key); // buffer result for further processing

	return tmpResult;
}

/** Reads gitInfo written by webpack into version.json */
export function getGitDetails() {
	$.getJSON("version.json").done((key) => {
		const tmpBranch = key.branch;
		const tmpHash = key.hash;
		const tmpTag = key.tag;
		const tmpDate = key.date;
		let tmpMode = null;
		let tmpText = null;
		switch (tmpBranch) {
			case "master":
				tmpMode = versionInfoModeEnum.MASTER;
				tmpText = `PRODUCTION VERSION v${tmpTag} (${tmpDate})`;
				break;
			case "develop":
				tmpMode = versionInfoModeEnum.DEVELOPMENT;
				tmpText = `DEVELOPMENT VERSION (${tmpDate} / #${tmpHash})`;
				break;
			case "release":
				tmpText = `TEST VERSION (${tmpDate} / #${tmpHash})`;
				tmpMode = versionInfoModeEnum.RELEASE;
				break;
			default: // handles feature branches that can have any name
				tmpText = `${tmpBranch} (${tmpDate} / #${tmpHash})`;
				tmpMode = versionInfoModeEnum.FEATURE;
				break;
		}

		menuBar.setVersionInfo(tmpMode, tmpText); // transform to return; get from menubar directly
	});
}

// --------------------------------------- SORTING Helpers ---------------------------------------

/**
 * String compare function argument for Array Sort ()
 * @param  {string} a to compare with b
 * @param  {string} b to compare with a
 * @returns {number} indicating result of comparison (used internally by array.sort)
 */
function compareStrings(a, b) {
	if (a < b) return -1;
	if (a > b) return 1;
	return 0; // a == b
}

/**
 * Number compare function argument for Array.sort
 * @param  {number} a to compare with b
 * @param  {number} b to compare with a
 * @returns {number} indicating result of comparison (used internally by array.sort)
 */
const compareNumbers = (a, b) => a - b;

/**
 * Date compare function argument for Array.sort
 * @param  {Date} a to compare with b
 * @param  {Date} b to compare with a
 * @returns {number} indicating result of comparison (used internally by array.sort)
 */
function compareDates(a, b) {
	if (new Date(a) > new Date(b)) return 1;
	if (new Date(a) < new Date(b)) return -1;
	return 0; // a == b
}

/**
 * ReferenceDesignator compare function argument for Array.sort (atm identical to compareStrings, we may need to elaborate on that later on...)
 * @param  {string} a to compare with b
 * @param  {string} b to compare with a
 * @returns {number} indicating result of comparison (used internally by array.sort)
 */
function compareReferenceDesignators(a, b) {
	if (a < b) return -1;
	if (a > b) return 1;
	return 0; // a == b
}

/**
 * Compare function for array of objects with variable property to sort for, based on https://stackoverflow.com/a/979325
 * @param  {string} _property to sort for
 * @param  {boolean} _ascending order of sorting (defaults to true)
 * @param  {Function} _modifier to prepend like parseInt (defaults to null)
 * @returns {number} indicating result of comparison (used internally by array.sort)
 */
export function compareObjectProperty(_property, _ascending = true, _modifier = null) {
	const key = _modifier ? (x) => _modifier(x[_property]) : (x) => x[_property];

	_ascending = !_ascending ? -1 : 1;

	return (a, b) => ((a = key(a)), (b = key(b)), _ascending * ((a > b) - (b > a)));
}

/**
 * String compare function argument for multi dimensional Array.sort
 * @param  {number} _columnIndex to compare
 * @param  {boolean} _ascending sorting direction
 * @returns {Function} used as comparator for
 */
export const stringCompare2dArray = (_columnIndex, _ascending) =>
	function (a, b) {
		const valueA = a[_columnIndex].toLowerCase();
		const valueB = b[_columnIndex].toLowerCase();

		if (valueA < valueB) return -1 * invertCompare(_ascending);
		if (valueA > valueB) return 1 * invertCompare(_ascending);
		return 0; // a == b
	};

/**
 * Number compare function argument for multi dimensional Array.sort
 * @param  {number} _columnIndex to compare
 * @param  {boolean} _ascending sorting direction
 * @returns {Function} used as comparator for
 */
export const numberCompare2dArray = (_columnIndex, _ascending) => (a, b) => a[_columnIndex] * invertCompare(_ascending) - b[_columnIndex] * invertCompare(_ascending);

/**
 * Boolean compare function argument for multi dimensional Array.sort
 * @param  {number} _columnIndex to compare
 * @param  {boolean} _ascending sorting direction
 * @returns {Function} used as comparator for
 */
export const booleanCompare2dArray = (_columnIndex, _ascending) =>
	function (a, b) {
		console.error("booleanCompare ToDo");
	};

/**
 * Date compare function argument for multi dimensional Array.sort
 * @param  {number} _columnIndex to compare
 * @param  {boolean} _ascending sorting direction
 * @returns {Function} used as comparator for
 */
export const dateCompare2dArray = (_columnIndex, _ascending) =>
	function (a, b) {
		if (new Date(a[_columnIndex]) > new Date(b[_columnIndex])) return 1 * invertCompare(_ascending);
		if (new Date(a[_columnIndex]) < new Date(b[_columnIndex])) return -1 * invertCompare(_ascending);
		return 0; // a == b
	};

/**
 * Small helper to invert the sorting order of above comparators
 * @param  {boolean} _ascending direction to sort
 * @returns {number} 1 or -1 indicating sort direction
 */
const invertCompare = (_ascending) => (_ascending ? 1 : -1);

/**
 * Converts server time (unix time stamp) into clients locale time
 * @param  {Date} _unixTimeStamp unix time stamp
 * @returns {string} converted time
 */
export function unixTime2localTime(_unixTimeStamp) {
	const options = {
		year: "2-digit",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
	};

	return new Date(_unixTimeStamp * 1000).toLocaleDateString(formatter.locale, options);
}

/**
 * Returns a localized timestamp in format "HH:mm:ss".
 * @returns {string} localized time
 */
export function getLocalTime() {
	const options = {
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	};

	return new Date().toLocaleString(formatter.locale, options);
}

/**
 * Returns connection by source & target port uuid
 * @param  {string} _portUUID UUID of the source or target port
 * @returns {Connection} connection found
 */
export function getConnectionByPortUUID(_portUUID) {
	let res = null;
	dataRoot.nodeList.forEach((node) => {
		node.connections.forEach((connection) => {
			if (connection.sourcePortId == _portUUID || connection.targetPortId == _portUUID) {
				res = connection;
			}
		});
	});
	return res;
}

/**
 * Rounds a float to a fixed number of decimal places
 * @param {Float} _number to round
 * @param {Integer} _decimalPlaces to round to
 * @returns {Float} with given amount of decimal places
 * @export
 */
export const roundFloat = (_number, _decimalPlaces) => Math.round(_number * 10 ** _decimalPlaces) / 10 ** _decimalPlaces;

/**
 * Checks if an array is subset of another array.
 * @param {Array} _referenceArray array to check against
 * @param {Array} _subsetArray array to check if subset
 * @returns {boolean} result of check
 * @important This solution is only reliable for flat arrays and will most likely fail for nested structures.
 */
export function isSubset(_referenceArray, _subsetArray) {
	const result = _subsetArray.every((value) => _referenceArray.includes(value));
	return result;
}

/**
 * Checks if an array is equal to another array (checking order is optional).
 * Based on https://www.30secondsofcode.org/articles/s/javascript-array-comparison
 * @param {Array} _array1 array to check
 * @param {Array} _array2 array to check
 * @param {boolean} [_checkOrder = false] check for element order (default = false)
 * @returns {boolean} result of check
 * @important This solution is only reliable for flat arrays and will most likely fail for nested structures.
 */
export function isEqual(_array1, _array2, _checkOrder = false) {
	if (_array1.length !== _array2.length) return false;

	if (_checkOrder) {
		return _array1.every((element, i) => element === _array2[i]);
	} else {
		const uniqueValues = new Set([..._array1, ..._array2]);

		for (const value of uniqueValues) {
			const array1Count = _array1.filter((element) => element === value).length;
			const array2Count = _array2.filter((element) => element === value).length;
			if (array1Count !== array2Count) return false;
		}
		return true;
	}
}

/**
 * Takes a projectList and a projectName to find according projectId.
 * @param {Array} _projectList list to search through //! each entry needs entry.name and entry.id
 * @param {string} _projectName name to search for
 * @returns {number} project id according to given projectName
 */
export function searchProjectIdInProjectList(_projectList, _projectName) {
	if (!_projectList) return null;
	const tmpProject = _projectList.find((project) => project.name == _projectName);
	if (!tmpProject) return null;
	return tmpProject.id;
}

/**
 * Corrects the port orientations for UnitNodes and AssemblyNodes.
 * @warning only works before a port gets created
 * @param {Port} _port to set orientation for
 */
export function adjustPortOrientation(_port) {
	if (_port.interfaces.some((_interface) => _interface.groupX === "DATA")) {
		if (_port.side === PortSide.TARGET) {
			_port.graphics.image.orientation = "EAST";
			_port.graphics.symbol.orientation = "EAST";
		} else {
			_port.graphics.image.orientation = "WEST";
			_port.graphics.symbol.orientation = "WEST";
		}
	} else {
		if (_port.side === PortSide.TARGET) {
			_port.graphics.image.orientation = "NORTH";
			_port.graphics.symbol.orientation = "NORTH";
		} else {
			_port.graphics.image.orientation = "SOUTH";
			_port.graphics.symbol.orientation = "SOUTH";
		}
	}
}

/**
 * Finds the cssRule within all stylesheets matching a provided css class
 * @param  {string} _boundCssClass to search for
 * @returns  {CSSRule} matching _boundCssClass
 */
export function findCssRule(_boundCssClass) {
	for (let i = 0; i < document.styleSheets.length; i++) {
		for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
			if (document.styleSheets[i].cssRules[j].selectorText == "." + _boundCssClass) {
				return document.styleSheets[i].cssRules[j];
			}
		}
	}
	throw new Error(`No cssRule matching "${_boundCssClass}" found!`);
}

/**
 * Stops a function to be called multiple times in a given timeout interval.
 * Instead runs the function when no further call is made within the timeout interval.
 * @warning the return value of debounce has to be stored in an "persistent" variable to save the current timeout.
 * @param {Function} _callback function to debounce.
 * @param {number} _timeout interval to stop the execution of the given callback.
 * @returns {Function} debounced version of the given callback.
 */
export function debounce(_callback, _timeout = 100) {
	let timeout = null;
	return (...args) => {
		window.clearTimeout(timeout);
		timeout = window.setTimeout(() => _callback(...args), _timeout);
	};
}

/**
 * Returns whether _dataNode is a motor.
 * @param {BaseDataNode} _dataNode to check
 * @returns {boolean} whether _dataNode is a motor
 */
export const isMotor = (_dataNode) => _dataNode.ports.some((_port) => _port.interfaces.some((_interface) => _interface.isMotor));

/**
 * Returns whether _dataNode is a consumer and no motor.
 * @param {BaseDataNode} _dataNode to check
 * @returns {boolean} whether _dataNode is a consumer and no motor
 */
export const isConsumer = (_dataNode) => _dataNode.group === "consumers";
